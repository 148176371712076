import { TypeInput, useInput } from '@plarin/utils';
import React, { useEffect } from 'react';
import { Input } from '../inputs';
import { ModalActionNamesEnum, TSetModalAction } from '../modal';
import { TypeStaffName } from './types';

type TChangeStaffName = (data: TypeStaffName, id: string) => Promise<void>;

type TModalContent = {
  firstName: string;
  secondName: string | undefined;
  lastName: string | undefined;
  public_id: string;
  setModalAction: TSetModalAction;
  changeStaffName: TChangeStaffName;
  isLoading: boolean | undefined;
  closeModal: () => boolean;
};

export const SetStaffNameContent = ({
  firstName,
  secondName,
  lastName,
  public_id,
  setModalAction,
  changeStaffName,
  isLoading,
  closeModal,
}: TModalContent) => {
  const fname = useInput({
    initialValue: firstName || '',
    type: TypeInput.fName,
    isRequired: true,
  });
  // eslint-disable-next-line
  const { setError: fnameSetError, setHint: fnameSetHint, setWasChanged: fnameSetWasChanged, checkValue: fnameCheckValue, ...fnameInput } = fname;

  const lname = useInput({
    initialValue: lastName || '',
    type: TypeInput.lName,
    isRequired: false,
  });
  // eslint-disable-next-line
  const { setError: lnameSetError, setHint: lnameSetHint, setWasChanged: lnameSetWasChanged, checkValue: lnameCheckValue, ...lnameInput } = lname;

  const sname = useInput({
    initialValue: secondName || '',
    type: TypeInput.sName,
    isRequired: false,
  });
  // eslint-disable-next-line
  const { setError: snameSetError, setHint: snameSetHint, setWasChanged: snameSetWasChanged, checkValue: snameCheckValue, ...snameInput } = sname;

  const setAction = () => {
    setModalAction({
      action: () => {
        if (!fname.value || fname.error || lname.error || sname.error) {
          !fname.value && fnameSetError(true);
        } else if (fname.value === firstName && sname.value === secondName && lname.value === lastName) {
          closeModal();
        } else {
          changeStaffName({ fname: fname.value, lname: lname.value, sname: sname.value }, public_id);
        }
      },

      actionTitle: isLoading ? ModalActionNamesEnum.Change : ModalActionNamesEnum.Save,
    });
  };

  useEffect(() => {
    fnameCheckValue();
    lnameCheckValue();
    snameCheckValue();
    setAction();
  });

  return (
    <>
      <Input {...fnameInput} label="Имя" fullWidth />
      <Input {...snameInput} label="Отчество" fullWidth />
      <Input {...lnameInput} label="Фамилия" fullWidth />
    </>
  );
};
