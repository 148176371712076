import { Typography } from '@plarin/design';
import { Input, ModalActionNamesEnum } from '@plarin/inputs';
import { GlobalErrorMessage, TypeInput, useInput } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { STATUS, ymDisableClass } from '../../../../utils/constants';
import classes from './style.module.scss';

const ChangeEmailContentStart = observer(() => {
  const {
    profileStore: {
      setModalAction,
      changeProfileEmail,
      setContentStep,
      contentStep,
      setNewEmail,
      data,
      globalError,
      setGlobalError,
      globalStatus,
      isLoading,
    },
  } = useAppDataStore();

  const email = useInput({
    initialValue: data.email || '',
    type: TypeInput.email,
    isRequired: true,
  });
  // eslint-disable-next-line
  const {
    setError: setEmailError,
    setTextError: setTextEmailError,
    setHint: setEmailHint,
    setWasChanged: setEmailWasChanged,
    checkValue: emailCheckValue,
    ...emailInput
  } = email;

  const password = useInput({
    initialValue: '',
    type: TypeInput.password,
  });
  // eslint-disable-next-line
  const {
    setError: setPasswordError,
    setTextError: setTextPasswordError,
    setHint: setPasswordHint,
    setWasChanged: setPasswordWasChanged,
    checkValue: passwordCheckValue,
    ...passwordInput
  } = password;

  const setNextAction = useCallback(() => {
    contentStep === 1 &&
      setModalAction({
        action: () => {
          if (!email.value || email.error || !password.value || password.error || globalError) {
            !email.value && setEmailError(true);
            !password.value && setPasswordError(true);
          } else {
            changeProfileEmail({ email: email.value, password: password.value });
          }
        },
        isLoading: isLoading,
        actionTitle: ModalActionNamesEnum.Save,
      });
  }, [
    changeProfileEmail,
    isLoading,
    setEmailError,
    setPasswordError,
    globalError,
    contentStep,
    email,
    password,
    setModalAction,
  ]);

  useEffect(() => {
    passwordCheckValue();
    emailCheckValue();
    setNextAction();
  });

  useEffect(() => {
    if (globalStatus === STATUS.SUCCESS) {
      setContentStep(2);
      setNewEmail(email.value);
    }

    if (
      globalError === GlobalErrorMessage.newNotOldEmail ||
      globalError === GlobalErrorMessage.newEmailExists ||
      globalError === GlobalErrorMessage.EditEmailProg
    ) {
      setTextEmailError && setTextEmailError(globalError!);
    }
    if (!globalError) {
      setTextPasswordError && setTextPasswordError('');
      setTextEmailError && setTextEmailError('');
    } else {
      setTextPasswordError && setTextPasswordError(globalError);
    }
  }, [
    setNextAction,
    globalStatus,
    globalError,
    email.value,
    setContentStep,
    setNewEmail,
    setTextEmailError,
    setTextPasswordError,
  ]);

  useEffect(() => {
    setGlobalError('');
  }, [email.value, password.value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form className={classes.form}>
      <Input
        {...emailInput}
        name="email"
        type="email"
        label="Электронная почта"
        fullWidth
        autoFocus
        autoComplete="off"
        className={ymDisableClass}
        size={48}
        disableMinHeight={true}
      />
      <Input
        {...passwordInput}
        name="password"
        label="Пароль"
        type="password"
        fullWidth
        autoComplete="off"
        className={ymDisableClass}
        size={48}
        disableMinHeight={true}
      />
    </form>
  );
});

const ChangeEmailContentFinish = observer(() => {
  const {
    profileStore: { data, cancelChangeEmail, newEmail, setDisabledActions, setModalTitle },
  } = useAppDataStore();

  useEffect(() => {
    setModalTitle('Изменение электронной почты');
    setDisabledActions(true);
    return () => {
      setDisabledActions(false);
    };
  }, [setDisabledActions]);

  return (
    <>
      <div>
        <Typography size="AuthContent">
          Мы отправили ссылку для подтверждения смены адреса электронной почты с{' '}
          <Typography weight={600}>{data.email}</Typography> на адрес{' '}
          <Typography weight={600}>{newEmail && newEmail}</Typography>. Письмо должно прийти через несколько минут.
        </Typography>
      </div>
      <br />
      <div>
        <Typography size="AuthContent">
          Если вы не получили письмо, проверьте не попало ли оно в папку со спамом.
        </Typography>
      </div>
      <br />
      <Typography size="AuthContent" color="LinkPrimary">
        <span onClick={cancelChangeEmail} style={{ cursor: 'pointer' }}>
          Отменить изменение электронной почты
        </span>
      </Typography>
    </>
  );
});

export const ChangeEmailContent = observer(() => {
  const {
    profileStore: { contentStep, setContentStep },
  } = useAppDataStore();

  useEffect(() => {
    setContentStep(1);
  }, [setContentStep]);

  return (
    <>
      {contentStep === 1 && <ChangeEmailContentStart />}
      {contentStep === 2 && <ChangeEmailContentFinish />}
    </>
  );
});
