import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import classes from './main-menu-item.module.scss';
import { TMainMenuItem } from './types';

// пункт меню, который не принимает isSelected и визуально не бывает выделен (т.е. не ведёт ни на какую страницу)
export const MainMenuItem = ({ icon, title, isSelected, onClick }: TMainMenuItem) => {
  return (
    <ListItem
      disablePadding
      classes={{
        root: classes.menuItemRoot,
      }}
    >
      <ListItemButton
        classes={
          isSelected
            ? { root: classes.menuItemSelectedButtonRoot }
            : { root: classes.menuItemButtonRoot, focusVisible: classes.noFocusVisible }
        }
        onClick={onClick}
        selected={isSelected}
      >
        {icon}
        <ListItemText
          classes={{ root: classes.menuItemTextRoot, primary: classes.menuItemTextPrimary }}
          primary={title}
        />
      </ListItemButton>
    </ListItem>
  );
};
