import { Divider, Menu, MenuItem as MUIMenuItem, MenuList } from '@mui/material';
import { CheckIcon, Preloader } from '@plarin/design';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from '../checkbox';
import classes from './style.module.scss';

interface Option {
  label: string;
  action?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  checkbox?: boolean;
  checked?: boolean;
  disableSelected?: boolean;
  close?: boolean;
  isGroup?: boolean;
  isDivider?: boolean;
}

export interface ContextMenuProps {
  anchorEl?: null | Element | ((element: Element) => Element);
  open?: boolean;
  menuHide?: boolean;
  classMenu?: string;
  classPaper?: string;
  openDatePicker?: boolean;
  handleClose: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  options?: Option[];
  getOptions?: () => Promise<Option[] | undefined>;
  selected?: number;
  sizeS?: boolean;
  width?: number;
  verticalTop?: number | 'center' | 'top' | 'bottom';
  endIcon?: JSX.Element;
}

type TMenuOption = Option & Omit<ContextMenuProps, 'anchorEl' | 'verticalTop'> & { idx: number; isSelected: boolean };

const MItem = ({
  label,
  isGroup,
  disableSelected,
  checked,
  action,
  handleClose,
  close,
  isSelected,
  checkbox,
  endIcon,
}: TMenuOption) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const isMouseEnterTrue = useCallback(() => setMouseEnter(true), []);
  const isMouseEnterFalse = useCallback(() => setMouseEnter(false), []);

  return (
    <MUIMenuItem
      classes={{
        root: clsx(classes.menuItem, isGroup && classes.menuGroup),
        selected: classes.selected,
        divider: classes.divider,
      }}
      selected={isSelected}
      onMouseEnter={isMouseEnterTrue}
      onMouseLeave={isMouseEnterFalse}
      onClick={e => {
        e.stopPropagation();
        action && action(e);
        close && handleClose(e);
      }}
    >
      {label}
      {checkbox && (
        <Checkbox
          className={classes.checkbox}
          disabled={disableSelected}
          value={!!checked}
          size="small"
          isInverted={mouseEnter}
        />
      )}
      {endIcon}
    </MUIMenuItem>
  );
};

export const ContextMenu = React.memo(
  ({
    anchorEl,
    menuHide,
    classMenu = '',
    classPaper = '',
    handleClose,
    sizeS,
    options = [],
    getOptions,
    open = !!anchorEl,
    selected,
    openDatePicker,
    verticalTop,
    width,
    endIcon,
  }: ContextMenuProps) => {
    const [compiledOptions, setCompiledOptions] = useState(options);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      (async () => {
        if (open && getOptions) {
          setLoading(true);
          const freshOptions = (await getOptions()) || [];
          setCompiledOptions(options => [...options, ...freshOptions]);
          setLoading(false);
        }
      })();
    }, [getOptions, open]);

    useEffect(() => {
      setCompiledOptions(options);
    }, [options]);

    const renderMenuItem = useCallback(
      ({ label, action, close, isGroup, checkbox, checked, disableSelected, isDivider }: Option, idx: number) => {
        return isDivider ? (
          <Divider key={idx} classes={{ root: classes.divider }} />
        ) : (
          <MItem
            label={label}
            isGroup={isGroup}
            disableSelected={disableSelected}
            checked={checked}
            action={action}
            handleClose={handleClose}
            close={close}
            idx={idx}
            isSelected={selected === idx}
            endIcon={selected === idx ? <CheckIcon /> : undefined}
            checkbox={checkbox}
            key={idx}
          />
        );
      },
      [handleClose, selected],
    );

    if (!open) {
      return <></>;
    }

    return anchorEl ? (
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: verticalTop ? verticalTop : 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        classes={{
          paper: clsx(classes.paper, menuHide && openDatePicker && classes.menuHide, classPaper),
          root: clsx(menuHide && openDatePicker && classes.menuHide, sizeS && classes.menuSizeS),
          list: clsx(classMenu, classes.menu),
        }}
      >
        {compiledOptions?.map(renderMenuItem)}
        {loading && <Preloader size={20} />}
        {!loading && !compiledOptions?.length && (
          <MUIMenuItem disabled onClick={handleClose}>
            Ничего нет
          </MUIMenuItem>
        )}
      </Menu>
    ) : (
      open && (
        <MenuList
          sx={{ width: `${width}px` }}
          classes={{
            root: clsx(
              menuHide && openDatePicker && classes.menuHide,
              !menuHide && openDatePicker && classes.menuVisible,
              classMenu,
              classes.menu,
              sizeS && classes.menuSizeS,
            ),
          }}
        >
          {compiledOptions?.map(renderMenuItem)}
          {loading && <Preloader size={20} />}
          {!loading && !compiledOptions?.length && (
            <MUIMenuItem disabled onClick={handleClose}>
              Ничего нет
            </MUIMenuItem>
          )}
        </MenuList>
      )
    );
  },
  (prevProps, nextProps) =>
    prevProps.open === nextProps.open &&
    prevProps.openDatePicker === nextProps.openDatePicker &&
    prevProps.options === nextProps.options &&
    prevProps.selected === nextProps.selected,
);
