import { Typography } from '@plarin/design';
import { getNumeral } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import classes from './style.module.scss';

type ComponentProps = {
  type: 'projects' | 'members' | 'teams';
  hasLoadingError: boolean;
  hasAdminRights: boolean;
  openDrawer: () => void;
  archived: number;
  completed?: number;
};

type OverlayProps = {
  hasAdminRights: boolean;
  openDrawer: () => void;
  archived: number;
  completed?: number;
};

const getErrorOverlay = () => (
  <div className={clsx(classes.emptyMessage, classes.clickable)}>
    <Typography size="Main" color="Secondary">
      При загрузке данных произошла ошибка.
    </Typography>
  </div>
);

const getTeamsOverlay = ({ hasAdminRights, openDrawer, archived, completed }: OverlayProps) => {
  if (archived)
    return (
      <div className={clsx(classes.emptyMessage, classes.clickable)}>
        <Typography size="Main" color="Secondary">
          У вас {archived} {getNumeral(archived, ['Архивная команда', 'Архивные команды', 'Архивных команд'])}. Для
          просмотра включите фильтр в таблице{hasAdminRights ? ' или ' : '.'}
        </Typography>
        {hasAdminRights && (
          <span className={classes.createLink} onClick={openDrawer}>
            <Typography size="Main" color="Primary60" weight={600}>
              Создайте новую команду.
            </Typography>
          </span>
        )}
      </div>
    );

  return (
    <div className={clsx(classes.emptyMessage, classes.clickable)}>
      <Typography size="Main" color="TextSecondary">
        У вас ещё нет команд.{' '}
      </Typography>
      {hasAdminRights && (
        <span className={classes.createLink} onClick={openDrawer}>
          <Typography size="Main" color="Primary60" weight={600}>
            Создайте первую команду.
          </Typography>
        </span>
      )}
    </div>
  );
};

const getProjectsOverlay = ({ hasAdminRights, openDrawer, archived, completed }: OverlayProps) => {
  if (archived && completed)
    return (
      <div className={clsx(classes.emptyMessage, classes.clickable)}>
        <Typography size="Main" color="TextSecondary">
          У вас {completed}{' '}
          {getNumeral(completed, ['Завершённый проект', 'Завершённых проекта', 'Завершённых проектов'])} и {archived}{' '}
          {getNumeral(archived, ['Архивный', 'Архивных', 'Архивных'])}. Для просмотра включите фильтр в таблице
          {hasAdminRights ? ' или ' : '.'}
        </Typography>
        {hasAdminRights && (
          <span className={classes.createLink} onClick={openDrawer}>
            <Typography size="Main" color="Primary60" weight={600}>
              Создайте новый проект.
            </Typography>
          </span>
        )}
      </div>
    );

  if (completed)
    return (
      <div className={clsx(classes.emptyMessage, classes.clickable)}>
        <Typography size="Main" color="TextSecondary">
          У вас {completed}{' '}
          {getNumeral(completed, ['Завершённый проект', 'Завершённых проекта', 'Завершённых проектов'])}. Для просмотра
          включите фильтр в таблице{hasAdminRights ? ' или ' : '.'}
        </Typography>
        {hasAdminRights && (
          <span className={classes.createLink} onClick={openDrawer}>
            <Typography size="Main" color="Primary60" weight={600}>
              Создайте новый проект.
            </Typography>
          </span>
        )}
      </div>
    );

  if (archived)
    return (
      <div className={clsx(classes.emptyMessage, classes.clickable)}>
        <Typography size="Main" color="TextSecondary">
          У вас {archived} {getNumeral(archived, ['Архивный проект', 'Архивных проекта', 'Архивных проектов'])}. Для
          просмотра включите фильтр в таблице{hasAdminRights ? ' или ' : '.'}
        </Typography>
        {hasAdminRights && (
          <span className={classes.createLink} onClick={openDrawer}>
            <Typography size="Main" color="Primary60" weight={600}>
              Создайте новый проект.
            </Typography>
          </span>
        )}
      </div>
    );

  return (
    <div className={clsx(classes.emptyMessage, classes.clickable)}>
      <Typography size="Main" color="TextSecondary">
        У вас ещё нет проектов.{' '}
      </Typography>
      {hasAdminRights && (
        <span className={classes.createLink} onClick={openDrawer}>
          <Typography size="Main" color="Primary60" weight={600}>
            Создайте первый проект.
          </Typography>
        </span>
      )}
    </div>
  );
};

const getMembersOverlay = () => (
  <div className={clsx(classes.emptyMessage, classes.clickable)}>
    <Typography size="Main" color="TextSecondary">
      {/* Вообще такая ситуация не должна возникать. В норме хотя бы владелец воркспейса активен */}
      Нет активных пользователей.
    </Typography>
  </div>
);

export const WsEmptyTableOverlay = ({
  type,
  hasLoadingError,
  hasAdminRights,
  openDrawer,
  archived,
  completed,
}: ComponentProps) => {
  if (hasLoadingError) return getErrorOverlay();

  if (type === 'projects') return getProjectsOverlay({ hasAdminRights, openDrawer, archived, completed });

  if (type === 'teams') return getTeamsOverlay({ hasAdminRights, openDrawer, archived });

  if (type === 'members') return getMembersOverlay();

  return <></>;
};
