import { IntegrationButton } from '@plarin/inputs';
import React from 'react';
import { NetworkEnum } from '../../dictionary/integrations';
import classes from './social-buttons.module.scss';

type SocialButtonsProps = {
  action?: (network: NetworkEnum) => void;
};

export const SocialButtons = ({ action = () => {} }: SocialButtonsProps) => {
  return (
    <div className={classes.socialButtons}>
      {Object.values(NetworkEnum).map(item => {
        return <IntegrationButton socialVariant={item} action={action} key={item} />;
      })}
    </div>
  );
};
