import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import classes from './switcher-tooltip.module.scss';
import { ITooltipProps } from './tooltip-props';

export const SwitcherTooltip = <TComponent extends React.ElementType>({
  children,
  content,
  component: Component,
  componentProps,
  placement = 'top-start',
  needPaddings,
}: ITooltipProps<TComponent>) => {
  if (!content) return children;

  return (
    <Tooltip
      TransitionProps={{ timeout: 0 }}
      title={content}
      classes={{ tooltip: clsx(classes.popperForSwitcher, needPaddings && classes.popperWithPadding) }}
      placement={placement}
      followCursor
      disableInteractive
    >
      <Component {...(componentProps as any)}>{children}</Component>
    </Tooltip>
  );
};
