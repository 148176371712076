import { BasicStore, httpClient2 } from '@plarin/core';
import { GridApi } from '@plarin/inputs';
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  GADisableReq,
  GAEnableReq,
  GARemoveReq,
  GAResponse,
  ModalData,
  TGAConnectResp,
  TGAStoreData,
} from '../../types/connect-ga/types';
import { history } from '../components/app';
import { routerPaths } from '../routing/router-path';
import { STATUS } from '../utils/constants';
import { getAccountsIntegrationsStatus, getAccountsItemsSyncStatus } from '../utils/integration-converters';

export class IntegrationsGAStore extends BasicStore {
  data: TGAStoreData[] = [];
  tableLoading: boolean = false;
  tableError: boolean = false;
  modalData: ModalData = {} as ModalData;
  isLoading: boolean = false;
  searchTableGa: string = '';

  constructor() {
    super();
    makeObservable<this>(this, {
      data: observable,
      tableLoading: observable,
      tableError: observable,
      modalData: observable,
      isLoading: observable,
      connectGoogleAccount: action.bound,
      searchTableGa: observable,
    });
  }

  setTableLoading = (value: boolean) => runInAction(() => (this.tableLoading = value));
  setTableError = (value: boolean) => runInAction(() => (this.tableError = value));
  setSearchTableGa = (value: string) => runInAction(() => (this.searchTableGa = value));

  setModalData = (data: ModalData) => runInAction(() => (this.modalData = data));
  private setIsLoading = (loading: boolean) => runInAction(() => (this.isLoading = loading));

  getData = async () => {
    this.setTableLoading(true);
    this.setTableError(false);

    setTimeout(async () => {
      await this.execRequest<GAResponse[]>(httpClient2.get('/api/v1/connect/ga/accounts'))
        .then(res =>
          runInAction(
            () =>
              (this.data = res.reduce((accumulator: TGAStoreData[], account) => {
                // представления внутри аккаунта
                if (account.items.length) {
                  account.items.forEach(item => {
                    accumulator.push({
                      ...item,
                      key: item.id,
                      _id: account._id,
                      orgHierarchy: [account._id, item.id],
                      syncStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
                      syncStatus: getAccountsItemsSyncStatus(
                        account.plarin_status,
                        account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                      ),
                      plarin_status: account.plarin_status,
                    });
                  });
                }

                // аккаунт
                accumulator.push({
                  ...account,
                  key: account._id,
                  header1: account._id,
                  integrationStatus: getAccountsIntegrationsStatus(
                    account.plarin_status,
                    account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                  ),
                  isParent: true,
                  integrationStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
                });
                return accumulator;
              }, [])),
          ),
        )
        .catch(e => {
          this.setTableError(!!e);
          this.setTableLoading(false);
        })
        .finally(() => this.setTableLoading(false));
    }, 500);
  };

  connectGoogleAccount = async (workspace: string) => {
    await this.execRequest<TGAConnectResp>(
      httpClient2.get('/api/v1/connect/ga/url', {
        params: {
          url: `${window.location.origin}/${workspace}${routerPaths.integrations.GOOGLE_ANALYTICS}`,
        },
      }),
    )
      .then(res =>
        runInAction(() => {
          if (res.url) {
            window.location.href = res.url;
          }
        }),
      )
      .catch(() => {});
  };
  checkQueryString = () => {
    const urlSearchParams = new URLSearchParams(history.location.search);

    const error = urlSearchParams.get('error');
    const errorDescription = urlSearchParams.get('error_description');

    if (error) {
      this.addNotification({
        type: STATUS.ERROR,
        title: error,
        message: errorDescription ? errorDescription : '',
      });
    }
    if (urlSearchParams.get('ga_account_id')) {
      window.history.replaceState(null, '', window.location.pathname);
      this.addNotification({
        type: STATUS.SUCCESS,
        title: `Аккаунт ${urlSearchParams.get('ga_account_id')} успешно подключен`,
      });
    }
  };

  enableGA = async (data: GAEnableReq, gridApi?: GridApi) => {
    runInAction(
      () =>
        (this.data = this.data.map(el =>
          data.db_ids.includes(el._id)
            ? el.syncStatusCode
              ? { ...el, syncStatusCode: 'loading' }
              : { ...el, integrationStatusCode: 'loading' }
            : el,
        )),
    );

    setTimeout(async () => {
      await this.execRequest<GAResponse[]>(httpClient2.post('/api/v1/connect/ga/clients/enable', data))
        .then(res => {
          res.forEach(account => {
            this.data = this.data.map(el =>
              el._id === account._id
                ? el.syncStatusCode
                  ? {
                      ...el,
                      syncStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
                      syncStatus: getAccountsItemsSyncStatus(
                        account.plarin_status,
                        account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                      ),
                      plarin_status: account.plarin_status,
                    }
                  : {
                      ...el,
                      plarin_status: account.plarin_status,
                      integrationStatus: getAccountsIntegrationsStatus(
                        account.plarin_status,
                        account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                      ),
                      integrationStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
                    }
                : { ...el },
            );
          });

          this.addNotification({
            type: STATUS.SUCCESS,
            title: data.db_ids.length === 1 ? 'Интеграция успешно включена' : 'Интеграции успешно включены',
          });
        })

        .catch(error => {
          console.log(error);
          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          gridApi && gridApi.redrawRows();
        });
    }, 500);
  };

  disableGA = async (data: GADisableReq, gridApi?: GridApi) => {
    this.setIsLoading(true);
    setTimeout(async () => {
      await this.execRequest<GAResponse[]>(httpClient2.post('/api/v1/connect/ga/clients/disable', data))
        .then(res => {
          res.forEach(account => {
            this.data = this.data.map(el =>
              el._id === account._id
                ? el.syncStatusCode
                  ? {
                      ...el,
                      syncStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
                      syncStatus: getAccountsItemsSyncStatus(
                        account.plarin_status,
                        account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                      ),
                      plarin_status: account.plarin_status,
                    }
                  : {
                      ...el,
                      plarin_status: account.plarin_status,
                      integrationStatus: getAccountsIntegrationsStatus(
                        account.plarin_status,
                        account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                      ),
                      integrationStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
                    }
                : { ...el },
            );
          });

          this.addNotification({
            type: STATUS.SUCCESS,
            title: data.db_ids.length === 1 ? 'Интеграция успешно выключена' : 'Интеграции успешно выключены',
          });
        })
        .catch(error => {
          console.log(error);
          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          this.setIsLoading(false);
          this.setModalData({
            type: '',
            name: [],
            ids: [],
          });
          gridApi && gridApi.redrawRows();
        });
    }, 500);
  };

  removeGA = async (data: GARemoveReq) => {
    this.setIsLoading(true);
    setTimeout(async () => {
      await this.execRequest<string[]>(httpClient2.post('/api/v1/connect/ga/clients/disconnect', data))
        .then(res => {
          const removeNames = this.data
            .filter(el => el.isParent && data.db_ids.includes(el._id))
            .map(el => el.header1)
            .join(', ');
          runInAction(() => {
            this.data = this.data.filter(el => !data.db_ids.includes(el._id));
          });
          this.addNotification({
            type: STATUS.SUCCESS,
            title: data.db_ids.length === 1 ? `Аккаунт ${removeNames} отключен` : `Аккаунты ${removeNames} отключены`,
          });
        })
        .catch(error => {
          console.log(error);
          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          this.setModalData({
            type: '',
            name: [],
            ids: [],
          });
          this.setIsLoading(false);
        });
    }, 500);
  };
}
