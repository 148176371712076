import { runInAction } from 'mobx';
import {
  Account,
  Cabinet,
  MTCabinetExtended,
  TAccountsMTStoreData,
  // TAccountsVKStoreData,
} from '../../types/integration/types';
import { MTTypesEnum } from '../dictionary/integrations';
import { STATUS } from './constants';
import { mtCabinetAssimilation, mtToAccount, mtToCabinet } from './integration-mappers-mt';
// import { vkCabinetAssimilation, vkToAccount, vkToCabinet } from './integration-mappers-vk';

type Converter = ([dataMT]: [TAccountsMTStoreData]) => [Account[], Cabinet[]]; // , dataVK , TAccountsVKStoreData

export const getAccountsIntegrationsStatus = (plarin_status: string, data_source_status: string) => {
  if (data_source_status === STATUS.ACTIVE) {
    if (plarin_status === STATUS.ENABLED) return 'Включена';
    return 'Выключена';
  }
  if (data_source_status === STATUS.BLOCKED) return 'Ошибка подключения';
  return '';
};

export const getAccountsItemsSyncStatus = (plarin_status: string, data_source_status: string) => {
  if (data_source_status === STATUS.ACTIVE) {
    if (plarin_status === STATUS.ENABLED) return 'Включена';
    return 'Выключена';
  }
  if (data_source_status === STATUS.BLOCKED) return 'Ошибка синхронизации';
  return '';
};

export const getCabinetsSyncStatus = (plarin_status: string, data_source_status: string) => {
  if (data_source_status === STATUS.DELETED) return 'Отключена';
  if (plarin_status === STATUS.ENABLED) return 'Включена';
  return 'Выключена';
};

export const getCabinetIntegrationStatus = (data_source_status: string) => {
  if (data_source_status === STATUS.ACTIVE) return 'Включена';
  return 'Отключена';
};

const checkDuplicates = <Elem extends Cabinet>(arr: Elem[], key: keyof Elem): Elem[] => {
  const groups: Record<string, Elem> = {};

  arr.forEach(cab => {
    const group = groups[cab[key] as unknown as string];

    if (!group) {
      const duplicates = arr.filter(item => cab[key] === item[key]);
      if (duplicates.length > 1) {
        duplicates.forEach(dup => (dup.orgHierarchy = [cab.key, dup.key]));
        groups[cab[key] as unknown as string] = {
          ...cab,
          orgHierarchy: undefined,
          ...checkEnables(duplicates),
          connectionType: 'Множественное подключение',
          rights: 'Множественное подключение',
        };
      }
    }
  });
  return Object.values(groups);
};

export const accountsAndCabinetsConverter: Converter = ([dataMT]) => {
  // dataVK
  const mtAccounts: TAccountsMTStoreData = dataMT.filter(
    ({ type }) => type === 'agency' || type === 'manager' || type === 'standalone',
  );
  // const vkAccounts: Account[] = dataVK.map(vkToAccount);

  const mtCabinets: MTCabinetExtended[] = dataMT
    .filter(({ type }) => type === 'client' || type === 'standalone')
    .map(mtCabinetAssimilation(mtAccounts)); // (см. каррирование)

  // const vkCabinets: Cabinet[] = dataVK
  //   .flatMap(vkCabinetAssimilation)
  //   .filter(item => item.account_type !== 'agency')
  //   .map(vkToCabinet);

  const mtAccountsConverted = mtAccounts.map(mtToAccount);
  mtAccountsConverted.forEach(acc =>
    acc.type === MTTypesEnum.standalone
      ? (acc.cabinetCount = 1)
      : (acc.cabinetCount = mtCabinets.filter(cab => cab.parent_id === acc.client_id).length),
  );

  const mtCabinetsConverted = mtCabinets.map(mtToCabinet);

  // const vkGroups = checkDuplicates(vkCabinets, 'clientId');

  const mtGroups = checkDuplicates(mtCabinetsConverted, 'clientId');

  return [
    [...mtAccountsConverted], // ...vkAccounts
    [...mtGroups, ...mtCabinetsConverted], // ...vkGroups, ...vkCabinets
  ];
};

const checkEnables = (duplicates: Cabinet[]) => {
  let result = {};

  if (duplicates.some(dup => dup.syncStatusCode === STATUS.ENABLED && dup.statusCode === STATUS.ACTIVE)) {
    result = {
      integrationStatusCode: STATUS.ACTIVE,
      integrationStatus: 'Включена',
      syncStatusCode: STATUS.ENABLED,
      syncStatus: 'Включен',
    };
  } else if (duplicates.some(dup => dup.syncStatusCode === STATUS.ENABLED && dup.statusCode === STATUS.BLOCKED)) {
    result = {
      integrationStatusCode: STATUS.BLOCKED,
      integrationStatus: 'Отключена',
      syncStatusCode: STATUS.ENABLED,
      syncStatus: 'Включен',
    };
  } else if (duplicates.some(dup => dup.syncStatusCode === STATUS.ENABLED && dup.statusCode === STATUS.DELETED)) {
    result = {
      integrationStatusCode: STATUS.DELETED,
      integrationStatus: 'Отключена',
      syncStatusCode: STATUS.ENABLED,
      syncStatus: 'Отключена',
    };
  } else if (duplicates.some(dup => dup.syncStatusCode === STATUS.DISABLED && dup.statusCode === STATUS.ACTIVE)) {
    result = {
      integrationStatusCode: STATUS.ACTIVE,
      integrationStatus: 'Включена',
      syncStatusCode: STATUS.DISABLED,
      syncStatus: 'Выключен',
    };
  } else if (duplicates.some(dup => dup.syncStatusCode === STATUS.DISABLED && dup.statusCode === STATUS.BLOCKED)) {
    result = {
      integrationStatusCode: STATUS.BLOCKED,
      integrationStatus: 'Отключена',
      syncStatusCode: STATUS.DISABLED,
      syncStatus: 'Выключен',
    };
  } else if (duplicates.some(dup => dup.syncStatusCode === STATUS.DISABLED && dup.statusCode === STATUS.DELETED)) {
    result = {
      integrationStatusCode: STATUS.DELETED,
      integrationStatus: 'Отключена',
      syncStatusCode: STATUS.DISABLED,
      syncStatus: 'Отключена',
    };
  }

  return result;
};

type ChangeCabinetProps = Pick<
  Cabinet,
  | 'syncStatusCode'
  | 'syncStatus'
  | 'syncDate'
  | 'integrationStatusCode'
  | 'integrationStatus'
  | 'integrationDate'
  | 'rights'
>;

const changeCabinetFields = (cabinets: Cabinet[], index: number, { ...rest }: ChangeCabinetProps) =>
  runInAction(() => (cabinets[index] = { ...cabinets[index], ...rest }));

export const refreshCabinets = (cabinet: Cabinet, cabinets: Cabinet[]): void => {
  const affectedIndexes: number[] = [];

  cabinets.forEach((item, index) => item.key === cabinet.key && affectedIndexes.push(index));

  const { syncStatus, syncStatusCode, syncDate, integrationDate, integrationStatus, integrationStatusCode } = cabinet;

  const { key, connectionType, rights, ...newGroupData } = cabinet;

  if (affectedIndexes.length === 1) {
    if (cabinets[affectedIndexes[0]].orgHierarchy) {
      // В этом блоке мы понимаем, что элемент с данным условием не является элементов, на основе которого создается группа
      // Меняем состояние элемента, а так же проверяем, являются ли все кабинеты в группе выключенными, если да - выключить элемент, который является группой
      // Если нет, оставить группу включеной

      changeCabinetFields(cabinets, affectedIndexes[0], {
        syncStatus,
        syncStatusCode,
        syncDate,
        integrationStatusCode,
        integrationStatus,
        integrationDate,
        rights,
      });

      const groupIndex = cabinets.findIndex(
        el => el.key === cabinets[affectedIndexes[0]]?.orgHierarchy?.[0] && !el.orgHierarchy,
      );

      const duplicates = cabinets.filter(
        item => cabinets[affectedIndexes[0]].clientId === item.clientId && item.orgHierarchy,
      );

      runInAction(() => {
        cabinets[groupIndex] = {
          ...cabinets[groupIndex],
          ...newGroupData,
          ...checkEnables(duplicates),
        };
      });
    } else {
      // В этом блоке мы работаем с элементами, которые не входят в группировку с другими, т.е. являются одиночными
      changeCabinetFields(cabinets, affectedIndexes[0], {
        syncStatus,
        syncStatusCode,
        syncDate,
        integrationStatusCode,
        integrationStatus,
        integrationDate,
        rights,
      });
    }
  } else if (affectedIndexes.length === 2) {
    // В этом блоке элементов всегда 2, т.к. ПЕРВЫЙ ВСЕГДА элемент, на основе которого создается группа, ВТОРОЙ ВСЕГДА сам кабинет.
    // (так работает принцип построения TreeData в AgGrid)
    // Первым делом меняем сам кабинет, затем проверяем группу на наличие включенных выключенных кабинетов

    changeCabinetFields(cabinets, affectedIndexes[1], {
      syncStatus,
      syncStatusCode,
      syncDate,
      integrationStatusCode,
      integrationStatus,
      integrationDate,
      rights,
    });

    const duplicates = cabinets.filter(
      item => cabinets[affectedIndexes[0]].clientId === item.clientId && item.orgHierarchy,
    );

    runInAction(() => {
      cabinets[affectedIndexes[0]] = {
        ...cabinets[affectedIndexes[0]],
        ...newGroupData,
        ...checkEnables(duplicates),
      };
    });
  }
};

export const returnNetwork = (index: number) => {
  switch (index) {
    case 0:
      return 'myTarget';
    case 1:
      return 'ВКонтакте';
    case 2:
      return 'Facebook';
  }
};
