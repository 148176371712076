import { MoreIcon } from '@plarin/design';
import { Button, Popover, IconButton } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useCallback, useRef, useState } from 'react';
import { WSMemberStatus, WSRole } from '../../../../types/workspace/types';
import { useAppDataStore } from '../../app-data-provider';
import classes from './style.module.scss';

type FooterDrawerProps = {
  action: () => void;
  secondAction: () => void;
  status?: WSMemberStatus;
  idMember: string;
  role: WSRole;
};

export const FooterDrawer = observer(({ secondAction, action, status, idMember, role }: FooterDrawerProps) => {
  const {
    members: { isLoading, editMemberModals, openModalMember, isDrawerDataChanged },
  } = useAppDataStore();

  const [isOpen, setIsOpen] = useState(false);
  const moreIcon = useRef<HTMLDivElement>(null);

  const cellMenuMembers = [
    {
      key: 1,
      action:
        status === 'active'
          ? () => {
              openModalMember('block', idMember);
              setIsOpen(false);
            }
          : () => {
              openModalMember('unBlock', idMember);
              setIsOpen(false);
            },
      name: status === 'active' ? 'Заблокировать' : 'Разблокировать',
    },

    {
      key: 2,
      action: () => {
        setIsOpen(false);
        openModalMember('remove', idMember);
      },
      name: 'Удалить',
    },
  ];

  const onOpen = useCallback((e: React.MouseEvent | Event) => {
    e.stopPropagation();
    // @ts-ignore

    setIsOpen(true);
  }, []);

  const onClose = useCallback((e: React.MouseEvent | Event) => {
    e.stopPropagation();
    setIsOpen(false);
  }, []);

  return (
    <div className={classes.footer}>
      <Button
        size="small"
        variant="filled"
        color="primary"
        onClick={action}
        loading={editMemberModals.idMember ? false : isLoading}
        disabled={!isDrawerDataChanged || (isLoading && !!editMemberModals.idMember)}
      >
        Сохранить
      </Button>
      <Button size="small" variant="outlined" color="primary" onClick={secondAction} disabled={isLoading}>
        Отменить
      </Button>

      {role !== 'wsrole:owner' && (
        <div ref={moreIcon} onClick={onOpen}>
          <IconButton
            variant="outlined"
            color="primary"
            disabled={editMemberModals.idMember ? false : isLoading}
            loading={editMemberModals.idMember ? isLoading : false}
          >
            <MoreIcon color={isLoading ? 'disabled' : 'primary60'} />
          </IconButton>
        </div>
      )}

      <Popover setIsOpen={onClose} anchorEl={moreIcon.current} isOpen={isOpen} dataList={cellMenuMembers} />
    </div>
  );
});
