import { Typography } from '@plarin/design';
import React from 'react';

export const SmsRetryTimeout: React.VFC<{
  seconds: number;
}> = ({ seconds }) => (
  <Typography size="main" color="textSecondary" align="center">
    Вы можете получить код повторно
    <br />
    через {seconds} секунд
  </Typography>
);
