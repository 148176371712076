import { BasicStore, httpClient2 } from '@plarin/core';
import { GridApi } from '@plarin/inputs';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { TGAConnectResp } from '../../types/connect-ga/types';
import {
  ModalData,
  TYDStoreData,
  YDDisableReq,
  YDEnableReq,
  YDRemoveReq,
  YDResponse,
} from '../../types/connect-yd/types';
import { history } from '../components/app';
import { routerPaths } from '../routing/router-path';
import { STATUS } from '../utils/constants';
import { getAccountsIntegrationsStatus, getAccountsItemsSyncStatus } from '../utils/integration-converters';

export class IntegrationsYDStore extends BasicStore {
  data: TYDStoreData[] = [];
  tableLoading: boolean = false;
  tableError: boolean = false;
  searchTableYd: string = '';
  modalData: ModalData = {} as ModalData;
  isLoading: boolean = false;

  constructor() {
    super();
    makeObservable<this>(this, {
      data: observable,
      tableLoading: observable,
      tableError: observable,
      modalData: observable,
      isLoading: observable,
      connectYandexDirectAccount: action.bound,
      checkQueryString: action.bound,
      searchTableYd: observable,
    });
  }

  setTableLoading = (value: boolean) => runInAction(() => (this.tableLoading = value));
  setTableError = (value: boolean) => runInAction(() => (this.tableError = value));
  setsearchTableYd = (value: string) => runInAction(() => (this.searchTableYd = value));

  setModalData = (data: ModalData) => runInAction(() => (this.modalData = data));
  private setIsLoading = (loading: boolean) => runInAction(() => (this.isLoading = loading));

  getData = async () => {
    this.setTableLoading(true);
    this.setTableError(false);

    setTimeout(async () => {
      await this.execRequest<YDResponse[]>(httpClient2.get('/api/v1/connect/yd/accounts'))
        .then(res =>
          runInAction(() => {
            const result = res.reduce((accumulator: TYDStoreData[], account) => {
              // счётчики внутри аккаунта
              if (account.items.length) {
                account.items.forEach(item => {
                  accumulator.push({
                    ...item,
                    key: item.header2,
                    _id: account._id,
                    orgHierarchy: [account.header1, item.header2],
                    syncStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
                    syncStatus: getAccountsItemsSyncStatus(
                      account.plarin_status,
                      account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                    plarin_status: account.plarin_status,
                  });
                });
              }

              // аккаунт
              accumulator.push({
                ...account,
                key: account.header1,
                _id: account._id,
                // name: account._id,
                integrationStatus: getAccountsIntegrationsStatus(
                  account.plarin_status,
                  account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                ),
                isParent: true,
                integrationStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
              });
              return accumulator;
            }, []);
            this.data = result.sort((a, b) => a.header1.localeCompare(b.header1));
          }),
        )
        .catch(e => {
          this.setTableError(!!e);
          this.setTableLoading(false);
        })
        .finally(() => this.setTableLoading(false));
    }, 500);
  };

  connectYandexDirectAccount = async (workspaceShortname: string) => {
    await this.execRequest<TGAConnectResp>(
      httpClient2.get('/api/v1/connect/yd/url', {
        params: {
          url: `${window.location.origin}/${workspaceShortname}${routerPaths.integrations.YANDEX_DIRECT}`,
        },
      }),
    )
      .then(res =>
        runInAction(() => {
          if (res.url) {
            window.location.href = res.url;
          }
        }),
      )
      .catch(e => {});
  };

  checkQueryString = () => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    const error = urlSearchParams.get('error');
    // @ts-ignore
    const errorParams = urlSearchParams && decodeURIComponent(urlSearchParams);
    var regex = /error_string":"([^"]+)"/;
    var matches = errorParams.match(regex);
    var errorString = matches && matches[1];
    errorString = errorString && decodeURIComponent(errorString.replace(/\+/g, ' '));

    // при упехе
    //     code = <код_подтверждения>
    // client_id = <идентификатор_приложения>
    // client_secret = <пароль_приложения>

    if (error) {
      this.addNotification({
        type: STATUS.ERROR,
        title: error,
        message: errorString ? errorString : '',
      });
    }
    if (urlSearchParams.get('client_id')) {
      window.history.replaceState(null, '', window.location.pathname);
      this.addNotification({
        type: STATUS.SUCCESS,
        title: `Аккаунт ${urlSearchParams.get('yd_user_id')} успешно подключен`,
      });
    }
  };

  enableYD = async (data: YDEnableReq, gridApi?: GridApi) => {
    runInAction(
      () =>
        (this.data = this.data.map(el =>
          data.db_ids.includes(el._id)
            ? el.syncStatusCode
              ? { ...el, syncStatusCode: 'loading' }
              : { ...el, integrationStatusCode: 'loading' }
            : el,
        )),
    );

    setTimeout(async () => {
      await this.execRequest<YDResponse[]>(httpClient2.post('/api/v1/connect/yd/clients/enable', data))
        .then(res => {
          res.forEach(account => {
            this.data = this.data.map(el =>
              el._id === account._id
                ? el.syncStatusCode
                  ? {
                      ...el,
                      syncStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
                      syncStatus: getAccountsItemsSyncStatus(
                        account.plarin_status,
                        account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                      ),
                      plarin_status: account.plarin_status,
                    }
                  : {
                      ...el,
                      plarin_status: account.plarin_status,
                      integrationStatus: getAccountsIntegrationsStatus(
                        account.plarin_status,
                        account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                      ),
                      integrationStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
                    }
                : { ...el },
            );
          });

          this.addNotification({
            type: STATUS.SUCCESS,
            title: data.db_ids.length === 1 ? 'Интеграция успешно включена' : 'Интеграции успешно включены',
          });
        })

        .catch(error => {
          console.log(error);
          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          gridApi && gridApi.redrawRows();
        });
    }, 500);
  };

  disableYD = async (data: YDDisableReq, gridApi?: GridApi) => {
    this.setIsLoading(true);
    setTimeout(async () => {
      await this.execRequest<YDResponse[]>(httpClient2.post('/api/v1/connect/yd/clients/disable', data))
        .then(res => {
          res.forEach(account => {
            this.data = this.data.map(el =>
              el._id === account._id
                ? el.syncStatusCode
                  ? {
                      ...el,
                      syncStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
                      syncStatus: getAccountsItemsSyncStatus(
                        account.plarin_status,
                        account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                      ),
                      plarin_status: account.plarin_status,
                    }
                  : {
                      ...el,
                      plarin_status: account.plarin_status,
                      integrationStatus: getAccountsIntegrationsStatus(
                        account.plarin_status,
                        account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                      ),
                      integrationStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
                    }
                : { ...el },
            );
          });

          this.addNotification({
            type: STATUS.SUCCESS,
            title: data.db_ids.length === 1 ? 'Интеграция успешно выключена' : 'Интеграции успешно выключены',
          });
        })
        .catch(error => {
          console.log(error);
          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          this.setIsLoading(false);
          this.setModalData({
            type: '',
            name: [],
            ids: [],
          });
          gridApi && gridApi.redrawRows();
        });
    }, 500);
  };

  removeYD = async (data: YDRemoveReq) => {
    this.setIsLoading(true);
    setTimeout(async () => {
      await this.execRequest<string[]>(httpClient2.post('/api/v1/connect/yd/clients/disconnect', data))
        .then(res => {
          const removeNames = this.data
            .filter(el => el.isParent && data.db_ids.includes(el._id))
            .map(el => el.header1)
            .join(', ');

          runInAction(() => {
            this.data = this.data.filter(el => !data.db_ids.includes(el._id));
          });

          this.addNotification({
            type: STATUS.SUCCESS,
            title: data.db_ids.length === 1 ? `Аккаунт ${removeNames} отключен` : `Аккаунты ${removeNames} отключены`,
          });
        })
        .catch(error => {
          console.log(error);
          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          this.setModalData({
            type: '',
            name: [],
            ids: [],
          });
          this.setIsLoading(false);
        });
    }, 500);
  };
}
