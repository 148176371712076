import { IconButton } from '@mui/material';
import { WorkSpaceIcon } from '@plarin/design';
import React from 'react';

export const WorkflowItemButton = () => {
  return (
    <IconButton>
      <WorkSpaceIcon />
    </IconButton>
  );
};
