import { ReportFilterOp, ReportFilterType } from '../../../../../../types/reports/reports';

type TFilterNameVariants = {
  value: ReportFilterType;
  label: string;
};

type TFilterIncludesVariants = {
  value: ReportFilterOp;
  label: string;
};

export const filterNameVariants: TFilterNameVariants[] = [
  {
    value: 'AD_PLAN_NAME',
    label: 'Название кампании',
  },
];

export const filterIncludesVariants: TFilterIncludesVariants[] = [
  {
    value: 'INCLUDE',
    label: 'Включает',
  },
  {
    value: 'EXCLUDE',
    label: 'Не включает',
  },
];
