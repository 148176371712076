import React from 'react';
import { AuthContentRegTemplate } from '../auth-content-template';
import { AuthSliderLayoutTemplate } from '../auth-layout-template';

export const AuthRegPageTemplate: React.FC = ({ children }) =>
  AuthSliderLayoutTemplate({
    children: AuthContentRegTemplate({
      children,
    }),
  });
