import React from 'react';

interface IconProps {
  className?: string;
  disabled?: boolean;
  size?: number;
}

export const Mt = ({ className, disabled, size }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.40078 10.6551C3.83622 10.4051 2.82451 9.54346 2.35783 8.10423C2.33715 8.03785 2.26961 7.81611 2.23527 7.75014C1.90993 7.12514 1.3751 7.1131 0.933596 7.43764C0.604762 7.67935 0.608144 8.06264 0.682968 8.42723C0.694577 8.4838 0.706557 8.52369 0.73353 8.59962C0.760504 8.67555 1.25935 9.44411 1.38529 9.64877C1.50169 9.84343 1.63151 10.0306 1.77364 10.2083C3.51055 12.374 6.76614 12.7921 9.04472 11.1412C10.6988 9.93065 11.3501 8.29783 11.0413 6.33206C11.0401 6.28526 11.0334 6.23952 11.0234 6.19377C10.9327 5.80126 10.5242 5.55235 10.1113 5.63851C9.69829 5.72467 9.43641 6.11293 9.52707 6.50545C9.53714 6.64905 9.55728 6.79159 9.57295 6.93519C9.79678 9.09669 7.6525 11.0167 5.40078 10.6551Z"
        fill={disabled ? '#C4CAD4' : '#D81823'}
      />
      <path
        d="M8.42804 3.22371C8.36313 2.71419 8.58584 2.20998 9.01224 1.89618C9.32895 1.62706 9.6121 1.31645 9.92658 1.04201C10.0441 0.967544 10.1739 0.911167 10.3093 0.875C10.3653 0.986692 10.4045 1.10477 10.4268 1.22709C10.4425 1.65684 10.4391 2.08659 10.4268 2.51846C10.4268 2.8195 10.5667 2.95246 10.8834 2.95034H12.2779C12.3943 2.96948 12.5073 3.00565 12.6125 3.05671C12.5823 3.17691 12.5319 3.29073 12.4614 3.39391C12.091 3.76728 11.6959 4.1215 11.3199 4.48849C11.0614 4.74485 10.6876 4.86398 10.3194 4.80654C9.80459 4.73208 9.28866 4.93419 8.97866 5.33309C8.58248 5.76496 8.11804 6.13833 7.70172 6.5585C7.58421 6.68509 7.52041 6.8489 7.52041 7.01697C7.58644 7.85413 6.92503 8.58278 6.04538 8.64448C5.16462 8.70724 4.398 8.07857 4.33309 7.24248C4.32861 7.19036 4.32749 7.13717 4.32973 7.08505C4.35995 6.26172 5.08739 5.6171 5.95361 5.64582C5.99726 5.64689 6.04202 5.65008 6.08567 5.6554C6.30726 5.6905 6.53333 5.61285 6.68106 5.45116C7.10745 5.02886 7.53608 4.60656 7.99605 4.21617C8.33068 3.94492 8.53548 3.65346 8.42804 3.22371Z"
        fill={disabled ? '#C4CAD4' : '#D81823'}
      />
      <path
        d="M1.24086 5.08004C1.28451 4.95133 1.33935 4.82688 1.40426 4.70668C2.50997 2.89727 4.69118 1.93992 6.85785 2.31435C7.38608 2.41115 7.68489 2.75154 7.60879 3.17384C7.53269 3.59614 7.13092 3.84825 6.58701 3.75357C5.09743 3.49402 3.59218 4.12588 2.79088 5.3481C2.72485 5.46405 2.64763 5.57361 2.56145 5.67573C2.34994 5.91294 1.9974 5.98634 1.70083 5.85338C1.39642 5.73105 1.23527 5.49596 1.24086 5.08004Z"
        fill={disabled ? '#C4CAD4' : '#D81823'}
      />
    </svg>
  );
};
