import * as React from 'react';

interface IAuthorizeProps {
  children: React.ReactNode;
  isAuthorized?: boolean;
}
interface IState {}

export class UnAuthorizedOnly extends React.Component<IAuthorizeProps, IState> {
  public render() {
    const { children, isAuthorized } = this.props;
    if (isAuthorized) {
      return null;
    }
    return children;
  }
}
