import { HelpIcon, PlusCircleIcon, Typography } from '@plarin/design';
import { MainTooltip } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useState, useMemo } from 'react';
import { useAppDataStore } from '../../../../../components/app-data-provider';
import { tooltipTexts } from '../../utils';
import classes from './style.module.scss';
import { TagLine } from './tag-line';
import { TTag, TTagsLayoutProps } from './types';

export const TagsLayout = observer(({ tagsType }: TTagsLayoutProps) => {
  const {
    members: { drawerData, editDrawerData, editMemberModals },
    profileStore: { WSRole },
  } = useAppDataStore();

  const { userData } = drawerData;

  const [newTags, setNewTags] = useState<TTag[]>([]);

  const canChange = WSRole === 'wsrole:owner' || WSRole === 'wsrole:admin';

  const addNewTags = () => {
    const timestamp = Date.now();
    setNewTags(prev => [{ name: '', id: `tag-${timestamp}`, edit: true, isChanged: true }, ...prev]);
  };

  const saveTag = (value: string, id: string, lastTag?: string) => {
    lastTag &&
      drawerData.workspaceTags[tagsType]?.includes(lastTag) &&
      editDrawerData({
        ...drawerData,
        workspaceTags: {
          ...drawerData.workspaceTags,
          [tagsType]: drawerData.workspaceTags[tagsType]!.filter(el => el !== lastTag),
        },
      });

    const data = newTags.map(el =>
      id === el.id
        ? { ...el, name: value, edit: false, isChanged: !el.isChanged || el.name === value ? false : true }
        : el,
    );
    setNewTags(data);

    editDrawerData({
      ...drawerData,
      userData: { ...drawerData.userData, [tagsType]: data.map(el => el.name) },
      workspaceTags: {
        ...drawerData.workspaceTags,
        [tagsType]:
          lastTag && drawerData.workspaceTags[tagsType]?.includes(lastTag)
            ? drawerData.workspaceTags[tagsType]!.filter(el => el !== lastTag)
            : [...drawerData.workspaceTags[tagsType]!, value],
      },
    });
  };

  const deleteTag = (id: string) => {
    const tag = newTags.filter(el => el.id === id)[0].name;
    const data = newTags.filter(el => el.id !== id);
    setNewTags(data);
    editDrawerData({
      ...drawerData,
      userData: { ...drawerData.userData, [tagsType]: data.map(el => el.name) },
      workspaceTags: {
        ...drawerData.workspaceTags,
        [tagsType]: drawerData.workspaceTags[tagsType]!.filter(el => el !== tag),
      },
    });
  };

  const editTag = (id: string) => setNewTags(prev => prev.map(el => (el.id === id ? { ...el, edit: true } : el)));

  useEffect(() => {
    userData[tagsType] && setNewTags(prev => prev.map(el => ({ ...el, edit: false })));
  }, [userData, editMemberModals]);

  useEffect(() => {
    userData[tagsType] &&
      setNewTags(
        userData[tagsType]!.map((el, index) => ({ name: el, id: index.toString(), edit: false, isChanged: false })),
      );
  }, []);

  return (
    <div className={classes.blockWrapper}>
      <div className={classes.header}>
        <Typography color="TextPrimary" size="Main" weight={600} componentProps={{ className: classes.headerText }}>
          {tagsType === 'tag_account' ? 'Метки в названии кабинетов' : 'Метки в названии кампаний'}

          <MainTooltip tooltipMessage={tooltipTexts[tagsType]} component="span" isVisible={true} maxWidth={280}>
            <HelpIcon />
          </MainTooltip>
        </Typography>

        {canChange && !!newTags.length && (
          <Typography
            color="Primary60"
            size="AuthContent"
            weight={600}
            componentProps={{ className: clsx(classes.headerText, classes.headerTextClickable), onClick: addNewTags }}
          >
            Добавить метку <PlusCircleIcon color="primary60" />
          </Typography>
        )}
      </div>
      <div className={classes.content}>
        {!newTags.length && (
          <>
            <Typography color="TextSecondary" size="AuthContent">
              У пользователя отсутствуют метки в названии
              <br />
              рекламных {tagsType === 'tag_account' ? 'кабинетов' : 'кампаний'}.
            </Typography>
            {canChange && (
              <Typography
                color="Primary60"
                size="AuthContent"
                weight={600}
                componentProps={{
                  className: clsx(classes.headerText, classes.headerTextClickable),
                  onClick: addNewTags,
                }}
              >
                Добавить метку <PlusCircleIcon color="primary60" />
              </Typography>
            )}
          </>
        )}
        <div className={classes.contentList}>
          {newTags.map(el => (
            <TagLine
              tag={el}
              key={el.id}
              saveTag={saveTag}
              deleteTag={deleteTag}
              editTag={editTag}
              canChange={canChange}
              isChanged={el.isChanged}
              workspaceTags={drawerData.workspaceTags[tagsType]}
            />
          ))}
        </div>
      </div>
    </div>
  );
});
