import { Typography } from '@plarin/design';
import { Input } from '@plarin/inputs';
import { InputNameErrors, ValidatorLength255 } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useAppDataStore } from '../../../../app-data-provider';
import classes from '../../project-drawer.module.scss';

export const ProjectName = observer(() => {
  const {
    projects: { projectsState, setProjectsState, editProject, setIsDrawerDataChanged },
  } = useAppDataStore();

  const [isLabelStatic, setIsLabelStatic] = useState(false);

  const isCreateProject = !editProject.editProjectData;

  useEffect(() => {
    setIsDrawerDataChanged(false);
  }, []);

  useEffect(() => {
    editProject.editProjectData && setProjectsState('projectsName', editProject.editProjectData?.name);
  }, [editProject, setProjectsState]);

  // нужно, чтобы после исчезновения скелетона лэйбл этого инпута стоял на месте, без анимации
  useEffect(() => {
    if (!isCreateProject) {
      setIsLabelStatic(true);
      setTimeout(() => {
        setIsLabelStatic(false);
      }, 500);
    }
  }, [isCreateProject]);

  const isError = !ValidatorLength255(projectsState.projectsName)[0];
  return (
    <div className={clsx(classes.inputCount, isLabelStatic && classes.staticLabel)}>
      <Input
        inputRootWrapperDefault
        deactivatedMessage={projectsState.projectsName}
        size={48}
        maxLength={42}
        maxCounterValue={42}
        value={projectsState.projectsName}
        onChange={e => {
          setProjectsState('projectsName', e);
        }}
        label="Название проекта"
        hint={isError ? InputNameErrors.bigName255 : ''}
        error={isError}
        onBlur={() => {
          setProjectsState('projectsName', projectsState.projectsName.trim());
        }}
      />
    </div>
  );
});
