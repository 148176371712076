export enum EditListEnum {
  bid = 'bid',
  limit = 'limit',
  name = 'name',
  timeTable = 'timeTable',
}

export enum ActionKeysEnum {
  divider = '0',
  start = '1',
  pause = '2',
  archive = '3',
  reestablish = '4',
  bid = '5',
  limit = '6',
  name = '7',
  timetable = '8',
}
