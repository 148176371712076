import { DefaultInput } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React from 'react';
import { TShortCabinetsFilters } from '../../../../../../../../types/projects/projects';
import { useAppDataStore } from '../../../../../../app-data-provider';
import classes from '../../styles.module.scss';
import { ProjectSearchResults } from './project-search-results';

type TPopupCabinetProps = {
  selectedCabinets: TShortCabinetsFilters[];
};

export const PopupCabinets = observer(({ selectedCabinets }: TPopupCabinetProps) => {
  const {
    projects: { shortCabinets, setProjectsState, projectsState },
  } = useAppDataStore();

  const changeCabinetSelection = (cabinet: TShortCabinetsFilters) => {
    if (!!selectedCabinets.find(item => item.client_id === cabinet.client_id)) {
      setProjectsState(
        'selectedCabinets',
        selectedCabinets.filter(item => item.client_id !== cabinet.client_id),
      );
    } else {
      setProjectsState('selectedCabinets', [...selectedCabinets, cabinet]);
    }
  };

  return (
    <div className={classes.cabinetsList}>
      <div className={classes.search}>
        <DefaultInput
          value={projectsState.searchValueCabinet}
          setValue={el => setProjectsState('searchValueCabinet', el)}
          placeholder="Поиск рекламного кабинета"
          searchIcon
          clearInputAction={() => setProjectsState('searchValueCabinet', '')}
        />
      </div>
      <ProjectSearchResults
        shortCabinets={shortCabinets}
        filteredCabinets={projectsState.filteredCabinets}
        selectedCabinets={selectedCabinets}
        changeCabinetSelection={changeCabinetSelection}
        searchValue={projectsState.searchValueCabinet}
      />
    </div>
  );
});
