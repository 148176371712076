import { Typography } from '@plarin/design';
import { getMemberName, shortDate } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import { EllipsisTooltip } from '../../../../tooltip';
import classes from '../style.module.scss';

type TLinesMember = {
  fname?: string;
  lname?: string;
  email: string;
  created?: string;
};

export const LinesMember = ({ fname, lname, email, created }: TLinesMember) => {
  return (
    <div className={classes.lines}>
      {created ? (
        <>
          <div className={clsx(classes.first)}>
            <EllipsisTooltip tooltipMessage={email}>
              <Typography ellips={true} weight={600} color="TextSecondary">
                {email}
              </Typography>
            </EllipsisTooltip>
          </div>
          <div className={clsx(classes.second)}>
            <EllipsisTooltip tooltipMessage={`Приглашён ${shortDate(created)}`}>
              <Typography ellips={true} size="TableRowSubHeader" color="TextSecondary">{`Приглашён ${shortDate(
                created,
              )}`}</Typography>
            </EllipsisTooltip>
          </div>
        </>
      ) : (
        <>
          <div className={clsx(classes.first)}>
            <EllipsisTooltip tooltipMessage={getMemberName({ fname, lname, email })} maxWidth={480}>
              <Typography ellips={true} weight={600}>
                {getMemberName({ fname, lname, email })}
              </Typography>
            </EllipsisTooltip>
          </div>
          <div className={clsx(classes.second)}>
            <EllipsisTooltip tooltipMessage={email}>
              <Typography ellips={true} size="TableRowSubHeader" color="TextSecondary">
                {email}
              </Typography>
            </EllipsisTooltip>
          </div>
        </>
      )}
    </div>
  );
};
