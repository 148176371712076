import { SmallArrowDownIcon, Typography } from '@plarin/design';
import { Button, Popover, GridApi, TRows } from '@plarin/inputs';
import { ManageVkTabNameEnum, sendMetricGoal } from '@plarin/utils';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { MTStatus } from '../../../types/manage-vk/types';
import { returnId } from '../../utils/manage-vk';
import { useAppDataStore } from '../app-data-provider';
import { EditListEnum } from './commonEnums';
import { EditBid } from './edit-bid';
import { EditMenuLayout } from './edit-menu-layout';
import { editMenuList } from './edit-menu-list';
import classes from './style.module.scss';

type ManageVkEditMenuProps = {
  disabled?: boolean;
  gridApi: GridApi;
  tabName: ManageVkTabNameEnum;
};

type TVisionBox = string | EditListEnum;

const editRenderer = (visionBox: string, close: () => void, selectedRows: TRows, gridApi: GridApi) => {
  switch (visionBox) {
    case EditListEnum.bid:
      return <EditBid close={close} selectedRows={selectedRows} gridApi={gridApi} />;
    // TODO: когда появятся новые пункты в меню, появятся новые кейсы в свитче
    default:
      return <></>;
  }
};

export const ManageVkEditMenu = ({ disabled, gridApi, tabName }: ManageVkEditMenuProps) => {
  const {
    manageVK: { changeManageVkStatus },
  } = useAppDataStore();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [visionBox, setVisionBox] = useState<TVisionBox>('');

  const [selectedRows, setSelectedRows] = useState<TRows>([]);

  useEffect(() => {
    gridApi &&
      setSelectedRows(
        gridApi
          .getSelectedRows()
          .filter(el =>
            new Set(Object.keys(el)).has('orgHierarchy') ? !!el.orgHierarchy && el.orgHierarchy.length : true,
          ),
      );
  }, [gridApi, anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setVisionBox('');
    setAnchorEl(null);
  };

  const stopPropagation = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  };

  const idsCampsByStatus = useCallback(
    (status: MTStatus) => {
      switch (status) {
        case 'active':
          return selectedRows.filter(camp => camp.status.includes('Останов')).map(camp => camp[returnId(tabName)]);
        case 'blocked':
          return selectedRows.filter(camp => camp.status.includes('Актив')).map(camp => camp[returnId(tabName)]);
        case 'deleted':
          return selectedRows
            .filter(camp => camp.status.includes('Актив') || camp.status.includes('Останов'))
            .map(camp => camp[returnId(tabName)]);
        case 'recovery':
          return selectedRows.filter(camp => camp.status.includes('Архив')).map(camp => camp[returnId(tabName)]);
      }
    },
    [selectedRows],
  );

  const changeStatus = (status: MTStatus, gridApi: GridApi) => {
    const ids = idsCampsByStatus(status).map(el => +el);
    const newStatus = status === 'recovery' ? 'blocked' : status;
    changeManageVkStatus(ids, newStatus, tabName, gridApi).then();
    handleClose();
  };

  const getSelectedAmount = (status?: MTStatus) => {
    const amount = status ? idsCampsByStatus(status).length : selectedRows?.length;

    return amount ? `(${status ? idsCampsByStatus(status).length : selectedRows?.length})` : '';
  };

  const dataList = editMenuList
    .filter((el, index) => el.key !== '0' && index !== editMenuList.length - 1)
    .map(el => {
      return {
        name: `${el.label} ${getSelectedAmount(el.status)}`,
        action: () => {
          handleClose();
          el.typeEdit
            ? visionBox === el.typeEdit
              ? setVisionBox('')
              : setVisionBox(el.typeEdit)
            : el.status && changeStatus(el.status, gridApi);
          // Отправляем конверсию в Яндекс.Метрику
          sendMetricGoal('usage_vk_massaction_change_status', 'manage/vk');
        },
        iconLeft: (e: boolean | undefined) => <span className={clsx(e && classes.iconLeftColor)}>{el.icon}</span>,
        isDisabled:
          (!el.status &&
            ((selectedRows.length === 1 && selectedRows[0].status.includes('Архивн')) ||
              selectedRows.length - selectedRows.filter(val => val.status.includes('Архивн')).length === 0)) ||
          (el.status && !idsCampsByStatus(el.status).length) ||
          disabled,
      };
    });

  const footerItem = () => {
    const lastItem = editMenuList[editMenuList.length - 1];

    return {
      name: `${lastItem.label} ${
        !selectedRows.filter(val => val.status.includes('Архивн')).length ? getSelectedAmount(lastItem.status) : ''
      }`,
      icon: lastItem.rightIcon,
      typeEdit: lastItem.typeEdit,
      status: lastItem.status,
      disabled:
        (!lastItem.status &&
          ((selectedRows.length === 1 && selectedRows[0].status.includes('Архивн')) ||
            selectedRows.length - selectedRows.filter(val => val.status.includes('Архивн')).length === 0)) ||
        (lastItem.status && !idsCampsByStatus(lastItem.status).length) ||
        disabled,
    };
  };

  const footerItemJSX = () => {
    const { icon, typeEdit, status, name, disabled } = footerItem();
    return (
      <>
        <span
          className={clsx(classes.footerList, disabled && classes.footerListDisabled)}
          onClick={() => {
            !disabled &&
              (typeEdit
                ? visionBox === typeEdit
                  ? setVisionBox('')
                  : setVisionBox(typeEdit)
                : status && changeStatus(status, gridApi));
            // Отправляем конверсию в Яндекс.Метрику
            sendMetricGoal('usage_vk_massaction_change_bid', 'manage/vk');
          }}
        >
          <Typography size="Main" weight={400}>{`${name}`}</Typography>
          {icon}
        </span>
        <EditMenuLayout visionBox={visionBox}>
          {editRenderer(visionBox, handleClose, selectedRows, gridApi)}
        </EditMenuLayout>
      </>
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <Button
        onClick={handleClick}
        color="secondary"
        disabled={disabled}
        active={!!anchorEl}
        className={classes.selectButton}
      >
        <div className={classes.buttonContent}>
          <Typography weight={600} size="Main" color={disabled ? 'TextDisabled' : 'TextPrimary'}>
            Редактировать
          </Typography>
          <SmallArrowDownIcon className={clsx(!!anchorEl && classes.rotate)} color={disabled ? 'disabled' : 'main'} />
        </div>
      </Button>

      <Popover
        footer={footerItemJSX()}
        setIsOpen={handleClose}
        anchorEl={anchorEl}
        isOpen={!!anchorEl}
        dataList={dataList}
        classNameWrapper={classes.wrapperList}
      />
    </div>
  );
};
