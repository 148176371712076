export const PeriodGroupsVariants = [
  {
    value: 'day',
    label: 'По дням',
  },
  {
    value: 'week',
    label: 'По неделям',
  },
  {
    value: 'month',
    label: 'По месяцам',
  },
  {
    value: 'noGroup',
    label: 'Без группировки',
  },
];
