import { DatePickerEnum, GridApi, MENU_ITEM_LABEL, TDateOption } from '@plarin/inputs';
import { ObjectValue, shortDateTime } from '@plarin/utils';
import { formatISO9075 } from 'date-fns';
import moment from 'moment';
import {
  DatePeriod,
  TCreateReports,
  TInitialStateDrawer,
  TRelativePeriod,
  TReportData,
  TShortClientsData,
} from '../../types/reports/reports';

export const toRequestFormatPeriod = (label: string): TRelativePeriod | null => {
  return DatePickerEnum.reduce(
    (acc: TRelativePeriod | null, elem) => (elem.label === label ? (elem.value as TRelativePeriod) : acc),
    null,
  );
};

export const toResponseFormatPeriod = (label: TRelativePeriod): ObjectValue<typeof MENU_ITEM_LABEL> | null => {
  return DatePickerEnum.reduce((acc: ObjectValue<typeof MENU_ITEM_LABEL> | null, elem) => {
    return elem.value === label ? (elem.label as ObjectValue<typeof MENU_ITEM_LABEL>) : acc;
  }, null);
};

export const dateAdapter = ({ label, startDate, endDate }: TDateOption) => {
  const relativePeriod = toRequestFormatPeriod(label);
  return relativePeriod
    ? {
        relative_period: relativePeriod,
        date_from: null,
        date_to: null,
      }
    : {
        date_from: formatISO9075(startDate),
        date_to: formatISO9075(endDate),
        relative_period: null,
      };
};

export const periodDatePickerAdapter = ({ storeStartDate, storeLabel, storeEndDate }: Partial<DatePeriod>) => {
  return {
    storeStartDate: storeStartDate ? +new Date(storeStartDate) : 0,
    storeEndDate: storeEndDate ? +new Date(storeEndDate) : 0,
    storeLabel: (storeLabel && toResponseFormatPeriod(storeLabel)) || 'За определенный период',
  };
};

export const requestReportAdapter = (data: TInitialStateDrawer): TCreateReports => {
  const group = data.periodGroups === 'noGroup' ? { group_by: null } : { group_by: data.periodGroups };
  const date = data.relative_period
    ? { relative_period: data.relative_period }
    : { date_from: moment(data.date_from).format('YYYY-MM-DD'), date_to: moment(data.date_to).format('YYYY-MM-DD') };

  return {
    name: data.name ? data.name : data.defaultName,
    fields: data.fields ? data.fields : [],
    client_ids: data.reportClients.map(elem => elem.account_id),
    network: 'mt',
    exclude_no_stat: data.exclude_no_stat,
    statuses: data.statuses && data.statuses.length ? data.statuses : [0, 1, 2],
    entities: data.entities ? data.entities : [],
    filters: data.filters ? data.filters : [],
    ...group,
    ...date,
  } as TCreateReports; // Убрать после добавления типа null для group_by на бэке
};

export const responseReportAdapter = (data: TReportData): TInitialStateDrawer => {
  return {
    name: data.name,
    defaultName: `Новый отчет, ${shortDateTime(Date.now())}`,
    clientsFilterValue: '',
    fields: data.fields,
    date_from: data.date_from ? data.date_from : null,
    date_to: data.date_to ? data.date_to : null,
    relative_period: data.relative_period ? data.relative_period : null,
    reportClients: data.clients.map(elem => {
      return {
        account_name: elem.client_name || elem.username,
        account_id: elem.id,
        network: elem.network,
        account_username: elem.username,
        has_error: elem.has_error,
      };
    }),
    statuses: data.statuses,
    entities: data.entities,
    periodGroups: data.group_by ? data.group_by : 'noGroup',
    filters: data.filters,
    exclude_no_stat: data.exclude_no_stat,
  };
};

export const createReportClientsDrawer = (gridApi: GridApi) => {
  const accountsHash: { [keys: number]: boolean } = {};

  return gridApi.getSelectedRows().reduce((acc: TShortClientsData, elem) => {
    if (!accountsHash[elem.accountId]) {
      acc.push({
        account_name: elem.accountName || elem.accountUsername,
        account_id: elem.accountId,
        network: elem.network,
        account_username: elem.accountUsername,
      });
    }
    accountsHash[elem.accountId] = true;
    return acc;
  }, []);
};
