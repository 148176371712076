import { TooltipProps } from '@mui/material';
import React from 'react';

export interface ITooltipProps<TComponent extends React.ElementType> {
  content?: NonNullable<React.ReactNode>;
  children: React.ReactElement;
  component: TComponent;
  componentProps?: React.ComponentProps<TComponent>;
  placement?: TooltipProps['placement'];
  needPaddings?: boolean;
}

export interface TableTooltipProps<TComponent extends React.ElementType> {
  content: NonNullable<React.ReactNode>;
  children: React.ReactElement;
  component: TComponent;
  componentProps?: React.ComponentProps<TComponent>;
  open?: boolean;
}

export interface IMainTooltip<TComponent extends React.ElementType> {
  tooltipMessage: NonNullable<React.ReactNode>;
  children: React.ReactElement;
  component: TComponent;
  componentProps?: React.ComponentProps<TComponent>;
  isVisible: boolean;
  placement?: TooltipProps['placement'];
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  componentFeature?: 'textEllips' | 'toggleButton';
  componentClassName?: string;
  maxWidth?: number;
  followCursor?: boolean;
  isTypograf?: boolean;
  typografType?: 'default' | 'names';
}
