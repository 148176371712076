import { Text, Typography } from '@plarin/design';
import React from 'react';
import { Link } from 'react-router-dom';
import { routerPaths } from '../../../routing/router-path';
import { AuthContentTemplate } from './auth-content-template';

export const AuthContentRegTemplate: React.FC = ({ children }) =>
  AuthContentTemplate({
    children,
    footer: (
      <Text color="textTertiary" size="s">
        Уже зарегистрированы?{' '}
        <Typography underline color="linkPrimary" component={Link} componentProps={{ to: routerPaths.unAuth.LOGIN }}>
          Войти
        </Typography>
      </Text>
    ),
  });
