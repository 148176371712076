import { YandexMailIcon, YandexMailTextIcon } from '@plarin/design';
import React from 'react';
import { SocialButton } from './social-button';
import { SocialConfiguredButtonProps } from './social-configured-button-props';

export const SocialYandexButton: React.FC<SocialConfiguredButtonProps> = function SocialYandexButton({ onClick }) {
  return (
    <SocialButton icon={<YandexMailIcon />} onClick={onClick}>
      <YandexMailTextIcon />
    </SocialButton>
  );
};
