import { InputAdornment, TextField, Divider } from '@mui/material';
import { SearchIcon, LightningIcon } from '@plarin/design';
import React from 'react';
import './input-search.scss';

export const SearchInput = () => {
  return (
    <TextField
      placeholder="Поиск"
      variant="outlined"
      fullWidth
      InputProps={{
        classes: {
          root: 'inputSearch',
          input: 'inputInputSearch',
          notchedOutline: 'visuallyHidden',
          focused: 'inputFocused',
          adornedStart: 'inputAdorned',
        },
        startAdornment: (
          <InputAdornment
            position="start"
            classes={{
              root: 'searchInputAdornment',
            }}
          >
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            classes={{
              root: 'searchInputAdornmentEnd',
            }}
          >
            <Divider
              orientation="vertical"
              classes={{
                root: 'adornmentDivider',
              }}
            />
            <LightningIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
