import { components } from '../../../types/profile/apispec';

export type TPasswordCheckReq = components['schemas']['PasswordComplexityReq'];

export type TPasswordCheckResp = components['schemas']['ResponseModel_PasswordComplexityRateResp_'];

export type TPasswordComplexityRate = components['schemas']['PasswordComplexityRate'];

export type TNewPasswordReq = components['schemas']['NewPasswordReq'];

export type TNewPasswordResp = components['schemas']['ResponseModel_Dict_'];

export type TResetPasswordReq = components['schemas']['ResetPasswordReq'];

export type TResetPasswordResp = components['schemas']['ResponseModel_LoginResp_'];

export type TActivatePasswordTokenReq = components['schemas']['ActivatePasswordTokenReq'];

export type TActivatePasswordTokenResp = components['schemas']['ResponseModel_Dict_'];
