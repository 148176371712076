// export function autoLayoutKeyboard( str ) {
//    const replacer = {
//         "q":"й", "w":"ц", "e":"у", "r":"к", "t":"е", "y":"н", "u":"г",
//         "i":"ш", "o":"щ", "p":"з", "[":"х", "]":"ъ", "a":"ф", "s":"ы",
//         "d":"в", "f":"а", "g":"п", "h":"р", "j":"о", "k":"л", "l":"д",
//         ";":"ж", "'":"э", "z":"я", "x":"ч", "c":"с", "v":"м", "b":"и",
//         "n":"т", "m":"ь", ",":"б", ".":"ю", "/":"."
//     };

//     return str.replace(/[A-z/,.;\'\]\[]/g, function ( x ){
//         return x == x.toLowerCase() ?  replacer[ x ] : replacer[ x.toLowerCase() ].toUpperCase();
//     });
// }

const EN_LAYOUT = '`1234567890-=qwertyuiop[]\\asdfghjkl;\'zxcvbnm,./~QWERTYUIOP{}|ASDFGHJKL:"ZXCVBNM<>?';
const RU_LAYOUT = 'ё1234567890-=йцукенгшщзхъ\\фывапролджэячсмитьбю.ЁЙЦУКЕНГШЩЗХЪ/ФЫВАПРОЛДЖЭЯЧСМИТЬБЮ,';

const mapLayouts = (sourceLayout: string, targetLayout: string): Record<string, string> => {
  if (sourceLayout.length !== targetLayout.length) {
    throw new Error('Different length of layouts');
  }

  const result: Record<string, string> = {};

  for (let i = 0; i < sourceLayout.length; i++) {
    result[sourceLayout[i]] = targetLayout[i];
  }

  return result;
};

const EN_RU_MAP = mapLayouts(EN_LAYOUT, RU_LAYOUT);
const RU_EN_MAP = mapLayouts(RU_LAYOUT, EN_LAYOUT);

const switchLayout = (source: string, layoutMap: Record<string, string>): string => {
  return Array.from(source)
    .map(char => layoutMap[char] ?? char)
    .join('');
};

export const switchEnToRu = (source: string) => switchLayout(source, EN_RU_MAP);

export const switchRuToEn = (source: string) => switchLayout(source, RU_EN_MAP);
