import { AUTH_ACCESS_TOKEN, AUTH_REFRESH_TOKEN } from '../constants';
import { AppLocalStorage, AppSessionStorage } from './app-local-storage';
import moment from 'moment';

export type TInitAppProps = {
  name: string;
};

export type TAppUtils = {
  saveAuthTokens: (access: string, refresh: string, refresh_expires?: string) => Promise<void>;
  removeAuthTokens: () => Promise<void>;
  appLocalStorage: AppLocalStorage;
  appSessionStorage: AppSessionStorage;
};

export function initAppUtils({ name }: TInitAppProps): TAppUtils {
  const appLocalStorage = new AppLocalStorage(`${name}_storage`);
  const appSessionStorage = new AppSessionStorage(`${name}_storage`);

  const saveAuthTokens = async (access: string, refresh: string, refresh_expires?: string) => {
    appLocalStorage.set(AUTH_ACCESS_TOKEN, access);
    appLocalStorage.set(AUTH_REFRESH_TOKEN, refresh);
    refresh_expires && localStorage.setItem('expires', moment(refresh_expires).unix().toString());
  };

  const removeAuthTokens = async () => {
    appLocalStorage.set(AUTH_ACCESS_TOKEN, null);
    appLocalStorage.set(AUTH_REFRESH_TOKEN, null);
    localStorage.removeItem('remainSystem');
    localStorage.removeItem('expires');
  };

  return {
    saveAuthTokens,
    removeAuthTokens,
    appLocalStorage,
    appSessionStorage,
  };
}
