import { AttachFileIcon, Typography } from '@plarin/design';
import clsx from 'clsx';
import React, { useCallback, useState, useEffect } from 'react';
import { Accept, ErrorCode, FileError, FileRejection, useDropzone } from 'react-dropzone';
import { getSklonenie } from '../_utils';
import { SingleFileUpload } from './singleFileUpload';
import classes from './style.module.scss';

export interface UploadableFile {
  file: File;
  errors: FileError[];
}

type UploaderProps = {
  accept?: Accept;
  maxFiles?: number;
  maxSize?: number;
  updateData: ({}) => void;
  className?: string;
};

const acceptFiles = {
  'image/jpeg': [],
  'image/png': [],
  'image/gif': [],
  'application/pdf': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
};

export const MultipleFileUploader = ({
  maxFiles = 3,
  accept = acceptFiles,
  maxSize = 10485760,
  updateData,
  className,
}: UploaderProps) => {
  const [files, setFiles] = useState<UploadableFile[]>([]);
  const [isHovered, setIsHovered] = useState(false);

  const isDisabled = maxFiles - 1 < files.length;
  const hasFiles = files.length > 0;
  const onDelete = (file: File) => {
    return setFiles(curr => curr.filter(fileWrapper => fileWrapper.file !== file));
  };
  const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
    const mappedAcc = accFiles.map(file => ({ file, errors: [] }));
    setFiles(current => [...current, ...mappedAcc, ...rejFiles]);
    setIsHovered(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    disabled: isDisabled,
    maxFiles: maxFiles - files.length,
    maxSize: maxSize,
    onDrop,
    accept: accept,
    onDragEnter: () => setIsHovered(true),
    onDragLeave: () => setIsHovered(false),
  });

  if (files.length > maxFiles) {
    setFiles(files.slice(0, maxFiles));
  }

  useEffect(() => {
    files &&
      updateData({ files: files.map(el => (el.errors.find(i => i.code === ErrorCode.TooManyFiles) ? el.file : el)) });
  }, [files]);

  return (
    <div className={clsx(className, classes.container)}>
      <div
        {...getRootProps({ className: classes.dropZone })}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={clsx(classes.uploadZone, isHovered && classes.uploadZoneEnter)}>
          <input id="inputDropzone" {...getInputProps()} />

          <AttachFileIcon color={isDisabled ? 'disabled' : 'primary60'} className={classes.icon} />
          <Typography color={isDisabled ? 'textTertiary' : 'textPrimary'} size="main">
            Перетащите файл в эту область или{' '}
            <span
              className={clsx(
                classes.textBlue,
                isDisabled && classes.addTertiaryColor,
                isHovered && classes.textDarkBlue,
              )}
            >
              загрузите
            </span>
          </Typography>
        </div>

        <div className={classes.subtitle}>
          {hasFiles && (
            <Typography size="SmallText" color="textSecondary">
              Вы можете прикрепить {maxFiles} {getSklonenie(maxFiles, ['файл', 'файла', 'файлов'])}
            </Typography>
          )}
        </div>
      </div>

      <div className={classes.filesBlock}>
        {files.map((fileWrapper, index) => {
          return (
            <SingleFileUpload
              key={fileWrapper.file.name + index}
              errors={fileWrapper.errors}
              onDelete={onDelete}
              file={fileWrapper.file}
              accept={accept}
              maxSize={maxSize}
            />
          );
        })}
      </div>
    </div>
  );
};
