import { GridApi } from '@plarin/inputs';
import { switchFilter } from '@plarin/utils';
import { useEffect } from 'react';

export const useFilter = (filterValue?: string, gridApi?: GridApi) => {
  useEffect(() => {
    if (gridApi) {
      setTimeout(() => {
        const wordsSearchMetrics = filterValue && filterValue.replace(/\s+/g, ' ').trim().split(' ');

        if (wordsSearchMetrics && wordsSearchMetrics.length > 1) {
          const resFilter = wordsSearchMetrics.map(elem => {
            return elem.trim();
          });

          gridApi.setQuickFilter(resFilter.join(' '));

          if (gridApi.getDisplayedRowCount() === 0) {
            const switchedPhrase = resFilter.map(elem => switchFilter(elem));
            gridApi.setQuickFilter(switchedPhrase.join(' '));
          }
        } else {
          gridApi.setQuickFilter(filterValue || '');

          if (gridApi.getDisplayedRowCount() === 0) {
            const switchFilteredValue = filterValue ? switchFilter(filterValue) : '';
            gridApi.setQuickFilter(switchFilteredValue);
          }
        }
      }, 1);
    }
  }, [gridApi, filterValue]);
};
