import { TInputProps } from '../inputs';
import { mapPasswordComplexity } from './map-password-complexity';

type TBaseProps = {
  value: string;
  onChange: (password: string) => void;
  label: string;
  errorHint?: string;
  onFocus?: VoidFunction;
  onBlur?: VoidFunction;
  passwordComplexity?: keyof typeof mapPasswordComplexity;
  fullWidth?: boolean;
  size?: number;
};

export type InputPasswordProps = Omit<TInputProps, keyof TBaseProps> & TBaseProps;
