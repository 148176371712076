import { CheckIcon, HelpIcon, Typography } from '@plarin/design';
import { isUrlProd, sendMetricGoal } from '@plarin/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '../buttons';
import {
  BidDateInputs,
  BidItemsType,
  BidTypeInput,
  BidValueInput,
  BudgetLimit,
  getCommonMaxPrice,
  getCurrencySymbol,
  getItemsType,
  grabStrategyBids,
  isKnownCurrency,
  LimitTypes,
  maxPricesAreDifferent,
  SaveBlockingMessages,
  setCommonMaxPrice,
  TCommonBudgetOptimizationLevel,
  TNewStrategyBids,
} from '../inputs';
import { MainTooltip } from '../tooltip';
import { getDate } from './bidsUtils';
import {
  EditBidProps,
  getBidMode,
  getCommonDailyLimit,
  getCommonLifetimeLimit,
  getCommonLimitType,
  getHeader,
  getItemsTypeForAdsReq,
  getStartAndEndDateForReqBody,
  inputsTypeVariants,
  limitMinMaxOk,
  limitWasChanged,
  maxPriceWasChanged,
  rowDataHasError,
  StrategyBidModes,
  VKHardcode,
} from './strategy-form-utils';
import classes from './style.module.scss';
import { StrategyBidSaveActionProps } from './types';

export const StrategyEditBid = ({
  selectedRows,
  close,
  gridApi,
  onSaveAction,
  getAvailableAds,
  isOnlyBudgetEditor,
  whatIsEdited,
}: EditBidProps) => {
  const [formIsBlocked, setFormIsBlocked] = useState(false);
  const [mode, setMode] = useState(getBidMode(selectedRows));
  const [adsAmountArr, setAdsAmounts] = useState<number[]>([]);
  const [isBlockedBudget, setIsBlockedBudget] = useState(true);

  const initialMode = getBidMode(selectedRows);
  const currency = isKnownCurrency(selectedRows[0].accountCurrency);

  // Лимиты размера ставки
  const maxPriceLimit = useMemo(
    () => Math.min(...Array.from(new Set(selectedRows.map(row => +row.packageLimitBidMax)))),
    [],
  );
  const minPriceLimit = useMemo(
    () => Math.max(...Array.from(new Set(selectedRows.map(row => +row.packageLimitBidMin)))),
    [],
  );

  // ********* Новые и старые данные редактируемых рекламных объектов

  const itemsType: BidItemsType = useMemo(() => getItemsType(window.location.pathname), []);
  const oldBidsData = useMemo(() => grabStrategyBids(selectedRows, itemsType), [selectedRows, itemsType]);
  const [newBidsData, setNewBidsData] = useState<TNewStrategyBids>(grabStrategyBids(selectedRows, itemsType));
  // храним здесь исходные значения в строковом формате, чтобы потом сравнивать с newBidsData и понимать, внёс ли пользователь изменения, то есть есть ли что сохранять
  const prevBidsData = useMemo(() => JSON.stringify(oldBidsData), []);

  // ********* Лимиты дневные/общие

  // если у выбранных сущностей разные значения лимита, то в стейте будет undefined
  const [commonDailyLimit, setCommonDailyLimit] = useState<number | undefined>(getCommonDailyLimit(newBidsData));
  const [commonLifetimeLimit, setCommonLifetimeLimit] = useState<number | undefined>(
    getCommonLifetimeLimit(newBidsData),
  );

  // проверяем, есть ли такое, что у всех выбранных сущностей значение только в дневном лимите либо только в вечернем
  const [limitType, setLimitType] = useState<LimitTypes>(getCommonLimitType(newBidsData));

  // Минимальное ограничение лимита бюджета
  // При дневном лимите бюджета это 100р. за каждое объявление
  // При лимите за всё время это 100р. за каждое объявление, умноженное на количество дней, которое должна отработать кампания
  const [minBudgetLimit, setMinBudgetLimit] = useState(VKHardcode.minLimit);

  // ********* Состояния кнопки сохранения

  const [maySave, setMaySave] = useState(false);
  const [blockingMessage, setBlockingMessage] = useState('');

  // *********  Состояния дат

  const [newStartDate, setNewStartDate] = useState<Date | null>(
    itemsType === 'ad_plan' ? getDate(selectedRows[0].adPlanStart) : getDate(selectedRows[0].campaignStart),
  );
  const [newEndDate, setNewEndDate] = useState<Date | null>(
    itemsType === 'ad_plan' ? getDate(selectedRows[0].adPlanStop) : getDate(selectedRows[0].campaignStop),
  );

  const [startDateWasChanged, setStartDateWasChanged] = useState<boolean>();
  const [endDateWasChanged, setEndDateWasChanged] = useState<boolean>();

  const [newStartDateHasError, setNewStartDateHasError] = useState<boolean>();
  const [newEndDateHasError, setNewEndDateHasError] = useState<boolean>();

  const [startDateErrorMessage, setStartDateErrorMessage] = useState('');
  const [endDateErrorMessage, setEndDateErrorMessage] = useState('');

  // Если выбрали несколько сущностей для редактирования и у них НЕ одинаковый уровень оптимизации бюджета (adPlan / campaign), то в этой переменной будет undefined
  const commonBudgetOptimizationLevel: TCommonBudgetOptimizationLevel = useMemo(
    () =>
      Array.from(new Set(newBidsData.map(item => item.budgetOptimizationLevel))).length === 1
        ? newBidsData[0].budgetOptimizationLevel
        : undefined,
    [newBidsData],
  );

  const budgetLimitsDisabled = useMemo(
    () => selectedRows.length === 1 && commonBudgetOptimizationLevel === 'campaign' && itemsType === 'ad_plan',
    [],
  );

  useEffect(() => {
    let maxAds = adsAmountArr.length ? Math.max(...adsAmountArr) : 0;

    setMinBudgetLimit(maxAds * VKHardcode.minLimit || VKHardcode.minLimit);
  }, [adsAmountArr]);

  const checkIfCanSave = () => {
    // ошибка, когда мы не понимаем, что редактируем: адплан, гр.кампаний или баннеры
    if (!itemsType) {
      setBlockingMessage(SaveBlockingMessages.itemTypeError);
      return setMaySave(false);
    }

    // форма редактирования отркрыта для нескольких сущностей, у которых разный тип оптимизации лимита бюджета: на уровне кампании и на уровне группы
    if (!commonBudgetOptimizationLevel) {
      setBlockingMessage(SaveBlockingMessages.itemTypeError);
      return setMaySave(false);
    }

    // есть ошибки в пришедших с бэкенда данных
    if (rowDataHasError(selectedRows[0], itemsType, commonBudgetOptimizationLevel)) {
      setBlockingMessage(SaveBlockingMessages.itemErrorVA);
      return setMaySave(false);
    }

    // I. Выбрана одна кампания
    if (selectedRows.length === 1) {
      // ПРОВЕРКИ НА ОШИБКИ

      if (mode === StrategyBidModes.maxPrice) {
        if (typeof newBidsData[0].maxPrice !== 'number' || newBidsData[0].maxPrice < minPriceLimit) {
          setBlockingMessage(SaveBlockingMessages.maxPriceTooSmall);
          return setMaySave(false);
        }

        if (typeof newBidsData[0].maxPrice !== 'number' || newBidsData[0].maxPrice > maxPriceLimit) {
          setBlockingMessage(SaveBlockingMessages.maxPriceTooBig);
          return setMaySave(false);
        }
      }

      // Если это условие отработало, то в коде, редактирующем лимит, есть ошибка
      if (!budgetLimitsDisabled && limitType === LimitTypes.differentLimitTypes) {
        setBlockingMessage(SaveBlockingMessages.limitType);
        return setMaySave(false);
      }

      if (limitType === LimitTypes.daily) {
        if (!commonDailyLimit || !limitMinMaxOk(commonDailyLimit, minBudgetLimit)) {
          setBlockingMessage(SaveBlockingMessages.dailyLimitError);
          return setMaySave(false);
        }
      }

      if (limitType === LimitTypes.lifetime) {
        if (!commonLifetimeLimit || !limitMinMaxOk(commonLifetimeLimit, minBudgetLimit)) {
          setBlockingMessage(SaveBlockingMessages.lifetimeLimitError);
          return setMaySave(false);
        }
      }

      if (newStartDateHasError) {
        setBlockingMessage(SaveBlockingMessages.dateError);
        return setMaySave(false);
      }

      if (newEndDateHasError) {
        setBlockingMessage(SaveBlockingMessages.dateError);
        return setMaySave(false);
      }

      // ПРОВЕРКИ НА НАЛИЧИЕ ИЗМЕНЕНИЙ:

      if (maxPriceWasChanged(selectedRows[0], newBidsData[0]) && mode === StrategyBidModes.maxPrice) {
        setBlockingMessage('');
        return setMaySave(true);
      }

      if (limitWasChanged(selectedRows[0], newBidsData[0], newBidsData[0].budgetOptimizationLevel)) {
        setBlockingMessage('');
        return setMaySave(true);
      }

      if (startDateWasChanged) {
        setBlockingMessage('');
        return setMaySave(true);
      }

      if (endDateWasChanged) {
        setBlockingMessage('');
        return setMaySave(true);
      }

      // если переключились с "Предельной цены" на "Минимальную", это измение типа стратегии - значит, есть что сохранять
      if (initialMode !== mode && mode === StrategyBidModes.minPrice) {
        setBlockingMessage('');
        return setMaySave(true);
      }

      setBlockingMessage(SaveBlockingMessages.noChanges);
      return setMaySave(false);
    }

    if (selectedRows.length > 1) {
      // проверяем, изменилось ли что-то, сравнивая приведённые к строке массивы
      // TODO: тут не учитывается, что если играться с датами, то вместо строки с датой в newBidsData начнёт лежать объект Date. ДОПИЛИТЬ!
      if (prevBidsData === JSON.stringify(newBidsData)) {
        setBlockingMessage(SaveBlockingMessages.noChanges);
        return setMaySave(false);
      }

      // ПРОВЕРКИ НА ОШИБКИ
      // TODO: здесь пока не все проверки

      if (mode === StrategyBidModes.maxPrice) {
        if (typeof newBidsData[0].maxPrice !== 'number' || newBidsData[0].maxPrice < minPriceLimit) {
          setBlockingMessage(SaveBlockingMessages.maxPriceTooSmall);
          return setMaySave(false);
        }

        if (typeof newBidsData[0].maxPrice !== 'number' || newBidsData[0].maxPrice > maxPriceLimit) {
          setBlockingMessage(SaveBlockingMessages.maxPriceTooBig);
          return setMaySave(false);
        }
      }

      // ноль в инпуте для лимита бюджета - невалидное значение
      if (commonDailyLimit === 0 && commonLifetimeLimit === 0) {
        setBlockingMessage(SaveBlockingMessages.limitValue);
        return setMaySave(false);
      }

      // дневной лимит в пределах допустимоо
      if (limitType === LimitTypes.daily) {
        if (!!commonDailyLimit && !limitMinMaxOk(commonDailyLimit, minBudgetLimit)) {
          setBlockingMessage(SaveBlockingMessages.dailyLimitError);
          return setMaySave(false);
        }
      }

      // общий лимит в пределах допустимого
      if (limitType === LimitTypes.lifetime) {
        if (!!commonLifetimeLimit && !limitMinMaxOk(commonLifetimeLimit, minBudgetLimit)) {
          setBlockingMessage(SaveBlockingMessages.lifetimeLimitError);
          return setMaySave(false);
        }
      }

      setBlockingMessage('');
      return setMaySave(true);
    }
  };

  const saveActionWrapper = () => {
    setFormIsBlocked(true);

    const requestData: StrategyBidSaveActionProps = [];

    // добавляем в requestData те объекты, которые были изменены. Сравниваем строковые представления объектов (есть проблема с датами: они поначалу string, потом Date)
    // TODO: для решения проблема с датами можно добавить проверку, что если нет равенства, нужно дополнительно проверить даты, приведя строку и Date к единому формату
    newBidsData.forEach((newData, index) => {
      if (JSON.stringify(newData) !== JSON.stringify(oldBidsData[index])) {
        const { startDate, stopDate } = getStartAndEndDateForReqBody(newData, itemsType);

        requestData.push({
          name: 'AUTOBIDDING_MODE',
          obj_type: itemsType || '',
          obj_id: +newData.item_id,
          network: newData.network,
          max_price: newData.maxPrice || 0,
          budget_limit: newData.limitLifetime,
          budget_limit_day: newData.limitDaily,
          date_start: startDate,
          date_end: stopDate,
        });
      }
    });

    onSaveAction({
      requestData,
      itemsType,
      commonBudgetOptimizationLevel,
      newBidsData,
      gridApi,
      whatIsEdited,
    })
      .then(res => {
        // Отправляем конверсию в Яндекс.Метрику
        sendMetricGoal('usage_vk_change_budget', 'manage/vk');
        if (res.command_results?.length && res.command_results[0].success) {
          !isUrlProd && console.log('Отредактировалось успешно. Результат: ', res.command_results[0]);
        } else {
          !isUrlProd &&
            console.log(
              'Ошибка: отредактировать не получилось ',
              res.command_results?.length && res.command_results[0],
            );
        }
      })
      .catch(res => {
        !isUrlProd && console.log('Catch:', res);
        // Отправляем конверсию в Яндекс.Метрику
        sendMetricGoal('error_vk_change_budget', 'manage/vk');
      });

    close();
  };

  // валидация инпутов с датами
  useEffect(() => {
    if (!newStartDate && startDateWasChanged) {
      setNewStartDateHasError(true);
      setStartDateErrorMessage('Задайте дату начала');
    } else {
      setNewStartDateHasError(false);
      setStartDateErrorMessage('');
    }

    if (limitType === LimitTypes.lifetime && !newEndDate) {
      setNewEndDateHasError(true);
      setEndDateErrorMessage('Задайте дату окончания');
    } else {
      setNewEndDateHasError(false);
      setEndDateErrorMessage('');
    }
  }, [newStartDate, newEndDate, limitType, startDateWasChanged, endDateWasChanged]);

  useEffect(() => {
    const type = getItemsTypeForAdsReq(itemsType);
    const ids = newBidsData.map(i => Number(i.item_id));

    type &&
      getAvailableAds({
        ids: ids,
        type: type,
        setIsBlockedBudget,
        setAdsArr: setAdsAmounts,
      });
  }, []);

  useEffect(() => {
    if (selectedRows.length === 1) {
      setNewBidsData(
        // @ts-ignore
        newBidsData.map(i => {
          return {
            ...i,
            adPlanStart: itemsType === 'ad_plan' ? newStartDate || undefined : i.adPlanStart,
            campaignStart: itemsType === 'ad_plan' ? undefined : newStartDate || undefined,
          };
        }),
      );
    }
  }, [newStartDate]);

  useEffect(() => {
    if (selectedRows.length === 1) {
      setNewBidsData(
        // @ts-ignore
        newBidsData.map(i => {
          return {
            ...i,
            adPlanStop: itemsType === 'ad_plan' ? newEndDate || undefined : i.adPlanStop,
            campaignStop: itemsType === 'ad_plan' ? undefined : newEndDate || undefined,
          };
        }),
      );
    }
  }, [newEndDate]);

  useEffect(() => {
    if (mode === StrategyBidModes.minPrice) {
      setNewBidsData(
        newBidsData.map(i => {
          return {
            ...i,
            maxPrice: '',
          };
        }),
      );
    }
  }, [mode]);

  useEffect(() => {
    checkIfCanSave();
  });

  if (isOnlyBudgetEditor)
    return (
      <div className={classes.mainWrap}>
        <Typography
          size="Main"
          color="typographyColorTextPrimary"
          weight={700}
          componentProps={{ className: classes.formHeader }}
        >
          Бюджет
        </Typography>
        {commonBudgetOptimizationLevel === 'adPlan' && itemsType !== 'ad_plan' && (
          <div className={classes.notice}>
            <CheckIcon color="status_success" />
            <Typography size="caption">Оптимизация бюджета на уровне Кампании</Typography>
            <MainTooltip
              component="span"
              isVisible={true}
              followCursor={true}
              tooltipMessage="Здесь можно изменить лимит бюджета всей кампании, а не бюджет отдельной группы объявлений."
            >
              <HelpIcon size={16} />
            </MainTooltip>
          </div>
        )}
        <BudgetLimit
          commonDailyLimit={commonDailyLimit}
          setCommonDailyLimit={setCommonDailyLimit}
          commonLifetimeLimit={commonLifetimeLimit}
          setCommonLifetimeLimit={setCommonLifetimeLimit}
          limitType={limitType}
          setLimitType={setLimitType}
          adsAmount={newBidsData.length === 1 ? adsAmountArr[0] || 0 : 0}
          minLimit={minBudgetLimit}
          maxLimit={VKHardcode.maxLimit}
          currencySymbol={getCurrencySymbol(currency)}
          newBidsData={newBidsData}
          setNewBidsData={setNewBidsData}
          budgetOptimizationLevel={commonBudgetOptimizationLevel}
          allDisabled={formIsBlocked || !adsAmountArr.length || isBlockedBudget}
          isBlockedBudget={isBlockedBudget}
        />

        {/* TODO: пока множественное редактирование - без дат */}
        {selectedRows.length === 1 && (
          <div className={classes.datesInStrategyForm}>
            <BidDateInputs
              title={itemsType === 'ad_plan' ? 'Даты проведения Кампании' : 'Даты проведения Группы объявлений'}
              startDate={newStartDate}
              initialStartDate={
                itemsType === 'ad_plan' ? getDate(selectedRows[0].adPlanStart) : getDate(selectedRows[0].campaignStart)
              }
              initialEndDate={
                itemsType === 'ad_plan' ? getDate(selectedRows[0].adPlanStop) : getDate(selectedRows[0].campaignStop)
              }
              endDate={newEndDate}
              setStartDate={setNewStartDate}
              setEndDate={setNewEndDate}
              setStartDateWasChanged={setStartDateWasChanged}
              setEndDateWasChanged={setEndDateWasChanged}
              setNewStartDateHasError={setNewStartDateHasError}
              newStartDateHasError={newStartDateHasError}
              newEndDateHasError={newEndDateHasError}
              setNewEndDateHasError={setNewEndDateHasError}
              startDateErrorMessage={startDateErrorMessage}
              endDateErrorMessage={endDateErrorMessage}
              limitType={limitType}
              adPlanStartDate={getDate(selectedRows[0].adPlanStart)}
              adPlanEndDate={getDate(selectedRows[0].adPlanStop)}
              areDatesLimitedByAdplan={itemsType === 'campaign' || itemsType === 'banner'}
            />
          </div>
        )}

        <div className={classes.btnsContainer}>
          <MainTooltip
            tooltipMessage={blockingMessage}
            isVisible={!!blockingMessage}
            followCursor={true}
            component="span"
            placement="bottom-start"
          >
            <Button
              disabled={!maySave || formIsBlocked}
              variant="filled"
              size="small"
              color="primary"
              onClick={saveActionWrapper}
            >
              Сохранить
            </Button>
          </MainTooltip>

          <Button disabled={formIsBlocked} variant="outlined" size="small" color="primary" onClick={close}>
            Отменить
          </Button>
        </div>
      </div>
    );

  return (
    <div className={classes.mainWrap}>
      <Typography
        size="Main"
        color="typographyColorTextPrimary"
        weight={700}
        componentProps={{ className: classes.formHeader }}
      >
        {getHeader(selectedRows[0].adPlanObjective, selectedRows[0].bidType)}
      </Typography>

      {/* За день:{adPlanDaily}  За всё время: {adPlanLifetime} */}
      <BidTypeInput
        mode={mode}
        setMode={setMode}
        inputsModeVariants={inputsTypeVariants}
        header="Стратегия"
        tooltipMessage="Изменить стратегию"
        isDisabled={formIsBlocked}
      />

      {mode === 'Предельная цена' && (
        <div className={classes.budgetStrategyWrap}>
          <BidValueInput
            selectedItems={newBidsData}
            setNewBids={setNewBidsData}
            bidsAreDifferent={maxPricesAreDifferent(newBidsData)}
            getCommonBid={getCommonMaxPrice}
            setCommonBid={setCommonMaxPrice}
            currency={currency}
            maxBid={maxPriceLimit}
            minBid={minPriceLimit}
            pinSize="12"
            header={`Максимальная стоимость${selectedRows[0].bidType ? ` - ${selectedRows[0].bidType}` : ''}`}
            isDisabled={formIsBlocked}
          />
        </div>
      )}

      <BudgetLimit
        commonDailyLimit={commonDailyLimit}
        setCommonDailyLimit={setCommonDailyLimit}
        commonLifetimeLimit={commonLifetimeLimit}
        setCommonLifetimeLimit={setCommonLifetimeLimit}
        limitType={limitType}
        setLimitType={setLimitType}
        adsAmount={newBidsData.length === 1 ? adsAmountArr[0] || 0 : 0}
        minLimit={minBudgetLimit}
        maxLimit={VKHardcode.maxLimit}
        currencySymbol={getCurrencySymbol(currency)}
        newBidsData={newBidsData}
        setNewBidsData={setNewBidsData}
        budgetOptimizationLevel={commonBudgetOptimizationLevel}
        allDisabled={formIsBlocked || !adsAmountArr.length || isBlockedBudget}
        isBlockedBudget={isBlockedBudget}
      />

      {/* TODO: пока множественное редактирование - без дат */}
      {selectedRows.length === 1 && (
        <div className={classes.datesInStrategyForm}>
          <BidDateInputs
            title={itemsType === 'ad_plan' ? 'Даты проведения Кампании' : 'Даты проведения Группы объявлений'}
            startDate={newStartDate}
            initialStartDate={
              itemsType === 'ad_plan' ? getDate(selectedRows[0].adPlanStart) : getDate(selectedRows[0].campaignStart)
            }
            initialEndDate={
              itemsType === 'ad_plan' ? getDate(selectedRows[0].adPlanStop) : getDate(selectedRows[0].campaignStop)
            }
            endDate={newEndDate}
            setStartDate={setNewStartDate}
            setEndDate={setNewEndDate}
            setStartDateWasChanged={setStartDateWasChanged}
            setEndDateWasChanged={setEndDateWasChanged}
            setNewStartDateHasError={setNewStartDateHasError}
            newStartDateHasError={newStartDateHasError}
            newEndDateHasError={newEndDateHasError}
            setNewEndDateHasError={setNewEndDateHasError}
            startDateErrorMessage={startDateErrorMessage}
            endDateErrorMessage={endDateErrorMessage}
            limitType={limitType}
            adPlanStartDate={getDate(selectedRows[0].adPlanStart)}
            adPlanEndDate={getDate(selectedRows[0].adPlanStop)}
            areDatesLimitedByAdplan={itemsType === 'campaign' || itemsType === 'banner'}
          />
        </div>
      )}

      <div className={classes.btnsContainer}>
        <MainTooltip
          tooltipMessage={blockingMessage}
          isVisible={!!blockingMessage}
          followCursor={true}
          component="span"
          placement="bottom-start"
        >
          <Button
            disabled={!maySave || formIsBlocked}
            variant="filled"
            size="small"
            color="primary"
            onClick={saveActionWrapper}
          >
            Сохранить
          </Button>
        </MainTooltip>

        <Button disabled={formIsBlocked} variant="outlined" size="small" color="primary" onClick={close}>
          Отменить
        </Button>
      </div>
    </div>
  );
};
