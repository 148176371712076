import { InfoIcon, Typography } from '@plarin/design';
import { CustomSelect } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { TWsRole } from '../../../../types/profile/types';
import { WSRolesVariants } from '../../../dictionary/workspaces';
import { useAppDataStore } from '../../app-data-provider';
import { SupportIntercomLink } from '../../support-link/support-intercom-link';
import { BlockTeams } from './block-teams';
import classes from './style.module.scss';
import { TagsLayout } from './tags/components';
import { canChange } from './utils';

export const ContentDrawer = observer(() => {
  const {
    profileStore: { workspaceName, WSRole, data },
    members: { drawerData, editDrawerData, openModalMember, setInitialDrawerData },
  } = useAppDataStore();

  const {
    userData: { role },
  } = drawerData;

  const changeRole = (value: TWsRole) => {
    editDrawerData({ ...drawerData, userData: { ...drawerData.userData, role: value } });
  };

  const isChange = canChange(WSRole);

  useEffect(() => {
    setInitialDrawerData();
  }, []);

  return (
    <div className={classes.widthContent}>
      {isChange && (
        <>
          {role === 'wsrole:owner' && drawerData.userData.user_id === data.user_id ? (
            <div className={classes.owner}>
              <InfoIcon color="primary60" iconType="solid" />
              <div className={classes.lines}>
                <Typography color="TextPrimary" weight={400} size="AuthContent">
                  Владелец пространства не может быть изменен вручную. Обратитесь в{' '}
                  <SupportIntercomLink>техническую поддержку</SupportIntercomLink> для изменения.
                </Typography>
              </div>
            </div>
          ) : (
            <CustomSelect
              className={classes.customSelect}
              value={role}
              onChange={(value: any) => {
                value === 'wsrole:guest' ? openModalMember('editToGuest') : changeRole(value);
              }}
              listVariants={WSRolesVariants}
              label={`Роль в ${workspaceName}`}
              menuHeight={144}
            />
          )}
        </>
      )}
      {role !== 'wsrole:guest' && (
        <>
          <TagsLayout tagsType="tag_account" />
          <TagsLayout tagsType="tag_ad_plan" />
          <BlockTeams />
        </>
      )}
    </div>
  );
});
