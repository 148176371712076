import { MetricIcon, Typography } from '@plarin/design';
import { Drawer, SearchDefaultInput, TitleOptions } from '@plarin/inputs';
import { sendMetricGoal } from '@plarin/utils';
import { observer } from 'mobx-react';
import React from 'react';
import classes from './style.module.scss';

export const MetricsDrawer = observer(
  ({
    drawerVisible,
    drawerAction,
    closeDrawer,
    setMetricFilterValue,
    metricsFilterValue,
    children,
  }: {
    drawerVisible: boolean;
    drawerAction: (() => void) | undefined;
    closeDrawer: () => void;
    setMetricFilterValue: (el: string) => void;
    metricsFilterValue: string;
    children: JSX.Element;
  }) => {
    return (
      <Drawer
        widthDrawer="large"
        isOpen={drawerVisible}
        title={
          <TitleOptions
            withTitleBox={classes.withTitleBox}
            iconBlock={<MetricIcon />}
            titleName={
              <Typography weight={600} size="ModalTitle" componentProps={{ className: 'mt2' }}>
                Выбор метрик и настройка столбцов
              </Typography>
            }
          />
        }
        closeDrawer={closeDrawer}
        action={() => {
          drawerAction && drawerAction();
          closeDrawer();
          // Отправляем конверсию в Яндекс.Метрику
          sendMetricGoal('usage_vk_drawer_metrics_save', 'manage/vk');
        }}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            <div className={classes.searchBlock}>
              <SearchDefaultInput
                placeholder="Поиск и фильтры"
                filterValue={metricsFilterValue}
                setFilter={setMetricFilterValue}
                inputStyle="tableSearch"
                isOutline
              />
            </div>
            <div className={classes.contentBlock}>{children}</div>
          </div>
        </div>
      </Drawer>
    );
  },
);
