import { AlertIntegrationError, Button } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React from 'react';
import { NetworkEnum } from '../../../dictionary/integrations';
import { useAppDataStore } from '../../app-data-provider';
import { SupportIntercomLink } from '../../support-link/support-intercom-link';
import classes from './drawer-content-connect-start.module.scss';
import { MyTargetText } from './myTarget-text';
import { VkAdsText } from './vkAds-text';
// import { VkontakteText } from './vkontakte-text';

export const DrawerContentConnectStart = observer(() => {
  const {
    profileStore: { workspaceShortname },
    integrationsVK: { connectAccount, error, network, setError, setStepInDrawer, setNetwork, isLoading },
  } = useAppDataStore();

  const closeAlert = () => setError();
  return (
    <div className={classes.drawerContent}>
      {!!error && (
        <div className={classes.errorAlert}>
          <AlertIntegrationError
            closeAlert={closeAlert}
            errorText={`${error}`}
            titleText="Не удалось подключить аккаунт к платформе Plarin"
            subtitleText={
              <p>
                Попробуйте еще раз или обратитесь в <SupportIntercomLink>службу поддержки</SupportIntercomLink>.
              </p>
            }
          />
        </div>
      )}
      <div className={classes.connectButtonsText}>
        {network === NetworkEnum.myTarget && <MyTargetText />}
        {network === NetworkEnum.VkAds && <VkAdsText />}
        {/* {network === NetworkEnum.VKontakte && <VkontakteText />} */}
      </div>
      <div className={classes.actionBlock}>
        {NetworkEnum.myTarget === network && (
          <Button
            loading={isLoading}
            onClick={() => (!isLoading ? connectAccount(NetworkEnum.myTarget, workspaceShortname) : '')}
            variant="filled"
            size="medium"
            color="primary"
            className={classes.buttonWidth}
          >
            Подключить
          </Button>
        )}
        {/* {NetworkEnum.VKontakte === network && (
            <Button onClick={() => connectAccount(NetworkEnum.VKontakte)} variant="filled" size="large">
              Подключить
            </Button>
          )} */}
        {NetworkEnum.VkAds === network && (
          <Button
            loading={isLoading}
            onClick={() => (!isLoading ? connectAccount(NetworkEnum.VkAds, workspaceShortname) : '')}
            variant="filled"
            size="medium"
            color="primary"
            className={classes.buttonWidth}
          >
            Подключить
          </Button>
        )}
      </div>
    </div>
  );
});
