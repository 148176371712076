import React from 'react';
import classes from './lockscreen.module.scss';

type LockscreenProps = {
  visible?: boolean;
};

export const Lockscreen = ({ visible }: LockscreenProps) => {
  if (!visible) return null;
  return <div className={classes.lockscreen} />;
};
