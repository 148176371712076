import { Typography } from '@plarin/design';
import { ModalActionNamesEnum, Modal } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { TWorkspaceAccessResp, WSMembers } from './../../../../../types/workspace/types';

export const MemberEditModals = observer(() => {
  const {
    members: {
      editMemberModals,
      members,
      closeModalMember,
      editStatusMember,
      setWsDataDrawer,
      deleteMember,
      wsDataDrawer,
      isLoading,
      editDrawerData,
      drawerData,
      setUserId,
      editDataMember,
      setIsDrawerDataChanged,
    },
  } = useAppDataStore();

  const idMember = editMemberModals.idMember;

  const [member, setMember] = useState<WSMembers>({} as WSMembers);

  useEffect(() => {
    idMember && setMember(members.find(el => el._id === idMember) || ({} as WSMembers));
  }, [idMember]);

  if (editMemberModals.typeModal === 'saveExit') {
    return (
      <Modal
        open={true}
        closeModal={() => closeModalMember('saveExit')}
        workspaceHeader="saveExit"
        secondAction={{
          actionTitle: ModalActionNamesEnum.NoSaveExit,
          action: () => {
            closeModalMember('saveExit');
            setUserId('');
            setIsDrawerDataChanged(false);
          },
        }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.SaveExit,
          isLoading: isLoading,
          action: () => {
            closeModalMember('saveExit');
            editDataMember(idMember, drawerData.userData);
            setIsDrawerDataChanged(false);
          },
        }}
        title="Сохранить изменения?"
      >
        <Typography size="AuthContent">
          Вы внесли изменения, которые не были сохранены.
          <br />
          Сохранить изменения перед выходом?
        </Typography>
      </Modal>
    );
  }

  if (editMemberModals.typeModal === 'remove') {
    return (
      <Modal
        open={editMemberModals.typeModal === 'remove'}
        closeModal={() => closeModalMember('remove')}
        workspaceHeader="remove"
        secondAction={{ actionTitle: ModalActionNamesEnum.Cancel, action: () => closeModalMember('remove') }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.Delete,
          isLoading: isLoading,
          action: () => {
            deleteMember(idMember);
            wsDataDrawer.editMember.isVisible && setWsDataDrawer('editMember', false, '', {} as TWorkspaceAccessResp);
          },
        }}
        title="Удалить сотрудника?"
      >
        <Typography size="AuthContent">
          {'Пользователь '}
          <Typography size="AuthContent" weight={600}>
            {member.fname} {member.lname}
          </Typography>
          {' и данные по нему будут удалены безвозвратно.'}
        </Typography>
      </Modal>
    );
  }

  if (editMemberModals.typeModal === 'block') {
    return (
      <Modal
        open={editMemberModals.typeModal === 'block'}
        closeModal={() => closeModalMember('block')}
        workspaceHeader="block"
        secondAction={{ actionTitle: ModalActionNamesEnum.Cancel, action: () => closeModalMember('block') }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.block,
          isLoading: isLoading,
          action: () => {
            editStatusMember(idMember, {
              status: 'blocked',
            });
          },
        }}
        title="Заблокировать сотрудника?"
      >
        <Typography size="AuthContent">
          {'Пользователь '}
          <Typography size="AuthContent" weight={600}>
            {member.fname} {member.lname}
          </Typography>
          {' будет заблокирован и не сможет больше входить в пространство.'}
        </Typography>
      </Modal>
    );
  }

  if (editMemberModals.typeModal === 'unBlock') {
    return (
      <Modal
        open={editMemberModals.typeModal === 'unBlock'}
        closeModal={() => closeModalMember('unBlock')}
        workspaceHeader="unBlock"
        secondAction={{ actionTitle: ModalActionNamesEnum.Cancel, action: () => closeModalMember('unBlock') }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.unBlock,
          isLoading: isLoading,
          action: () => {
            editStatusMember(idMember, {
              status: 'active',
            });
          },
        }}
        title="Разблокировать сотрудника?"
      >
        <Typography size="AuthContent">
          {'Пользователь '}
          <Typography size="AuthContent" weight={600}>
            {member.fname} {member.lname}
          </Typography>
          {' будет разблокирован и сможет входить в пространство.'}
        </Typography>
      </Modal>
    );
  }

  if (editMemberModals.typeModal === 'editToGuest') {
    return (
      <Modal
        open={editMemberModals.typeModal === 'editToGuest'}
        closeModal={() => closeModalMember('editToGuest')}
        workspaceHeader="person"
        secondAction={{ actionTitle: ModalActionNamesEnum.Cancel, action: () => closeModalMember('editToGuest') }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.Change,
          isLoading: isLoading,
          action: () => {
            closeModalMember('editToGuest');

            editDrawerData({
              userData: {
                ...drawerData.userData,
                role: 'wsrole:guest',
                tag_account: [],
                tag_ad_plan: [],
                teams: [],
              },
              workspaceTags: {},
            });
          },
        }}
        title="Изменить роль сотрудника?"
      >
        <Typography size="AuthContent">
          При изменении роли сотрудника на Гость будут удалены все введенные теги, разорвана связь со всеми
          ассоциированными рекламными кампаниями и пользователь будет исключен из всех команд.
        </Typography>
      </Modal>
    );
  }

  if (editMemberModals.typeModal === 'removeTag') {
    return (
      <Modal
        title="Удалить привязку с меткой?"
        workspaceHeader="remove"
        open={editMemberModals.typeModal === 'removeTag'}
        closeModal={() => closeModalMember('removeTag')}
        secondAction={{ actionTitle: ModalActionNamesEnum.Cancel, action: () => closeModalMember('removeTag') }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.Delete,

          action: () => {
            closeModalMember('removeTag');
            editMemberModals.action();
          },
        }}
      >
        <Typography size="AuthContent">
          После удаления метки{' '}
          <Typography size="AuthContent" weight={600}>
            {editMemberModals.idMember}
          </Typography>{' '}
          все связи с ассоциированными рекламными кампаниями будут удалены безвозвратно.
        </Typography>
      </Modal>
    );
  }
  if (editMemberModals.typeModal === 'editTag') {
    return (
      <Modal
        title="Изменить метку?"
        workspaceHeader="edit"
        open={editMemberModals.typeModal === 'editTag'}
        closeModal={() => closeModalMember('editTag')}
        secondAction={{ actionTitle: ModalActionNamesEnum.Cancel, action: () => closeModalMember('editTag') }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.Change,

          action: () => {
            closeModalMember('editTag');
            editMemberModals.action();
          },
        }}
      >
        <Typography size="AuthContent">
          После изменения метки{' '}
          <Typography size="AuthContent" weight={600}>
            {editMemberModals.idMember}
          </Typography>{' '}
          все связи с ассоциированными рекламными кампаниями предыдущей метки будут удалены безвозвратно и будут созданы
          новые связи с новой меткой.
        </Typography>
      </Modal>
    );
  } else {
    return <></>;
  }
});
