import { Page } from '@plarin/core';
import { Typography } from '@plarin/design';
import { Button } from '@plarin/inputs';
import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useAppDataStore } from '../../components/app-data-provider';
import error504ImageSrc from './images/error504.svg';
import classes from './style.module.scss';

export const ErrorPage: React.FC = () => {
  const { show: showIntercom, getVisitorId } = useIntercom();
  const history = useHistory();
  const {
    profileStore: { setNoError },
  } = useAppDataStore();
  const onClick = () => {
    setNoError('');
  };
  const visitorId = getVisitorId();

  return (
    <Page title="Произошла ошибка на сервере">
      <div className={classes.wrapper}>
        <div className={classes.errorText}>
          <Typography color="textPrimary" size="errorTitle" weight={600}>
            Произошла ошибка на сервере
          </Typography>
          <br />
          <Typography color="textPrimary" size="AuthSubheader">
            Мы разбираемся в проблеме. Попробуйте обновить страницу. Если ошибка повторяется,{' '}
            {visitorId ? (
              <Typography color="linkPrimary" componentProps={{ onClick: showIntercom, style: { cursor: 'pointer' } }}>
                обратитесь в поддержку
                <br />
                платформы Plarin
              </Typography>
            ) : (
              <Typography color="linkPrimary" component={Link} componentProps={{ onClick: onClick, to: '/support' }}>
                обратитесь в поддержку
                <br />
                платформы Plarin
              </Typography>
            )}
          </Typography>

          <br />
          <Button color="primary" variant="filled" size="large" onClick={() => history.go(0)}>
            Обновить страницу
          </Button>
          <br />
          <Typography color="linkPrimary" weight={600} component={Link} componentProps={{ onClick: onClick, to: '/' }}>
            Перейти на главную страницу
          </Typography>
        </div>
        <div className={classes.errorImage}>
          <img src={error504ImageSrc} alt="" />
        </div>
      </div>
    </Page>
  );
};
