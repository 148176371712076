import { TCampaignsData } from '../../types/manage-vk/types';
import { TMetric, TMetricGroup, TYDMetric, TYDMetricGroup } from '../../types/metrics';
import { sortRow } from './common';
import { parseStringToCamelCase } from './manage-vk';

export const grouping = (
  metricGroup: TMetric | TYDMetric,
  data: TCampaignsData[],
  metrics: TMetricGroup[] | TYDMetricGroup[],
  mainTabData: string[],
) => {
  const [tabMainName, tabMainId] = mainTabData;
  // создаем сет из вариантов группировки
  const groupingVariants = new Set<string>();

  if (metricGroup.path === 'mt_project') {
    data?.forEach(el => {
      groupingVariants.add(el.mtProject.map((el: { name: string }) => el.name).join(', '));
    });
  } else {
    data?.forEach(el => {
      groupingVariants.add(el[parseStringToCamelCase(metricGroup.path)]);
    });
  }

  // создаем новые данные с orgHierarchy и с новыми элементами для группировки, в значение ключей которых нужно записать:
  // если math_default метрики === sum, записываем сумму всех остальных объектов с этим ключом
  // если math_default метрики === avg, записываем среднее арефметическое всех остальных объектов с этим ключом
  // если math_default метрики === undefined, записываем null в это ключ

  return [...groupingVariants]
    .sort((a, b) => sortRow(a, b))
    .flatMap(groupingVariant => {
      // фильтруем объекты для одного варианта
      const dataForGrouping = data.filter(item => {
        if (metricGroup.path === 'mt_project') {
          return item.mtProject.map((el: { name: string }) => el.name).join(', ') === groupingVariant;
        } else {
          return item[parseStringToCamelCase(metricGroup.path)] === groupingVariant;
        }
      });

      // new element
      const elemForGrouping = JSON.parse(JSON.stringify(dataForGrouping[0]));

      Object.keys(elemForGrouping).forEach(key => {
        // находим math_default и math_allowed этого поля
        let mathAllowed: TMetric['math_allowed'] = undefined;
        let mathDefault: TMetric['math_default'] = undefined;

        metrics.forEach(group =>
          group.fields.forEach(metric => {
            if (parseStringToCamelCase(metric.path) === key) {
              mathAllowed = metric.math_allowed;
              mathDefault = metric.math_default;
            }
          }),
        );

        // если math_allowed === true, считаем math_default для значения ключа
        if (
          mathAllowed &&
          key !== 'bidCurrent' &&
          key !== 'adPlanLifetime' &&
          key !== 'adPlanDaily' &&
          key !== 'statisticsSpentToday'
        ) {
          let result = 0;
          let resultLength = 0;

          dataForGrouping.forEach(elem => {
            if (+elem[key]) {
              result += +elem[key];
              resultLength += 1;
            }
          });
          if (mathDefault === 'sum') {
            elemForGrouping[key] = result;
          }
          if (mathDefault === 'avg') {
            elemForGrouping[key] = result / resultLength;
          }
        } else {
          if (key !== 'accountCurrency') {
            elemForGrouping[key] = null;
          }
        }
      });

      elemForGrouping[tabMainName] = `${Array.isArray(groupingVariant) ? groupingVariant[0].code : groupingVariant}`;
      elemForGrouping[tabMainId] = null;
      elemForGrouping.orgHierarchy = undefined;
      elemForGrouping.key = dataForGrouping[0].key + Date.now();

      return [
        elemForGrouping,
        ...dataForGrouping.map(dataItem => ({ ...dataItem, orgHierarchy: [elemForGrouping.key, dataItem.key] })),
      ];
    });
};
