import { BasicStore, httpClient, httpClient2 } from '@plarin/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  MembersStatRequest,
  ProjectsOverviewChartsRequest,
  ProjectsOverviewChartsResponse,
} from '../../types/manage-vk/types';
import { parseStatistics } from '../utils/manage-vk';

export class ProjectOverviewStore extends BasicStore {
  constructor() {
    super();
    makeObservable<this>(this, {});
  }

  getMemberStat = (requestData: MembersStatRequest) =>
    this.execRequest<string>(httpClient.post('/api/v1/core/project/members', requestData)).then(res => {
      const [columns, ...values] = parseStatistics(res);
      return values.reduce((acc, value) => {
        const obj: Record<string, string> = {};
        value.forEach((el, index) => (obj[columns[index]] = el));
        acc.push(obj);
        return acc;
      }, [] as Array<Record<string, string>>);
    });

  // "spent"
  // "cpc/cpm"
  // "installs"
  // в свагере почему то тип name - string, а не Enum, так что вот доступные типы (пока что) графиков
  getChartsData = (requestData: ProjectsOverviewChartsRequest) =>
    this.execRequest<ProjectsOverviewChartsResponse>(httpClient2.post('/api/v1/core/project/charts', requestData));
}
