import { Typography } from '@plarin/design';
import { GridApi, Modal, ModalActionNamesEnum } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useAppDataStore } from './../../../components/app-data-provider';

const textRemove = {
  one: {
    title: 'Аккаунт ',
    text: ' будет отключен и удален из Plarin, связи с проектами и вся ранее накопленная статистика по целям и E-commerce будут удалены.',
  },
  many: {
    title: 'Аккаунты ',
    text: ' будут отключены и удалены из Plarin, связи с проектами и вся ранее накопленная статистика по целям и E-commerce будут удалены.',
  },
};

export const MetricsConfirmModal = observer(({ gridApi }: { gridApi: GridApi }) => {
  const {
    integrationsYM: { modalData, setModalData, isLoading, disableYM, removeYM },
  } = useAppDataStore();

  const textAccounts = useMemo(
    () =>
      modalData.name &&
      modalData.name.map((element, index) => (index === modalData.name!.length - 1 ? element : element + ', ')),
    [modalData.name],
  );
  switch (modalData.type) {
    case 'pause':
      return (
        <Modal
          open={modalData.type === 'pause'}
          closeModal={() =>
            setModalData({
              type: '',
              name: [],
              ids: [],
            })
          }
          workspaceHeader="pause"
          secondAction={{
            actionTitle: ModalActionNamesEnum.Cancel,
            action: () =>
              setModalData({
                type: '',
                name: [],
                ids: [],
              }),
          }}
          firstAction={{
            actionTitle: ModalActionNamesEnum.Pause,
            isLoading: isLoading,
            action: () => {
              disableYM({ db_ids: modalData.name! }, gridApi);
            },
          }}
          title="Выключить интеграцию?"
        >
          <Typography size="AuthContent">
            {'После выключения '}
            <Typography size="AuthContent" weight={600}>
              {textAccounts}
            </Typography>
            {
              ' все связи с проектами будут сохранены, но синхронизация будет остановлена и новые данные поступать не будут до возобновления интеграции.'
            }
          </Typography>
        </Modal>
      );

    case 'remove':
      return (
        <Modal
          open={modalData.type === 'remove'}
          closeModal={() =>
            setModalData({
              type: '',
              name: [],
              ids: [],
            })
          }
          workspaceHeader="remove"
          secondAction={{
            actionTitle: ModalActionNamesEnum.Cancel,
            action: () =>
              setModalData({
                type: '',
                name: [],
                ids: [],
              }),
          }}
          firstAction={{
            actionTitle: ModalActionNamesEnum.Unplug,
            isLoading: isLoading,
            action: () => {
              removeYM({ db_ids: modalData.name! });
            },
          }}
          title="Отключить аккаунт?"
        >
          <Typography size="AuthContent">
            {textAccounts?.length === 1 ? textRemove.one.title : textRemove.many.title}
            <Typography size="AuthContent" weight={600}>
              {textAccounts}
            </Typography>
            {textAccounts?.length === 1 ? textRemove.one.text : textRemove.many.text}
          </Typography>
        </Modal>
      );

    default:
      return null;
  }
});
