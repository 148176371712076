import React from 'react';

export const IconChecked = ({ isInverted, size }: { isInverted?: boolean; size: number }) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="4" fill={isInverted ? 'white' : '#214ECB'} />
      <path
        d="M13.4714 5.80506C13.7318 5.54471 13.7318 5.1226 13.4714 4.86225C13.2111 4.6019 12.7889 4.6019 12.5286 4.86225L7 10.3909L4.13807 7.52892C3.87772 7.26857 3.45561 7.26857 3.19526 7.52892C2.93491 7.78927 2.93491 8.21138 3.19526 8.47173L6.5286 11.8051C6.78894 12.0654 7.21106 12.0654 7.4714 11.8051L13.4714 5.80506Z"
        fill={isInverted ? '#214ECB' : 'white'}
      />
    </svg>
  );
};
