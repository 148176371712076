import { ArrowRight } from '@plarin/design';
import React from 'react';

export const LinkWithArrow: React.FC = function LinkWithArrow({ children }) {
  return (
    <div className="linkWithArrow">
      <span className="linkWithArrow__text">{children}</span>
      <ArrowRight />
    </div>
  );
};
