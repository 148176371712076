import { Typography } from '@plarin/design';
import React from 'react';
import { Link } from 'react-router-dom';
import { routerPaths } from '../../../routing/router-path';
import { AuthContentTemplate } from '../auth-content-template';
import { AuthSliderLayoutTemplate } from '../auth-layout-template';

const footer = (
  <Typography color="textTertiary" size="Main">
    <Typography underline color="linkPrimary" component={Link} componentProps={{ to: routerPaths.unAuth.REGISTRATION }}>
      Зарегистрироваться
    </Typography>{' '}
    в Plarin
  </Typography>
);

export const AuthLoginPageTemplate: React.FC = ({ children }) => (
  <AuthSliderLayoutTemplate>
    <AuthContentTemplate footer={footer}>{children}</AuthContentTemplate>
  </AuthSliderLayoutTemplate>
);
