import { MoreIcon } from '@plarin/design';
import { Button, Popover, IconButton, MainTooltip } from '@plarin/inputs';
import { ValidatorLength255 } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useAppDataStore } from '../../app-data-provider';
import classes from './project-drawer.module.scss';

interface IActionBlockDrawer {
  onCloseDrawer: () => void;
  editProjects: () => void;
  createProjects: () => void;
}

export const ActionBlockDrawer = observer(({ onCloseDrawer, editProjects, createProjects }: IActionBlockDrawer) => {
  const {
    projects: { editProject, projectsState, setModalData, statusWSroles, isLoading, modalData, isDrawerDataChanged },
    profileStore: { loadWorkspaces },
  } = useAppDataStore();

  const [openActionMenu, setOpenActionMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const isDisabled = !projectsState.projectsName;
  const buttonIsDisabled =
    !isDrawerDataChanged ||
    !ValidatorLength255(projectsState.projectsName)[0] ||
    isDisabled ||
    projectsState.projectsName.length === 0 ||
    projectsState.projectsName.trim() === '' ||
    (isLoading && !!modalData.type);

  const options = [
    {
      name: editProject.editProjectData?.is_archived ? 'Разархивировать' : 'Архивировать',
      action: () => {
        setOpenActionMenu(false);
        setModalData({
          type: editProject.editProjectData?.is_archived ? 'unArchive' : 'archive',
          data: editProject.editProjectData,
        });
      },
    },
  ];

  if (statusWSroles.isAdmin) {
    options.push({
      name: 'Удалить',
      action: () => {
        setOpenActionMenu(false);
        setModalData({ type: 'delete', data: editProject.editProjectData });
      },
    });
  }

  return (
    <div className={classes.footerBox}>
      <MainTooltip tooltipMessage="нет имени проекта" isVisible={isDisabled} component="span" followCursor={true}>
        <Button
          loading={modalData.type ? false : isLoading}
          onClick={editProject.isEditProject ? editProjects : createProjects}
          size="small"
          color="primary"
          variant="filled"
          disabled={buttonIsDisabled}
        >
          {editProject.isEditProject ? 'Сохранить' : 'Создать'}
        </Button>
      </MainTooltip>
      <Button disabled={isLoading} size="small" variant="outlined" color="primary" onClick={onCloseDrawer}>
        Отменить
      </Button>
      {editProject.isEditProject && (
        <div
          ref={ref => {
            if (!ref) return;
            ref.onclick = e => setAnchorEl(ref);
          }}
        >
          <IconButton
            variant="outlined"
            color="primary"
            disabled={modalData.type ? false : isLoading}
            loading={modalData.type ? isLoading : false}
            onClick={() => setOpenActionMenu(prev => !prev)}
          >
            <MoreIcon color={isLoading ? 'disabled' : 'primary60'} />
          </IconButton>
        </div>
      )}

      <Popover
        anchorEl={anchorEl}
        isOpen={openActionMenu}
        setIsOpen={() => {
          setOpenActionMenu(false);
          setAnchorEl(null);
        }}
        dataList={options}
      />
    </div>
  );
});
