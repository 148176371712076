import { List } from '@mui/material';
import React from 'react';
import classes from './main-menu-list.module.scss';

type MainMenuListProps = {
  children: React.ReactNode;
};

export const MainMenuList = ({ children }: MainMenuListProps) => {
  return (
    <List
      classes={{
        root: classes.mainMenuListRoot,
      }}
    >
      {children}
    </List>
  );
};
