import { ICellRendererParams } from 'ag-grid-community';
import React, { useMemo } from 'react';
import { Bar, ComposedChart, Line, Tooltip, YAxis } from 'recharts';
import { Skeleton } from '../../../preloader/skeleton/skeleton-table-row';
import classes from './style.module.scss';
import { TooltipContent } from './tooltip-content';

interface FastStatRendererProps extends ICellRendererParams {
  fastStatType: 'fastStat' | 'fastStat72';
}

export const FastStatRenderer = ({ data, fastStatType }: FastStatRendererProps) => {
  const { statisticsCtr } = data;

  const allDataStat = useMemo(() => {
    if (Array.isArray(data[fastStatType])) {
      const allClicks = data[fastStatType].reduce((total: number, el: any) => (total += el.clicks), 0);
      const allImpressions = data[fastStatType].reduce((total: number, el: any) => (total += el.impressions), 0);
      const allCTR = (allClicks / allImpressions) * 100 || 0;
      return { allClicks, allImpressions, allCTR };
    }
  }, [data[fastStatType]]);

  if (data[fastStatType] === 0 || data[fastStatType] === 'loading') {
    return (
      <div className={classes.fastWrapper}>
        <div className={classes.preloaderWrapper}>
          <Skeleton size="long" height={17} />
        </div>
      </div>
    );
  }

  return data[fastStatType] ? (
    // изменяя ширину графика, изменяем ширину бара

    <ComposedChart
      style={{
        zIndex: fastStatType === 'fastStat' ? 21 : 20,
      }}
      height={32}
      data={data[fastStatType]}
      width={fastStatType === 'fastStat' ? 256 : 304}
      margin={{ left: 8, top: 0, right: 8, bottom: 0 }}
    >
      {/* YAxis для масштаба графика по своим значениям */}
      <YAxis yAxisId="left" hide />
      <YAxis yAxisId="right" hide />
      <Tooltip
        cursor={{ stroke: '#7FC222', strokeWidth: 3 }}
        content={
          <TooltipContent
            allClicks={allDataStat?.allClicks}
            allImpressions={allDataStat?.allImpressions}
            allCTR={allDataStat?.allCTR}
            ctr={+statisticsCtr || 0}
            fastStatType={fastStatType}
          />
        }
      />

      <Bar dataKey="impressions" radius={4} isAnimationActive={false} fill="#779AFC" yAxisId="left" />
      <Line
        type="monotone"
        dataKey="clicks"
        stroke="#CE5DE2"
        dot={false}
        strokeWidth={1.5}
        isAnimationActive={false}
        activeDot={false}
        yAxisId="right"
      />
    </ComposedChart>
  ) : null;
};
