export enum StatusValueEnum {
  DRAFT = 'DRAFT',
  MODERATION = 'MODERATION',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  PREACCEPTED = 'PREACCEPTED',
}

export enum StateValueEnum {
  ON = 'ON',
  OFF_BY_MONITORING = 'OFF_BY_MONITORING',
  ARCHIVED = 'ARCHIVED',
  SUSPENDED = 'SUSPENDED',
  OFF = 'OFF',
  ENDED = 'ENDED',
}

export enum StatusColorEnum {
  ACTIVE = 'active',
  BANNED = 'banned',
  ARCHIVED = 'archived',
  COMPLETED = 'completed',
}

export enum StatusTextEnum {
  ARCHIVED = 'В архиве',
  ON = 'Запущено',
  OFF_BY_MONITORING = 'Запущено',
  REJECTED = 'Отклонено',
  SUSPENDED = 'Остановлено',
  ENDED = 'Остановлено',
  DRAFT = 'Черновик',
  MODERATION = 'На модерации',
  NO_DATA = 'Данные отсутствуют',
}

export enum TranslationTextEnum {
  TranslationOff = 'Не транслируется',
  TranslationOn = 'Транслируется',
  TranslationUndefined = 'Трансляция неизвестна',
}

export enum StatusToSet {
  RESUME = 'resume',
  SUSPEND = 'suspend',
  MODERATE = 'moderate',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
}

// значение статуса, которое мы отправляем в запросе на изменение статуса сущности Директа (запустить кампанию, остановить объявление и т.д.)
export const statusToSet = {
  RESUME: StatusToSet.RESUME, // возобновить показ
  SUSPEND: StatusToSet.SUSPEND, // остановить
  MODERATE: StatusToSet.MODERATE,
  ARCHIVE: StatusToSet.ARCHIVE,
  UNARCHIVE: StatusToSet.UNARCHIVE,
};

// Проверки, можем ли мы изменить у сущности Яндекс Директа статус на другой статус:
export const mayResume = (state: StateValueEnum, status: StatusValueEnum) => state === StateValueEnum.SUSPENDED;

export const maySuspend = (state: StateValueEnum, status: StatusValueEnum) =>
  state === StateValueEnum.ON || state === StateValueEnum.OFF_BY_MONITORING;

export const mayModerate = (state: StateValueEnum, status: StatusValueEnum) => status === StatusValueEnum.DRAFT;

export const mayArchive = (state: StateValueEnum, status: StatusValueEnum) => {
  return status == StatusValueEnum.DRAFT || state === StateValueEnum.SUSPENDED || status === StatusValueEnum.REJECTED;
};

export const mayUnarchive = (state: StateValueEnum, status: StatusValueEnum) => state === StateValueEnum.ARCHIVED;

// эти свойства добавляются в объект data для таблицы manageYD, чтобы мы лучше понимали, какой у сущности Yandex Direct статус
// https://wiki.yandex.ru/homepage/planing/bazovyjj-funkcional-upravlenija-yandex.direct/metriki-dlja-tablic-upravlenija-reklamojj/#statusy-obuektov
export const statusEntities = {
  // В архиве
  ARCHIVED: {
    statusColor: StatusColorEnum.ARCHIVED,
    statusText: StatusTextEnum.ARCHIVED,
    translationText: TranslationTextEnum.TranslationOff,
  },
  // Запущено
  ON: {
    statusColor: StatusColorEnum.ACTIVE,
    statusText: StatusTextEnum.ON,
    translationText: TranslationTextEnum.TranslationOn,
  },
  // Запущено
  // это специальный статус для объявлений с трансляцией. Визуально он пока будет совсем как статус ON.
  OFF_BY_MONITORING: {
    statusColor: StatusColorEnum.ACTIVE,
    statusText: StatusTextEnum.OFF_BY_MONITORING,
    translationText: TranslationTextEnum.TranslationOn,
  },
  // Отклонено
  REJECTED: {
    statusColor: StatusColorEnum.BANNED,
    statusText: StatusTextEnum.REJECTED,
    translationText: TranslationTextEnum.TranslationOff,
  },
  // Остановлено
  SUSPENDED: {
    statusColor: StatusColorEnum.COMPLETED,
    statusText: StatusTextEnum.SUSPENDED,
    translationText: TranslationTextEnum.TranslationOff,
  },
  // Остановлено и нельзя запустить
  OFF: {
    statusColor: StatusColorEnum.COMPLETED,
    statusText: StatusTextEnum.SUSPENDED,
    translationText: TranslationTextEnum.TranslationOff,
  },
  // Черновик
  DRAFT: {
    statusColor: StatusColorEnum.COMPLETED,
    statusText: StatusTextEnum.DRAFT,
    translationText: TranslationTextEnum.TranslationOff,
  },
  // На модерации
  MODERATION: {
    statusColor: StatusColorEnum.ACTIVE,
    statusText: StatusTextEnum.MODERATION,
    translationText: TranslationTextEnum.TranslationOff,
  },
  // Мастер-кампания без статуса
  NO_DATA: {
    statusColor: StatusColorEnum.COMPLETED,
    statusText: StatusTextEnum.NO_DATA,
    translationText: TranslationTextEnum.TranslationUndefined,
  },
};

// проверяем, есть ли у строчки из таблицы manageYD статус "Запущено"
// data должен быть объектом, соответствующим строчке из таблицы manageYD
// запущенным статусом на текущий момент мы считаем статусы statusEntities.ON и statusEntities.OFF_BY_MONITORING
// на текущий момент объет OFF_BY_MONITORING такой же как объект ON, поэтому сравниваем только со statusEntities.ON
export const isYdActive = (data: any) => {
  if (
    data.statusColor === statusEntities.ON.statusColor &&
    data.statusText === statusEntities.ON.statusText &&
    data.translationText === statusEntities.ON.translationText
  ) {
    return true;
  }

  return false;
};

// проверяем, есть ли у строчки из таблицы manageYD статус "Остановлено"
export const isYdSuspended = (data: any) => {
  if (
    data.statusColor === statusEntities.SUSPENDED.statusColor &&
    data.statusText === statusEntities.SUSPENDED.statusText &&
    data.translationText === statusEntities.SUSPENDED.translationText
  ) {
    return true;
  } else if (
    data.statusColor === statusEntities.OFF.statusColor &&
    data.statusText === statusEntities.OFF.statusText &&
    data.translationText === statusEntities.OFF.translationText
  ) {
    return true;
  }

  return false;
};
