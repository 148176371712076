import clsx from 'clsx';
import React from 'react';
import classes from './../style.module.scss';

interface IconProps {
  className?: string;
  disabled?: boolean;
  size?: number;
}

export const Va = ({ className, disabled, size }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      className={clsx(classes.height, className)}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4.75396C0 2.51318 0 1.3906 0.696396 0.696396C1.3928 0.00219683 2.51318 0 4.75396 0H5.24604C7.48683 0 8.60721 0 9.30358 0.696396C10 1.3928 10 2.51318 10 4.75396V5.24604C10 7.48683 10 8.60721 9.30358 9.30358C8.60721 10 7.48683 10 5.24604 10H4.75396C2.51318 10 1.3928 10 0.696396 9.30358C0 8.60721 0 7.48683 0 5.24604V4.75396Z"
        fill={disabled ? '#C4CAD4' : '#0077FF'}
      />
      <path
        d="M4.99984 8.33329C6.8408 8.33329 8.33317 6.84092 8.33317 4.99996C8.33317 3.15901 6.8408 1.66663 4.99984 1.66663C3.15889 1.66663 1.6665 3.15901 1.6665 4.99996C1.6665 6.84092 3.15889 8.33329 4.99984 8.33329Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.10162 1.25409V1.89604H8.74792C8.82992 1.89557 8.91025 1.91883 8.97879 1.96289C9.04733 2.00695 9.10108 2.06986 9.13325 2.14372C9.16546 2.21758 9.17462 2.29911 9.15971 2.37806C9.14475 2.45702 9.10629 2.52989 9.04921 2.58754L8.16371 3.48853C8.12483 3.52842 8.07808 3.56017 8.02629 3.58185C7.9745 3.60353 7.91875 3.61469 7.86242 3.61467H7.02521L6.15812 4.53367C6.22437 4.68842 6.258 4.85467 6.257 5.02246C6.257 5.26525 6.1835 5.50258 6.04575 5.70446C5.90804 5.90633 5.71225 6.06367 5.48321 6.15658C5.25417 6.2495 5.00212 6.27383 4.75896 6.22646C4.51579 6.17908 4.29246 6.06217 4.11715 5.8905C3.94184 5.71879 3.82245 5.50008 3.77409 5.26196C3.72572 5.02383 3.75054 4.777 3.84542 4.55267C3.94029 4.32837 4.10096 4.13664 4.30708 4.00175C4.51325 3.86686 4.75558 3.79486 5.0035 3.79486C5.11854 3.79484 5.233 3.81 5.34392 3.83991L6.33983 2.76999V2.09426C6.33992 2.03858 6.35158 1.9835 6.37412 1.93238C6.39667 1.88126 6.42958 1.83518 6.47092 1.79694L7.39092 0.943253C7.45042 0.88902 7.52475 0.852924 7.60483 0.839366C7.68492 0.825807 7.76729 0.835374 7.84192 0.866903C7.91658 0.898432 7.98021 0.950553 8.02508 1.01691C8.07 1.08327 8.09417 1.16098 8.09471 1.24058L8.10162 1.25409Z"
        fill={disabled ? '#C4CAD4' : '#313131'}
      />
    </svg>
  );
};
