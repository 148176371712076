import { ErrorIcon } from '@plarin/design';
import { CustomMenuItem, EllipsisTooltip, HightLightText, MainTooltip } from '@plarin/inputs';
import { TooltipError, typografNames } from '@plarin/utils';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { TShortCabinetsFilters } from '../../../../../../../../types/projects/projects';
import classes from '../../styles.module.scss';

type TListItemProps = {
  cabinet: TShortCabinetsFilters;
  changeCabinetSelection: (cabinet: TShortCabinetsFilters) => void;
  searchValue?: string;
};

export const ProjectListItem = ({ cabinet, changeCabinetSelection, searchValue = '' }: TListItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef(null);

  const nameTooltipContent = cabinet.client_name
    ? `${cabinet.client_name} (${cabinet.username}, ${cabinet.client_id})`
    : `${cabinet.username} (${cabinet.username}, ${cabinet.client_id})`;

  const regularItem = (
    <>
      <div className={clsx(classes.regularWrap, classes.gap)}>
        {cabinet.has_error && (
          <MainTooltip
            tooltipMessage={TooltipError.CLIENT_NOT_AVAILABLE}
            isVisible={true}
            component="span"
            followCursor={true}
            componentClassName={classes.errorTooltip}
          >
            <ErrorIcon color={isHovered ? 'white' : 'status_error'} />
          </MainTooltip>
        )}
        <EllipsisTooltip refElement={ref} tooltipMessage={nameTooltipContent}>
          <div ref={ref} className={clsx(classes.listItemText)}>
            <HightLightText
              size="Main"
              color={isHovered ? 'TextWhite' : 'TextPrimary'}
              text={typografNames(cabinet.client_name || cabinet.username)}
              filterValue={searchValue}
            />
            &nbsp;
            <HightLightText
              size="caption"
              color={isHovered ? 'TextWhite' : 'TextSecondary'}
              text={`(${cabinet.username}, ${cabinet.client_id})`}
              filterValue={searchValue}
            />
          </div>
        </EllipsisTooltip>
      </div>
    </>
  );

  return (
    <CustomMenuItem
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={e => e.preventDefault()}
      onClick={() => changeCabinetSelection(cabinet)}
      className={clsx(classes.cabinetListItem, classes.foundCabinet)}
    >
      {regularItem}
    </CustomMenuItem>
  );
};
