import { ManageYDTabs } from '@plarin/inputs';
import { TObjType } from '../../types/metrics';

export enum ManageYDStatusEnum {}

export const mainTabData: Record<ManageYDTabs, string[]> = {
  // не уверен в правильности
  accounts: ['accountInfo', 'accountId'],
  campaigns: ['campaignName', 'campaignId'],
  groups: ['adGroupName', 'adGroupId'],
  ads: ['adId'],
};
