import { Typography } from '@plarin/design';
import { Button, TwoInputBlocks, menuItemsPath } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { TNameWorkspace } from 'types/workspace/types';
import { useAppDataStore } from '../../components/app-data-provider';
import { menuSectionIndexes } from '../../utils/constants';
import classes from './style.module.scss';

const WorkSpaceSettings = observer(() => {
  const {
    menu: { menuVisible, selectMenuItem },
    profileStore: { workspaceName, workspaceShortname, changeNameWorkspace },
  } = useAppDataStore();

  const [nameWorkspace, setNameWorkspace] = useState({
    company: workspaceName,
    shortname: workspaceShortname,
  });

  const [initNameWorkspace, setInitNameWorkspace] = useState({
    company: workspaceName,
    shortname: workspaceShortname,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDisabledInput, setIsDisabledInput] = useState(false);

  const onHandleAction = () => {
    if (!isDisabled || !isLoading) {
      setIsLoading(true);
      setIsDisabledInput(true);

      const updatedData: TNameWorkspace = {};

      if (nameWorkspace.shortname !== initNameWorkspace.shortname) {
        updatedData.shortname = nameWorkspace.shortname;
      }

      if (nameWorkspace.company !== initNameWorkspace.company) {
        updatedData.company = nameWorkspace.company;
      }

      Object.keys(updatedData).length > 0 &&
        changeNameWorkspace(updatedData).then(res => {
          selectMenuItem(menuItemsPath.SETTINGS, true, menuSectionIndexes.ws);

          setIsDisabledInput(false);
          setIsLoading(false);
        });
    }
  };
  const handleCancel = () => {
    setNameWorkspace({ company: workspaceName, shortname: workspaceShortname });
    setInitNameWorkspace({ company: workspaceName, shortname: workspaceShortname });
  };

  const isChanged = workspaceName !== nameWorkspace.company || workspaceShortname !== nameWorkspace.shortname;
  const isDisabled = nameWorkspace?.company?.length && nameWorkspace.company.trim().length ? isError : true;

  useEffect(() => {
    setInitNameWorkspace({ company: workspaceName, shortname: workspaceShortname });
  }, [workspaceName, workspaceShortname]);

  return (
    <div className={classes.containerPage}>
      <div className={classes.tabs}>
        <Typography weight={600} size="AuthContent" color="TextPrimary">
          Общие настройки
        </Typography>
      </div>
      <div className={classes.contentWrapper}>
        <div className={clsx(classes.cardWrapper, classes.firstCard)}>
          <Typography weight={600} size="IntegrationSelectChannelMessage" color="TextPrimary">
            Общие сведения
          </Typography>

          <TwoInputBlocks
            needAutofill
            initNameWorkspace={initNameWorkspace}
            workspaceName={nameWorkspace.company}
            workspaceShortname={nameWorkspace.shortname}
            disabledInput={isDisabledInput}
            onChangeData={data => setNameWorkspace(data)}
            direction="row"
            onError={error => setIsError(error)}
          />
        </div>
        <div className={classes.cardWrapper}>
          <Typography weight={600} size="IntegrationChannelMessage" color="TextPrimary">
            Настройки функционала
          </Typography>

          <div>
            <Typography size="Main" color="TextPrimary">
              Данный раздел появится в ближайшем будущем
            </Typography>
            {/* <div className={classes.settingItem}>
              <div className={classes.settingItemContent} onClick={() => {}}>
                <Typography size="Main" color="TextPrimary">
                  Сделать команду невидимой
                </Typography>
                <Typography size="Caption" color="TextSecondary">
                  Команда не видна никому, кроме участников группы
                </Typography>
              </div>
              <Switch checked={false} onChange={() => {}} />
            </div> */}
          </div>
        </div>
      </div>

      <div className={clsx(classes.actionFooter, !menuVisible && classes.widthFooter)}>
        <Button
          size="small"
          variant="filled"
          color="primary"
          onClick={onHandleAction}
          loading={isLoading}
          disabled={isDisabled || !isChanged}
        >
          Сохранить
        </Button>
        <Button size="small" variant="outlined" color="primary" onClick={handleCancel} disabled={isLoading}>
          Отменить
        </Button>
      </div>
    </div>
  );
});

export default WorkSpaceSettings;
