import { currency, int, percent } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import classes from '../style.module.scss';

export const CustomTooltip = (data: any) => {
  if (data.active && data.payload && data.payload.length) {
    return (
      <div className={classes.customTooltip}>
        <p className={classes.label}>{`Ставка ${currency(data.payload[0].payload.price, data.cur)}`}</p>
        <p className={classes.label}>{`Охват ${percent(data.payload[0].payload.share)}`}</p>

        <p className={classes.label}>{int(data.payload[0].payload.uniqs)}</p>
      </div>
    );
  }

  return null;
};
