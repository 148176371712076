import { BarChart } from '@plarin/inputs';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { ProjectsOverviewChartsResponse } from '../../../../../types/manage-vk/types';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { ScheduleLayout } from '../schedule-layout';

export const InstallationStatTable = ({ project_id }: { project_id: string }) => {
  const {
    projectOverview: { getChartsData },
  } = useAppDataStore();
  const [data, setData] = useState<ProjectsOverviewChartsResponse>();
  useEffect(() => {
    project_id &&
      getChartsData({
        project_id: project_id,
        network: 'mt',
        name: 'installs',
        relative_period: 'all_time',
        group_by: 'day',
      }).then(res => setData(res));
  }, [getChartsData, project_id]);

  return (
    <ScheduleLayout title="Динамика Установок">
      <BarChart data={data?.grouped} />
    </ScheduleLayout>
  );
};
