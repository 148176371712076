import { CircularProgress } from '@mui/material';
import React from 'react';
import classes from './style.module.scss';

export const CellLoader = () => {
  return (
    <div className={classes.preloaderWrapper}>
      <CircularProgress />
    </div>
  );
};
