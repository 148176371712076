import { ChevronRightIcon, DeleteIcon, PauseIcon, PlayIcon, RedoIcon, SmallArrowRightIcon } from '@plarin/design';
import React from 'react';
import { MTStatus } from '../../../types/manage-vk/types';
import { ActionKeysEnum, EditListEnum } from './commonEnums';

type TEditMenuList = {
  key: ActionKeysEnum;
  icon?: JSX.Element;
  rightIcon?: JSX.Element;
  label?: string;
  disabled?: boolean;
  typeEdit?: EditListEnum;
  status?: MTStatus | 'recovery';
}[];

export const editMenuList: TEditMenuList = [
  { key: ActionKeysEnum.start, icon: <PlayIcon />, label: 'Запустить', status: 'active' },
  { key: ActionKeysEnum.pause, icon: <PauseIcon />, label: 'Остановить', status: 'blocked' },
  {
    key: ActionKeysEnum.archive,
    icon: <DeleteIcon />,
    label: 'Архивировать',
    status: 'deleted',
  },
  {
    key: ActionKeysEnum.reestablish,
    icon: <RedoIcon />,
    label: 'Восстановить',
    // disabled: true,
    status: 'recovery',
  },
  {
    key: ActionKeysEnum.divider,
  },
  {
    key: ActionKeysEnum.bid,
    label: 'Изменить ставку',
    rightIcon: <ChevronRightIcon />,
    typeEdit: EditListEnum.bid,
  },
  // {
  //   key: ActionKeysEnum.limit,
  //   label: 'Изменить лимит',
  //   rightIcon: <SmallArrowRightIcon />,
  //   typeEdit: EditListEnum.limit,
  //   disabled: true,
  // },
  // {
  //   key: ActionKeysEnum.name,
  //   label: 'Изменить название',
  //   rightIcon: <SmallArrowRightIcon />,
  //   typeEdit: EditListEnum.name,
  // },
  // {
  //   key: ActionKeysEnum.timetable,
  //   label: 'Изменить расписание',
  //   rightIcon: <SmallArrowRightIcon />,
  //   typeEdit: EditListEnum.timeTable,
  //   disabled: true,
  // },
];
