import { InputAdornment, TextField } from '@mui/material';
import { CloseCircleIcon, SearchIcon } from '@plarin/design';
import React from 'react';
import { SearchIntegrationProps } from './search-inegration-props';
import './search-integration-table.scss';

export const SearchIntegrationTable: React.FC<SearchIntegrationProps> = ({
  value,
  onChange,
  deleteAction,
  ...props
}) => {
  return (
    <TextField
      placeholder="Поиск"
      variant="outlined"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
        classes: {
          root: 'TableInputRoot',
          input: 'TableInputinput',
          notchedOutline: 'visuallyHidden',
        },
        endAdornment:
          deleteAction && value.length ? (
            <CloseCircleIcon size={24} onClick={deleteAction} className="deleteAction" />
          ) : null,
      }}
      value={value}
      onChange={e => onChange?.(e.target.value)}
    />
  );
};
