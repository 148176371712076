import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Typography, SortDownSolidET, SortDownSolidETDisabled } from '@plarin/design';
import { getNumeral, int } from '@plarin/utils';
import clsx from 'clsx';
import React, { useState, useEffect, useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import { BidValidationMessage } from '../';
import { CustomMenuItem, CustomMenuItemSelect } from '../../../custom-menu-item';
import { getCommonDailyLimit, getCommonLifetimeLimit } from '../../../edit-bid/strategy-form-utils';
import commonClasses from '../common.module.scss';
import {
  updateNumericInputStates,
  TNewStrategyBids,
  getCurrencySymbol,
  TAccountCurrency,
  TCommonBudgetOptimizationLevel,
} from '../utils';
import classes from './style.module.scss';

// возвращает строку типа '(12 231 объявление)'
const totalAds = (amount: number): string => {
  const ads = ['объявление', 'объявления', 'объявлений'];

  return `${int(amount)}\u00a0${getNumeral(amount, ads)}`;
};

export enum LimitTypes {
  daily = 'За день',
  lifetime = 'За все время',
  differentLimitTypes = 'Limit type error (not daily, not lifetime)',
  bothLimitsHaveValue = 'Both limits have value',
}

const listOfLimitTypes: TListOfLimitTypes = [
  {
    value: LimitTypes.daily,
    label: LimitTypes.daily,
  },
  {
    value: LimitTypes.lifetime,
    label: LimitTypes.lifetime,
  },
];

type TListOfLimitTypes = {
  value: LimitTypes;
  label: LimitTypes;
}[];

type TLimitInputProps = {
  numericValue: string;
  setNumericValue: React.Dispatch<React.SetStateAction<string>>;
  minLimit: number;
  maxLimit: number;
  header: string;
  bottomText: string;
  bottomTextMaxError: string;
  isDiffLimitType: boolean;
  isDiffValue: boolean;
  setIsDiffValue: React.Dispatch<React.SetStateAction<boolean>>;
  currencySymbol: TAccountCurrency;
  isDisabled: boolean;
  mayRewriteLimits: boolean;
  setMayRewriteLimits: React.Dispatch<React.SetStateAction<boolean>>;
};

const LimitValueInput = ({
  numericValue,
  setNumericValue,
  minLimit,
  maxLimit,
  header,
  bottomText,
  bottomTextMaxError,
  currencySymbol,
  isDiffLimitType,
  isDiffValue,
  setIsDiffValue,
  isDisabled,
  mayRewriteLimits,
  setMayRewriteLimits,
}: TLimitInputProps) => {
  const [initialValue] = useState(numericValue);
  const hasMaxError = Number(numericValue) > maxLimit;
  const [hasError, setInputHasError] = useState(Number(numericValue) < minLimit || hasMaxError);

  const [isInputDisabled, setInputDisabled] = useState(isDisabled || isDiffLimitType);

  useEffect(() => {
    setInputDisabled(isDisabled || isDiffLimitType);
  }, [isDisabled, isDiffLimitType]);

  useEffect(() => {
    !isInputDisabled && setInputHasError(Number(numericValue) < minLimit || hasMaxError);
  }, [numericValue, isInputDisabled, minLimit]);

  // при множественном редактировании после того как впервые введём символ в инпут, разблокируем возможность редактировать значения лимитов в массиве newBidsData
  useEffect(() => {
    !mayRewriteLimits && initialValue !== numericValue && setMayRewriteLimits(true);
  }, [numericValue]);

  return (
    <>
      <div className={commonClasses.headerContainer}>
        <Typography
          size="TableRowSubHeader"
          color="TextSecondary"
          componentProps={{ className: commonClasses.inputBidHeader }}
        >
          {header}
        </Typography>
      </div>

      <NumericFormat
        disabled={isInputDisabled}
        value={isDiffValue ? undefined : numericValue}
        defaultValue={isDiffValue ? undefined : initialValue}
        valueIsNumericString={true}
        allowNegative={false}
        className={commonClasses.formControlWidth}
        customInput={TextField}
        InputProps={{
          endAdornment: getCurrencySymbol(currencySymbol),
          classes: {
            root: clsx(commonClasses.inputWrap, commonClasses.inputNumericWrap),
            input: commonClasses.inputInput,
            focused: commonClasses.inputFocused,
            error: commonClasses.inputError,
            disabled: commonClasses.disabledInput,
          },
          disabled: isDiffLimitType || isDisabled,
          error: !(isDiffValue || isDiffLimitType) && hasError,
        }}
        decimalSeparator=","
        thousandSeparator=" "
        decimalScale={2}
        onValueChange={values => updateNumericInputStates(values, setNumericValue)}
        placeholder={isDiffValue || isDiffLimitType ? 'Разные значения' : undefined}
      />

      <BidValidationMessage
        text={hasMaxError ? bottomTextMaxError : bottomText}
        isError={!(isDiffValue || isDiffLimitType) && hasError}
      />
    </>
  );
};

type TLimitTypeProps = {
  limitType: LimitTypes;
  setLimitType: React.Dispatch<React.SetStateAction<LimitTypes>>;
  isDiff: boolean;
  setIsDiff: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled: boolean;
  mayRewriteLimits: boolean;
  setMayRewriteLimits: React.Dispatch<React.SetStateAction<boolean>>;
};

const LimitTypeInput = ({
  limitType,
  setLimitType,
  isDiff,
  setIsDiff,
  isDisabled,
  mayRewriteLimits,
  setMayRewriteLimits,
}: TLimitTypeProps) => {
  const [initialLimitType] = useState(limitType);
  const [isOpen, setIsOpen] = useState(false);

  const handleLimitTypeChange = (event: SelectChangeEvent) => {
    // @ts-ignore
    setLimitType(event.target.value);
    isDiff && setIsDiff(false);
  };

  useEffect(() => {
    !mayRewriteLimits && limitType !== initialLimitType && setMayRewriteLimits(true);
  }, [limitType]);

  return (
    <>
      <Select
        disabled={isDisabled}
        className={commonClasses.inputSelect}
        variant="outlined"
        value={isDiff ? 'isDiff' : limitType}
        onChange={handleLimitTypeChange}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        IconComponent={isDisabled ? SortDownSolidETDisabled : SortDownSolidET}
        MenuProps={{
          className: commonClasses.menuOverlay,
          classes: { paper: commonClasses.menuPaper },
        }}
        classes={{
          root: clsx(
            commonClasses.inputWrap,
            classes.limitTypeInput,
            isOpen && commonClasses.inputFocused,
            (isDiff || isDisabled) && commonClasses.diffPlaceholder,
            isDisabled && commonClasses.disabledInput,
          ),
          select: commonClasses.inputInput,
          disabled: commonClasses.disabledInput,
        }}
        inputProps={{
          classes: {
            icon: clsx('rotatable', commonClasses.iconSize),
            iconOpen: 'deg180',
          },
        }}
      >
        {isDiff && (
          <CustomMenuItem value="isDiff" style={{ display: 'none' }}>
            Разные значения
          </CustomMenuItem>
        )}
        {listOfLimitTypes.map((option, index) => (
          <CustomMenuItemSelect
            key={option.value}
            value={option.value}
            tabIndex={index}
            selectedItem={option.value === limitType}
          >
            {option.label}
          </CustomMenuItemSelect>
        ))}
      </Select>
    </>
  );
};

type TBudgetLimitProps = {
  commonDailyLimit: number | undefined;
  setCommonDailyLimit: React.Dispatch<React.SetStateAction<number | undefined>>;
  commonLifetimeLimit: number | undefined;
  setCommonLifetimeLimit: React.Dispatch<React.SetStateAction<number | undefined>>;
  limitType: LimitTypes;
  setLimitType: React.Dispatch<React.SetStateAction<LimitTypes>>;
  adsAmount: number;
  minLimit: number;
  maxLimit: number;
  currencySymbol: string;
  newBidsData: TNewStrategyBids;
  setNewBidsData: React.Dispatch<React.SetStateAction<TNewStrategyBids>>;
  budgetOptimizationLevel: TCommonBudgetOptimizationLevel;
  allDisabled?: boolean;
  isBlockedBudget: boolean;
};

export const BudgetLimit = ({
  commonDailyLimit, // равен undefined, если у нескольких выбранных сущностей разные значения в limitDaily
  setCommonDailyLimit,
  commonLifetimeLimit, // равен undefined, если у нескольких выбранных сущностей разные значения в limitLifetime
  setCommonLifetimeLimit,
  limitType,
  setLimitType,
  adsAmount,
  minLimit,
  maxLimit,
  currencySymbol,
  newBidsData,
  setNewBidsData,
  budgetOptimizationLevel,
  allDisabled = false,
  isBlockedBudget,
}: TBudgetLimitProps) => {
  const [numericLimit, setNumericLimit] = useState<string>(String(commonDailyLimit || commonLifetimeLimit || 0));

  // редактируются несколько сущностей, и у них разный тип лимита бюджета (за день / за всё время)
  const [isDiffLimitType, setIsDiffLimitType] = useState(limitType === LimitTypes.differentLimitTypes);

  // редактируются несколько сущностей, и у них неодинаковый размер лимита бюджета
  const [isDiffLimitValue, setIsDiffLimitValue] = useState(
    commonDailyLimit === undefined || commonLifetimeLimit === undefined,
  );

  // при множественном редактировании, если у всех сущностей один тип лимита, но разное значение лимита, мы запретим с помощью этого стейта записывать нули в оба лимита при событии componentDidMount
  const [mayRewriteLimits, setMayRewriteLimits] = useState(
    !(newBidsData.length > 1 && !isDiffLimitType && isDiffLimitValue),
  );

  useEffect(() => {
    (commonDailyLimit || commonLifetimeLimit) && // это условие необходимо, чтобы стейт isDiffLimitValue становился true только когда мы положим в одну из этих переменных положительное число, а не ноль (ноль кладётся в обе переменные при componentDidMount плюс при множественном редактировании, но с одним типом бюджета)
      setIsDiffLimitValue(commonDailyLimit === undefined || commonLifetimeLimit === undefined);
  }, [commonDailyLimit, commonLifetimeLimit]);

  useEffect(() => {
    if (limitType === LimitTypes.daily) {
      setCommonDailyLimit(Number(numericLimit));
      setCommonLifetimeLimit(0);

      mayRewriteLimits &&
        setNewBidsData(
          newBidsData.map(item => {
            return {
              ...item,
              limitDaily: Number(numericLimit),
              limitLifetime: 0,
            };
          }),
        );
    }

    if (limitType === LimitTypes.lifetime && mayRewriteLimits) {
      setCommonDailyLimit(0);
      setCommonLifetimeLimit(Number(numericLimit));

      mayRewriteLimits &&
        setNewBidsData(
          newBidsData.map(item => {
            return {
              ...item,
              limitDaily: 0,
              limitLifetime: Number(numericLimit),
            };
          }),
        );
    }
  }, [limitType, numericLimit, mayRewriteLimits]);

  const bottomText = useMemo(() => {
    if (isBlockedBudget) {
      return 'Проверяем количество объявлений';
    }

    if (adsAmount === 0) {
      return `Минимум\u00a0${int(minLimit)}\u00a0${currencySymbol}`;
    }

    return `Минимум\u00a0${int(minLimit)}\u00a0${currencySymbol} (${totalAds(adsAmount)})`;
  }, [minLimit, currencySymbol, adsAmount, isBlockedBudget]);

  return (
    <div className={commonClasses.minMaxWrap}>
      <div className={commonClasses.minMaxItem}>
        {/* Бюджет: верхнее ограничение */}
        <LimitValueInput
          numericValue={numericLimit}
          setNumericValue={setNumericLimit}
          minLimit={minLimit}
          maxLimit={maxLimit}
          header={budgetOptimizationLevel === 'adPlan' ? 'Бюджет кампании' : 'Бюджет группы объявлений'}
          bottomText={bottomText}
          bottomTextMaxError={`Не более ${int(maxLimit)}\u00a0${currencySymbol}`}
          isDiffLimitType={isDiffLimitType}
          isDiffValue={isDiffLimitValue}
          setIsDiffValue={setIsDiffLimitValue}
          // @ts-ignore TODO: типы поправить
          currencySymbol={currencySymbol}
          isDisabled={allDisabled}
          mayRewriteLimits={mayRewriteLimits}
          setMayRewriteLimits={setMayRewriteLimits}
        />
      </div>
      <div className={commonClasses.minMaxItem}>
        {/* За день / За всё время */}
        <LimitTypeInput
          limitType={limitType}
          setLimitType={setLimitType}
          isDiff={isDiffLimitType}
          setIsDiff={setIsDiffLimitType}
          isDisabled={allDisabled}
          mayRewriteLimits={mayRewriteLimits}
          setMayRewriteLimits={setMayRewriteLimits}
        />
      </div>
    </div>
  );
};
