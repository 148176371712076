import { SmallArrowDownIcon, Typography } from '@plarin/design';
import { Button, Popover, GridApi, TRows, StatusToSet, statusToSet } from '@plarin/inputs';
import { ManageYDTabNameEnum } from '@plarin/utils';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import {
  returnItemIdByTabName,
  maySetStatus,
  returnItemStateByTabName,
  returnItemStatusByTabName,
} from '../../utils/manage-yd';
import { useAppDataStore } from '../app-data-provider';
// import { EditListEnum } from './commonEnums';
// import { EditBid } from './edit-bid';
import { EditMenuLayout } from './edit-menu-layout';
import { editMenuListCampaigns, editMenuListAds } from './edit-menu-list';
import classes from './style.module.scss';

type ManageYdEditMenuProps = {
  disabled?: boolean;
  gridApi: GridApi;
  tabName: ManageYDTabNameEnum;
};

// type TVisionBox = string  | EditListEnum;

// const editRenderer = (visionBox: string, close: () => void, selectedRows: TRows, gridApi: GridApi) => {
//   switch (visionBox) {
//     case EditListEnum.bid:
//       // return <EditBid close={close} selectedRows={selectedRows} gridApi={gridApi} />;
//       return <></>;

//     default:
//       return (
//         <Typography size="Title" weight={600}>
//           “Здесь скоро что-то появится“
//         </Typography>
//       );
//   }
// };

export const ManageYdEditMenu = ({ disabled, gridApi, tabName }: ManageYdEditMenuProps) => {
  const {
    manageYD: { changeManageYdStatus },
  } = useAppDataStore();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [visionBox, setVisionBox] = useState<TVisionBox>(''); // в будущем будет нужно для отображения формы изменения ставки

  const [selectedRows, setSelectedRows] = useState<TRows>([]);

  const actionsList = tabName === ManageYDTabNameEnum.CAMPAIGNS ? editMenuListCampaigns : editMenuListAds;

  useEffect(() => {
    gridApi &&
      setSelectedRows(
        gridApi
          .getSelectedRows()
          .filter(el =>
            new Set(Object.keys(el)).has('orgHierarchy') ? !!el.orgHierarchy && el.orgHierarchy.length : true,
          ),
      );
  }, [gridApi, anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // setVisionBox('');
    setAnchorEl(null);
  };

  // формируем массив сущностей, которым можно включить выбранный статус
  const idsCampsByStatus = useCallback(
    (status: StatusToSet) => {
      const id = returnItemIdByTabName(tabName); // находим, в каком свойстве объекта лежит id этого объекта
      const stateName = returnItemStateByTabName(tabName); // в каком свойсте лежит стейт
      const statusName = returnItemStatusByTabName(tabName); // в каком свойсте лежит статус

      return maySetStatus(selectedRows, status, id, stateName, statusName);
    },
    [selectedRows],
  );

  const changeStatus = (status: StatusToSet, gridApi: GridApi) => {
    const ids = idsCampsByStatus(status).map(el => +el);
    changeManageYdStatus(ids, status, tabName, gridApi).then();
    handleClose();
  };

  const getSelectedAmount = (status?: StatusToSet) => {
    if (status) {
      const amount = idsCampsByStatus(status).length;
      return amount ? `(${idsCampsByStatus(status).length})` : '';
    }

    // для экшенов, не работающих со статусом, подсчитываем просто количество выделенных сущностей (например, экшен по изменению бюджета)
    const amount = selectedRows?.length;
    return amount ? `(${selectedRows?.length})` : '';
  };

  // формируем список пунктов для выпадающего менб
  const dataList = actionsList
    .filter((actionButton, index) => actionButton.key !== '0')
    .map(actionButton => {
      return {
        name: `${actionButton.label} ${getSelectedAmount(actionButton.status)}`,
        action: () => {
          handleClose();
          if (!actionButton.typeEdit) {
            actionButton.status && changeStatus(actionButton.status, gridApi);
          }
        },
        iconLeft: (e: boolean | undefined) => (
          <span className={clsx(e && classes.iconLeftColor)}>{actionButton.icon}</span>
        ),
        isDisabled: (actionButton.status && !getSelectedAmount(actionButton.status)) || disabled,
      };
    });

  return (
    <div style={{ position: 'relative' }}>
      <Button
        onClick={handleClick}
        color="secondary"
        disabled={disabled}
        active={!!anchorEl}
        className={classes.selectButton}
      >
        <div className={classes.buttonContent}>
          <Typography weight={600} size="Main" color={disabled ? 'TextDisabled' : 'TextPrimary'}>
            Редактировать
          </Typography>
          <SmallArrowDownIcon className={clsx(!!anchorEl && classes.rotate)} color={disabled ? 'disabled' : 'main'} />
        </div>
      </Button>

      <Popover
        // footer={footerItemJSX()}
        setIsOpen={handleClose}
        anchorEl={anchorEl}
        isOpen={!!anchorEl}
        dataList={dataList}
        classNameWrapper={classes.wrapperList}
      />
    </div>
  );
};
