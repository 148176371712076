import { CloseCircleIcon, ErrorIcon, Typography } from '@plarin/design';
import { EllipsisTooltip, MainTooltip } from '@plarin/inputs';
import { TooltipError, TooltipMessage, typografNames } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { TShortCabinetsFilters } from '../../../../../../../types/projects/projects';
import { useAppDataStore } from '../../../../../app-data-provider';
import classes from '../project-info.module.scss';

interface IProjectCabinetItem {
  cabinet: TShortCabinetsFilters;
  isArchived: boolean;
}

export const ProjectCabinetItem = observer(({ cabinet, isArchived }: IProjectCabinetItem) => {
  const [isHovered, setIsHovered] = useState(false);
  const [show, setShow] = useState(false);

  const {
    projects: { setProjectsState, setModalData, projectsState, projectsData, editProject },
  } = useAppDataStore();

  const nameTooltipContent = cabinet.client_name
    ? `${cabinet.client_name} (${cabinet.username}, ${cabinet.client_id})`
    : `(${cabinet.username}, ${cabinet.client_id})`;

  return (
    <div
      data-isarchived={isArchived}
      className={classes.projectCabinetsList}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={e => e.preventDefault()}
    >
      <div className={clsx(classes.listItemAdded, classes.gapCabinet)}>
        {cabinet.has_error && (
          <MainTooltip
            tooltipMessage={TooltipError.CLIENT_NOT_AVAILABLE}
            isVisible={true}
            component="span"
            followCursor={true}
          >
            <ErrorIcon color="status_error" />
          </MainTooltip>
        )}
        <div className={classes.cabinetInfo}>
          <EllipsisTooltip tooltipMessage={nameTooltipContent} isShowTooltipVisibleOff={setShow}>
            <div className={classes.textBlock}>
              <Typography color="TextPrimary" size="Main" weight={400}>
                {cabinet.client_name ? typografNames(cabinet.client_name) : typografNames(cabinet.username)}
              </Typography>
              &nbsp;
              <Typography size="Main" color="TextSecondary" ellips>
                {typografNames(` (${cabinet.username}, ${cabinet.client_id})`)}
              </Typography>
            </div>
          </EllipsisTooltip>
          <div className={clsx(classes.deleteCabinetProjectHover, !isHovered && classes.deleteCabinetProject)}>
            {isArchived && (
              <MainTooltip
                tooltipMessage={TooltipMessage.DELETE_FROM_LIST}
                isVisible={true}
                component="span"
                followCursor={true}
              >
                <CloseCircleIcon
                  onClick={() => {
                    projectsData
                      .find(el => el._id === editProject.editProjectData?._id)
                      ?.tm_client_ids?.includes(cabinet.client_id)
                      ? setModalData({ type: 'untie', data: { cabinet: cabinet } })
                      : setProjectsState(
                          'selectedCabinets',
                          projectsState.selectedCabinets.filter(e => e.client_id !== cabinet.client_id),
                        );
                  }}
                />
              </MainTooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
