import { SetCustomerNameContent } from '@plarin/inputs';
import { observer } from 'mobx-react';
import { useAppDataStore } from '../../../../components/app-data-provider';

export const ChangeNameContent = observer(() => {
  const {
    profileStore: { data, setModalAction, changeProfileName, isLoading, closeModal },
  } = useAppDataStore();

  return SetCustomerNameContent({
    firstName: data.fname || '',
    lastName: data.lname || '',
    setModalAction: setModalAction,
    changeCustomerName: changeProfileName,
    isLoading: isLoading,
    closeModal: closeModal,
    public_id: '',
  });
});
