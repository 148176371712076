import { Typography } from '@plarin/design';
import React from 'react';
import './retry-button.scss';

export const RetryButton: React.FC<{
  onClick?: VoidFunction;
}> = ({ children, onClick }) => (
  <button type="button" className="retryButton" onClick={onClick}>
    <div className="retryButton__icon" />
    <Typography size="authSubheader" color="LinkPrimary" weight>
      {children}
    </Typography>
  </button>
);
