export interface IAppLocalStorage {
  set: (key: string, value: string | null) => void;
  get: (key: string) => string | null;
}

export class AppLocalStorage implements IAppLocalStorage {
  private readonly localStorageKeyPrefix: string;

  constructor(localStorageKeyPrefix: string) {
    this.localStorageKeyPrefix = localStorageKeyPrefix;
  }
  tableState = () => {
    const tableState = localStorage.getItem('columnState');
    !tableState && localStorage.setItem('columnState', JSON.stringify({}));
  };
  get = (key: string) => localStorage.getItem(`${this.localStorageKeyPrefix}_${key}`);
  set(key: string, value: string | null) {
    if (value) {
      localStorage.setItem(`${this.localStorageKeyPrefix}_${key}`, value);
    } else {
      localStorage.removeItem(`${this.localStorageKeyPrefix}_${key}`);
    }
  }
}

export class AppSessionStorage implements IAppLocalStorage {
  private readonly localStorageKeyPrefix: string;

  constructor(localStorageKeyPrefix: string) {
    this.localStorageKeyPrefix = localStorageKeyPrefix;
  }

  get = (key: string) => sessionStorage.getItem(`${this.localStorageKeyPrefix}_${key}`);
  set(key: string, value: string | null) {
    if (value) {
      sessionStorage.setItem(`${this.localStorageKeyPrefix}_${key}`, value);
    } else {
      sessionStorage.removeItem(`${this.localStorageKeyPrefix}_${key}`);
    }
  }
}
