import { Button } from '@plarin/inputs';
import React from 'react';
import { DrawerId } from '../../../enums';
import classes from './style.module.scss';

type FooterType = {
  isDisabled: boolean;
  isLoading: boolean;
  sendProposeIdea: () => void;
  setCloseDrawer: (id: number) => void;
};

export const FooterIdea = ({ isDisabled, isLoading, sendProposeIdea, setCloseDrawer }: FooterType) => {
  return (
    <div className={classes.footer}>
      <Button
        disabled={isDisabled}
        loading={isLoading}
        variant="filled"
        color="primary"
        size="small"
        onClick={sendProposeIdea}
      >
        Отправить
      </Button>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => setCloseDrawer(DrawerId.proposeIdea)}
        disabled={isLoading}
      >
        Отменить
      </Button>
    </div>
  );
};
