import { CloseCircleIcon, EditIcon, Typography } from '@plarin/design';
import { EllipsisTooltip, MainTooltip } from '@plarin/inputs';
import { TooltipMessage } from '@plarin/utils';
import { typografNames } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState, useMemo } from 'react';
import { useAppDataStore } from '../../../../../components/app-data-provider';
import classes from './style.module.scss';
import { TagEditInput } from './tag-edit-input';
import { TTagLineProps } from './types';

export const TagLine = observer(
  ({ tag, saveTag, deleteTag, editTag, canChange, workspaceTags, isChanged }: TTagLineProps) => {
    const {
      members: { openModalMember },
    } = useAppDataStore();

    const [isHover, setIsHover] = useState(false);

    const tagName = useMemo(() => typografNames(tag.name), [tag]);

    useEffect(() => {
      setIsHover(false);
    }, [editTag]);

    return tag.edit ? (
      <TagEditInput
        tag={tag}
        editTag={editTag}
        canChange={canChange}
        workspaceTags={workspaceTags}
        deleteTag={deleteTag}
        saveTag={saveTag}
        isChanged={isChanged}
      />
    ) : (
      <div
        className={classes.teamsListItem}
        onMouseLeave={() => setIsHover(false)}
        onMouseOver={() => !isHover && setIsHover(true)}
        onClick={() => editTag(tag.id)}
      >
        <div className={classes.iconBlock}>
          <EllipsisTooltip tooltipMessage={tagName}>
            <Typography size="AuthContent" ellips={true} color="TextPrimary">
              {tagName}
            </Typography>
          </EllipsisTooltip>
          {canChange && (
            <MainTooltip tooltipMessage="Редактировать" isVisible={true} component="span" followCursor={true}>
              <EditIcon onClick={() => editTag(tag.id)} className={classes.clickable} />
            </MainTooltip>
          )}
        </div>

        {canChange && (
          <div className={classes.deleteAction}>
            {isHover && (
              <MainTooltip
                tooltipMessage={TooltipMessage.DELETE_FROM_LIST}
                isVisible={true}
                component="span"
                followCursor={true}
              >
                <CloseCircleIcon
                  onClick={e => {
                    e.stopPropagation();
                    !isChanged ? openModalMember('removeTag', tagName, () => deleteTag(tag.id)) : deleteTag(tag.id);
                  }}
                />
              </MainTooltip>
            )}
          </div>
        )}
      </div>
    );
  },
);
