import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import classes from './skeleton-integration.module.scss';
import { Skeleton } from './skeleton-table-row';

export const SkeletonIntegration = ({
  isHeader,
  isColor,
  alertVisible,
}: {
  isHeader?: boolean;
  isColor?: boolean;
  alertVisible?: boolean;
}) => {
  const heightHeader = alertVisible ? 560 : 500;

  const numberOfItems = Math.floor((document.documentElement.clientHeight - heightHeader) / 50);

  const [rows, setRows] = useState<number[]>([...(Array(Math.max(numberOfItems, 0)).fill(1) || [1])]);

  useEffect(() => {
    const numberOfItems = Math.floor((document.documentElement.clientHeight - heightHeader) / 50);
    setRows([...Array(Math.max(numberOfItems, 0)).fill(1)]);
  }, [heightHeader]);

  const ItemRight = () => (
    <div className={classes.itemRight}>
      <div className={classes.cell}>
        <Skeleton height={17} size="maxLong" />
      </div>
      <div className={classes.cell}>
        <Skeleton height={17} size="maxLong" />
      </div>
    </div>
  );

  return (
    <>
      <div className={clsx(classes.listItem, classes.headerList)}>
        <div className={clsx(classes.itemLeft, classes.cell)}>
          <Skeleton height={17} size="maxLong" />
        </div>
        <ItemRight />
      </div>
      <div className={classes.listContainer}>
        {rows.map(item => (
          <div className={classes.listItem} key={item}>
            <div className={clsx(classes.itemLeft, classes.cell)}>
              <Skeleton height={17} size="maxLong" />
              <Skeleton height={17} size="short" />
            </div>
            <ItemRight />
          </div>
        ))}
      </div>
    </>
  );
};
