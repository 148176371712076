export const daysAbbreviation = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const stylesDateRangePicker = {
  marginRight: 0,
  display: 'none',

  '.StaticDateRangePicker': {
    position: 'relative',
  },
  '.StaticDateRangePicker > div': {
    gap: '28px',
  },
  // левый календарь
  '.StaticDateRangePicker > div > div:first-of-type': {
    borderRight: 'none',
  },
  // хедэры календаря с месяцем и годом
  '.StaticDateRangePicker > div > div > div:first-of-type': {
    borderRight: 'none',
    padding: '4px 3px',
    marginBottom: '6px',
  },
  '.StaticDateRangePicker > div > div > div:first-of-type > span.MuiTypography-subtitle1': {
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '24px',
  },
  // левая половина хедэра календаря
  '.StaticDateRangePicker > div:first-of-type > div:first-of-type > div:first-of-type ': {
    marginLeft: '-10px',
    borderRadius: '4px 0 0 4px',
  },
  // правая половина хедэра календаря
  '.StaticDateRangePicker > div:first-of-type > div:nth-of-type(2n) > div:first-of-type': {
    borderRadius: '0 4px 4px 0',
    marginRight: '-10px',
  },
  // тело календаря, в котором перечислены дни месяца
  '.StaticDateRangePicker > div > div > div:last-of-type': {
    minHeight: '245px',
    minWidth: '252px',
  },
  '.StaticDateRangePicker > div > div > div:last-of-type>div>div': {
    margin: '4px 0',
  },
  // день календаря, на который можно нажать
  '.StaticDateRangePicker button': {
    fontSize: '14px',
  },
  '.PrivatePickersSlideTransition-root button:hover': {
    border: '1px solid #214ECB ',
    backgroundColor: '#214ECB',
  },
  // надпись с месяцем и годом в хедэре календаря
  'span.MuiTypography-subtitle1': {
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  // элемент, в котором указан день недели
  'span.MuiTypography-root.MuiTypography-caption': {
    fontSize: '14px',
    width: '36px',
    height: '36px',
    margin: '0px',
    color: 'black',
  },
  // суббота и воскресенье (должны быть красного цвета)
  'span.MuiTypography-root.MuiTypography-caption:nth-of-type(5n) ~ span': {
    color: '#D81823',
  },

  // дни месяца, перечисленные в календаре (элементы, внутри которых находится номер дня месяца)
  '.MuiDateRangePickerDay-day': {
    height: '30px',
    width: '32px',
  },
  // выбранный день месяца в календаря
  'button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
    backgroundColor: '#214ECB',
  },
  'button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected:hover': {
    backgroundColor: '#0040B8 ',
    borderColor: '1px solid #0040B8 ',
  },
  // дни месяца, находящиеся между двумя выбранными днями
  '.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
    backgroundColor: '#e6eeff',
  },

  '.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd': {
    borderTopRightRadius: '50%  !important',
    borderBottomRightRadius: '50%  !important',
  },
  '.MuiDateRangePickerDay-rangeIntervalDayHighlightStart': {
    borderTopLeftRadius: '50%  !important',
    borderBottomLeftRadius: '50%  !important',
  },
  // первый и последний выделенные дни
  '.MuiDateRangePickerDay-root': {
    marginLeft: '0px',
    marginRight: '0px',
    height: '36px !important',
  },
  '.MuiDateRangePickerDay-root:last-of-type': {
    borderRadius: '0%',
  },
  '.MuiDateRangePickerDay-root:first-of-type': {
    borderRadius: '0%',
  },

  // превью диапозона выбранных дат

  '.MuiDateRangePickerDay-rangeIntervalDayPreview': {
    background: '#EFF2F5',
    border: '2px solid #EFF2F5 !important',
    borderRadius: '0px !important',
    color: 'red',
  },
  '.MuiDateRangePickerDay-rangeIntervalDayPreview button:hover': {
    backgroundColor: '#214ECB !important',
    border: '1px solid #214ECB',
    color: 'white',
  },
  '.MuiDateRangePickerDay-rangeIntervalDayPreviewEnd': {
    borderTopRightRadius: '50%  !important',
    borderBottomRightRadius: '50%  !important',
  },
  '.MuiDateRangePickerDay-rangeIntervalDayPreviewStart': {
    borderTopLeftRadius: '50%  !important',
    borderBottomLeftRadius: '50%  !important',
  },
};
