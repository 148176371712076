import { BasicStore, httpClient2 } from '@plarin/core';
import { NotificationErrorTitle, NotificationTitle } from '@plarin/utils';
import isEqual from 'lodash/isEqual';
import { makeObservable, observable, runInAction } from 'mobx';
import { TWsProject } from '../../types/profile/types';
import {
  IEditProject,
  IGuestsState,
  IPostEditProjects,
  IPostProjects,
  IprojectsState,
  IstatusWSroles,
  ITeamsState,
  TShortCabinets,
  TTeams,
  TWsProjectStatus,
} from '../../types/projects/projects';
import { TShortClientsResponse } from '../../types/reports/reports';
import { ResponseModel_Dict_ } from '../../types/workspace/types';
import { sortRow } from '../utils/common';
import { STATUS } from '../utils/constants';

const valuesProjectStatus: TWsProjectStatus = 'active';

const defaultProjectsState = {
  creatPostProject: null,
  projectsName: '',
  projectManagers: '',
  searchValueCabinet: '',
  selectedCabinets: [],
  filteredCabinets: [],
  valuesProjectStatus,
};

const defaultTeamsState = {
  isOpenModalTeams: false,
  selectedMembersTeam: [],
  selectedTeams: [],
  searchValue: '',
  filteredTeams: null,
};

const defaultGuestsState = {
  isOpenModalGuests: false,
  selectedMembersGuests: [],
  selectedGuests: [],
  searchValueGuests: '',
  filteredGuests: null,
  guests: [],
};

const defaultEditProject = {
  isOpen: false,
  isEditProject: false,
  editProjectData: null,
};

const defaultStatusWSroles = {
  isAdmin: false,
  isManager: false,
  isLeader: false,
};

interface ModalData {
  type: 'completed' | 'active' | 'archive' | 'unArchive' | 'delete' | 'untie' | 'saveExit' | '';
  data?: any;
}

type IDataState = Partial<IprojectsState | ITeamsState | IGuestsState>;

export class ProjectsStore extends BasicStore {
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  searchTableProject: string = '';
  editProject: IEditProject = defaultEditProject;
  projectsState: IprojectsState = defaultProjectsState;
  shortCabinets: TShortCabinets = { error: false, loading: false, clients: [] };
  projectsData: TWsProject[] = [];
  hasLoadingError: boolean = false;
  teams: TTeams = { error: false, loading: false, teams: [] };
  teamsState: ITeamsState = defaultTeamsState;
  guestsState: IGuestsState = defaultGuestsState;
  statusWSroles: IstatusWSroles = defaultStatusWSroles;
  modalData: ModalData = {} as ModalData;
  isDrawerDataChanged: boolean = false;
  isProjectStateChanged: boolean = false;
  isTeamsStateChanged: boolean = false;
  isGuestsStateChanged: boolean = false;
  inititalProjectsState: IprojectsState = defaultProjectsState;
  initialTeamsState: ITeamsState = defaultTeamsState;
  initialGuestsState: IGuestsState = defaultGuestsState;

  constructor() {
    super();
    makeObservable<this>(this, {
      isLoading: observable,
      isTableLoading: observable,
      projectsData: observable,
      shortCabinets: observable,
      projectsState: observable,
      teamsState: observable,
      guestsState: observable,
      editProject: observable,
      modalData: observable,
      statusWSroles: observable,
      hasLoadingError: observable,
      searchTableProject: observable,
      isDrawerDataChanged: observable,
      isProjectStateChanged: observable,
      isTeamsStateChanged: observable,
      isGuestsStateChanged: observable,
      inititalProjectsState: observable,
      initialTeamsState: observable,
      initialGuestsState: observable,
    });
  }

  isChanged(initialData: IDataState, drawerData: IDataState): boolean {
    function compareObjects(obj1: any, obj2: any): number {
      const key1 = JSON.stringify(obj1);
      const key2 = JSON.stringify(obj2);
      if (key1 < key2) {
        return -1;
      } else if (key1 > key2) {
        return 1;
      } else {
        return 0;
      }
    }

    function isArrayDifferent(arr1: any[], arr2: any[]): boolean {
      if (arr1.length !== arr2.length) {
        return true;
      }
      const sortedArr1 = arr1.slice().sort(compareObjects);
      const sortedArr2 = arr2.slice().sort(compareObjects);

      return !sortedArr1.every((item, index) => {
        // Костыль: При добавлении команды в объекте передаются дополнительное поле 'status', которого нет в объектах уже добавленных команд.
        // Чтобы это обойти, исключаем это поле из проверки.
        const item1 = { ...item };
        const item2 = { ...sortedArr2[index] };
        delete item1.status;
        delete item2.status;
        return isEqual(item1, item2);
      });
    }

    function isProjectManagerChanged(initialData: any, drawerData: any) {
      const noProjectManagerNotChanged = initialData.projectManagers === null && drawerData.projectManagers === 'not';
      if (!noProjectManagerNotChanged && initialData.projectManagers !== drawerData.projectManagers) {
        return true;
      }
    }

    const ignoredKeys = [
      'isOpenModalTeams',
      'isOpenModalGuests',
      'searchValue',
      'searchValueGuests',
      'searchValueCabinet',
      'filteredCabinets',
      'filteredTeams',
      'filteredGuests',
      'selectedMembersTeam',
      'selectedMembersGuests',
      'status',
    ];

    for (const key in initialData) {
      if (ignoredKeys.includes(key)) {
        continue;
      }

      const initialValue = initialData[key as keyof typeof initialData];

      const drawerValue = drawerData[key as keyof typeof drawerData];
      const isArray = Array.isArray(initialValue);
      const isProjectManager = key === 'projectManagers';

      // 1 сравниваем свойства объектов, в которых лежат массивы
      if (isArray && isArrayDifferent(initialValue, drawerValue)) {
        return true;
      }
      // 2 сравниваем значения по ключу projectManagers
      else if (isProjectManager && isProjectManagerChanged(initialData, drawerData)) {
        return true;
      }
      // 3 сравниваем все оставшиеся значения: строки и булевы значения
      else if (!isArray && !isProjectManager && initialValue !== drawerValue) {
        return true;
      }
    }
    return false;
  }

  setProjects = (data: TWsProject[]) => runInAction(() => (this.projectsData = data));
  setSearchTableProject = (value: string) => runInAction(() => (this.searchTableProject = value));
  setStatusWSroles = (statusWSroles: IstatusWSroles) => runInAction(() => (this.statusWSroles = statusWSroles));

  private setTableLoading = (isLoading: boolean) => runInAction(() => (this.isTableLoading = isLoading));

  setIsLoading = (loading: boolean) => runInAction(() => (this.isLoading = loading));

  setHasError = (isError: boolean) => runInAction(() => (this.hasLoadingError = isError));

  setModalData = (data: ModalData) => runInAction(() => (this.modalData = data));

  setIsDrawerDataChanged = (isChanged: boolean) =>
    runInAction(() => {
      this.isDrawerDataChanged = isChanged;
      this.isProjectStateChanged = isChanged;
      this.isGuestsStateChanged = isChanged;
    });

  setIsProjectStateChanged = (isProjectStateChanged: boolean) =>
    runInAction(() => (this.isProjectStateChanged = isProjectStateChanged));

  setIsTeamsStateChanged = (isTeamsStateChanged: boolean) =>
    runInAction(() => (this.isTeamsStateChanged = isTeamsStateChanged));

  setIsGuestsStateChanged = (isGuestsStateChanged: boolean) =>
    runInAction(() => (this.isGuestsStateChanged = isGuestsStateChanged));

  setInitialProjectsState = () => runInAction(() => (this.inititalProjectsState = this.projectsState));
  setInitialTeamsState = () => runInAction(() => (this.initialTeamsState = this.teamsState));
  setInitialGuestsState = () => runInAction(() => (this.initialGuestsState = this.guestsState));

  setProjectsState = (elKey: string, elValue: any) =>
    runInAction(() => {
      this.projectsState = { ...this.projectsState, [elKey]: elValue };
      this.isProjectStateChanged = this.isChanged(this.inititalProjectsState, this.projectsState);
      this.isDrawerDataChanged = this.isProjectStateChanged || this.isTeamsStateChanged || this.isGuestsStateChanged;
    });

  setTeamsState = (elKey: string, elValue: any) =>
    runInAction(() => {
      this.teamsState = { ...this.teamsState, [elKey]: elValue };
      this.isTeamsStateChanged = this.isChanged(this.initialTeamsState, this.teamsState);
      this.isDrawerDataChanged = this.isProjectStateChanged || this.isTeamsStateChanged || this.isGuestsStateChanged;
    });

  setGuestsState = (elKey: string, elValue: any) =>
    runInAction(() => {
      this.guestsState = { ...this.guestsState, [elKey]: elValue };
      this.isGuestsStateChanged = this.isChanged(this.initialGuestsState, this.guestsState);
      this.isDrawerDataChanged = this.isProjectStateChanged || this.isTeamsStateChanged || this.isGuestsStateChanged;
    });

  // DRAWER OPEN/CLOSE ACTIONS
  setIsOpenDrawer = (isOpen: boolean) =>
    runInAction(() => {
      this.editProject.isOpen = isOpen;
      this.isDrawerDataChanged = false;
    });

  setEditProject = (elKey: string, elValue: any) =>
    runInAction(
      () =>
        (this.editProject.editProjectData =
          elKey === 'all' ? elValue : { ...this.editProject.editProjectData, [elKey]: elValue }),
    );

  setIsEditProject = (el: boolean) => runInAction(() => (this.editProject.isEditProject = el));
  //

  setTeams = (elKey: string, elValue: any) => runInAction(() => (this.teams = { ...this.teams, [elKey]: elValue }));

  clearProjectsState = () =>
    runInAction(
      () => (this.projectsState = { ...defaultProjectsState, creatPostProject: this.projectsState.creatPostProject }),
    );

  clearTeamsState = () => runInAction(() => (this.teamsState = defaultTeamsState));

  clearEditProject = () => runInAction(() => (this.editProject = defaultEditProject));
  clearGuestsState = () =>
    runInAction(() => (this.guestsState = { ...defaultGuestsState, guests: this.guestsState.guests }));

  getProjects = async () => {
    runInAction(() => this.setTableLoading(true));
    this.setHasError(false);

    return this.execRequest<TWsProject[]>(httpClient2.get('/api/v1/user/projects'))
      .then(resp => {
        runInAction(
          () =>
            (this.projectsData = resp
              .map(project => ({
                ...project,
                key: project._id,
              }))
              .sort((a, b) => sortRow(a.name, b.name))),
        );
      })
      .catch(() => {
        this.setProjects([]);
        this.setHasError(true);
      })
      .finally(() => runInAction(() => this.setTableLoading(false)));
  };

  getCabinetsShort = () => {
    runInAction(() => {
      this.shortCabinets.loading = true;
      this.shortCabinets.error = false;
    });

    this.execRequest<TShortClientsResponse>(httpClient2.get('/api/v1/core/view/reports/clients'))
      .then(res => {
        runInAction(() => {
          this.shortCabinets = {
            ...this.shortCabinets,
            clients: res.clients?.sort((a, b) =>
              a.account_name && b.account_name
                ? a.account_name.localeCompare(b.account_name)
                : a.account_username.localeCompare(b.account_username),
            ),
          };
          this.shortCabinets.loading = false;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.shortCabinets.loading = false;
          this.shortCabinets.error = true;
        });
      });
  };

  postProjects = (data: IPostProjects) => {
    runInAction(() => this.setIsLoading(true));

    return this.execRequest<ResponseModel_Dict_['data']>(httpClient2.post('/api/v1/user/projects', data))
      .then(resp => {
        this.addNotification({ type: STATUS.SUCCESS, title: `Проект ${data.name} успешно создан` });
        return resp;
      })
      .catch(e => {
        this.addNotification({
          type: STATUS.ERROR,
          title: NotificationErrorTitle.UNKNOW_ERROR,
        });
      })
      .finally(() => {
        runInAction(() => this.setIsLoading(false));
      });
  };

  postEditProjects = (id: string, dataPost: IPostEditProjects) => {
    this.setIsLoading(true);
    dataPost.manager_id === 'not' && delete dataPost.manager_id;
    return this.execRequest<ResponseModel_Dict_>(httpClient2.post(`/api/v1/user/projects/${id}`, dataPost))
      .then(_ => {
        runInAction(() => {
          this.setEditProject('all', { ...this.editProject.editProjectData, ...dataPost });
          this.projectsData = this.projectsData.map(el => {
            if (el._id === id) {
              return { ...el, ...dataPost };
            } else {
              return el;
            }
          });
        });

        const notifTitle = () => {
          if (dataPost.name) {
            return `Проект ${this.editProject.editProjectData?.name} успешно сохранен`;
          }
          if (dataPost.status === 'completed') {
            return NotificationTitle.PROJECT_COMPLETED;
          }
          if (dataPost.status === 'active') {
            return NotificationTitle.PROJECT_ACTIVE;
          }
          if (dataPost.is_archived === false) {
            return NotificationTitle.PROJECT_UNARCHIVED;
          }
          if (dataPost.is_archived) {
            return NotificationTitle.PROJECT_ARCHIVED;
          } else return '';
        };

        this.addNotification({
          type: STATUS.SUCCESS,
          title: notifTitle(),
        });
      })
      .catch(_ => {
        this.addNotification({
          type: STATUS.ERROR,
          title: NotificationErrorTitle.UNKNOW_ERROR,
        });
      })
      .finally(() => {
        this.setIsLoading(false);
        this.setModalData({ type: '' });
      });
  };

  deleteProject = async (id: string) => {
    this.setIsLoading(true);
    await this.execRequest<ResponseModel_Dict_>(httpClient2.delete(`/api/v1/user/projects/${id}`))
      .then(_ => {
        runInAction(() => {
          this.projectsData = this.projectsData.filter(e => {
            return e._id !== id;
          });
        });
        this.addNotification({ type: STATUS.SUCCESS, title: NotificationTitle.PROJECT_DELETE });
      })
      .catch(_ => {
        this.addNotification({
          type: STATUS.ERROR,
          title: NotificationErrorTitle.UNKNOW_ERROR,
        });
      })
      .finally(() => {
        this.setIsLoading(false);
        this.setIsOpenDrawer(false);
        this.setModalData({ type: '' });
      });
  };

  resetStore = () =>
    runInAction(() => {
      this.isLoading = false;
      this.isTableLoading = false;
      this.searchTableProject = '';
      this.editProject = defaultEditProject;
      this.projectsState = defaultProjectsState;
      this.shortCabinets = { error: false, loading: false, clients: [] };
      this.projectsData = [];
      this.hasLoadingError = false;
      this.teams = { error: false, loading: false, teams: [] };
      this.teamsState = defaultTeamsState;
      this.guestsState = defaultGuestsState;
      this.statusWSroles = defaultStatusWSroles;
      this.modalData = {} as ModalData;
    });
}
