import { Typography } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import classes from './style.module.scss';

type TProps = {
  text: string;
  isError?: boolean;
  styles?: string;
};

export const BidValidationMessage = ({ text, isError = true, styles }: TProps) => {
  return (
    <div className={clsx('bvm', classes.messageWrap, styles)}>
      <Typography size="TableRowSubHeader" color={isError ? 'danger' : 'TextSecondary'}>
        {text}
      </Typography>
    </div>
  );
};
