import { Typography } from '@plarin/design';
import { ModalActionNamesEnum, Modal } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { TTeams } from '../../../../../types/teams/teams';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { getWsRole } from '../utils';

export const TeamsConfirmModal = observer(() => {
  const {
    teams: {
      modalData,
      setModalData,
      isLoading,
      toggleStatusTeam,
      deleteTeam,
      teamsData,
      createTeam,
      changeTeam,
      dataDrawer: teamData,
      onCloseDrawer,
    },
    profileStore: { currentWorkspace, data },
  } = useAppDataStore();

  const teamPrevData = teamsData.find(team => team._id === modalData.data?._id);

  const isAdmin = useMemo(() => {
    const wsRole = getWsRole(data.user_id, currentWorkspace);
    return wsRole === 'wsrole:admin' || wsRole === 'wsrole:owner';
  }, []);

  if (modalData.type === 'saveExit') {
    return (
      <Modal
        open={true}
        closeModal={() => setModalData({ type: '' })}
        workspaceHeader="saveExit"
        title="Сохранить изменения?"
        secondAction={{
          actionTitle: ModalActionNamesEnum.NoSaveExit,
          action: () => {
            setModalData({ type: '' });
            onCloseDrawer();
          },
        }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.SaveExit,
          isLoading: isLoading,
          action: () => {
            if (teamData?.isEditDrawer) {
              setModalData({ type: '' });
              changeTeam(data.user_id, isAdmin);
            } else {
              setModalData({ type: '' });
              createTeam();
            }
          },
        }}
      >
        <Typography size="AuthContent">
          Вы внесли изменения, которые не были сохранены.
          <br />
          Сохранить изменения перед выходом?
        </Typography>
      </Modal>
    );
  }

  if (!teamPrevData) return <></>; // такого быть не должно

  if (modalData.type === 'archive') {
    return (
      <Modal
        open={modalData.type === 'archive'}
        closeModal={() => setModalData({ type: '' })}
        workspaceHeader="archive"
        secondAction={{ actionTitle: ModalActionNamesEnum.Cancel, action: () => setModalData({ type: '' }) }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.Archive,
          isLoading: isLoading,
          action: () => {
            !isLoading && toggleStatusTeam(teamPrevData as TTeams, 'inactive');
          },
        }}
        title="Архивировать команду?"
      >
        <Typography size="AuthContent">
          {'После архивации команда  '}
          <Typography size="AuthContent" weight={600}>
            {teamPrevData.name}
          </Typography>
          {' будет доступна в таблице с дополнительным фильтром.'}
        </Typography>
      </Modal>
    );
  }

  if (modalData.type === 'unArchive') {
    return (
      <Modal
        open={modalData.type === 'unArchive'}
        closeModal={() => setModalData({ type: '' })}
        workspaceHeader="unArchive"
        secondAction={{ actionTitle: ModalActionNamesEnum.Cancel, action: () => setModalData({ type: '' }) }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.UnArchive,
          isLoading: isLoading,
          action: () => {
            !isLoading && toggleStatusTeam(teamPrevData as TTeams, 'active');
          },
        }}
        title="Разархивировать команду?"
      >
        <Typography size="AuthContent">
          {'Команда  '}
          <Typography size="AuthContent" weight={600}>
            {teamPrevData.name}
          </Typography>
          {' станет активной.'}
        </Typography>
      </Modal>
    );
  }

  if (modalData.type === 'delete') {
    return (
      <Modal
        open={modalData.type === 'delete'}
        closeModal={() => setModalData({ type: '' })}
        workspaceHeader="remove"
        secondAction={{ actionTitle: ModalActionNamesEnum.Cancel, action: () => setModalData({ type: '' }) }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.Delete,
          isLoading: isLoading,
          action: () => {
            !isLoading && teamPrevData && deleteTeam(teamPrevData as TTeams);
          },
        }}
        title="Удалить команду?"
      >
        <Typography size="AuthContent">
          {'После удаления команды '}
          <Typography size="AuthContent" weight={600}>
            {teamPrevData.name}
          </Typography>
          {' все связанные данные о команде будут удалены безвозвратно.'}
        </Typography>
      </Modal>
    );
  }

  return null;
});
