import { DownloadReportIcon } from '@plarin/design';
import { ActionTableButton, ManageYDTabs, Popover } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useCallback, useRef, useState } from 'react';
import { useAppDataStore } from '../../../../../components/app-data-provider';
import classes from './style.module.scss';

type TReportWidget = {
  tab: ManageYDTabs;
};

export const ReportWidget = observer(({ tab }: TReportWidget) => {
  const {
    manageYD: { errorTable, metricsYD, downloadExcelStat },
  } = useAppDataStore();

  const reportMenu = useRef(null);
  const [isOpenReportMenu, setOpenReportMenu] = useState(false);
  const [isLoadingActionMenu, setLoadingActionMenu] = useState(false);

  const onOpenReportMenu = useCallback(() => {
    setOpenReportMenu(true);
  }, []);

  const onCloseReportMenu = useCallback(() => {
    setOpenReportMenu(false);
  }, []);

  const onDownloadExcel = useCallback(() => {
    setLoadingActionMenu(true);
    downloadExcelStat(tab, setLoadingActionMenu).then();
  }, [downloadExcelStat]);

  const options = [
    {
      name: 'Скачать таблицу в Excel',
      action: () => {
        onDownloadExcel();
        onCloseReportMenu();
      },
    },
    // {
    //   name: 'Создать отчет',
    //   action: () => {
    //     onOpenReportDrawer();
    //     onCloseReportMenu();
    //   },
    // },
  ];

  // const onOpenReportDrawer = () => {
  //   if (data) {
  //     const clients = createReportClientsDrawer(gridApi);
  //     const checkedMetricsVK = localStorage.getItem(STORAGE_ITEM.localStorage.METRICSVK);
  //     updateInitialStateDrawer({
  //       reportClients: clients,
  //       entities: ['ad_plan'],
  //       fields: checkedMetricsVK && JSON.parse(checkedMetricsVK),
  //     });
  //   }
  //   openDrawer();
  // };

  return (
    <>
      <div ref={reportMenu} className={classes.tableReport}>
        <ActionTableButton
          textTooltip={metricsYD?.length && !errorTable ? 'Отчёт' : 'Произошла ошибка'}
          action={metricsYD?.length && !errorTable ? onOpenReportMenu : () => {}}
          loading={isLoadingActionMenu}
          className={classes.downloadIcon}
          icon={<DownloadReportIcon />}
        />
      </div>

      <Popover
        isListLeft
        classNameWrapper={classes.reportMenu}
        setIsOpen={onCloseReportMenu}
        anchorEl={reportMenu.current}
        isOpen={isOpenReportMenu}
        dataList={options}
      />
    </>
  );
});
