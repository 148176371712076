import { CloseIcon } from '@plarin/design';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { IntegrationsAcceptButton } from '../buttons';
import classes from './drawer.module.scss';

export const drawerSize = {
  large: 'large',
  small: 'small',
};
const root = document.getElementById('root') as HTMLElement;

type DrawerProps = {
  widthDrawer?: keyof typeof drawerSize;
  classWrapHead?: string;
  closeDrawer?: () => void;
  title?: React.ReactNode | string;
  action?: () => void;
  actionDisabled?: boolean;
  helperBlock?: React.ReactNode;
  actionBlock?: React.ReactNode;
  workspaceDrawer?: boolean;
  loading?: boolean;
  preloader?: JSX.Element;
  isOpen?: boolean;
  withHeaderBackground?: boolean;
  isDrawerDataChanged?: boolean; // обязателен, если при закрытия дровера, нужна модалка, предлагающая сохранить изменения
};

const CustomDrawer: React.FC<DrawerProps> = ({
  widthDrawer = 'small',
  isOpen,
  children,
  closeDrawer,
  isDrawerDataChanged,
}) => {

  const [drawerIsShown, setDrawerIsShown] = useState(false);

  function keyPress(e: KeyboardEvent) {
    if (e.key === 'Escape' && closeDrawer) {
      closeDrawer();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => {
      document.removeEventListener('keydown', keyPress);
    };
  }, [isDrawerDataChanged]);

  useEffect(() => {
    if (isOpen) {
      setDrawerIsShown(true);
    } else {
      // Ждём около 300мс анимации, когда дровер уезжает за пределы экрана, а потом убираем со страницы элемент с дровером
      setTimeout(() => {
        setDrawerIsShown(false);
      }, 300);
    }
  }, [isOpen]);

  // здесь важно учесть два условия при которых дровер не отображается
  // isOpen - это переменная из стора. Она заставляет появляться дровер, когда мы заходим в приложение по прямой ссылке, и дровер при этом должен автоматически открыться. Такое случается при редиректе с вк обратно в приложение после подключения к Plarin вк-аккаунта. В этом исключительном случае drawerIsShown === false, а isOpen === true
  if (!isOpen && !drawerIsShown) {
    return <></>;
  }

  return (
    <div className={classes.customDrawer}>
      <div
        className={clsx(
          classes.customDrawerWraper,
          widthDrawer === 'small' && classes.smallDrawer,
          widthDrawer === 'large' && classes.largeDrawer,
          !isOpen && classes.hidden,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export const Drawer: React.FC<DrawerProps> = ({
  children,
  helperBlock,
  closeDrawer,
  title,
  action,
  actionDisabled,
  actionBlock,
  workspaceDrawer,
  loading,
  preloader,
  isOpen,
  widthDrawer,
  classWrapHead,
  withHeaderBackground = false,
  isDrawerDataChanged,
  ...props
}) => {
  return ReactDOM.createPortal(
    <CustomDrawer
      widthDrawer={widthDrawer}
      closeDrawer={closeDrawer}
      isOpen={isOpen}
      isDrawerDataChanged={isDrawerDataChanged}
    >
      <>
        <div
          className={clsx(
            classes.header,
            classWrapHead && classWrapHead,
            withHeaderBackground && classes.backgroundHeader,
          )}
        >
          <div className={classes.headerFlexWrap}>
            {title}
            <CloseIcon
              onClick={closeDrawer}
              size={24}
              className={clsx(classes.closeDrawer, workspaceDrawer && classes.wsCloseDrawer)}
            />
          </div>
        </div>

        {loading ? (
          preloader
        ) : (
          <>
            <div className={classes.scrollBox}>
              <div className={clsx(classes.content, (action || actionBlock) && classes.contentPadding)}>
                {children}
                {!action && helperBlock}
              </div>
            </div>
            {(action || actionBlock) && (
              <div className={classes.footer}>
                {actionBlock ? actionBlock : <IntegrationsAcceptButton onClick={action} disabled={actionDisabled} />}
              </div>
            )}
          </>
        )}
      </>
    </CustomDrawer>,
    root,
  );
};
