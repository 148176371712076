import { CheckIcon, Typography } from '@plarin/design';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import classes from './style.module.scss';
import { IListItem } from './type';

export const ListItem = ({ dataList, classNameListItem, sizeTextItem }: IListItem) => {
  const [mouseEnter, setMouseEnter] = useState<number | null>(null);
  const isMouseEnterTrue = useCallback((e: number) => setMouseEnter(e), []);
  const isMouseEnterFalse = useCallback(() => setMouseEnter(null), []);

  return (
    <>
      {dataList?.map((el, index) => {
        return (
          <div
            onMouseEnter={el.iconLeft || el.iconRight ? () => isMouseEnterTrue(index) : undefined}
            onMouseLeave={(el.iconLeft || el.iconRight) && isMouseEnterFalse}
            className={clsx(
              classes.listItem,
              el.isDisabled && classes.disabled,
              el.isSelected && classes.listItemSelected,
              !!classNameListItem && classNameListItem, // пропс стилей других при необходимости
            )}
            key={index}
            onClick={e => {
              !el.isDisabled && el.action && el.action(e);
            }}
          >
            <div className={classes.listLeftContent}>
              {el.iconLeft && <div>{el.iconLeft(mouseEnter === index)}</div>}
              <Typography size={`${sizeTextItem ? sizeTextItem : 'Main'}`} weight={400} ellips={true}>
                {el.name}
              </Typography>
            </div>
            {el.isSelected && (
              <div className={clsx(classes.iconRight, el.isDisabled && classes.disabled)}>
                <CheckIcon />
              </div>
            )}
            {el.iconRight && <div className={classes.iconRight}>{el.iconRight(mouseEnter === index)}</div>}
          </div>
        );
      })}
    </>
  );
};
