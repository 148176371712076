import { PlusCircleIcon, Typography } from '@plarin/design';
import { Button } from '@plarin/inputs';
import React, { useEffect, useState } from 'react';
import { ReportFilter, TInitialStateDrawer } from '../../../../../../types/reports/reports';
import { SelectBlock, TFilter } from './select-block';
import { filterIncludesVariants, filterNameVariants } from './select-menu-variants';
import classes from './style.module.scss';

type FilterBlockProps = {
  updateData: (data: Partial<TInitialStateDrawer>) => void;
  initialFilters?: ReportFilter[];
};

export const FilterBlock = ({ updateData, initialFilters }: FilterBlockProps) => {
  const [filter, setFilter] = useState<TFilter[]>([]);

  const updateFilter = (value: TFilter) => {
    setFilter(prevState =>
      prevState.map(item => {
        return item.key === value.key ? value : item;
      }),
    );
  };

  const deleteFilter = (index: number) => {
    setFilter(filter.filter(el => el.key !== index));
  };

  const addFilter = () => {
    setFilter((prev: TFilter[]) => [
      ...prev,
      {
        key: Date.now() + Math.random(),
        type: filterNameVariants[0].value,
        op: filterIncludesVariants[0].value,
        value: '',
      },
    ]);
  };
  const disabled = filter.length === 10;

  useEffect(() => {
    const initialFilter: TFilter[] = initialFilters
      ? initialFilters.map(el => ({
          key: Date.now() + Math.random(),
          type: el.type,
          op: el.op,
          value: el.value,
        }))
      : [];

    setFilter((prev: TFilter[]) => [...prev, ...initialFilter]);
  }, []);

  useEffect(() => {
    updateData({ filters: filter.filter(el => el.value) });
  }, [updateData, filter]);

  return (
    <div className={classes.filterBlock}>
      <Typography size="Main" weight={600} componentProps={{ className: classes.checksTitle }}>
        Фильтры
      </Typography>

      {filter.map(el => (
        <SelectBlock
          dataFilter={el}
          setDataFilter={updateFilter}
          deleteFilter={deleteFilter}
          key={el.key}
          index={el.key}
        />
      ))}

      <Button disabled={disabled} className={classes.addFilterButton} onClick={addFilter}>
        <div className={classes.addFilter}>
          <PlusCircleIcon color={disabled ? 'disabled' : 'primary60'} />
          <Typography size="AuthContent" weight={600} color={disabled ? 'TextDisabled' : 'LinkPrimary'}>
            Добавить фильтр
          </Typography>
        </div>
      </Button>
    </div>
  );
};
