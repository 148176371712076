import Joi from '@hapi/joi';
import { getApiUrl, httpClient, phoneAllowedSymbols, phoneValidateRule, TFormInitParams } from '@plarin/core';
import { action, makeObservable } from 'mobx';
import { paths } from '../../../types/profile/apispec';
import { history } from '../../components/app';
import { AConfirmPhoneStore } from '../../components/auth/auth-confirm-phone-form/confirm-phone.store';
import { TPhoneCodeReq, TPhoneCodeResp } from '../../components/auth/auth-confirm-phone-form/types';
import { routerPaths } from '../../routing/router-path';
import { STATUS } from '../../utils/constants';
import {
  TPhoneValidateReq,
  TPhoneValidateResp,
  TRegistrationPhoneReq,
  TRegistrationPhoneResp,
} from '../registration/types';

export type TRegistrationPhoneFormData = TRegistrationPhoneReq & TPhoneValidateReq;

const initData: TFormInitParams<TRegistrationPhoneFormData> = {
  phone: {
    validators: {
      change: phoneAllowedSymbols.allow(''),
      blur: phoneValidateRule,
      submit: Joi.string().required(),
    },
  },
  code: {
    validators: {
      blur: Joi.string().required(),
      submit: Joi.string().required(),
    },
  },
  // @ts-ignore
  captcha_token: {},
};

export class ForgotPasswordPhoneFormStore extends AConfirmPhoneStore {
  constructor() {
    super(initData);
    makeObservable<this>(this, {
      submit: action.bound,
      onSendCode: action.bound,
      forgot: action.bound,
      forgotSuccess: action.bound,
      phoneValidate: action.bound,
      phoneValidateSuccess: action.bound,
    });
  }

  onSendCode = () => {
    this.phoneValidate(this.data);
  };

  submit = this.onSubmit(() => {
    this.errors = this.validateAll('phone');
    if (this.isValid) {
      this.forgot(this.data);
    }
  });

  forgot = async (data: TPhoneCodeReq) => {
    this.execRequest<[TPhoneCodeResp]>([httpClient.post(getApiUrl<paths>('/api/v1/user/phone/send_code'), data)])
      .then(this.forgotSuccess)
      .catch(this.onError);
  };

  forgotSuccess = ([resp]: [TRegistrationPhoneResp]) => {
    if (resp.status === STATUS.SUCCESS) {
      this.needConfirm = true;
      this.error = undefined;
      this.alertProps = undefined;
    }
  };

  phoneValidate = async (data: TPhoneValidateReq) => {
    this.execRequest<[TPhoneValidateResp]>([httpClient.post(getApiUrl<paths>('/api/v1/user/phone/validate'), data)])
      .then(this.phoneValidateSuccess)
      .catch(this.onError);
  };

  phoneValidateSuccess = ([resp]: [TPhoneValidateResp]) => {
    if (resp.status === STATUS.SUCCESS) {
      history.push(routerPaths.unAuth.CREATE_PASSWORD);
    }
  };

  retryCode = async () =>
    httpClient.post(getApiUrl<paths>('/api/v1/user/phone/send_code'), {
      phone: this.data.phone,
    });
}
