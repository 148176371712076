import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useButtonColorClass, useButtonSizeClass, useButtonVariantClass } from '../_utils';
import { ButtonProps } from './button-props';
import './button.scss';

export const Button: React.FC<ButtonProps> = function Button({
  children,
  size,
  color,
  variant = 'filled',
  submit,
  type,
  loading,
  className,
  classNameLoading,
  active,
  ...props
}) {
  const buttonSizeClass = useButtonSizeClass(size);
  const buttonColorClass = useButtonColorClass(color);
  const buttonVariantClass = useButtonVariantClass(variant);

  return (
    <button
      className={clsx(
        'button',
        buttonSizeClass,
        buttonColorClass,
        buttonVariantClass,
        className,
        active && `${buttonVariantClass}Active`,
      )}
      type={submit ? 'submit' : 'button'}
      {...props}
    >
      <span className="button__label">
        {loading && (
          <CircularProgress
            thickness={3}
            className={clsx(classNameLoading, 'circularProgress', loading && 'progress__visible')}
          />
        )}
        <div className={clsx(loading && 'children__none')}>{children}</div>
      </span>
    </button>
  );
};
