import clsx from 'clsx';
import React, { useState } from 'react';
import classes from './skeleton-reports.module.scss';
import { Skeleton } from './skeleton-table-row';

export const SkeletonReports = ({ isHeader, isColor }: { isHeader?: boolean; isColor?: boolean }) => {
  const heightHeader = 200;
  const [rows] = useState<number[]>([
    ...Array(Math.floor((document.documentElement.clientHeight - heightHeader) / 73)).keys(),
  ]);

  return (
    <div className={classes.listContainer}>
      {rows.map(item => (
        <div className={classes.reportListItem} key={item}>
          <div className={clsx(classes.itemLeft, classes.cell)}>
            <Skeleton height={19} size="long" />
            <Skeleton height={16} size="short" />
          </div>
          <div className={classes.itemRight}>
            <div className={classes.cell}>
              <Skeleton height={16} size="middle" />
              <Skeleton height={16} size="short" />
            </div>
            <div className={classes.cell}>
              <Skeleton height={16} size="middle" />
              <Skeleton height={16} size="short" />
            </div>
            <div></div>
          </div>
        </div>
      ))}
    </div>
  );
};
