import { BranchesIcon } from '@plarin/design';
import { TitleOptions, Mt, Va } from '@plarin/inputs';
import React from 'react';
import { NetworkEnum } from '../../../../dictionary/integrations';
import classes from './drawer-header.module.scss';

type DrawerTitleProps = {
  network?: NetworkEnum;
};

export const DrawerTitleForConnect = () => (
  <TitleOptions iconBlock={<BranchesIcon />} defaultTitleName="Выберите канал для подключения" />
);

export const DrawerTitleWithNetwork = ({ network }: DrawerTitleProps) => (
  <div>
    {network === 'mt' && (
      <TitleOptions
        icon={
          <div className={classes.iconBlock}>
            <Mt size={24} />
          </div>
        }
        defaultTitleName="Подключение myTarget"
      />
    )}
    {network === 'va' && <TitleOptions icon={<Va size={40} />} defaultTitleName="Подключение VK Реклама" />}
  </div>
);
