import { PlusIcon, Typography } from '@plarin/design';
import { Button } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';
import classes from './create-new-ws.module.scss';

type Props = {
  setIsDrawerOpen: (e: boolean) => void;
  headerContain?: boolean;
};

export const CreateNewWs = observer(({ setIsDrawerOpen, headerContain }: Props) => {
  return (
    <Button
      className={clsx(classes.creatButton, headerContain && classes.headerCreateWs)}
      size={headerContain ? 'small' : 'medium'}
      color="primary"
      variant="filled"
      onClick={() => setIsDrawerOpen(true)}
    >
      <div className={classes.creatButtonContent}>
        <PlusIcon size={20} />
        <Typography componentProps={{ className: classes.lableButton }} weight={600} size="Main">
          Cоздать новое пространство
        </Typography>
      </div>
    </Button>
  );
});
