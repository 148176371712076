import { IconStatus } from '@plarin/design';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { Skeleton } from '../../../preloader';
import classes from './style.module.scss';

const STATUSES = {
  active: 'Активный',
  completed: 'Завершенный',
  blocked: 'Заблокированный',
  archived: 'Архивный',
  pending: 'Ожидает подтверждения',
} as const;

export const StatusRenderer = (props: ICellRendererParams) => {
  const { data } = props;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { status, cellLoadingName, is_archived } = data;

  if (cellLoadingName === 'status') return <Skeleton size="maxLong" height={17} />;

  return (
    <div>
      <IconStatus status={is_archived ? STATUSES.pending : status ? status : STATUSES.pending} />
      <span className={classes.statusText}>
        {status ? STATUSES[is_archived ? 'archived' : (status as keyof typeof STATUSES)] : STATUSES.pending}
      </span>
    </div>
  );
};
