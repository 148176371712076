import { Button, ButtonProps } from '@mui/material';
import { RedoIcon } from '@plarin/design';
import React from 'react';
import classes from './icon-button.module.scss';

export const AlertErrorRefreshButton: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <Button
      {...props}
      variant="outlined"
      classes={{
        root: 'refreshButtonRoot',
      }}
    >
      <RedoIcon className={classes.mr10} />
      Загрузить данные об аккаунте повторно
    </Button>
  );
};
