import React, { createContext, useContext } from 'react';
import { AppDataStore, TAppDataStoreProps } from '../../stores/app-data.store';

let appDataStore: AppDataStore;

const AppDataStoreContext = createContext<AppDataStore | undefined>(undefined);

export function useAppDataStore() {
  const context = useContext(AppDataStoreContext);
  if (context === undefined) {
    throw new Error('useAppDataStore должен использоваться в AppDataStoreContext');
  }
  return context;
}

export const AppDataStoreProvider: React.FC<TAppDataStoreProps> = ({ children, ...props }) => {
  appDataStore = appDataStore ?? new AppDataStore(props);
  return <AppDataStoreContext.Provider value={appDataStore}>{children}</AppDataStoreContext.Provider>;
};
