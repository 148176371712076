import { TextField } from '@mui/material';
import { CheckIcon, CloseCircleIcon, iconColors, iconSizes, iconTypes, SearchIcon } from '@plarin/design';
import { useDebounce, sendMetricGoal } from '@plarin/utils';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { CharactersCounter } from '../counters/characters-counter';
import classes from './default-input.module.scss';

const inputStyles = {
  default: 'default',
  tableSearch: 'tableSearch',
};

type DefaultInputProps = {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  size?: 32 | 40 | 48;
  searchIcon?: boolean;
  searchIconColor?: keyof typeof iconColors;
  clearInputAction?: () => void;
  saveInputAction?: () => void;
  onFocusAction?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlurAction?: () => void;
  onDeleteAction?: () => void;
  errorText?: string;
  closeCircleSize?: keyof typeof iconSizes;
  closeCircleType?: keyof typeof iconTypes;
  saveIconSize?: keyof typeof iconSizes;
  saveIconType?: keyof typeof iconTypes;
  className?: string;
  classNameRoot?: string;
  inputStyle?: keyof typeof inputStyles;
  helperTextClassName?: string;
  maxLengthValue?: number;
  maxCounterValue?: number;
  maxLength?: number;
};

export const DefaultInput = ({
  value,
  setValue,
  placeholder,
  size,
  searchIcon,
  searchIconColor,
  clearInputAction,
  saveInputAction,
  onFocusAction,
  onBlurAction,
  onDeleteAction,
  errorText = '',
  closeCircleSize,
  closeCircleType,
  saveIconSize,
  saveIconType,
  className,
  classNameRoot,
  inputStyle = 'default',
  helperTextClassName,
  maxLength,
  maxLengthValue,
  maxCounterValue,
  ...props
}: DefaultInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    saveInputAction && inputRef.current?.focus();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (maxLengthValue) {
      setValue(event.target.value.substring(0, maxLengthValue));
    } else {
      setValue(event.target.value);
    }
  };

  return (
    <>
      <TextField
        {...props}
        fullWidth
        variant="outlined"
        value={value}
        onChange={handleChange}
        inputProps={{
          maxLength: maxLength,
        }}
        placeholder={placeholder}
        classes={{ root: clsx(classes.textField, className) }}
        FormHelperTextProps={{
          classes: {
            root: clsx(classes.helperText, helperTextClassName),
          },
        }}
        InputProps={{
          classes: {
            root: clsx(
              classes.root,
              size === 48 && classes.root42,
              size === 40 && classes.root40,
              inputStyle === 'tableSearch' && classes.tableSearch,
              classNameRoot && classNameRoot,
            ),
            input: clsx(classes.input, searchIcon && classes.inputWithLeftPadding),
            focused: classes.inputFocused,
            notchedOutline: 'visuallyHidden',
            error: classes.inputError,
          },
          startAdornment: searchIcon ? (
            <SearchIcon
              size={20}
              className={clsx(size === 48 && classes.minusMarginLeft)}
              color={searchIconColor ? searchIconColor : 'main'}
            />
          ) : null,
          endAdornment:
            clearInputAction || saveInputAction ? (
              <>
                {clearInputAction && !!value.length && (
                  <CloseCircleIcon
                    size={closeCircleSize}
                    onClick={clearInputAction}
                    className={classes.clearInputIcon}
                    iconType={closeCircleType || 'outlined'}
                  />
                )}
                {saveInputAction && (
                  <CheckIcon
                    onClick={e => {
                      e.stopPropagation();
                      !errorText && saveInputAction();
                    }}
                    iconType={saveIconType || 'outlined'}
                    size={saveIconSize}
                    color={errorText ? 'disabled' : 'primary60'}
                    className={classes.saveInputIcon}
                  />
                )}
              </>
            ) : null,
          inputRef: inputRef,
          onFocus: onFocusAction,
          onKeyDown: e => {
            if (e.keyCode === 27) {
              e.stopPropagation();
              onDeleteAction && onDeleteAction();
              onBlurAction && onBlurAction();
              inputRef.current?.blur();
            }
            if (e.keyCode === 13 && saveInputAction && !errorText && value.length) {
              e.stopPropagation();
              saveInputAction();
            }
          },
          onBlur: onBlurAction,
        }}
        InputLabelProps={{ disabled: true }}
        error={!!errorText}
        helperText={errorText}
      />
      {maxCounterValue !== undefined && (
        <CharactersCounter
          inputValueLength={value.length}
          maxCounterValue={maxCounterValue}
          fontSize={size}
          errorText={errorText}
        />
      )}
    </>
  );
};

type SearchInputProps = {
  filterValue: string;
  setFilter: (value: string) => void;
  placeholder?: string;
  size?: 32 | 48;
  searchIconColor?: keyof typeof iconColors;
  closeCircleSize?: keyof typeof iconSizes;
  closeCircleType?: keyof typeof iconTypes;
  className?: string;
  inputStyle?: keyof typeof inputStyles;
  isOutline?: boolean;
};

// обёртка над DefaultInput, которая сбрасывает в сторе значение фильтра при unmount компонента инпута
export const SearchDefaultInput = ({
  filterValue,
  setFilter,
  placeholder,
  size = 48,
  searchIconColor,
  closeCircleSize = 20,
  closeCircleType,
  className,
  inputStyle = 'default',
  isOutline,
}: SearchInputProps) => {
  const [inputValue, setInputValue] = useState(filterValue || '');
  const debounce = useDebounce();

  useEffect(() => {
    setInputValue(filterValue || '');
    setFilter && setFilter(filterValue || '');

    return () => setFilter('');
  }, []);

  const changeInputValue = (value: string) => {
    setInputValue(value);
  };

  const clearInput = () => {
    changeInputValue('');
  };

  useEffect(() => {
    debounce(() => {
      if (setFilter) {
        setFilter(inputValue.toLowerCase());
        if (inputValue.trim() !== '') {
          // Отправляем конверсию в Яндекс.Метрику
          sendMetricGoal('usage_vk_search', 'manage/vk');
        }
      }
    }, 300);
  }, [inputValue]);

  return (
    <DefaultInput
      placeholder={placeholder}
      value={inputValue}
      setValue={changeInputValue}
      size={size}
      searchIcon={true}
      searchIconColor={searchIconColor}
      closeCircleSize={closeCircleSize}
      closeCircleType={closeCircleType}
      clearInputAction={clearInput}
      inputStyle={inputStyle}
      className={className}
      classNameRoot={clsx(isOutline ? classes.isRootOutline : classes.rootNoOutline)}
    />
  );
};
