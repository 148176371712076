import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Typography, SortDownSolidET, SortDownSolidETDisabled } from '@plarin/design';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { CustomMenuItem, CustomMenuItemSelect } from '../../../custom-menu-item';
import { FixedBidModes } from '../../../edit-bid/fixed-edit-bid';
import { StrategyBidModes } from '../../../edit-bid/strategy-form-utils';
import commonClasses from '../common.module.scss';
import { HelpInfoTooltip } from '../help-info-tooltip';

type TBidTypeProps = {
  mode: string;
  setMode: React.Dispatch<React.SetStateAction<FixedBidModes>> | React.Dispatch<React.SetStateAction<StrategyBidModes>>;
  inputsModeVariants: {
    value: string;
    label: string;
  }[];
  header: string;
  tooltipMessage: string;
  isDisabled?: boolean;
};

export const BidTypeInput = ({
  mode,
  setMode,
  inputsModeVariants,
  header,
  tooltipMessage,
  isDisabled = false,
}: TBidTypeProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDiff = mode === StrategyBidModes.differentModes;

  const handleSelectChange = (event: SelectChangeEvent) => {
    // @ts-ignore
    setMode(event.target.value);
  };

  return (
    <>
      <Typography
        size="TableRowSubHeader"
        color="TextSecondary"
        componentProps={{ className: commonClasses.inputBidHeader }}
      >
        {header}
        <HelpInfoTooltip tooltipMessage={tooltipMessage} className={commonClasses.helpTooltip} followCursor={true} />
      </Typography>
      <Select
        onChange={handleSelectChange}
        disabled={isDisabled}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        variant="outlined"
        value={isDiff ? 'isDiff' : mode}
        IconComponent={isDisabled ? SortDownSolidETDisabled : SortDownSolidET}
        MenuProps={{
          className: commonClasses.menuOverlay,
          classes: {
            paper: commonClasses.menuPaper,
          },
        }}
        classes={{
          root: clsx(
            commonClasses.inputWrap,
            isOpen && commonClasses.inputFocused,
            isDisabled && commonClasses.disabledInput,
          ),
          select: commonClasses.inputInput,
        }}
        inputProps={{
          classes: {
            icon: clsx('rotatable', commonClasses.iconSize),
            iconOpen: 'deg180',
          },
        }}
      >
        {isDiff && (
          <CustomMenuItem value="isDiff" style={{ display: 'none' }}>
            Разные значения
          </CustomMenuItem>
        )}
        {inputsModeVariants.map((option, index) => (
          <CustomMenuItemSelect
            value={option.value}
            key={option.value}
            tabIndex={index}
            selectedItem={option.value === mode}
          >
            {option.label}
          </CustomMenuItemSelect>
        ))}
      </Select>
    </>
  );
};
