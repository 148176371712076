import { Typography } from '@plarin/design';
import { formatDateToReq, shortDate } from '@plarin/utils';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import { isValid } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '../../../buttons';
import { getLimitTypeFromSingleItem } from '../../../edit-bid/strategy-form-utils';
import { Popover } from '../../../popover';
import { SkeletonTableBlock } from '../../../preloader';
import { NetworkEnum } from '../../../social-buttons/integrations/types';
import { EditDate } from './edit-date';
import classes from './style.module.scss';

export interface ScheduleButtonsProps {
  gridApi: GridApi;
  onClose: () => void;
  requestData: DataRequest;
  disabledSave: boolean;
}

interface DataRequest {
  ad_plan?: {
    date_start?: string;
    date_end?: string;
    obj_id: number;
  };
  campaign?: {
    date_start?: string;
    date_end?: string;
    obj_id: number;
  };
  network: 'va' | 'mt';
}

interface PeriodRendererProps extends ICellRendererParams {
  tabName: 'accountName' | 'adPlanName' | 'campaignName' | 'adName';
  cellName: string;
  ButtonComponent: React.FC<ScheduleButtonsProps>;
}

export const PeriodRenderer = ({ tabName, data, ButtonComponent, api }: PeriodRendererProps) => {
  const [isOpen, setOpen] = useState(false);
  const [limitType] = useState(getLimitTypeFromSingleItem(+data.adPlanDaily, +data.adPlanLifetime));

  const [newDateStartAdPlan, setNewDateStartAdPlan] = useState<Date | null>(
    data.adPlanStart ? new Date(data.adPlanStart) : null,
  );
  const [newDateEndAdPlan, setNewDateEndAdPlan] = useState<Date | null>(
    data.adPlanStop ? new Date(data.adPlanStop) : null,
  );

  const [newDateStartCampaign, setNewDateStartCampaign] = useState<Date | null>(
    data.campaignStart ? new Date(data.campaignStart) : null,
  );
  const [newDateEndCampaign, setNewDateEndCampaign] = useState<Date | null>(
    data.campaignStop ? new Date(data.campaignStop) : null,
  );

  const [isErrorAdPlan, setErrorAdPlan] = useState<boolean>(false);
  const [isErrorCampaign, setErrorCampaign] = useState<boolean>(false);

  const [isChangeAdPlan, setChangeAdPlan] = useState<boolean>(false);
  const [isChangeCampaign, setChangeCampaign] = useState<boolean>(false);

  const anchorRef = useRef(null);

  const isAdPlanTab = tabName === 'adPlanName';

  const isArchived = data.status.includes('Архив');

  const getPeriod = (dateStart: Date | null, dateStop: Date | null) => {
    return (
      <>
        <span className={clsx(!isArchived && classes.timeBorder)}>
          {dateStart && isValid(new Date(dateStart)) ? shortDate(String(dateStart)) : 'Не определена'}
        </span>
        <span> – </span>
        <span className={clsx(!isArchived && classes.timeBorder)}>
          {dateStop && isValid(new Date(dateStop)) ? shortDate(String(dateStop)) : 'Никогда'}
        </span>
      </>
    );
  };

  const getRequestData = () => {
    const result: DataRequest = {
      network: data.network,
    };

    if (isChangeAdPlan) {
      result.ad_plan = {
        obj_id: data.adPlanId,
        date_start: newDateStartAdPlan ? formatDateToReq(new Date(newDateStartAdPlan)) : undefined,
        date_end: newDateEndAdPlan ? formatDateToReq(new Date(newDateEndAdPlan)) : undefined,
      };
    }

    if (isChangeCampaign) {
      result.campaign = {
        obj_id: data.campaignId,
        date_start: newDateStartCampaign ? formatDateToReq(new Date(newDateStartCampaign)) : undefined,
        date_end: newDateEndCampaign ? formatDateToReq(new Date(newDateEndCampaign)) : undefined,
      };
    }
    return result;
  };

  useEffect(() => {
    setNewDateStartAdPlan(data.adPlanStart);
    setNewDateEndAdPlan(data.adPlanStop);
    setNewDateStartCampaign(data.campaignStart);
    setNewDateEndCampaign(data.campaignStop);
  }, [data.adPlanStart, data.adPlanStop, data.campaignStart, data.campaignStop, isOpen]);

  const handleClick = (ref: HTMLDivElement) => {
    if (!ref || isArchived) return;
    ref.onclick = event => {
      event.stopPropagation();
      setOpen(true);
    };
  };

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      {data.cellLoadingName === 'bid' ? (
        <SkeletonTableBlock withCheckbox isReversLong height={17} isRightContent width={100} />
      ) : (
        <>
          <div ref={handleClick}>
            <div ref={anchorRef} className={clsx(classes.buttonWrapper, isArchived && classes.buttonWrapperNoCursor)}>
              <Typography size="Caption" color={clsx(!isArchived && 'LinkPrimary')}>
                {isAdPlanTab
                  ? getPeriod(data.adPlanStart, data.adPlanStop)
                  : getPeriod(data.campaignStart, data.campaignStop)}
              </Typography>
            </div>
          </div>
          <div ref={handleClick}>
            <Button className={classes.rangeTime}>
              {data.dateScheduleHours ? `${data.dateScheduleHours} из 168 часов` : ''}
            </Button>
          </div>
          <Popover
            classNameWrapper={classes.wrapperPopup}
            isOpen={isOpen}
            setIsOpen={onClose}
            anchorEl={anchorRef.current}
          >
            <div className={classes.paper}>
              <Typography size="Main" weight={700}>
                Расписание
              </Typography>
              <div className={classes.inputContainer}>
                {!isAdPlanTab && (
                  <EditDate
                    setHasError={setErrorCampaign}
                    setWasChanged={setChangeCampaign}
                    dateStart={newDateStartCampaign ? new Date(newDateStartCampaign) : null}
                    dateStop={newDateEndCampaign ? new Date(newDateEndCampaign) : null}
                    adPlanDateStart={data.network === NetworkEnum.VkAds ? newDateStartAdPlan : undefined}
                    adPlanDateStop={data.network === NetworkEnum.VkAds ? newDateEndAdPlan : undefined}
                    isAdPlanTab={isAdPlanTab}
                    limitType={limitType}
                    setNewStartDate={setNewDateStartCampaign}
                    setNewEndDate={setNewDateEndCampaign}
                    title="Дата проведения Группы объявлений"
                  />
                )}

                {(isAdPlanTab || data.network === NetworkEnum.VkAds) && (
                  <EditDate
                    setHasError={setErrorAdPlan}
                    setWasChanged={setChangeAdPlan}
                    dateStart={newDateStartAdPlan ? new Date(newDateStartAdPlan) : null}
                    dateStop={newDateEndAdPlan ? new Date(newDateEndAdPlan) : null}
                    isAdPlanTab={isAdPlanTab}
                    limitType={limitType}
                    setNewStartDate={setNewDateStartAdPlan}
                    setNewEndDate={setNewDateEndAdPlan}
                    title="Дата проведения  Кампании"
                  />
                )}
              </div>

              <div className={classes.buttonContainer}>
                <ButtonComponent
                  gridApi={api}
                  disabledSave={isChangeAdPlan || isChangeCampaign ? isErrorAdPlan || isErrorCampaign : true}
                  requestData={getRequestData()}
                  onClose={onClose}
                />
              </div>
            </div>
          </Popover>
        </>
      )}
    </>
  );
};
