import { ManageVkTabs } from '../..//menu';
import { TRows } from '../../edit-bid';

export type TUsedCurrencies = 'EUR' | 'RUB' | 'USD' | 'CNY';

export type TAccountCurrency = TUsedCurrencies | undefined;

type TNewFixBid = {
  item_id: string;
  network: string;
  bid: number;
  maxBid: number;
  minBid: number;
};

export type TNewFixBids = TNewFixBid[];

export type BidItemsType = 'ad_plan' | 'campaign' | 'banner' | null;

export const isKnownCurrency = (accountCurrency: string | number | []): TUsedCurrencies | undefined => {
  const usedCurrencies: TUsedCurrencies[] = ['EUR', 'RUB', 'USD', 'CNY'];

  if (typeof accountCurrency === 'string') {
    // @ts-ignore
    return usedCurrencies[usedCurrencies.indexOf(accountCurrency)] || undefined;
  }

  return undefined;
};

// если выбраны несколько групп кампаний, мы хотим узнать, разные у них размеры ставок или нет
export const fixedBidsAreDifferent = (newBidsValues: TNewFixBids): boolean => {
  if (newBidsValues.length === 1) {
    return false;
  }

  return Array.from(new Set(newBidsValues.map(item => item.bid))).length > 1;
};

export const maxPricesAreDifferent = (newBidsValues: TNewStrategyBids): boolean => {
  if (newBidsValues.length === 1) {
    return false;
  }

  return Array.from(new Set(newBidsValues.map(item => item.maxPrice))).length > 1;
};

// если у всех выбранных кампаний ставка имеет одно и то же значение, проверяем это и возвращаем ставку. Если значения разные, возвращаем -1
// TODO: подумать, можно ли заменить -1 на что-то более лицепреятное
export const getCommonFixedBid = (newBidsValues: TNewFixBids): number | -1 => {
  if (fixedBidsAreDifferent(newBidsValues)) {
    return -1;
  }

  return newBidsValues[0].bid;
};

export const getCommonMaxPrice = (newBidsValues: TNewStrategyBids): number | '' | -1 => {
  if (maxPricesAreDifferent(newBidsValues)) {
    return -1;
  }

  return newBidsValues[0].maxPrice;
};

// устанавливает для всех выбранных кампаний одну и ту же ставку
export const setCommonFixedBid = (prevBids: TNewFixBids, newBid: number): TNewFixBids =>
  prevBids.map(obj => ({
    ...obj,
    bid: newBid,
  }));

export const setCommonMaxPrice = (prevVal: TNewStrategyBids, newMaxPrice: number): TNewStrategyBids =>
  prevVal.map(obj => ({
    ...obj,
    maxPrice: newMaxPrice,
  }));

export const getItemsType = (pathname: string): BidItemsType => {
  const currentPage = pathname.split('/').pop();

  if (currentPage === ManageVkTabs.adPlans) return 'ad_plan';
  if (currentPage === ManageVkTabs.adGroups) return 'campaign';
  if (currentPage === ManageVkTabs.ads) return 'banner';

  return null;
};

const getBidItemId = (obj: Record<string, any>, itemType: BidItemsType) => {
  if (itemType === 'ad_plan') return obj.adPlanId;
  if (itemType === 'campaign') return obj.campaignId;
  if (itemType === 'banner') return obj.adId;

  return '0';
};

// вытаскиваем из row (кампаний) нужные данные для работы со ставками
export const grabFixedBids = (selectedObjects: TRows, itemType: BidItemsType): TNewFixBids =>
  selectedObjects.map(obj => ({
    item_id: getBidItemId(obj, itemType),
    network: obj.network,
    bid: obj.bidCurrent,
    maxBid: obj.packageLimitBidMax,
    minBid: obj.packageLimitBidMin,
  }));

export type TBudgetOptimizationLevel = 'adPlan' | 'campaign';

export type TCommonBudgetOptimizationLevel = TBudgetOptimizationLevel | undefined;

export type TNewStrategyBid = {
  item_id: string;
  adPlanId: string;
  campaignId: string | undefined;
  network: string;
  itemObjective: string;
  maxPrice: number | '';
  maxLimit: number;
  minLimit: number;
  limitDaily: number;
  limitLifetime: number;
  campaignDaily?: number;
  campaignLifetime?: number;
  adPlanStart: string | Date;
  adPlanStop: string | Date;
  campaignStart?: string | Date;
  campaignStop?: string | Date;
  budgetOptimizationLevel: TBudgetOptimizationLevel;
};

export type TNewStrategyBids = TNewStrategyBid[];

export const grabStrategyBids = (selectedObjects: TRows, itemType: BidItemsType): TNewStrategyBids =>
  selectedObjects.map(obj => ({
    item_id: getBidItemId(obj, itemType),
    adPlanId: obj.adPlanId,
    campaignId: obj.campaignId || undefined, // встречается только у групп и объявлений
    network: obj.network,
    itemObjective: obj.adPlanObjective,
    maxPrice: obj.maxPrice,
    maxLimit: +obj.packageLimitBidMax,
    minLimit: +obj.packageLimitBidMin,

    limitDaily:
      obj.budgetOptimizationLevel === 'adPlan'
        ? obj.adPlanDaily === ''
          ? 0
          : obj.adPlanDaily
        : obj.campaignDaily === ''
        ? 0
        : obj.campaignDaily,

    limitLifetime:
      obj.budgetOptimizationLevel === 'adPlan'
        ? obj.adPlanLifetime === ''
          ? 0
          : obj.adPlanLifetime
        : obj.campaignLifetime === ''
        ? 0
        : obj.campaignLifetime,

    adPlanStart: obj.adPlanStart,
    adPlanStop: obj.adPlanStop,
    campaignStart: itemType === 'campaign' || itemType === 'banner' ? obj.campaignStart : undefined,
    campaignStop: itemType === 'campaign' || itemType === 'banner' ? obj.campaignStop : undefined,
    budgetOptimizationLevel: obj.budgetOptimizationLevel,
  }));

// При мзмении ставки "относительно текущей" меняет ставку всех выбранных кампаний на один и тот же коэффициент (вычисления производит calculateBid)
export const setCalculatedBid = (prevBids: TNewFixBids, calculateBid: (prevValue: number) => number): TNewFixBids =>
  prevBids.map(obj => {
    const newBid = calculateBid(obj.bid);

    return {
      item_id: obj.item_id,
      network: obj.network,
      bid: newBid > obj.maxBid ? obj.maxBid : newBid, // не позволит при изменении ставки "Относительно текущей" задать кампании слишком большой размер ставки
      maxBid: obj.maxBid,
      minBid: obj.minBid,
    };
  });

export const getCurrencySymbol = (currency: TAccountCurrency) => {
  switch (currency) {
    case 'RUB':
      return '₽';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'CNY':
      return '¥';
    default:
      return '';
  }
};

// при вводе нового значения в инпут NumericFormat нам необходимо одновременно менять 1) иногда, но не обязательно, менять bidValue<number> родительского компонента и 2) numericBidValue<string> инпута NumericFormat.
// Также эта функция преобразует некорректный пользовательский ввод
export const updateNumericInputStates = (
  values: any, // д.б. объект values, возвращаемый NumericInput и имеющий свойства value, floatValue, formattedValue
  numericSetter: React.Dispatch<React.SetStateAction<string>>, // стейт, непосредственно являющийся значением для инпута (строка)
  parentSetter?: React.Dispatch<React.SetStateAction<number>>, // стейт, в котором хранится число, из которого получаем numeric-стейт
) => {
  // если мы ввели '00', преобразуется в '0.0'
  if (values.value.startsWith('00')) {
    parentSetter && parentSetter(0);
    return numericSetter('0.0');
  }

  // если мы ввели '01', преобразуется в '1'
  if (
    values.value.startsWith('0') &&
    values.value.length === 2 &&
    values.value &&
    !!Number(values.value[1]) // второй символ в инпуте число, а не знак, отделяющий десятые
  ) {
    parentSetter && parentSetter(Number(values.value[1]));
    return numericSetter(values.value[1]);
  }

  parentSetter && parentSetter(values.floatValue ? values.floatValue : 0);
  numericSetter(values.value);
};

// проверяем, чтобы значение ставки было в заданном пределе
export const minMaxCheck = (bidValue: number, minBid: number, maxBid: number) =>
  bidValue >= minBid && bidValue <= maxBid;

export enum SaveBlockingMessages {
  setNewBid = 'Задайте новую ставку',
  setOneNewBidForMultiple = 'Задайте новую ставку для всех выбранных кампаний', // здесь есть неразрывные пробелы
  setNewDifferentBids = 'Задайте новые ставки',
  limitsError = 'Ошибка: новые лимиты для размера ставки заданы неверно',
  maxLimitError = 'Ставка не может быть больше', // + ` ${maxBid} ${getCurrencySymbol(currency)}`
  itemTypeError = 'Возникла ошибка при определении типа редактируемых элементов',

  // для кампаний из vk ads:
  limitType = 'Ошибка лимита бюджета: не установлен дневной либо общий бюджет',
  limitValue = 'Задайте новое значение лимита бюджета',
  dailyLimitError = 'Размер дневного лимита бюджета задан неверно',
  lifetimeLimitError = 'Размер общего лимита бюджета задан неверно',
  itemErrorVA = 'Запрос о редактируемой кампании вернул некорректные данные',
  noChanges = 'Можно будет сохранить после того как вы внесёте изменения',
  maxPriceTooSmall = 'Максимальная стоимость целевого действия меньше минимума',
  maxPriceTooBig = 'Максимальная стоимость целевого действия больше максимума',
  dateError = 'Задайте правильные даты проведения',
}

export const strategyBidTypesAreDifferent = (selectedRows: TRows): boolean => {
  if (selectedRows.length === 1) {
    return false;
  }

  return Array.from(new Set(selectedRows.map(item => item.bidType))).length > 1;
};

export const strategyObjectivesAreDifferent = (selectedRows: TRows): boolean => {
  if (selectedRows.length === 1) {
    return false;
  }

  return Array.from(new Set(selectedRows.map(item => item.adPlanObjective))).length > 1;
};

export const itemHasAdGroupBudgetOptimization = (selectedRows: TRows): boolean => {
  return !!selectedRows.find(item => item.budgetOptimizationLevel === 'campaign');
};

export const itemsHasDifferentBudgetOptimizationTypes = (selectedRows: TRows): boolean => {
  return Array.from(new Set(selectedRows.map(item => item.budgetOptimizationLevel))).length > 1;
};
