import { isUrlProd } from '@plarin/utils';
import React, { useEffect, useState } from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { useAppDataStore } from '../app-data-provider';
import { IntercomGlobal } from './global-intercom';

const appId = isUrlProd ? INTERCOM_APP_ID_PROD : INTERCOM_APP_ID_DEV;

const IntercomInitializer: React.FC = () => {
  const { shutdown } = useIntercom();
  // Присваиваем функцию shutdown глобальной переменной IntercomGlobal,
  // чтобы иметь возможность вызывать shutdown Intercom из любой части приложения
  // (используем для вызова shutdown при логауте в apps/app/src/components/app/index.tsx)
  useEffect(() => {
    IntercomGlobal.shutdown = shutdown;
  }, []);

  return null;
};

export const IntercomProviderWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    menu: { setUnreadIntercomCount },
  } = useAppDataStore();

  const handleUnreadCountChange = (newUnreadCount: number) => {
    setUnreadIntercomCount(newUnreadCount);
  };

  if (!appId) {
    return <>{children}</>;
  }

  return (
    <>
      <IntercomProvider appId={appId} onUnreadCountChange={handleUnreadCountChange}>
        <IntercomInitializer />
        {children}
      </IntercomProvider>
    </>
  );
};
