import clsx from 'clsx';
import React from 'react';
import { Button } from './button';
import { ButtonProps } from './button-props';
import classes from './icon-button.module.scss';

export const IconButton = ({ children, color, className, ...props }: ButtonProps) => {
  return (
    <Button
      {...props}
      className={clsx(className, classes.iconButton)}
      classNameLoading={classes.loading}
      color={color ? color : 'secondary'}
    >
      {children}
    </Button>
  );
};
