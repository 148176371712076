import { GridApi, Lockscreen, Table } from '@plarin/inputs';
import { RowClickedEvent } from 'ag-grid-community';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDataStore } from '../../components/app-data-provider';
import { WsEmptyTableOverlay } from '../../components/ws-table-overlay/ws-table-overlay';
import { sortRow } from '../../utils/common';
import { setTableLayout } from '../../utils/workspaces';
import { ActionBar, ColumnsMembers, EditMemberDrawer, MemberEditModals } from './components';
import classes from './style.module.scss';

const Members = observer(() => {
  const {
    members: { members, invites, isTableLoading, getWSMembers, isLoading, setMembers, setUserId, hasLoadingError },
    teams: { getTeams },
  } = useAppDataStore();

  const [blockEnabled, setBlockEnabled] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);

  const rowData = blockEnabled
    ? // @ts-ignore
      [...members, ...invites].sort((a, b) => sortRow(a.fname, b.fname))
    : [...members.filter(el => el.status !== 'blocked'), ...invites].sort((a, b) =>
        // @ts-ignore
        sortRow(a.fname, b.fname),
      );

  const onRowClicked = useCallback((event: RowClickedEvent) => {
    event.data._id && setUserId(event.data._id);
  }, []);

  useEffect(() => {
    getWSMembers().then();
    getTeams().then();
    return () => {
      setMembers([]);
      setUserId('');
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () =>
      setTableLayout(
        blockEnabled ? [...members, ...invites] : [...members.filter(el => el.status !== 'blocked'), ...invites],
        gridApi,
      ),
    );
    return () => {
      window.removeEventListener('resize', () =>
        setTableLayout(
          blockEnabled ? [...members, ...invites] : [...members.filter(el => el.status !== 'blocked'), ...invites],
          gridApi,
        ),
      );
    };
  }, [members, invites, gridApi, blockEnabled]);

  return (
    <>
      <ActionBar blockEnabled={blockEnabled} setBlockEnabled={setBlockEnabled} />
      <div
        className={clsx(
          classes.tableContent,
          classes.fixFlexColumns,
          (hasLoadingError || !members.length) && classes.emptyTable,
        )}
      >
        <Table
          columns={ColumnsMembers}
          rowData={rowData}
          isRowGroup
          onGridReady={setGridApi}
          tableLoading={isTableLoading}
          onRowClicked={onRowClicked}
          needAutoHeight
          suppressRowClickSelection
          agNoRowsOverlay={() =>
            WsEmptyTableOverlay({
              type: 'members',
              hasLoadingError,
              hasAdminRights: false,
              openDrawer: () => null,
              archived: 0,
              completed: 0,
            })
          }
          spaceAboveTable={185}
        />
      </div>

      <EditMemberDrawer />
      <MemberEditModals />
      <Lockscreen visible={isLoading} />
    </>
  );
});

export default Members;
