import { SettingsIcon } from '@plarin/design';
import { Popover, TMemberCellMenu } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { useAppDataStore } from '../../../components/app-data-provider';
import classes from './member-cell-menu.module.scss';

export const MemberCellMenu = observer(({ data }: TMemberCellMenu) => {
  const {
    members: { openModalMember, deleteInviteMember, setWsDataDrawer, setUserId },
    profileStore: { WSRole },
  } = useAppDataStore();

  const cellMenuMembers = () => {
    const result = [];
    result.push({
      action: () => {
        setUserId(data._id);
        setAnchorEl(null);
      },
      name: 'Редактировать',
    });
    if (data.role !== 'wsrole:owner') {
      result.push(
        {
          action:
            data.status === 'active'
              ? () => {
                  openModalMember('block', data._id);
                  setAnchorEl(null);
                }
              : () => {
                  openModalMember('unBlock', data._id);
                  setAnchorEl(null);
                },
          name: data.status === 'active' ? 'Заблокировать' : 'Разблокировать',
        },
        {
          action: () => {
            openModalMember('remove', data._id);

            setAnchorEl(null);
          },
          name: 'Удалить',
        },
      );
    }
    return result;
  };

  const cellMenuInvites = [
    {
      action: () => deleteInviteMember(data.email),
      name: 'Удалить приглашение',
      isVisible: data.role !== 'wsrole:owner',
    },
  ];

  const cellMenu = data._id ? cellMenuMembers() : cellMenuInvites;

  // //////////////////////////

  const [isPositionTop, setPositionTop] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const onOpen = useCallback((e: MouseEvent, ref: HTMLDivElement) => {
    e.stopPropagation();
    setAnchorEl(ref);
    // @ts-ignore
    /* ref.view.setTextError - ref.clientY < 120 ? setPositionTop(true) : setPositionTop(false);*/
  }, []);

  const onClose = useCallback((e: React.MouseEvent | Event) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  return (
    <>
      {WSRole !== 'wsrole:employee' && (
        <>
          <div
            ref={ref => {
              if (!ref) return;
              ref.onclick = e => onOpen(e, ref);
            }}
            className={clsx(classes.settingsIcon, !!anchorEl && classes.forceVisibility)}
          >
            <SettingsIcon size={16} color="secondary" />
          </div>

          <Popover anchorEl={anchorEl} isOpen={!!anchorEl} setIsOpen={onClose} dataList={cellMenu} />
        </>
      )}
    </>
  );
});
