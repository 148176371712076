import React, { ReactNode } from 'react';

export type List = {
  type: string;
  title: string;
  message?: string | ReactNode;
  key?: number;
  canceledDownloadExcel?: (e: React.MouseEvent) => void;
};

export type NotifacationProps = { deleteNotification: (id: number) => void; notifications: List[] };
