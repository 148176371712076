import { HelpIcon } from '@plarin/design';
import React from 'react';
import { MainTooltip } from '../../tooltip';

type THelpInfoTooltipProps = {
  className: string | undefined;
  tooltipMessage: string;
  followCursor?: boolean;
};

export const HelpInfoTooltip = ({ className, tooltipMessage, followCursor }: THelpInfoTooltipProps) => {
  return (
    <MainTooltip
      tooltipMessage={tooltipMessage}
      isVisible={true}
      followCursor={followCursor || false}
      component="span"
      placement="bottom-start"
    >
      <HelpIcon className={className} size={16} />
    </MainTooltip>
  );
};
