import { CloseCircleIcon, ErrorIcon, Typography } from '@plarin/design';
import { int, percent, TooltipMessage } from '@plarin/utils';
import clsx from 'clsx';
import React, { useState } from 'react';
import { MainTooltip } from '../../../../tooltip';
import classes from '../style.module.scss';
import { CoverageProps } from './types';

export const Coverage = ({ loading, error, share, uniqs, removeCampaign, id }: CoverageProps) => {
  const [hover, setHover] = useState(false);
  // const myRef = useRef<HTMLSpanElement>(null);
  // const [width, setWidth] = useState(0);

  // useEffect(() => {
  // myRef.current && console.log(myRef.current.getBoundingClientRect());
  //   myRef.current && !loading && setWidth(myRef.current.getBoundingClientRect().width);
  // }, [loading]);
  // console.log(width);
  return (
    <>
      {loading ? (
        <div className={clsx(classes.coverageCol, classes.colorChange)} />
      ) : (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={classes.coverage}>
          {error ? (
            <MainTooltip
              tooltipMessage="Произошел сбой при загрузке прогноза"
              isVisible={true}
              followCursor={true}
              component="span"
              placement="bottom-start"
              maxWidth={175}
            >
              <div className={clsx(classes.coverageCol, classes.ml10)}>
                <Typography
                  size="AuthContent"
                  color="Error"
                  componentProps={{ style: { display: 'inline-block', marginRight: '3px' } }}
                >
                  Ошибка
                </Typography>
                <ErrorIcon color="status_error" className={classes.mr2} />
              </div>
            </MainTooltip>
          ) : (
            // <div className={clsx(classes.coverageCol, hover && classes.coverRageOverflow)}>
            <div className={classes.coverageCol}>
              <Typography size="AuthContent" ellips={true}>
                {int(uniqs)} {`(${percent(share)}) `}
              </Typography>
            </div>
          )}
          {hover && (
            <div className={classes.deleteIcon}>
              <MainTooltip
                tooltipMessage={TooltipMessage.DELETE_FROM_LIST}
                isVisible={true}
                component="span"
                placement="bottom-start"
                followCursor={true}
              >
                <CloseCircleIcon onClick={() => removeCampaign(id)} className={classes.mr2} />
              </MainTooltip>
            </div>
          )}
        </div>
      )}
    </>
  );
};
