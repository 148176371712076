import { argv0 } from 'process';
import { BasicFormStore, getApiUrl, httpClient, TFormInitParams, WORKSPACE_ID } from '@plarin/core';
import { TAlertProps } from '@plarin/inputs';
import { AxiosError } from 'axios';
import { action, autorun, makeObservable, observable, runInAction } from 'mobx';
import { paths } from '../../../types/profile/apispec';
import { history, saveAuthTokens, appLocalStorage } from '../../components/app';
import { routerPaths } from '../../routing/router-path';
import { ProfileStore } from '../../stores/profile.store';
import { STATUS } from '../../utils/constants';
import { getAlertProps } from '../../utils/get-alert-props';
import { getUrlAuthData } from '../../utils/get-url-auth-data';
import { TCompleteSocialRegistrationReq, TCompleteSocialRegistrationResp } from './types';

export type TRegistrationSocial = TCompleteSocialRegistrationReq;

const initData: TFormInitParams<TRegistrationSocial> = {
  fname: {
    value: '',
  },
  lname: {
    value: '',
  },
  token: {
    value: '',
  },
};

export class RegistrationSocialFormStore extends BasicFormStore<TRegistrationSocial> {
  profileStore: ProfileStore;
  emailFromToken = '';
  emailFromInput = '';
  phone = '';
  alertProps?: TAlertProps;
  errorCode?: string;

  constructor(profileStore: ProfileStore) {
    super(initData);
    this.profileStore = profileStore;
    makeObservable<this>(this, {
      submit: action.bound,
      completeRegistration: action.bound,
      completeRegistrationSuccess: action.bound,
      completeRegistrationError: action.bound,
      emailFromToken: observable,
      alertProps: observable,
      emailFromInput: observable,
      errorCode: observable,
    });
    autorun(() => {
      this.init();
    });
  }

  init = () => {
    const { token, lname, fname, email: emailFromToken, phone } = getUrlAuthData();
    if (emailFromToken && fname && lname && token) {
      this.emailFromToken = emailFromToken || ''; // при регистрации через вк почты может не быть, если есть телефон
      this.phone = phone || '';
      this.data.lname = lname;
      this.data.fname = fname;
      this.data.token = token;
      this.errorCode = undefined;
    }
  };

  submit = this.onSubmit(() => {
    runInAction(() => (this.errorCode = undefined));
    runInAction(() => (this.alertProps = undefined));

    const data = { ...this.data };

    // добавляем email в тело запроса, если пользователь самостоятельно вводил адрес почты в инпут. Если почта пришла от вконтакта в токене и она норм, то бэкенд заберёт почту из токена
    if (this.emailFromInput) {
      data.email = this.emailFromInput;
    }

    this.completeRegistration(data);
  });

  setNewEmail = (value: string) => runInAction(() => (this.emailFromInput = value));

  setAlertProps = (err: AxiosError<any, any>) => runInAction(() => (this.alertProps = getAlertProps(err)));
  setErrorCode = (errCode: string) => runInAction(() => (this.errorCode = errCode));

  completeRegistration = async (data: TCompleteSocialRegistrationReq) => {
    this.execRequest<[TCompleteSocialRegistrationResp]>([
      httpClient.post(getApiUrl<paths>('/api/v1/user/social/complete_registration'), data),
    ])
      .then(this.completeRegistrationSuccess)
      .then(() => {
        // TODO это повторяющийся код. Мб внутри profileStore создать такой метод?
        this.profileStore.loadProfileWithCheckInvites().then(e => {
          const workspaceId = appLocalStorage.get(WORKSPACE_ID);
          const workspace = e?.workspaces?.filter(el => el.workspace_id === workspaceId)[0];
          this.profileStore.setWorkspaceShortname(workspace?.shortname ?? '');
          this.profileStore.setWorkspaceName(workspace?.name ?? '');
          !workspaceId ? history.push(routerPaths.auth.ROOT) : history.push(`/${workspace?.shortname ?? ''}`);
        });
      })
      .catch(this.completeRegistrationError);
  };

  completeRegistrationSuccess = ([resp]: [TCompleteSocialRegistrationResp]) => {
    if (resp.status === STATUS.SUCCESS) {
      saveAuthTokens(resp.data!.access_token, resp.data!.refresh_token, resp.data!.refresh_expires);
    }
  };

  completeRegistrationError = (err: AxiosError<any>) => {
    this.setErrorCode(err.response?.data.error.code);
    this.setAlertProps(err);
  };
}
