import { components, operations } from '../../../types/profile/apispec';
import { SOCIAL_NAME, SocialNameType } from '../../utils/constants';

export type TLoginReq = components['schemas']['LoginSchema'];

export type TLoginResp = components['schemas']['ResponseModel_LoginResp_'];

export type TOauthRegistrationUrlReq =
  operations['oauth_registration_url_api_v1_user_oauth_registration_url_get']['parameters']['query'];

export type TOauthRegistrationUrlResp = components['schemas']['ResponseModel_ConnectUrlResponse_'];

export const mapSocialNamesToSocialNetworkEnum = (
  social: SocialNameType,
): components['schemas']['SocialNetworkEnum'] => {
  const map: {
    [key in SocialNameType]: components['schemas']['SocialNetworkEnum'];
  } = {
    vk: SOCIAL_NAME.VKONTAKTE,
    g: SOCIAL_NAME.GOOGLE,
  };
  return map[social];
};

export type TLoginPhoneReq = components['schemas']['LoginPhoneReq'];

export type TLoginPhoneResp = components['schemas']['ResponseModel_LoginResp_'];

export type TLoginPhoneCodeReq =
  components['schemas']['Body_send_auth_phone_code_api_v1_user_auth_login_phone_code_post'];

export type TLoginPhoneCodeResp = components['schemas']['ResponseModel_Dict_'];
