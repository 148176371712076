import axios from 'axios';
import { useEffect, useState } from 'react';
import moment from 'moment';
interface IAuthorizeProps {
  children: JSX.Element;
  isAuthorized?: boolean;
  logout: () => Promise<void>;
}

let interval: NodeJS.Timer;

const accessTokenName = 'plarin_app_storage_access_token';
const refreshTokenName = 'plarin_app_storage_refresh_token';
const isRefreshingKey = 'isRefreshing';

const refreshTokens = async (logout: () => Promise<void>) => {
  if (localStorage.getItem(isRefreshingKey)) {
    await new Promise<void>(resolve => {
      const interval = setInterval(() => {
        if (!localStorage.getItem(isRefreshingKey)) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
    return;
  }

  localStorage.setItem(isRefreshingKey, 'true');

  const refreshToken = localStorage.getItem(refreshTokenName);
  if (!refreshToken) {
    await logout();
    return;
  }

  try {
    const res = await axios.post(
      '/api/v1/user/auth/refresh',
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      },
    );

    localStorage.setItem(refreshTokenName, res.data.data.refresh_token);
    localStorage.setItem(accessTokenName, res.data.data.access_token);

    if (res.data.data.refresh_expires) {
      localStorage.setItem('expires', moment(res.data.data.refresh_expires).unix().toString());
    }
  } catch (e) {
    [accessTokenName, refreshTokenName, 'metrics'].forEach(item => localStorage.removeItem(item));
    await logout();
  } finally {
    localStorage.removeItem(isRefreshingKey);
  }
};

export const AuthorizedOnly = ({ children, isAuthorized, logout }: IAuthorizeProps) => {
  const [expires, setExpires] = useState(localStorage.getItem('expires'));

  useEffect(() => {
    if (isAuthorized) {
      if (expires) {
        interval = setInterval(() => {
          if (+expires * 1000 - Date.now() < 190000) {
            refreshTokens(logout);
            setExpires(localStorage.getItem('expires'));
          }
        }, 5000);
      } else {
        setTimeout(() => {
          refreshTokens(logout);
        }, 20000);
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [isAuthorized, logout, expires]);

  return isAuthorized ? children : null;
};
