import {
  ManageYDTabID,
  manageYDTabs,
  ManageYDTabs,
  StateValueEnum,
  StatusTextEnum,
  StatusValueEnum,
  statusEntities,
  mayArchive,
  mayModerate,
  mayResume,
  maySuspend,
  mayUnarchive,
  StatusToSet,
  statusToSet,
  TRows,
  ManageYDTabState,
  ManageYDTabStatus,
} from '@plarin/inputs';
import { ManageYDTabNameEnum } from '@plarin/utils';
import { IRowNode } from 'ag-grid-community';
import { Dictionary, MetricRecord } from '../../types/manage-vk/types';
import { TMetricGroupYD, TYDMetric } from '../../types/manage-yd/types';
import { ManageYDNameCellsEnum } from './constants';

type TdataState = {
  groups: { status: string; state: string };
  campaigns: { status: string; state: string };
  ads: {
    status: string;
    state: string;
  };
};

export const parseStringToCamelCase = (item: string): string => {
  const stringToArr = item.split('');
  stringToArr.forEach((item, index) => {
    (item === '.' || item === '_') && (stringToArr[index + 1] = stringToArr[index + 1].toUpperCase());
  });
  return stringToArr
    .filter(item => item !== '.')
    .filter(item => item !== '_')
    .join('');
};

export const returnId = (tabName: ManageYDTabs) => {
  let id = ManageYDTabID.accountId;
  switch (tabName) {
    case manageYDTabs.ACCOUNTS:
      return ManageYDTabID.accountId;
    case manageYDTabs.CAMPAIGNS:
      return ManageYDTabID.campaignId;
    case manageYDTabs.GROUPS:
      return ManageYDTabID.adGroupId;
    case manageYDTabs.ADS:
      return ManageYDTabID.adId;
  }
  return id;
};

export const returnItemIdByTabName = (tabName: ManageYDTabNameEnum) => {
  let id = ManageYDTabID.accountId;
  switch (tabName) {
    case ManageYDTabNameEnum.ACCOUNTS:
      return ManageYDTabID.accountId;
    case ManageYDTabNameEnum.CAMPAIGNS:
      return ManageYDTabID.campaignId;
    case ManageYDTabNameEnum.GROUPS:
      return ManageYDTabID.adGroupId;
    case ManageYDTabNameEnum.ADS:
      return ManageYDTabID.adId;
  }
  return id;
};

export const returnItemStateByTabName = (tabName: ManageYDTabNameEnum) => {
  switch (tabName) {
    case ManageYDTabNameEnum.ACCOUNTS:
      return undefined;

    case ManageYDTabNameEnum.CAMPAIGNS:
      return ManageYDTabState.campaignState;

    case ManageYDTabNameEnum.GROUPS:
      return undefined;

    case ManageYDTabNameEnum.ADS:
      return ManageYDTabState.adState;
  }
};

export const returnItemStatusByTabName = (tabName: ManageYDTabNameEnum) => {
  switch (tabName) {
    case ManageYDTabNameEnum.ACCOUNTS:
      return ManageYDTabStatus.accountStatus;

    case ManageYDTabNameEnum.CAMPAIGNS:
      return ManageYDTabStatus.campaignStatus;

    case ManageYDTabNameEnum.GROUPS:
      return ManageYDTabStatus.adGroupStatus;

    case ManageYDTabNameEnum.ADS:
      return ManageYDTabStatus.adStatus;
  }
};

// проверяем, какие из selectedRows у Яндекс Директ можно запустить, остановить, архивировать и т.д.
export const maySetStatus = (
  selectedRows: TRows,
  status: StatusToSet, // какой статус хотим присвоить сущностям Яндекс Директ
  idName: ManageYDTabID, // campaignId, adId и т.д.
  stateName: ManageYDTabState | undefined, // accountState, adState
  statusName: ManageYDTabStatus | undefined, // accountStatus, adStatus
) => {
  if (!stateName || !statusName) return [];

  switch (status) {
    case statusToSet.SUSPEND:
      return selectedRows.filter(item => maySuspend(item[stateName], item[statusName])).map(item => item[idName]);

    case statusToSet.RESUME:
      return selectedRows.filter(item => mayResume(item[stateName], item[statusName])).map(item => item[idName]);

    case statusToSet.ARCHIVE:
      return selectedRows.filter(item => mayArchive(item[stateName], item[statusName])).map(item => item[idName]);

    case statusToSet.MODERATE:
      return selectedRows.filter(item => mayModerate(item[stateName], item[statusName])).map(item => item[idName]);

    case statusToSet.UNARCHIVE:
      return selectedRows.filter(item => mayUnarchive(item[stateName], item[statusName])).map(item => item[idName]);
  }

  return [];
};

// дока на этот алгоритм: https://wiki.yandex.ru/homepage/planing/bazovyjj-funkcional-upravlenija-yandex.direct/metriki-dlja-tablic-upravlenija-reklamojj/#statusy-obuektov
const getStatusYD = ({ status, state }: { status: string; state: string }) => {
  // if state = ARCHIVED && status = any -> В архиве, Не транслируется, Серый архивный
  if (state === StateValueEnum.ARCHIVED) {
    return { ...statusEntities.ARCHIVED };
  }

  // if state = ON && status = any -> Запущено, Транслируется, Зеленый запущено
  if (state === StateValueEnum.ON) {
    return { ...statusEntities.ON };
  }

  // if state = OFF_BY_MONITORING && status = any -> Запущено, Транслируется, Зеленый
  if (state === StateValueEnum.OFF_BY_MONITORING) {
    return { ...statusEntities.OFF_BY_MONITORING };
  }

  // if state = SUSPENDED || ENDED
  if (state === StateValueEnum.SUSPENDED || state === StateValueEnum.ENDED) {
    if (status === StatusValueEnum.REJECTED) {
      return { ...statusEntities.REJECTED };
    }
    return { ...statusEntities.SUSPENDED };
  }

  if (state === StateValueEnum.OFF) {
    if (status === StatusValueEnum.ACCEPTED || status === StatusValueEnum.PREACCEPTED) {
      return { ...statusEntities.OFF };
    }
  }

  // if status = DRAFT -> Черновик, Не транслируется, Серый остановленный
  if (status === StatusValueEnum.DRAFT) {
    return { ...statusEntities.DRAFT };
  }

  // if status = MODERATION -> На модерации, Не транслируется, Зеленый запущено
  if (status === StatusValueEnum.MODERATION) {
    return { ...statusEntities.MODERATION };
  }

  // Default case: Данные отсутствуют, Трансляция неизвестна, Серый остановленный
  return { ...statusEntities.NO_DATA };
};

export const parseStatistics = (str: string) => {
  const values = str.split('\u0019').map(el => el.split('\u0016'));
  return values.filter(el => el.length === values[0].length);
};

export const comparatorStatus =
  (tabName: keyof typeof ManageYDNameCellsEnum) =>
  (valueA: string, valueB: string, nodeA: IRowNode, nodeB: IRowNode, isDescending: boolean) => {
    const { statusText: statusTextA } = nodeA.data;
    const { statusText: statusTextB } = nodeB.data;

    const aStatus = getSortByTranslation(statusTextA || '');
    const bStatus = getSortByTranslation(statusTextB || '');

    if (aStatus === bStatus) {
      return nodeA.data[tabName].toLowerCase() > nodeB.data[tabName].toLowerCase() ? 1 : -1;
    }

    return aStatus - bStatus;
  };

const getSortByTranslation = (statusText: string) => {
  switch (true) {
    case statusText === StatusTextEnum.ON:
      return 6;
    case statusText === StatusTextEnum.SUSPENDED:
      return 5;
    case statusText === StatusTextEnum.DRAFT:
      return 4;
    case statusText === StatusTextEnum.MODERATION:
      return 3;
    case statusText === StatusTextEnum.REJECTED:
      return 2;
    case statusText === StatusTextEnum.ARCHIVED:
      return 1;

    default:
      return 0;
  }

  //   Запущено
  // Остановлено
  // Черновик
  // На модерации
  // Отклоненные
  // В архиве
};

export const parseToData = (
  columns: string[],
  values: string[][],
  dict: Dictionary,
  tabName: ManageYDTabs,
  // wsDictionary?: TWsDictionary,
) => {
  const data: MetricRecord[] = [];

  values.forEach(stat => {
    let obj = {} as MetricRecord;

    stat.forEach((el, index) => {
      if (dict[columns[index]] === 'int' || dict[columns[index]] === 'percent' || dict[columns[index]] === 'float') {
        obj[columns[index]] = +el;
      } else if (el && dict[columns[index]] === 'currency') {
        obj[columns[index]] = parseFloat(el);
      } else if (el && dict[columns[index]] === 'json') {
        obj[columns[index]] = JSON.parse(el.replace(/('|`|^\w)/g, ''));
      } else {
        obj[columns[index]] = el;
      }
    });

    obj.key = Number(obj[returnId(tabName)]);
    obj.cellLoadingName = '';

    // obj.previewSmall = 'none';

    // if (obj.status) {
    //   obj.pastStatus = '';
    //   obj.nextStatus = '';

    // для определения какие ключи использовать из объекта в зависимости от таба для использования statusColor
    const dataState = {
      groups: { status: obj.adGroupStatus, state: obj.adGroupState },
      campaigns: { status: obj.campaignStatus, state: obj.campaignState },
      ads: {
        status: obj.adStatus,
        state: obj.adState,
      },
    } as TdataState;

    // const dataState = {
    //   groups: { status: obj.adGroupStatus, state: obj.adGroupState },
    //   campaigns: { status: obj.campaignStatus, state: obj.campaignState },
    //   ads: {
    //     status: obj.adStatus,
    //     state: obj.adState,
    //   },
    // } as TdataState;

    if (tabName !== 'accounts' && tabName !== 'groups') {
      const dataStatus = getStatusYD(dataState[tabName as keyof typeof dataState]);
      obj.statusColor = dataStatus.statusColor;
      obj.statusText = dataStatus.statusText;
      obj.translationText = dataStatus.translationText;
    }

    // }

    // ????
    // obj.budgetOptimizationLevel =
    //   obj.network === 'mt' ? 'none' : obj.adPlanDaily || obj.adPlanLifetime ? 'adPlan' : 'campaign';

    // obj.budgetLifetime &&
    //   (obj.percentBudgetLifetime = `${
    //     obj.budgetLifetime ? 100 - (+obj.statisticsSpentAll / +obj.budgetLifetime) * 100 || 100 : ''
    //   }`);

    // if (typeof obj.status !== 'number' && !obj.status?.includes('Архив')) {
    //   obj.fastStat = 0;
    //   obj.fastStat72 = 0;
    // }

    // if (obj.hasOwnProperty('mtProject')) {
    //   // @ts-ignore
    //   obj.mtProject = (obj.mtProject as string)
    //     .split(',')
    //     .map(el => (wsDictionary?.projects.hasOwnProperty(el) ? wsDictionary.projects[el] : { name: 'Не определен' }));
    // }

    // для получение имени отвественного ( есть методы преобразования по которому получаем id по
    // responsible и в wsDictionary.members получаем имя ответсвенного по id - для того что бы определялось имя ответсвенного
    // по id и если его нет то будет выводится "не указано", а в интерфейсе будет пусто вместо ответственного)

    // if (obj.hasOwnProperty('responsible')) {
    //   obj.responsible = wsDictionary?.members[obj.responsible as string] || 'Не указано';
    // }

    data.push(obj);
  });

  // if (data[0].status) {
  //   data[0].status.toString().length &&
  //     data.sort((a, b) => {
  //       let aStatus = getSortByStatus(a.status);
  //       let bStatus = getSortByStatus(b.status);

  //       return aStatus - bStatus === 0
  //         ? a[manageVkTableMainNames[tabName]].toString().toLowerCase() >
  //           b[manageVkTableMainNames[tabName]].toString().toLowerCase()
  //           ? 1
  //           : -1
  //         : aStatus - bStatus;
  //     });
  // }

  // if (data[0].status) {
  //   const active = data.filter(obj => typeof obj.status === 'string' && obj.status.includes('Активн'));
  //   const disabled = data.filter(obj => typeof obj.status === 'string' && obj.status.includes('Остановл'));
  //   const archived = data.filter(obj => typeof obj.status === 'string' && obj.status.includes('Архивн'));

  //   const activeActive = active.filter(el => el.statusColor !== 'banned');
  //   const activeBanned = active.filter(el => el.statusColor === 'banned');

  //   const disabledActive = disabled.filter(el => el.statusColor !== 'banned');
  //   const disabledBanned = disabled.filter(el => el.statusColor === 'banned');

  //   return data[0].adModerationStatus
  //     ? [...activeActive, ...activeBanned, ...disabledActive, ...disabledBanned, ...archived]
  //     : [...active, ...disabled, ...archived];
  // }
  return data;
};

export const getColumnsForTableYD = (
  columns: string[],
  dictionary: Dictionary,
  metrics: TMetricGroupYD[],
  // fast72Checked?: boolean,
) => {
  const result: TYDMetric[] = [];
  // нужно для добавления колонки по умолчанию
  // columns.push('satisticsFast');

  Object.keys(dictionary).forEach(el => {
    columns.forEach(columnName => {
      if (
        columnName === el
        // &&
        // columnName !== parseStringToCamelCase(ADS.ACTIVE) &&
        // columnName !== parseStringToCamelCase(ADS.PAUSED) &&
        // columnName !== parseStringToCamelCase(ADS.ARCHIVED)
      ) {
        metrics.forEach(group => {
          group.fields.forEach(metric => {
            if (columnName === parseStringToCamelCase(metric.path)) {
              result.push(metric);
            }
            // if (
            //   fast72Checked &&
            //   columnName === parseStringToCamelCase(FAST_STAT.SatisticsFast1) &&
            //   metric.path === FAST_STAT.SatisticsFast1
            // ) {
            //   metrics.forEach(
            //     groups =>
            //       groups.path === METRICS_GROUP_PATHS.STATISTIC &&
            //       groups.fields.forEach(metric => metric.path === FAST_STAT.SatisticsFast72 && result.push(metric)),
            //   );
            // }
          });
        });
      }

      // ?????? не понял зачем

      // if (columnName === parseStringToCamelCase(ADS.ACTIVE) && el === parseStringToCamelCase(ADS.ACTIVE)) {
      //   result.push({
      //     hidden: true,
      //     math_allowed: true,
      //     math_default: 'sum',
      //     name: { ru: 'Кол-во объявлений', en: 'Ads count' },
      //     obj_types: ['account', 'banner', 'ad_plan', 'campaign'],
      //     path: ADS.ALL,
      //     position: 'right',
      //     round_digits: 2,
      //     type: 'percent',
      //   });
      // }
    });
  });
  return result;
};
