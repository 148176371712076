import { PlarinLogo } from '@plarin/design';
import React from 'react';
import { AuthFrame } from '../../auth-frame';
import './auth-page-layout.scss';

export const AuthContentTemplate: React.FC<{
  footer: React.ReactNode;
}> = ({ children, footer }) => (
  <div className="authPageLayout">
    <a href="/" className="authPageLayout__logo">
      <PlarinLogo variant="auth-form" />
    </a>
    <div className="authPageLayout__form">
      <AuthFrame>{children}</AuthFrame>
    </div>
    <div className="authPageLayout__footer">{footer}</div>
  </div>
);
