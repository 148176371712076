import { ManageVkTabNameEnum, IntegrationsTabNameEnum } from './dictionary';

export type ObjectValue<K> = K[keyof K];

export type SortState = {
  colId?: string;
  sort?: string | null;
  sortIndex?: number | null;
  tabName?: ManageVkTabNameEnum | IntegrationsTabNameEnum;
};
