import { Popover } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useAppDataStore } from '../../../components/app-data-provider';

interface SettingMenuProps {
  data: any;
  onClose: (props: any) => void;
  anchorEl: HTMLDivElement | null;
  isAdmin?: boolean;
  isOpen: boolean;
  isEditDrawer?: boolean;
}

export const SettingsMenuTeams = observer(
  ({ data, onClose, anchorEl, isEditDrawer, isAdmin, isOpen }: SettingMenuProps) => {
    const {
      teams: { onOpenDrawer, setModalData, joinTeam, leaveTeam, setMenuIsLoading },
      profileStore: { data: profileData },
    } = useAppDataStore();

    const settingMenu = useMemo(() => {
      const isMember = data.member_ids.includes(profileData.user_id);
      const isLeader = data.leader_id === profileData.user_id;
      const result = [];
      if (!isEditDrawer && (isAdmin || isLeader)) {
        result.push({
          name: 'Редактировать',
          action: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
            onOpenDrawer({ ...data, isEditDrawer: true });
            onClose(e);
          },
        });
      }
      result.push({
        name: isMember ? 'Выйти из команды' : 'Присоедениться к команде',
        action: isMember
          ? (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
              leaveTeam(data, profileData.user_id, isAdmin || false);
              setMenuIsLoading(true);
              onClose(e);
            }
          : (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
              joinTeam(data, profileData.user_id);
              setMenuIsLoading(true);
              onClose(e);
            },
      });
      if (isAdmin || isLeader) {
        result.push(
          {
            name: data.status === 'inactive' ? 'Разархивировать' : 'Архивировать',
            action: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
              setModalData({ type: data.status === 'inactive' ? 'unArchive' : 'archive', data: data });
              onClose(e);
            },
          },
          {
            name: 'Удалить команду',
            action: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
              setModalData({ type: 'delete', data: data });
              onClose(e);
            },
          },
        );
      }
      return result;
    }, [data]);

    return <Popover anchorEl={anchorEl} isOpen={!!anchorEl} setIsOpen={onClose} dataList={settingMenu} />;
  },
);
