import { GmailLogo } from '@plarin/design';
import React from 'react';
import { SocialButton } from './social-button';
import { SocialConfiguredButtonProps } from './social-configured-button-props';

export const SocialGmailButton: React.FC<SocialConfiguredButtonProps> = function SocialGmailButton({ onClick }) {
  return (
    <SocialButton icon={null} onClick={onClick}>
      <GmailLogo />
    </SocialButton>
  );
};
