import { SmallArrowRightIcon, Typography } from '@plarin/design';
import { Checkbox, EllipsisTooltip, HightLightText, SearchDefaultInput } from '@plarin/inputs';
import { isCyrillic, switchEnToRu, switchRuToEn, typografNames } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TMetricGroup } from '../../../types/metrics';
import { getSortMetrics } from '../../utils/getSortMetrics';
import { useAppDataStore } from '../app-data-provider';
import { Chips } from '../chips';
import classes from './list.module.scss';

export const ListReport = observer(
  ({
    setReportsMetrics,
    reportMetrics,
  }: {
    setReportsMetrics: (metrics: string[]) => void;
    reportMetrics?: string[];
  }) => {
    const [filter, setFilter] = useState('');

    const {
      manageVK: { fast72Checked, metricsVK, getMetricsVK },
    } = useAppDataStore();

    const [metricsList, setMetricsList] = useState<TMetricGroup[]>([]);

    const filterMetricsList = useMemo(() => {
      const switchFilter = filter && (isCyrillic(filter) ? switchRuToEn(filter) : switchEnToRu(filter));

      let filterMetrics = (searchMetrics: string) => {
        return metricsList
          .map(item => {
            return searchMetrics && item.name.ru.toLowerCase().includes(searchMetrics.toLowerCase())
              ? {
                  ...item,
                  selected: item.name.ru.toLowerCase().includes(searchMetrics.toLowerCase()) && !item.selected,
                }
              : {
                  ...item,
                  selected:
                    item.fields
                      .map(el => el.name?.ru.toLowerCase().includes(searchMetrics.toLowerCase()))
                      .includes(true) && !item.selected,
                  fields: item.fields.filter(el => el.name?.ru.toLowerCase().includes(searchMetrics.toLowerCase())),
                };
          })
          .filter(item => item.fields.length);
      };

      const wordsSearchMetrics = filter.split(' ');
      if (wordsSearchMetrics.length >= 2) {
        let resFilter: string[] = [];
        const switchFilter = (text: string) => (isCyrillic(text) ? switchRuToEn(text) : switchEnToRu(text));

        for (let index = 0; index < wordsSearchMetrics.length; index++) {
          filterMetrics(wordsSearchMetrics[index]).length
            ? resFilter.push(wordsSearchMetrics[index])
            : resFilter.push(switchFilter(wordsSearchMetrics[index]));
        }

        return filterMetrics(resFilter.join(' '));
      }

      return filter
        ? filterMetrics(filter).length
          ? filterMetrics(filter)
          : filterMetrics(switchFilter)
        : metricsList;
    }, [filter, metricsList]);

    const onSelect = useCallback((path: string) => {
      setMetricsList(prev =>
        prev.map(item => (item.path === path ? { ...item, selected: !item.selected } : { ...item })),
      );
    }, []);

    const onCheckAll = (groupPath: string) => {
      if (filter) {
        const nonFilterFields =
          metricsList
            .find(item => item.path === groupPath)
            ?.fields.filter(
              ({ name }) =>
                !filterMetricsList
                  .find(item => item.path === groupPath)
                  ?.fields.map(el => el.name?.ru)
                  .includes(name?.ru),
            ) || [];

        setMetricsList(prev =>
          prev.map(group =>
            group.path === groupPath
              ? {
                  ...group,
                  fields: [
                    ...nonFilterFields,
                    ...filterMetricsList
                      .filter(item => item.path === groupPath)[0]
                      .fields.map((metric, _, originArray) => ({
                        ...metric,
                        checked: !(originArray.filter(el => el.checked).length === originArray.length),
                      })),
                  ].sort((a, b) => (a.name && b.name ? a.name.ru.localeCompare(b.name!.ru) : 0)),
                }
              : group,
          ),
        );
      } else {
        setMetricsList(prev =>
          prev.map(group =>
            group.path === groupPath
              ? {
                  ...group,
                  fields: group.fields.map((metric, _, originArray) => ({
                    ...metric,
                    checked: !(originArray.filter(el => el.checked).length === originArray.length),
                  })),
                }
              : group,
          ),
        );
      }
    };

    const onSingleCheck = (groupPath: string, metricPath: string) => {
      setMetricsList(prev =>
        prev.map(group =>
          group.path === groupPath
            ? {
                ...group,
                fields: group.fields.map(metric =>
                  metric.path === metricPath ? { ...metric, checked: !metric.checked } : metric,
                ),
              }
            : group,
        ),
      );
    };

    useEffect(() => {
      setMetricsList(prev => prev.map(item => ({ ...item, selected: false })));
    }, [filter]);

    useEffect(() => {
      if (metricsVK?.length) {
        if (reportMetrics) {
          setMetricsList(
            metricsVK.map(group => ({
              ...group,
              fields: group.fields
                .map(metric => ({
                  ...metric,
                  checked: reportMetrics.includes(metric.path),
                }))
                .filter(metric => !metric.hidden),
            })),
          );
        } else {
          setMetricsList(
            metricsVK.map(group => ({
              ...group,
              fields: group.fields.filter(metric => !metric.hidden),
            })),
          );
        }
      }
    }, [fast72Checked, getMetricsVK, metricsVK]);

    useEffect(() => {
      const checkedMetricsVK: string[] = [];
      metricsList.forEach(group =>
        group.fields.forEach(metric => metric.checked && checkedMetricsVK.push(metric.path)),
      );
      setReportsMetrics(checkedMetricsVK);
    }, [metricsList]);

    return (
      <div className={classes.metricsBlock}>
        <Typography size="Main" weight={600}>
          Колонки
        </Typography>
        <div className={classes.searchBlock}>
          <SearchDefaultInput
            placeholder="Поиск и фильтры"
            filterValue={filter}
            setFilter={setFilter}
            searchIconColor="main"
            isOutline
          />
        </div>

        {filterMetricsList.map(({ path, name: { ru }, selected, fields }) => (
          <div className={clsx(classes.listItem)} key={path}>
            <div className={classes.listButton} onClick={() => onSelect(path)}>
              <div className={classes.icon}>
                <SmallArrowRightIcon
                  className={clsx(classes.collapseIcon, selected && classes.collapseIconTransform)}
                />
              </div>
              <div
                onClick={e => {
                  e.stopPropagation();
                  onCheckAll(path);
                }}
              >
                <Checkbox
                  value={fields.some(metric => metric.checked)}
                  partChecked={fields.length !== fields.filter(el => el.checked).length}
                />
              </div>

              <EllipsisTooltip tooltipMessage={ru}>
                <HightLightText textEllips={true} text={typografNames(ru)} filterValue={filter} size="Caption" />
              </EllipsisTooltip>
            </div>
            {selected && (
              <div className={classes.collapse}>
                {fields.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => onSingleCheck(path, item.path)}
                    className={clsx(
                      classes.listButton,
                      classes.subListButton,
                      fields.length - 1 !== index && classes.subListButtonBorder,
                    )}
                  >
                    <Checkbox value={item.checked!} />
                    <div className={classes.titleReportMetrcs}>
                      <EllipsisTooltip tooltipMessage={item.name?.ru || item.path}>
                        <HightLightText
                          textEllips={true}
                          text={typografNames(item.name?.ru || item.path)}
                          filterValue={filter}
                          size="Caption"
                          color={!item.hidden ? 'TextPrimary' : 'TextTertiary'}
                        />
                      </EllipsisTooltip>
                    </div>
                    <div className={classes.chipsWrap}>
                      <Chips names={getSortMetrics(item.obj_types)} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  },
);
