import { Preloader } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import classes from './style.module.scss';

export const PreloaderRenderer = (props: any) => {
  // в некоторых ячейках таблиц интеграций лоадер нужно подвинуть левее
  const changePosition = props.loaderPrecisePositioning && props.loaderPrecisePositioning === -8;

  return <Preloader className={clsx(classes.wrapper, changePosition && classes.position8)} size={20} thickness={3} />;
};
