export const mapPasswordComplexity = {
  red: 'Слабый пароль',
  yellow: 'Простой пароль',
  green: 'Надёжный пароль',
};

export const mapPasswordErrors = {
  noPassword: 'Введите пароль',
  littlePassword: 'Пароль содержит менее 8 символов',
  bigPassword: 'Пароль содержит более 64 символов',
  wrongPassword: 'Пароль введен неверно',
  noNewPassword: 'Введите новый пароль',
};
