import {
  ImageInsideIcon,
  PerfomaceIcon,
  SourceIcon,
  DinamicIcon,
  MobileIcon,
  GalleryIcon,
  EyeOpenedIcon,
  AutomationIcon,
} from '@plarin/design';
import React from 'react';
import { MainTooltip } from './../../../tooltip';

export enum CampaignsIconYDEnum {
  TEXT_CAMPAIGN = 'TEXT_CAMPAIGN',
  UNIFIED_CAMPAIGN = 'UNIFIED_CAMPAIGN',
  SMART_CAMPAIGN = 'SMART_CAMPAIGN',
  DYNAMIC_TEXT_CAMPAIGN = 'DYNAMIC_TEXT_CAMPAIGN',
  MOBILE_APP_CAMPAIGN = 'MOBILE_APP_CAMPAIGN',
  MCBANNER_CAMPAIGN = 'MCBANNER_CAMPAIGN',
  CPM_BANNER_CAMPAIGN = 'CPM_BANNER_CAMPAIGN',
  CPM_DEALS_CAMPAIGN = 'CPM_DEALS_CAMPAIGN',
  CPM_FRONTPAGE_CAMPAIGN = 'CPM_FRONTPAGE_CAMPAIGN',
  CPM_PRICE = 'CPM_PRICE',
  DEFAULT = 'DEFAULT',
}

export const RenderIcon = (type: CampaignsIconYDEnum | undefined) => {
  const campaign = type && campaignsIconYD[type] ? campaignsIconYD[type] : campaignsIconYD['DEFAULT'];

  return campaign ? (
    <MainTooltip tooltipMessage={campaign.name} isVisible={true} component="span" followCursor={true}>
      {campaign.icon}
    </MainTooltip>
  ) : null;
};

export const campaignsIconYD: { [key in CampaignsIconYDEnum]: { icon: JSX.Element; name: string } } = {
  DEFAULT: {
    icon: <AutomationIcon size={14} />,
    name: 'Создана в Мастере кампаний',
  },
  TEXT_CAMPAIGN: {
    icon: <ImageInsideIcon size={14} />,
    name: 'Текстово-графические объявления',
  },
  UNIFIED_CAMPAIGN: {
    icon: <PerfomaceIcon size={14} />,
    name: 'Единая перфоманс-кампания',
  },
  SMART_CAMPAIGN: {
    icon: <SourceIcon size={14} />,
    name: 'Смарт-баннеры',
  },
  DYNAMIC_TEXT_CAMPAIGN: {
    icon: <DinamicIcon size={14} />,
    name: 'Динамические объявления',
  },
  MOBILE_APP_CAMPAIGN: {
    icon: <MobileIcon size={14} />,
    name: 'Реклама мобильных приложений',
  },
  MCBANNER_CAMPAIGN: {
    icon: <GalleryIcon size={14} />,
    name: 'Баннер на поиске',
  },
  CPM_BANNER_CAMPAIGN: {
    icon: <EyeOpenedIcon size={14} />,
    name: 'Медийная кампания',
  },
  CPM_DEALS_CAMPAIGN: {
    icon: <GalleryIcon size={14} />,
    name: 'Медийная кампания со сделками',
  },
  CPM_FRONTPAGE_CAMPAIGN: {
    icon: <GalleryIcon size={14} />,
    name: 'Медийная кампания с баннером под поисковой строкой',
  },
  CPM_PRICE: {
    icon: <GalleryIcon size={14} />,
    name: 'Кампания с фиксированным СРМ',
  },
};
