import { Typography, SettingAlarm } from '@plarin/design';
import React from 'react';
import { NetworkEnum } from '../../dictionary/integrations';
import classes from './style.module.scss';

type InfoBlockWithoutCabinetsProps = {
  network?: string;
};

export const InfoBlockWithoutCabinets = ({ network }: InfoBlockWithoutCabinetsProps) => {
  return (
    <div className={classes.infoBlockWithoutAccounts}>
      <div className={classes.leftContent}>
        <div className={classes.title}>
          <Typography weight={700} size="AuthSubheader">
            У вас пока нет рекламных кабинетов
          </Typography>
        </div>
        <div className={classes.subtitle}>
          <Typography size="AuthContent">
            Для возможности работы с рекламой необходимо создать рекламный кабинет на площадке
            <Typography color="TextIntegrationsLink" size="SizeAuthContent">
              {network === NetworkEnum.VKontakte && ' ВКонтакте'}
              .
              <br />
              <br />
            </Typography>
            После создания рекламного кабинета, необходимо его включить на платформе Plarin
          </Typography>
          {/* <br /> */}
          {/* <br /> */}
          {/* <Typography color="TextIntegrationsLink" size="AuthContent"> */}
          {/*   Подробнее */}
          {/* </Typography> */}
        </div>
      </div>
      <div className={classes.rightContent}>
        <SettingAlarm />
      </div>
    </div>
  );
};
