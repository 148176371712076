import { IconButton, Badge } from '@mui/material';
import { WorkflowItemIcon } from '@plarin/design';
import React from 'react';
import { WhiteCircle } from './white-circle';

import './workflow-button.scss';

interface IWorkFlowButton {
  icon?: React.ReactNode;
}

export const WorkflowButton: React.FC<IWorkFlowButton> = ({ icon = <WorkflowItemIcon /> }) => {
  return (
    <div className="work-flow-item">
      <Badge
        variant="dot"
        color="primary"
        // overlap="circular"
        classes={{
          colorPrimary: 'badgeColorPrimary1',
        }}
      >
        <IconButton
          classes={{
            root: 'workFlowIconButton',
          }}
        >
          {icon}
          <WhiteCircle />
        </IconButton>
      </Badge>
    </div>
  );
};
