import { ToggleButtonGroup } from '@mui/material';
import { Typography } from '@plarin/design';
import { MainTooltip, ToggleButton } from '@plarin/inputs';
import React, { useEffect, useState } from 'react';
import { ProposeIdeaImportance } from '../../../../types/propose-idea/types';
import classes from './style.module.scss';

type TImportance = {
  title: string;
  value: ProposeIdeaImportance;
  color: 'success' | 'warning' | 'error';
  tooltipText: string;
};
const Importance: TImportance[] = [
  {
    title: 'Было бы удобно',
    value: 'comfortable',
    color: 'success',
    tooltipText: 'Хотелось бы, чтобы предложенный функционал \n был, но я справляюсь и без него',
  },
  {
    title: 'Важно',
    value: 'important',
    color: 'warning',
    tooltipText: 'Предложенный функционал сильно упростит и улучшит \n качество моей работы',
  },
  {
    title: 'Критично важно',
    value: 'critical',
    color: 'error',
    tooltipText: 'Предложенный функционал необходим, без него \n невозможно решить свои задачи',
  },
];

type ImportanceBlockProps = {
  updateData: ({}) => void;
};

export const ImportanceBlock = ({ updateData }: ImportanceBlockProps) => {
  const [importance, setImportance] = useState<ProposeIdeaImportance | string>('');
  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    setImportance(nextView);
  };

  useEffect(() => {
    importance && updateData({ importance: importance });
  }, [importance]);

  return (
    <div className={classes.importance}>
      <Typography weight={600} size="Main" color="textPrimary">
        Оцените насколько важна для вас описанная функция?
      </Typography>

      <ToggleButtonGroup
        orientation="vertical"
        value={importance}
        exclusive
        onChange={handleChange}
        classes={{
          grouped: classes.grouped,
        }}
        fullWidth
      >
        {Importance.map(el => (
          <ToggleButton value={el.value} aria-label={el.value} color={el.color} key={el.value}>
            <MainTooltip
              tooltipMessage={el.tooltipText}
              isVisible={true}
              component="span"
              placement="bottom"
              componentFeature="toggleButton"
              followCursor
              maxWidth={335}
            >
              <Typography weight={600} size="Main" color="textPrimary" componentProps={{ className: classes.pd7 }}>
                {el.title}
              </Typography>
            </MainTooltip>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};
