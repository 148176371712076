import { Tab as TabMaterial, Tabs as TabsMaterial } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { MainTooltip } from '../tooltip';
import { TabsProps } from './tabs-props';
import classes from './tabs.module.scss';

export const Tabs: React.VFC<TabsProps> = function Tabs({ tabs, value, onChange, component, className }) {
  return (
    <TabsMaterial
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      variant="fullWidth"
      className={clsx(className, classes.wrap)}
      TabIndicatorProps={{ className: component && classes.menuIndicator }}
    >
      {tabs.map(tab => (
        <TabMaterial
          key={tab.value}
          value={tab.value}
          // label={tab.label}
          // ниже костыль нужен для показывания тултипа с disabled для tab
          icon={
            tab.disabled ? (
              <MainTooltip
                tooltipMessage={tab.tooltipMessage || ''}
                isVisible={tab.disabled || false}
                component="span"
                followCursor
              >
                <>{tab.label}</>
              </MainTooltip>
            ) : (
              tab.label
            )
          }
          style={{ pointerEvents: 'auto' }}
          disabled={tab.disabled}
          className={clsx(!component && classes.border, component === 'menu' && classes.newMenu)}
          component={component === 'menu' ? 'a' : 'button'}
          href={tab.link}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            tab.onClick && tab.onClick();
          }}
          classes={{
            selected: clsx(component === 'menu' && classes.selectMenu, classes.selected),
            disabled: classes.disabled,
          }}
        />
      ))}
    </TabsMaterial>
  );
};
