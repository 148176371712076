import { formatDateToReq, isUrlProd } from '@plarin/utils';
import { GridApi } from 'ag-grid-community';
import { toJS } from 'mobx';
import { TNewStrategyBid, TNewStrategyBids, LimitTypes, TCommonBudgetOptimizationLevel, BidItemsType } from '../inputs';
import { TRows, BidResp, StrategySaveAction, AvailableAdsProps } from './types';

export enum StrategyBidModes {
  maxPrice = 'Предельная цена',
  minPrice = 'Минимальная цена',
  differentModes = 'Разные значения',
}

export const inputsTypeVariants = [
  {
    value: StrategyBidModes.maxPrice,
    label: StrategyBidModes.maxPrice,
  },
  {
    value: StrategyBidModes.minPrice,
    label: StrategyBidModes.minPrice,
  },
];

// эти данные мы не можем получить от API, поэтому ручками забираем их из интерфейса vk ads
export const VKHardcode = {
  minLimit: 100,
  maxLimit: 9999999999,
};

export const limitMinMaxOk = (budgetLimit: number, minLimit: number) =>
  budgetLimit <= VKHardcode.maxLimit && budgetLimit >= minLimit;

export enum WhatIsEditedInStrategy {
  bid = 'bid',
  limit = 'limit',
}

export type EditBidProps = {
  selectedRows: TRows;
  close: () => void;
  onSaveAction: (args: StrategySaveAction) => Promise<BidResp>;
  gridApi: GridApi;
  getAvailableAds: (args: AvailableAdsProps) => any;
  isOnlyBudgetEditor?: boolean;
  whatIsEdited: WhatIsEditedInStrategy;
};

export type BudgetEditorProps = {
  rowData: Record<string, any>;
  close: () => void;
  onSaveAction: (args: StrategySaveAction) => Promise<BidResp>;
  gridApi: GridApi;
  getAvailableAds: (args: AvailableAdsProps) => any;
};

export const getHeader = (adPlanObjective: string, bidType: string) => {
  const whatYouPayFor = () => {
    if (bidType === 'CPC') return ' — Клики';
    if (bidType === 'CPM') return ' — Показы';

    return '';
  };

  if (adPlanObjective === 'site_conversions') {
    // TODO: кампаний может быть несколько
    return `Реклама сайта${whatYouPayFor()}`;
  }

  if (adPlanObjective === 'leadads') {
    return 'Лид-форма';
  }

  if (adPlanObjective === 'appinstalls') {
    return 'Установка приложения новыми пользователями';
  }

  if (adPlanObjective === 'reengagement') {
    return 'Приложение — вовлечение существующих пользователей';
  }

  if (adPlanObjective === 'storeproductssales') {
    return 'Каталог товаров';
  }

  if (adPlanObjective === 'socialengagement') {
    return 'Продвижение сообщества ВКонтакте';
  }
};

export const getSingleBidMode = (row: Record<string, any>) => {
  // maxPrice - "максимальная стоимость установки/конверсии" для стратегии "Предельная цена"

  if (row.maxPrice > 0) return StrategyBidModes.maxPrice;

  return StrategyBidModes.minPrice;
};

export const getBidMode = (selectedRows: TRows) => {
  if (selectedRows.length === 1) return getSingleBidMode(selectedRows[0]);

  if (new Set(selectedRows.map(row => getSingleBidMode(row))).size > 1) return StrategyBidModes.differentModes;

  return getSingleBidMode(selectedRows[0]);
};

export const getLimitTypeFromSingleItem = (limitDaily: number, limitLifetime: number) => {
  if (limitDaily > 0 && limitLifetime === 0) return LimitTypes.daily;
  if (limitDaily === 0 && limitLifetime > 0) return LimitTypes.lifetime;

  if (limitDaily > 0 && limitLifetime > 0) return LimitTypes.bothLimitsHaveValue;

  return LimitTypes.differentLimitTypes;
};

export const getCommonLimitType = (newBidsData: TNewStrategyBids) => {
  const limitsSet = new Set(newBidsData.map(item => getLimitTypeFromSingleItem(item.limitDaily, item.limitLifetime)));

  if (limitsSet.size === 1) return Array.from(limitsSet)[0];
  return LimitTypes.differentLimitTypes;
};

export const rowDataHasError = (
  selectedRow: Record<string, any>,
  itemsType: string,
  commonBudgetOptimizationLevel: TCommonBudgetOptimizationLevel,
) => {
  let hasError = false;

  // непонятен тип лимита
  if (
    commonBudgetOptimizationLevel === 'adPlan' &&
    getLimitTypeFromSingleItem(selectedRow.adPlanDaily || 0, selectedRow.adPlanLifetime || 0) ===
      LimitTypes.differentLimitTypes
  ) {
    !isUrlProd && console.log('Ошибка в пришедших данных: в дневном/общем лимите');
    hasError = true;
  }

  if (
    commonBudgetOptimizationLevel === 'campaign' &&
    getLimitTypeFromSingleItem(selectedRow.campaignDaily || 0, selectedRow.campaignLifetime || 0) ===
      LimitTypes.differentLimitTypes
  ) {
    !isUrlProd && console.log('Ошибка в пришедших данных: в дневном/общем лимите');
    hasError = true;
  }

  if (!selectedRow.bidType.length) {
    !isUrlProd && console.log('Ошибка в пришедших данных: нет bidType');
    // hasError = true;
  }

  if (!(selectedRow.packageLimitBidMax > 0)) {
    !isUrlProd && console.log('Ошибка в пришедших данных: нет packageLimitBidMax');
    hasError = true;
  }

  if (!(selectedRow.packageLimitBidMin > 0)) {
    !isUrlProd && console.log('Ошибка в пришедших данных: нет packageLimitBidMin');
    hasError = true;
  }

  return hasError;
};

export const maxPriceWasChanged = (selectedRow: Record<string, any>, newBidData: TNewStrategyBid) => {
  if (selectedRow.maxPrice !== newBidData.maxPrice) {
    return true;
  }

  return false;
};

export const limitWasChanged = (
  selectedRow: Record<string, any>,
  newBidData: TNewStrategyBid,
  budgetOptimizationLevel: string,
) => {
  const getLimitDaily = (level: string) => selectedRow[`${level}Daily`] || 0;
  const getLimitLifetime = (level: string) => selectedRow[`${level}Lifetime`] || 0;

  const isChanged = (limitDaily: number, limitLifetime: number) =>
    newBidData.limitDaily !== limitDaily || newBidData.limitLifetime !== limitLifetime;

  if (budgetOptimizationLevel === 'adPlan') {
    return isChanged(getLimitDaily('adPlan'), getLimitLifetime('adPlan'));
  }

  if (budgetOptimizationLevel === 'campaign') {
    return isChanged(getLimitDaily('campaign'), getLimitLifetime('campaign'));
  }

  return false;
};

export const getCommonDailyLimit = (newBidsData: TNewStrategyBids): number | undefined => {
  if (new Set(newBidsData.map(item => item.limitDaily)).size > 1) return undefined;

  return newBidsData[0].limitDaily;
};

export const getCommonLifetimeLimit = (newBidsData: TNewStrategyBids): number | undefined => {
  if (new Set(newBidsData.map(item => item.limitLifetime)).size > 1) return undefined;

  return newBidsData[0].limitLifetime;
};

export const getStartAndEndDateForReqBody = (data: TNewStrategyBid, itemType: BidItemsType) => {
  let startDate = null;
  let stopDate = null;

  if (itemType === 'ad_plan') {
    startDate = data.adPlanStart;
    stopDate = data.adPlanStop;
  } else if (itemType === 'campaign' || itemType === 'banner') {
    startDate = data.campaignStart;
    stopDate = data.campaignStop;
  }

  return {
    startDate: startDate ? formatDateToReq(startDate) : (null as unknown as string),
    stopDate: stopDate ? formatDateToReq(stopDate) : (null as unknown as string),
  };
};

export const getItemsTypeForAdsReq = (itemsType: BidItemsType) => {
  if (itemsType === 'ad_plan') return 'ad_plan';
  if (itemsType === 'campaign') return 'campaign';
  if (itemsType === 'banner') return 'ad';

  return undefined;
};
