import React from 'react';

export const CustomReferenceDot = (props: { cx: string | number | undefined; cy: string | number | undefined }) => {
  return (
    <>
      <circle cx={props.cx} r="6" cy={props.cy} fill="#537AC6"></circle>
      <circle cx={props.cx} r="10" cy={props.cy} fill="rgba(159, 181, 223, 0.5)">
        <animate attributeName="r" from="8" to="12" dur="1.5s" begin="0s" repeatCount="indefinite" />
      </circle>
      <circle cx={props.cx} r="2" cy={props.cy} fill="white"></circle>
    </>
  );
};
