import { Typography } from '@plarin/design';
import { SkeletonList } from '@plarin/inputs';
import { switchFilter } from '@plarin/utils';
import React from 'react';
import { TShortCabinets, TShortCabinetsFilters } from '../../../../../../../../types/projects/projects';
import classes from '../../styles.module.scss';
import { filtersShortCabinets } from '../project-info-cabinets';
import { ProjectClientsList } from './project-clients-list';

interface IProjectSearchResults {
  shortCabinets: TShortCabinets;
  filteredCabinets: null | TShortCabinetsFilters[];
  selectedCabinets: TShortCabinetsFilters[];
  changeCabinetSelection: (cabinets: TShortCabinetsFilters) => void;
  searchValue: string;
}

export const ProjectSearchResults = ({
  shortCabinets,
  filteredCabinets,
  selectedCabinets,
  changeCabinetSelection,
  searchValue,
}: IProjectSearchResults) => {
  const isFilteredCabinetsSearch =
    filteredCabinets &&
    filteredCabinets.filter(el => !selectedCabinets?.find(item => item.client_id === el.client_id)).length === 0;

  if (shortCabinets.error) {
    return (
      <div className={classes.wrapMessage}>
        <Typography size="AuthContent" color="TextSecondary" weight={400}>
          Произошла ошибка при загрузке рекламных кабинетов.
        </Typography>
      </div>
    );
  }
  if (shortCabinets.clients.length === selectedCabinets.length) {
    return (
      <div className={classes.wrapMessage}>
        <Typography size="AuthContent" color="TextSecondary" weight={400}>
          Все рекламные кабинеты в пространстве добавлены в проект.
        </Typography>
      </div>
    );
  }
  if (shortCabinets.clients && isFilteredCabinetsSearch) {
    return (
      <div className={classes.wrapMessage}>
        <Typography size="AuthContent" color="TextSecondary" weight={400}>
          По вашему запросу не найден ни один рекламный кабинет.
        </Typography>
      </div>
    );
  }

  if (shortCabinets.clients) {
    return (
      <ProjectClientsList
        cabinetsListToRender={filteredCabinets}
        selectedCabinets={selectedCabinets}
        changeCabinetSelection={changeCabinetSelection}
        searchValue={
          filtersShortCabinets(searchValue, shortCabinets.clients).length ? searchValue : switchFilter(searchValue)
        }
      />
    );
  }

  if (shortCabinets.loading) {
    return (
      <div className={classes.wrap}>
        <SkeletonList size="maxLong" height={17} sumList={7} />
      </div>
    );
  }

  return (
    <div className={classes.wrapMessage}>
      <Typography size="AuthContent" color="TextSecondary" weight={400}>
        Что-то пошло не так.
      </Typography>
    </div>
  );
};
