import { Typography } from '@plarin/design';
import React from 'react';
import { components } from '../../../../types/profile/apispec';
import { SocialNetwork } from '../../../../types/profile/types';
import { Social } from '../../../enums';
import { SOCIAL_NAME } from '../../../utils/constants';
import classes from './style.module.scss';

export type ProfileItemProps = {
  data?: components['schemas']['SocialShortResponse'];
  icon: React.ReactNode;
  title: string;
  actionAdd: (social: SocialNetwork) => void;
  actionRemove: (social: SocialNetwork) => void;
  isDisabled?: boolean;
  userName?: string;
};

export const ProfileItem = ({ data, icon, title, actionAdd, actionRemove, isDisabled, userName }: ProfileItemProps) => {
  const SocialLogin = () => {
    if (title === Social.vk) {
      data ? actionRemove(SOCIAL_NAME.VKONTAKTE) : actionAdd(SOCIAL_NAME.VKONTAKTE);
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.leftContent}>
        {icon}
        <span className={classes.title}>
          <Typography size="AuthSubheader" weight={600} color={isDisabled ? 'TextTertiary' : 'TextPrimary'}>
            {title}
          </Typography>
        </span>
        <span className={classes.email}>
          <Typography size="AuthSubheader" color="TextSecondary" weight={400}>
            {data && userName}
          </Typography>
        </span>
      </div>
      <div className={isDisabled ? '' : classes.rightContent} onClick={isDisabled ? () => {} : SocialLogin}>
        <Typography size="AuthContent" color={isDisabled ? 'TextTertiary' : 'LinkPrimary'}>
          {isDisabled ? 'Временно недоступно' : data ? 'Отключить' : 'Подключить'}
        </Typography>
      </div>
    </div>
  );
};
