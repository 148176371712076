import { useEffect } from 'react';

export const useEscape = (onEscape: (() => void) | undefined) => {
  const onEsc = (event: KeyboardEvent) => {
    event.key === 'Escape' && onEscape && onEscape();
  };
  useEffect(() => {
    if (onEscape) {
      document.addEventListener('keydown', onEsc);
    }

    return () => {
      document.removeEventListener('keydown', onEsc);
    };
  }, []);
};
