import { SkeletonReports } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useAppDataStore } from '../../components/app-data-provider';
import { ReportDrawer } from '../../components/drawer/report-drawer';
import { ReportsHeader } from './reports-header';
import { ReportsList } from './reports-list';
import './commonStyles.scss';
import classes from './reports.module.scss';

const Reports = () => {
  const {
    reports: { getReports, isLoading, setDefaultInitialStateDrawerReport },
  } = useAppDataStore();

  useEffect(() => {
    getReports().then();
    return () => {
      setDefaultInitialStateDrawerReport();
    };
  }, [getReports]);

  return (
    <div className={classes.reportsWrapper}>
      <ReportsHeader />
      {isLoading ? <SkeletonReports /> : <ReportsList />}
      <ReportDrawer />
    </div>
  );
};

export default observer(Reports);
