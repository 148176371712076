import { ArrowDownIcon, ArrowUpIcon, Typography } from '@plarin/design';
import { int, percent } from '@plarin/utils';
import React from 'react';
import { ProjectionPoint } from '../../../types';
import classes from '../style.module.scss';

type InfoBlockBidsProps = {
  currentRate: ProjectionPoint | undefined;
  newRate: ProjectionPoint | undefined;
};

export const InfoBlockBids = ({ currentRate, newRate }: InfoBlockBidsProps) => {
  return (
    <div className={classes.infoBlock}>
      <div className={classes.coverageBlock}>
        <Typography size="TableRowSubHeader" color="TextSecondary">
          Охват:
        </Typography>
        <div className={classes.dataInfo}>
          <Typography size="Main" weight={600}>
            {newRate ? newRate && percent(newRate.share) : currentRate && percent(currentRate.share)}
          </Typography>
          <Typography size="Main" color="TextSecondary">
            {newRate && currentRate && newRate.share - currentRate.share > 0 && '+'}
            {newRate &&
              currentRate &&
              newRate.share - currentRate.share !== 0 &&
              percent(newRate.share - currentRate.share)}
          </Typography>
          {newRate &&
            currentRate &&
            newRate.share - currentRate.share != 0 &&
            (newRate.share - currentRate.share > 0 ? (
              <ArrowUpIcon color="status_success" size={20} />
            ) : (
              <ArrowDownIcon color="status_failed" size={20} />
            ))}
        </div>
      </div>
      <div className={classes.coverageBlock}>
        <Typography size="TableRowSubHeader" color="TextSecondary">
          Охват (человек):
        </Typography>
        <div className={classes.dataInfo}>
          <Typography size="Main" weight={600}>
            {newRate ? newRate && int(newRate.uniqs) : currentRate && int(currentRate.uniqs)}
          </Typography>
          <Typography size="Main" color="TextSecondary">
            {newRate && currentRate && newRate.uniqs - currentRate.uniqs > 0 && '+'}
            {newRate && currentRate && newRate.uniqs - currentRate.uniqs != 0 && int(newRate.uniqs - currentRate.uniqs)}
          </Typography>
          {newRate &&
            currentRate &&
            newRate.uniqs - currentRate.uniqs != 0 &&
            (newRate.uniqs - currentRate.uniqs > 0 ? (
              <ArrowUpIcon color="status_success" size={20} />
            ) : (
              <ArrowDownIcon color="status_failed" size={20} />
            ))}
        </div>
      </div>
    </div>
  );
};
