import { RedoIcon, Typography } from '@plarin/design';
import { ActionTableButton, Button, GridApi, Modal, ModalActionNamesEnum, SelectButton } from '@plarin/inputs';
import { typografText } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { Account } from '../../../../types/integration/types';
import { useAppDataStore } from '../../../components/app-data-provider';
import classes from './style.module.scss';

enum ActionKeysEnum {
  enabled = '1',
  disabled = '2',
  deleted = '3',
}

type IntegrationsActionBarProps = {
  gridApi: GridApi;
  selectedRows?: Account[];
};

export const IntegrationsActionBar = observer(({ gridApi, selectedRows = [] }: IntegrationsActionBarProps) => {
  const {
    integrationsVK: {
      fetchClients,
      openDrawer,
      isLoading,
      disconnectAccounts,
      enableAccounts,
      disableAccounts,
      isTableLoading,
      visibleModal,
      openModal,
      closeModal,
    },
  } = useAppDataStore();

  const rowsForDisable = selectedRows.filter(item => item.syncStatusCode === 'enabled');

  const rowsForEnable = selectedRows.filter(item => item.syncStatusCode !== 'enabled');

  const setLoading = (rowsAccount: Account[]) => {
    gridApi.applyTransaction({ update: rowsAccount.map(el => ({ ...el, syncStatusCode: 'loading' })) });
  };

  const renderList = [
    {
      key: ActionKeysEnum.enabled,
      label: rowsForEnable?.length === 1 ? 'Включить аккаунт' : `Включить аккаунты (${rowsForEnable?.length})`,
      rowsCount: selectedRows?.filter(item => item.syncStatusCode !== 'enabled').length,
      action: () => {
        setLoading(rowsForEnable);
        gridApi.refreshCells();
        setTimeout(() => {
          enableAccounts(rowsForEnable || []);
        }, 500);
      },
    },
    {
      key: ActionKeysEnum.disabled,
      label: rowsForDisable?.length === 1 ? 'Выключить аккаунт' : `Выключить аккаунты (${rowsForDisable?.length})`,
      rowsCount: selectedRows?.filter(item => item.syncStatusCode === 'enabled').length,
      action: () => {
        setLoading(rowsForDisable);
        gridApi.refreshCells();
        setTimeout(() => {
          disableAccounts(rowsForDisable || []);
        }, 500);
      },
    },
    {
      key: ActionKeysEnum.deleted,
      rowsCount: selectedRows.length,
      label:
        selectedRows.length === 1
          ? 'Удалить аккаунт и все данные'
          : `Удалить аккаунты и все данные (${selectedRows.length})`,
      action: openModal,
    },
  ].filter(
    item =>
      (item.key === ActionKeysEnum.deleted && item.rowsCount < 2) ||
      (item.key !== ActionKeysEnum.deleted && item.rowsCount),
  );

  const onclick = useCallback(() => openDrawer(), [openDrawer]);
  return (
    <div className={classes.actionBar}>
      <div className={classes.connect}>
        <Button variant="filled" size="small" color="primary" onClick={onclick}>
          Подключить
        </Button>
        <div className={classes.select}>
          <SelectButton renderList={renderList} disabled={isTableLoading || (selectedRows && !selectedRows.length)} />
        </div>
      </div>

      <ActionTableButton
        textTooltip="Обновить"
        action={fetchClients}
        loading={isTableLoading}
        icon={<RedoIcon />}
        isReloadButton={true}
      />

      <Modal
        open={visibleModal}
        closeModal={closeModal}
        firstAction={{
          actionTitle: ModalActionNamesEnum.Delete,
          action: () => {
            setLoading(selectedRows);
            disconnectAccounts(selectedRows);
          },
        }}
        secondAction={{ actionTitle: ModalActionNamesEnum.Cancel, action: closeModal }}
        workspaceHeader="remove"
        title="Удалить аккаунт?"
      >
        <div className={classes.contentDelete}>
          <div className={classes.title}>
            <Typography size="AuthContent" weight={600}>
              {typografText(`Вы уверены что хотите удалить аккаунт 
              ${selectedRows?.length && `${selectedRows[0].name} (${selectedRows[0].clientId})`} из Plarin?`)}
            </Typography>
          </div>
          <div className={classes.subtitle}>
            <Typography size="AuthContent">
              {typografText(
                'Выбранный аккаунт будет удален, и его нельзя будет использовать в продуктах и сервисах Plarin. Статистика будет удалена и не будет больше синхронизироваться по данному аккаунту.',
              )}
            </Typography>
          </div>
        </div>
      </Modal>
    </div>
  );
});
