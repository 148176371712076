import { GridApi } from 'ag-grid-community';
import { BidItemsType, TCommonBudgetOptimizationLevel, TNewStrategyBids } from '../inputs/bids';
import { WhatIsEditedInStrategy } from './index';

export type TRows = Record<string, any>[];

export type ProjectionPoint = { uniqs: number; share: number; price: number };

export type Currency = 'EUR' | 'RUB' | 'USD' | 'CNY';

export type ProjectionResp = {
  chart_data: ProjectionPoint[];
  /** Тип */
  bid_type: string;
  /** Точка для указанной цены */
  point: ProjectionPoint;
  /** Минимальный лимит */
  min: number;
  /** Максимальный лимит */
  max: number;
  /** Валюта аккаунта myTarget */
  currency: Currency;
  /** ID кампании */
  campaign_id: number;
};

export type BidResp = {
  command_results?:
    | {
        status: 'new' | 'accepted' | 'in_progress' | 'done' | 'canceled';
        success?: boolean | undefined;
        command_id: string;
      }[]
    | undefined;
  errors?:
    | {
        obj_id: number;
        code: string;
        message: string;
        kwargs: {};
      }[]
    | undefined;
  command_batch_id?: string | undefined;
};

export type TGetProjection = (id: string | number | [], allPoints: boolean) => Promise<ProjectionResp>;

export type FixBidSaveActionProps = {
  name: 'FIX_BID';
  obj_type: string;
  obj_id: number;
  network: string;
  bid: number;
}[];

export type StrategyBidSaveActionProps = {
  name: 'AUTOBIDDING_MODE';
  obj_type: string;
  obj_id: number;
  network: string;
  autobidding_mode?: string;
  max_price: number;
  budget_limit: number;
  budget_limit_day: number;
  date_start: string;
  date_end: string;
}[];

export type StrategySaveAction = {
  requestData: StrategyBidSaveActionProps;
  itemsType: BidItemsType;
  commonBudgetOptimizationLevel: TCommonBudgetOptimizationLevel;
  newBidsData: TNewStrategyBids;
  gridApi: GridApi;
  whatIsEdited: WhatIsEditedInStrategy;
};

export type AvailableAdsProps = {
  ids: number[];
  type: 'campaign' | 'ad_plan' | 'ad';
  setIsBlockedBudget: React.Dispatch<React.SetStateAction<boolean>>;
  setAdsArr: any;
};
