import clsx from 'clsx';
import React from 'react';

export type TAlertProps = {
  title: string;
  content?: React.ReactNode;
};

export const Alert: React.VFC<TAlertProps> = function Alert({ title, content }) {
  return (
    <div className={clsx('alert')}>
      {title && <span>{title}</span>}
      {content && <p>{content}</p>}
    </div>
  );
};
