import { IdeaIcon, Typography } from '@plarin/design';
import { MainTooltip } from '@plarin/inputs';
import { TooltipMessage } from '@plarin/utils';
import { observer } from 'mobx-react';
import React from 'react';
import { DrawerId } from '../../enums';
import { useAppDataStore } from '../app-data-provider';
import classes from './style.module.scss';

export const BlockβVer = observer(({}) => {
  const {
    menu: { setOpenDrawer },
  } = useAppDataStore();

  return (
    <div className={classes.blockVer}>
      <MainTooltip
        tooltipMessage={TooltipMessage.TEST_FUNCTIONALITY}
        isVisible={true}
        component="span"
        placement="bottom"
        maxWidth={250}
      >
        <Typography size="AuthContent" color="TextSecondary">
          β&#8209;версия
        </Typography>
      </MainTooltip>
      <MainTooltip
        tooltipMessage={TooltipMessage.SUGGEST_IDEA}
        isVisible={true}
        component="span"
        placement="bottom"
        maxWidth={300}
      >
        <span className={classes.blockIcon} onClick={() => setOpenDrawer(DrawerId.proposeIdea)}>
          <IdeaIcon hasSafebox={true} className="menu-icon" />
        </span>
      </MainTooltip>
    </div>
  );
});
