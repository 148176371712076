import type { VirtualElement as IVirtualElement } from '@popperjs/core';
import React, { useRef, useEffect } from 'react';
import { ReactNode, useState, MutableRefObject } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { useEvent } from 'react-use';

export interface PopperPortalProps {
  active?: boolean;
  children: ReactNode;
}

export const useLazyRef = <T extends any>(factory: () => T): MutableRefObject<T> => {
  const didInit = useRef(false);
  const ref = useRef<T | undefined>(undefined);

  if (!didInit.current) {
    ref.current = factory();
    didInit.current = true;
  }

  return ref as MutableRefObject<T>;
};

class VirtualElement implements IVirtualElement {
  private rect = {
    width: 0,
    height: 0,
    top: -1000,
    right: 0,
    bottom: 0,
    left: -1000,
    x: 0,
    y: 0,
    toJSON() {
      return this;
    },
  };

  update(x: number, y: number) {
    this.rect.y = y;
    this.rect.top = y;
    this.rect.bottom = y;

    this.rect.x = x;
    this.rect.left = x;
    this.rect.right = x;
  }

  getBoundingClientRect(): DOMRect {
    return this.rect;
  }
}

const POPPER_OPTIONS: Parameters<typeof usePopper>[2] = {
  placement: 'right-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [10, 10],
      },
    },
  ],
};

export default function TooltipPortal({ active = true, children }: PopperPortalProps) {
  const [portalElement, setPortalElement] = useState<HTMLDivElement>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const virtualElementRef = useLazyRef(() => new VirtualElement());
  // @ts-ignore
  const { styles, attributes, update } = usePopper(virtualElementRef.current, popperElement, POPPER_OPTIONS);

  useEffect(() => {
    const el = document.createElement('div');
    document.body.appendChild(el);
    setPortalElement(el);
    return () => el.remove();
  }, []);

  useEvent('mousemove', ({ clientX: x, clientY: y }) => {
    // @ts-ignore
    virtualElementRef.current.update(x, y);
    if (!active) return;
    update?.();
  });

  useEffect(() => {
    if (!active) return;
    update?.();
  }, [active, update]);

  if (!portalElement) return null;

  return createPortal(
    <div
      ref={setPopperElement}
      {...attributes.popper}
      style={{
        ...styles.popper,
        zIndex: 1000,
        display: active ? 'flex' : 'none',
      }}
    >
      {children}
    </div>,
    document.body,
  );
}
