import { Avatar, SmashedAvatar, Typography } from '@plarin/design';
import React, { useRef, useState } from 'react';
import { MainTooltip } from '../../../tooltip';
import { ItemsPopover } from '../../../wsTableActionMenu';
import classes from './style.module.scss';

interface Props {
  projectsData: { avatar_color: string; id: string; name: string }[];
  node?: { data: { plarin_status: string; status: string } };
  type?: 'circle' | 'square';
  header?: string;
}

export const ProjectListRenderer = ({ node, projectsData, type = 'square', header }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const actionRef = useRef(null);
  const isActionMenuOpen = Boolean(anchorEl);

  const openMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div className={classes.wrap}>
      {!projectsData.length && (
        <Typography color="TextSecondary" weight={400} componentProps={{ className: classes.secondaryName }}>
          —
        </Typography>
      )}

      {/* Если проектов меньше пяти, показываем все аватарки */}
      {!!projectsData.length &&
        projectsData.length < 5 &&
        projectsData.map((project, index) => (
          <MainTooltip key={index} tooltipMessage={project.name} component="div" isVisible={true}>
            <Avatar
              name={project?.name}
              type={type}
              hasBorder={true}
              colorAvatar={
                node?.data.status === 'inactive' || node?.data?.plarin_status === 'disabled'
                  ? 'C4CAD4'
                  : project.avatar_color
              }
            />
          </MainTooltip>
        ))}

      {/* Если проектов пять или больше, аватарки схлопываются */}
      {projectsData.length >= 5 && (
        <>
          {projectsData.slice(0, 4).map((project, index) => (
            <MainTooltip key={index} tooltipMessage={project?.name} component="div" isVisible={true}>
              <Avatar
                name={project?.name}
                type={type}
                hasBorder={true}
                colorAvatar={
                  node?.data?.status === 'inactive' || node?.data?.plarin_status === 'disabled'
                    ? 'C4CAD4'
                    : project.avatar_color
                }
              />
            </MainTooltip>
          ))}
          <SmashedAvatar amount={projectsData.length - 4} type={type} action={openMenu} />
          <ItemsPopover
            type={type}
            isOpen={isActionMenuOpen}
            closeMenu={closeMenu}
            actionRef={actionRef}
            anchorEl={anchorEl}
            items={projectsData}
            header={header ? header : 'Проекты команды'}
            rowContentStatus={node?.data?.status || node?.data?.plarin_status}
          />
        </>
      )}
    </div>
  );
};
