import { Accept } from 'react-dropzone';

const mapStrAcceptType = {
  'image/jpeg': 'jpeg',
  'image/png': 'png',
  'image/gif': 'gif',
  'application/pdf': 'pdf',
  'application/vnd.ms-excel': 'xlsx',
  'application/msword': 'doc',
  'application/vnd.ms-powerpoint': 'pptx',
};

export const getStrAcceptType = (accept: Accept) => {
  const arrAcceptStr = [];
  for (let key in accept) {
    if (mapStrAcceptType.hasOwnProperty(key)) {
      // @ts-ignore
      arrAcceptStr.push(mapStrAcceptType[key]);
    }
  }
  return arrAcceptStr.join(', ');
};

export const getFormatStrFile = (file: File) => {
  const name = file.name.replace(/\.[^/.]+$/, '');
  const type = file.name.replace(/.*\.(?=)/, '').toUpperCase();
  const size = formatBytes(file.size).replace('.', ',');
  return { name, type, size };
};

export const formatBytes = (bytes: number, decimals = 2) => {
  let k = 1024;
  let dm = decimals < 0 ? 0 : decimals;
  let sizes = ['байт', 'Кб', 'Мб'];
  let i = bytes > 0 ? Math.floor(Math.log(bytes) / Math.log(k)) : 0;
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getSklonenie = (number: number, text: string[]) => {
  let cases = [2, 0, 1, 1, 1, 2];
  return text[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
};
