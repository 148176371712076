import { BasicFormStoreError, BasicStore, EApiCodes } from '@plarin/core';
import { TAlertProps } from '@plarin/inputs';
import { AxiosError } from 'axios';
import { action, makeObservable, observable, reaction } from 'mobx';
import { getAlertProps, getApiCodeAxiosError } from '../../../utils/get-alert-props';
import { AConfirmPhoneStore } from './confirm-phone.store';

const retryTimeout = 60;

export class AuthConfirmPhoneFormStore extends BasicStore {
  dispose?: () => void;
  alertProps?: TAlertProps;
  smsRetryTimeout: number = retryTimeout;
  locked: boolean = false;
  storeData: AConfirmPhoneStore;
  private timer?: ReturnType<typeof setTimeout>;

  constructor(storeData: AConfirmPhoneStore) {
    super();
    this.storeData = storeData;
    makeObservable<this>(this, {
      alertProps: observable,
      smsRetryTimeout: observable,
      locked: observable,
      init: action.bound,
      startCountDown: action.bound,
      countDown: action.bound,
      retry: action.bound,
      onError: action.bound,
      onUmount: action.bound,
    });
    this.init();
  }

  init = () => {
    this.dispose = reaction(
      () => this.storeData.data.code,
      () => {
        if (this.storeData.data.code.length === 6) {
          this.storeData.onSendCode();
        }
      },
    );
    this.startCountDown(retryTimeout);
  };

  onUmount = () => {
    this.storeData.data.code = '';
    this.dispose && this.dispose();
  };

  startCountDown = (timeout: number) => {
    this.smsRetryTimeout = timeout;
    this.countDown();
  };

  countDown = () => {
    if (this.smsRetryTimeout > 0) {
      this.smsRetryTimeout -= 1;
    }
    if (this.timer === undefined && this.smsRetryTimeout > 0) {
      this.timer = setTimeout(() => {
        this.timer = undefined;
        this.countDown();
      }, 1000);
    }
  };

  retry = () => {
    this.startCountDown(retryTimeout);
    this.storeData.retryCode().catch(this.onError);
  };

  onError = (err: AxiosError<any>) => {
    const apiCode = getApiCodeAxiosError(err);
    if (apiCode === EApiCodes.THROTTLING_LIMIT_2_MIN) {
      this.startCountDown(retryTimeout * 2);
      this.storeData.setError('code', new BasicFormStoreError('Подождите 2 минуты'));
    } else if (apiCode === EApiCodes.THROTTLING_LIMIT_5_MIN) {
      this.startCountDown(retryTimeout * 5);
      this.storeData.setError('code', new BasicFormStoreError('Подождите 5 минут'));
    } else if (apiCode === EApiCodes.NEED_CAPTCHA) {
      this.storeData.needCaptcha = true;
    } else {
      this.alertProps = getAlertProps(err);
      this.storeData.alertProps = getAlertProps(err);
    }
    if (apiCode === EApiCodes.LIMIT_EXCEEDED) {
      this.locked = true;
    }
  };
}
