import { ManageVkTabNameEnum, int, IntegrationsTabNameEnum, ManageYDTabNameEnum } from '@plarin/utils';
import { ColumnApi, ColumnState, GridApi } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { Column } from './';

export const copy = (
  event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLSpanElement> | MouseEvent,
  value: string,
) => {
  event.stopPropagation();
  return navigator.clipboard.writeText(value);
};

export const customSelectNodes = (selectedRowKeys?: number[], value?: boolean, api?: GridApi) => {
  if (selectedRowKeys && selectedRowKeys.length && api) {
    const nodes: any[] = [];
    api.forEachNode(node => selectedRowKeys.includes(+node.key!) && nodes.push(node));
    api.setNodesSelected({ nodes, newValue: true });
  }
};

const defaultFast72ColumnState = {
  aggFunc: null,
  colId: 'fastStat72',
  flex: null,
  hide: false,
  pinned: null,
  pivot: false,
  pivotIndex: null,
  rowGroup: false,
  rowGroupIndex: null,
  sort: null,
  sortIndex: null,
  width: 370,
};

const defaultAccountColumnState = {
  aggFunc: null,
  colId: 'accountName',
  flex: null,
  hide: false,
  pinned: null,
  pivot: false,
  pivotIndex: null,
  rowGroup: false,
  rowGroupIndex: null,
  sort: null,
  sortIndex: null,
  width: 300,
};

const defaultAdPlanColumnState = {
  aggFunc: null,
  colId: 'adPlanName',
  flex: null,
  hide: false,
  pinned: null,
  pivot: false,
  pivotIndex: null,
  rowGroup: false,
  rowGroupIndex: null,
  sort: null,
  sortIndex: null,
  width: 300,
};

export const getNewState = (
  tabName: ManageVkTabNameEnum | IntegrationsTabNameEnum | ManageYDTabNameEnum,
  prevColumnState: ColumnState[],
  newColumnState: ColumnState[],
) => {
  const newColumnStateIds = new Set(newColumnState.map(col => col.colId));
  const prevColumnStateIds = new Set(prevColumnState.map(col => col.colId));
  const newState: ColumnState[] = [];

  let needNewState: boolean = false;
  prevColumnState.forEach((col, index) => {
    !newColumnState[index] && (needNewState = true);
    newColumnState[index] && col.colId !== newColumnState[index].colId && (needNewState = true);
  });

  if (!needNewState && prevColumnState.length === newColumnState.length) {
    return prevColumnState;
  }

  prevColumnState.forEach((el, index, array) => {
    newState.push(el);
    if (index === 1) {
      if (tabName === ManageVkTabNameEnum.ADPLANS) {
        newColumnStateIds.has('accountName') &&
          !prevColumnStateIds.has('accountName') &&
          newState.push(defaultAccountColumnState);
      }
      if (tabName === ManageVkTabNameEnum.ADGROUPS) {
        newColumnStateIds.has('accountName') &&
          !prevColumnStateIds.has('accountName') &&
          newState.push(defaultAccountColumnState);

        newColumnStateIds.has('adPlanName') &&
          !prevColumnStateIds.has('adPlanName') &&
          newState.push(defaultAdPlanColumnState);
      }
      if (tabName === ManageVkTabNameEnum.ADS) {
        newColumnStateIds.has('accountName') &&
          !prevColumnStateIds.has('accountName') &&
          newState.push(defaultAccountColumnState);

        newColumnStateIds.has('adPlanName') &&
          !prevColumnStateIds.has('adPlanName') &&
          newState.push(defaultAdPlanColumnState);
      }
    }

    if (el.colId === 'fastStat' && newColumnStateIds.has('fastStat72') && !prevColumnStateIds.has('fastStat72')) {
      newState.push(defaultFast72ColumnState);
    }
  });
  return newState;
};

export const isDisabledStatus = (status: string) => {
  return status.includes('Ожидает подтверждения') || status.includes('Заблокированный');
};

export const getFooterText = (tabName: ManageVkTabNameEnum | ManageYDTabNameEnum, value: number) => {
  const lastSymbol = +value.toString().split('').reverse()[0];
  const isElevenOfLast = +value.toString().slice(value.toString().length - 2, value.toString().length) === 11;
  const intValue = int(value);

  if (tabName === ManageVkTabNameEnum.ACCOUNTS) {
    if (isElevenOfLast) return `${intValue} Рекламных кабинетов`;
    if (lastSymbol === 1) return `${intValue} Рекламный кабинет`;
    if (lastSymbol === 2 || value === 3 || value === 4) return `${intValue} Рекламных кабинета`;
    return `${intValue} Рекламных кабинетов`;
  }
  if (tabName === ManageYDTabNameEnum.ACCOUNTS) {
    if (isElevenOfLast) return `${intValue} Клиентов`;
    if (lastSymbol === 1) return `${intValue} Клиент`;
    if (lastSymbol === 2 || value === 3 || value === 4) return `${intValue} Клиента`;
    return `${intValue} Клиентов`;
  }

  if (tabName === ManageVkTabNameEnum.ADPLANS || tabName === ManageYDTabNameEnum.CAMPAIGNS) {
    if (isElevenOfLast) return `${intValue} Кампаний`;
    if (lastSymbol === 1) return `${intValue} Кампания`;
    if (lastSymbol === 2 || value === 3 || value === 4) return `${intValue} Кампании`;
    return `${intValue} Кампаний`;
  }
  if (tabName === ManageVkTabNameEnum.ADGROUPS || tabName === ManageYDTabNameEnum.GROUPS) {
    if (isElevenOfLast) return `${intValue} Групп объявлений`;
    if (lastSymbol === 1) return `${intValue} Группа объявлений`;
    if (lastSymbol === 2 || value === 3 || value === 4) return `${intValue} Группы объявлений`;
    return `${intValue} Групп объявлений`;
  }
  if (tabName === ManageVkTabNameEnum.ADS || tabName === ManageYDTabNameEnum.ADS) {
    if (isElevenOfLast) return `${intValue} Объявлений`;
    if (lastSymbol === 1) return `${intValue} Объявление`;
    if (lastSymbol === 2 || value === 3 || value === 4) return `${intValue} Объявления`;
    return `${intValue} Объявлений`;
  }
};

export const saveColumnState = (api: ColumnApi) => {
  const href = window.location.href;
  const columns = localStorage.getItem('columns');
  columns
    ? localStorage.setItem('columns', JSON.stringify({ ...JSON.parse(columns), [href]: api.getColumnState() }))
    : localStorage.setItem('columns', JSON.stringify({ [href]: api.getColumnState() }));
};

export const applyColumnState = (
  api: ColumnApi,
  tabName?: ManageVkTabNameEnum | IntegrationsTabNameEnum | ManageYDTabNameEnum,
) => {
  const href = window.location.href;
  const columnState = localStorage.getItem('columns');
  columnState &&
    JSON.parse(columnState).hasOwnProperty(href) &&
    api.applyColumnState({
      state: tabName
        ? getNewState(tabName, JSON.parse(columnState)[href], api.getColumnState())
        : JSON.parse(columnState)[href],
      applyOrder: true,
    });
};

// Лечим ситуацию, когда при уменьшении окна по вертикали таблица не переключается с режима автовысоты на стандартный режим. Лечение необходимо только при получении пропса needAutoHeight плюс при уменьшении высоты окна до размера, в который перестают вмещаться все строки таблицы.
export const useAutoHeightFix = (
  needAutoHeight: boolean,
  gridRef: React.RefObject<AgGridReact<any>>,
  needHeight: number,
) => {
  useEffect(() => {
    if (needAutoHeight) {
      const handleResize = () => {
        const pageDomElement = document.querySelector('.autoHeightAnchor');
        const height = pageDomElement!.getBoundingClientRect().height - 240;

        if (needHeight > height) {
          gridRef && gridRef.current && gridRef.current?.api.setDomLayout('normal');
        }
      };

      let prevHeight = window.innerHeight;

      const fixTableHeight = debounce(() => {
        const currentHeight = window.innerHeight;

        if (currentHeight < prevHeight) {
          handleResize();
        }
        prevHeight = currentHeight;
      }, 500);
      window.addEventListener('resize', fixTableHeight);

      return () => {
        window.removeEventListener('resize', fixTableHeight);
      };
    }
  }, [needHeight]);
};

export const isNotResizable = (columns: Column[]) => {
  return columns.every(col => col.resizable === false || col.hide === true);
};

export const toggleClassFillHeightScroll = (needHeight: number, className: string, needAutoHeight?: boolean) => {
  const pageDomElement = document.querySelector('.autoHeightAnchor');
  const height = (pageDomElement?.getBoundingClientRect().height || 1080) - 298;

  if (!needAutoHeight && pageDomElement) {
    if (needHeight >= height) {
      document.querySelector('.ag-center-cols-clipper')?.classList.remove(className);
    }

    if (needHeight < height) {
      document.querySelector('.ag-center-cols-clipper')?.classList.add(className);
    }
  }
};
