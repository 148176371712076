import { IconButton } from '@mui/material';
import { LeftDoubleIcon, MenuIcon, RightDoubleIcon } from '@plarin/design';
import React, { VFC } from 'react';
import { IActionMenuButtonProps } from './button-types';
import classes from './icon-button.module.scss';

export const ActionMenuButton: VFC<IActionMenuButtonProps> = ({
  isOpen,
  onClick,
  onMouseEnter,
  onMouseLeave,
  hover,
  disabled,
}) => {
  return (
    <IconButton
      classes={{
        root: classes.iconButtonRoot,
      }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
    >
      {isOpen ? (
        <LeftDoubleIcon color="primary60" />
      ) : (
        <div className={classes.openMenuButton}>
          {!hover ? <MenuIcon color="secondary" /> : <RightDoubleIcon color="primary60" />}
        </div>
      )}
    </IconButton>
  );
};
