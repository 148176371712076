import { TextField } from '@mui/material';
import { CloseCircleIcon } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import { CharactersCounter } from '../counters/characters-counter';
import classes from './text-area.module.scss';

export enum TextAreaErrors {
  noText = 'Введите описание вашей идеи',
  maxLength = 'Вы ввели максимально возможное количество символов',
  minLength = 'Поле содержит менее 3 символов',
}

interface HelperTextProps {
  hint?: string;
  maxCounterValue?: number;
  value: string;
  size?: number;
}

interface TextAreaProps extends HelperTextProps {
  setValue: (value: string) => void;
  placeholder?: string;
  deleteIcon?: boolean;
  onFocusAction?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlurAction?: () => void;
  errorText?: string;
  maxLengthValue?: number;
  maxRows: number;
  className?: string;
  classNameRoot?: string;
  isTrimMaxLength?: boolean;
  disabled?: boolean;
}

const HelperText = ({ hint, maxCounterValue, value, size }: HelperTextProps) => {
  return (
    <>
      {hint && <>{hint}</>}
      <div></div>
      {maxCounterValue && (
        <CharactersCounter
          inputValueLength={value?.length || 0}
          maxCounterValue={maxCounterValue}
          fontSize={size || 0}
          errorText={hint || ''}
        />
      )}
    </>
  );
};

export const TextArea = ({
  value,
  setValue,
  placeholder,
  onFocusAction,
  deleteIcon,
  onBlurAction,
  maxLengthValue,
  maxRows,
  errorText = '',
  className,
  classNameRoot,
  isTrimMaxLength,
  disabled,
  maxCounterValue,
  hint,
  size,
  ...props
}: TextAreaProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (isTrimMaxLength && maxLengthValue) {
      setValue(event.target.value.substring(0, maxLengthValue));
    } else {
      maxLengthValue ? setValue(event.target.value.substring(0, maxLengthValue)) : setValue(event.target.value);
    }
  };

  return (
    <TextField
      className={className}
      {...props}
      fullWidth
      variant="outlined"
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      classes={{ root: clsx(classes.textField) }}
      FormHelperTextProps={{
        classes: {
          root: clsx(classes.helperText),
        },
      }}
      InputProps={{
        classes: {
          root: clsx(classes.root, classNameRoot && classNameRoot),
          input: clsx(classes.input),
          focused: classes.inputFocused,
          notchedOutline: 'visuallyHidden',
          error: classes.inputError,
        },
        endAdornment:
          deleteIcon && value.length ? (
            <CloseCircleIcon size={24} onClick={() => !disabled && setValue('')} className={classes.deleteIcon} />
          ) : null,
        onFocus: onFocusAction,
        onBlur: onBlurAction,
      }}
      InputLabelProps={{ disabled: true }}
      error={errorText ? true : false}
      helperText={<HelperText hint={errorText} maxCounterValue={maxCounterValue} value={value} size={size} />}
      multiline
      maxRows={maxRows}
      disabled={disabled}
    />
  );
};
