import { Typography } from '@plarin/design';
import { DefaultInput } from '@plarin/inputs';
import clsx from 'clsx';
import React, { useState } from 'react';
import classes from '../reports-drawer-content.module.scss';

export const ReportName = ({
  name,
  setName,
  defaultName,
}: {
  name: string;
  setName: (name: string) => void;
  defaultName: string;
}) => {
  return (
    <div className={clsx(classes.nameReport, classes.blockWithLabel)}>
      <Typography weight={600} size="Main">
        Название отчета
      </Typography>
      <DefaultInput
        value={name}
        setValue={setName}
        placeholder={defaultName}
        size={48}
        maxCounterValue={42}
        maxLengthValue={42}
        clearInputAction={() => setName('')}
        onBlurAction={() => {
          setName(name.trim());
        }}
      />
    </div>
  );
};
