import { DefaultInput } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React from 'react';
import { TGuests } from '../../../../../../../../types/projects/projects';
import { filtersGuests } from '../../../../../../../utils/searchList';
import { useAppDataStore } from '../../../../../../app-data-provider';
import classes from '../../styles.module.scss';
import { GuestsSearchResult } from './guests-search-result';

type TProjectPopupGuests = {
  selectedGuests: TGuests[];
  setSelectedGuests: (e: TGuests[]) => void;
};

export const ProjectPopupGuests = observer(({ selectedGuests, setSelectedGuests }: TProjectPopupGuests) => {
  const {
    projects: { guestsState, setGuestsState },
  } = useAppDataStore();

  const changeGuestsSelection = (guests: TGuests) => {
    if (!!selectedGuests.find(item => item.id === guests.id)) {
      setSelectedGuests(selectedGuests.filter(item => item.id !== guests.id));
    } else {
      setSelectedGuests([...selectedGuests, guests]);
    }
  };

  return (
    <div className={classes.cabinetsList}>
      <div className={classes.search}>
        <DefaultInput
          value={guestsState.searchValueGuests}
          setValue={el => {
            setGuestsState('searchValueGuests', el);
          }}
          placeholder="Поиск гостя"
          searchIcon
          clearInputAction={() => setGuestsState('searchValueGuests', '')}
        />
      </div>
      <GuestsSearchResult
        guests={guestsState.guests}
        filteredGuests={guestsState.filteredGuests}
        selectedGuests={selectedGuests}
        changeGuestsSelection={changeGuestsSelection}
        filtersGuests={filtersGuests}
        searchValue={guestsState.searchValueGuests}
      />
    </div>
  );
});
