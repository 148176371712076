import { EAppErrorCodes } from '@plarin/core';
import { Alert, TAlertProps } from '@plarin/inputs';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthFormContainerTemplate } from './auth-form-container-template';
import './auth-form-container-template.scss';
import { routerPaths } from '../../../routing/router-path';
import { Typography } from '@plarin/design';

export const AuthFormContainerRegSocialTemplate: React.FC<{
  alertProps?: TAlertProps;
  errorCode?: string;
  setCanEditEmail: React.Dispatch<React.SetStateAction<boolean>>;
  emailSetError: (arg: boolean) => void;
}> = ({ children, alertProps, errorCode, setCanEditEmail, emailSetError }) => {
  const commonAlert = alertProps?.title && (
    <div role="alert" className="auth-form-template__error">
      <Alert title={alertProps.title} content={alertProps.content} />
    </div>
  );

  const usedEmailAlert = (
    <div role="alert" className="auth-form-template__error">
      <Alert
        title="Что–то пошло не так."
        content={
          <>
            Указанный адрес электронной почты уже зарегистрирован,{' '}
            <Typography
              underline
              color="linkPrimary"
              component={Link}
              componentProps={{ to: routerPaths.unAuth.LOGIN }}
            >
              войдите в учетную запись
            </Typography>{' '}
            и добавьте социальную сеть, или введите другой адрес для регистрации новой учетной записи
          </>
        }
      />
    </div>
  );

  useEffect(() => {
    if (errorCode === EAppErrorCodes.USER_WITH_THIS_EMAIL_ALREADY_EXISTS) {
      setCanEditEmail(true);
      emailSetError(true);
    }
  }, [errorCode]);

  return AuthFormContainerTemplate({
    title: 'Регистрация',
    children: (
      <>
        {errorCode === EAppErrorCodes.USER_WITH_THIS_EMAIL_ALREADY_EXISTS ? usedEmailAlert : commonAlert}
        {children}
      </>
    ),
  });
};
