import { IRowNode } from 'ag-grid-enterprise';

export const comparator = (
  a: string | number,
  b: string | number,
  nodeA: IRowNode,
  nodeB: IRowNode,
  isInverted: boolean,
): number => {
  if (!a || a === '0' || a === '--') {
    return isInverted ? -1 : 1;
  } else if (!b || b === '0' || b === '--') {
    return isInverted ? 1 : -1;
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }
  a = (a + '').toLowerCase();
  b = (b + '').toLowerCase();
  if (a === b) {
    return 0;
  }
  return a > b ? 1 : -1;
};
