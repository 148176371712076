import { Typography } from '@plarin/design';
import { Switch } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React from 'react';
import { useAppDataStore } from '../../../../components/app-data-provider';
import classes from './teams-drawer.module.scss';

export const TeamsDrawerSettings = observer(() => {
  const {
    teams: { dataDrawer, updateDataDrawer },
  } = useAppDataStore();

  return (
    <div className={classes.settingsContent}>
      <div className={classes.settingsTitle}>
        <Typography size="Main" weight={600}>
          Настройки
        </Typography>
      </div>
      <div className={classes.settingItem}>
        <div className={classes.settingItemContent} onClick={() => updateDataDrawer('hidden', !dataDrawer?.hidden)}>
          <Typography size="Main" color="TextPrimary">
            Сделать команду невидимой
          </Typography>
          <Typography size="Caption" color="TextSecondary">
            Команда не видна никому, кроме участников группы
          </Typography>
        </div>
        <Switch checked={dataDrawer?.hidden} onChange={() => updateDataDrawer('hidden', !dataDrawer?.hidden)} />
      </div>
      <div className={classes.settingItem}>
        <div className={classes.settingItemContent} onClick={() => updateDataDrawer('closed', !dataDrawer?.closed)}>
          <Typography size="Main" color="TextPrimary">
            Сделать команду закрытой
          </Typography>
          <Typography size="Caption" color="TextSecondary">
            Вступление возможно только по приглашению
          </Typography>
        </div>
        <Switch checked={dataDrawer?.closed} onChange={() => updateDataDrawer('closed', !dataDrawer?.closed)} />
      </div>
    </div>
  );
});
