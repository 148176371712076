import { DeleteIcon, PauseIcon, PlayIcon } from '@plarin/design';
import { SelectButton } from '@plarin/inputs';
import React, { useMemo } from 'react';
import { TGAStoreData } from '../../../../types/connect-ga/types';
import { TYDStoreData } from '../../../../types/connect-yd/types';
import { ModalData, TYMStoreData } from '../../../../types/connect-ym/types';

interface ActionBarProps {
  selectedRows: (TYMStoreData | TGAStoreData | TYDStoreData)[];
  setModalData: (data: ModalData) => ModalData;
  enableAction: (data: { db_ids: string[] }) => Promise<void>;
  tableLoading: boolean;
}

export const SelectDropMenu = ({ selectedRows, setModalData, enableAction, tableLoading }: ActionBarProps) => {
  enum ActionKeysEnum {
    enabled = '1',
    disabled = '2',
    deleted = '3',
  }

  const parentsRows = useMemo(() => selectedRows && selectedRows.filter(item => item.isParent), [selectedRows]);
  const rowsForEnable = useMemo(
    () => parentsRows && parentsRows.filter(item => item.plarin_status !== 'enabled'),
    [selectedRows],
  );

  const rowsForDisable = useMemo(
    () => parentsRows && parentsRows.filter(item => item.plarin_status === 'enabled'),
    [selectedRows],
  );

  const renderList = [
    {
      key: ActionKeysEnum.enabled,
      label: rowsForEnable?.length === 1 ? 'Включить интеграцию' : `Включить интеграции (${rowsForEnable?.length})`,
      iconLeft: () => <PlayIcon />,
      rowsCount: rowsForEnable?.length,
      action: () => {
        enableAction({ db_ids: rowsForEnable?.map(el => el._id) });
      },
    },
    {
      key: ActionKeysEnum.disabled,
      label: rowsForDisable?.length === 1 ? 'Выключить интеграцию' : `Выключить интеграции (${rowsForDisable?.length})`,
      iconLeft: () => <PauseIcon />,
      rowsCount: rowsForDisable?.length,
      action: () => {
        setModalData({
          type: 'pause',
          name: rowsForDisable?.map(el => el.header1),
          ids: rowsForDisable?.map(el => el._id),
        });
      },
    },
    {
      key: ActionKeysEnum.deleted,
      rowsCount: parentsRows?.length,
      label:
        parentsRows?.length === 1
          ? 'Отключить аккаунт и удалить все данные'
          : `Отключить аккаунты и удалить все данные (${parentsRows?.length})`,
      iconLeft: () => <DeleteIcon />,
      action: () => {
        setModalData({
          type: 'remove',
          name: parentsRows?.map(el => el.header1),
          ids: parentsRows?.map(el => el._id),
        });
      },
    },
  ].filter(
    item =>
      (item.key === ActionKeysEnum.deleted && item.rowsCount) ||
      (item.key !== ActionKeysEnum.deleted && item.rowsCount),
  );

  return (
    <div>
      <SelectButton renderList={renderList} disabled={tableLoading || (selectedRows && !selectedRows.length)} />
    </div>
  );
};
