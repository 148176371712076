import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { TTabsData } from '../../../types/common-types';
import { useAppDataStore } from '../../components/app-data-provider';
import DrawerContent from '../../components/drawer/drawer';
import { LayoutTable } from '../../components/layout-table';
import { NetworkEnum } from '../../dictionary/integrations';
import { routerPaths } from '../../routing/router-path';
import { ProfileStore } from '../../stores/profile.store';
import { TabContentAccounts } from './tab-content-with-accounts';
import { TabContentCabinets } from './tab-content-with-cabinets';

const IntegrationsVK = () => {
  const {
    integrationsVK: {
      fetchClients,
      accounts,
      checkQueryString,
      getAccountDataByIdMT,
      isChange,
      network,
      accountId,
      filterValue,
      setFilterValue,
    },
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/naming-convention
    profileStore: { data: { user_id } = {} as ProfileStore, workspaceShortname },
  } = useAppDataStore();

  useEffect(() => {
    if (!accounts?.length || isChange) {
      fetchClients().then();
    }

    checkQueryString(user_id);
  }, [window.location.pathname, fetchClients, checkQueryString, user_id]);

  useEffect(() => {
    network === NetworkEnum.myTarget && accountId && getAccountDataByIdMT();
    // network === NetworkEnum.VKontakte && accountId && getAccountDataByIdVK();
    network === NetworkEnum.VkAds && accountId && getAccountDataByIdMT();
  }, [network, getAccountDataByIdMT, accountId]); // getAccountDataByIdVK

  const tabsData: TTabsData = {
    settings: false,
    tabs: [
      {
        label: 'Аккаунты',
        key: 'accounts',
        path: `/${workspaceShortname}${routerPaths.integrations.ACCOUNT}`,
        content: <TabContentAccounts />,
      },
      {
        label: 'Рекламные кабинеты',
        key: 'adverts',
        path: `/${workspaceShortname}${routerPaths.integrations.ADVERTS}`,
        content: <TabContentCabinets />,
      },
    ],
  };
  return (
    <>
      <LayoutTable tabsData={tabsData} filterValue={filterValue} setFilterValue={setFilterValue}>
        <>
          <DrawerContent />
        </>
      </LayoutTable>
    </>
  );
};

export default observer(IntegrationsVK);
