import { Typography } from '@plarin/design';
import { Button } from '@plarin/inputs';
import clsx from 'clsx';
import React from 'react';
import classes from './styles.module.scss';

type TProps = {
  openDrawer: (e: boolean) => void;
  margins?: string; // передаём сюда стили, задающие верхний и нижний отступы для блока .flex
};

export const CreateFirstWs = ({ openDrawer, margins }: TProps) => {
  return (
    <div className={clsx(classes.flex, margins)}>
      <div className={classes.createBlock}>
        <div className={classes.content}>
          <Typography size="WsTitle" weight={600}>
            Создайте свое первое пространство
          </Typography>
          <div className={classes.actionBlock}>
            <Button
              // className={classes.none}
              size="medium"
              color="primary"
              variant="filled"
              onClick={() => openDrawer(true)}
            >
              Создать пространство
            </Button>
          </div>
        </div>
      </div>

      {/* <div className={classes.slider}></div> */}
    </div>
  );
};
