import React, { useEffect } from 'react';

interface ReCAPTCHA {
  execute(opt_widget_id?: string): void;

  render(container: HTMLDivElement | string, parameters: { [key: string]: string }): void;

  reset(opt_widget_id?: string): void;

  getResponse(opt_widget_id?: string): string;
}

type TWindowReCAPTCHA = Window &
  typeof globalThis & {
    grecaptcha?: ReCAPTCHA;
    reCaptchaComponentCallbacks?: (token: string) => void;
    reCaptchaComponentCallbacksError?: VoidFunction;
    reCaptchaComponentCallbacksExpired?: VoidFunction;
  };

export type TReCaptchaV2Props = {
  siteKey: string;
  onResponse?: (token: string) => void;
  onError?: VoidFunction;
  onExpired?: VoidFunction;
};

export const ReCaptchaV2: React.VFC<TReCaptchaV2Props> = function Alert({ siteKey, onError, onExpired, onResponse }) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    document.body.appendChild(script);
    (window as TWindowReCAPTCHA).reCaptchaComponentCallbacks = (token: string) => onResponse && onResponse(token);
    (window as TWindowReCAPTCHA).reCaptchaComponentCallbacksError = () => onError && onError();
    (window as TWindowReCAPTCHA).reCaptchaComponentCallbacksExpired = () => onExpired && onExpired();
    return () => {
      delete (window as TWindowReCAPTCHA).reCaptchaComponentCallbacks;
      delete (window as TWindowReCAPTCHA).reCaptchaComponentCallbacksError;
      delete (window as TWindowReCAPTCHA).reCaptchaComponentCallbacksExpired;
      delete (window as TWindowReCAPTCHA).grecaptcha;
    };
  }, [onResponse, onError, onExpired]);

  return (
    <div
      className="g-recaptcha"
      data-sitekey={siteKey}
      data-callback="reCaptchaComponentCallbacks"
      data-error-callback="reCaptchaComponentCallbacksError"
      data-expired-callback="reCaptchaComponentCallbacksExpired"
    />
  );
};
