import { InputAdornment, TextField } from '@mui/material';
import { CalendarIcon, SortDownIcon } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import { MENU_ITEM_LABEL } from '../constants';
import { RenderComponentProps } from '../types';
import classes from './style.module.scss';

export const DefaultRenderComponent = ({
  className,
  hasError,
  open,
  sizeS,
  leave,
  label,
  toggle,
  refAnchor,
  reportInput,
  labelConstants,
  calendarIdx,
  disabled,
  ...other
}: RenderComponentProps) => {
  return (
    <TextField
      {...other}
      disabled={disabled}
      label={label}
      onFocus={leave}
      ref={refAnchor}
      classes={{ root: clsx(classes.root, className, reportInput && classes.reportRoot, sizeS && classes.rootSizeS) }}
      InputLabelProps={{
        classes: {
          root: clsx(
            classes.label,
            open && classes.rootOpened,
            label === MENU_ITEM_LABEL.All_TIME && classes.disableLabel,
          ),
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment
            onClick={disabled ? undefined : toggle}
            position="start"
            className={clsx(classes.adornedStart, disabled && classes.cursorDisabled)}
          >
            <CalendarIcon color={disabled ? 'disabled' : open ? 'primary60' : 'main'} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            onClick={disabled ? undefined : toggle}
            position="end"
            className={clsx(classes.adornedEnd, disabled && classes.cursorDisabled)}
          >
            <SortDownIcon
              color={disabled ? 'disabled' : 'main'}
              iconType="solid"
              className={clsx('rotatable', open && 'deg180')}
            />
          </InputAdornment>
        ),
        classes: {
          root: clsx(
            classes.inputRoot,
            reportInput && classes.inputRootReports,
            open && classes.rootOpened,
            label === MENU_ITEM_LABEL.All_TIME && classes.inputRootWithoutLabel,
            sizeS && classes.inputRootSizeS,
            hasError && classes.hasError,
            disabled && classes.inputRootDisabled,
          ),
          input: clsx(classes.input, disabled && classes.cursorDisabled),
          focused: classes.focused,
          notchedOutline: reportInput ? classes.notchedOutlineReports : classes.notchedOutline,
        },
      }}
    ></TextField>
  );
};
