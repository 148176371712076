import { AppError, BasicStore, EAppErrorCodes, getApiUrl, httpClient } from '@plarin/core';
import { TabItem, TAlertProps } from '@plarin/inputs';
import { AxiosError } from 'axios';
import { action, makeObservable, observable, reaction } from 'mobx';
import { paths } from '../../../types/profile/apispec';
import { routerPaths } from '../../routing/router-path';
import { ProfileStore } from '../../stores/profile.store';
import { SocialNameType } from '../../utils/constants';
import { getAlertProps } from '../../utils/get-alert-props';
import { LoginEmailFormStore } from './login-email-form.store';
import { LoginPhoneFormStore } from './login-phone-form.store';
import { mapSocialNamesToSocialNetworkEnum, TOauthRegistrationUrlReq, TOauthRegistrationUrlResp } from './types';

export class LoginPageStore extends BasicStore {
  alertProps?: TAlertProps;
  emailFormStore: LoginEmailFormStore;
  phoneFormStore: LoginPhoneFormStore;
  profileStore: ProfileStore;

  tabs: TabItem[] = [
    { value: 'email', label: 'Почта' },
    { value: 'phone', label: 'Телефон', disabled: true, tooltipMessage: 'Вход по номеру телефона временно недоступен' },
  ];

  activeTab: string;

  constructor(profileStore: ProfileStore) {
    super();
    this.activeTab = this.tabs[0].value;
    this.profileStore = profileStore;
    this.emailFormStore = new LoginEmailFormStore(profileStore);
    this.phoneFormStore = new LoginPhoneFormStore(profileStore);
    makeObservable<this>(this, {
      alertProps: observable,
      emailFormStore: observable,
      activeTab: observable,
      selectTab: action.bound,
      initAlertUrlError: action.bound,
    });
    this.initAlertUrlError();

    reaction(
      () => this.emailFormStore.isPending || this.phoneFormStore.isPending,
      () => {
        this.emailFormStore.isPending || this.phoneFormStore.isPending
          ? this.tabs.map(item => (item.disabled = true))
          : this.tabs.map(item => (item.disabled = false));
      },
    );
  }

  initAlertUrlError() {
    const error = new URLSearchParams(window.location.search).get('error');
    if (error !== null) {
      this.alertProps = getAlertProps(
        new AppError(
          Object.keys(EAppErrorCodes).includes(error) ? (error as EAppErrorCodes) : EAppErrorCodes.UNKNOWN_ERROR,
        ),
      );
    }
  }

  selectTab(activeTab: string) {
    this.activeTab = activeTab;
    this.alertProps = undefined;
    this.emailFormStore.alertProps = undefined;
    this.phoneFormStore.alertProps = undefined;
  }

  getSocialUrl = (social: SocialNameType) => {
    const params: TOauthRegistrationUrlReq = {
      url: `${window.location.origin}${routerPaths.unAuth.LOGIN}`,
      network: mapSocialNamesToSocialNetworkEnum(social),
    };
    this.execRequest<[TOauthRegistrationUrlResp]>([
      httpClient.get(getApiUrl<paths>('/api/v1/user/oauth/registration_url'), {
        params,
      }),
    ])
      .then(this.getSocialUrlSuccess)
      .catch(this.getSocialUrlError);
  };

  getSocialUrlSuccess = ([resp]: [TOauthRegistrationUrlResp]) => {
    const url = resp.data?.url;
    if (url) {
      window.location.assign(url);
    } else {
      this.alertProps = {
        title: 'Не удалось получить адрес',
      };
    }
  };

  getSocialUrlError = (err: AxiosError<any>) => {
    this.alertProps = getAlertProps(err);
  };
}
