import { Alert, AlertProps } from '@mui/material';
import { CheckIcon, CloseCircleIcon, CloseIcon } from '@plarin/design';
import React from 'react';

import './alert-integrations.scss';

interface IAlertProps extends AlertProps {
  closeAlert(): void;
}

export const AlertIntegrations: React.FC<IAlertProps> = ({ children, closeAlert, ...props }) => {
  return (
    <Alert
      {...props}
      variant="outlined"
      action={
        <div className="alert-close" onClick={closeAlert}>
          <CloseIcon size={20} />
        </div>
      }
      iconMapping={{
        success: <CheckIcon iconType="solid" color="status_success" />,
        error: <CloseCircleIcon iconType="solid" color="status_failed" />,
      }}
      classes={{
        root: 'AlertRoot',
        outlinedSuccess: 'AlertSuccess',
        outlinedError: 'AlertErrorAny',
        action: 'AlertAction',
        icon: 'AlertIcon',
        message: 'AlertMessage',
      }}
    >
      {children}
    </Alert>
  );
};
