import Joi from '@hapi/joi';
import {
  BasicFormStore,
  emailAllowedSymbols,
  emailValidateRule,
  getApiUrl,
  httpClient,
  TFormInitParams,
} from '@plarin/core';
import { TAlertProps } from '@plarin/inputs';
import { AxiosError } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { paths } from '../../../types/profile/apispec';
import { history } from '../../components/app';
import { routerPaths } from '../../routing/router-path';
import { getAlertProps } from '../../utils/get-alert-props';
import { TForgotPasswordReq, TForgotPasswordResp } from './types';

export type TForgotPasswordEmailData = {
  email: string;
};

const initData: TFormInitParams<TForgotPasswordEmailData> = {
  email: {
    value: '',
    validators: {
      change: emailAllowedSymbols.allow(''),
      blur: emailValidateRule.allow(''),
      submit: Joi.string().required(),
    },
  },
};

export class ForgotPasswordEmailFormStore extends BasicFormStore<TForgotPasswordEmailData> {
  alertProps?: TAlertProps;

  constructor() {
    super(initData);
    makeObservable<this>(this, {
      alertProps: observable,
      submit: action.bound,
      forgot: action.bound,
      forgotSuccess: action.bound,
      forgotError: action.bound,
    });
  }

  submit = this.onSubmit(() => {
    this.errors = this.validateAll();
    if (this.isValid) {
      this.forgot(this.data);
    }
  });

  forgot = async (data: TForgotPasswordReq) => {
    this.execRequest<[TForgotPasswordResp]>([httpClient.post(getApiUrl<paths>('/api/v1/user/password/forgot'), data)])
      .then(this.forgotSuccess)
      .catch(this.forgotError);
  };

  forgotSuccess = () => {
    history.push(routerPaths.unAuth.REGISTRATION_CHECK_MAIL);
  };

  forgotError = (err: AxiosError<any>) => {
    this.alertProps = getAlertProps(err);
  };
}
