import { GridIcon, RedoIcon } from '@plarin/design';
import { ActionTableButton, manageYDTabs } from '@plarin/inputs';
import { ManageYDTabNameEnum } from '@plarin/utils';
import { observer } from 'mobx-react';
import React from 'react';
import { ActionContentPropsYd } from '../../../../../types/manage-yd/types';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { ManageYdEditMenu } from '../../../../components/manage-yd-edit-menu/manage-yd-edit-menu';
import { TableGroupingYD } from '../../../../components/table-grouping';
import { ReportWidget } from '../components';
import classes from '../styles.module.scss';

export const AdsActionBar = observer(({ gridApi }: ActionContentPropsYd) => {
  const {
    manageYD: { tableLoading, toggleDrawer, metricsYD, getYDStat, selectedAdIds },
    profileStore: { wsDictionary },
  } = useAppDataStore();

  return (
    <div className={classes.actionContent}>
      <div className={classes.leftContainer}>
        <ManageYdEditMenu
          disabled={tableLoading || !selectedAdIds.length}
          gridApi={gridApi}
          tabName={ManageYDTabNameEnum.ADS}
        />
      </div>
      <div className={classes.rightContainer}>
        {!!metricsYD?.length && <TableGroupingYD tab={manageYDTabs.ADS} />}
        <ActionTableButton
          textTooltip={metricsYD?.length ? 'Настроить колонки' : 'Произошла ошибка'}
          action={metricsYD?.length ? toggleDrawer : () => {}}
          icon={<GridIcon />}
        />

        <ReportWidget tab={manageYDTabs.ADS} />

        <ActionTableButton
          textTooltip="Обновить"
          action={() => getYDStat(manageYDTabs.ADS)}
          loading={tableLoading}
          icon={<RedoIcon />}
          isReloadButton={true}
        />
      </div>
    </div>
  );
});
