import { ArrowUpIcon, Typography } from '@plarin/design';
import { Button, Popover } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TShortCabinetsFilters } from '../../../../../../../types/projects/projects';
import { TShortClientsData } from '../../../../../../../types/reports/reports';
import { routerPaths, INTEGRATIONS_VK } from '../../../../../../routing/router-path';
import { menuSectionIndexes } from '../../../../../../utils/constants';
import { filterClients, getSearchResults } from '../../../../../../utils/searchList';
import { useAppDataStore } from '../../../../../app-data-provider';
import classes from '../project-info.module.scss';
import { CustomButtonText } from '../uicomponents/custom-button-text';
import { PopupCabinets } from './components/project-info-list';
import { ProjectCabinetItem } from './project-cabinet-item';

export const filtersShortCabinets = (searchVal: string, allCabinets: TShortClientsData): TShortCabinetsFilters[] => {
  return filterClients(searchVal, allCabinets).map(cabinet => ({
    client_id: cabinet.account_id,
    username: cabinet.account_username,
    client_name: cabinet.account_name,
    network: cabinet.network,
    has_error: false,
  }));
};

export const ProjectInfoCabinets = observer(() => {
  const vkCabinet = 'Рекламные кабинеты VK Реклама';
  const [isOpen, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const history = useHistory();

  const {
    projects: {
      statusWSroles,
      shortCabinets,
      projectsState,
      editProject,
      setProjectsState,
      setIsOpenDrawer,
      setIsDrawerDataChanged,
      setInitialProjectsState,
    },
    profileStore: { workspaceShortname },
    menu: { selectMenuItem },
  } = useAppDataStore();
  const { isAdmin, isManager, isLeader } = statusWSroles;

  const redirectToCabinet = () => {
    setIsOpenDrawer(false);
    history.push(`/${workspaceShortname}${routerPaths.integrations.ACCOUNT}`);
    selectMenuItem(INTEGRATIONS_VK, false, menuSectionIndexes.integrations);
  };

  const isHidden = isLeader || isManager || isAdmin;

  const handleClosePopover = () => {
    setOpen(false);
    setProjectsState('searchValueCabinet', '');
  };

  useEffect(() => {
    setInitialProjectsState();
  }, []);

  useEffect(() => {
    editProject.editProjectData &&
      setProjectsState(
        'selectedCabinets',
        shortCabinets.clients
          .filter(el => {
            return editProject.editProjectData?.tm_client_ids?.includes(el.account_id);
          })
          .map(cabinet => {
            return {
              client_id: cabinet.account_id,
              username: cabinet.account_username,
              client_name: cabinet.account_name,
              network: cabinet.network,
              has_error: false,
            };
          }),
      );
  }, [editProject, setProjectsState]);

  useEffect(() => {
    shortCabinets.clients &&
      getSearchResults(
        projectsState.searchValueCabinet,
        filtersShortCabinets,
        (el: TShortCabinetsFilters[]) => {
          setProjectsState('filteredCabinets', el);
        },
        shortCabinets.clients,
      );
  }, [shortCabinets.clients, projectsState.searchValueCabinet]);

  if (shortCabinets.clients.length === 0) {
    return (
      <div className={clsx(classes.infoBlock, !isHidden && classes.viewingMode)}>
        <span className={classes.infoTitleBox}>
          <Typography color="TextPrimary" weight={600} size="Main" align="left">
            {vkCabinet}
          </Typography>
        </span>
        <span className={classes.infoTextBox}>
          <Typography color="TextTertiary" weight={400} size="AuthContent" align="left">
            У вас еще не подключен ни один рекламный кабинет в пространстве
          </Typography>
        </span>

        {isHidden && (
          <Button className={classes.buttonAddForm} onClick={redirectToCabinet}>
            <div className={classes.addItem}>
              <Typography size="AuthContent" weight={600} color="TextButton">
                Перейти к настройке рекламных кабинетов
              </Typography>
              <ArrowUpIcon className={classes.arrowIcon} />
            </div>
          </Button>
        )}
      </div>
    );
  }

  return (
    <>
      <div
        className={clsx(
          classes.infoBlock,
          projectsState.selectedCabinets.length && classes.infoBlockTextNone,
          !isHidden && classes.viewingMode,
        )}
      >
        <span className={clsx(classes.infoTitleBox, projectsState.selectedCabinets.length && classes.buttonRight)}>
          <Typography color="TextPrimary" weight={600} size="Main" align="left">
            {vkCabinet}
          </Typography>
          {isHidden && (
            <div ref={anchorRef}>
              <CustomButtonText onClickButton={() => setOpen(true)} isOpen={isOpen}>
                Добавить кабинет
              </CustomButtonText>
            </div>
          )}
        </span>
        {!projectsState.selectedCabinets.length && (
          <span className={classes.infoTextBox}>
            <Typography color="TextTertiary" weight={400} size="AuthContent" align="left">
              В проект не добавлен еще ни один рекламный кабинет
            </Typography>
          </span>
        )}
      </div>
      {!!projectsState.selectedCabinets.length && (
        <div className={classes.boxScrollProject}>
          {projectsState.selectedCabinets.map(el => {
            return (
              <ProjectCabinetItem
                isArchived={!editProject.editProjectData?.is_archived && isHidden}
                key={el.client_id}
                cabinet={el}
              />
            );
          })}
        </div>
      )}
      <Popover
        isListLeft
        setIsOpen={handleClosePopover}
        onEscape={handleClosePopover}
        anchorEl={anchorRef.current}
        isOpen={isOpen}
      >
        <PopupCabinets selectedCabinets={projectsState.selectedCabinets} />
      </Popover>
    </>
  );
});
