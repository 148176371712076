import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { DefaultRenderer } from './default-renderer';

export const DefaultAvgRenderer = ({ column, api }: ICellRendererParams) => {
  let avg = 0,
    n = 0;

  column &&
    api.forEachNodeAfterFilter(rowNode => {
      if (rowNode.data && rowNode.data[column.getColId()] && !rowNode.data.orgHierarchy) {
        n += 1;
        avg += +rowNode.data[column.getColId()];
      }
    });

  return <DefaultRenderer value={avg / n} formatter={column?.getColDef().valueFormatter} />;
};
