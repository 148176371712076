import { BasicStore, httpClient2 } from '@plarin/core';
import { ValidatorLength255 } from '@plarin/utils';
import { makeObservable, observable, reaction, runInAction } from 'mobx';
import { DataDrawerTeam, TStatusTeam, TTeamResponse, TTeams, TUpdateTeamRequest } from '../../types/teams/teams';
import { sortRow } from '../utils/common';
import { STATUS } from '../utils/constants';

interface ModalData {
  type: 'block' | 'archive' | 'unArchive' | 'delete' | 'saveExit' | '';
  data?: TTeams | DataDrawerTeam;
}

const emptyDrawerData: DataDrawerTeam = {
  name: '',
  leader_id: 'not',
  member_ids: [],
  project_ids: [],
  hidden: false,
  closed: false,
  status: 'active',
  isEditDrawer: false,
};

export class TeamsStore extends BasicStore {
  isTeamsLoading: boolean = false;
  teamsData: TTeams[] = [];
  hasLoadingError: boolean = false;
  isErrorName: boolean = false;
  // получение проектов в этом сторе - временно, пока не появится стор для проектов или не будет принято решение как-то иначе организовать сторы
  isProjectsLoading: boolean = false;
  isDrawerOpen: boolean = false;
  dataDrawer: DataDrawerTeam | null = null;
  modalData: ModalData = {} as ModalData;
  isLoading: boolean = false;
  menuIsLoading: boolean = false;
  searchFilter: string = '';
  isDrawerDataChanged: boolean = false;
  emptyDrawerData: DataDrawerTeam = emptyDrawerData;
  initialDrawerData: DataDrawerTeam = emptyDrawerData;

  constructor() {
    super();
    makeObservable<this>(this, {
      isTeamsLoading: observable,
      teamsData: observable,
      createTeam: observable,
      isProjectsLoading: observable,
      getTeams: observable,
      isDrawerOpen: observable,
      dataDrawer: observable,
      hasLoadingError: observable,
      isErrorName: observable,
      modalData: observable,
      isLoading: observable,
      menuIsLoading: observable,
      searchFilter: observable,
      isDrawerDataChanged: observable,
      emptyDrawerData: observable,
      initialDrawerData: observable,
    });

    reaction(
      () => this.dataDrawer?.name,
      name => {
        if (name) {
          this.isErrorName = !ValidatorLength255(name)[0];
        } else {
          this.isErrorName = false;
        }
      },
    );
  }

  setIsTeamsLoading = (loading: boolean) => runInAction(() => (this.isTeamsLoading = loading));

  setSearchFilter = (value: string) => runInAction(() => (this.searchFilter = value));

  // был ли вызван триггер из экшен меню перед началом лоадинга
  setMenuIsLoading = (data: boolean) => runInAction(() => (this.menuIsLoading = data));

  setModalData = (data: ModalData) => {
    runInAction(() => (this.modalData = data));
  };

  setLoading = (loading: boolean) => runInAction(() => (this.isLoading = loading));

  onOpenDrawer = (data: DataDrawerTeam) =>
    runInAction(() => {
      this.isDrawerOpen = true;
      this.dataDrawer = { ...data };
      this.initialDrawerData = { ...data };
      this.isDrawerDataChanged = false;
    });
  onCloseDrawer = () =>
    runInAction(() => {
      this.isDrawerOpen = false;
      this.isDrawerDataChanged = false;
    });

  isChanged(initialData: DataDrawerTeam, drawerData: DataDrawerTeam): boolean {
    function isArrayDifferent(arr1: string[], arr2: string[]): boolean {
      const sortedArr1 = arr1.slice().sort();
      const sortedArr2 = arr2.slice().sort();
      return JSON.stringify(sortedArr1) !== JSON.stringify(sortedArr2);
    }

    function isLeaderChanged(initialData: DataDrawerTeam, drawerData: DataDrawerTeam) {
      // изначально лидер не был выбран, пользователь повторно выбрал отсутствие лидера
      const noLeaderNotChanged = initialData.leader_id === null && drawerData.leader_id === 'not';
      if (!noLeaderNotChanged && initialData.leader_id !== drawerData.leader_id) {
        return true;
      }
    }

    for (const key in initialData) {
      let initialValue = initialData[key as keyof DataDrawerTeam];
      let drawerValue = drawerData[key as keyof DataDrawerTeam];
      const isArray = Array.isArray(initialValue);
      const isLeader = key === 'leader_id';

      // 1 сравниваем свойства объектов, в которых лежат массивы со строками
      if (isArray && isArrayDifferent(initialValue as string[], drawerValue as string[])) {
        return true;
      }
      // 2 сравниваем значения по ключу leader_id
      else if (isLeader && isLeaderChanged(initialData, drawerData)) {
        return true;
      }
      // 3 сравниваем все оставшиеся значения: строки и булевы значения
      else if (!isArray && !isLeader && initialValue !== drawerValue) {
        return true;
      }
    }
    return false;
  }

  updateDataDrawer = (key: keyof DataDrawerTeam, value: any) => {
    runInAction(() => {
      if (this.dataDrawer) {
        this.dataDrawer[key] = value as never;
        this.isDrawerDataChanged = this.isChanged(this.initialDrawerData, this.dataDrawer);
      }
    });
  };

  setLoadingCells = (cellKey: string[], cellLoadingName: string[]) => {
    runInAction(
      () =>
        (this.teamsData = this.teamsData!.map(el =>
          cellKey.includes(el.key)
            ? {
                ...el,
                cellLoadingName: cellLoadingName,
              }
            : el,
        )),
    );
  };

  setTeams = (data: TTeams[]) => runInAction(() => (this.teamsData = data));

  getTeams = async () => {
    this.setIsTeamsLoading(true);
    runInAction(() => (this.hasLoadingError = false));

    await this.execRequest<TTeamResponse[]>(httpClient2.get('/api/v1/user/teams'))
      .then(resp => {
        const data = resp
          .map(team => ({
            ...team,
            key: team._id,
            cellLoadingName: [],
          }))
          .sort((a, b) => sortRow(a.name, b.name));

        runInAction(() => {
          this.teamsData = data;
        });
      })
      .catch(() => {
        this.setTeams([]);
        runInAction(() => (this.hasLoadingError = true));
      })
      .finally(() => this.setIsTeamsLoading(false));
  };

  joinTeam = async (team: DataDrawerTeam, userId: string) => {
    this.setLoading(true);
    const keyNotification = Date.now();
    team._id && this.setLoadingCells([team._id], ['members', 'name']);

    setTimeout(async () => {
      await this.execRequest(httpClient2.post(`/api/v1/user/teams/${team._id}/join`))
        .then(() => {
          this.addNotification({
            type: STATUS.SUCCESS,
            title: `Вы успешно присоединились к команде "${team.name}"`,
            key: keyNotification,
          });

          runInAction(() => {
            this.teamsData = this.teamsData.map(e => {
              if (e.key === team._id) {
                return { ...e, member_ids: [...e.member_ids, userId] };
              }
              return e;
            });
            this.onCloseDrawer();
          });
        })
        .catch(err => {
          this.addNotification({
            type: STATUS.ERROR,
            title: err,
          });
        })
        .finally(() => {
          team._id && this.setLoadingCells([team._id], []);
          this.deleteNotification(keyNotification);
          this.setLoading(false);
        });
    }, 350);
  };

  leaveTeam = async (teamInDrawer: DataDrawerTeam, userId: string, isAdmin: boolean) => {
    this.setLoading(true);
    const keyNotification = Date.now();
    teamInDrawer._id && this.setLoadingCells([teamInDrawer._id], ['members', 'name']);

    setTimeout(async () => {
      await this.execRequest(httpClient2.post(`/api/v1/user/teams/${teamInDrawer._id}/leave`))
        .then(() => {
          this.addNotification({
            type: STATUS.SUCCESS,
            title: `Вы успешно вышли из команды "${teamInDrawer.name}"`,
            key: keyNotification,
          });

          const teamData = this.teamsData.find(team => team._id === teamInDrawer._id);

          // проверяем, нужно ли убирать команду из таблицы, по данным из таблицы, а не по данным из дровера
          const isTeamRemoved = teamData && teamData.hidden && !isAdmin && teamData.leader_id !== userId;

          if (isTeamRemoved) {
            // после выхода из невидимой команды пользователь перестаёт её видеть, если он не остаётся лидером команды или если он не
            runInAction(() => {
              this.teamsData = this.teamsData.filter(team => team._id !== teamInDrawer._id);
            });
          } else {
            runInAction(() => {
              this.teamsData = this.teamsData.map(e => {
                if (e.key === teamInDrawer._id) {
                  return { ...e, member_ids: e.member_ids.filter(user => user !== userId) };
                }
                return e;
              });
            });
          }

          this.onCloseDrawer();
        })
        .catch(err => {
          this.addNotification({
            type: STATUS.ERROR,
            title: err,
          });
        })

        .finally(() => {
          teamInDrawer._id && this.setLoadingCells([teamInDrawer._id], []);
          this.deleteNotification(keyNotification);
          this.setLoading(false);
        });
    }, 350);
  };

  toggleStatusTeam = async (teamPrevData: TTeams, status: TStatusTeam) => {
    const keyNotification = Date.now();

    this.setLoading(true);

    setTimeout(async () => {
      this.execRequest<TUpdateTeamRequest>(
        httpClient2.post(`/api/v1/user/teams/${teamPrevData._id}`, { status: status }),
      )
        .then(() => {
          this.addNotification({
            type: STATUS.SUCCESS,
            title: `Команда успешно ${status === 'active' ? 'разархивирована' : 'заархивирована'}`,
            key: keyNotification,
          });

          runInAction(() => {
            this.teamsData = this.teamsData.map(e =>
              e.key === teamPrevData.key ? { ...e, status: status } : e,
            ) as TTeams[];
            this.deleteNotification(keyNotification);
            this.onCloseDrawer();
          });
        })
        .catch(err => {
          this.addNotification({
            type: STATUS.ERROR,
            title: err,
          });
        })
        .finally(() => {
          this.setLoading(false);
          this.setModalData({ type: '' });
        });
    }, 350);
  };

  deleteTeam = async (data: TTeams) => {
    const keyNotification = Date.now();

    this.setLoading(true);

    setTimeout(async () => {
      await this.execRequest<TUpdateTeamRequest>(httpClient2.delete(`/api/v1/user/teams/${data._id}`))
        .then(() => {
          this.addNotification({
            type: STATUS.SUCCESS,
            title: 'Команда успешно удалена',
            key: keyNotification,
          });
          runInAction(() => {
            this.teamsData = this.teamsData.filter(e => e.key !== data.key);

            this.onCloseDrawer();
          });
        })
        .catch(err => {
          this.addNotification({
            type: STATUS.ERROR,
            title: err,
            key: keyNotification,
          });
        })

        .finally(() => {
          this.setLoading(false);

          this.setModalData({ type: '' });
          this.deleteNotification(keyNotification);
        });
    }, 350);
  };

  changeTeam = async (userId: string, iaAdmin: boolean) => {
    const data: TUpdateTeamRequest = {
      name: this.dataDrawer?.name,
      leader_id: this.dataDrawer?.leader_id === 'not' ? (null as unknown as string) : this.dataDrawer?.leader_id,
      hidden: this.dataDrawer?.hidden,
      closed: this.dataDrawer?.closed,
      status: this.dataDrawer?.status,
      member_ids: this.dataDrawer?.member_ids,
      project_ids: this.dataDrawer?.project_ids,
    };
    const keyNotification = Date.now();

    this.setLoading(true);

    setTimeout(async () => {
      this.dataDrawer &&
        (await this.execRequest<TUpdateTeamRequest>(httpClient2.post(`/api/v1/user/teams/${this.dataDrawer._id}`, data))
          .then(() => {
            this.addNotification({
              type: STATUS.SUCCESS,
              title: 'Команда успешно изменена',
              key: keyNotification,
            });
            runInAction(() => {
              this.teamsData = this.teamsData
                .map(e => {
                  if (this.dataDrawer && e._id === this.dataDrawer._id) {
                    return { ...e, ...data };
                  }
                  return e;
                })
                // если пользователь является обычным сотрудником без админских прав, и в результате запроса changeTeam перестал быть лидером && членом скрытой команды, он перестаёт видеть эту команду в таблице
                .filter(e => iaAdmin || !e.hidden || e.leader_id === userId || e.member_ids.includes(userId));
              this.deleteNotification(keyNotification);
            });
          })
          .catch(err => {
            this.addNotification({
              type: STATUS.ERROR,
              title: err,
              key: keyNotification,
            });
            this.deleteNotification(keyNotification);
          })

          .finally(() => {
            this.onCloseDrawer();
            this.setLoading(false);
          }));
    }, 350);
  };

  createTeam = async () => {
    const data: TUpdateTeamRequest = {
      name: this.dataDrawer?.name,
      leader_id: this.dataDrawer?.leader_id === 'not' ? undefined : this.dataDrawer?.leader_id,
      hidden: this.dataDrawer?.hidden,
      closed: this.dataDrawer?.closed,
      status: this.dataDrawer?.status,
      member_ids: this.dataDrawer?.member_ids,
      project_ids: this.dataDrawer?.project_ids,
    };
    const keyNotification = Date.now();

    this.setLoading(true);

    setTimeout(async () => {
      this.dataDrawer &&
        (await this.execRequest<TTeamResponse>(httpClient2.post('/api/v1/user/teams', data))
          .then(res => {
            this.addNotification({
              type: STATUS.SUCCESS,
              title: 'Команда успешно создана',
              key: keyNotification,
            });
            runInAction(() => {
              this.teamsData = [
                ...this.teamsData,
                {
                  ...res,
                  key: res._id,
                },
              ].sort((a, b) => sortRow(a.name, b.name));
            });
            this.deleteNotification(keyNotification);
          })
          .catch(err => {
            this.addNotification({
              type: STATUS.ERROR,
              title: err,
              key: keyNotification,
            });
            this.deleteNotification(keyNotification);
          })

          .finally(() => {
            this.onCloseDrawer();
            this.setLoading(false);
          }));
    }, 350);
  };

  resetStore = () =>
    runInAction(() => {
      this.isTeamsLoading = false;
      this.teamsData = [];
      this.hasLoadingError = false;
      this.isErrorName = false;
      this.isDrawerOpen = false;
      this.dataDrawer = null;
      this.modalData = {} as ModalData;
      this.isLoading = false;
      this.menuIsLoading = false;
      this.searchFilter = '';
    });
}
