import { DeleteIcon } from '@plarin/design';
import { CustomSelect, DefaultInput } from '@plarin/inputs';
import { InputNameErrors, useStateValidator, ValidatorLength255 } from '@plarin/utils';
import React, { useEffect, useState } from 'react';
import { ReportFilter } from '../../../../../../types/reports/reports';
import { filterIncludesVariants, filterNameVariants } from './select-menu-variants';
import classes from './style.module.scss';

export type TFilter = ReportFilter & {
  key: number;
  error?: boolean;
};

type SelectBlockProps = {
  dataFilter: TFilter;
  setDataFilter: (arg: TFilter) => void;
  deleteFilter: (index: number) => void;
  index: number;
};

export const SelectBlock = ({ dataFilter, setDataFilter, deleteFilter, index }: SelectBlockProps) => {
  const [filter, setFilter] = useState<ReportFilter>({
    type: dataFilter.type,
    op: dataFilter.op,
    value: dataFilter.value,
  });

  const updateFilter = (value: any) => {
    setFilter(prev => {
      return { ...prev, ...value };
    });
  };

  const [[isValidLength]] = useStateValidator(filter.value, ValidatorLength255);

  const [isIconHover, setIsIconHover] = useState<boolean>(false);
  const enableSettingsIcon = () => setIsIconHover(true);
  const disableSettingsIcon = () => setIsIconHover(false);

  useEffect(() => {
    setDataFilter({ key: index, type: filter.type, op: filter.op, value: filter.value, error: !isValidLength });
  }, [filter, index, isValidLength]);

  return (
    <div className={classes.selectBlock}>
      <div className={classes.selectInputs}>
        <CustomSelect
          value={filter.type}
          onChange={value => updateFilter({ type: value })}
          listVariants={filterNameVariants}
          menuHeight={48}
        />
        <CustomSelect
          value={filter.op}
          onChange={value => updateFilter({ op: value })}
          listVariants={filterIncludesVariants}
          menuHeight={80}
        />
        <DefaultInput
          value={filter.value}
          setValue={(e: string) => updateFilter({ value: e })}
          size={48}
          clearInputAction={() => updateFilter({ value: '' })}
          errorText={!isValidLength ? InputNameErrors.bigName255 : ''}
        />
      </div>

      <div onMouseOver={enableSettingsIcon} onMouseOut={disableSettingsIcon}>
        <DeleteIcon
          className={classes.deleteIcon}
          color={isIconHover ? 'secondary' : 'main'}
          onClick={() => deleteFilter(index)}
        />
      </div>
    </div>
  );
};
