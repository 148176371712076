export * from './app-local-storage';

export * from './auth';

export * from './errors';

export * from './http-client';

export * from './init-app-utils';

export function getApiUrl<T>(path: Extract<keyof T, string>, params?: Record<string, any>) {
  return params
    ? Object.keys(params).reduce(
        (previousValue, currentValue) => previousValue.replaceAll(`{${currentValue}}`, params[currentValue]),
        path,
      )
    : path;
}

export function createThrottleFunc(func: VoidFunction, ms: number) {
  let isThrottled = false;

  return () => {
    if (isThrottled) {
      return;
    }
    func();
    isThrottled = true;
    setTimeout(() => {
      if (!isThrottled) {
        func();
      }
      isThrottled = false;
    }, ms);
  };
}
