import React, { useState, useMemo, useEffect } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import ru from 'react-phone-input-2/lang/ru.json';
import { phoneIsValid, InputPhoneErrors } from './utils/phoneValidation';
import './input-phone-2.scss';
import clsx from 'clsx';

const inputStyle = {
  width: '100%',
};

const enabledCountry = 'ru';
const enabledCountryFullname = 'Russia';

// Эта функция нужна, чтобы валидировать инпут, в который ничего не вводили, и отобразить сообщение об ошибке
export const setEmptyErrorMessage = (
  inputValue: string,
  setInputValue: React.Dispatch<React.SetStateAction<string>>,
) => {
  inputValue.length < 2 && setInputValue('+');
};

interface PhoneInputProps2 extends PhoneInputProps {
  onChange: React.Dispatch<React.SetStateAction<string>>;
  checkLength?: boolean | undefined;
  error?: string;
  setHasError?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InputPhone2: React.VFC<PhoneInputProps2> = ({ error, onChange, setHasError, ...props }) => {
  const [focus, setFocus] = useState(false);

  const checkLength = useMemo(
    () => (typeof props.checkLength === 'boolean' ? props.checkLength : true),
    [props.checkLength],
  );

  // Это нужно для починки следующего бага: если начальное value для инпута - это номер не из списка разрешённых стран, то инпут не давал редактироват телефон. По факту, инпут был полностью заблокирован, хотя каретку в него можно поставить
  // Для починки бага применяем такую логику: если телефон не из списка разрешённых стран, мы позволяем менять код страны. Это позволяет редактировать value инпута. Ну а если телефон имеет код разрешённых стран, то редактировать код страны нельзя
  const countryCodeIsEditable =
    phoneIsValid(props.value || '', { name: enabledCountryFullname }, checkLength, error) ===
      InputPhoneErrors.wrongCountryCode || !props.value;

  useEffect(() => {
    if (props.value === '') {
      onChange('7');
    }
  }, [props.value]);

  return (
    <PhoneInput
      onChange={onChange}
      specialLabel="Номер мобильного телефона"
      containerClass={clsx(
        'containerClass',
        (!props.value || props.value === '7') && !focus && 'containerClassLabel',
        focus && 'containerClassFocused',
      )}
      country={enabledCountry}
      onlyCountries={['ru']}
      countryCodeEditable={countryCodeIsEditable}
      localization={ru}
      inputStyle={inputStyle}
      inputProps={{
        onFocus: () => setFocus(true),
        onBlur: () => setFocus(false),
        autoFocus: true,
      }}
      isValid={(value, country) => {
        const isPhoneValid = phoneIsValid(value, country, checkLength, error);
        setHasError && setHasError(typeof isPhoneValid !== 'boolean');
        return isPhoneValid;
      }}
      {...props}
    />
  );
};
