import { Divider } from '@mui/material';
import { Typography } from '@plarin/design';
import { CurrencyEnum, typeCurrency } from '@plarin/utils';
import React, { memo, useRef, useState } from 'react';
import { Switch } from '../switch';
import { MainTooltip, SwitcherTooltip } from '../tooltip';
import { HightLightText } from './../hightlight-text';
import classes from './integrations-table-row.module.scss';
import { IIntagrationsTableRowProps } from './types';

export const IntegrationsTableRow: React.FC<IIntagrationsTableRowProps> = memo(
  ({ id, name, cost, isEnable, onChange, keyId, currency, searchFilter }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);
    const checkedLengthLine = (event: React.MouseEvent<HTMLSpanElement>) => {
      if (ref.current) setIsVisible(event.currentTarget.offsetWidth > ref.current.offsetWidth);
    };
    const typedCurrency = currency as keyof typeof CurrencyEnum;

    return (
      <div className={classes.tableRow}>
        <div className={classes.info}>
          <div className={classes.nameAndId} ref={ref}>
            <MainTooltip
              tooltipMessage={name}
              component="div"
              isVisible={isVisible}
              followCursor
              componentClassName={classes.textHide}
            >
              <span onMouseEnter={checkedLengthLine}>
                <HightLightText textEllips={true} text={name} filterValue={searchFilter!} size="Main" />
              </span>
            </MainTooltip>

            <Typography color="textSecondary" component="div" size="TableRowSubHeader" weight={400}>
              <HightLightText text={id} filterValue={searchFilter!} />
            </Typography>
          </div>

          <div className={classes.cost}>
            <Typography size="Caption" color="primary" weight={600}>
              {`${cost}`}
            </Typography>
            &nbsp;
            <Typography size="Caption" color="textSecondary">
              {typeCurrency(typedCurrency)}
            </Typography>
          </div>
        </div>
        <div className={classes.status}>
          <SwitcherTooltip
            content={
              isEnable ? (
                <Typography color="TextWhite" size="AuthContent">
                  Выключить
                </Typography>
              ) : (
                <Typography color="TextWhite" size="AuthContent">
                  Включить
                </Typography>
              )
            }
            component="div"
            placement="bottom"
          >
            <Switch checked={isEnable} onChange={() => onChange(keyId)} />
          </SwitcherTooltip>
        </div>
        <Divider classes={{ root: classes.TableDividerRoot }} orientation="horizontal" />
      </div>
    );
  },
);
