import { Typography } from '@plarin/design';
import { ModalActionNamesEnum, Modal } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React from 'react';
import { useAppDataStore } from '../../../../components/app-data-provider';

interface IProjectsConfirmModal {
  editProjects: () => void;
  createProjects: () => void;
}

export const ProjectsConfirmModal = observer(({ editProjects, createProjects }: IProjectsConfirmModal) => {
  const {
    projects: {
      modalData,
      setModalData,
      isLoading,
      deleteProject,
      postEditProjects,
      setIsOpenDrawer,
      setProjectsState,
      projectsState,
      editProject,
    },
    profileStore: { deleteProjectFromWsDictionary },
  } = useAppDataStore();

  if (modalData.type === 'saveExit') {
    return (
      <Modal
        open={true}
        closeModal={() => setModalData({ type: '' })}
        workspaceHeader="saveExit"
        secondAction={{
          actionTitle: ModalActionNamesEnum.NoSaveExit,
          action: () => {
            setModalData({ type: '' });
            setIsOpenDrawer(false);
          },
        }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.SaveExit,
          isLoading: isLoading,
          action: () => {
            if (editProject.isEditProject) {
              setModalData({ type: '' });
              editProjects();
            } else {
              setModalData({ type: '' });
              createProjects();
            }
          },
        }}
        title="Сохранить изменения?"
      >
        <Typography size="AuthContent">
          Вы внесли изменения, которые не были сохранены.
          <br />
          Сохранить изменения перед выходом?
        </Typography>
      </Modal>
    );
  }

  if (modalData.type === 'archive') {
    return (
      <Modal
        open={modalData.type === 'archive'}
        closeModal={() => setModalData({ type: '' })}
        workspaceHeader="archive"
        secondAction={{ actionTitle: ModalActionNamesEnum.Cancel, action: () => setModalData({ type: '' }) }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.Archive,
          isLoading: isLoading,
          action: () => {
            postEditProjects(modalData.data._id, {
              is_archived: true,
            }).then(() => {
              setIsOpenDrawer(false);
            });
          },
        }}
        title="Архивировать проект?"
      >
        <Typography size="AuthContent">
          {'После архивации проект '}
          <Typography size="AuthContent" weight={600}>
            {modalData?.data?.name}
          </Typography>
          {' останется доступным в таблице с соответствующим фильтром.'}
        </Typography>
      </Modal>
    );
  }

  if (modalData.type === 'unArchive') {
    return (
      <Modal
        open={modalData.type === 'unArchive'}
        closeModal={() => setModalData({ type: '' })}
        workspaceHeader="unArchive"
        secondAction={{ actionTitle: ModalActionNamesEnum.Cancel, action: () => setModalData({ type: '' }) }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.UnArchive,
          isLoading: isLoading,
          action: () => {
            postEditProjects(modalData.data._id, {
              is_archived: false,
            });
          },
        }}
        title="Разархивировать проект?"
      >
        <Typography size="AuthContent">
          {'Проект '}
          <Typography size="AuthContent" weight={600}>
            {modalData?.data?.name}
          </Typography>
          {' будет разархивирован.'}
        </Typography>
      </Modal>
    );
  }

  if (modalData.type === 'delete') {
    return (
      <Modal
        open={modalData.type === 'delete'}
        closeModal={() => setModalData({ type: '' })}
        workspaceHeader="remove"
        secondAction={{
          actionTitle: ModalActionNamesEnum.Cancel,
          action: () => setModalData({ type: '' }),
        }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.Delete,
          isLoading: isLoading,
          action: () => {
            modalData.data &&
              deleteProject(modalData.data._id).then(() => deleteProjectFromWsDictionary(modalData.data._id));
          },
        }}
        title="Удалить проект?"
      >
        <Typography size="AuthContent">
          {'После удаления проекта '}
          <Typography size="AuthContent" weight={600}>
            {modalData?.data?.name}
          </Typography>
          {' все данные, связанные с проектом, будут удалены безвозвратно.'}
        </Typography>
      </Modal>
    );
  }
  if (modalData.type === 'untie') {
    return (
      <Modal
        open={modalData.type === 'untie'}
        closeModal={() => setModalData({ type: '' })}
        workspaceHeader="untie"
        title="Отвязать рекламный кабинет?"
        firstAction={{
          actionTitle: ModalActionNamesEnum.Untie,
          action: () => {
            setProjectsState(
              'selectedCabinets',
              projectsState.selectedCabinets.filter(e => e.client_id !== modalData?.data.cabinet.client_id),
            );
            setModalData({ type: '' });
          },
        }}
        secondAction={{
          actionTitle: ModalActionNamesEnum.Cancel,
          action: () => setModalData({ type: '' }),
        }}
      >
        <Typography size="AuthContent">
          {' Вы уверены, что хотите отвязать рекламный кабинет '}
          <Typography size="AuthContent" weight={600}>
            {modalData?.data?.cabinet.username}
          </Typography>
          {' от проекта'}{' '}
          <Typography size="AuthContent" weight={600}>
            {projectsState.projectsName}
          </Typography>
          ? Все связи с проектом будут удалены безвозвратно.
        </Typography>
      </Modal>
    );
  }

  return null;
});
