import { CheckIcon, Typography } from '@plarin/design';
import { EllipsisTooltip } from '@plarin/inputs';
import clsx from 'clsx';
import React from 'react';
import { TMetric, TYDMetric } from '../../../types/metrics';
import classes from './style.module.scss';

export const GroupingNameItem = ({ metric, action }: { metric: TMetric | TYDMetric; action: () => void }) => {
  return (
    <div className={clsx(metric.checked && classes.active, classes.itemWrapper)} onClick={action}>
      <div className={classes.textWrapper}>
        <EllipsisTooltip tooltipMessage={metric.name?.ru || metric.path}>
          <Typography size="Main" ellips={true}>
            {metric.name?.ru || metric.path}
          </Typography>
        </EllipsisTooltip>
      </div>

      {metric.checked && <CheckIcon />}
    </div>
  );
};
