import { TabItem, Tabs } from '@plarin/inputs';
import React from 'react';
import './auth-tabs-template.scss';

export const AuthTabsTemplate: React.FC<{
  tabs: TabItem[];
  tab: string;
  onTabChange: (tab: string) => void;
  children: (tab: string) => React.ReactNode;
}> = ({ tabs, tab, onTabChange, children: renderChildren }) => (
  <div className="authTabsTemplate">
    <div className="authTabsTemplate__tabs">
      <Tabs tabs={tabs} value={tab} onChange={onTabChange} />
    </div>
    {renderChildren(tab)}
  </div>
);
