import { ObjectValue, shortDate } from '@plarin/utils';
import {
  addDays,
  addMonths,
  addQuarters,
  endOfWeek,
  endOfMonth,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  subMonths,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ru } from 'date-fns/locale';

export const timeZone = 'Europe/Moscow';

export const generateDates = (currentDate: Date) => {
  const zonedDate = utcToZonedTime(currentDate, timeZone);
  const weekStart = () => startOfWeek(zonedDate, { locale: ru });
  const weekEnd = () => endOfWeek(zonedDate, { locale: ru });

  const VARIANTS = [
    { label: MENU_ITEM_LABEL.All_TIME, startDay: () => new Date('2012, 01, 01'), endDay: () => zonedDate },
    { label: MENU_ITEM_LABEL.TODAY, startDay: () => zonedDate, endDay: () => zonedDate },
    { label: MENU_ITEM_LABEL.YESTERDAY, startDay: () => addDays(zonedDate, -1), endDay: () => addDays(zonedDate, -1) },
    { label: MENU_ITEM_LABEL.THIS_WEEK, startDay: () => weekStart(), endDay: () => zonedDate },
    {
      label: MENU_ITEM_LABEL.LAST_WEEK,
      startDay: () => addDays(weekStart(), -7),
      endDay: () => addDays(weekEnd(), -7),
    },
    { label: MENU_ITEM_LABEL.CURRENT_MONTH, startDay: () => startOfMonth(zonedDate), endDay: () => zonedDate },
    {
      label: MENU_ITEM_LABEL.LAST_MONTH,
      startDay: () => addMonths(startOfMonth(zonedDate), -1),
      endDay: () => endOfMonth(subMonths(zonedDate, 1)),
    },

    // { label: '', isDivider: true },
    // { label: 'Последние 2 полных дня', startDay: () => addDays(zonedDate, -2), endDay: () => addDays(zonedDate, -1) },
    // { label: 'Последние 3 полных дня', startDay: () => addDays(zonedDate, -3), endDay: () => addDays(zonedDate, -1) },
    // { label: 'Последние 7 полных дней', startDay: () => addDays(zonedDate, -7), endDay: () => addDays(zonedDate, -1) },
    // {
    //   label: 'Последние 14 полных дней',
    //   startDay: () => addDays(zonedDate, -14),
    //   endDay: () => addDays(zonedDate, -1),
    // },
    // {
    //   label: 'Последние 30 полных дней',
    //   startDay: () => addDays(zonedDate, -30),
    //   endDay: () => addDays(zonedDate, -1),
    // },
    { label: '', isDivider: true, startDay: () => 0, endDay: () => 0 },
    // { label: 'За последние # дней', startDay: () => zonedDate, endDay: () => zonedDate },
    // { label: 'За определенную дату', startDay: () => zonedDate, endDay: () => zonedDate },
    { label: MENU_ITEM_LABEL.PERIOD, startDay: () => zonedDate, endDay: () => zonedDate },
    // { label: 'Сравнить периоды', startDay: () => zonedDate, endDay: () => zonedDate },
  ];

  const QuarterItems = [
    {
      label: MENU_ITEM_LABEL.CURRENT_QUARTER,
      startDay: () => startOfQuarter(zonedDate),
      endDay: () => zonedDate,
      isDivider: false,
    },
    {
      label: MENU_ITEM_LABEL.LAST_QUARTER,
      startDay: () => addQuarters(startOfQuarter(zonedDate), -1),
      endDay: () => addDays(startOfQuarter(zonedDate), -1),
      isDivider: false,
    },
  ];
  return { VARIANTS, QuarterItems };
};

export const MENU_ITEM_LABEL = {
  All_TIME: 'За все время',
  TODAY: 'За сегодня',
  YESTERDAY: 'За вчера',
  THIS_WEEK: 'Текущая неделя',
  LAST_WEEK: 'Прошлая неделя',
  CURRENT_MONTH: 'Текущий месяц',
  LAST_MONTH: 'Прошлый месяц',
  CURRENT_QUARTER: 'Текущий квартал',
  LAST_QUARTER: 'Прошлый квартал',
  PERIOD: 'За определенный период',
} as const;

export const DatePickerEnum: { label: ObjectValue<typeof MENU_ITEM_LABEL>; value: string }[] = [
  { label: MENU_ITEM_LABEL.All_TIME, value: 'all_time' },
  { label: MENU_ITEM_LABEL.TODAY, value: 'today' },
  { label: MENU_ITEM_LABEL.YESTERDAY, value: 'yesterday' },
  { label: MENU_ITEM_LABEL.THIS_WEEK, value: 'this_week' },
  { label: MENU_ITEM_LABEL.LAST_WEEK, value: 'last_week' },
  { label: MENU_ITEM_LABEL.CURRENT_MONTH, value: 'current_month' },
  { label: MENU_ITEM_LABEL.LAST_MONTH, value: 'last_month' },
  { label: MENU_ITEM_LABEL.CURRENT_QUARTER, value: 'current_quarter' }, // проверить, когда добавят на бэке
  { label: MENU_ITEM_LABEL.LAST_QUARTER, value: 'last_quarter' },
];
