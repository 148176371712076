import { Action, History, Location, LocationState, Path, UnregisterCallback } from 'history';
import { observable, makeObservable, runInAction } from 'mobx';

export class RouterStore {
  location: Location;
  history: History;
  unsubscribeFromHistory?: UnregisterCallback;

  constructor(history: History) {
    this.history = history;
    makeObservable(this, {
      location: observable,
    });
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
    this.location = history.location;
  }

  private updateLocation = (location: Location) =>
    runInAction(() => {
      this.location = location;
      return this.location;
    });

  handleLocationChange = (location: Location) => {
    this.updateLocation(location);
  };

  push = (path: Path, state?: LocationState) => {
    this.history.push(path, state);
  };

  replace = (path: Path, state?: LocationState) => {
    this.history.replace(path, state);
  };

  go = (n: number) => {
    this.history.go(n);
  };

  goBack = () => {
    this.history.goBack();
  };

  goForward = () => {
    this.history.goForward();
  };
}
