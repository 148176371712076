import { InputHTMLAttributes } from 'react';

type TBaseType = {
  size?: 'small' | 'medium' | 'large' | 'square';
  color?: 'primary' | 'secondary' | 'light' | 'danger' | 'midle';
  variant?: 'filled' | 'outlined' | 'lightOutlined';
  disabled?: boolean;
  submit?: boolean;
  onClick?: (e: any) => void;
  loading?: boolean;
  active?: boolean;
  classNameLoading?: string;
};

export type ButtonProps = Omit<InputHTMLAttributes<HTMLButtonElement>, keyof TBaseType> & TBaseType;
