import { Page } from '@plarin/core';
import { Text, Typography } from '@plarin/design';
import { SocialButtonsLayout, SocialGmailButton, SocialMailRuButton, SocialYandexButton } from '@plarin/inputs';
import React from 'react';
import { AuthSliderLayoutTemplate } from '../../components/auth/auth-layout-template';
import './check-mail-layout.scss';
import { Link } from 'react-router-dom';

const CheckMailPage: React.FC = () => {
  const email = getEmail();
  return (
    <Page title="Проверьте почту">
      <AuthSliderLayoutTemplate>
        <div className="checkMailLayoutWrapper">
          <div className="checkMailLayout">
            <div className="checkMailLayout__title">
              <Typography size="AuthMediumHeader" weight={600} color="TextPrimary">
                Проверьте папку «Входящие»
              </Typography>
            </div>
            <div className="checkMailLayout__content">
              <Text size="s" weight="regular" color="textPrimary" increaseLineHeight>
                Мы отправили ссылку для подтверждения адреса
                <br />
                <span>электронной почты на адрес{email ? <Text weight="bold">{` ${email}`}</Text> : ''}.</span>
                <br />
                Письмо должно прийти через несколько минут.
              </Text>
            </div>
            <div className="checkMailLayout__actionHeader">
              <Text color="textPrimary" size="s" weight="bold" increaseLineHeight>
                Открыть почту
              </Text>
            </div>
            <div className="checkMailLayout__action">
              <SocialButtonsLayout classProps="socialButtonsFlex">
                <SocialMailRuButton onClick={() => window.open('https://mail.ru/', '_blank')} />
                <SocialYandexButton onClick={() => window.open('https://mail.yandex.ru/', '_blank')} />
                <SocialGmailButton onClick={() => window.open('https://mail.google.com/', '_blank')} />
              </SocialButtonsLayout>
            </div>
            <div className="checkMailLayout__caption">
              <Text size="xs" color="textSecondary" increaseLineHeight>
                Если вы не получили письмо, проверьте не попало ли оно
                <br />в папку со спамом или обратитесь в{' '}
                <a href="https://plarin.ru/support/" target="_blank" className="colorLinc" rel="noreferrer">
                  службу поддержки
                </a>
                .
              </Text>
            </div>
          </div>
        </div>
      </AuthSliderLayoutTemplate>
    </Page>
  );
};

function getEmail() {
  const email = new URLSearchParams(window.location.search).get('email');
  email && window.history.replaceState(null, '', window.location.pathname);
  return email;
}

export default CheckMailPage;
