import {
  CheckIcon,
  CloseCircleIcon,
  CloseIcon,
  ErrorIcon,
  ProposeIdeaSuccessIcon,
  TimeIcon,
  Typography,
} from '@plarin/design';
import { Button } from '@plarin/inputs';
import { NotificationTitle, typografText } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { ReactChild, ReactNode, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { STATUS } from '../../utils/constants';
import classes from './style.module.scss';
import './style.scss';
import { List, NotifacationProps } from './types';

interface BodyProps extends Partial<List> {
  children?: ReactChild;
}
// В будущем возможно понадобится , не удалять
// const CustomProgressBarError = () => {
//   const [width, setWidth] = useState(100);
//   const intervalRef = useRef<any>();
//   useEffect(() => {
//     intervalRef.current = setInterval(() => {
//       setWidth(prev => prev - 0.2);
//     }, 14);
//     return () => clearInterval(intervalRef.current);
//   }, []);
//   const cancelInterval = () => {
//     if (width === 0) {
//       clearInterval(intervalRef.current);
//     }
//   };
//   cancelInterval();
//
//   return <div className={classes.progressError} style={{ width: `${width}%`, opacity: width < 0 ? 0 : 1 }} />;
// };

const NotificationBody = ({ title, message, type, children }: BodyProps) => {
  const formattedMessage = typeof message === 'string' ? typografText(message) : message;

  return (
    <div className={clsx(classes.bodyWrapper, !message && classes.textBody)}>
      <Typography
        weight={message ? 600 : undefined}
        size="AuthContent"
        color={type === STATUS.LOADING ? 'TextWhite' : 'TextPrimary'}
        componentProps={{ className: clsx(!message && classes.textCountLine) }}
      >
        {title}
      </Typography>

      {title === NotificationTitle.PROPOSE_IDEA ? (
        <div className={classes.messWithIcon}>
          <Typography componentProps={{ className: classes.textCountLine }} size="AuthContent">
            {formattedMessage}
          </Typography>
          <ProposeIdeaSuccessIcon className={classes.proposeIdea} />
        </div>
      ) : (
        <Typography
          componentProps={{ className: clsx(classes.textCountLine) }}
          size="AuthContent"
          color={type === STATUS.LOADING ? 'TextWhite' : 'TextPrimary'}
        >
          {formattedMessage}
        </Typography>
      )}
      {children && children}
    </div>
  );
};

export const Notification = observer(({ deleteNotification, notifications }: NotifacationProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const callToast = (arr: List[]) => {
    return arr.map(item => {
      const message = item.message && (typeof item.message === 'string' ? typografText(item.message) : item.message);

      if (item.type === STATUS.WARNING) {
        return toast.warning(
          <>
            <NotificationBody title={item.title} message={message} />
          </>,
          {
            position: 'bottom-right',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            onClose: () => item.key && notifications && deleteNotification(item.key),
            icon: <ErrorIcon iconType="solid" color="status_warning" hasSafebox={false} />,
            className: clsx(classes.toast, classes.toastWarning),
            toastId: item.key,
          },
        );
      }
      if (item.type === STATUS.ERROR) {
        return toast.error(
          <>
            <NotificationBody title={item.title} message={message} />
          </>,
          {
            position: 'bottom-right',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            onClose: () => item.key && notifications && deleteNotification(item.key),
            icon: <CloseCircleIcon iconType="solid" color="status_error" hasSafebox={false} />,
            className: clsx(classes.toast, classes.toastError),
            toastId: item.key,
          },
        );
      } else if (item.type === STATUS.LOADING) {
        return toast.success(
          ({ closeToast }) => {
            return (
              <NotificationBody title={item.title} message={message} type={item.type}>
                <Button
                  className={classes.cancelButton}
                  onClick={e => {
                    item.canceledDownloadExcel && item.canceledDownloadExcel(e);
                    closeToast && closeToast();
                  }}
                >
                  <Typography weight={600}>Отменить</Typography>
                </Button>
              </NotificationBody>
            );
          },
          {
            position: 'bottom-right',
            autoClose: false,
            onOpen: () => item.key && notifications && deleteNotification(item.key),
            icon: <TimeIcon iconType="solid" color="primary30Disable" hasSafebox={false} />,
            toastId: item.key,
            className: clsx(classes.toast, classes.loading),
            closeButton: <CloseIcon color="white" />,
          },
        );
      } else {
        return toast.success(<NotificationBody title={item.title} message={message} />, {
          position: 'bottom-right',
          autoClose: 7000,
          onOpen: () => {
            item.key && notifications && deleteNotification(item.key);
          },
          icon: item.title !== NotificationTitle.PROPOSE_IDEA && (
            <CheckIcon iconType="solid" color="primary60" hasSafebox={false} />
          ),
          progressClassName: classes.progressSuccess,
          className: clsx(classes.toast, classes.toastSuccess),
        });
      }
    });
  };

  useEffect(() => {
    notifications && callToast(notifications);
  }, [callToast, notifications]);

  return (
    <ToastContainer
      className={classes.toastContainer}
      bodyClassName={classes.body}
      closeButton={<CloseIcon className={classes.closeButton} />}
    />
  );
});
