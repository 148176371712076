import { Tabs, TabsProps } from '@mui/material';
import React from 'react';
import classes from './tabs.module.scss';

export const TableTabs = ({ children, ...props }: TabsProps) => {
  return (
    <Tabs
      {...props}
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
      classes={{ flexContainer: classes.tabListRoot }}
    >
      {children}
    </Tabs>
  );
};
