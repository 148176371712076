import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import { Tcountry } from './types';

export enum InputPhoneErrors {
  noNumber = 'Введите номер телефона',
  shortNumber = 'Короткий мобильный номер',
  wrongNumber = 'Введите корректный номер мобильного телефона',
  wrongCountryCode = 'Неправильный код страны',
  wrongMobileOperatorCode = 'Неправильный код мобильного оператора',
  phoneUsed = 'Данный номер телефона уже зарегистрирован',
}
enum EApiCodes {
  PHONE_ALREADY_USED = 'PHONE_ALREADY_USED',
}

// валидация телефонных номеров: только русские && мобильные. Поле не может остаться пустым.
export const rusNumberIsValid = (value: string): boolean => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  const phoneIsRusValid = (value: string): boolean =>
    phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, 'RU'), 'RU');

  const phoneIsRusMobile = (value: string): boolean => {
    return phoneUtil.getNumberType(phoneUtil.parse(value, 'RU')) === PhoneNumberType.MOBILE;
  };
  // обязательно проверяем, чтобы строка 'value' была не пустая. Иначе методы phoneUtil будут выдавать ошибку типизации и ронять приложение
  // также value должна быть > 10, чтобы валидацию не проходили строки без '+7' в начале (такое бывает, т.к. phoneUtil может одобрить номера без международного кода)
  if (value.length > 10) {
    return phoneIsRusValid(value) && phoneIsRusMobile(value);
  }
  return false;
};

export const phoneIsValid = (
  value: string,
  country: Tcountry | any,
  checkLength: boolean,
  error?: string,
): string | boolean => {
  if (!value) {
    return InputPhoneErrors.noNumber;
  }
  if (value.length >= 1 && value[0] !== country.countryCode) {
    return InputPhoneErrors.wrongCountryCode;
  }
  if (value.length >= 2 && country.name === 'Russia' && value[1] !== '9') {
    return InputPhoneErrors.wrongMobileOperatorCode;
  }
  if (value.length < 11 && checkLength) {
    return InputPhoneErrors.shortNumber;
  }
  if (value.length === 11 && !rusNumberIsValid(value)) {
    return InputPhoneErrors.wrongNumber;
  }
  if (error === EApiCodes.PHONE_ALREADY_USED) {
    return InputPhoneErrors.phoneUsed;
  }
  if (error === InputPhoneErrors.noNumber) {
    return InputPhoneErrors.noNumber;
  }
  return true;
};
