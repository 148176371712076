import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import { ChevronRightIcon, Typography } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import { EllipsisTooltip } from '../tooltip';
import classes from './style.module.scss';
import { TMenuSection, TMenuItem } from './types';

export const MenuSection: React.FunctionComponent<TMenuSection & { onClick: () => void }> = ({
  title,
  isOpen,
  children,
  onClick,
}) => {
  return (
    <>
      <ListItemButton classes={{ root: classes.menuItem }} disableRipple onClick={onClick}>
        <Typography size="Caption" weight={600} color={isOpen ? 'TextPrimary' : 'TextSecondary'}>
          {title.toUpperCase()}
        </Typography>
        <ChevronRightIcon
          size={20}
          className={clsx(classes.subheaderButton, classes.rotatable, isOpen && classes.isRotated)}
        />
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit classes={{ root: classes.collapseRoot }}>
        <div className={classes.collapse}>{children}</div>
      </Collapse>
    </>
  );
};

export const MenuItem: React.FunctionComponent<TMenuItem & { onClick: () => void }> = ({
  title,
  index,
  selected,
  icon,
  onClick,
}) => {
  return (
    <ListItemButton
      classes={{
        root: classes.rootListItemButton,
        selected: classes.selectedListItemButton,
      }}
      selected={selected}
      key={index}
      onClick={onClick}
    >
      {icon}
      <EllipsisTooltip tooltipMessage={title}>
        <Typography
          color="TextPrimary"
          size="AuthContent"
          ellips
          componentProps={{ className: classes.subMenuMaxWidth }}
        >
          {title}
        </Typography>
      </EllipsisTooltip>
    </ListItemButton>
  );
};
