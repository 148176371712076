import { /* CopyLinkIcon,*/ Typography } from '@plarin/design';
import {
  Button,
  CustomSelect,
  // EllipsisTooltip,
  Input,
  Lockscreen,
  MainTooltip,
  Modal,
  ModalActionNamesEnum,
  // Skeleton,
} from '@plarin/inputs';
import { InputEmailErrors, TooltipErrorRole, TypeInput, typografText, useInput } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { WSRolesEnum } from '../../../../types/workspace/enum';
import { WSRole } from '../../../../types/workspace/types';
import { useAppDataStore } from '../../../components/app-data-provider';
import { ymDisableClass } from '../../../utils/constants';
import classes from './invite-members.module.scss';

type TWSRolesVariants = {
  value: WSRole;
  label: WSRolesEnum;
  disabled?: boolean;
};

const WSRolesVariants: TWSRolesVariants[] = [
  {
    value: 'wsrole:employee',
    label: WSRolesEnum['wsrole:employee'],
  },
  {
    value: 'wsrole:guest',
    label: WSRolesEnum['wsrole:guest'],
  },
];

export const InviteMembers = observer(() => {
  const {
    members: { postInviteMember, getInviteHash, isLoading, members, invites },
    profileStore: { WSRole, workspaceName },
  } = useAppDataStore();

  const [isOpenInvite, setIsOpenInvite] = useState(false);

  // const [isCopied, setIsCopied] = useState<boolean>(false);

  const [wSRole, setWSRole] = useState<WSRole>('wsrole:employee');

  // const [invHash, setInvHash] = useState({
  //   hash: '',
  //   isError: false,
  //   isLoading: false,
  // });

  const email = useInput({
    initialValue: '',
    type: TypeInput.email,
    isRequired: true,
  });

  // eslint-disable-next-line
  const { setError: setEmailError, setHint, setWasChanged, checkValue, ...emailInput } = email;

  useEffect(() => {
    email.checkValue();
    if (members.filter(el => el.email === email.value).length) {
      setEmailError(true);
      setHint(InputEmailErrors.emailMemberExist);
    }
    if (invites.filter(el => el.email === email.value).length) {
      setEmailError(true);
      setHint(InputEmailErrors.inviteEmailExist);
    }
  }, [email]);

  useEffect(() => {
    email.onChange('');
    email.setError(false);
    email.setHint('');
  }, [isOpenInvite]);

  // useEffect(() => {
  //   setIsCopied(false);
  //   setInvHash(invHash => ({ ...invHash, hash: `${new URL(window.location.href).origin}/invite/`, isLoading: true }));
  //   isOpenInvite &&
  //     setTimeout(() => {
  //       getInviteHash({ role: wSRole })
  //         .then(res => {
  //           setInvHash(invHash => ({
  //             ...invHash,
  //             hash: invHash.hash + res.hash,
  //           }));
  //         })
  //         .catch(() => {
  //           setInvHash(invHash => ({ ...invHash, isError: true }));
  //         })
  //         .finally(() => {
  //           setInvHash(invHash => ({ ...invHash, isLoading: false }));
  //         });
  //     }, 500);
  // }, [isOpenInvite, wSRole]);

  // const handleClick = () => {
  //   setIsCopied(true);
  //   setTimeout(() => {
  //     setIsCopied(false);
  //   }, 5000);
  //   navigator.clipboard.writeText(invHash.hash);
  // };

  return (
    <>
      <MainTooltip
        tooltipMessage={WSRole === 'wsrole:employee' && TooltipErrorRole.INVITE}
        isVisible={WSRole === 'wsrole:employee'}
        component="span"
        placement="bottom"
        followCursor
      >
        <Button
          variant="filled"
          size="small"
          color="primary"
          onClick={() => setIsOpenInvite(true)}
          disabled={WSRole === 'wsrole:employee'}
        >
          Пригласить
        </Button>
      </MainTooltip>

      <Modal
        open={isOpenInvite}
        closeModal={() => setIsOpenInvite(false)}
        workspaceHeader="invite"
        title={workspaceName || ''}
        secondAction={{ actionTitle: ModalActionNamesEnum.Cancel, action: () => setIsOpenInvite(false) }}
        firstAction={{
          actionTitle: ModalActionNamesEnum.Invite,
          isLoading: isLoading,
          action: () => {
            if (!email.value) {
              setEmailError(true);
              setHint(InputEmailErrors.noEmail);
            } else {
              !email.error && postInviteMember({ emails: [email.value], role: wSRole }, setIsOpenInvite);
            }
          },
        }}
      >
        <>
          <CustomSelect
            className={classes.customSelect}
            value={wSRole}
            onChange={setWSRole}
            listVariants={WSRolesVariants}
            label="Роль"
            menuHeight={144}
          />
          <span aria-data="inputEmail" className={clsx(classes.inviteBlock, classes.customSelect)}>
            <Typography
              componentProps={{ className: classes.marginTitle }}
              weight={600}
              size="Main"
              color="TextPrimary"
            >
              Пригласить по электронной почте
            </Typography>
            {/* TODO должен быть другой инпут, на несколько emails с бэйджами */}
            <Input
              {...emailInput}
              name="email"
              type="email"
              label="Адрес электронной почты"
              fullWidth
              autoComplete="off"
              className={ymDisableClass}
              size={48}
              disableMinHeight={true}
            />
          </span>
          {/* Блок со ссылкой-приглашением для безлимитного количества пользователей  
          <>
            <span className={classes.inviteBlock}>
              <Typography
                componentProps={{ className: classes.marginTitle }}
                weight={600}
                size="Main"
                color="TextPrimary"
              >
                Или отправьте ссылку–приглашение
              </Typography>

              <div className={clsx(classes.hashLink)}>
                <span className={clsx(classes.invHash, invHash.isLoading && classes.widthSkeleton)}>
                  {invHash.isLoading ? (
                    <>
                      <Skeleton size="maxLong" height={27} />
                      <Skeleton size="short" height={27} />
                    </>
                  ) : (
                    <div className={classes.hashBlock}>
                      <EllipsisTooltip tooltipMessage={invHash.hash}>
                        <Typography size="AuthContent" ellips={true}>
                          {invHash.isError ? 'Произошла ошибка при загрузке ссылки' : invHash.hash}
                        </Typography>
                      </EllipsisTooltip>
                      <div onClick={() => !isCopied && handleClick()}>
                        {isCopied ? (
                          <Typography
                            size="AuthContent"
                            color={isLoading ? 'TextTertiary' : 'Primary60'}
                            weight={600}
                            componentProps={{ className: classes.noIcon }}
                          >
                            Ссылка скопирована!
                          </Typography>
                        ) : (
                          <div className={classes.btnCopy}>
                            <Typography
                              size="AuthContent"
                              color={isLoading ? 'TextTertiary' : 'Primary60'}
                              weight={600}
                              componentProps={{ className: classes.withIcon }}
                            >
                              <CopyLinkIcon color="primary60" className={classes.icon} />
                              Скопировать ссылку
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </span>
              </div>
              <Typography
                componentProps={{ className: classes.marginText }}
                size="Caption"
                color={isLoading ? 'TextTertiary' : 'TextSecondary'}
              >
                {typografText(
                  'Ссылка позволит пригласить безлимитное количество пользователей и будет действовать 24 часа',
                )}
              </Typography>
            </span>
          </> */}
          <Lockscreen visible={isLoading} />
        </>
      </Modal>
    </>
  );
});
