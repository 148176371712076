import { Select, SelectChangeEvent, TextField } from '@mui/material';
import { ArrowsVerticale2Icon, SortDownSolidET, Typography } from '@plarin/design';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { IconButton } from '../../../buttons';
import { CustomMenuItemSelect } from '../../../custom-menu-item';
import { MainTooltip } from '../../../tooltip';
import commonClasses from '../common.module.scss';
import { setCalculatedBid, TNewFixBids, updateNumericInputStates } from '../utils';
import classes from './style.module.scss';

type TInputProps = {
  previousBids: TNewFixBids;
  setNewBids: React.Dispatch<React.SetStateAction<TNewFixBids>>;
  currencySymbol: string;
  maxBid: number;
  minBid: number;
  openMinMax: () => void;
};

const typeOfCalculation = [
  {
    value: 'Прибавить',
    label: 'Прибавить',
  },
  {
    value: 'Отнять',
    label: 'Отнять',
  },
];

// сделает, чтобы у ставки было не более 2 знаков после запятой
const normalizeBid = (newBid: number) => Math.round(newBid * 100) / 100;

export const BidValueRelativeInput = ({
  previousBids,
  setNewBids,
  currencySymbol,
  maxBid,
  minBid,
  openMinMax,
}: TInputProps) => {
  const [calculationType, setCalculationType] = useState('Прибавить');
  const [coefficientType, setCoefficientType] = useState('percent');
  const [numericBidCoefficient, setNumericBidCoefficient] = useState<string>('');

  const listOfCoefficientTypes = useMemo(() => {
    return [
      {
        value: 'percent',
        label: '%',
      },
      {
        value: 'currency',
        label: currencySymbol,
      },
    ];
  }, [currencySymbol]);

  // TODO: учитывать индивидуальные min и max конкретной кампании
  const calculateBid = (prevValue: number) => {
    let bid: number = prevValue;
    // сложение с абсолютной величиной
    if (calculationType === 'Прибавить' && coefficientType === 'currency') {
      bid = normalizeBid(prevValue + Number(numericBidCoefficient));
    }

    // вычитание абсолютной величны
    if (calculationType === 'Отнять' && coefficientType === 'currency') {
      bid = normalizeBid(prevValue - Number(numericBidCoefficient));
    }

    // сложение с процентом от ставки
    if (calculationType === 'Прибавить' && coefficientType === 'percent') {
      bid = normalizeBid(prevValue + (prevValue / 100) * Number(numericBidCoefficient));
    }

    // вычитание процента от ставки
    if (calculationType === 'Отнять' && coefficientType === 'percent') {
      bid = normalizeBid(prevValue - (prevValue / 100) * Number(numericBidCoefficient));
    }

    if (bid > maxBid) {
      return maxBid;
    }
    if (bid < minBid) {
      return minBid;
    } else return bid;
  };

  const handleCalculationTypeChange = (event: SelectChangeEvent) => {
    setCalculationType(event.target.value);
  };

  const handleCoefficientTypeChange = (event: SelectChangeEvent) => {
    setCoefficientType(event.target.value);
  };

  useEffect(() => {
    setNewBids(setCalculatedBid(previousBids, calculateBid));
  }, [calculationType, coefficientType, previousBids, numericBidCoefficient, maxBid, minBid]);

  return (
    <div className={classes.relativeBidChanger}>
      <Typography
        size="TableRowSubHeader"
        color="TextSecondary"
        componentProps={{ className: commonClasses.inputBidHeader }}
      >
        Новая ставка
      </Typography>

      <div className={classes.relativeWrap}>
        <div className={classes.relativeBidContainer}>
          <div className={classes.relativeCalcType}>
            <SelectBid value={calculationType} onChange={handleCalculationTypeChange} items={typeOfCalculation} />
          </div>

          <div className={classes.relativeCoeffValue}>
            <NumericFormat
              value={numericBidCoefficient}
              defaultValue={numericBidCoefficient}
              valueIsNumericString={true}
              allowNegative={false}
              customInput={TextField}
              InputProps={{
                classes: { input: commonClasses.inputInput, focused: commonClasses.inputFocused },
                className: clsx(commonClasses.inputWrap, commonClasses.inputNumericWrap),
              }}
              decimalSeparator=","
              thousandSeparator=" "
              decimalScale={2}
              onValueChange={values => updateNumericInputStates(values, setNumericBidCoefficient)}
              // когда из инпута всё удалили, values.floatValue возвращает undefined
              isAllowed={values => !(values.floatValue && values.floatValue > 9999999)}
            />
          </div>

          <div className={classes.relativeCoeffType}>
            <SelectBid value={coefficientType} onChange={handleCoefficientTypeChange} items={listOfCoefficientTypes} />
          </div>
        </div>

        <MainTooltip
          tooltipMessage="Установить мининимальную или максимальную ставку"
          isVisible={true}
          component="span"
          placement="bottom-end"
          componentFeature="textEllips"
          maxWidth={225}
        >
          <IconButton
            className={classes.showMinMaxButton}
            variant="filled"
            size="small"
            color="primary"
            onClick={openMinMax}
          >
            <ArrowsVerticale2Icon color="white" />
          </IconButton>
        </MainTooltip>
      </div>
    </div>
  );
};

const SelectBid = ({
  value,
  onChange,
  items,
}: {
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  items: {
    value: string;
    label: string;
  }[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Select
      className={classes.inputSelect}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      classes={{
        root: clsx(commonClasses.inputWrap, isOpen && commonClasses.inputFocused),
        select: commonClasses.inputInput,
      }}
      variant="outlined"
      value={value}
      onChange={onChange}
      IconComponent={SortDownSolidET}
      MenuProps={{
        className: commonClasses.menuOverlay,
        classes: { paper: commonClasses.menuPaper },
      }}
      inputProps={{
        classes: {
          icon: 'rotatable',
          iconOpen: 'deg180',
        },
      }}
    >
      {items.map((item, index) => (
        <CustomMenuItemSelect key={item.value} value={item.value} tabIndex={index} selectedItem={item.value === value}>
          {item.label}
        </CustomMenuItemSelect>
      ))}
    </Select>
  );
};
