import { Input } from '@plarin/inputs';
import { typografText } from '@plarin/utils';
import { observer } from 'mobx-react';
import React from 'react';
import { useAppDataStore } from '../../../components/app-data-provider';
import { ProfileBlockWrapper } from '../../../components/profile-block-wrapper';
import { ymDisableClass } from '../../../utils/constants';

const profilePasswordTitle = 'Пароль и защита';
const profilePasswordSubTitle =
  'Чтобы изменить пароль, сначала проверьте подлинность текущего. Затем создайте пароль, который вы не используете в других местах, и обязательно регулярно его меняйте. Если пароль не задан (вы авторизовались через социальные сети) - сперва задайте его.';

const PasswordBlock = observer(() => {
  const {
    profileStore: { data, setModalContentType, setModalTitle, openModal },
  } = useAppDataStore();

  const openModalWindowChange = () => {
    setModalContentType('changePassword');
    setModalTitle('Изменить пароль');
    openModal();
  };
  const openModalWindowAdd = () => {
    setModalContentType('addPassword');
    setModalTitle('Задать пароль для входа');
    openModal();
  };

  return (
    <Input
      autoComplete="off"
      value={data.password_set ? '• • • • • • • • • • • •' : 'Не задан'}
      onChange={() => {}}
      label="Текущий пароль"
      type="box"
      fullWidth
      actionTitle={data.password_set ? 'Изменить' : 'Задать'}
      action={data.password_set ? openModalWindowChange : openModalWindowAdd}
      className={ymDisableClass}
    />
  );
});

export const ProfilePassword = () => {
  return (
    <ProfileBlockWrapper title={profilePasswordTitle} subTitle={typografText(profilePasswordSubTitle)}>
      <div className="marginBottom24px">
        <PasswordBlock />
      </div>
    </ProfileBlockWrapper>
  );
};
