import { IconButton, Badge } from '@mui/material';
import { WorkflowItemIcon } from '@plarin/design';
import React from 'react';
import { WhiteCircle } from './white-circle';

import './unique-button.scss';

interface IUniqueButton {
  withBadge?: boolean;
  withCircle?: boolean;
  icon?: React.ReactNode;
}

export const UniqueButton: React.FC<IUniqueButton> = () => {
  return (
    <Badge
      variant="dot"
      color="primary"
      overlap="circular"
      classes={{
        colorPrimary: 'badgeColorPrimary',
      }}
    >
      <div className="uniqe-button">
        <IconButton classes={{ root: 'buttonRoot' }}>
          <WorkflowItemIcon />
          <WhiteCircle />
        </IconButton>
      </div>
    </Badge>
  );
};
