import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import classes from './customlist.module.scss';

export const CustomList = ({
  children,
  classProps,
  classContainer,
}: {
  children: JSX.Element;
  classProps?: string;
  classContainer?: string;
}) => {
  const [container, setContainer] = useState<null | HTMLDivElement>(null);
  const [content, setContent] = useState<null | HTMLDivElement>(null);
  const [scroll, setScroll] = useState<null | HTMLDivElement>(null);
  const [update, setUpdate] = useState<boolean>(false);

  const scrollingHeight = () => {
    if (content && scroll && container) {
      const hideScroll = content.scrollHeight <= Math.ceil(content.getBoundingClientRect().height);
      scroll.style.height = hideScroll ? '0px' : `${content.scrollHeight}px`;
      container.style.zIndex = hideScroll ? '0' : '1';
    }
  };

  // костыль для получения высоты содержимого контента по окончании анимации
  setTimeout(() => {
    scrollingHeight();
  }, 500);

  useEffect(() => {
    scrollingHeight();
  }, [content, scroll]);

  useEffect(() => {
    scrollingHeight();
    window.addEventListener('resize', scrollingHeight);
    return () => {
      window.removeEventListener('resize', scrollingHeight);
    };
  });

  // делает у двух элементов положение скролла одинаковым
  const scrollingTopSame = (a: HTMLDivElement | null, b: HTMLDivElement | null) => {
    if (a && b) {
      a.scrollTop = b.scrollTop;
    }
  };

  return (
    <div className={clsx(classes.container, classContainer)}>
      <div
        ref={el => setContainer(el)}
        className={classes.scrollbarContainer}
        onScroll={() => {
          scrollingTopSame(content, container);
        }}
      >
        <div ref={el => setScroll(el)} className={classes.scrollbar}></div>
      </div>
      <div
        onTransitionEnd={e => {
          if (e.propertyName === 'transform') {
            setUpdate(!update);
          }
        }}
        ref={el => setContent(el)}
        className={clsx(classes.content, classProps)}
        onScroll={() => {
          scrollingTopSame(container, content);
        }}
      >
        {children}
      </div>
    </div>
  );
};
