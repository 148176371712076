import { CustomSelect } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useAppDataStore } from '../../../../app-data-provider';
import classes from '../../project-drawer.module.scss';

export const ProjectStatus = observer(() => {
  const {
    projects: { projectsState, setProjectsState, editProject, setIsDrawerDataChanged },
  } = useAppDataStore();

  useEffect(() => {
    setIsDrawerDataChanged(false);
  }, []);

  useEffect(() => {
    editProject.editProjectData && setProjectsState('valuesProjectStatus', editProject.editProjectData?.status);
  }, [editProject, setProjectsState]);

  return (
    <div className={classes.inputProject}>
      <CustomSelect
        label="Статус"
        value={projectsState.valuesProjectStatus}
        onChange={el => {
          setProjectsState('valuesProjectStatus', el);
        }}
        listVariants={[
          { value: 'active', label: 'Активный' },
          { value: 'completed', label: 'Завершенный' },
        ]}
        menuHeight={144}
      />
    </div>
  );
});
