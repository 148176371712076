import { DownloadReportIcon, GridIcon, RedoIcon } from '@plarin/design';
import { ActionTableButton, manageYDTabs, Popover } from '@plarin/inputs';
import { ManageYDTabNameEnum } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useRef, useState } from 'react';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { TableGroupingYD } from '../../../../components/table-grouping';
import { ReportWidget } from '../components';
import classes from '../styles.module.scss';

export const AccountsActionBar = observer(() => {
  const {
    manageYD: { tableLoading, toggleDrawer, metricsYD, getYDStat },
  } = useAppDataStore();

  return (
    <div className={classes.actionContent}>
      <div className={classes.leftContainer} />
      <div className={classes.rightContainer}>
        {!!metricsYD?.length && <TableGroupingYD tab={manageYDTabs.ACCOUNTS} />}
        <ActionTableButton
          textTooltip={metricsYD?.length ? 'Настроить колонки' : 'Произошла ошибка'}
          action={metricsYD?.length ? toggleDrawer : () => {}}
          icon={<GridIcon />}
        />
        <ReportWidget tab={manageYDTabs.ACCOUNTS} />

        <ActionTableButton
          textTooltip="Обновить"
          action={() => getYDStat(manageYDTabs.ACCOUNTS)}
          loading={tableLoading}
          icon={<RedoIcon />}
          isReloadButton={true}
        />
      </div>
    </div>
  );
});
