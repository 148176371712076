import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { DefaultRenderer } from './default-renderer';

interface SumRendererProps extends ICellRendererParams {}

export const DefaultSumRenderer = ({ column, api }: SumRendererProps) => {
  let sum = 0;
  column &&
    api.forEachNodeAfterFilter(rowNode => {
      return (sum +=
        column && !!rowNode.data && !rowNode.data.orgHierarchy && rowNode.data[column.getColId()]
          ? +rowNode.data[column.getColId()]
          : 0);
    });
  return <DefaultRenderer value={sum} formatter={column?.getColDef().valueFormatter} />;
};

export const AdsSumRenderer = ({ column, api }: SumRendererProps) => {
  let sumActive = 0;
  let sumPaused = 0;
  let sumArchived = 0;
  column &&
    api.forEachNodeAfterFilter(rowNode => {
      sumActive += rowNode.data.adsActive;

      sumPaused += rowNode.data.adsPaused;

      sumArchived += rowNode.data.adsArchived;
    });
  const result = `${sumActive} / ${sumPaused} / ${sumArchived}`;
  return <DefaultRenderer value={result} />;
};

export const BidRenderer = ({ column, api }: SumRendererProps) => {
  let sum = 0;
  column &&
    api.forEachNodeAfterFilter(
      rowNode =>
        (sum +=
          column && !rowNode.data.orgHierarchy && parseInt(rowNode.data[column.getColId()])
            ? parseInt(rowNode.data[column.getColId()])
            : 0),
    );
  return <DefaultRenderer value={sum} formatter={column?.getColDef().valueFormatter} />;
};
