import { BasicFormStore, BasicFormStoreError, EApiCodes, TFormInitParams } from '@plarin/core';
import { TAlertProps } from '@plarin/inputs';
import { timeToSendReq } from '@plarin/utils';
import { AxiosError, AxiosResponse } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { components } from '../../../../types/profile/apispec';
import { getAlertProps, getApiCodeAxiosError } from '../../../utils/get-alert-props';

export type TConfirmPhoneData = {
  phone: string;
  code: string;
  captcha_token?: string;
};

export abstract class AConfirmPhoneStore extends BasicFormStore<TConfirmPhoneData> {
  alertProps?: TAlertProps;
  needCaptcha = false;
  needConfirm = false;

  protected constructor(initData: TFormInitParams<TConfirmPhoneData>) {
    super(initData);
    makeObservable<this>(this, {
      alertProps: observable,
      needCaptcha: observable,
      needConfirm: observable,
      cancel: action.bound,
      setCaptchaToken: action.bound,
      resetNeedConfirm: action.bound,
      onError: action.bound,
    });
  }

  abstract submit(e: any): void;

  abstract onSendCode(): void;

  abstract retryCode(): Promise<AxiosResponse<components['schemas']['ResponseModel_Dict_']>>;

  cancel = () => {
    this.needConfirm = false;
    this.error = undefined;
    this.alertProps = undefined;
  };

  setCaptchaToken = (token: string) => {
    this.data.captcha_token = token;
  };

  resetNeedConfirm = () => {
    this.needConfirm = false;
  };

  onError = (err: AxiosError<any>) => {
    const apiCode = getApiCodeAxiosError(err);
    if (apiCode === EApiCodes.NEED_CAPTCHA) {
      this.needCaptcha = true;
      this.setError('code', new BasicFormStoreError('Вы ввели много раз неправильно код. Введите код повторно.'));
    } else if (apiCode === EApiCodes.TWILIO_INVALID_TO_PHONE_NUMBER) {
      this.setError('phone', new BasicFormStoreError('Номер телефона введен не верно'));
    } else if (apiCode === EApiCodes.NOT_CONFIRMED) {
      this.setError('code', new BasicFormStoreError('Неверный код. Введите код повторно.'));
    } else if (apiCode === EApiCodes.TWILIO_ERROR_CODE || apiCode === EApiCodes.WRONG_CREDENTIALS) {
      this.setError('code', new BasicFormStoreError('Срок действия кода истек. Запросите новый код'));
    } else if (apiCode === EApiCodes.ALREADY_EXISTS) {
      this.alertProps = {
        title: 'Что то пошло не так.',
        content: 'Указанный номер мобильного телефона уже зарегистрирован',
      };
    } else if (
      apiCode === EApiCodes.THROTTLING_LIMIT_5_MIN ||
      apiCode === EApiCodes.THROTTLING_LIMIT_2_MIN ||
      apiCode === EApiCodes.THROTTLING_LIMIT_1_MIN
    ) {
      this.setError('code', new BasicFormStoreError('Вы превысили лимит отправки неправильных кодов'));
      this.alertProps = getAlertProps(err);
    } else {
      this.setError('code', new BasicFormStoreError(''));
      this.alertProps = getAlertProps(err);
    }

    err.response?.data.error.details?.next_request_allowed &&
      setTimeout(() => {
        this.setError('code');
        this.alertProps = undefined;
        this.data.code = '';
      }, timeToSendReq(err.response?.data.error.details?.next_request_allowed));
  };
}
