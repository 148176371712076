import { SmallArrowDownIcon, Typography } from '@plarin/design';
import { Button, Popover, DefaultInput } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { TeamAccessItemResp, TTeams } from '../../../../../types/teams/teams';
import { TTeamAccessItemResp, TWorkspaceDrawerData } from '../../../../../types/workspace/types';
import { filterReports, getSearchResults } from '../../../../utils/searchList';
import { useAppDataStore } from '../../../app-data-provider';
import { canChange } from '../utils';
import classes from './style.module.scss';
import { TeamsList } from './teams-list';

type TAddTeamsList = {
  addTeam: (team: TTeamAccessItemResp) => void;
  memberTeams: TeamAccessItemResp[];
};

export const AddTeamsList = observer(({ addTeam, memberTeams }: TAddTeamsList) => {
  const {
    teams: { teamsData },
    profileStore: { WSRole },
  } = useAppDataStore();

  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);

  const [searchValue, setSearchValue] = useState('');
  const [teams, setTeams] = useState<TTeams[]>([]);
  const [rendererList, setRenderList] = useState<TWorkspaceDrawerData['userData']['teams']>([]);

  const open = Boolean(anchorEl);
  const isChange = canChange(WSRole);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSearchValue('');
  };

  useEffect(() => {
    teamsData && setTeams(teamsData.filter(el => !memberTeams.find(team => team._id === el._id)));
  }, [teamsData, memberTeams]);

  useEffect(() => {
    searchValue && getSearchResults(searchValue, filterReports, setRenderList, teams);
  }, [searchValue, teams]);

  return (
    <>
      <div className={classes.actionTextBlock}>
        <div className={classes.clientsTotal}>
          {isChange && !!teamsData.length && (
            <span className={classes.rightBlock} onClick={handleClick}>
              <Typography size="AuthContent" weight={600} color="Primary60">
                Добавить в команду
              </Typography>
              <Button className={classes.button} variant="filled">
                <SmallArrowDownIcon className={clsx('rotatable', anchorEl && 'deg180')} color="primary60" />
              </Button>
            </span>
          )}
        </div>
      </div>
      <Popover
        isListLeft
        isOpen={open}
        anchorEl={anchorEl}
        setIsOpen={handleClosePopover}
        onEscape={handleClosePopover}
      >
        <div className={classes.allTeamsList}>
          <div className={classes.search}>
            <DefaultInput
              value={searchValue}
              setValue={setSearchValue}
              placeholder="Поиск команды"
              searchIcon
              clearInputAction={() => setSearchValue('')}
            />
          </div>
          {/* @ts-ignore */}
          <TeamsList addTeam={addTeam} rendererList={searchValue ? rendererList : teams} searchValue={searchValue} />
        </div>
      </Popover>
    </>
  );
});
