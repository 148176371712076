import { DeleteIcon, PauseIcon, PlayIcon, SettingsIcon } from '@plarin/design';
import { GridApi, Popover } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { TGAStoreData } from '../../../../types/connect-ga/types';
import { TYMStoreData } from '../../../../types/connect-ym/types';
import { useAppDataStore } from '../../app-data-provider';
import classes from './style.module.scss';

const OptionsName = {
  PLAY: 'Включить интеграцию',
  PAUSE: 'Выключить интеграцию',
  REMOVE: 'Отключить аккаунт и удалить все данные',
};

type SettingsCellProps = {
  // TODO тип дата для YD
  data: TYMStoreData | TGAStoreData;
  page: 'ym' | 'ga' | 'yd';
  gridApi: GridApi;
};

export const SettingsCellMenu = observer(({ data, page, gridApi }: SettingsCellProps) => {
  // eslint-disable-next-line
  const { _id, header1, plarin_status: status } = data;
  const {
    integrationsYM: { setModalData: setModalDataYM, enableYM },
    integrationsGA: { setModalData: setModalDataGA, enableGA },
    integrationsYD: { setModalData: setModalDataYD, enableYD },
  } = useAppDataStore();

  const YMoptions = [
    {
      name: status === 'enabled' ? OptionsName.PAUSE : OptionsName.PLAY,
      iconLeft: () => (status === 'enabled' ? <PauseIcon /> : <PlayIcon />),
      action: () => {
        status === 'enabled'
          ? setModalDataYM({
              type: 'pause',
              name: [header1],
              ids: [],
            })
          : enableYM({ db_ids: [_id] }, gridApi);
        setAnchorEl(null);
      },
    },
    {
      name: OptionsName.REMOVE,
      iconLeft: () => <DeleteIcon />,
      action: () => {
        setModalDataYM({
          type: 'remove',
          name: [header1],
          ids: [_id],
        });
        setAnchorEl(null);
      },
    },
  ];

  const GAoptions = [
    {
      name: status === 'enabled' ? OptionsName.PAUSE : OptionsName.PLAY,
      iconLeft: () => (status === 'enabled' ? <PauseIcon /> : <PlayIcon />),
      action: () => {
        status === 'enabled'
          ? setModalDataGA({ type: 'pause', name: [header1], ids: [_id] })
          : enableGA({ db_ids: [_id] }, gridApi);
        setAnchorEl(null);
      },
    },
    {
      name: OptionsName.REMOVE,
      iconLeft: () => <DeleteIcon />,
      action: () => {
        setModalDataGA({ type: 'remove', name: [header1], ids: [_id] });
        setAnchorEl(null);
      },
    },
  ];

  const YDoptions = [
    {
      name: status === 'enabled' ? OptionsName.PAUSE : OptionsName.PLAY,
      iconLeft: () => (status === 'enabled' ? <PauseIcon /> : <PlayIcon />),
      action: () => {
        status === 'enabled'
          ? // @ts-ignore
            setModalDataYD({ type: 'pause', name: [header1], ids: [_id] })
          : // @ts-ignore
            enableYD({ db_ids: [_id] }, gridApi);
        setAnchorEl(null);
      },
    },
    {
      name: OptionsName.REMOVE,
      iconLeft: () => <DeleteIcon />,
      action: () => {
        // @ts-ignore
        setModalDataYD({ type: 'remove', name: [header1], ids: [_id] });
        setAnchorEl(null);
      },
    },
  ];

  const options = useMemo(() => {
    switch (page) {
      case 'ym':
        return YMoptions;
      case 'ga':
        return GAoptions;
      case 'yd':
        return YDoptions;
    }
  }, [status, page]);

  // //////////////////////////

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const onOpen = useCallback((e: MouseEvent, ref: HTMLDivElement) => {
    e.stopPropagation();
    setAnchorEl(ref);
  }, []);

  const onClose = useCallback((e: React.MouseEvent | Event) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  return (
    <>
      <div
        ref={ref => {
          if (!ref) return;
          ref.onclick = e => onOpen(e, ref);
        }}
        className={clsx(classes.settingsIcon, !!anchorEl && classes.forceVisibility)}
      >
        <SettingsIcon size={16} color="secondary" />
      </div>

      <Popover anchorEl={anchorEl} isOpen={!!anchorEl} setIsOpen={onClose} dataList={options} />
    </>
  );
});
