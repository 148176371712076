import { SetProfilePasswordContent } from '@plarin/inputs';
import { observer } from 'mobx-react';
import { useAppDataStore } from '../../../../components/app-data-provider';

export const ChangePasswordContent = observer(() => {
  const {
    profileStore: {
      setModalAction,
      changeProfilePassword,
      newPasswordStyle,
      setNewPasswordStyle,
      isLoading,
      setGlobalError,
      globalError,
      checkNewPassword,
    },
  } = useAppDataStore();

  return SetProfilePasswordContent({
    setModalAction: setModalAction,
    changeProfilePassword: changeProfilePassword,
    newPasswordStyle: newPasswordStyle,
    setNewPasswordStyle: setNewPasswordStyle,
    isLoading: isLoading,
    setGlobalError: setGlobalError,
    globalError: globalError,
    checkNewPassword: checkNewPassword,
  });
});
