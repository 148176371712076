import { CustomList } from '@plarin/inputs';
import React from 'react';
import { TGuests } from '../../../../../../../../types/projects/projects';
import classes from '../../styles.module.scss';
import { GuestsListItem } from './guests-list-item';

type TProjectGuestsList = {
  guestsListToRender: TGuests[] | null;
  changeGuestsSelection: (team: TGuests) => void;
  isFoundItem: boolean;
  searchValue?: string;
};

export const ProjectGuestsList = ({
  guestsListToRender,
  changeGuestsSelection,
  isFoundItem,
  searchValue,
}: TProjectGuestsList) => {
  return (
    <CustomList classProps={classes.listRoot}>
      <>
        {guestsListToRender?.map(guest => (
          <GuestsListItem
            guest={guest}
            changeGuestsSelection={changeGuestsSelection}
            isFoundItem={isFoundItem}
            key={guest.id}
            searchValue={searchValue}
          />
        ))}
      </>
    </CustomList>
  );
};
