import { Drawer } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useAppDataStore } from '../../app-data-provider';
import { MembersPreloader } from '../preloaders/preloaders';
import { ContentDrawer } from './content-drawer';
import { FooterDrawer } from './footer-drawer';
import classes from './style.module.scss';
import { TitleDrawer } from './title-drawer';
import { canChange } from './utils';

export const EditMemberDrawer = observer(() => {
  const {
    profileStore: { WSRole },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    members: {
      editDataMember,
      userId,
      members,
      setUserId,
      drawerData,
      getDrawerData,
      isDrawerDataChanged,
      openModalMember,
      setIsDrawerDataChanged,
    },
    teams: { getTeams },
  } = useAppDataStore();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      getTeams();
      getDrawerData(userId).finally(() => setTimeout(() => setIsLoading(false), 350));
    }
  }, [userId]);

  const closeHandler = () => {
    if (isDrawerDataChanged && drawerData && userId) {
      openModalMember('saveExit', userId);
    } else {
      setUserId('');
    }
  };
  const isChange = canChange(WSRole);
  const preloadUserData = members.find(m => m._id === userId);

  return (
    <Drawer
      classWrapHead={classes.wrapHead}
      isOpen={!!userId}
      closeDrawer={closeHandler}
      workspaceDrawer
      preloader={<MembersPreloader />}
      loading={isLoading}
      isDrawerDataChanged={isDrawerDataChanged}
      title={
        isLoading ? (
          <TitleDrawer member={preloadUserData || drawerData.userData} />
        ) : (
          <TitleDrawer member={drawerData.userData} />
        )
      }
      actionBlock={
        isChange ? (
          <FooterDrawer
            action={() => editDataMember(userId!, drawerData.userData)}
            secondAction={() => {
              setUserId('');
              setIsDrawerDataChanged(false);
            }}
            status={drawerData.userData.status}
            idMember={userId || ''}
            role={drawerData.userData.role}
          />
        ) : null
      }
    >
      <ContentDrawer />
    </Drawer>
  );
});
