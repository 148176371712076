import { EmptyTableFindIcon, EmptyTableNoCompany, Typography } from '@plarin/design';
import { Button } from '@plarin/inputs';
import { typografText } from '@plarin/utils';
import React from 'react';
import classes from './styles.module.scss';

export const NoReportsError = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className={classes.noReports}>
      <EmptyTableNoCompany />
      <div className={classes.text}>
        <Typography size="AuthSubheader" color="TextSecondary" align="center">
          У вас еще нет ни одного отчёта. <br />
          Cоздайте свой первый отчёт сейчас.
        </Typography>
      </div>
      <div>
        <Button size="medium" variant="filled" color="primary" onClick={onClick}>
          Создать отчёт
        </Button>
      </div>
    </div>
  );
};

export const FetchReportsError = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className={classes.noReports}>
      <EmptyTableFindIcon />
      <div className={classes.text}>
        <Typography size="AuthSubheader" color="TextSecondary" align="center">
          Ошибка загрузки данных,
          <br /> пожалуйста, попробуйте позднее.
        </Typography>
      </div>
      <div className={classes.refresh} onClick={onClick}>
        <Typography size="AuthSubheader" color="LinkPrimary" weight={700}>
          Обновить
        </Typography>
      </div>
    </div>
  );
};

export const ReportsNoSearch = () => {
  return (
    <div className={classes.noReports}>
      <EmptyTableFindIcon />
      <div className={classes.text}>
        <Typography size="AuthSubheader" color="TextSecondary" align="center">
          {typografText('По вашему запросу ничего не найдено. Попробуйте ввести другой запрос.')}
        </Typography>
      </div>
    </div>
  );
};

<div className={classes.error}></div>;
