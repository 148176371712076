import { Link } from '@plarin/core';
import { Typography } from '@plarin/design';
import React from 'react';
import { ProfileBlockWrapper } from '../../../components/profile-block-wrapper';
import { routerPaths } from '../../../routing/router-path';
import { LegalDocuments } from './../../../enums';
import classes from './style.module.scss';

const legalInformationTitle = 'Правовая информация';

export const LegalInformation = () => {
  return (
    <ProfileBlockWrapper title={legalInformationTitle}>
      <div className={classes.content}>
        <Typography
          color="linkPrimary"
          component="a"
          componentProps={{ href: LegalDocuments.LICENSE_AGREEMENT, target: '_blank' }}
        >
          &bull; Лицензионный договор
        </Typography>
        <br />
        <Typography
          color="linkPrimary"
          component="a"
          componentProps={{ href: LegalDocuments.PERSONAL_DATA_PROCESSING_CONSENT, target: '_blank' }}
        >
          &bull; Согласие на обработку персональных данных
        </Typography>
        <br />
        <Typography
          color="linkPrimary"
          component="a"
          componentProps={{ href: LegalDocuments.INFORMATION_MESSAGES_CONSENT, target: '_blank' }}
        >
          &bull; Согласие на получение информационных сообщений
        </Typography>
      </div>
    </ProfileBlockWrapper>
  );
};
