import React from 'react';
import './auth-layout.scss';

export const AuthLayoutTemplate: React.FC<{ sidebar: React.ReactNode }> = ({ children, sidebar }) => (
  <div className="authLayoutWrapper">
    <div className="authLayout">
      <main>{children}</main>
      <aside>{sidebar}</aside>
    </div>
  </div>
);
