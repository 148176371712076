import { TypeInput, useDebounce, useInput } from '@plarin/utils';
import React, { useEffect } from 'react';
import { InputPassword } from '../input-password';
import { TSetModalAction, ModalActionNamesEnum } from '../modal';
import { TypeNewPasswordComplexityStyle, TypePasswordData } from './types';

type TSetPassword = {
  public_id: string;
  setModalAction: TSetModalAction;
  setNewPassword: (password: string, id: string) => Promise<void>;
  isLoading: boolean | undefined;
  setGlobalError: (error?: string | undefined) => string | undefined;
  globalError: string | undefined;
  newPasswordStyle: TypeNewPasswordComplexityStyle;
  checkNewPassword: (data: TypePasswordData) => Promise<void>;
  setNewPasswordStyle: (style?: TypeNewPasswordComplexityStyle | undefined) => TypeNewPasswordComplexityStyle;
};

// форма для изменения пароля, не требующая введения старого пароля. Используется в бэкофисе администратором при изменении паролей сотрудников и клиентов
export const SetPasswordContent = ({
  public_id,
  setModalAction,
  setNewPassword,
  isLoading,
  setGlobalError,
  globalError,
  newPasswordStyle,
  checkNewPassword,
  setNewPasswordStyle,
}: TSetPassword) => {
  const password = useInput({
    initialValue: '',
    type: TypeInput.newPassword,
  });
  // eslint-disable-next-line
  const {
    setError: setPasswordError,
    setHint,
    setTextError: setTextPasswordError,
    setWasChanged,
    checkValue: passwordCheckValue,
    ...passwordInput
  } = password;
  const debounce = useDebounce();
  const setAction = () => {
    setModalAction({
      action: () => {
        if (!password.value || password.error) {
          !password.value && setPasswordError(true);
        } else {
          setNewPassword(password.value, public_id);
        }
      },
      actionTitle: isLoading ? ModalActionNamesEnum.Change : ModalActionNamesEnum.Save,
    });
  };

  useEffect(() => {
    passwordCheckValue();
    setAction();
  });

  useEffect(() => {
    setTextPasswordError && (globalError ? setTextPasswordError(globalError) : setTextPasswordError(''));
  }, [globalError, setTextPasswordError]);

  useEffect(() => {
    if (password.value.length > 7 && !password.error) {
      debounce(() => checkNewPassword({ password: password.value }), 300);
    } else {
      setNewPasswordStyle(undefined);
    }
  }, [checkNewPassword, password.error, password.value, setNewPasswordStyle]);

  useEffect(() => {
    setGlobalError('');
  }, [password.value, setGlobalError]);

  return (
    <>
      <InputPassword
        {...passwordInput}
        passwordComplexity={password.value.length > 7 ? newPasswordStyle : undefined}
        name="newPassword"
        label="Новый пароль"
        type="password"
        autoFocus
        fullWidth
        autoComplete="new-password"
      />
    </>
  );
};
