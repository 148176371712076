import { RedoIcon, Typography } from '@plarin/design';
import { ActionTableButton, Button, Switch } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useAppDataStore } from '../../components/app-data-provider';
import classes from './style.module.scss';

type TProjectsActionBar = {
  hasAdminRights: boolean;
  onOpen: () => void;
  onChangeCompleted: (checked: boolean) => void;
  onChangeArchived: (checked: boolean) => void;
};

export const ProjectsActionBar = observer(
  ({ hasAdminRights, onOpen, onChangeCompleted, onChangeArchived }: TProjectsActionBar) => {
    const {
      projects: { getProjects, isLoading, isTableLoading },
    } = useAppDataStore();

    const [isArchiveShown, setIsArchiveShown] = useState(false);
    const [isFinishedShown, setIsFinishedShown] = useState(false);

    return (
      <div className={classes.tableWrap}>
        <div className={classes.actionPanel}>
          <div className={classes.leftButtons}>
            <Button variant="filled" size="small" color="primary" onClick={onOpen} disabled={!hasAdminRights}>
              Создать
            </Button>
          </div>

          <div className={classes.rightButtons}>
            <div className={classes.archiveSwitcher}>
              {/* TODO: стилизовать свитчер, сделать поменьше. Переиспользуется в других местах */}
              <Switch
                className={classes.switcher}
                checked={isFinishedShown}
                onChange={() => {
                  setIsFinishedShown(!isFinishedShown);
                  onChangeCompleted(!isFinishedShown);
                }}
              />
              <Typography size="Caption" weight={400} color="TextPrimary">
                Завершенные
              </Typography>
            </div>

            <div className={classes.archiveSwitcher}>
              {/* TODO: стилизовать свитчер, сделать поменьше. Переиспользуется в других местах */}
              <Switch
                className={classes.switcher}
                checked={isArchiveShown}
                onChange={() => {
                  setIsArchiveShown(!isArchiveShown);
                  onChangeArchived(!isArchiveShown);
                }}
              />
              <Typography size="Caption" weight={400} color="TextPrimary">
                Архивные
              </Typography>
            </div>
            <ActionTableButton
              textTooltip="Обновить"
              action={getProjects}
              loading={isLoading || isTableLoading}
              icon={<RedoIcon />}
              className={classes.reloadButton}
              isReloadButton={true}
            />
          </div>
        </div>
      </div>
    );
  },
);
