import { Typography } from '@plarin/design';
import React from 'react';
import classes from './style.module.scss';

export const DifferentTypeBid = () => {
  return (
    <div className={classes.viewBox}>
      <Typography size="AuthContent">Выбраны рекламные кампании с разным типом ставки</Typography>
      <Typography size="AuthContent">
        Для изменения ставки, пожалуйста, выберите рекламные кампании с одним типом ставки
      </Typography>
    </div>
  );
};

export const DifferentCurrency = () => {
  return (
    <div className={classes.viewBox}>
      <Typography size="AuthContent">Выбраны рекламные кампании с разной валютой.</Typography>
      <Typography size="AuthContent">
        Для изменения ставки, пожалуйста, выберите рекламные кампании с одной валютой
      </Typography>
    </div>
  );
};

export const ArchiveCampaign = () => {
  return (
    <div className={classes.viewBox}>
      <Typography size="AuthContent">
        Среди выбранных кампаний есть архивные. <br />
        Ставка не может быть отредактирована.
      </Typography>
    </div>
  );
};

export const StrategyTypesIncompatible = () => {
  return (
    <div className={classes.viewBox}>
      <Typography size="AuthContent">
        Выбраны рекламные кампании с разным типом целевого действия
        <br />
        <br />
        Для изменения ставки, пожалуйста, выберите рекламные кампании с одним типом целевого действия
      </Typography>
    </div>
  );
};

export const HasDifferentBudgetOptimizationTypes = () => {
  return (
    <div className={classes.viewBox}>
      <Typography size="AuthContent">
        Выбраны рекламные объекты с разными типами оптимизации лимита бюджета (на уровне кампании и на уровне группы
        объявлений)
        <br />
        <br />
        Для изменения стратегии, пожалуйста, выберите рекламные объекты с одним типом оптимизации лимита бюджета
      </Typography>
    </div>
  );
};

export const AdPlanHasAdGroupBudgetOptimization = () => {
  return (
    <div className={classes.viewBox}>
      <Typography size="AuthContent">
        Выбрана кампания, для которой оптимизация лимита бюджета включена на уровне групп объявлений.
        <br />
        <br />
        Чтобы изменить лимиты бюджета и/или предельную стоимость, перейдите во вкладку "Группы объявлений" или
        "Объявления".
      </Typography>
    </div>
  );
};
