import { SmallArrowDownIcon, Typography } from '@plarin/design';
import { Button, CustomList, EllipsisTooltip, Popover } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { useAppDataStore } from '../../app-data-provider';
import { MenuFooterWorkspace } from './menu-footer-workspace';
import { MenuItemWorkspace } from './menu-item-workspace';
import classes from './style.module.scss';

export const MenuWorkspace = observer(() => {
  const {
    profileStore: { workspaceName, workspaces = [] },
  } = useAppDataStore();

  const [isOpen, setOpen] = useState(false);

  const anchorRef = useRef(null);

  return (
    <>
      <div ref={anchorRef} className={classes.workspaceMenuWrapper}>
        <Button
          className={clsx(classes.workspaceButton, isOpen && classes.colorActive)}
          size="small"
          color="midle"
          onClick={() => setOpen(true)}
        >
          <div className={classes.contentButtoon}>
            <EllipsisTooltip classNameTooltip={classes.ellipsisWidthButton} tooltipMessage={workspaceName}>
              <Typography
                component="div"
                color="TextPrimary"
                weight={400}
                size="AuthContent"
                align="center"
                ellips={true}
              >
                {workspaceName || '<Не выбрано>'}
              </Typography>
            </EllipsisTooltip>
            <SmallArrowDownIcon className={clsx('rotatable', isOpen && 'deg180')} />
          </div>
        </Button>
      </div>
      <Popover
        zIndex={997}
        isListLeft
        classNameWrapper={classes.menuAlignment}
        setIsOpen={() => setOpen(false)}
        anchorEl={anchorRef.current}
        isOpen={isOpen}
      >
        <>
          <div className={clsx(classes.workspaceContent, 'autoHeightAnchor')}>
            {workspaces?.length > 1 && (
              <Typography size="Caption" color="TextSecondary" componentProps={{ className: classes.workspaceTitle }}>
                Выбрать другое пространство
              </Typography>
            )}
            <div className={classes.line}>
              <CustomList classProps={classes.workspaceListWrapper}>
                <>
                  {workspaces &&
                    workspaces.map(el => {
                      return (
                        <MenuItemWorkspace
                          key={el.workspace_id}
                          isSelect={el.selected}
                          onClose={() => setOpen(false)}
                          workspaceName={el.name || '<Без названия>'}
                          workspaceId={el.workspace_id}
                          worksSpaceShortName={el.shortname}
                        />
                      );
                    })}
                </>
              </CustomList>
            </div>
            <MenuFooterWorkspace setOpen={setOpen} />
          </div>
        </>
      </Popover>
    </>
  );
});
