import { IconStatus } from '@plarin/design';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { HightLightText } from '../../../hightlight-text';
import { SkeletonTableBlock } from '../../../preloader';
import classes from './style.module.scss';
import clsx from 'clsx';
import { StatusColorEnum } from './utils';
// import { valueIconStatus, valueStatus, valueTranslation } from './utils';

type TTabName = 'groupName' | 'campaignName' | 'adId';

export const TranslationRendererYd = (props: ICellRendererParams & { tabName: TTabName }) => {
  const { data } = props;
  const { statusColor, statusText, translationText, cellLoadingName } = data;

  // @ts-ignore
  const filterValue = props.api.filterManager.quickFilter;

  if (cellLoadingName === 'status') return <SkeletonTableBlock withCheckbox isReversLong height={17} />;

  return (
    <>
      <div className={classes.translationContainer}>
        {<IconStatus status={statusColor} />}

        <span className={clsx(classes.statusText)}>
          <HightLightText text={statusText} filterValue={filterValue} />
        </span>
      </div>
      <div className={clsx(classes.stateTranslation, statusColor === StatusColorEnum.ARCHIVED && classes.archivedText)}>
        <HightLightText
          // color="TextSecondary"
          size="TableRowSubHeader"
          text={translationText}
          filterValue={filterValue}
        />
      </div>
    </>
  );
};
