import { Typography } from '@plarin/design';
import { ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import React from 'react';
import { EllipsisTooltip } from '../../../tooltip';
import classes from './style.module.scss';

export const LinkCellRenderer = (props: ICellRendererParams) => {
  const { data } = props;
  const isArchived = data.statusColor === 'archived';

  return (
    <a href={data.adUrl} target="_blank" rel="noopener noreferrer" className={clsx(classes.link)}>
      <EllipsisTooltip tooltipMessage={data.adUrl}>
        <Typography
          size="FontSizeCaption"
          color={isArchived ? 'TextSecondary' : 'LinkPrimary'}
          componentProps={{ className: classes.linkText }}
          ellips
        >
          {data.adUrl}
        </Typography>
      </EllipsisTooltip>
    </a>
  );
};
