import { Typography } from '@plarin/design';
import { ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import React from 'react';
import { HightLightText } from '../../../hightlight-text';
import { EllipsisTooltip } from '../../../tooltip';
import classes from './style.module.scss';

type GroupCellRendererParams = {
  first: string;
  page: string;
  isNoneCounter: boolean;
  classNames?: string;
  SettingsCellMenu: (data: any) => JSX.Element;
} & ICellRendererParams;

export const GroupNameRenderer = ({ SettingsCellMenu, classNames, ...props }: GroupCellRendererParams) => {
  // @ts-ignore
  const filterValue = props.api.filterManager.quickFilter;

  return (
    <div className={clsx(classes.groupNameWrapper, classNames)}>
      <EllipsisTooltip tooltipMessage={props.data[props.first]}>
        <HightLightText
          weight={600}
          text={props.data[props.first] || 'Не указано'}
          filterValue={filterValue}
          textEllips={true}
        />
      </EllipsisTooltip>
      {!props.isNoneCounter && (
        <Typography componentProps={{ className: classes.groupName }} color="TextSecondary">
          ({Object.keys(props.node.childrenAfterFilter || {}).length})
        </Typography>
      )}
      {SettingsCellMenu && <SettingsCellMenu data={props?.data} page={props.page} gridApi={props.api} />}
    </div>
  );
};
