import {
  AdPlanHasAdGroupBudgetOptimization,
  DifferentCurrency,
  DifferentTypeBid,
  EditBidFormTypes,
  FixedEditBid,
  getFormType,
  getItemsType,
  GridApi,
  HasDifferentBudgetOptimizationTypes,
  itemHasAdGroupBudgetOptimization,
  itemsHasDifferentBudgetOptimizationTypes,
  strategyBidTypesAreDifferent,
  StrategyEditBid,
  strategyObjectivesAreDifferent,
  StrategyTypesIncompatible,
  TRows,
  WhatIsEditedInStrategy,
} from '@plarin/inputs';
import { isUrlProd } from '@plarin/utils';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { useAppDataStore } from '../../app-data-provider';
import classes from './style.module.scss';

type EditBidProps = {
  close: () => void;
  selectedRows: TRows;
  gridApi: GridApi;
};

export const EditBid = observer(({ close, selectedRows, gridApi }: EditBidProps) => {
  // Я серьёзно, перестаньте удалять. Это нужно на деве.
  !isUrlProd && selectedRows.forEach(row => console.log(toJS(row)));

  const {
    manageVK: { getProjection, editFixedBid: editBid, editStrategyBid, getAvailableAds },
  } = useAppDataStore();

  const hasDifferentCurrency = selectedRows.some(val => val.accountCurrency !== selectedRows[0].accountCurrency);

  const isSingleBidType = (bidType: EditBidFormTypes): boolean =>
    selectedRows.every(val => getFormType(val) === bidType);

  if (!isSingleBidType('FixedEditBid') && !isSingleBidType('StrategyEditBid')) {
    return <DifferentTypeBid />;
  }

  // Только "Фиксированная ставка":
  if (isSingleBidType('FixedEditBid')) {
    if (selectedRows.length > 1 && hasDifferentCurrency) {
      return <DifferentCurrency />;
    }

    return (
      <FixedEditBid
        close={close}
        selectedRows={selectedRows}
        getProjection={getProjection}
        onSaveAction={editBid}
        gridApi={gridApi}
      />
    );
  }

  // Только "Стратегия":
  if (isSingleBidType('StrategyEditBid')) {
    if (strategyBidTypesAreDifferent(selectedRows) || strategyObjectivesAreDifferent(selectedRows)) {
      return <StrategyTypesIncompatible />;
    }

    if (itemsHasDifferentBudgetOptimizationTypes(selectedRows)) {
      return <HasDifferentBudgetOptimizationTypes />;
    }

    if (
      selectedRows.length === 1 &&
      getItemsType(window.location.pathname) === 'ad_plan' &&
      itemHasAdGroupBudgetOptimization(selectedRows)
    ) {
      return <AdPlanHasAdGroupBudgetOptimization />;
    }

    return (
      <StrategyEditBid
        selectedRows={selectedRows}
        close={close}
        onSaveAction={editStrategyBid}
        gridApi={gridApi}
        getAvailableAds={getAvailableAds}
        whatIsEdited={WhatIsEditedInStrategy.bid}
      />
    );
  }

  // непредвиденные исключения, либо с бэкенда пришли странные данные
  return <div className={classes.wrap}>Не получилось определить тип ставки.</div>;
});
