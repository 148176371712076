import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number | null, isActive: boolean): void => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };

    if (delay !== null && isActive) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, isActive]);
};
