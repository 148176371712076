import { Typography } from '@plarin/design';
import { Button, Checkbox, Input } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { ForgotPasswordEmailFormStore } from './forgot-password-email-form.store';
import './reset-form.scss';
import classes from '../registration/style.module.scss';
import { LegalDocuments } from './../../enums';

export type TResetByEmailFormProps = {
  store: ForgotPasswordEmailFormStore;
};

export const ResetByEmailForm: React.VFC<TResetByEmailFormProps> = observer(function ResetByEmailForm({ store }) {
  const { submit, data, errors, getErrorMsg, onChange, onBlur, isPending } = store;
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const [policyChecked, setPolicyChecked] = React.useState(true);

  const inputClearAction = () => {
    data.email = '';
  };

  const inputOnFocus = () => {
    setIsInputFocused(true);
  };

  const inputOnBlur = () => {
    setIsInputFocused(false);
    onBlur('email');
  };

  return (
    <form className="resetForm" onSubmit={submit}>
      <Typography color="TextPrimary" size="AuthContent">
        Ничего страшного! Мы отправим вам ссылку для смены пароля. Введите адрес электронной почты, на который
        зарегистрирован аккаунт Plarin.
      </Typography>
      <Input
        name="email"
        type="email"
        label="Электронная почта"
        value={data.email}
        error={!!errors.email}
        hint={getErrorMsg('email')}
        onChange={onChange('email')}
        onFocus={inputOnFocus}
        onBlur={inputOnBlur}
        isFocused={isInputFocused}
        action={inputClearAction}
        autoFocus
        fullWidth
        disabled={isPending}
        disableMinHeight={true}
        size={48}
      />
      <div className={classes.policy}>
        <Checkbox
          value={policyChecked}
          onChange={setPolicyChecked}
          size="small"
          className={classes.disableMarginLabel}
        />
        <Typography size="Caption" componentProps={{ className: classes.containerWidth }} color="TextSecondary">
          Я принимаю условия{' '}
          <Typography
            underline
            color="linkPrimary"
            component="a"
            componentProps={{ href: LegalDocuments.LICENSE_AGREEMENT, target: '_blank' }}
          >
            лицензионного договора
          </Typography>
          , даю{' '}
          <Typography
            underline
            color="linkPrimary"
            component="a"
            componentProps={{ href: LegalDocuments.PERSONAL_DATA_PROCESSING_CONSENT, target: '_blank' }}
          >
            согласие на обработку персональных данных
          </Typography>{' '}
          и
          <Typography
            underline
            color="linkPrimary"
            component="a"
            componentProps={{ href: LegalDocuments.INFORMATION_MESSAGES_CONSENT, target: '_blank' }}
          >
            {' '}
            согласие на получение информационных сообщений
          </Typography>
        </Typography>
      </div>
      {isPending ? (
        <Button variant="filled" size="medium" color="primary" loading={true} />
      ) : (
        <Button color="primary" variant="filled" size="medium" submit={true} disabled={!policyChecked}>
          Отправить ссылку для смены пароля
        </Button>
      )}
    </form>
  );
});
