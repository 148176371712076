import { RedoIcon } from '@plarin/design';
import { ActionTableButton, Button, GridApi, SelectButton } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { Cabinet } from '../../../../types/integration/types';
import { useAppDataStore } from '../../../components/app-data-provider';
import { SyncStatusEnum } from '../../../dictionary/integrations';
import classes from './style.module.scss';

type IntegrationsActionBarForCabinetsProps = {
  gridApi: GridApi;
  selectedRows?: Cabinet[];
};

export const IntegrationsActionBarForCabinets = observer(
  ({ gridApi, selectedRows = [] }: IntegrationsActionBarForCabinetsProps) => {
    const {
      integrationsVK: { fetchClients, openDrawer, isLoading, isTableLoading, enableCabinets, disableCabinets },
    } = useAppDataStore();

    const rowsForDisable = selectedRows.filter(item => item.syncStatusCode === 'enabled');

    const rowsForEnable = selectedRows.filter(item => item.syncStatusCode !== 'enabled');

    const setLoading = (rowsCabinets: Cabinet[]) => {
      gridApi.applyTransaction({ update: rowsCabinets.map(el => ({ ...el, syncStatusCode: SyncStatusEnum.loading })) });
    };

    const renderList = [
      {
        key: '1',
        label:
          rowsForEnable?.length === 1
            ? 'Включить синхронизацию данных'
            : `Включить синхронизацию данных (${rowsForEnable?.length})`,
        rowsCount: selectedRows?.filter(item => item.syncStatusCode !== 'enabled').length,
        action: () => {
          setLoading(rowsForEnable);
          setTimeout(() => {
            enableCabinets(rowsForEnable || []);
          }, 500);
        },
      },
      {
        key: '2',
        label:
          rowsForDisable?.length === 1
            ? 'Выключить синхронизацию данных'
            : `Выключить синхронизацию данных (${rowsForDisable?.length})`,
        rowsCount: selectedRows?.filter(item => item.syncStatusCode === 'enabled').length,
        action: () => {
          setLoading(rowsForDisable);
          setTimeout(() => {
            disableCabinets(rowsForDisable || []);
          }, 500);
        },
      },
    ].filter(item => item.rowsCount);

    // при нажатии на кнопку действие

    const onclick = useCallback(() => openDrawer(), [openDrawer]);

    return (
      <div className={classes.actionBar}>
        <div className={classes.connect}>
          <Button variant="filled" size="small" color="primary" onClick={onclick}>
            Подключить
          </Button>
          <div className={classes.select}>
            <SelectButton renderList={renderList} disabled={isTableLoading || (selectedRows && !selectedRows.length)} />
          </div>
        </div>

        <ActionTableButton
          textTooltip="Обновить"
          action={fetchClients}
          loading={isTableLoading}
          icon={<RedoIcon />}
          isReloadButton={true}
        />
      </div>
    );
  },
);
