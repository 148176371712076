import { ErrorIcon, Typography } from '@plarin/design';
import { Popover, MainTooltip } from '@plarin/inputs';
import { getNumeral, TooltipError } from '@plarin/utils';
import React from 'react';
import { TReportClient, TReportData } from '../../../../../types/reports/reports';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { responseReportAdapter } from '../../../../utils/reports';
import { PopupClients } from './reports-clients-list';
import classes from './styles.module.scss';

const checkIfCabinetsChanged = (prevClients: TReportClient[], newClients: TReportClient[]): boolean => {
  const prevC = prevClients
    .map(item => item.id)
    .sort()
    .join();
  const newC = newClients
    .map(item => item.id)
    .sort()
    .join();

  return prevC !== newC;
};

export const ReportsClients = ({ reportData }: { reportData: TReportData }) => {
  const {
    reports: { updateReport },
  } = useAppDataStore();

  const hasClientsError = reportData.clients.some(client => client.has_error) || !reportData.clients.length;
  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);

  const [selectedClients, setSelectedClients] = React.useState<TReportClient[]>(reportData.clients);

  const clientsTotal = `${reportData.clients.length} ${getNumeral(reportData.clients.length, [
    'кабинет',
    'кабинета',
    'кабинетов',
  ])}`;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    // если Popover закрывается с помощью клавиши esc, отменим все изменения и просто закроем поповер, без редактирования шаблона отчёта
    if (reason === 'escapeKeyDown') {
      setTimeout(() => setSelectedClients(reportData.clients), 1000);
      return setAnchorEl(null);
    }

    // запрос на обновление отчёта будет отправлен только если реально изменился список р.к. у шаблона отчёта
    if (checkIfCabinetsChanged(reportData.clients, selectedClients)) {
      updateReport(
        {
          ...responseReportAdapter({
            ...reportData,
            clients: selectedClients,
          }),
          id: reportData._id,
        },
        () => setSelectedClients(reportData.clients),
      );
    }

    setAnchorEl(null);
  };

  return (
    <div className={classes.actionTextBlock}>
      <Typography size="Caption" weight={600}>
        Рекламные кабинеты
      </Typography>
      <div className={classes.clientsTotal}>
        <div>
          <Typography
            size="Caption"
            color="LinkPrimary"
            componentProps={{
              className: classes.textAction,
              onClick: (e: React.MouseEvent<HTMLSpanElement>) => e.stopPropagation(),
            }}
          >
            <span onClick={handleClick}>{clientsTotal}</span>
            <Popover
              isListLeft
              classNameWrapper={classes.wrapperPopupRK}
              isOpen={open}
              anchorEl={anchorEl}
              onEscape={() => {
                handleClose({}, 'escapeKeyDown');
              }}
              setIsOpen={() => {
                handleClose({}, 'backdropClick');
              }}
            >
              <PopupClients selectedClients={selectedClients} setSelectedClients={setSelectedClients} />
            </Popover>
          </Typography>
        </div>

        {hasClientsError && (
          <MainTooltip
            tooltipMessage={
              reportData.clients.length ? TooltipError.SOME_CLIENTS_NOT_AVAILABLE : TooltipError.NO_REPORT_DOWNLOAD
            }
            component="span"
            isVisible={true}
            followCursor
          >
            <ErrorIcon color="status_error" />
          </MainTooltip>
        )}
      </div>
    </div>
  );
};
