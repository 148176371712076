import { Typography } from '@plarin/design';
import { Alert, Input, ReCaptchaV3 } from '@plarin/inputs';
import { maskedNumber } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import './auth-confirm-phone-form.scss';
import { AuthConfirmPhoneFormStore } from './auth-confirm-phone-form.store';
import { CancelButton, CheckingSmsCode, MissingSmsInfo, RetryButton, SmsRetryTimeout } from './components';
import { AConfirmPhoneStore } from './confirm-phone.store';

export type TAuthConfirmPhoneFormProps = {
  store: AConfirmPhoneStore;
};

export const AuthConfirmPhoneForm: React.VFC<TAuthConfirmPhoneFormProps> = observer(function AuthConfirmPhoneForm({
  store,
}) {
  const { data, onBlur, onChange, getErrorMsg, errors, needCaptcha, setCaptchaToken, cancel } = store;
  const [pageStore] = useState(() => new AuthConfirmPhoneFormStore(store));
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => pageStore.onUmount, [pageStore]);

  const { smsRetryTimeout, alertProps, locked, retry, isPending } = pageStore;
  const alert = alertProps || store.alertProps;

  const inputClearAction = () => {
    data.code = '';
  };

  const inputOnFocus = () => {
    setIsInputFocused(true);
  };

  const inputOnBlur = () => {
    setIsInputFocused(false);
    onBlur('code');
  };

  return (
    <div className="authConfirmPhoneForm">
      <div className="authConfirmPhoneForm__title">
        <Typography size="main" color="textPrimary" align="center">
          Мы отправили 6–значный код на номер {maskedNumber(data.phone)}
        </Typography>
      </div>
      <div className="authConfirmPhoneForm__input">
        <Input
          label="Введите код из СМС"
          type="number"
          value={data.code}
          error={!!errors.code}
          hint={getErrorMsg('code')}
          onChange={onChange('code')}
          onFocus={inputOnFocus}
          onBlur={inputOnBlur}
          isFocused={isInputFocused}
          action={inputClearAction}
          disabled={isPending || store.isPending || locked}
          fullWidth
          autoFocus
          maxLength={6}
        />
      </div>
      <div className="authConfirmPhoneForm__status">
        {alert ? (
          <Alert {...alert} />
        ) : (
          <>
            {isPending ? (
              <CheckingSmsCode>Проверяем код</CheckingSmsCode>
            ) : (
              <>
                {smsRetryTimeout === 0 && <RetryButton onClick={retry}>Отправить новый код</RetryButton>}
                {smsRetryTimeout > 0 && <SmsRetryTimeout seconds={smsRetryTimeout} />}
              </>
            )}
          </>
        )}
      </div>
      {needCaptcha && <ReCaptchaV3 siteKey={RECAPTCHA_SITE_KEY} onResponse={setCaptchaToken} />}
      <CancelButton onClick={cancel}>Отменить</CancelButton>
      <MissingSmsInfo>
        <Typography size="authContent">
          Проблемы могут быть на стороне вашего сотового оператора. Обратитесь в его службу поддержки
        </Typography>
      </MissingSmsInfo>
    </div>
  );
});
