import { SmallArrowDownIcon, Typography } from '@plarin/design';
import { Button } from '@plarin/inputs';
import clsx from 'clsx';
import React from 'react';
import classes from '../project-info.module.scss';

interface ICustomButtonText {
  onClickButton?: () => void;
  children: string;
  isOpen: boolean;
}

export const CustomButtonText = ({ isOpen, onClickButton, children }: ICustomButtonText) => {
  return (
    <Button onClick={onClickButton} className={classes.buttonAddForm}>
      <div className={classes.addItem}>
        <Typography color="TextButton" weight={600} size="AuthContent" align="center">
          {children}
        </Typography>
        <SmallArrowDownIcon className={clsx('rotatable', isOpen && 'deg180')} />
      </div>
    </Button>
  );
};
