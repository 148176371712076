import { RedoIcon, Typography } from '@plarin/design';
import {
  AlertIntegrationError,
  AlertIntegrationSuccess,
  Button,
  IntegrationsTableRow,
  SearchIntegrationTable,
  SkeletonIntegration,
  Switch,
  SwitcherTooltip,
} from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ConnectedAccount } from '../../../../types/integration/types';
import { SyncStatusEnum } from '../../../dictionary/integrations';
import { IntegrationsInfoBlock } from '../../account-info-block';
import { useAppDataStore } from '../../app-data-provider';
import { InfoBlockWithoutCabinets } from '../../info-block-without-cabinets';
import classes from './drawer-content-connect-result.module.scss';

const checkStatus = (status: string) =>
  status === SyncStatusEnum.enabled ? SyncStatusEnum.disabled : SyncStatusEnum.enabled;

export const DrawerContentConnectResult = observer(() => {
  const {
    integrationsVK: {
      accountData,
      network,
      errorAfterSuccess,
      setSomeAction,
      cabinetsStatusAction,
      setErrorAfterSuccess,
      getAccountDataByIdMT,
      // getAccountDataByIdVK,
      isDrawerClosable,
    },
  } = useAppDataStore();

  const [checkedAll, setCheckedAll] = useState(true);
  const [alertVisible, setAlertVisible] = useState(false);
  const [clientsData, setClientsData] = useState<ConnectedAccount>({ cabinets: [] } as unknown as ConnectedAccount);
  const [search, setSearch] = useState('');

  const retryGetClientInfo = useCallback(() => {
    setErrorAfterSuccess();
    network === 'mt' && getAccountDataByIdMT();
    // network === 'vk' && getAccountDataByIdVK();
    network === 'va' && getAccountDataByIdMT();
  }, [getAccountDataByIdMT, network, setErrorAfterSuccess]); // getAccountDataByIdVK,

  useEffect(() => {
    accountData?.cabinets && setClientsData({ ...accountData });
    accountData && setAlertVisible(true);
  }, [accountData]);

  useEffect(() => {
    setSomeAction(() => cabinetsStatusAction(clientsData.cabinets));
  }, [cabinetsStatusAction, clientsData.cabinets, setSomeAction]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isDrawerClosable) {
        setCheckedAll(event.target.checked);
        setClientsData(prev => {
          const newValueClients = prev.cabinets.map(item => ({
            ...item,
            syncStatus: event.target.checked ? SyncStatusEnum.enabled : SyncStatusEnum.disabled,
          }));
          return { ...prev, cabinets: [...newValueClients] };
        });
      }
    },
    [isDrawerClosable],
  );

  const changeRowStatus = useCallback(
    (key: string) => {
      setClientsData(prev => {
        const newCabinets = prev.cabinets.map(item =>
          item.key === key
            ? {
                ...item,
                syncStatus: checkStatus(item.syncStatus),
              }
            : item,
        );
        setCheckedAll(!newCabinets.find(item => item.syncStatus === SyncStatusEnum.disabled));
        return { ...prev, cabinets: newCabinets };
      });
    },
    [setClientsData],
  );

  const closeAlert = () => setAlertVisible(false);
  const closeError = () => setErrorAfterSuccess();

  const filterClientsData = useMemo(() => {
    return clientsData?.cabinets?.filter(
      ({ name, clientId }) =>
        name.toLowerCase().includes(search.toLowerCase()) || clientId.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, clientsData]);

  return (
    <div className={classes.drawerContent}>
      {alertVisible && (
        <div className={classes.drawerAlert}>
          <AlertIntegrationSuccess closeAlert={closeAlert} />
        </div>
      )}
      {errorAfterSuccess && (
        <div className={classes.drawerAlert}>
          <AlertIntegrationError
            titleText="Не удалось сохранить данные"
            closeAlert={closeError}
            errorText={`${errorAfterSuccess}`}
          />
        </div>
      )}
      {accountData ? (
        <>
          <div className={classes.drawerAccount}>
            <div className={classes.drawerInfoBlock}>
              <IntegrationsInfoBlock account={accountData} />
            </div>

            {!!clientsData.cabinets.length && (
              <>
                <Typography color="TextPrimary" size="LabelDr" weight={600}>
                  Включите рекламные кабинеты
                </Typography>
                <div className={classes.drawerTableHeader}>
                  {clientsData?.cabinets?.length > 10 && (
                    <div className={classes.search}>
                      <SearchIntegrationTable value={search} onChange={setSearch} deleteAction={() => setSearch('')} />
                    </div>
                  )}

                  {!!filterClientsData.length && !clientsData?.update_after_creation && (
                    <div className={classes.drawerTableLabel}>
                      <Typography color="TextPrimary" size="AuthContent" weight={600}>
                        Всего: {filterClientsData.length}
                      </Typography>
                      <SwitcherTooltip
                        content={
                          checkedAll ? (
                            <Typography color="TextWhite" size="AuthContent">
                              Выключить все рекламные кабинеты
                            </Typography>
                          ) : (
                            <Typography color="TextWhite" size="AuthContent">
                              Включить все рекламные кабинеты
                            </Typography>
                          )
                        }
                        component="span"
                        placement="bottom"
                      >
                        <Switch checked={checkedAll} onChange={handleChange} />
                      </SwitcherTooltip>
                    </div>
                  )}
                </div>
              </>
            )}

            <div>
              {clientsData?.update_after_creation ? (
                <SkeletonIntegration alertVisible={alertVisible} />
              ) : (
                <div className={classes.drawerIntegrations}>
                  {clientsData?.cabinets.length ? (
                    !filterClientsData.length ? (
                      <div className={classes.drawerNoFind}>
                        <Typography color="TextPrimary" size="IntegrationSelectChannelMessage" weight={600}>
                          По вашему запросу ничего не найдено
                        </Typography>
                        <Typography color="TextPrimary" size="Main">
                          Проверьте введенные данные
                        </Typography>
                      </div>
                    ) : (
                      filterClientsData.map(({ name, clientId, syncStatus, balance, key, currency }) => (
                        <IntegrationsTableRow
                          name={name}
                          id={clientId}
                          isEnable={syncStatus === SyncStatusEnum.enabled}
                          cost={balance}
                          keyId={key}
                          key={key}
                          onChange={changeRowStatus}
                          currency={currency}
                          searchFilter={search.toLowerCase()}
                        />
                      ))
                    )
                  ) : (
                    <InfoBlockWithoutCabinets network={network} />
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        clientsData?.cabinets && (
          <Button variant="outlined" onClick={retryGetClientInfo}>
            <div className={classes.buttonRetry}>
              <RedoIcon />
              Загрузить данные об аккаунте повторно
            </div>
          </Button>
        )
      )}
    </div>
  );
});
