import { Typography } from '@plarin/design';
import { ManageVkTabNameEnum } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import { BidItemsType, TNewFixBids } from '../../../inputs';
import { getFooterText } from '../../../table';
import { TGetProjection, TRows } from '../../types';
import { ListInfoCampaign } from './components';
import classes from './style.module.scss';

type ListProjectionsProps = {
  itemsBids: TNewFixBids;
  selectedRows: TRows;
  removeItem: (campaignId: string | number) => void;
  getProjection: TGetProjection;
  itemType: BidItemsType;
};

export const ListProjections = ({
  itemsBids,
  selectedRows,
  getProjection,
  removeItem,
  itemType,
}: ListProjectionsProps) => {
  const returnTabName = () => {
    if (itemType === 'ad_plan') return ManageVkTabNameEnum.ADPLANS;
    if (itemType === 'campaign') return ManageVkTabNameEnum.ADGROUPS;
    if (itemType === 'banner') return ManageVkTabNameEnum.ADS;
  };

  return (
    <div>
      <div className={clsx(classes.titles)}>
        <div className={classes.campaignCol} style={{ whiteSpace: 'nowrap' }}>
          <Typography size="Main" weight={600}>
            {/* @ts-ignore*/}
            {getFooterText(returnTabName(), selectedRows.length)}
          </Typography>
        </div>
        <div className={classes.bidCol}>
          <Typography size="Main" weight={600}>
            Ставка
          </Typography>
        </div>
        <div className={classes.coverageCol}>
          <Typography size="Main" weight={600}>
            Охват
          </Typography>
        </div>
      </div>
      <ListInfoCampaign
        itemsBids={itemsBids}
        selectedRows={selectedRows}
        removeCampaign={removeItem}
        getProjection={getProjection}
        itemType={itemType}
      />
    </div>
  );
};
