export enum NetworkEnum {
  myTarget = 'mt',
  VKontakte = 'vk',
  VkAds = 'va',
}

export interface IButtonProps {
  socialVariant: NetworkEnum;
  action?: (param: IButtonProps['socialVariant']) => void;
}
