import { GridApi } from '@plarin/inputs';
import { ManageVkTabNameEnum, ManageYDTabNameEnum } from '@plarin/utils';
import { useEffect } from 'react';
import { TCampaignsData } from '../../../../../types/manage-vk/types';

export const useGroupingSelect = (
  gridApi: GridApi,
  isGroupDestroyed: boolean,
  setIsGroupDestroyed: (value: boolean) => void,
  getSelectedIds: any,
  tabName: ManageVkTabNameEnum | ManageYDTabNameEnum,
  data?: TCampaignsData[],
) => {
  useEffect(() => {
    if (gridApi && isGroupDestroyed) {
      const selectedKeys = getSelectedIds(tabName);
      const nodes: any[] = [];
      gridApi.forEachNode(node => selectedKeys.includes(+node.key!) && nodes.push(node));
      gridApi.setNodesSelected({ nodes, newValue: true });
      setIsGroupDestroyed(false);
    }
  }, [isGroupDestroyed]);
};
