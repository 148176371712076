import { Typography } from '@plarin/design';
import { sendMetricGoal, shortDate } from '@plarin/utils';
import clsx from 'clsx';
import { format } from 'date-fns-tz';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ContextMenu, ContextMenuProps } from '../context-menu';
import { DateRangePicker } from '../date-range-picker';
import { Popover } from '../popover';
import { DefaultRenderComponent } from './components/defaultRenderComponent';
import { MENU_ITEM_LABEL, generateDates } from './constants';
import classes from './style.module.scss';
import { DateRangeInputProps, DateRangeMenuItem } from './types';

export const DateRangeInput = ({
  menuHide = false,
  reportInput = false,
  sizeS,
  withQuarters,
  setDateOption,
  componentClasses,
  date = { storeEndDate: 0, storeStartDate: 0, storeLabel: '' },
  renderComponent: Component = DefaultRenderComponent,
  setMenuItems,
  hasError,
  errorMessage = 'Ошибка',
  disabled,
  isOpenBottom,
}: DateRangeInputProps) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const { storeLabel, storeStartDate, storeEndDate } = date;
  const isAllDay = MENU_ITEM_LABEL.All_TIME === storeLabel;
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<number>(storeStartDate);
  const [endDate, setEndDate] = useState<number>(storeEndDate);
  const [label, setLabel] = useState<string>(storeLabel);

  const [selected, setSelected] = useState<number>(0);
  const [popoverClose, setpopoverClose] = useState(false);
  const [closeTimeoutId, setCloseTimeoutId] = useState<number | null>(null);
  const [arrowActive, setArrowActive] = useState(false);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const { VARIANTS, QuarterItems } = generateDates(currentDate);

  const setInitialValue = (label: string, startDate: number, endDate: number) => {
    const [variantsItem] = [...VARIANTS, ...QuarterItems].filter(e => e.label === label);

    return variantsItem && variantsItem.label !== MENU_ITEM_LABEL.PERIOD
      ? `${shortDate(+variantsItem.startDay())}-${shortDate(+variantsItem.endDay())}`
      : `${shortDate(startDate)}–${shortDate(endDate)}`;
  };
  const [value, setValue] = useState<string>(setInitialValue(storeLabel, storeStartDate, storeEndDate));

  const menuItems: DateRangeMenuItem[] = useMemo(() => {
    if (withQuarters) {
      const result: DateRangeMenuItem[] = [...VARIANTS];
      result.splice(7, 0, ...QuarterItems);
      return setMenuItems ? setMenuItems(result) : result;
    }
    return setMenuItems ? setMenuItems(VARIANTS) : VARIANTS;
  }, [withQuarters, setMenuItems, currentDate]);

  const calendarIdx = useMemo(() => menuItems.length - 1, [menuItems]);

  useEffect(() => {
    if (value.includes('-')) {
      setValue(value.replace('-', '–'));
    }
  }, [value]);

  useEffect(() => {
    setDateOption && setDateOption({ label: label, endDate: endDate, startDate: startDate });
  }, [endDate, label, setDateOption, startDate]);

  useLayoutEffect(() => {
    if (storeLabel) {
      const selected = menuItems.findIndex(item => item.label === storeLabel);
      setSelected(selected === -1 ? calendarIdx : selected);
      setLabel(storeLabel);
    }
  }, [calendarIdx, menuItems, storeLabel]);

  const leave = useCallback(e => setTimeout(() => e.target.blur()), []);
  const toggle = useCallback(() => setOpen(open => !open), []);
  const handleClose = useCallback(e => {
    e.stopPropagation();
    setArrowActive(false);
    setpopoverClose(true);
    const timeoutId = window.setTimeout(() => {
      setOpenDatePicker(false);
      setOpen(false);
    }, 245);
    setCloseTimeoutId(timeoutId);
  }, []);

  const defaultAction = useCallback(
    (newLabel: number | boolean, idx?: number) => {
      const menuItems = withQuarters ? [...VARIANTS, ...QuarterItems] : VARIANTS;

      typeof newLabel === 'number' && setLabel(menuItems[newLabel].label);
      typeof idx === 'number' && idx !== calendarIdx && setSelected(idx);
      if (typeof idx === 'number' && idx === calendarIdx) {
        setOpenDatePicker(true);
      }
    },
    [calendarIdx, withQuarters, setMenuItems, currentDate],
  );

  const openContextMenu = useCallback(event => {
    event.stopPropagation();
    setArrowActive(true);
    setpopoverClose(false);
    setOpen(true);
    clearTimeout(closeTimeoutId!);
  }, []);

  const options: ContextMenuProps['options'] = useMemo(() => {
    const menuItems = withQuarters ? [...VARIANTS, ...QuarterItems] : VARIANTS;
    return menuItems.map(({ startDay, endDay, ...variant }, idx) => {
      return {
        ...variant,
        close: idx < calendarIdx,
        action: () => {
          defaultAction(idx < calendarIdx && idx, idx);
          idx < calendarIdx && startDay && setStartDate(+startDay());
          idx < calendarIdx && endDay && setEndDate(+endDay());
          idx < calendarIdx &&
            startDay &&
            endDay &&
            setValue(`${format(startDay(), 'dd.MM.yyyy')}–${shortDate(+endDay())}`);
          // Отправляем конверсию в Яндекс.Метрику
          idx < calendarIdx
            ? sendMetricGoal('usage_vk_filter_preset_period', 'manage/vk')
            : sendMetricGoal('usage_vk_filter_custom_period', 'manage/vk');
        },
      };
    });
  }, [menuItems, calendarIdx, defaultAction]);

  useEffect(() => {
    const newDate = new Date();
    setCurrentDate(newDate);
  }, [open]);

  return (
    <div className={clsx(classes.container, componentClasses?.container)} ref={refContainer}>
      <Component
        disabled={disabled}
        hasError={hasError}
        sizeS={sizeS}
        onClick={e => !disabled && openContextMenu(e)}
        refAnchor={ref}
        open={arrowActive}
        label={label === MENU_ITEM_LABEL.All_TIME ? 'Период' : label}
        toggle={toggle}
        leave={leave}
        calendarIdx={calendarIdx}
        labelConstants={MENU_ITEM_LABEL}
        reportInput={reportInput}
        value={label === MENU_ITEM_LABEL.All_TIME ? label : value}
      />
      <Popover
        isOpenBottom={isOpenBottom}
        isListLeft={!menuHide}
        observerSize
        classNameWrapper={clsx(
          classes.wrapperPaper,
          componentClasses?.paper ? componentClasses?.paper : classes.paper,
          sizeS && classes.paperSizeS,
          openDatePicker && classes.paperOpenDatePicker,
          popoverClose ? classes.popoverClosed : classes.popoverOpen,
        )}
        setIsOpen={handleClose}
        anchorEl={ref.current}
        isOpen={open}
      >
        <>
          <DateRangePicker
            classDatePicker={componentClasses?.datePicker}
            startDate={isAllDay ? undefined : startDate}
            endDate={isAllDay ? undefined : endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setOpen={setOpen}
            setOpenDatePicker={setOpenDatePicker}
            open={openDatePicker}
            setRangeValue={setValue}
            setSelected={setSelected}
            setLabel={setLabel}
            menuItems={menuItems}
            calendarIdx={calendarIdx}
          />
          <ContextMenu
            sizeS={sizeS}
            classPaper={componentClasses?.paper}
            open={open}
            menuHide={menuHide}
            openDatePicker={openDatePicker}
            handleClose={handleClose}
            options={options}
            selected={selected}
            width={refContainer.current?.offsetWidth}
          />
        </>
      </Popover>
      {hasError && (
        <div className={classes.errorMessage}>
          <Typography color="Error" size={sizeS ? 'TableRowSubHeader' : 'Caption'}>
            {errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};
