import { PlusIcon } from '@plarin/design';
import React from 'react';
import './workflow-button.scss';

export const AddWorkflowButton = () => {
  return (
    <div className="workFlowAddButton">
      <PlusIcon color="primary60" />
    </div>
  );
};
