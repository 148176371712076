import { debounce, switchFilter } from '@plarin/utils';
import React from 'react';
import { TGuests, TTeamsData, TTeamsFilters } from '../../types/projects/projects';
import { TReportData, TShortClientsData } from '../../types/reports/reports';

type TDataList = TShortClientsData | TReportData[];

// TODO вынести в утилс глобально
export const filterReports = (searchValue: string, dataList: TReportData[] | any[]) => {
  const arraySearchValue: string[] = searchValue.replace(/\s+/, ' ').split(' ');

  return dataList.filter(report =>
    arraySearchValue.reduce((acc: boolean, elem) => {
      return report.name.toLowerCase().includes(elem.toLowerCase()) ? acc : false;
    }, true),
  );
};

export const filterClients = (searchValue: string, dataList: TShortClientsData) => {
  const arraySearchValue: string[] = searchValue.replace(/\s+/, ' ').split(' ');

  return dataList.filter(client =>
    arraySearchValue.reduce((acc: boolean, elem) => {
      if (
        (client.account_name && client.account_name.toLowerCase().includes(elem.toLowerCase())) ||
        client.account_id?.toString().includes(elem.toLowerCase()) ||
        client.account_username.toLowerCase().includes(elem.toLowerCase())
      ) {
        return acc;
      }
      return false;
    }, true),
  );
};

export const searchResults = (
  searchValue: string,
  filters: (
    searchValue: string,
    dataList: TShortClientsData | TReportData[] | any[],
  ) => TShortClientsData | TReportData[],
  setSearchResults: React.Dispatch<React.SetStateAction<any>>,
  dataList: TDataList | any[],
) => {
  const filterResult = filters(searchValue, dataList);

  if (searchValue.length === 1) {
    setSearchResults(filterResult);
  }
  if (searchValue && searchValue.trim().length >= 2) {
    filterResult.length
      ? setSearchResults(filterResult)
      : setSearchResults(filters(switchFilter(searchValue), dataList));
  } else {
    searchValue && filterResult.length
      ? setSearchResults(filterResult)
      : setSearchResults(filters(switchFilter(searchValue), dataList));
  }
};

export const filtersTeams = (searchVal: string, allTeams: TTeamsData[]): TTeamsFilters[] => {
  return filterReports(searchVal, allTeams).map(team => ({
    id: `${team._id}`,
    name: team.name,
    avatar_color: team.avatar_color,
    member_ids: team.member_ids,
  }));
};

export const filtersGuests = (searchVal: string, allGuests: TGuests[]): TGuests[] => {
  const filterShortGuests = (searchValue: string, dataList: TGuests[]) => {
    const arraySearchValue: string[] = searchValue.replace(/\s+/, ' ').split(' ');

    return dataList.filter(guest =>
      arraySearchValue.reduce((acc: boolean, elem) => {
        if (
          (guest.lname && guest.lname.toLowerCase().includes(elem.toLowerCase())) ||
          (guest.fname && guest.fname.toLowerCase().includes(elem.toLowerCase()))
        ) {
          return acc;
        }
        return false;
      }, true),
    );
  };

  return filterShortGuests(searchVal, allGuests).map(guests => ({
    fname: guests.fname,
    lname: guests.lname,
    avatarColor: guests.avatarColor,
    id: guests.id,
    status: guests.status,
  }));
};

export const getSearchResults = debounce(searchResults, 350);
