import { AnySchema, ValidationError } from '@hapi/joi';

export type TBasicFormStoreError = ValidationError;

export class BasicFormStoreError extends ValidationError implements TBasicFormStoreError {
  constructor(msg: string) {
    super(msg, null, null);
  }
}

export type TEvents = keyof WindowEventMap;

export type TBasicFormStore<F extends TFormData> = {
  data: F;
  errors: TFormError<F>;
  validators: TValidators<F>;
  validateAll: <K extends keyof F>(key?: K) => TFormError<F>;
  validate: TBasicFormStoreValidate<F>;
  onInput: TBasicFormStoreCallbackEvent<F>;
  onChange: TBasicFormStoreCallbackEvent<F>;
  onBlur: TBasicFormStoreCallbackEvent<F>;
  onSubmit: TBasicFormStoreSubmitEvent<F>;
  setError: <K extends keyof F>(key: K, error?: TBasicFormStoreError) => void;
  getErrorMsg: <K extends keyof F>(key: K) => string | undefined;
  isValid: boolean;
};

export type TFormData = {
  [key: string]: any;
};

export type TFormInitParams<F extends TFormData> = {
  [key in keyof F]: IFormInitProps<F[key], F>;
};

export type IFormInitProps<T, F extends TFormData> = {
  value?: T;
  validators?: TFormValidators<T, F>;
};

export type TBasicFormStoreCallbackEvent<F extends TFormData> = <K extends keyof F>(key: K) => (value: F[K]) => void;

export type TBasicFormStoreSubmitEvent<F extends TFormData> = (callback: VoidFunction) => (ev: any) => void;

export type TBasicFormStoreValidate<F extends TFormData> = <K extends keyof F>(
  key: K,
  event?: TEvents,
) => TBasicFormStoreError | undefined;

export type TFormValidators<T, F extends TFormData> = {
  [e in TEvents]?: TFormValidator<T, F>;
};

export type TFormValidator<T, F extends TFormData> = AnySchema | TFormCustomValidator<T, F>;

export type TFormCustomValidator<T, F extends TFormData> = (
  value: T,
  storeForm: TBasicFormStore<F>,
) => TBasicFormStoreError | undefined;

export type TValidators<T> = { [key in keyof T]?: TValidator };

export type TValidator = (value: any, event?: TEvents) => TBasicFormStoreError | undefined;

export type TFormError<T> = { [key in keyof T]?: TBasicFormStoreError };
