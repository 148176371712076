import { Avatar, Typography } from '@plarin/design';
import { CustomList, EllipsisTooltip, HightLightText, AvatarSkeleton, CustomMenuItem } from '@plarin/inputs';
import { switchFilter, typografText, typografNames } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';
import { TTeamAccessItemResp, TWorkspaceDrawerData } from '../../../../../types/workspace/types';
import { filterReports } from '../../../../utils/searchList';
import { useAppDataStore } from '../../../app-data-provider';
import classes from './style.module.scss';

type TAddTeamsList = {
  addTeam: (team: TTeamAccessItemResp) => void;
  rendererList?: TWorkspaceDrawerData['userData']['teams'];
  searchValue: string;
};

export const TeamsList = observer(({ rendererList, addTeam, searchValue }: TAddTeamsList) => {
  const {
    teams: { hasLoadingError, isTeamsLoading },
  } = useAppDataStore();

  const teamsNoArchive = rendererList?.filter(team => team.status === 'active');

  const errorText = () => {
    if (hasLoadingError) {
      return 'Произошла ошибка при загрузке списка команд пространства.';
    }
    if (teamsNoArchive?.length != rendererList?.length) {
      return 'Команды в пространстве находятся в архиве. Разархивируйте команды или создайте новые.';
    }
    if (searchValue) {
      return 'По вашему запросу не найдена ни одна команда пространства.';
    } else {
      return 'Сотрудник добавлен во все команды пространства.';
    }
  };

  if (isTeamsLoading) {
    return (
      <div className={clsx(classes.listRoot, classes.marginSkeleton)}>
        <AvatarSkeleton sumList={7} size="long" height={17} />
      </div>
    );
  }

  return (
    <CustomList classProps={classes.listRoot}>
      <>
        {teamsNoArchive?.length ? (
          teamsNoArchive.map(team => (
            <CustomMenuItem className={classes.teamsAddListItem} onClick={() => addTeam(team)} key={team._id}>
              <div className={classes.nameBlock}>
                <Avatar
                  name={team.name}
                  colorAvatar={team.avatar_color}
                  iconType={team.status === 'inactive' ? 'archived' : undefined}
                  type="square"
                  size={20}
                />
                <EllipsisTooltip tooltipMessage={team.name}>
                  <HightLightText
                    textEllips={true}
                    text={typografNames(team.name)}
                    filterValue={
                      filterReports(searchValue, teamsNoArchive).length ? searchValue : switchFilter(searchValue)
                    }
                    size="AuthContent"
                  />
                </EllipsisTooltip>
              </div>
            </CustomMenuItem>
          ))
        ) : (
          <Typography size="AuthContent" color="TextSecondary" componentProps={{ className: classes.noTeams }}>
            {typografText(errorText())}
          </Typography>
        )}
      </>
    </CustomList>
  );
});
