import { MenuItem, Popover } from '@mui/material';
import { Typography } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
// import { IEditProjectData } from '../table';
import classes from './style.module.scss';

// WsTableMainActionPanel сейчас не используется на  проекте, ее может заменять <CustomDropdownMenu>

type TActionPanel = {
  isOpen: boolean;
  closeMenu: (e: React.MouseEvent) => void;
  anchorEl: HTMLDivElement | null;
  menuOptions: ({
    value: string;
    label: string;
    labelArchived?: string;
    action?: (e: any | null) => void;
    actionArchived?: () => void;
  } | null)[];
  isArchived?: boolean;
  anchorOrigin?: {
    vertical: number | 'top' | 'center' | 'bottom';
    horizontal: number | 'center' | 'left' | 'right';
  };
  transformOrigin?: {
    vertical: number | 'top' | 'center' | 'bottom';
    horizontal: number | 'center' | 'left' | 'right';
  };
  data: any | null;
  positionTopInline?: boolean;
};

// для главного экшен меню с пунктами типа "Редактировать", "Выйти" и пр.
export const WsTableMainActionPanel = ({
  isOpen,
  closeMenu,
  anchorEl,
  menuOptions,
  isArchived,
  anchorOrigin,
  transformOrigin,
  data,
  positionTopInline,
}: TActionPanel) => {
  return (
    <Popover
      open={isOpen}
      onClose={closeMenu}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin ? anchorOrigin : { vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={transformOrigin ? transformOrigin : { horizontal: 218, vertical: -5 }}
      marginThreshold={9}
      classes={{ paper: clsx(classes.paper, positionTopInline && classes.positionTopInline) }}
    >
      {menuOptions.map((item, index) => {
        if (item) {
          const { action, actionArchived, labelArchived, label } = item;
          return (
            <MenuItem
              key={index}
              className={classes.menuItem}
              onClick={e => {
                e.stopPropagation();
                isArchived && actionArchived ? actionArchived() : action && action(data);
                closeMenu(e);
              }}
            >
              <Typography size="MenuItem">{isArchived ? labelArchived || label : label}</Typography>
            </MenuItem>
          );
        }
      })}
    </Popover>
  );
};
