import { Typography } from '@plarin/design';
import { EllipsisTooltip, HightLightText } from '@plarin/inputs';
import { typografNames } from '@plarin/utils';
import React from 'react';
import { TReportEntity } from '../../../../../types/reports/reports';
import { Chips } from '../../../../components/chips';
import { getSortMetrics } from '../../../../utils/getSortMetrics';
import classes from './styles.module.scss';

export const ReportsName = ({
  name,
  metrics,
  filterValue,
}: {
  name: string;
  metrics: TReportEntity[];
  filterValue: string;
}) => {
  const metricsSort = getSortMetrics(metrics);

  return (
    <div className={classes.clientsName}>
      <EllipsisTooltip tooltipMessage={name}>
        <HightLightText
          textEllips={true}
          text={typografNames(name)}
          filterValue={filterValue}
          size="AuthContent"
          weight={600}
        />
      </EllipsisTooltip>

      <div className={classes.metricsList}>
        <Chips names={metricsSort} />
      </div>
    </div>
  );
};
