import { Typography } from '@plarin/design';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { EllipsisTooltip } from '../../../tooltip';
import classes from '../budget-renderer-yd/style.module.scss';
import { strategyText, valueBudget } from './utils';
import clsx from 'clsx';
import { StatusColorEnum } from '../status-renderer-yd';

export const BudgetRendererYd = (props: ICellRendererParams) => {
  const { dailyBudgetAmount, weeklySpendLimit, biddingStrategyType, accountCurrency, statusColor } = props.data;

  return (
    <>
      <EllipsisTooltip
        classNameContainer={classes.ellipsisBudget}
        tooltipMessage={valueBudget(dailyBudgetAmount, weeklySpendLimit, accountCurrency)}
      >
        <Typography ellips size="Caption">
          {valueBudget(dailyBudgetAmount, weeklySpendLimit, accountCurrency)}
        </Typography>
      </EllipsisTooltip>

      <EllipsisTooltip
        classNameContainer={clsx(
          classes.ellipsisBudget,
          classes.second,
          statusColor === StatusColorEnum.ARCHIVED && classes.archivedText,
        )}
        tooltipMessage={strategyText[biddingStrategyType as keyof typeof strategyText]}
      >
        <Typography ellips={true} size="TableRowSubHeader">
          {strategyText[biddingStrategyType as keyof typeof strategyText]}
        </Typography>
      </EllipsisTooltip>
    </>
  );
};
