import { Page } from '@plarin/core';
import { Typography } from '@plarin/design';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const NotFoundPage: React.FC = () => {
  const history = useHistory();
  return (
    <Page title="Страница не найдена">
      <div>
        <button onClick={history.goBack}>Вернутся</button>
        <Typography color="textPrimary" weight={600}>
          Страница не найдена
        </Typography>
      </div>
    </Page>
  );
};
