import { InputAdornment, IconButton } from '@mui/material';
import { CloseCircleIcon } from '@plarin/design';
import React from 'react';
// import classes from './clear-button-style.module.scss';

export type TClearButton = {
  clearInputAction?(): void;
  inputValue: string;
};

export const ClearButton = ({ clearInputAction, inputValue }: TClearButton) => {
  return (
    <InputAdornment position="end" onClick={clearInputAction} onMouseDown={e => e.preventDefault()}>
      <IconButton tabIndex={-1} size="small" edge="end" sx={{ visibility: inputValue ? 'visible' : 'hidden' }}>
        <CloseCircleIcon iconType="outlined" />
      </IconButton>
    </InputAdornment>
  );
};
