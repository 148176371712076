import { InputAdornment, OutlinedInputProps, TextField } from '@mui/material';
import { SearchIcon, SmallArrowDownIcon } from '@plarin/design';
import { useDebounce } from '@plarin/utils';
import clsx from 'clsx';
import React, { useEffect, useState, useCallback } from 'react';
import { Button } from '../buttons';
import { ContextMenu, ContextMenuProps } from '../context-menu';
import { ClearButton } from '../inputs';
import './search-with-select.scss';
import classes from './style.module.scss';

export interface SearchWithSelectProps<T = FilterState> extends Pick<ContextMenuProps, 'options' | 'getOptions'> {
  filter?: T;
  setFilter?: (str: string) => void;
  className?: string;
  withSelect?: boolean;
  withBorder?: boolean;
}

// TODO: вынести в отдельный пакет общие интерфейсы (например в utils)
interface FilterState {
  inputValue: string;
}

export const SearchWithSelectUnMemoized = <T extends FilterState>({
  options,
  getOptions,
  filter,
  setFilter,
  className,
  withSelect = true,
  withBorder = false,
}: SearchWithSelectProps<T>) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState(filter?.inputValue || '');
  const debounce = useDebounce();
  useEffect(() => {
    setInputValue(filter?.inputValue || '');
    setFilter && setFilter(filter?.inputValue || '');
  }, [filter]);

  useEffect(() => {
    debounce(() => setFilter && setFilter(inputValue.toLowerCase()), 300);
  }, [inputValue, setInputValue]);

  const openMenu = (event: { currentTarget: React.SetStateAction<null> }) => {
    setAnchorEl(event.currentTarget);
  };

  const changeInputValue = useCallback(
    (value: string) => {
      setInputValue(value);
    },
    [setFilter],
  );

  const handleChange: OutlinedInputProps['onChange'] = value => {
    changeInputValue(value.target.value);
  };

  const handleClose = () => setAnchorEl(null);

  const clearInput = useCallback(() => {
    changeInputValue('');
  }, [changeInputValue]);

  return (
    <div className={clsx(className, classes.wrapper, withBorder && classes.withBorder)}>
      <TextField
        fullWidth
        placeholder="Поиск и фильтры"
        classes={{
          root: classes.root,
        }}
        value={inputValue}
        onChange={handleChange}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            input: classes.input,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
            // inputAdornedStart: classes.inputAdornedStart,
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="disabled" />
            </InputAdornment>
          ),
          endAdornment: ClearButton({
            clearInputAction: clearInput,
            inputValue: inputValue,
          }),
        }}
      />
      {withSelect && (
        <Button className={classes.button} variant="filled" color="light" onClick={openMenu}>
          <SmallArrowDownIcon className={clsx('rotatable', anchorEl && 'deg180')} />
        </Button>
      )}
      <ContextMenu anchorEl={anchorEl} options={options} handleClose={handleClose} getOptions={getOptions} />
    </div>
  );
};

export const SearchWithSelect = React.memo(
  SearchWithSelectUnMemoized,
  (prevProps, nextProps) => prevProps.filter?.inputValue === nextProps.filter?.inputValue,
) as typeof SearchWithSelectUnMemoized;
