import { GridApi, TRows } from '@plarin/inputs';
import React from 'react';
import { EditBid } from '../../../components/manage-vk-edit-menu/edit-bid';
import { EditBudget } from '../../../components/manage-vk-edit-menu/edit-budget';

type TBidRendererMenu = {
  data: TRows[0];
  close: () => void;
  gridApi: GridApi;
};

export const BidRendererMenu = ({ data, close, gridApi }: TBidRendererMenu) => (
  <EditBid close={close} selectedRows={[data]} gridApi={gridApi} />
);

export const BudgetEditRenderer = ({ data, close, gridApi }: TBidRendererMenu) => {
  return <EditBudget close={close} selectedRows={[data]} gridApi={gridApi} />;
};
