import { RedoIcon } from '@plarin/design';
import {
  ActionBar,
  ActionTableButton,
  Button,
  GridApi,
  SearchDefaultInput,
  StatusPanelDef,
  Table,
} from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { TGAStoreData } from '../../../types/connect-ga/types';
import { useAppDataStore } from '../../components/app-data-provider';
import { SelectDropMenu } from '../../components/metrics-widgets';
import { statusPanelYmGa, StatusPanelYmGaTypes } from '../../components/status-panels/status-panel-ym-ga';
import { useFilter } from '../manage-vk/tabs-content/utils/quickFilterSwitcher';
import { columns } from './ga-columns';
import { MetricsConfirmModal } from './modals';
import { NoRowsOverlay } from './no-rows-overlay';
import classes from './style.module.scss';

const IntegrationsGA = observer(() => {
  const {
    integrationsGA: {
      getData,
      searchTableGa,
      setSearchTableGa,
      tableLoading,
      data,
      tableError,
      connectGoogleAccount,
      checkQueryString,
      setModalData,
      enableGA,
    },
    profileStore: { workspaceShortname },
  } = useAppDataStore();

  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);
  const [selectedRows, setSelectedRows] = useState<TGAStoreData[]>([]);

  useEffect(() => {
    setTimeout(() => {
      gridApi && setSelectedRows(gridApi.getSelectedRows());
    });
  }, [data, gridApi]);

  useEffect(() => {
    getData();
    checkQueryString();
  }, []);

  useFilter(searchTableGa, gridApi);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: statusPanelYmGa,
          align: 'left',
          statusPanelParams: { type: StatusPanelYmGaTypes.google },
        },
      ],
    };
  }, []);

  return (
    <>
      <SearchDefaultInput
        placeholder="Поиск"
        filterValue={searchTableGa}
        setFilter={setSearchTableGa}
        searchIconColor="main"
        inputStyle="tableSearch"
      />
      <div
        className={clsx(
          classes.tableWrapper,
          classes.fixFlexColumns,
          !data?.length && classes.emptyTable,
          tableError && classes.fixErrorPadding,
        )}
      >
        <ActionBar>
          <div className={classes.actionWrapper}>
            {!tableError && !tableLoading && (
              <>
                <div className={classes.connect}>
                  <Button color="primary" size="small" onClick={() => connectGoogleAccount(workspaceShortname)}>
                    Подключить
                  </Button>
                  <SelectDropMenu
                    selectedRows={selectedRows}
                    setModalData={setModalData}
                    enableAction={enableGA}
                    tableLoading={tableLoading}
                  />
                </div>
                <ActionTableButton
                  textTooltip="Обновить"
                  action={getData}
                  loading={tableLoading}
                  icon={<RedoIcon />}
                  isReloadButton={true}
                />
              </>
            )}
          </div>
        </ActionBar>

        <Table
          onGridReady={setGridApi}
          setSelectedRows={setSelectedRows}
          columns={columns}
          statusBar={data.length && statusBar}
          rowData={data || []}
          tableLoading={tableLoading}
          agNoRowsOverlay={() => <NoRowsOverlay action={() => connectGoogleAccount(workspaceShortname)} />}
          needAutoHeight
          errorTable={tableError}
          ActionError={getData}
          groupDefaultExpanded={1}
          suppressRowClickSelection
          extraFooterHeight={40}
        />
        <MetricsConfirmModal gridApi={gridApi} />
      </div>
    </>
  );
});

export default IntegrationsGA;
