import * as Joi from '@hapi/joi';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { allowedSymbolsValidateRule } from '../validator';

const phoneValidationMethod: Joi.CustomValidator = (value: string, helpers: Joi.CustomHelpers) =>
  value.length > 0 && isValidPhoneNumber(value) ? value : helpers.error('string.phone');

export const phoneValidateRule = Joi.string().custom(phoneValidationMethod, 'phone validation');

export const phoneAllowedSymbols = allowedSymbolsValidateRule('+\\(\\) \\-0-9');
