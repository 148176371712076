import { EmptyTableNoCompany, Typography } from '@plarin/design';
import { Button } from '@plarin/inputs';
import { ManageVkTabNameEnum } from '@plarin/utils';
import React from 'react';
import classes from './styles.module.scss';

const errorText = {
  client_ids: {
    title: 'У вас еще нет ни одного рекламного кабинета',
    subTitle: 'Cоздайте свой первый рекламный кабинет',
    btnText: 'Создать рекламный кабинет',
  },
  ad_plan_ids: {
    title: 'У вас еще нет ни одной рекламной кампании',
    subTitle: 'Cоздайте свою первую рекламную кампанию',
    btnText: 'Создать рекламную кампанию',
  },
  campaign_ids: {
    title: 'У вас еще нет ни одной группы объявлений',
    subTitle: 'Cоздайте свою первую группу объявлений',
    btnText: 'Создать группу объявлений ',
  },
  ad_ids: {
    title: 'У вас еще нет ни одного объявления',
    subTitle: 'Cоздайте свое первое объявление',
    btnText: 'Создать объявление',
  },
};

export const NoRowsComponent = ({ action, tabName }: { action: () => void; tabName: ManageVkTabNameEnum }) => {
  return (
    <div className={classes.error}>
      <EmptyTableNoCompany />
      <div className={classes.text}>
        <Typography size="AuthSubheader" color="TextPrimary" align="center" weight={700}>
          {errorText[tabName].title}
        </Typography>
        <Typography size="AuthSubheader" color="TextSecondary" align="center">
          {errorText[tabName].subTitle}
        </Typography>
      </div>
      <div className={classes.refresh}>
        <Button size="medium" variant="filled" color="primary" disabled onClick={action}>
          {errorText[tabName].btnText}
        </Button>
      </div>
    </div>
  );
};
