import * as Sentry from '@sentry/react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AUTH_ACCESS_TOKEN, AUTH_REFRESH_TOKEN, CURRENT_WORKSPACE } from '../constants';
import { EHttpsStatus } from '../types';

export type THttpClient = AxiosInstance;

export type TInitInterceptorsProps = {
  getToken: (key: string) => string | null;
  getWorkspaceId: (key: string) => string | null;
  saveTokens: (access: string, refresh: string, refresh_expires?: string) => Promise<void>;
  logout: () => Promise<void>;
  logoutFromWorkspace?: () => void;
  ignorePaths: string[];
};

const getCustomHeaders = (config: AxiosRequestConfig) => {
  const customHeaders = sessionStorage.getItem('customHeaders');
  if (customHeaders) {
    const headersObject: Record<string, string> = JSON.parse(customHeaders);
    Object.keys(headersObject).forEach(headerName => {
      config.headers && (config.headers[headerName] = headersObject[headerName]);
    });
  }
};

export const httpClient: THttpClient = axios.create();

export const httpClient2: THttpClient = axios.create();

export const httpClient3: THttpClient = axios.create();

export const httpClient4: THttpClient = axios.create();

let isRefreshing = false;
let refreshPromise: Promise<any> | null = null;
const pendingRequests: Function[] = [];

export function initInterceptors({
  getToken,
  saveTokens,
  logout,
  logoutFromWorkspace,
  ignorePaths,
  getWorkspaceId,
}: TInitInterceptorsProps): void {
  const refreshTokenIfNeeded = async () => {
    if (!refreshPromise) {
      refreshPromise = new Promise(resolve => {
        pendingRequests.push(resolve);
      });
    }
    if (!isRefreshing) {
      isRefreshing = true;
      try {
        const refreshToken = getToken(AUTH_REFRESH_TOKEN);
        const response = await axios.post(
          '/api/v1/user/auth/refresh',
          {},
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          },
        );
        saveTokens(
          response.data.data.access_token,
          response.data.data.refresh_token,
          response.data.data.refresh_expires,
        );
        isRefreshing = false;
        resolvePendingRequests();
      } catch (error) {
        isRefreshing = false;
        if (axios.isAxiosError(error) && error.response && error.response.status === 504) {
          console.log('Ошибка 504 Gateway Timeout, повторный запрос на обновление токена...');
          await refreshTokenIfNeeded();
        } else {
          console.log('Авторизация не успешна');
          logout();
        }
      } finally {
        refreshPromise = null;
      }
    }

    return refreshPromise;
  };

  const resolvePendingRequests = () => {
    while (pendingRequests.length) {
      const resolve = pendingRequests.pop();
      resolve && resolve();
    }
  };

  httpClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const authToken = getToken(AUTH_ACCESS_TOKEN);
    const wsId = getWorkspaceId(CURRENT_WORKSPACE);

    getCustomHeaders(config);
    wsId && config.headers && (config.headers['X-Workspace-ID'] = wsId);
    authToken && config.headers && (config.headers.Authorization = `Bearer ${authToken}`);
    return config;
  });

  httpClient2.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const authToken = getToken(AUTH_ACCESS_TOKEN);
    const wsId = getWorkspaceId(CURRENT_WORKSPACE);

    getCustomHeaders(config);
    wsId && config.headers && (config.headers['X-Workspace-ID'] = wsId);
    authToken && config.headers && (config.headers.Authorization = `Bearer ${authToken}`);
    return config;
  });

  httpClient3.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const authToken = getToken(AUTH_ACCESS_TOKEN);

    getCustomHeaders(config);
    authToken && config.headers && (config.headers.Authorization = `Bearer ${authToken}`);
    return config;
  });

  httpClient4.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const authToken = getToken(AUTH_ACCESS_TOKEN);

    getCustomHeaders(config);
    authToken && config.headers && (config.headers.Authorization = `Bearer ${authToken}`);
    return config;
  });

  // Для httpClient
  httpClient.interceptors.response.use(
    (response: any) => {
      return response;
    },
    async (error: any) => {
      Sentry.captureException(error);
      const { status, data, config } = error.response;
      const ignore = ignorePaths.some(exception => error.config.url.includes(exception));
      status === EHttpsStatus.FORBIDDEN && logoutFromWorkspace && logoutFromWorkspace();
      console.log(data);

      if (
        !ignore &&
        (status === EHttpsStatus.UNAUTHORIZED || data.error.message === 'Missing Authorization header.') &&
        error.response &&
        !config._isRetry
      ) {
        config._isRetry = true;
        await refreshTokenIfNeeded();
        config.headers.Authorization = `Bearer ${getToken(AUTH_ACCESS_TOKEN)}`;
        return httpClient.request(config);
      } else {
        return Promise.reject(error);
      }
    },
  );

  // Для httpClient2
  httpClient2.interceptors.response.use(
    (response: any) => {
      return response.data;
    },
    async (error: any) => {
      Sentry.captureException(error);
      const { status, data, config } = error.response;
      const ignore = ignorePaths.some(exception => error.config.url.includes(exception));
      status === EHttpsStatus.FORBIDDEN && logoutFromWorkspace && logoutFromWorkspace();
      console.log(data);
      if (
        !ignore &&
        (status === EHttpsStatus.UNAUTHORIZED || data.error.message === 'Missing Authorization header.') &&
        error.response &&
        !config._isRetry
      ) {
        config._isRetry = true;
        await refreshTokenIfNeeded();
        config.headers.Authorization = `Bearer ${getToken(AUTH_ACCESS_TOKEN)}`;
        return httpClient2.request(config);
      } else {
        return Promise.reject(data?.error?.message);
      }
    },
  );

  // Для httpClient3
  httpClient3.interceptors.response.use(
    (response: any) => {
      return response;
    },
    async (error: any) => {
      Sentry.captureException(error);
      const { status, data, config } = error.response;
      const ignore = ignorePaths.some(exception => error.config.url.includes(exception));

      if (
        !ignore &&
        (status === EHttpsStatus.UNAUTHORIZED || data.error.message === 'Missing Authorization header.') &&
        error.response &&
        !config._isRetry
      ) {
        config._isRetry = true;
        await refreshTokenIfNeeded();
        config.headers.Authorization = `Bearer ${getToken(AUTH_ACCESS_TOKEN)}`;
        return httpClient3.request(config);
      } else {
        return Promise.reject(error);
      }
    },
  );

  // Для httpClient4
  httpClient4.interceptors.response.use(
    (response: any) => {
      return response.data;
    },
    async (error: any) => {
      Sentry.captureException(error);
      const { status, data, config } = error.response;
      const ignore = ignorePaths.some(exception => error.config.url.includes(exception));

      if (
        !ignore &&
        (status === EHttpsStatus.UNAUTHORIZED || data.error.message === 'Missing Authorization header.') &&
        error.response &&
        !config._isRetry
      ) {
        config._isRetry = true;
        await refreshTokenIfNeeded();
        config.headers.Authorization = `Bearer ${getToken(AUTH_ACCESS_TOKEN)}`;
        return httpClient4.request(config);
      } else {
        return Promise.reject(data?.error?.message);
      }
    },
  );
}
