import { action, makeObservable, observable, computed } from 'mobx';
import { BasicStore } from '../basic-store';
import {
  TBasicFormStore,
  TBasicFormStoreError,
  TEvents,
  TFormData,
  TFormError,
  TFormInitParams,
  TValidators,
} from './types';
import { initBasicFormStoreData, initBasicFormStoreValidators } from './utils';
import { formatValidationMessage } from './validator';

export class BasicFormStore<F extends TFormData> extends BasicStore implements TBasicFormStore<F> {
  data: F;
  errors: TFormError<F>;
  validators: TValidators<F>;

  constructor(params: TFormInitParams<F>) {
    super();
    this.data = initBasicFormStoreData(params);
    this.errors = {};
    this.validators = initBasicFormStoreValidators(params, this);
    makeObservable(this, {
      data: observable,
      errors: observable,
      validateAll: action.bound,
      validate: action.bound,
      set: action.bound,
      onInput: action.bound,
      onChange: action.bound,
      onBlur: action.bound,
      onSubmit: action.bound,
      setError: action.bound,
      isValid: computed,
    });
  }

  validateAll = <K extends keyof F>(key?: K) =>
    Object.keys(this.validators).reduce((errors, validatorKey: keyof F) => {
      if (key !== undefined && key !== validatorKey) {
        return errors;
      }
      const validator = this.validators[validatorKey];
      if (validator) {
        const error = validator(this.data[validatorKey]);
        if (error) {
          errors[validatorKey] = error;
        }
      }
      return errors;
    }, {} as TFormError<F>);

  validate = <K extends keyof F>(key: K, event?: TEvents) => {
    const validator = this.validators[key];
    if (validator) {
      const error = validator(this.data[key], event);
      this.setError(key, error);
      return error;
    }
  };

  set = <K extends keyof F>(key: K, value: F[K], event?: TEvents) => {
    this.data[key] = value;
    this.validate(key, event);
  };

  onInput =
    <K extends keyof F>(key: K) =>
    (value: F[K]) =>
      this.set(key, value, 'input');

  onChange =
    <K extends keyof F>(key: K) =>
    (value: F[K]) =>
      this.set(key, value, 'change');

  onBlur =
    <K extends keyof F>(key: K) =>
    () =>
      this.set(key, this.data[key], 'blur');

  onSubmit = (callback: VoidFunction) => (e: any) => {
    e.preventDefault();
    callback();
  };

  setError = <K extends keyof F>(key: K, error?: TBasicFormStoreError) => {
    if (error) {
      this.errors[key] = error;
    } else {
      delete this.errors[key];
    }
  };

  getError = <K extends keyof F>(key: K) => this.errors[key];

  getErrorMsg = <K extends keyof F>(key: K) => {
    const error = this.getError(key);
    if (error) {
      if (error.details && error.details.length) {
        return error.details.map(({ type, context }) => formatValidationMessage(type, context)).join('. ');
      }
      return error.message;
    }
  };

  get isValid(): boolean {
    return Object.keys(this.errors).length === 0;
  }
}
