import { IconButton, InputAdornment, TextField } from '@mui/material';
import { CloseCircleIcon, EyeClosedIcon, EyeOpenedIcon, Typography } from '@plarin/design';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { CharactersCounter } from '../counters/characters-counter';
import { mapPasswordComplexity } from '../input-password/map-password-complexity';
import { MainTooltip } from '../tooltip';
import { ClearButton } from './components/clear-button';
import { TInputProps } from './input-props';
import classes from './inputs.module.scss';

// проверяет, должна ли у инпута с данным типом быть кнопка очистки значения инпута
const hasClearButton = (type: string | undefined): boolean => {
  const typeHasClearButton = ['text', 'number', 'email'];

  return type ? typeHasClearButton.indexOf(type) !== -1 : false;
};

const HelperText = ({
  hint,
  maxCounterValue,
  value,
  size,
}: {
  hint?: string;
  maxCounterValue?: number;
  value?: string;
  size?: number;
}) => {
  return (
    <>
      {hint && <>{hint}</>}
      {maxCounterValue && (
        <CharactersCounter
          inputValueLength={value?.length || 0}
          maxCounterValue={maxCounterValue}
          fontSize={size || 0}
          errorText={hint || ''}
        />
      )}
    </>
  );
};

export const Input: React.VFC<TInputProps> = function Input({
  name,
  value,
  onChange,
  label,
  hint,
  error,
  disabled,
  type,
  customType,
  isFocused,
  onFocus,
  onBlur,
  fullWidth,
  autoFocus,
  actionTitle,
  action,
  required,
  isDeactivated,
  disabledRead,
  disableMinHeight, // минимальная высота 88px нужна, чтобы при появлении/исчезновении сообщения под инпутом форма не менялась по высоте
  inputRootWrapperDefault,
  deactivatedMessage,
  size,
  maxLength, // максимально допустимое количество вводимых символов
  disabledInput,
  maxCounterValue,
  ...props
}) {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    type === 'number' ? onChange(e.target.value.replace(/\D/, '')) : onChange?.(e.target.value);
  };

  const handleTogglePasswordShown = () => {
    setIsPasswordShown(prev => !prev);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  return (
    <TextField
      inputRef={inputRef}
      name={name}
      fullWidth={fullWidth}
      variant="outlined"
      className={clsx(
        !disableMinHeight && classes.inputRootWrapper,
        inputRootWrapperDefault && classes.inputRootWrapperDefault,
      )}
      InputProps={{
        classes: {
          root: clsx(
            classes.inputRoot,
            size === 48 && classes.root48,
            type === 'box' && classes.inputRootBox,
            disabledRead && classes.disabledRead,
          ),
          input: clsx(
            classes.inputInput,
            size === 48 && classes.inputRoot48,
            type === 'box' && classes.inputInputBox,
            name === 'telegram' && value && classes.inputTelegram,
          ),
          notchedOutline: 'visuallyHidden',
          focused: classes.inputFocused,
          error: classes.inputError,
          disabled: clsx(
            classes.inputDisabled,
            type === 'box' && classes.inputBoxDisabled,
            disabledInput && classes.inputDisabled48,
          ),
          adornedStart: classes.inputAdorned,
        },

        notched: false,
        startAdornment: name === 'telegram' && value && (
          <InputAdornment
            position="start"
            classes={{
              root: classes.startAdornment,
            }}
          >
            @
          </InputAdornment>
        ),
        endAdornment:
          (type === 'text' && value.length && !disabled && (
            <CloseCircleIcon
              size={20}
              onClick={() => {
                onChange('');
              }}
              className={classes.clearInputIcon}
            />
          )) ||
          (type === 'password' && (
            <InputAdornment
              position="end"
              classes={{
                root: classes.inputAdornment,
                positionEnd: classes.inputAdornmentEnd,
              }}
            >
              <IconButton size="small" edge="end" onClick={handleTogglePasswordShown}>
                {isPasswordShown ? <EyeOpenedIcon /> : <EyeClosedIcon />}
              </IconButton>
            </InputAdornment>
          )) ||
          (type === 'box' && (
            <InputAdornment
              position="end"
              classes={{
                root: classes.inputAdornment,
                positionEnd: classes.inputAdornmentEnd,
              }}
            >
              <div onClick={action} className={isDeactivated ? classes.actionTouchDisabled : classes.actionTouch}>
                <MainTooltip
                  tooltipMessage={deactivatedMessage ? deactivatedMessage : false}
                  isVisible={!!isDeactivated}
                  component="span"
                >
                  <Typography size="AuthContent" color={isDeactivated ? 'TextDisabled' : 'LinkPrimary'}>
                    {actionTitle}
                  </Typography>
                </MainTooltip>
              </div>
            </InputAdornment>
          )) ||
          (hasClearButton(type) &&
            isFocused &&
            ClearButton({
              clearInputAction: action,
              inputValue: value,
            })),
        onFocus: () => void onFocus?.(),
        onBlur: () => void onBlur?.(),
      }}
      InputLabelProps={{
        classes: {
          root: clsx(classes.inputLabelRoot, size === 48 && classes.labelRoot48),
          error: classes.inputLabelError,
          disabled: classes.inputLabelDisabled,
          focused: clsx(classes.inputLabelFocused, size === 48 && classes.labelFocusedRoot48),
          filled: clsx(classes.inputLabelFilled, size === 48 && classes.labelFilledRoot48),
        },
      }}
      value={value}
      onChange={handleChange}
      label={
        <span>
          {label}
          &nbsp;
          <span className={classes.redStar}>{required ? '*' : ''}</span>
        </span>
      }
      helperText={<HelperText hint={hint} maxCounterValue={maxCounterValue} value={value} size={size} />}
      disabled={type === 'box' || disabled}
      error={error}
      type={type === 'password' && !isPasswordShown ? 'password' : 'text'}
      FormHelperTextProps={{
        classes: {
          root: clsx(classes.inputHelperRoot, {
            [classes.red]: hint === mapPasswordComplexity.red,
            [classes.yellow]: hint === mapPasswordComplexity.yellow,
            [classes.green]: hint === mapPasswordComplexity.green,
          }),
          error: classes.inputHelperError,
          disabled: classes.inputHelperDisabled,
        },
      }}
      inputProps={
        type === 'number'
          ? { className: classes.inputElement, inputMode: 'numeric', pattern: '[0-9]*', ...props }
          : {
              className: classes.inputElement,
              autoCapitalize: name === 'email' ? 'off' : undefined,
              maxLength: maxLength,
              ...props,
            }
      }
      autoFocus={autoFocus}
    />
  );
};
