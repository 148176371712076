import { Avatar, SmallArrowDownIcon, Typography } from '@plarin/design';
import {
  Button,
  CustomList,
  DefaultInput,
  EllipsisTooltip,
  HightLightText,
  Popover,
  CustomMenuItem,
} from '@plarin/inputs';
import { getMemberName, switchFilter, typografNames } from '@plarin/utils';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { WsMember } from '../../../../types/profile/types';
import { TeamAccessItemResp, TWsProject } from '../../../../types/teams/teams';
import { searchResults } from '../../../utils/searchList';
import classes from './styles.module.scss';

interface AvatarSearchPopupProps {
  buttonText: string;
  searchPlaceholder: string;
  buttonComponent?: React.FC;
  listItems: (WsMember | TWsProject | TeamAccessItemResp)[];
  avatarType?: 'square';
  setItem: (item: any) => void;
  allAddedText: string;
  searchErrorText: string;
}

const filterItems = (searchValue: string, items: (WsMember | TWsProject | TeamAccessItemResp | any)[]) => {
  const arraySearchValue: string[] = searchValue.toLowerCase().replace(/\s+/, ' ').split(' ');

  return items.filter(item =>
    arraySearchValue.reduce((acc: boolean, elem) => {
      if (
        ('fname' in item && `${item.fname} ${item.lname}`.toLowerCase().includes(elem)) ||
        ('name' in item && item.name.toLowerCase().includes(elem))
      ) {
        return acc;
      }
      return false;
    }, true),
  );
};

export const WorkspaceDrawerSearchPopup = ({
  buttonText,
  listItems,
  avatarType,
  buttonComponent: ButtonComponent,
  setItem,
  allAddedText,
  searchErrorText,
  searchPlaceholder,
}: AvatarSearchPopupProps) => {
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState<(WsMember | TWsProject | TeamAccessItemResp)[]>(listItems);

  const anchorRef = useRef(null);

  const onOpen = useCallback(e => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    onClear();
  }, []);

  const onClickItem = useCallback((item: WsMember | TWsProject | TeamAccessItemResp) => {
    setItem(item);
    setFilteredItems(items => items.filter(e => e._id !== item._id));
  }, []);

  const onClear = useCallback(() => {
    setSearchValue('');
  }, []);

  useEffect(() => {
    if (!!searchValue) {
      searchResults(searchValue, filterItems, setFilteredItems, listItems);
    } else {
      setFilteredItems(listItems);
    }
  }, [searchValue, listItems]);

  return (
    <>
      <div className={classes.button} ref={anchorRef}>
        {ButtonComponent ? (
          <ButtonComponent />
        ) : (
          <>
            <Button onClick={onOpen}>
              <Typography size="AuthContent" color="Primary60" weight={600}>
                {buttonText}
              </Typography>
            </Button>
            <SmallArrowDownIcon color="primary60" className={clsx('rotatable', isOpen && 'deg180')} />
          </>
        )}
      </div>

      <Popover isListLeft setIsOpen={onClose} onEscape={onClose} anchorEl={anchorRef.current} isOpen={isOpen}>
        <div className={classes.paper}>
          <DefaultInput
            className={classes.input}
            value={searchValue}
            setValue={setSearchValue}
            placeholder={searchPlaceholder}
            searchIcon
            clearInputAction={onClear}
          />
          {filteredItems.length ? (
            <CustomList classProps={classes.list}>
              <>
                {filteredItems.map(item => {
                  const names =
                    'name' in item
                      ? item.name
                      : getMemberName({ fname: item?.fname, lname: item?.lname, email: item?.email });

                  return (
                    <CustomMenuItem className={classes.item} key={item._id} onClick={() => onClickItem(item)}>
                      <Avatar name={names} colorAvatar={item.avatar_color} size={20} type={avatarType} />
                      <EllipsisTooltip tooltipMessage={names}>
                        <HightLightText
                          size="AuthContent"
                          text={typografNames(names)}
                          textEllips
                          filterValue={
                            filterItems(searchValue, listItems).length ? searchValue : switchFilter(searchValue)
                          }
                        />
                      </EllipsisTooltip>
                    </CustomMenuItem>
                  );
                })}
              </>
            </CustomList>
          ) : (
            <Typography size="AuthContent" color="TextSecondary" componentProps={{ className: classes.notItems }}>
              {typografNames(searchValue ? searchErrorText : allAddedText)}
            </Typography>
          )}
        </div>
      </Popover>
    </>
  );
};
