// Функция позволяет подобрать склонение окончаний в словах при числительных
// Массив txt выглядит так: ['объявление', 'объявления', 'объявлений', 'n'] (именительный, родитительный ед.ч, родительный мн.ч, род для склонения (m, f, n))
// обсуждение сложных случаев склонений в нашем slack: https://plarin.slack.com/archives/C67TAFBCM/p1657554239262599 : 'рекламная кампания', 'рекламные кампании', 'рекламных кампаний'
export const getNumeral = (amount: number, numerals: String[]) => {
  var cases = [2, 0, 1, 1, 1, 2];

  return numerals[amount % 100 > 4 && amount % 100 < 20 ? 2 : cases[amount % 10 < 5 ? amount % 10 : 5]];
};

// получить слово "выбран", склонённое по роду и количеству (выбрана, выбран, выбраны)
export const getSelectedWord = (num: number, gender: 'f' | 'm' | 'n'): string => {
  // 1, 21, 31, 41 и т.д.
  if (String(num).slice(-1) === '1' && String(num).slice(-2) !== '11') {
    if (gender === 'f') {
      return 'выбрана';
    }
    if (gender === 'm') {
      return 'выбран';
    }
    if (gender === 'n') {
      return 'выбрано';
    }
  }
  // все остальные цифры
  if (num > 1) {
    return 'выбраны';
  }
  return 'выбрано';
};
