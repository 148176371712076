import type { PopoverOrigin } from '@mui/material';
import { ObjectValue } from '@plarin/utils';
import React from 'react';
import { MENU_ITEM_LABEL } from './constants';

export type TDateOption = { label: string; startDate: number; endDate: number };

export type DateItems = { storeLabel: string; storeStartDate: number; storeEndDate: number };

type MenuItem = ObjectValue<typeof MENU_ITEM_LABEL>;

export interface DateRangeMenuItem {
  label: MenuItem | string;
  startDay: () => Date | number;
  endDay: () => Date | number;
  isDivider?: boolean | undefined;
}

export interface DateRangeInputProps {
  menuHide?: boolean;
  reportInput?: boolean;
  positionRight?: boolean;
  withQuarters?: boolean;
  setDateOption?: (filterOption: TDateOption) => void;
  date?: DateItems;
  renderComponent?: (props: RenderComponentProps) => JSX.Element;
  setMenuItems?: (defaultMenuItems: DateRangeMenuItem[]) => DateRangeMenuItem[];
  componentClasses?: { datePicker?: string; menu?: string; paper?: string; container?: string };
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  sizeS?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  isOpenBottom?: boolean;
}

export interface RenderComponentProps {
  className?: string;
  open: boolean;
  onClick?: React.MouseEventHandler;
  refAnchor: React.RefObject<HTMLDivElement>;
  label: string;
  toggle: React.MouseEventHandler;
  leave: (event: React.FocusEvent) => void;
  value: string;
  calendarIdx: number;
  labelConstants: typeof MENU_ITEM_LABEL;
  reportInput: boolean;
  sizeS?: boolean;
  hasError?: boolean;
  disabled?: boolean;
}
