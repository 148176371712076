import React from 'react';
import './integrations-accept-button.scss';
import { Button } from './button';
import { ButtonProps } from './button-props';

export const IntegrationsAcceptButton: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <div>
      <Button size="small" variant="filled" color="primary" {...props}>
        Готово
      </Button>
    </div>
  );
};
