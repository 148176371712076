import { InputPassword, ModalActionNamesEnum } from '@plarin/inputs';
import { TypeInput, useDebounce, useInput } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { ymDisableClass } from '../../../../utils/constants';

export const AddPasswordContent = observer(() => {
  const {
    profileStore: {
      setModalAction,
      addNewProfilePassword,
      isLoading,
      setGlobalError,
      globalError,
      newPasswordStyle,
      checkNewPassword,
      setNewPasswordStyle,
    },
  } = useAppDataStore();

  const password = useInput({
    initialValue: '',
    type: TypeInput.newPassword,
  });
  // eslint-disable-next-line
  const {
    setError: setPasswordError,
    setHint,
    setWasChanged,
    setTextError: setTextPasswordError,
    checkValue: passwordCheckValue,
    ...passwordInput
  } = password;
  const debounce = useDebounce();
  const setAction = useCallback(() => {
    setModalAction({
      action: () => {
        if (!password.value || password.error) {
          !password.value && setPasswordError(true);
        } else {
          addNewProfilePassword(password.value);
        }
      },
      isLoading: isLoading,
      actionTitle: ModalActionNamesEnum.Save,
    });
  }, [setModalAction, isLoading, password, addNewProfilePassword, setPasswordError]);

  useEffect(() => {
    passwordCheckValue();
    setAction();
  });

  useEffect(() => {
    setTextPasswordError && (globalError ? setTextPasswordError(globalError) : setTextPasswordError(''));
  }, [globalError, setTextPasswordError]);

  useEffect(() => {
    if (password.value.length > 7 && !password.error) {
      debounce(() => checkNewPassword({ password: password.value }), 300);
    } else {
      setNewPasswordStyle(undefined);
    }
  }, [checkNewPassword, password.error, password.value, setNewPasswordStyle]);

  useEffect(() => {
    setGlobalError('');
  }, [password.value, setGlobalError]);

  return (
    <form>
      <InputPassword
        {...passwordInput}
        passwordComplexity={password.value.length > 7 ? newPasswordStyle : undefined}
        name="newPassword"
        label="Новый пароль"
        type="password"
        autoFocus
        fullWidth
        autoComplete="new-password"
        className={ymDisableClass}
        size={48}
        disableMinHeight={true}
      />
    </form>
  );
});
