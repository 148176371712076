import { Typography } from '@plarin/design';
import { Button, InputPhone, ReCaptchaV3 } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React from 'react';
import { ForgotPasswordPhoneFormStore } from './forgot-password-phone-form.store';
import './reset-form.scss';

export type TResetByPhoneFormProps = {
  store: ForgotPasswordPhoneFormStore;
};

export const ResetByPhoneForm: React.VFC<TResetByPhoneFormProps> = observer(({ store }) => {
  const { errors, data, getErrorMsg, onChange, onBlur, submit, needCaptcha, setCaptchaToken, isPending } = store;
  return (
    <form className="resetForm" onSubmit={submit}>
      <Typography color="TextPrimary" size="AuthContent">
        Ничего страшного! Мы отправим вам СМС с кодом для смены пароля. Введите номер мобильного телефона, на который
        зарегистрирован аккаунт Plarin.
      </Typography>
      <InputPhone
        name="phone"
        label="Номер мобильного телефона"
        selectLabel="Страна"
        value={data.phone}
        error={!!errors.phone}
        hint={getErrorMsg('phone')}
        onChange={onChange('phone')}
        onBlur={onBlur('phone')}
        autoFocus
      />
      {needCaptcha && <ReCaptchaV3 siteKey={RECAPTCHA_SITE_KEY} onResponse={setCaptchaToken} />}
      <Button variant="filled" size="large" color="primary" submit={true} disabled={isPending}>
        Отправить СМС с кодом
      </Button>
    </form>
  );
});
