import { Text, TextProps } from '@plarin/design';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export type TLinkProps = TextProps & {
  to: string;
};

export const Link: React.FC<TLinkProps> = ({ children, to, ...props }) => (
  <RouterLink to={to}>
    <Text color="primary" {...props}>
      {children}
    </Text>
  </RouterLink>
);
