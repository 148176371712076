import { DefaultInput } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDataStore } from '../../../../../components/app-data-provider';
import classes from './style.module.scss';
import { TTagLineProps } from './types';

const maxLength = 128;

export const TagEditInput = observer(({ deleteTag, saveTag, tag, workspaceTags, isChanged }: TTagLineProps) => {
  const {
    members: { openModalMember },
  } = useAppDataStore();

  const [tagValue, setTagValue] = useState<string>(tag.name);
  const [error, setError] = useState(false);
  const clearAction = () => setTagValue('');
  const trimmedValue = tagValue.trim();

  const onBlurAction = useCallback(() => {
    if (error) {
      deleteTag(tag.id);
      return;
    }
    if (!trimmedValue) {
      deleteTag(tag.id);
      return;
    }
    if (!tagValue) {
      !isChanged ? openModalMember('removeTag', tag.name, () => deleteTag(tag.id)) : deleteTag(tag.id);
      return;
    }
    if (tag.name != tagValue) {
      !isChanged
        ? openModalMember('editTag', tag.name, () =>
            saveTag(!error && tagValue.length ? tagValue : tag.name, tag.id, tag.name),
          )
        : saveTag(!error && tagValue.length ? tagValue : tag.name, tag.id, tag.name);
      return;
    } else {
      saveTag(tagValue, tag.id);
    }
  }, [tagValue, tag, error]);

  const saveInputAction = useCallback(() => {
    if (!isChanged && tag.name !== trimmedValue) {
      !trimmedValue
        ? openModalMember('removeTag', tag.name, () => deleteTag(tag.id))
        : openModalMember('editTag', tag.name, () => saveTag(trimmedValue, tag.id, tag.name));
    } else {
      !trimmedValue ? deleteTag(tag.id) : saveTag(trimmedValue, tag.id, tag.name);
    }
  }, [tag, tagValue]);

  useEffect(() => {
    workspaceTags && workspaceTags.some(el => tagValue.toLowerCase() === el.toLowerCase() && tagValue !== tag.name)
      ? setError(true)
      : setError(false);
  }, [tag.name, tagValue, workspaceTags]);

  return (
    <DefaultInput
      value={tagValue}
      setValue={setTagValue}
      size={40}
      maxLength={maxLength}
      clearInputAction={clearAction}
      saveInputAction={saveInputAction}
      errorText={error ? 'Эта метка уже используется. Введите уникальную метку.' : ''}
      className={clsx(error && classes.errorVisible)}
      helperTextClassName={classes.helperError}
      onBlurAction={onBlurAction}
    />
  );
});
