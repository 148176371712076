import { Typography } from '@plarin/design';
import React from 'react';
import './checking-sms-code.scss';

export const CheckingSmsCode: React.FC = function CheckingSmsCode({ children }) {
  return (
    <div className="checkingSmsCode">
      <div className="checkingSmsCode__icon" />
      <Typography size="main" color="textSecondary">
        {children}
      </Typography>
    </div>
  );
};
