import { useEffect, useState, useCallback } from 'react';

export enum GlobalErrorMessage {
  newNotOldEmail = 'New email == old email',
  EditEmailProg = 'Edit email process in progress',
  newEmailExists = 'Already exists',
  wrongPassword = 'Wrong old password',
  wrongCredentials = 'Wrong credentials',
  newNotOldPassword = 'Invalid request',
  WrongSmsCode = 'Wrong SMS code',
  ExpiredCode = 'Expired code',
  PhoneUsed = 'Phone already used',
  Wait5min = 'Wait 5 minutes',
  Wait2min = 'Wait 2 minutes',
}

export enum TypeInput {
  fName = 'fName',
  lName = 'lName',
  sName = 'sName',
  email = 'email',
  password = 'password',
  newPassword = 'newPassword',
  smsCode = 'smsCode',
  telegram = 'telegram',
  workspace = 'workspace',
}

export enum InputWorkspaceErrors {
  shortText = 'Поле содержит менее 3 символов',
}

export enum InputNameErrors {
  noFName = 'Введите имя',
  noSName = 'Введите отчество',
  noLName = 'Введите фамилию',
  bigName = 'Поле содержит более 35 символов',
  badSymbols = 'Поле содержит недопустимые символы',
  bigName255 = 'Вы ввели более 255 символов',
  maxLength = 'Вы ввели максимально возможное количество символов',
}

export enum InputEmailErrors {
  noEmail = 'Введите email',
  bigEmail = 'Поле содержит более 254 символов',
  badFormat = 'Неверный формат адреса электронной почты',
  newNotOldEmail = 'Новый email не должен совпадать с предыдущим',
  newEmailExists = 'Такой email уже существует',
  EditEmailProg = 'Электронная почта в процессе изменения',
  emailMemberExist = 'Пользователь с таким email уже есть',
  inviteEmailExist = 'Приглашение на такой email уже отправлено',
}

export enum InputPasswordErrors {
  noPassword = 'Введите пароль',
  littlePassword = 'Пароль содержит менее 8 символов',
  bigPassword = 'Пароль содержит более 64 символов',
  wrongPassword = 'Пароль введен неверно',
  noNewPassword = 'Введите новый пароль',
  newNotOld = 'Новый пароль не должен совпадать с предыдущим паролем',
  errorSymbols = 'Введены недопустимые символы',
}

export enum InputSmsCodeErrors {
  shortCode = 'Поле содержит менее 6 символов',
  wrongSmsCode = 'Неверный код. Введите код повторно.',
  expiredCode = 'Время жизни кода истекло. Введите новый код',
  limitSmsCode = 'Вы исчерпали лимит попыток ввода СМС–кода',
  phoneUsed = 'Данный номер телефона уже зарегистрирован',
  errorWait = 'Вы слишком много раз ввели неверный код проверки. Повторите попытку позже',
}

export enum InputTelegramErrors {
  noTelegram = 'Введите имя пользователя',
  bigTelegram = 'Поле содержит более 32 символов',
  shortTelegram = 'Поле содержит менее 5 символов',
  badFormat = 'Неверный формат имени пользователя',
}

type TInputBasicTypes = 'text' | 'password' | 'phone' | 'email' | 'box' | 'number' | undefined;

type TUseInputProps = {
  initialValue: string;
  type: TypeInput;
  isRequired?: boolean;
};

export const useInput = ({ initialValue, type: customType, isRequired }: TUseInputProps) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(false);
  const [textError, setTextError] = useState('');
  const [hint, setHint] = useState('');
  const [wasChanged, setWasChanged] = useState(false);
  const [basicType, setBasicType] = useState<TInputBasicTypes>(undefined);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    switch (customType) {
      case TypeInput.fName:
        return setBasicType('text');
      case TypeInput.sName:
        return setBasicType('text');
      case TypeInput.lName:
        return setBasicType('text');
      case TypeInput.email:
        return setBasicType('email');
    }
  }, [customType]);

  const checkName = useCallback(
    (customType: TypeInput) => {
      const regSymbol = /^(\p{L}|(['.-]))*$/gu;

      if (!isRequired && !value) {
        setError(false);
        setHint('');
      }
      // обязательное поле не может быть пустым, но до изменения содержимого инпута показывать ошибку валидации не нужно
      if (isRequired && !value && (wasChanged || value !== initialValue)) {
        if (customType === TypeInput.fName) {
          setError(true);
          setHint(InputNameErrors.noFName);
        }
        if (customType === TypeInput.sName) {
          setError(true);
          setHint(InputNameErrors.noSName);
        }
        if (customType === TypeInput.lName) {
          setError(true);
          setHint(InputNameErrors.noLName);
        }
      }
      if (value.length >= 1) {
        setError(false);
        setHint('');
      }
      if (value.length > 35) {
        setError(true);
        setHint(InputNameErrors.bigName);
      }
      if (!regSymbol.test(value)) {
        setError(true);
        setHint(InputNameErrors.badSymbols);
      }
    },
    [error, value, wasChanged, initialValue, isRequired],
  );

  const checkWorkspace = useCallback(() => {
    if (!isRequired && !value) {
      setError(false);
      setHint('');
    }
    if (isRequired) {
      if (value.length < 3) {
        setError(true);
        setHint(InputWorkspaceErrors.shortText);
      }
      if (error && !(value.length < 3)) {
        setError(false);
        setHint('');
      }
    }
  }, [error, value, wasChanged, initialValue, isRequired]);

  const checkEmail = useCallback(() => {
    const newEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]{1,64}@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

    // Local - та часть email, которая идёт до '@'
    const getLocal = (email: string): string => {
      if (email.includes('@')) {
        return email.split('@')[0];
      }
      return '';
    };

    // Subdomain - то, что расположено между '@' и доменом почты (например: '1@этосубдомен.yandex.ru').
    // Субдомена у почты может не быть, в этом случае функция вернёт пустую строку.
    const getSubdomain = (value: string): string => {
      if (newEmailRegex.test(value)) {
        const splitted = value.split('@')[1];
        const mayIncludeSubdomain = splitted.slice(0, splitted.lastIndexOf('.'));
        if (mayIncludeSubdomain.includes('.')) {
          const subdomain = mayIncludeSubdomain.slice(0, mayIncludeSubdomain.lastIndexOf('.'));
          return subdomain;
        }
      }
      return '';
    };

    if (!isRequired && !value) {
      error && setHint(InputEmailErrors.noEmail);
    }
    if (isRequired && !value && value !== initialValue) {
      setError(true);
      setHint(InputEmailErrors.noEmail);
    }
    if (value.length >= 1) {
      setError(false);
      setHint('');
    }
    if (value.length > 254) {
      setError(true);
      setHint(InputEmailErrors.bigEmail);
    }
    if (getLocal(value).length > 64) {
      setError(true);
      setHint(InputEmailErrors.badFormat);
    }
    if (getSubdomain(value).length > 63) {
      setError(true);
      setHint(InputEmailErrors.badFormat);
    }
    if (value && !newEmailRegex.test(value)) {
      setError(true);
      setHint(InputEmailErrors.badFormat);
    }
    if (textError === GlobalErrorMessage.newNotOldEmail) {
      setError(true);
      setHint(InputEmailErrors.newNotOldEmail);
    }
    if (textError === GlobalErrorMessage.newEmailExists) {
      setError(true);
      setHint(InputEmailErrors.newEmailExists);
    }
    if (textError === GlobalErrorMessage.EditEmailProg) {
      setError(true);
      setHint(InputEmailErrors.EditEmailProg);
    }
  }, [error, textError, value, initialValue, isRequired]);

  const checkPassword = useCallback(() => {
    const passwordRegex = /^([0-9a-zA-Z!"#$%&'()*+,-.:;<=>?^@\/[\]_`{|}~])*$/;

    if (!value) {
      error && setHint(InputPasswordErrors.noPassword);
    }

    if (value.length && value.length < 8) {
      setError(true);
      setHint(InputPasswordErrors.littlePassword);
    }
    if (value.length >= 8 && !textError.length) {
      setError(false);
      setHint('');
    }
    if (value.length > 64) {
      setError(true);
      setHint(InputPasswordErrors.bigPassword);
    }

    if (textError === GlobalErrorMessage.wrongPassword || textError === GlobalErrorMessage.wrongCredentials) {
      setError(true);
      setHint(InputPasswordErrors.wrongPassword);
    }
    if (value && !passwordRegex.test(value)) {
      setError(true);
      setHint(InputPasswordErrors.errorSymbols);
    }
  }, [error, textError, value]);

  const checkNewPassword = useCallback(() => {
    const passwordRegex = /^([0-9a-zA-Z!"#$%&'()*+,-.:;<=>?^@\/[\]_`{|}~])*$/;

    if (!value) {
      error && setHint(InputPasswordErrors.noPassword);
    }

    if (value.length && value.length < 8) {
      setError(true);
      setHint(InputPasswordErrors.littlePassword);
    }
    if (value.length >= 8) {
      setError(false);
      setHint('');
    }
    if (value.length > 64) {
      setError(true);
      setHint(InputPasswordErrors.bigPassword);
    }
    if (value && !passwordRegex.test(value)) {
      setError(true);
      setHint(InputPasswordErrors.errorSymbols);
    }
  }, [error, value]);

  const checkSmsCode = useCallback(() => {
    if (!textError.length) {
      setError(false);
      setHint('');
    }
    if (value.length > 0 && !textError.length) {
      setError(false);
      setHint('');
    }
    if (textError === GlobalErrorMessage.WrongSmsCode) {
      setError(true);
      setHint(InputSmsCodeErrors.wrongSmsCode);
    }
    if (textError === GlobalErrorMessage.ExpiredCode) {
      setError(true);
      setHint(InputSmsCodeErrors.expiredCode);
    }
    if (textError === GlobalErrorMessage.PhoneUsed) {
      setError(true);
      setHint(InputSmsCodeErrors.phoneUsed);
    }
    if (textError === GlobalErrorMessage.Wait2min || textError === GlobalErrorMessage.Wait5min) {
      setError(true);
      setHint(InputSmsCodeErrors.errorWait);
    }
  }, [error, value, textError]);

  const checkTelegram = useCallback(() => {
    const telegramReg = /^([a-zA-Z0-9_]+)$/;

    if (!isRequired && !value) {
      setError(false);
      setHint('');
    }

    if (value.length && value.length < 5) {
      setError(true);
      setHint(InputTelegramErrors.shortTelegram);
    }
    if (value.length >= 5) {
      setError(false);
      setHint('');
    }
    if (value.length > 32) {
      setError(true);
      setHint(InputTelegramErrors.bigTelegram);
    }
    if (value && !telegramReg.test(value)) {
      setError(true);
      setHint(InputTelegramErrors.badFormat);
    }
  }, [error, textError, value, initialValue, isRequired]);

  const setValueTelegram = (value: string) => {
    const telegramReg = /(?:@|(?:(?:(?:https)?t(?:elegram)?)\.me\/))(\w{4,})/;
    const userName = telegramReg.test(value) ? value.match(telegramReg)![1] : value;

    setValue(userName);
  };

  useEffect(() => {
    customType === TypeInput.telegram && setValueTelegram(value);
  }, [value]);

  const checkValue = useCallback(() => {
    switch (customType) {
      case TypeInput.fName:
        return checkName(customType);
      case TypeInput.lName:
        return checkName(customType);
      case TypeInput.sName:
        return checkName(customType);
      case TypeInput.email:
        return checkEmail();
      case TypeInput.password:
        return checkPassword();
      case TypeInput.newPassword:
        return checkNewPassword();
      case TypeInput.smsCode:
        return checkSmsCode();
      case TypeInput.telegram:
        return checkTelegram();
      case TypeInput.workspace:
        return checkWorkspace();
    }
  }, [checkEmail, checkName, checkNewPassword, checkPassword, checkSmsCode, customType]);

  const onBlurCheck = () => {
    checkValue();
    setIsFocused(false);
  };

  const onFocusCheck = () => {
    checkValue();
    setIsFocused(true);
  };

  const clearButtonAction = () => {
    setValue('');
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    checkValue();
  }, [value, error, checkValue]);

  useEffect(() => {
    if (!wasChanged && value !== initialValue) {
      setWasChanged(true);
    }
  }, [value, wasChanged, initialValue]);

  if (customType === TypeInput.telegram)
    return {
      value,
      action: clearButtonAction,
      onChange: setValue,
      error,
      setError,
      hint,
      setHint,
      isFocused,
      onFocus: onFocusCheck,
      onBlur: onBlurCheck,
      checkValue: checkValue,
      setTextError,
      setWasChanged, // ???
    };

  if (customType === TypeInput.password)
    return {
      value,
      onChange: setValue,
      error,
      setError,
      hint,
      setHint,
      onBlur: checkValue,
      setTextError,
      setWasChanged, // ???
      checkValue: checkValue,
    };

  if (customType === TypeInput.newPassword)
    return {
      value,
      onChange: setValue,
      error,
      setError,
      errorHint: hint,
      setHint,
      onBlur: checkValue,
      setTextError,
      setWasChanged,
      checkValue: checkValue,
    };

  if (customType === TypeInput.email)
    return {
      value,
      action: clearButtonAction,
      onChange: setValue,
      error,
      setError,
      hint,
      setHint,
      isFocused,
      onFocus: onFocusCheck,
      onBlur: onBlurCheck,
      checkValue: checkValue,
      setTextError,
      setWasChanged, // ???
    };

  if (customType === TypeInput.smsCode)
    return {
      type: basicType,
      action: clearButtonAction,
      value,
      onChange: setValue, // ???
      error,
      setError,
      setTextError, // из-за него
      hint,
      setWasChanged,
      setHint,
      isFocused,
      onFocus: onFocusCheck,
      onBlur: onBlurCheck,
      checkValue: checkValue,
    };

  return {
    type: basicType,
    action: clearButtonAction,
    value,
    onChange: setValue, // ???
    error,
    setError,
    hint,
    setWasChanged,
    setHint,
    isFocused,
    onFocus: onFocusCheck,
    onBlur: onBlurCheck,
    checkValue: checkValue,
  };
};
