import { Button, InputPhone2, InputPhoneErrors, ReCaptchaV3, rusNumberIsValid } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect, useState, useCallback } from 'react';
import { AuthInputsTemplate } from '../../components/auth/auth-inputs-template';
import { ymDisableClass } from '../../utils/constants';
import { RegistrationPhoneFormStore } from './registration-phone-form.store';
import classes from './style.module.scss';

export type TRegistrationPhoneFormProps = {
  store: RegistrationPhoneFormStore;
};

export const RegistrationPhoneForm: React.VFC<TRegistrationPhoneFormProps> = observer(({ store }) => {
  const { data, onChange, submit, needCaptcha, setCaptchaToken, isPending } = store;

  const [checkLength, setCheckLength] = useState(false);
  const [error, setError] = useState('');

  const isPhoneValid = useCallback(() => {
    if (!data.phone || data.phone.length < 2) {
      setError(InputPhoneErrors.noNumber);
      return false;
    }
    if (!rusNumberIsValid(data.phone)) {
      !checkLength && setCheckLength(true);
      return false;
    }
    setError('');
    return true;
  }, [data.phone, checkLength, setCheckLength]);

  const submitForm = useCallback(
    (synthEvent: any) => {
      synthEvent.preventDefault();

      if (isPhoneValid()) {
        submit(synthEvent);
      } else {
        return undefined;
      }
    },
    [isPhoneValid, submit],
  );

  useEffect(() => {
    if (data.phone && data.phone.length >= 11 && !checkLength) {
      setCheckLength(true);
    }
  }, [data.phone, checkLength]);

  return (
    <AuthInputsTemplate formProps={{ onSubmit: submitForm }}>
      <div className={classes.container}>
        {/* 
        // когда будем возвращать регистрацию по телефону, перепроверить, что передается в пропсы компонента

        <InputPhone2
          value={data.phone}
          onChange={onChange('phone')}
          checkLength={checkLength}
          disabled={isPending}
          dropdownClass={classes.countryList}
          error={error}
          inputClass={ymDisableClass}
        /> */}
      </div>

      {needCaptcha && <ReCaptchaV3 siteKey={RECAPTCHA_SITE_KEY} onResponse={setCaptchaToken} />}
      {isPending ? (
        <Button variant="filled" size="large" color="primary" loading={true} />
      ) : (
        <Button variant="filled" size="large" color="primary" submit={true}>
          Зарегистрироваться
        </Button>
      )}
    </AuthInputsTemplate>
  );
});
