import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import { IconStatus, SortDownSolidET } from '@plarin/design';
import { sendMetricGoal } from '@plarin/utils';
import clsx from 'clsx';
import React, { useState, useCallback } from 'react';
import { Checkbox } from '../checkbox';
import { CustomMenuItemSelect } from '../custom-menu-item';
import classes from './style.module.scss';

const STATUS_LABEL = 'Статус';

type SelectStatusesProps = {
  statusesFilter?: StatusEnumVK[];
  setStatusesFilter: (status: StatusEnumVK[]) => void;
  tableLoading?: boolean;
};

export enum StatusEnumVK {
  active = 'Активные',
  blocked = 'Остановленные',
  deleted = 'Архивные',
}

const statusNameVK = [StatusEnumVK.active, StatusEnumVK.blocked, StatusEnumVK.deleted];

const RenderMenuItem = (status: StatusEnumVK, statusesFilter: StatusEnumVK[], tableLoading: boolean, index: number) => {
  const [isHover, setIsHover] = useState(false);
  const isSelected = statusesFilter.includes(status);
  return (
    <CustomMenuItemSelect
      selectedItem={false}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      key={status}
      value={status}
      tabIndex={index}
      className={clsx(classes.menuItem, isHover && classes.hoverItem)}
      disabled={tableLoading}
    >
      <Checkbox
        isInverted={isHover}
        className={classes.formContorlLabel}
        size="small"
        value={isSelected}
        disabled={tableLoading}
      />
      <IconStatus status={status} />
      <div className={classes.marginLeft}>{status}</div>
    </CustomMenuItemSelect>
  );
};

export const SelectVKStatus = ({
  statusesFilter = [],
  setStatusesFilter,
  tableLoading = false,
}: SelectStatusesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const renderContentValue = (statuses: string[]) => {
    return (
      <div className={classes.valueContent}>
        {statuses
          .map(status => {
            if (status === StatusEnumVK.active) {
              return { status, index: 0 };
            }
            if (status === StatusEnumVK.blocked) {
              return { status, index: 1 };
            }
            return { status, index: 2 };
          })
          .sort((a, b) => a.index - b.index)
          .map(
            status =>
              status && (
                <div key={status.index} className={classes.iconStatus}>
                  <IconStatus status={status.status} />
                </div>
              ),
          )}
      </div>
    );
  };
  const handleChange = useCallback(
    (event: SelectChangeEvent<typeof statusNameVK>) => {
      const {
        target: { value },
      } = event;
      // @ts-ignore
      setStatusesFilter(value);
      // Отправляем конверсию в Яндекс.Метрику
      sendMetricGoal('usage_vk_filter_status', 'manage/vk');
    },
    [statusesFilter],
  );
  return (
    <FormControl classes={{ root: classes.form }} className={classes.form}>
      <label className={classes.label}>{STATUS_LABEL}</label>

      <Select
        onOpen={e => setIsOpen(true)}
        onClose={e => setIsOpen(false)}
        className={clsx(classes.inputWrap, isOpen && classes.inputSelect, classes.pdLabel)}
        value={statusesFilter}
        multiple
        onChange={handleChange}
        IconComponent={SortDownSolidET}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 0,
          },
          transformOrigin: {
            vertical: -16,
            horizontal: 'left',
          },
          classes: {
            root: classes.menuOverlay,
            paper: classes.paperStatusesList,
          },
        }}
        componentsProps={{}}
        inputProps={{
          classes: {
            select: classes.selectStatus,
            icon: clsx(classes.rotatable, classes.iconSize),
            iconOpen: classes.deg180,
          },
        }}
        classes={{ root: clsx(classes.root, classes.pdLabel) }}
        displayEmpty
        renderValue={statusesFilter => renderContentValue(statusesFilter.length === 0 ? statusNameVK : statusesFilter)}
      >
        {statusNameVK.map((status, index) => RenderMenuItem(status, statusesFilter, tableLoading, index))}
      </Select>
    </FormControl>
  );
};
