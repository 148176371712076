import { Typography } from '@plarin/design';
import { DefaultInput } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { TShortClientsData } from '../../../../../../types/reports/reports';
import { useAppDataStore } from '../../../../app-data-provider';
import classes from '../../reports-drawer-content.module.scss';
import { CustomMenu } from './custom-menu';
import { SelectedClientsMenu } from './selected-clients-menu';

export const ReportClients = observer(
  ({
    filterValue,
    setFilterValue,
    reportClients,
    deleteAction,
    addClient,
    deleteClient,
  }: {
    filterValue: string;
    setFilterValue: (filterValue: string) => void;
    reportClients: TShortClientsData;
    deleteAction: (id: number) => void;
    addClient: (client: TShortClientsData[0]) => void;
    deleteClient: (id: number) => void;
  }) => {
    const {
      reports: { getClientsShort, shortClients },
    } = useAppDataStore();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setAnchorEl(event.target);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [userClients, setUserClients] = useState<TShortClientsData>([]);

    useEffect(() => {
      getClientsShort().then(() => {
        const userClients = shortClients.clients.map(client => ({
          ...client,
          client_client_name: client.account_name || client.account_username,
        }));

        const arr = [...reportClients, ...userClients];
        const ids = arr.map(o => o.account_id);
        const filtered = arr.filter(({ account_id: id }, index) => !ids.includes(id, index + 1));
        setUserClients(filtered);
      });
    }, []);

    return (
      <div className={clsx(classes.blockWithLabel, classes.clientsBlock)}>
        <Typography weight={600} size="Main">
          Рекламные кабинеты
        </Typography>
        <DefaultInput
          value={filterValue}
          setValue={setFilterValue}
          placeholder="Поиск рекламного кабинета"
          size={48}
          clearInputAction={() => setFilterValue('')}
          searchIcon
          onFocusAction={handleClick}
          onBlurAction={handleClose}
        />
        <CustomMenu
          open={Boolean(anchorEl)}
          loading={shortClients.loading}
          error={shortClients.error}
          clients={userClients}
          addClient={addClient}
          deleteClient={deleteClient}
          reportClients={reportClients}
          filterValue={filterValue}
        />
        <SelectedClientsMenu deleteAction={deleteAction} reportClients={reportClients} />
      </div>
    );
  },
);
