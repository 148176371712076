import { DeleteIcon, PauseIcon, PlayIcon, RedoIcon, LoaderIcon } from '@plarin/design';
import { statusToSet, StatusToSet } from '@plarin/inputs';
import React from 'react';
import { ActionKeysEnum, EditListEnum } from './commonEnums';

type TEditMenuList = {
  key: ActionKeysEnum;
  icon?: JSX.Element;
  // rightIcon?: JSX.Element;
  label?: string;
  // disabled?: boolean;
  typeEdit?: EditListEnum; // при редактировании бюджета, лимита
  status: StatusToSet;
}[];

const RESUME = statusToSet.RESUME;
const SUSPEND = statusToSet.SUSPEND;
const ARCHIVE = statusToSet.ARCHIVE;
const UNARCHIVE = statusToSet.UNARCHIVE;
const MODERATE = statusToSet.MODERATE;

export const editMenuListCampaigns: TEditMenuList = [
  { key: ActionKeysEnum.resume, icon: <PlayIcon />, label: 'Запустить', status: RESUME },
  { key: ActionKeysEnum.suspend, icon: <PauseIcon />, label: 'Остановить', status: SUSPEND },
  { key: ActionKeysEnum.archive, icon: <DeleteIcon />, label: 'Архивировать', status: ARCHIVE },
  { key: ActionKeysEnum.unarchive, icon: <RedoIcon />, label: 'Разархивировать', status: UNARCHIVE },
];

export const editMenuListAds: TEditMenuList = [
  { key: ActionKeysEnum.resume, icon: <PlayIcon />, label: 'Запустить', status: RESUME },
  { key: ActionKeysEnum.suspend, icon: <PauseIcon />, label: 'Остановить', status: SUSPEND },
  { key: ActionKeysEnum.moderate, icon: <LoaderIcon size={20} />, label: 'На модерацию', status: MODERATE },
  { key: ActionKeysEnum.archive, icon: <DeleteIcon />, label: 'Архивировать', status: ARCHIVE },
  { key: ActionKeysEnum.unarchive, icon: <RedoIcon />, label: 'Разархивировать', status: UNARCHIVE },
];

