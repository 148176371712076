import { Typography } from '@plarin/design';
import { Button, Checkbox, Input, ReCaptchaV3 } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { AuthInputsTemplate } from '../../components/auth/auth-inputs-template';
import { ymDisableClass } from '../../utils/constants';
import { LegalDocuments } from './../../enums';
import { RegistrationEmailFormStore } from './registration-email-form.store';
import classes from './style.module.scss';

export type TRegistrationEmailFormProps = {
  store: RegistrationEmailFormStore;
};

export const RegistrationEmailForm: React.VFC<TRegistrationEmailFormProps> = observer(({ store }) => {
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const [policyChecked, setPolicyChecked] = React.useState(true);

  const { errors, data, getErrorMsg, onChange, onBlur, submit, needCaptcha, setCaptchaToken, isPending } = store;

  const emailClearAction = () => {
    data.email = '';
  };

  const emailOnFocus = () => {
    setIsInputFocused(true);
  };

  const emailOnBlur = () => {
    setIsInputFocused(false);
    onBlur('email');
  };

  return (
    <AuthInputsTemplate formProps={{ onSubmit: submit, className: classes.emailReg }}>
      <Input
        name="email"
        type="email"
        label="Электронная почта"
        value={data.email}
        error={!!errors.email}
        hint={getErrorMsg('email')}
        onChange={onChange('email')}
        onFocus={emailOnFocus}
        onBlur={emailOnBlur}
        isFocused={isInputFocused}
        action={emailClearAction}
        autoFocus
        disabled={isPending}
        className={ymDisableClass}
        disableMinHeight={true}
        size={48}
      />
      {needCaptcha && <ReCaptchaV3 siteKey={RECAPTCHA_SITE_KEY} onResponse={setCaptchaToken} />}
      <div className={clsx(classes.policy, classes.policyPaddingTop2, classes.policyMarginTop)}>
        <Checkbox
          value={policyChecked}
          onChange={setPolicyChecked}
          size="medium"
          className={classes.disableMarginLabel}
        />
        <Typography size="Caption" componentProps={{ className: 'containerWidth' }} color="TextSecondary">
          Регистрируясь, я принимаю условия{' '}
          <Typography
            underline
            color="linkPrimary"
            component="a"
            componentProps={{ href: LegalDocuments.LICENSE_AGREEMENT, target: '_blank' }}
          >
            лицензионного договора
          </Typography>
          , даю{' '}
          <Typography
            underline
            color="linkPrimary"
            component="a"
            componentProps={{ href: LegalDocuments.PERSONAL_DATA_PROCESSING_CONSENT, target: '_blank' }}
          >
            согласие на обработку персональных данных
          </Typography>{' '}
          и
          <Typography
            underline
            color="linkPrimary"
            component="a"
            componentProps={{ href: LegalDocuments.INFORMATION_MESSAGES_CONSENT, target: '_blank' }}
          >
            {' '}
            согласие
            <br /> на получение информационных сообщений
          </Typography>
        </Typography>
      </div>
      {isPending ? (
        <Button variant="filled" size="medium" color="primary" loading={true} />
      ) : (
        <Button variant="filled" size="medium" color="primary" submit={true} disabled={!policyChecked}>
          Зарегистрироваться
        </Button>
      )}
    </AuthInputsTemplate>
  );
});
