import { CloseIcon, Typography } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import { Accept, FileError } from 'react-dropzone';
import { formatBytes, getFormatStrFile, getStrAcceptType } from '../../_utils';
import classes from './style.module.scss';

export interface SingleFileUploadProps {
  file: File;
  errors?: FileError[];
  onDelete: (file: File) => void;
  accept?: Accept;
  maxSize?: number;
}

export const SingleFileUpload = ({ file, onDelete, errors, accept, maxSize }: SingleFileUploadProps) => {
  const { name, type, size } = getFormatStrFile(file);
  const hasErrorType = errors?.find(error => error.code === 'file-invalid-type');
  const hasErrorLarge = errors?.find(error => error.code === 'file-too-large');
  return (
    <div>
      <div className={clsx(classes.item, hasErrorType || (hasErrorLarge && classes.itemError))}>
        <div className={classes.info}>
          <div className={classes.name}>
            {' '}
            <Typography size="main">{name}</Typography>{' '}
          </div>
          <div className={classes.type}>
            <Typography size="main" color="textSecondary">
              {type + ' · ' + size}
            </Typography>
          </div>
        </div>
        <CloseIcon className={classes.icon} size={16} onClick={() => onDelete(file)} />
      </div>
      {hasErrorType && accept && (
        <Typography componentProps={{ className: classes.typography }} size="SmallText" color="Error">
          {`Вы можете загрузить файлы следующего формата: ${getStrAcceptType(accept)} `}
        </Typography>
      )}
      {hasErrorLarge && maxSize && (
        <Typography componentProps={{ className: classes.typography }} size="SmallText" color="Error">
          {`Максимальный размер файла не должен превышать ${formatBytes(maxSize)} `}
        </Typography>
      )}
    </div>
  );
};
