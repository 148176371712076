import { Typography } from '@plarin/design';
import { ManageVkTabNameEnum, int } from '@plarin/utils';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { getFooterText } from '../../../table/utils';

interface CustomFooterRendererProps extends ICellRendererParams {
  tabName: ManageVkTabNameEnum;
}



export const CustomFooterRenderer = (params: CustomFooterRendererProps) => {
  const { tabName, api } = params;
  let count: number = 0;

  api.forEachNodeAfterFilter(node => {
    if (new Set(Object.keys(node.data)).has('orgHierarchy')) {
      node.data && !!node.data.orgHierarchy && (count += 1);
    } else {
      node.data && (count += 1);
    }
  });
  return <Typography weight={600}>{getFooterText(tabName, count)}</Typography>;
};
