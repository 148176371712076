import { MenuItem, Select, TextField } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { getCountries, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import classesPhone from './input-phone.module.scss';
import classes from './inputs.module.scss';
// import { Flag } from "./tags/flag";

const ruLocale: Record<string, string> = require('react-phone-number-input/locale/ru.json');

const countries = getCountries();
const phoneCodes = countries.map(country => ({
  country,
  phoneCode: getCountryCallingCode(country),
  label: ruLocale[country as 'RU'] as string,
}));

const mapCountryToPhoneCode = Object.fromEntries(phoneCodes.map(({ country, phoneCode }) => [country, phoneCode]));

interface InputPhoneProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  name: string;
  onBlur?: VoidFunction;
  error?: boolean;
  hint?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  selectLabel?: string;
}

const CustomInput = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & Record<string, any>
>(({ customProps, ...inputProps }, ref) => {
  const { error, hint, label, name, autoFocus, disabled } = customProps;

  return (
    <TextField
      inputRef={ref}
      inputProps={{ maxLength: 13, ...inputProps }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
          notchedOutline: 'visuallyHidden',
          focused: classes.inputFocused,
          error: classes.inputError,
          disabled: classes.inputDisabled,
          adornedStart: classes.inputAdorned,
        },
        notched: false,
      }}
      InputLabelProps={{
        classes: {
          root: classes.inputLabelRoot,
          error: classes.inputLabelError,
          disabled: classes.inputLabelDisabled,
          focused: classes.inputLabelFocused,
          filled: classes.inputLabelFilled,
        },
      }}
      label={label}
      helperText={hint}
      disabled={disabled}
      error={error}
      name={name}
      FormHelperTextProps={{
        classes: {
          root: classes.inputHelperRoot,
          error: classes.inputHelperError,
          disabled: classes.inputHelperDisabled,
        },
      }}
      autoFocus={autoFocus}
      fullWidth
    />
  );
});

export const InputPhone: React.VFC<InputPhoneProps> = ({
  error,
  hint,
  label,
  name,
  onBlur,
  onChange,
  value,
  autoFocus,
  selectLabel,
}) => {
  const pPhone = value?.length && parsePhoneNumber(value);
  const [country, setCountry] = React.useState(pPhone && pPhone.country ? pPhone.country : 'RU');

  function handleChange(phone?: string) {
    onChange(phone ?? '');
  }

  return (
    <div className={clsx(classesPhone.inputPhoneContainer, classes.inputRootWrapper)}>
      <Select
        className={clsx(classes.inputRoot, classesPhone.inputPhoneSelect)}
        classes={{
          select: classesPhone.inputPhoneSelector,
          iconOutlined: classesPhone.inputPhoneSelectorIcon,
        }}
        value={country}
        onChange={e => setCountry(e.target.value)}
        renderValue={country => (
          <div className={classesPhone.inputPhoneMenuItem}>
            {/* <Flag country={country} />*/}
            <span>+{mapCountryToPhoneCode[country]}</span>
          </div>
        )}
        MenuProps={{
          className: classesPhone.inputPhoneMenu,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        label={selectLabel}
      >
        {phoneCodes.map(({ country, label }) => (
          // когда будет задействован InputPhone поменять MenuItem на CustomMenuItem
          <MenuItem value={country} key={country}>
            <div className={classes.inputPhoneMenuItem}>
              {/* <Flag country={country} />*/}
              <span dangerouslySetInnerHTML={{ __html: label }} />
            </div>
          </MenuItem>
        ))}
      </Select>
      <PhoneInput
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        country={country}
        international={true}
        inputComponent={CustomInput}
        customProps={{
          error,
          hint,
          label,
          name,
          autoFocus,
        }}
      />
    </div>
  );
};
