import React, { useEffect } from 'react';

type TWindowReCAPTCHA = Window &
  typeof globalThis & {
    grecaptcha?: {
      execute: (siteKey: string, options: object) => Promise<any>;
      getResponse: VoidFunction;
      ready: (callback: VoidFunction) => void;
      render: VoidFunction;
      reset: VoidFunction;
    };
  };

export type TReCaptchaV3Props = {
  siteKey: string;
  onResponse: (token: string) => void;
};

const handleLoaded = (siteKey: string, callback: TReCaptchaV3Props['onResponse']) => () =>
  (window as TWindowReCAPTCHA).grecaptcha!.ready(() =>
    (window as TWindowReCAPTCHA)
      .grecaptcha!.execute(siteKey, { action: 'login' })
      .then((token: string) => callback(token)),
  );

export const ReCaptchaV3: React.VFC<TReCaptchaV3Props> = ({ siteKey, onResponse }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
    script.addEventListener('load', handleLoaded(siteKey, onResponse));
    document.body.appendChild(script);
    return () => {
      delete (window as TWindowReCAPTCHA).grecaptcha;
      document.querySelectorAll('.grecaptcha-badge').forEach(el => el.parentElement?.removeChild(el));
      document.body.removeChild(script);
    };
  }, [onResponse, siteKey]);
  return (
    <div
      className="g-recaptcha"
      data-sitekey={siteKey}
      data-size="invisible"
      style={{
        display: 'none',
      }}
    />
  );
};
