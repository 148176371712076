import { switchFilter } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { TReportData } from '../../../../types/reports/reports';
import { useAppDataStore } from '../../../components/app-data-provider';
import { filterReports, getSearchResults } from '../../../utils/searchList';
import { PeriodItem } from './components';
import { FetchReportsError, NoReportsError, ReportsNoSearch } from './components/reports-error';
import classes from './styles.module.scss';

export const ReportsListComponent = () => {
  const {
    reports: { reportsList, searchValue, openDrawer, getReports, isError, getClientsShort, shortClients },
  } = useAppDataStore();

  const [rendererList, setRenderList] = useState<TReportData[]>(reportsList);

  useEffect(() => {
    setTimeout(() => {
      getSearchResults(searchValue, filterReports, setRenderList, reportsList);
    }, 200);
  }, [searchValue, reportsList]);

  useEffect(() => {
    reportsList.length && getClientsShort();
  }, [reportsList]);

  if (isError) {
    return (
      <div className={classes.listContainer}>
        <FetchReportsError onClick={getReports} />
      </div>
    );
  }

  if (!reportsList.length) {
    return (
      <div className={classes.listContainer}>
        <NoReportsError onClick={openDrawer} />
      </div>
    );
  }

  return (
    <div className={clsx(classes.listContainer, rendererList.length && classes.paddings)}>
      {rendererList.length ? (
        rendererList.map(report => (
          <PeriodItem
            key={report._id}
            data={report}
            filterValue={filterReports(searchValue, reportsList).length ? searchValue : switchFilter(searchValue)}
          />
        ))
      ) : (
        <ReportsNoSearch />
      )}
    </div>
  );
};

export const ReportsList = observer(ReportsListComponent);
