import { WSRolesEnum } from '../../types/workspace/enum';
import { TWSRolesVariants } from '../../types/workspace/types';

export const WSRolesVariants: TWSRolesVariants[] = [
  {
    value: 'wsrole:admin',
    label: WSRolesEnum['wsrole:admin'],
  },
  {
    value: 'wsrole:employee',
    label: WSRolesEnum['wsrole:employee'],
  },
  {
    value: 'wsrole:guest',
    label: WSRolesEnum['wsrole:guest'],
  },
];
