import { PlusIcon, RedoIcon } from '@plarin/design';
import { ActionTableButton, Button, DefaultInput } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useAppDataStore } from '../../../components/app-data-provider';
import { BlockβVer } from '../../../components/suggest-idea';
import classes from './styles.module.scss';

export const ReportsHeader = observer(() => {
  const {
    reports: { openDrawer, isLoading, searchValue, setSearchValue, getReports },
  } = useAppDataStore();

  useEffect(() => {
    setSearchValue('');
  }, []);

  const onOpenDrawer = useCallback(() => {
    openDrawer('initialState');
  }, [openDrawer]);

  return (
    <div className={classes.header}>
      <div className={clsx('report-list-item', classes.item)}>
        <div className={classes.leftBlock}>
          <Button variant="filled" size="small" color="primary" onClick={onOpenDrawer}>
            <div className={classes.buttonContainer}>Создать</div>
          </Button>
          <BlockβVer />
        </div>
        <div className={classes.rightBlock}>
          <DefaultInput
            className={classes.mr15}
            value={searchValue}
            setValue={setSearchValue}
            placeholder="Поиск"
            searchIcon
            clearInputAction={() => setSearchValue('')}
          />

          <ActionTableButton
            textTooltip="Обновить"
            action={getReports}
            loading={isLoading}
            icon={<RedoIcon />}
            isReloadButton={true}
          />
        </div>
      </div>
    </div>
  );
});
