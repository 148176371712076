import { Typography } from '@plarin/design';
import { DateRangeInput, TDateOption } from '@plarin/inputs';
import React, { useCallback } from 'react';
import { TReportData } from '../../../../../types/reports/reports';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { dateAdapter, periodDatePickerAdapter, responseReportAdapter } from '../../../../utils/reports';
import classes from './styles.module.scss';

export const ReportsPeriod = ({ data }: { data: TReportData }) => {
  const {
    reports: { updateReport },
  } = useAppDataStore();

  const setDate = useCallback(() => {
    let isFirstDate = false;
    return (date: TDateOption) => {
      isFirstDate && updateReport({ ...responseReportAdapter(data), ...dateAdapter(date), id: data._id });
      isFirstDate = true;
    };
  }, [data, updateReport]);

  return (
    <div className={classes.actionTextBlock}>
      <Typography size="Caption" weight={600}>
        Период отчета
      </Typography>
      <DateRangeInput
        withQuarters
        menuHide
        sizeS
        setDateOption={setDate()}
        date={periodDatePickerAdapter({
          storeEndDate: data.date_to,
          storeStartDate: data.date_from,
          storeLabel: data.relative_period,
        })}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: -8 }}
        componentClasses={{ container: classes.textContainer, paper: classes.paper }}
        renderComponent={({ onClick, refAnchor, value, labelConstants, label }) => {
          return (
            <Typography
              size="Caption"
              color="LinkPrimary"
              componentProps={{ className: classes.textAction, onClick: onClick, ref: refAnchor }}
            >
              {label === labelConstants.PERIOD ? value : label === 'Период' ? labelConstants.All_TIME : label}
            </Typography>
          );
        }}
      />
    </div>
  );
};
