import React, { useState, useEffect } from 'react';
import { InputPhone2, setEmptyErrorMessage, rusNumberIsValid } from '../inputs';
import { ModalActionNamesEnum, TSetModalAction } from '../modal';
import { TypeChangePhone } from './types';

type TChangePhoneProps = {
  currentPhone: string | undefined;
  public_id: string;
  setModalAction: TSetModalAction;
  changePhone: (data: TypeChangePhone, id: string) => Promise<void>;
  isLoading: boolean | undefined;
  closeModal: () => boolean;
};

export const SetPhoneContent = ({
  currentPhone,
  public_id,
  setModalAction,
  changePhone,
  isLoading,
  closeModal,
}: TChangePhoneProps) => {
  const [phone, setPhone] = useState(currentPhone || '');
  const [checkLength, setCheckLength] = useState(false);

  const getAction = () => {
    if (phone.length < 2) {
      return setEmptyErrorMessage(phone, setPhone);
    }
    if (!rusNumberIsValid(phone)) {
      !checkLength && setCheckLength(true);
      return null;
    }
    if (phone === currentPhone || '+' + phone === currentPhone) {
      return closeModal();
    }
    return changePhone({ phone: `+${phone}` }, public_id);
  };

  useEffect(() => {
    if (phone.length >= 11 && !checkLength) {
      setCheckLength(true);
    }
  }, [phone, checkLength]);

  useEffect(() => {
    setModalAction({
      action: getAction,
      actionTitle: isLoading ? ModalActionNamesEnum.Change : ModalActionNamesEnum.Save,
    });
  });

  return <InputPhone2 value={currentPhone} onChange={setPhone} checkLength={checkLength} />;
};
