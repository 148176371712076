import { SmallArrowDownIcon, Typography } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import { Popover } from '../popover';
import { Button } from './button';
import classes from './select-button.module.scss';

type RenderList = {
  key: string;
  label: string;
  rowsCount?: number;
  action: () => void;
  iconRight?: ((e?: boolean | undefined) => JSX.Element) | undefined;
  iconLeft?: ((e?: boolean | undefined) => JSX.Element) | undefined;
};

type SelectButtonProps = {
  renderList?: RenderList[];
  disabled?: boolean;
};

export const SelectButton = ({ renderList, disabled }: SelectButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataList = renderList?.map(el => {
    return {
      name: el.label,
      action: () => {
        el.action();
        handleClose();
      },
      iconLeft: el.iconLeft,
      iconRight: el.iconRight,
    };
  });

  return (
    <>
      <Button
        onClick={handleClick}
        color="secondary"
        disabled={disabled}
        active={open}
        className={classes.selectButton}
      >
        <div className={classes.buttonContent}>
          <Typography weight={600} size="Main" color={disabled ? 'TextDisabled' : 'TextPrimary'}>
            Действия
          </Typography>
          <SmallArrowDownIcon className={clsx(open && classes.rotate)} color={disabled ? 'disabled' : 'main'} />
        </div>
      </Button>
      <Popover setIsOpen={handleClose} anchorEl={anchorEl} isOpen={open} dataList={dataList} />
    </>
  );
};
