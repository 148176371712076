import { AxiosError, AxiosResponse } from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { List, TComplexity, TNewPasswordComplexityStyle, TPasswordData } from '../types/kateTypesTemporary';
import { getApiUrl, httpClient } from '../utils';

export class BasicStore {
  public isPending = false;
  public isFulfilled = false;
  public error: any | null = null;
  notifications: List[] = [];
  newPasswordStyle?: TComplexity;

  constructor() {
    makeObservable(this, {
      isPending: observable,
      isFulfilled: observable,
      error: observable,
      execRequest: action.bound,
      notifications: observable,
      newPasswordStyle: observable,
    });
  }

  public setNewPasswordStyle = (style?: TNewPasswordComplexityStyle) =>
    runInAction(() => (this.newPasswordStyle = style));

  public setNoError = (error?: string) => runInAction(() => (this.error = error));

  public execRequest<T = unknown[]>(req: Promise<AxiosResponse> | Promise<AxiosResponse>[]) {
    this.isPending = true;
    this.isFulfilled = false;
    return new Promise<T>((resolve, reject) => {
      (Array.isArray(req) ? Promise.all(req) : req)
        .then(resp => {
          runInAction(() => {
            this.isPending = false;
            this.isFulfilled = true;
            if (Array.isArray(resp)) {
              const resArray: T = resp.map(response => response.data) as unknown as T;
              resolve(resArray);
            } else {
              resolve(resp.data);
            }
          });
        })
        .catch((error: AxiosError<any>) => {
          runInAction(() => {
            this.isPending = false;
            reject(error);
          });
        });
    });
  }

  // fixme: Should be fixed types
  public execRequestAllSettled(req: Promise<AxiosResponse>[]) {
    runInAction(() => {
      this.isPending = true;
      this.isFulfilled = false;
    });
    return new Promise((resolve, reject) => {
      Promise.allSettled(req)
        .then(resp => {
          runInAction(() => {
            this.isPending = false;
            this.isFulfilled = true;
            resp.forEach(item => {
              if (item.status === 'fulfilled') {
                item.value = item.value?.data;
              }
            });
            resolve(resp);
          });
        })
        .catch((error: AxiosError<any>) => {
          runInAction(() => {
            this.isPending = false;
            reject(error);
          });
        });
    });
  }

  // ============ notifications ================== //

  public setNotifications = (notifications: List[]) => runInAction(() => (this.notifications = notifications));

  public addNotification = ({ ...notification }: List) => {
    this.setNotifications([
      ...this.notifications,
      {
        ...notification,
        key: notification.key || Date.now(),
      },
    ]);
  };

  public deleteNotification = (id: number) => {
    this.setNotifications(this.notifications.filter(item => id !== item.key));
  };

  checkNewPassword = async (data: TPasswordData) => {
    httpClient.post(getApiUrl('/api/v1/user/password/check'), data).then(resp => {
      const complexity = resp.data.data?.complexity;
      switch (complexity) {
        case 'medium':
          return this.setNewPasswordStyle('yellow');
        case 'strong':
          return this.setNewPasswordStyle('green');
        default:
          return this.setNewPasswordStyle('red');
      }
    });
  };
}
