export const downloadFile = (blob: any, name: string) => {
  // @ts-ignore

  try {
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = href;
    link.download = name as string;
    link.click();
    URL.revokeObjectURL(href);
  } catch (e) {}
};
