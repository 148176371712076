import { EmptyTableFindIcon, Typography } from '@plarin/design';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import classes from './style.module.scss';

interface TEmptyComp<Data> {
  gridRef: React.RefObject<AgGridReact<any>>;
  agNoRowsOverlay?: () => JSX.Element;
}

export const EmptyDataAfterFilter = <Data,>({ gridRef, agNoRowsOverlay }: TEmptyComp<Data[]>) => {
  const isRenderedNodes = gridRef.current?.api.getRenderedNodes().length;

  if ((gridRef.current?.api.isQuickFilterPresent() && !isRenderedNodes) || !agNoRowsOverlay) {
    return (
      <div className={classes.noRowsOverlay}>
        <EmptyTableFindIcon />
        <div className={classes.text}>
          <Typography size="AuthSubheader" color="TextSecondary" align="center">
            В соответствии с настройками
            <br />и фильтрами ничего не найдено.
          </Typography>
        </div>
      </div>
    );
  }
  if (!isRenderedNodes && agNoRowsOverlay) {
    return <>{agNoRowsOverlay()}</>;
  }
  return null;
};
