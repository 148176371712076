import clsx from 'clsx';
import React from 'react';
import { SocialButtonsLayoutProps } from './social-buttons-layout-props';

export const SocialButtonsLayout: React.FC<SocialButtonsLayoutProps> = function SocialButtonsLayout({
  children,
  inline,
  classProps,
}) {
  return (
    <div
      className={clsx(
        'social-buttons-layout',
        {
          'social-buttons-layout__inline': inline,
        },
        classProps && classProps,
      )}
    >
      {children}
    </div>
  );
};
