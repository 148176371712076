import { Typography } from '@plarin/design';
import { typografText } from '@plarin/utils';
import React from 'react';
import { NetworkEnum } from '../../../dictionary/integrations';
import { SocialButtons } from '../../social-buttons';
import classes from './drawer-select-connect.module.scss';

type DrawerSelectConnectProps = {
  action?: (network: NetworkEnum) => void;
};

export const DrawerSelectConnect = ({ action }: DrawerSelectConnectProps) => (
  <div className={classes.selectConnect}>
    <div className={classes.textForConnect}>
      <Typography size="AuthContent">
        {typografText('Выберите рекламную систему, аккаунт c рекламными кампаниями которой вы хотите')} <br />
        {typografText('подключить к платформе Plarin')}
      </Typography>
    </div>
    <SocialButtons action={action} />
  </div>
);
