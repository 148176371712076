import { CloseCircleIcon, Typography } from '@plarin/design';
import { ActionTableButton, Popover, CustomList, ManageYDTabs, objYDTypes } from '@plarin/inputs';
import { isUrlProd } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useMemo, useRef, useState } from 'react';
import { TObjType } from '../../../types/metrics';
import { useAppDataStore } from '../app-data-provider';
import { GroupingButtonContent } from './grouping-button-content';
import { GroupingNameItem } from './grouping-name-item';
import classes from './style.module.scss';

export const TableGroupingYD = observer(({ tab }: { tab: ManageYDTabs }) => {
  const {
    manageYD: { metricsForGroupingYD, setGroupingMetrics },
  } = useAppDataStore();
  !isUrlProd && console.log(objYDTypes[tab], metricsForGroupingYD);

  const metrics = metricsForGroupingYD[objYDTypes[tab]];

  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [arrowActive, setArrowActive] = useState(false);
  const [popoverClose, setpopoverClose] = useState(false);
  const [closeTimeoutId, setCloseTimeoutId] = useState<number | null>(null);

  const openMenu = () => {
    setArrowActive(true);
    setpopoverClose(false);
    setOpen(true);
    clearTimeout(closeTimeoutId!);
  };

  const closeMenu = () => {
    setArrowActive(false);
    setpopoverClose(true);
    const timeoutId = window.setTimeout(() => setOpen(false), 245);
    setCloseTimeoutId(timeoutId);
  };

  const active = useMemo(() => metrics.some(el => el.checked), [metricsForGroupingYD]);

  const checkedMetric = metrics.filter(el => el.checked)[0];
  const groupingName = checkedMetric ? checkedMetric.name?.ru : '';
  const parsedMetricsForGrouping = active ? [checkedMetric, ...metrics.filter(el => !el.checked)] : metrics;

  return (
    <>
      <div ref={ref} className={classes.groupingZone}>
        <ActionTableButton
          className={classes.buttonWidth}
          textTooltip={active ? `Сгруппировано по: ${groupingName}` : 'Сгруппировать объекты'}
          placement="top"
          action={openMenu}
          icon={<GroupingButtonContent isOpen={arrowActive} isActive={active} />}
          active={open}
        />
      </div>
      <Popover
        isListLeft
        anchorEl={ref.current}
        isOpen={open}
        setIsOpen={closeMenu}
        classNameWrapper={popoverClose ? classes.popoverClosed : classes.popoverOpen}
      >
        <div className={classes.menuContent}>
          <CustomList classProps={classes.menuList}>
            <>
              {parsedMetricsForGrouping.map(el => (
                <GroupingNameItem
                  metric={el}
                  key={el.path}
                  action={() => {
                    closeMenu();
                    setGroupingMetrics(tab, el.path, el);
                  }}
                />
              ))}
            </>
          </CustomList>
          <div className={classes.hr} />
          <div
            className={clsx(classes.resetGrouping, active && classes.hoverResetGrouping)}
            onClick={() => {
              if (active) {
                closeMenu();
                setGroupingMetrics(tab, '');
              }
            }}
          >
            <CloseCircleIcon color={active ? 'main' : 'disabled'} />
            <Typography size="Main" color={active ? 'TextMein' : 'TextDisabled'}>
              Сбросить группировку
            </Typography>
          </div>
        </div>
      </Popover>
    </>
  );
});
