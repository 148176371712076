import { SettingsIcon } from '@plarin/design';
import {
  Checkbox,
  ContextMenuProps,
  Popover,
  DateRangeInput,
  TableTab,
  TableTabPanel,
  TableTabs,
  MainTooltip,
  SearchDefaultInput,
  SelectVKStatus,
  StatusEnumVK,
  TDateOption,
  CustomSelect,
  ListVariants,
} from '@plarin/inputs';
import clsx from 'clsx';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DateOptions, TTabsData } from '../../../types/common-types';
import { FilterState } from '../../../types/manage-vk/types';
import { TStatusYD } from '../../../types/manage-yd/types';
import { RedirectToRoot } from '../../routing/routes';
import classes from './style.module.scss';

type DOptions = Pick<TDateOption, 'endDate' | 'startDate'> | string;

export interface LayoutTableProps<T> {
  tabsData?: TTabsData;
  children?: JSX.Element;
  disableStatusSelection?: boolean;
  singleStatusValue?: TStatusYD;
  setSingleStatusValue?: React.Dispatch<React.SetStateAction<TStatusYD>>;
  singleStatusListVariants?: ListVariants[];
  filterValue: string | undefined;
  setFilterValue: (str: string) => void;
  setFilterStatuses?: (status: StatusEnumVK[]) => void;
  statuses?: StatusEnumVK[];
  setDateFilterOptions?: (option: TDateOption) => void;
  dateOption?: DOptions;
  date?: DateOptions;
  tableLoading?: boolean;
}

export const LayoutTable = <T extends FilterState>({
  tabsData: { settings, tabs } = { settings: false, tabs: [] },
  children,
  singleStatusValue,
  setSingleStatusValue,
  singleStatusListVariants,
  disableStatusSelection,
  filterValue,
  setFilterValue,
  setFilterStatuses,
  statuses,
  setDateFilterOptions,
  date,
  tableLoading,
}: LayoutTableProps<T>) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const anchorEl = useRef(null);
  const [isIconHover, setIsIconHover] = useState<boolean>(false);
  const [isOpenSettings, setOpenSettings] = useState(false);
  const [tabsPaths, setTabsPaths] = useState<string[]>(tabs.map(tab => tab.path));

  const goToTabContent = useCallback(
    (path: string) => window.location.pathname !== path && history.push(path),
    [history],
  );

  const options: ContextMenuProps['options'] = tabs;
  const openMenu = useCallback(() => setOpenSettings(true), []);
  const closeMenu = useCallback(() => setOpenSettings(false), []);

  const enableSettingsIcon = () => setIsIconHover(true);
  const disableSettingsIcon = () => setIsIconHover(false);

  useEffect(() => {
    tabs && tabs.length && setTabsPaths(tabs.map(tab => tab.path));
  }, [tabs]);

  // если url не строго соответствует тому, который должен быть при нахождении в определённой вкладке таблицы, редиректим на корневую страницу воркспейса
  if (tabsPaths.length && !tabsPaths.find(path => path === decodeURIComponent(window.location.pathname))) {
    return RedirectToRoot();
  }

  const dataListOptions = options?.map(el => {
    return {
      name: el.label,
      isDisabled: true,
      iconRight: (e: boolean | undefined) => {
        return (
          <Checkbox
            data-label="svg"
            className={classes.checkbox}
            disabled={true}
            value={!!el.checked}
            size="small"
            isInverted={e}
          />
        );
      },

      action: (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
        el.action && el.action(e as React.MouseEvent<HTMLLIElement, MouseEvent>);
      },
    };
  });
  dataListOptions?.splice(0, 1);

  return (
    <div className={classes.tabsLayout}>
      <div className={classes.filterSection}>
        <SearchDefaultInput
          searchIconColor="main"
          placeholder="Поиск"
          filterValue={filterValue || ''}
          setFilter={setFilterValue}
          inputStyle="tableSearch"
          className={classes.search}
        />

        {/* Селект для выбора нескольких статусов - используется в РК ВК */}
        {setFilterStatuses && (
          <SelectVKStatus tableLoading={tableLoading} statusesFilter={statuses} setStatusesFilter={setFilterStatuses} />
        )}

        {/* Селект для выбора 1 статуса - используется в РК ЯД */}
        {singleStatusValue && setSingleStatusValue && singleStatusListVariants && (
          <CustomSelect
            label="Статус"
            value={singleStatusValue}
            onChange={setSingleStatusValue}
            className={clsx(classes.singleStatusInput, disableStatusSelection && classes.disabledSelect)}
            listVariants={singleStatusListVariants}
            menuHeight={240}
            listWidth={286}
            disabled={disableStatusSelection}
            iconType="statusDot"
            removeFocusOnClose={true}
          />
        )}

        {date && (
          <div className={classes.dateRangeInput}>
            <DateRangeInput isOpenBottom setDateOption={setDateFilterOptions} date={date} />
          </div>
        )}
      </div>
      {!!tabs.length && (
        <div className={classes.tabs}>
          <TableTabs value={id}>
            {tabs.map(
              ({ key, label, path, checkbox, checked, isGroup, rowsCount, deleteAction }) =>
                !isGroup &&
                ((checkbox && checked) || !checkbox) && (
                  <TableTab
                    key={key}
                    value={key}
                    onClick={() => goToTabContent(path)}
                    label={label}
                    rowsCount={rowsCount}
                    deleteAction={deleteAction}
                  />
                ),
            )}
            {/* {settings && (
              <MainTooltip tooltipMessage="Настройка вкладок" isVisible={true} component="span">
                <div ref={anchorEl}>
                  <TableTab
                    onMouseOver={enableSettingsIcon}
                    onMouseOut={disableSettingsIcon}
                    disableFocusRipple
                    settings
                    label={
                      <SettingsIcon
                        className={clsx(classes.rotatable, isIconHover && classes.deg180)}
                        color={isIconHover ? 'secondary' : 'main'}
                      />
                    }
                    onClick={openMenu}
                  />
                </div>
              </MainTooltip>
            )} */}
            <Popover
              titleText="Выберите вкладки"
              marginTop={0}
              classNameWrapper={classes.popupWrapper}
              isListLeft
              setIsOpen={closeMenu}
              anchorEl={anchorEl.current}
              isOpen={isOpenSettings}
              dataList={dataListOptions}
            />
          </TableTabs>
          {tabs.map(({ key, content }) => (
            <TableTabPanel key={key} hidden={id !== key}>
              {content}
            </TableTabPanel>
          ))}
        </div>
      )}
      {children}
    </div>
  );
};
