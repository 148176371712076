import React from 'react';

export const IconPartChecked = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="4" fill="#214ecb" />
      <rect x="4" y="7.19995" width="8" height="1.6" rx="0.8" fill="white" />
    </svg>
  );
};
