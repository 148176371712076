import { ManageVkTabNameEnum } from '@plarin/utils';
import { useAppDataStore } from '../../../../components/app-data-provider';

export const useGetStatTable = (currentTabName: ManageVkTabNameEnum) => {
  const {
    manageVK: { getStat, setTableLoading },
    profileStore: { loadWorkspaces, wsDictionary },
  } = useAppDataStore();

  return () => {
    setTableLoading(true);
    return loadWorkspaces().then(() => {
      getStat(currentTabName, wsDictionary).then();
    });
  };
};
