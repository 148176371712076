import { ShopCartIcon } from '@plarin/design';
import { Column, GetQuickFilterTextParams, DynamicTextError } from '@plarin/inputs';
import { syncCellRelativeDate } from '@plarin/utils';
import type { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { SettingsCellMenu } from '../../components/metrics-widgets';
import { SyncStatusEnum } from '../../dictionary/integrations';
import { sortRow } from '../../utils/common';
import { STATUS } from '../../utils/constants';
import classes from './style.module.scss';

export const columns: Column[] = [
  {
    pinned: 'left',
    suppressMovable: true,
    lockPosition: true,
    lockPinned: true,
    resizable: false,
    headerName: '',
    headerClass: 'checkbox',
    headerCheckboxSelection: true,
    checkboxSelection: params => params.data.integrationStatusCode !== SyncStatusEnum.loading && !!params.data.items,
    cellRendererSelector: params => {
      if (params.data.integrationStatusCode === SyncStatusEnum.loading) {
        return { component: 'PreloaderRenderer' };
      } else return { component: 'agGroupCellRenderer' };
    },
    minWidth: 64,
    maxWidth: 64,
    cellRendererParams: {
      suppressCount: true,
      value: '',
      suppressDoubleClickExpand: true,
      suppressEnterExpand: true,
      loaderPrecisePositioning: -8,
    },
    cellStyle: { borderRight: 'none' },
    cellClass: 'YMGAStatusCode rowExpandedDisable',
    cellClassRules: {
      enabled: params => !params.data.orgHierarchy && params.data.plarin_status === 'enabled',
      disable: params => !params.data.orgHierarchy && params.data.plarin_status === 'disabled',
      blocked: params =>
        !params.data.orgHierarchy &&
        params.data.plarin_status === 'enabled' &&
        params.data.integrationStatusCode === 'blocked',
    },
    headerTooltip: 'true',
  },
  {
    headerName: 'Название представления',
    field: 'header1',
    headerComponentParams: { subheader: 'Ресурс и идентификатор' },
    cellRendererParams: {
      page: 'ga',
      isNoneCounter: true,
      first: 'header1',
      second: 'header2',
      // nextSecond: 'id',
      visibleIcon: 'ecommerce',
      icon: <ShopCartIcon color="tertiary" size={16} />,
      textTooltip: 'Доступны данные электронной торговли',
      textIcon: 'E-commerce',
      SettingsCellMenu: SettingsCellMenu,
      classNames: classes.groupName,
    },
    flex: 2,
    resizable: false,
    cellRendererSelector: e => {
      if (!e?.data?.items) {
        return { component: 'TwoLinesRenderer' };
      }
      return { component: 'GroupNameRenderer' };
    },
    cellClassRules: {
      'sync-status-disabled': param => param.data.plarin_status === STATUS.DISABLED,
      hasOrgHierarchy: params => !!params.node.data.orgHierarchy,
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) =>
      `${params?.data?.header1 || ''} ${params?.data?.header2 || ''} ${params?.data?.id || ''}`,

    comparator: (a, b) => sortRow(a, b),
  },
  {
    headerName: 'Проекты',
    cellRendererSelector: e => {
      if (!e?.data?.items) return { component: 'ProjectListRenderer', params: { projectsData: e?.data?.projects } };
    },
    flex: 1,
    resizable: false,
    sortable: false,
  },
  {
    headerName: 'Статус интеграции',
    field: 'integrationStatusCode',
    headerComponentParams: { subheader: 'Последнее обновление' },
    cellRendererSelector: params => {
      if (params.data.integrationStatusCode === SyncStatusEnum.loading) {
        return { component: 'PreloaderRenderer' };
      }
      if (params.node.level === 0) return { component: 'TwoLinesRenderer' };
    },
    cellRendererParams: (params: ICellRendererParams) => ({
      dateFormatter: syncCellRelativeDate,
      bullet: 'integrationStatusCode',
      alternativeBullet: 'plarin_status',
      first: 'integrationStatus',
      second: 'last_metadata_updated',
      canHasError:
        params.data.plarin_status === 'enabled' &&
        (params.data.integrationStatusCode === 'blocked' || params.data.syncStatusCode === 'blocked'),
      errorTitle: params.data.integrationStatus,
      textHint: <DynamicTextError name="Google" account={params?.data?.header1} />,
    }),
    cellClassRules: {
      'sync-status-disabled': param => param.data.plarin_status === STATUS.DISABLED,
    },
    flex: 1,
    resizable: false,
  },
  {
    headerName: 'Синхронизация данных',
    headerComponentParams: { subheader: 'Последняя синхронизация' },
    cellRendererSelector: params => {
      if (params.data.syncStatusCode === SyncStatusEnum.loading) {
        return { component: 'PreloaderRenderer' };
      }
      if (params.node.level !== 0 && params.node.data.projects.length) return { component: 'TwoLinesRenderer' };
    },
    cellRendererParams: (params: ICellRendererParams) => ({
      dateFormatter: syncCellRelativeDate,
      bullet: 'syncStatusCode',
      alternativeBullet: 'plarin_status',
      first: 'syncStatus',
      second: 'last_updated',
      canHasError:
        params.data.plarin_status === 'enabled' &&
        (params.data.integrationStatusCode === 'blocked' || params.data.syncStatusCode === 'blocked'),
      errorTitle: params.data.syncStatus,
      textHint: <DynamicTextError name="Google" account={params?.data?.header1} />,
    }),
    cellClassRules: {
      'sync-status-disabled': params => params.data.plarin_status === STATUS.DISABLED,
    },
    flex: 1,
    resizable: false,
    sortable: false,
  },
];
