function getParents(element: any): Array<HTMLElement> {
  const result: Array<HTMLElement> = [];
  for (let p = element && element.parentElement; p; p = p && p.parentElement) {
    result.push(p);
  }
  return result;
}

function isScrollable(el: any) {
  const isInput = el.tagName.toUpperCase() === 'INPUT';
  const overflowX = window.getComputedStyle(el).overflowX;
  return (overflowX !== 'hidden' && overflowX !== 'visible') || isInput;
}

/**
 * в маке есть функция "смахивание между страницами",
 * когда с помощью тача можно переключаться между записями истории в браузере
 * данные функция отключает это
 * @param event
 */
export function preventScrollBack(event: WheelEvent) {
  const isVerticalScroll = Math.abs(event.deltaY) > Math.abs(event.deltaX);
  // чтоб работало ток на маке
  // вертикальный скролл не трогаем
  if (!navigator.userAgent.match(/Macintosh/) || isVerticalScroll) {
    return;
  }
  const scrollableElements = ([event.target, ...getParents(event.target)] as Array<HTMLElement>).filter(el => {
    // скролл влево
    if (event.deltaX < 0) {
      return isScrollable(el) && el.scrollLeft > 0;
    }
    // скролл вправо
    else if (el.scrollWidth !== el.offsetWidth) {
      return isScrollable(el) && el.scrollWidth > el.offsetWidth + el.scrollLeft;
    }
    return false;
  });
  // если есть элементы, которые будут проскролены,
  // то мы ничего не делаем т.к. скроллбек в таком случае не работает,
  // в ином случае, делаем preventDefault
  if (scrollableElements.length === 0) {
    event.preventDefault();
  }
}
