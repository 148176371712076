import { shortDate } from '@plarin/utils';

type TMonthValue = '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12';

const getPrevMonthValue = (month: TMonthValue): TMonthValue => {
  switch (month) {
    case '01': {
      return '12';
    }
    case '02': {
      return '01';
    }
    case '03': {
      return '02';
    }
    case '04': {
      return '03';
    }
    case '05': {
      return '04';
    }
    case '06': {
      return '05';
    }
    case '07': {
      return '06';
    }
    case '08': {
      return '07';
    }
    case '09': {
      return '08';
    }
    case '10': {
      return '09';
    }
    case '11': {
      return '10';
    }
    case '12': {
      return '11';
    }
  }
};

const getYear = (year: string, month: TMonthValue) => {
  if (month === '12') {
    return String(Number(year) - 1);
  }

  return year;
};

// функция ожидает на вход строку типа '30.01.2022'
// а возвращает массив типа ['2022', '01', '30']
const normalizeDate = (date: string): [string, TMonthValue, string] => {
  // @ts-ignore
  return date.split('.').reverse();
};

// на входе функция получает '30.01.2022' а возвращает '2021.12.01' (первое число предыдущего месяца в формате, который можно передать внутрь new Date())
const getPrevMonthString = (currentDate: string): string => {
  // @ts-ignore
  const normalizedDate: [string, TMonthValue, string] = normalizeDate(currentDate); // здесь строка с датой разбивается на массив, чтобы далее можно было изменить дату
  normalizedDate[2] = '01'; // меняем любой день месяца на первый
  normalizedDate[1] = getPrevMonthValue(normalizedDate[1]); // получаем значение предыдущего месяца
  normalizedDate[0] = getYear(normalizedDate[0], normalizedDate[1]); // получаем правильный год (если строкой выше мы получили 12 месяц, то здесь нам нужен предыдущий год, а не текущий)

  return normalizedDate.join('.');
};

// Принимает дату в миллисекундах (Date.now() или +new Date(****.**.**)), а возвращает Date, соответствующий 1 числу предыдущего месяца по отношению к полученной дате.
// Зачем это нужно: мы должны получать Date с предыдущим месяцем, чтобы в DateRangePicker в левом календаре установился предыдущий, а в правом текущий месяц.
export const getPrevMonthDate = (currentDate: number) => {
  return new Date(getPrevMonthString(shortDate(currentDate)));
};

// возвращает дату в строковом виде, без времени: 'Thu Mar 31 2022'
export const getDateString = (date: Date | number) => {
  if (typeof date === 'number') {
    return new Date(date).toDateString();
  }

  return date.toDateString();
};
