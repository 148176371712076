export enum EditListEnum {
  bid = 'bid',
  limit = 'limit',
}

export enum ActionKeysEnum {
  divider = '0',
  resume = '1',
  suspend = '2',
  moderate = '3',
  archive = '4',
  unarchive = '5',
}
