import { Typography } from '@plarin/design';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

interface GroupingRendererProps extends ICellRendererParams {}

export const CampaignGroupingRenderer = (props: GroupingRendererProps) => {
  return (
    <Typography weight={600} size="Caption">
      {props.valueFormatted}
    </Typography>
  );
};
