export const debounce = (foo: any, ms: number) => {
  let timeout: any;
  return function (...args: any): any {
    const fooCallback = () => {
      // @ts-ignore
      foo.apply(this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(fooCallback, ms);
  };
};
