import * as punycode from 'punycode';
import * as Joi from '@hapi/joi';
import { allowedSymbolsValidateRule } from '../validator';

export const emailRegexp = new RegExp(
  '^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]{1,64}@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$',
);

export const emailAllowedSymbolsRegExp = new RegExp('^[a-zA-Zа-яА-ЯёЁ0-9.!#$%&\'*+\\/=?^_`{|@}~-]*$');

const emailValidationMethod: Joi.CustomValidator = (value: any, helpers: Joi.CustomHelpers) =>
  value.length > 0 && !punycode.toASCII(value).match(emailRegexp) ? helpers.error('string.email') : value;

export const emailValidateRule = Joi.string().custom(emailValidationMethod, 'email validation');

export const emailAllowedSymbols = allowedSymbolsValidateRule('a-zA-Zа-яА-ЯёЁ0-9.!#$%&\'*+\\/=?^_`{|@}~-');
