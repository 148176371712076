import { Typography } from '@plarin/design';
import { Button, Checkbox, Input, ReCaptchaV3 } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAppDataStore } from '../../components/app-data-provider';
import { AuthInputsTemplate } from '../../components/auth/auth-inputs-template';
import { SpaceBetween } from '../../components/space-between';
import { ymDisableClass } from '../../utils/constants';
import classes from '../registration/style.module.scss';
import { LegalDocuments } from './../../enums';
import { LoginEmailFormStore } from './login-email-form.store';

export type TLoginEmailFormProps = {
  store: LoginEmailFormStore;
};

export const LoginEmailForm: React.VFC<TLoginEmailFormProps> = observer(({ store }) => {
  const { data, submit, onChange, onBlur, errors, getErrorMsg, needCaptcha, setCaptchaToken, isPending } = store;

  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const [policyChecked, setPolicyChecked] = React.useState(true);

  const inputClearAction = () => {
    data.email = '';
  };

  const inputOnFocus = () => {
    setIsInputFocused(true);
  };

  const inputOnBlur = () => {
    setIsInputFocused(false);
    onBlur('email');
  };

  return (
    <AuthInputsTemplate
      formProps={{
        onSubmit: event => {
          submit(event);
        },
      }}
    >
      <Input
        name="email"
        type="email"
        label="Электронная почта"
        value={data.email}
        error={!!errors.email}
        hint={getErrorMsg('email')}
        onChange={onChange('email')}
        onFocus={inputOnFocus}
        onBlur={inputOnBlur}
        isFocused={isInputFocused}
        action={inputClearAction}
        fullWidth
        disabled={isPending}
        className={ymDisableClass}
        disableMinHeight={true}
        size={48}
      />
      <Input
        name="password"
        label="Пароль"
        type="password"
        value={data.password}
        error={!!errors.password}
        hint={getErrorMsg('password')}
        onChange={onChange('password')}
        onBlur={onBlur('password')}
        fullWidth
        disabled={isPending}
        className={ymDisableClass}
        disableMinHeight={true}
        size={48}
      />
      {needCaptcha && <ReCaptchaV3 siteKey={RECAPTCHA_SITE_KEY} onResponse={setCaptchaToken} />}

      <SpaceBetween>
        <Checkbox
          value={!!data.stay_logged_in}
          onChange={onChange('stay_logged_in')}
          label="Оставаться в системе"
          disabled={isPending}
          size="small"
          className={classes.disableMarginLabel}
          labelSize="caption"
        />
        <Typography
          underline
          size="caption"
          color="linkPrimary"
          component={Link}
          componentProps={{ to: '/reset-password' }}
        >
          Забыли пароль?
        </Typography>
      </SpaceBetween>
      <div className={clsx(classes.policy, classes.policyMarginBottom, classes.top)}>
        <Checkbox
          value={policyChecked}
          onChange={setPolicyChecked}
          disabled={isPending}
          size="small"
          className={classes.disableMarginLabel}
        />
        <Typography size="Caption" componentProps={{ className: 'containerWidth' }} color="TextSecondary">
          Входя в аккаунт, я принимаю условия{' '}
          <Typography
            underline
            color="linkPrimary"
            component="a"
            componentProps={{ href: LegalDocuments.LICENSE_AGREEMENT, target: '_blank' }}
          >
            лицензионного договора
          </Typography>
          , даю{' '}
          <Typography
            underline
            color="linkPrimary"
            component="a"
            componentProps={{ href: LegalDocuments.PERSONAL_DATA_PROCESSING_CONSENT, target: '_blank' }}
          >
            согласие на обработку персональных данных
          </Typography>{' '}
          <br />и
          <Typography
            underline
            color="linkPrimary"
            component="a"
            componentProps={{ href: LegalDocuments.INFORMATION_MESSAGES_CONSENT, target: '_blank' }}
          >
            {' '}
            согласие на получение информационных сообщений
          </Typography>
        </Typography>
      </div>
      {isPending ? (
        <Button variant="filled" size="medium" color="primary" loading={true} />
      ) : (
        <Button variant="filled" size="medium" color="primary" submit={true} disabled={!policyChecked}>
          Войти
        </Button>
      )}
    </AuthInputsTemplate>
  );
});
