import { Typography } from '@plarin/design';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import classes from './tabs-layout.module.scss';

type TTabs = { id: string; titleLabel: string; isDisabled?: boolean; component: React.ReactElement };

interface ITabs {
  tabs: TTabs[];
  defaultActiveTab?: string;
  onClickTab?: (tabsId: string) => void;
}

export const TabsLayout = ({ tabs, defaultActiveTab, onClickTab }: ITabs) => {
  const [activeTab, setActiveTab] = useState<string | undefined>(undefined);

  useEffect(() => {
    setActiveTab(defaultActiveTab || tabs[0]?.id);
  }, [defaultActiveTab]);

  const tabComponent = () => {
    return (
      tabs.filter(tab => {
        return tab.id === activeTab;
      })[0]?.component || null
    );
  };

  return (
    <div className={classes.layoutContent}>
      <div className={classes.layoutHeader}>
        <div className={classes.headerLeft}>
          {tabs.map(tab => {
            const isActive = tab.id === activeTab;
            const isDisabled = tab.isDisabled;
            return (
              <div
                key={tab.id}
                className={clsx(classes.tabName, isActive && classes.active, isDisabled && classes.disabled)}
                onClick={() => {
                  onClickTab && onClickTab(tab.id);
                  !tab.isDisabled && setActiveTab(tab.id);
                }}
              >
                <Typography
                  size="AuthContent"
                  weight={isActive ? 600 : 400}
                  componentProps={{ className: classes.tabNameText }}
                  color={isDisabled ? 'TextDisabled' : 'TextPrimary'}
                >
                  {tab.titleLabel}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.layoutBody}>{tabComponent()}</div>
    </div>
  );
};
