import { LocalizationProvider, StaticDatePicker } from '@mui/lab';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';
import { isBefore } from 'date-fns';
import ru from 'date-fns/locale/ru';
import React, { useLayoutEffect, useState } from 'react';
import { daysAbbreviation, stylesDatePicker } from './constants';
import classes from './styles.module.scss';

interface DatePickerProps {
  isOpen: boolean;
  setValue: React.Dispatch<React.SetStateAction<Date | null>>;
  onClose: () => void;
  applyDate: () => void;
  value?: Date | null;
  minDate?: null | Date;
  maxDate?: Date;
}

export const DatePicker = ({
  isOpen,
  setValue,
  onClose,
  applyDate,
  value,
  minDate,
  maxDate = new Date('2040'),
}: DatePickerProps) => {
  const [view, setView] = useState<'year' | 'day' | 'month'>('day');
  const defaultDate = minDate || new Date(Date.now());

  // эта манипуляция с DOM необходима, чтобы аббревиатуры неделей были не "П", а "Пн" для понедельника и тд. Другого способа замены аббревиатур для данной версии этого календаря нет.
  useLayoutEffect(() => {
    const rootElement = document.getElementById('DatePicker');

    if (rootElement) {
      const daysCollection = rootElement.getElementsByClassName('MuiTypography-caption');

      if (daysCollection) {
        const arrayOfDays = Array.from(daysCollection);

        arrayOfDays.forEach((_, index) => {
          arrayOfDays[index].textContent = daysAbbreviation[index];
        });
      }
    }
  }, []);

  return (
    <Box id="DatePicker" className={clsx(classes.container, isOpen && classes.open)} sx={stylesDatePicker}>
      <LocalizationProvider locale={ru} dateAdapter={DateFnsAdapter} dateFormats={{ monthShort: 'LLLL' }}>
        <StaticDatePicker
          onViewChange={view => setView(view)}
          defaultCalendarMonth={defaultDate}
          shouldDisableDate={date => isBefore(date, defaultDate) /* && !isToday(date)*/}
          componentsProps={{}}
          maxDate={maxDate}
          components={{
            SwitchViewIcon: () => null,
          }}
          views={['year', 'month', 'day']}
          className="StaticDatePicker"
          displayStaticWrapperAs="desktop"
          disablePast
          openTo="day"
          onChange={newValue => {
            return newValue && setValue(new Date(newValue.setHours(0, 0, 0, 0))); // необходимо руками устанавливать полночь, иначе время от времени возникает баг, что нельзя в качестве даты конца установить тот же день, когда реклама начинает работать (баг был завязан на часовой пояс юзера и время, когда он использует интерфейс). В будущем м.б. необходима более хитровыкрученная работа со временем, когда через наш интерфейс будет устанавливаться конкретное время работы рекламы.
          }}
          value={value}
          renderInput={() => <div />}
        />
      </LocalizationProvider>
      {view === 'day' && (
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={onClose}>
            Отменить
          </Button>
          <Button onClick={applyDate}>Применить</Button>
        </div>
      )}
    </Box>
  );
};
