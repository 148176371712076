import { Typography } from '@plarin/design';
import React from 'react';
import classes from './style.module.scss';
import { switchFilter } from '@plarin/utils';

type THightLightText = {
  text: string;
  filterValue: string;
  textEllips?: boolean;
  size?: string;
  weight?: number;
  color?: string;
  componentProps?: { className: string };
};

export const HightLightText = ({
  componentProps,
  color,
  text,
  filterValue,
  textEllips,
  size,
  weight,
}: THightLightText) => {
  if (filterValue) {
    const allFilterValueLanguage = filterValue + ' ' + switchFilter(filterValue);

    const filterValueProcessed = allFilterValueLanguage
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      .replace(/\s+/g, ' ')
      .trim();
    const regexp = new RegExp(filterValueProcessed.split(' ').join('|'), 'gi');
    const matchValue = text?.match(regexp);

    if (matchValue) {
      const resultString = text.split(regexp).map((str, index, array) => {
        if (index < array.length - 1) {
          return (
            <Typography
              componentProps={componentProps}
              color={color}
              size={size}
              ellips={textEllips}
              weight={weight}
              key={index}
            >
              {str}
              <div className={classes.textHightLight}>{matchValue[index]}</div>
            </Typography>
          );
        }
        return str;
      });
      return (
        <Typography componentProps={componentProps} color={color} size={size} ellips={textEllips} weight={weight}>
          {resultString}
        </Typography>
      );
    }
  }

  return (
    <Typography componentProps={componentProps} color={color} size={size} ellips={textEllips} weight={weight}>
      {text}
    </Typography>
  );
};
