import { Typography } from '@plarin/design';
import { ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import React from 'react';
import { EllipsisTooltip } from '../../../tooltip';

export const DefaultTooltipCell = (props: ICellRendererParams) => {
  return (
    <div className={clsx(props.colDef?.headerClass)}>
      <EllipsisTooltip tooltipMessage={props.valueFormatted || props.value}>
        <Typography ellips={true}> {props.valueFormatted || props.value} </Typography>
      </EllipsisTooltip>
    </div>
  );
};
