import { MailRuIcon, MailRuTextIcon } from '@plarin/design';
import React from 'react';
import { SocialButton } from './social-button';
import { SocialConfiguredButtonProps } from './social-configured-button-props';

export const SocialMailRuButton: React.FC<SocialConfiguredButtonProps> = function SocialMailRuButton({ onClick }) {
  return (
    <SocialButton icon={<MailRuIcon />} onClick={onClick}>
      <MailRuTextIcon />
    </SocialButton>
  );
};
