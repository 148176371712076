import { Typography } from '@plarin/design';
import { AvatarSkeleton } from '@plarin/inputs';
import { switchFilter } from '@plarin/utils';
import React from 'react';
import { TGuests } from '../../../../../../../../types/projects/projects';
import classes from '../../styles.module.scss';
import { ProjectGuestsList } from './project-guests-list';

interface IGuestsSearchResult {
  guests: TGuests[];
  filteredGuests: null | TGuests[];
  selectedGuests: TGuests[];
  changeGuestsSelection: (teams: TGuests) => void;
  filtersGuests: (searchVal: string, allGuests: TGuests[]) => TGuests[];
  searchValue: string;
}

export const GuestsSearchResult = ({
  guests,
  filteredGuests,
  selectedGuests,
  changeGuestsSelection,
  filtersGuests,
  searchValue,
}: IGuestsSearchResult) => {
  const guestsIsNotBlocked = guests.filter(el => el.status !== 'blocked');

  // фильтр по выбранным гостям
  const guestsListToRender = filteredGuests?.filter(
    guest => !selectedGuests?.find(e => e.id === guest.id) && guest.status !== 'blocked',
  );

  if (selectedGuests.length >= guestsIsNotBlocked?.length) {
    return (
      <div className={classes.wrapMessage}>
        <Typography weight={400} size="AuthContent" color="TextSecondary">
          Все гости пространства добавлены в проект.
        </Typography>
      </div>
    );
  }

  if (!guestsListToRender?.length) {
    return (
      <div className={classes.wrapMessage}>
        <Typography weight={400} size="AuthContent" color="TextSecondary">
          По вашему запросу не найдено ни одного гостя пространства.
        </Typography>
      </div>
    );
  }

  if (guestsListToRender) {
    return (
      <ProjectGuestsList
        guestsListToRender={guestsListToRender}
        changeGuestsSelection={changeGuestsSelection}
        isFoundItem={true}
        searchValue={filtersGuests(searchValue, guests).length ? searchValue : switchFilter(searchValue)}
      />
    );
  }

  if (!filteredGuests) {
    return (
      <div className={classes.wrap}>
        <AvatarSkeleton sumList={7} size="long" height={17} />
      </div>
    );
  }

  return (
    <div className={classes.wrapMessage}>
      <Typography weight={400} size="AuthContent" color="TextSecondary">
        Что-то пошло не так.
      </Typography>
    </div>
  );
};
