import { currency } from '@plarin/utils';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceDot,
  Label,
  ResponsiveContainer,
} from 'recharts';
import { ProjectionPoint, ProjectionResp } from '../../../types';
import { CustomReferenceDot } from './chart-dot';
import { CustomLabelDot } from './chart-label-dot';
import { CustomTooltip } from './chart-tooltip';

type ChartBidsProps = {
  projection: ProjectionResp;
  currentRate: ProjectionPoint | undefined;
  newRate: ProjectionPoint | undefined;
  setNewRate: Dispatch<SetStateAction<ProjectionPoint | undefined>>;
  setBidValue: (newBid: number) => void;
  points: ProjectionPoint[];
};

const toPercent = (decimal: number) => `${decimal}%`;

export const AreaChartBids = ({
  projection,
  setNewRate,
  setBidValue,
  currentRate,
  newRate,
  points,
}: ChartBidsProps) => {
  const [chartKey, setChartKey] = useState(0);

  useEffect(() => {
    setChartKey(Math.random());
  }, [projection, newRate, points]);

  return (
    <ResponsiveContainer minWidth={447} height={170}>
      <AreaChart
        style={{ zIndex: '99', position: 'fixed' }}
        data={points}
        onClick={(data: any) => {
          setNewRate(data.activePayload[0].payload);
          setBidValue(data.activePayload[0].payload.price);
        }}
        margin={{
          top: 20,
          right: 55,
          left: -9,
          bottom: 0,
        }}
        key={chartKey}
      >
        <defs>
          <linearGradient id="colorUv">
            <stop offset="0%" stopColor="#FFEBAA" />
            <stop offset="20%" stopColor="#80DCA0" /> //по оси Y от 50% зелёный до жёлтый
            <stop offset="100%" stopColor="#80DCA0" />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="price" minTickGap={40} tickSize={0} ticks={[0]} />
        <YAxis
          dataKey="share"
          tickFormatter={toPercent}
          tickSize={0}
          tickMargin={10}
          style={{
            fontSize: '10px',
          }}
        />
        <Tooltip
          cursor={{ stroke: '#8A96A8', strokeWidth: 1 }}
          content={<CustomTooltip cur={projection && projection.currency} />}
          allowEscapeViewBox={{ x: true, y: true }}
          offset={20}
          animationDuration={150}
          isAnimationActive={false}
        />

        <Area
          type="monotone"
          dataKey="share"
          stroke="#8884d8"
          fill="url(#colorUv)"
          cursor="pointer"
          isAnimationActive={newRate && false}
          key={chartKey}
        />

        {!newRate || newRate?.price === projection.point.price ? (
          <ReferenceDot x={currentRate?.price} y={currentRate?.share} shape={CustomReferenceDot} isFront>
            <Label
              content={CustomLabelDot}
              value={`Текущая: ${currentRate && currency(currentRate.price, projection && projection.currency)}`}
              offset={20}
              position="insideBottom"
            />
          </ReferenceDot>
        ) : (
          <ReferenceDot x={newRate?.price} y={newRate?.share} shape={CustomReferenceDot} isFront>
            <Label
              content={CustomLabelDot}
              value={`Новая: ${newRate && currency(newRate.price, projection && projection.currency)}`}
              offset={20}
              position="insideBottom"
            />
          </ReferenceDot>
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};
