import * as Joi from '@hapi/joi';
import { IntlMessageFormat } from 'intl-messageformat';

const validatorMessages: Record<string, any> = {
  'not.valid': {
    id: 'input.not.valid',
    defaultMessage: 'Ошибка в заполнении поля',
  },
  'any.required': {
    id: 'input.any.required',
    defaultMessage: 'Поле должно быть заполнено',
  },
  'object.and': {
    id: 'input.object.and',
    defaultMessage: 'Поле должно быть заполнено',
  },
  'any.empty': {
    id: 'input.any.empty',
    defaultMessage: 'Поле не может быть пустым',
  },
  'any.invalid': {
    id: 'input.any.invalid',
    defaultMessage: 'Некорректное значение',
  },
  'array.length': {
    id: 'input.array.length',
    defaultMessage: 'Поле должно содержать {limit, plural, one {# элемент} few {# элемента} other {# элементов}}',
  },
  'array.max': {
    id: 'input.array.max',
    defaultMessage: 'Максимальное количество элементов - {limit}',
  },
  'array.min': {
    id: 'input.array.min',
    defaultMessage: 'Минимальное количество элементов - {limit}',
  },
  'date.base': {
    id: 'input.date.base',
    defaultMessage: 'Поле должно быть заполнено',
  },
  'date.greater': {
    id: 'input.date.greater',
    defaultMessage: 'Введите дату после {limit}',
  },
  'date.less': {
    id: 'input.date.less',
    defaultMessage: 'Введите дату до {limit}',
  },
  'date.max': {
    id: 'input.date.max',
    defaultMessage: 'Дата должна быть не больше {limit}',
  },
  'date.min': {
    id: 'input.date.min',
    defaultMessage: 'Дата должна быть не меньше {limit}',
  },
  'number.base': {
    id: 'input.number.base',
    defaultMessage: 'Значение должно быть числом',
  },
  'number.greater': {
    id: 'input.number.greater',
    defaultMessage: 'Число должно быть больше {limit}',
  },
  'number.less': {
    id: 'input.number.less',
    defaultMessage: 'Число должно быть меньше {limit}',
  },
  'number.max': {
    id: 'input.number.max',
    defaultMessage: 'Число должно быть меньше {limit}',
  },
  'number.min': {
    id: 'input.number.min',
    defaultMessage: 'Число должно быть больше {limit}',
  },
  'string.base': {
    id: 'input.string.base',
    defaultMessage: 'Значение должно быть заполнено',
  },
  'string.length': {
    id: 'input.string.length',
    defaultMessage: 'Должно быть {limit, plural, one {# символ} few {# символа} other {# символов}}',
  },
  'string.max': {
    id: 'input.string.max',
    defaultMessage: 'Максимум {limit, plural, one {# символ} few {# символа} other {# символов}}',
  },
  'string.min': {
    id: 'input.string.min',
    defaultMessage: 'Минимум {limit, plural, one {# символ} few {# символа} other {# символов}}',
  },
  'string.empty': {
    id: 'input.string.empty',
    defaultMessage: 'Поле не может быть пустым',
  },
  'string.email': {
    id: 'input.string.email',
    defaultMessage: 'Неверный формат адреса электронной почты',
  },
  'string.phone': {
    id: 'string.phone',
    defaultMessage: 'Номер телефона введен не верно',
  },
  'string.hex': {
    id: 'input.string.hex',
    defaultMessage: 'Неверный формат',
  },
  'string.regex.base': {
    id: 'input.string.regex.base',
    defaultMessage: 'Неверный формат',
  },
  'string.pattern.base': {
    id: 'input.string.pattern.base',
    defaultMessage: 'Неверный формат',
  },
  'string.allowed.symbols': {
    id: 'string.allowed.symbols',
    defaultMessage: 'Введены недопустимые символы',
  },
};

export function formatValidationMessage(key: string, values = {}): string {
  const msg = new IntlMessageFormat(validatorMessages[key].defaultMessage, 'ru-RU');
  return msg.format(values) as string;
}

const createAllowedSymbolsRegExp = (allowedSymbols: string) => new RegExp(`^[${allowedSymbols}]+$`, 'gi');

const allowedSymbolsValidationMethod =
  (allowedSymbols: string): Joi.CustomValidator =>
  (value: any, helpers: Joi.CustomHelpers) =>
    value.length > 0 && !createAllowedSymbolsRegExp(allowedSymbols).test(value)
      ? helpers.error('string.allowed.symbols')
      : value;

export const allowedSymbolsValidateRule = (allowedSymbols: string) =>
  Joi.string().custom(allowedSymbolsValidationMethod(allowedSymbols), 'allowed symbols validation');
