import { TypeInput, useInput } from '@plarin/utils';
import React, { useEffect } from 'react';
import { Input } from '../inputs';
import { ModalActionNamesEnum, TSetModalAction } from '../modal';
import classes from './style.module.scss';
import { TypeCustomerName } from './types';

type TChangeCustomerName = (data: TypeCustomerName, public_id: string) => Promise<void>;

type TModalContent = {
  firstName: string | undefined;
  lastName: string | undefined;
  public_id: string; // нужен для изменения имени клиента через бэкофис. При использовании в app сюда можно передать пустую строку
  setModalAction: TSetModalAction;
  changeCustomerName: TChangeCustomerName;
  isLoading: boolean | undefined;
  closeModal: () => boolean;
};

export const SetCustomerNameContent = ({
  firstName,
  lastName,
  public_id,
  setModalAction,
  changeCustomerName,
  isLoading,
  closeModal,
}: TModalContent) => {
  const fname = useInput({
    initialValue: firstName || '',
    type: TypeInput.fName,
    isRequired: true,
  });
  // eslint-disable-next-line
  const {
    setError: fnameSetError,
    setHint: fnameSetHint,
    setWasChanged: fnameSetWasChanged,
    checkValue: fnameCheckValue,
    ...fnameInput
  } = fname;

  const lname = useInput({
    initialValue: lastName || '',
    type: TypeInput.lName,
    isRequired: false,
  });
  // eslint-disable-next-line
  const {
    setError: lnameSetError,
    setHint: lnameSetHint,
    setWasChanged: lnameSetWasChanged,
    checkValue: lnameCheckValue,
    ...lnameInput
  } = lname;

  const setAction = () => {
    setModalAction({
      action: () => {
        if (!fname.value || fname.error || lname.error) {
          !fname.value && fnameSetError(true);
        } else if (fname.value === firstName && lname.value === lastName) {
          closeModal();
        } else {
          changeCustomerName({ fname: fname.value, lname: lname.value }, public_id);
        }
      },
      isLoading: isLoading,
      actionTitle: ModalActionNamesEnum.Save,
    });
  };

  useEffect(() => {
    fnameCheckValue();
    lnameCheckValue();
    setAction();
  });

  return (
    <form className={classes.form}>
      <Input {...fnameInput} label="Имя" fullWidth size={48} disableMinHeight={true} />
      <Input {...lnameInput} label="Фамилия" fullWidth size={48} disableMinHeight={true} />
    </form>
  );
};
