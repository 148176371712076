import React from 'react';
import { useIntercom } from 'react-use-intercom';
import classes from './style.module.scss';

export const SupportIntercomLink: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { show: showIntercom, getVisitorId } = useIntercom();
  const visitorId = getVisitorId();

  const supportRedirect = () => {
    window.open('https://plarin.ru/support/', '_blank');
  };

  return (
    <span className={classes.supportIntercomLink} onClick={visitorId ? showIntercom : supportRedirect}>
      {children}
    </span>
  );
};
