import { Input } from '@plarin/inputs';
import { formatPhone, typografText } from '@plarin/utils';
import { observer } from 'mobx-react';
import React from 'react';
import { useAppDataStore } from '../../../components/app-data-provider';
import { ProfileBlockWrapper } from '../../../components/profile-block-wrapper';
import { ymDisableClass } from '../../../utils/constants';

const profileInfoTitle = 'Сведения об учетной записи и доступе';
const profileInfoSubTitle =
  'Это личная информация, которую вы используете для доступа к своей учетной записи. По умолчанию в сервисах Plarin для совместной работы с коллегами используется ваше имя и фамилия.';

const NameBlock = observer(() => {
  const {
    profileStore: { data, setModalContentType, setModalTitle, openModal },
  } = useAppDataStore();

  const openModalWindow = () => {
    setModalContentType('changeAccountName');
    setModalTitle('Изменить имя учетной записи');
    openModal();
  };

  return (
    <Input
      value={data.fname ? data.fname + ' ' + data.lname : 'Не заполнено'}
      onChange={() => {}}
      label="Имя учетной записи"
      type="box"
      disabled
      fullWidth
      actionTitle="Изменить"
      action={openModalWindow}
      className={ymDisableClass}
    />
  );
});

const EmailBlock = observer(() => {
  const {
    profileStore: { data, setModalContentType, setModalTitle, openModal },
  } = useAppDataStore();

  const openModalWindow = () => {
    setModalContentType('changeAccountEmail');
    setModalTitle('Изменить электронную почту');
    openModal();
  };

  return (
    <Input
      value={data.email || 'Не заполнена'}
      onChange={() => {}}
      label="Электронная почта"
      type="box"
      fullWidth
      actionTitle="Изменить"
      action={data.password_set ? openModalWindow : undefined}
      isDeactivated={!data.password_set}
      deactivatedMessage="Для изменения электронной почты сначала задайте пароль"
      className={ymDisableClass}
    />
  );
});

const PhoneBlock = () => {
  const {
    profileStore: { data, setModalContentType, setModalTitle, openModal },
  } = useAppDataStore();

  const openModalWindow = () => {
    setModalContentType('changePhonePhone');
    setModalTitle(data.phone ? 'Изменить мобильный телефон' : 'Добавить мобильный телефон');
    openModal();
  };

  return (
    <>
      <Input
        value={formatPhone(data.phone || '') || 'Не заполнен'}
        onChange={() => {}}
        label="Мобильный телефон"
        type="box"
        fullWidth
        actionTitle={data.phone ? 'Изменить' : 'Добавить'}
        action={openModalWindow}
        className={ymDisableClass}
      />
    </>
  );
};

export const ProfileInfo = () => {
  return (
    <ProfileBlockWrapper title={profileInfoTitle} subTitle={typografText(profileInfoSubTitle)}>
      <div className="marginBottom24px">
        <NameBlock />
      </div>
      <div className="marginBottom24px">
        <EmailBlock />
      </div>
      <div className="marginBottom24px">
        <PhoneBlock />
      </div>
    </ProfileBlockWrapper>
  );
};
