import { transformDomain, getDomainFromLocation } from './utils';

export const AUTH_ACCESS_TOKEN = 'access_token';

export const AUTH_REFRESH_TOKEN = 'refresh_token';

export const CONFIRM_EMAIL_TOKEN_KEY = 'token';

export const FORGOT_PASSWORD_EMAIL = 'email';

export const WORKSPACE_ID = 'selectedWS';

export const CURRENT_WORKSPACE = 'current_workspace';

const UI_DEV_SUBDOMAIN = 'ui-dev';

export const UI_DEV_DOMAIN_DYNAMIC =
  process.env.NODE_ENV === 'development'
    ? transformDomain(API_URL_ROOT, UI_DEV_SUBDOMAIN)
    : getDomainFromLocation(UI_DEV_SUBDOMAIN); // например: 'ui-dev.plarin.ru'
