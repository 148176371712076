import { CellClassParams, Column } from '@plarin/inputs';
import { ICellRendererParams } from 'ag-grid-community';
import { TWsRole, WsMembers } from '../../../../types/profile/types';
import { TWsProject } from '../../../../types/teams/teams';
import { sortRow } from '../../../utils/common';
import { SettingsMenuTeams } from './settingsMenuTeams';

export const teamsColumns: (
  wsMembers: WsMembers,
  wsRole: TWsRole | undefined,
  projectsData: TWsProject[],
  userId: string,
) => Column[] = (wsMembers, wsRole, projectsData, userId) => [
  {
    headerName: 'Название',
    headerComponentParams: {
      alignY: 'center',
    },
    field: 'name',
    flex: 2,
    cellRenderer: 'SquareAvatarNameRenderer',
    cellRendererParams: {
      SettingMenu: SettingsMenuTeams,
      wsRole,
      userId,
    },
    cellClassRules: {
      'users-status-disabled ': (params: CellClassParams) =>
        !params.data.status || params.data.status.includes('inactive'),
    },
    resizable: false,
    lockPosition: true,

    comparator: (a, b) => sortRow(a, b),
  },
  {
    headerName: 'Участники',
    headerComponentParams: {
      alignY: 'center',
    },
    field: 'members',
    flex: 1,
    cellRenderer: 'TeamMembersRenderer',
    cellRendererParams: {
      wsMembers: wsMembers,
    },
    cellClassRules: {
      'users-status-disabled ': (params: CellClassParams) =>
        !params.data.status || params.data.status.includes('inactive'),
    },
    resizable: false,
    lockPosition: true,
    comparator: (a, b, nodeA, nodeB) => nodeA.data.member_ids.length - nodeB.data.member_ids.length,
  },
  {
    headerName: 'Проекты',
    headerComponentParams: {
      alignY: 'center',
    },
    field: '',
    flex: 1,
    cellRenderer: 'ProjectListRenderer',
    cellRendererParams: (data: ICellRendererParams) => {
      return { projectsData: projectsData?.filter(project => data?.data?.project_ids.includes(project._id)) };
    },
    cellClassRules: {
      'users-status-disabled ': (params: CellClassParams) =>
        !params.data.status || params.data.status.includes('inactive'),
    },
    resizable: false,
    lockPosition: true,
    comparator: (a, b, nodeA, nodeB) => nodeA.data.project_ids.length - nodeB.data.project_ids.length,
  },
  {
    headerName: 'Лидер команды',
    headerComponentParams: {
      alignY: 'center',
    },
    field: 'leaderName',
    cellRenderer: 'CircleAvatarNameRenderer',
    cellRendererParams: {
      wsMembers: wsMembers,
    },
    resizable: false,
    lockPosition: true,
    comparator: (a, b) => sortRow(b, a),
    flex: 1,
    cellClassRules: {
      'users-status-disabled ': (params: CellClassParams) =>
        !params.data.status || params.data.status.includes('inactive'),
    },
  },
];
