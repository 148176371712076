import { Button, MainTooltip } from '@plarin/inputs';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDataStore } from '../../app-data-provider';
import classes from './style.module.scss';

interface IFooterWs {
  setIsDrawerOpen: (e: boolean) => void;
  onCreate?: () => void;
  isDisabled: boolean;
}

export const FooterWs = ({ setIsDrawerOpen, onCreate, isDisabled }: IFooterWs) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const {
    ws: { getWsList },
    profileStore: { setWorkspaceCreate, postNewWorkspace },
  } = useAppDataStore();

  const onClose = () => {
    if (!isDisabled || !isLoading) {
      setIsDrawerOpen(false);
      setWorkspaceCreate({ company: '', shortname: '' });
    }
  };

  const actionCreate = () => {
    onCreate && onCreate();
    if (!isDisabled || !isLoading) {
      setIsLoading(true);
      postNewWorkspace().finally(() => {
        history.push('/');
        getWsList();
        onClose();
      });
    }
  };

  return (
    <div className={classes.footer}>
      <MainTooltip tooltipMessage="нет имени пространства" isVisible={isDisabled} component="span" followCursor={true}>
        <Button
          loading={isLoading}
          onClick={!isLoading ? actionCreate : undefined}
          size="small"
          color="primary"
          variant="filled"
          disabled={isDisabled}
        >
          Создать
        </Button>
      </MainTooltip>
      <Button disabled={isLoading} size="small" variant="outlined" color="primary" onClick={onClose}>
        Отменить
      </Button>
    </div>
  );
};
