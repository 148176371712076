import { RedoIcon } from '@plarin/design';
import { ActionTableButton } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useAppDataStore } from '../../../components/app-data-provider';
import { SwitcherActionButton } from '../../workspace-teams/components/table-actions';
import classes from './action-bar.module.scss';
import { InviteMembers } from './invite-members';

type ActionBarProps = {
  blockEnabled: boolean;
  setBlockEnabled: (block: boolean) => void;
};

export const ActionBar = observer(({ blockEnabled, setBlockEnabled }: ActionBarProps) => {
  const {
    members: { isTableLoading, getWSMembers },
  } = useAppDataStore();

  return (
    <div className={classes.actionBar}>
      <div className={classes.connect}>
        <InviteMembers />
      </div>
      <div className={classes.rightBlock}>
        <SwitcherActionButton
          isOn={blockEnabled}
          action={() => setBlockEnabled(!blockEnabled)}
          name="Заблокированные"
        />
        <ActionTableButton
          textTooltip="Обновить"
          action={getWSMembers}
          loading={isTableLoading}
          icon={<RedoIcon />}
          isReloadButton={true}
        />
      </div>
    </div>
  );
});
