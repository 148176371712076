import { History } from 'history';

export function clearUrlParams(delParams: string[]) {
  return delParams.reduce((params, delParam) => {
    if (params.has(delParam)) {
      params.delete(delParam);
    }
    return params;
  }, new URLSearchParams(window.location.search));
}

export function clearUrl(history: History, delParams: string[]) {
  history.replace(`${history.location.pathname}?${clearUrlParams(delParams)}`);
}

export function expired(exp?: number) {
  return !exp || new Date().getTime() >= new Date(exp * 1000).getTime();
}

export function getTimeToUpdate(exp: number) {
  return new Date(exp * 1000 - 5 * 60000).getTime() - new Date().getTime();
}
