import { DefaultInput } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { TTeamsFilters } from '../../../../../../../../types/projects/projects';
import { getSearchResults, filtersTeams } from '../../../../../../../utils/searchList';
import { useAppDataStore } from '../../../../../../app-data-provider';
import classes from '../../styles.module.scss';
import { TeamsSearchResult } from './teams-search-result';

type TPopupTeamProps = {
  selectedTeams: TTeamsFilters[];
  setSelectedTeams: React.Dispatch<React.SetStateAction<TTeamsFilters[]>>;
};

export const ProjectPopupTeams = observer(({ selectedTeams, setSelectedTeams }: TPopupTeamProps) => {
  const {
    projects: { teams, teamsState, setTeamsState },
  } = useAppDataStore();

  useEffect(() => {
    teams.teams &&
      getSearchResults(
        teamsState.searchValue,
        filtersTeams,
        (el: TTeamsFilters[]) => {
          setTeamsState('filteredTeams', el);
        },
        teams.teams.filter(el => el.status === 'active'),
      );
  }, [teams.teams, teamsState.searchValue]);

  const changeTeamsSelection = (teams: TTeamsFilters) => {
    if (!!selectedTeams.find(item => item.id === teams.id)) {
      setSelectedTeams(selectedTeams.filter(item => item.id !== teams.id));
    } else {
      setSelectedTeams([...selectedTeams, teams]);
    }
  };

  return (
    <div className={classes.cabinetsList}>
      <div className={classes.search}>
        <DefaultInput
          value={teamsState.searchValue}
          setValue={el => {
            setTeamsState('searchValue', el);
          }}
          placeholder="Поиск команды"
          searchIcon
          clearInputAction={() => setTeamsState('searchValue', '')}
        />
      </div>
      <TeamsSearchResult
        teams={teams}
        filteredTeams={teamsState.filteredTeams}
        selectedTeams={selectedTeams}
        changeTeamsSelection={changeTeamsSelection}
        filtersTeams={filtersTeams}
        searchValue={teamsState.searchValue}
      />
    </div>
  );
});
