import {
  getItemsType,
  StrategyBidSaveActionProps,
  TCommonBudgetOptimizationLevel,
  TNewStrategyBids,
} from '@plarin/inputs';
import { manageVkTableMainNames, ManageVkTabNameEnum, formatDateToReq, isUrlPreProd, isUrlProd } from '@plarin/utils';
import { GridApi, IRowNode } from 'ag-grid-community';
import { Dictionary, MetricRecord } from '../../types/manage-vk/types';
import { TMetric, TMetricGroup } from '../../types/metrics';
import { TWsDictionary } from '../../types/profile/types';
import { ADS, ManageVkNameCellsEnum, FAST_STAT, METRICS_GROUP_PATHS } from './constants';

export const parseStringToCamelCase = (item: string): string => {
  const stringToArr = item.split('');
  stringToArr.forEach((item, index) => {
    (item === '.' || item === '_') && (stringToArr[index + 1] = stringToArr[index + 1].toUpperCase());
  });
  return stringToArr
    .filter(item => item !== '.')
    .filter(item => item !== '_')
    .join('');
};

export const returnId = (tabName: ManageVkTabNameEnum) => {
  let id = 'accountId';
  switch (tabName) {
    case 'client_ids':
      return 'accountId';
    case 'ad_plan_ids':
      return 'adPlanId';
    case 'campaign_ids':
      return 'campaignId';
    case 'ad_ids':
      return 'adId';
  }
  return id;
};

function getStatusColor(status: string, deliveryStatus: string, moderationStatus: string): string {
  if (status === '2') {
    return 'archived';
  }

  if (status === '0') {
    return !!moderationStatus && moderationStatus === 'banned' ? 'banned' : 'stopped';
  }

  if (status === '1') {
    if (deliveryStatus === 'delivering') return 'active';
    if (!!moderationStatus && moderationStatus === 'banned') return 'banned';
    return 'stopped';
  }
  return '';
}

export const parseStatistics = (str: string) => {
  const parts = str.split('\u0019');
  const length = parts[0].split('\u0016').length;
  return parts.map(part => part.split('\u0016')).filter(elements => elements.length === length);
};

export const parseToData = (
  columns: string[],
  values: string[][],
  dict: Dictionary,
  tabName: ManageVkTabNameEnum,
  wsDictionary: TWsDictionary,
) => {
  const data: MetricRecord[] = [];

  function getStatusColor(status: string, deliveryStatus: string, moderationStatus: string): string {
    if (status === '2') {
      return 'archived';
    }

    if (status === '0') {
      return !!moderationStatus && moderationStatus === 'banned' ? 'banned' : 'stopped';
    }

    if (status === '1') {
      if (deliveryStatus === 'delivering') return 'active';
      if (!!moderationStatus && moderationStatus === 'banned') return 'banned';
      return 'stopped';
    }
    return '';
  }

  const translateStatus = (status: string, tabName: ManageVkTabNameEnum): string => {
    switch (status) {
      case '0':
        return tabName === 'ad_ids' ? 'Остановлено' : 'Остановлена';
      case '1':
        return tabName === 'ad_ids' ? 'Активное' : 'Активная';
      case '2':
        return tabName === 'ad_ids' ? 'Архивное' : 'Архивная';
      default:
        return '';
    }
  };

  const getSortByStatus = (status: any) => {
    if (status.includes('Активн')) {
      return 0;
    }
    if (status.includes('Остан')) {
      return 1;
    }
    if (status.includes('Архив')) {
      return 2;
    } else {
      return 3;
    }
  };

  const returnId = (tabName: ManageVkTabNameEnum) => {
    let id = 'accountId';
    switch (tabName) {
      case 'client_ids':
        return 'accountId';
      case 'ad_plan_ids':
        return 'adPlanId';
      case 'campaign_ids':
        return 'campaignId';
      case 'ad_ids':
        return 'adId';
    }
    return id;
  };

  const getRandomArbitrary = (min: number, max: number) => Math.random() * (max - min) + min;

  values.forEach(stat => {
    const obj: MetricRecord = {};

    columns.forEach((col, index) => {
      const type = dict[col];
      const value = stat[index];

      if (type === 'int' || type === 'percent' || type === 'float') {
        obj[col] = +value;
      } else if (type === 'currency' && value) {
        obj[col] = parseFloat(value);
      } else if (type === 'json' && value) {
        obj[col] = JSON.parse(value.replace(/('|`|^\w)/g, ''));
      } else {
        obj[col] = value;
      }
    });

    obj.key = Number(obj[returnId(tabName)]);
    obj.cellLoadingName = '';
    obj.previewSmall = 'none';

    if (obj.status) {
      obj.pastStatus = '';
      obj.nextStatus = '';
      obj.statusColor = getStatusColor(
        obj.status as string,
        obj.deliveryStatus as string,
        (obj.adModerationStatus as string) || '',
      );
      obj.status = translateStatus(`${obj.status}`, tabName);
    }

    obj.budgetOptimizationLevel =
      obj.network === 'mt' ? 'none' : obj.adPlanDaily || obj.adPlanLifetime ? 'adPlan' : 'campaign';

    if (!isUrlProd && !isUrlPreProd && obj.adPlanId) {
      const items = getItemsType(window.location.pathname);

      // Фейковые лимиты и траты
      if (items === 'ad_plan') {
        if (obj.network === 'va') {
          obj.statisticsSpentAll = getRandomArbitrary(0, +obj.adPlanLifetime || 99000000);
        } else if (obj.network === 'mt') {
          if (!obj.statisticsSpentAll && !obj.adPlanLifetime) {
            obj.statisticsSpentAll = getRandomArbitrary(0, 99000000);
            obj.adPlanLifetime = getRandomArbitrary(0, 99000000);
          }
          if (!obj.statisticsSpentToday && !obj.adPlanDaily) {
            obj.adPlanDaily = getRandomArbitrary(0, 99000000);
          }
        }
      } else if (items === 'campaign' || items === 'banner') {
        if (obj.network === 'va') {
          obj.statisticsSpentAll = getRandomArbitrary(0, +obj.campaignLifetime || 99000000);
        } else if (obj.network === 'mt') {
          if (!obj.statisticsSpentAll && !obj.campaignLifetime) {
            obj.campaignLifetime = getRandomArbitrary(0, 99000000);
            obj.statisticsSpentAll = getRandomArbitrary(0, 99000000);
          }
          if (!obj.statisticsSpentToday && !obj.campaignDaily) {
            obj.campaignDaily = getRandomArbitrary(0, 99000);
          }
        }
      }
    }

    if (obj.budgetLifetime) {
      obj.percentBudgetLifetime = `${100 - (+obj.statisticsSpentAll / +obj.budgetLifetime) * 100 || 100}`;
    }

    if (typeof obj.status !== 'number' && !obj.status?.includes('Архив')) {
      obj.fastStat = 0;
      obj.fastStat72 = 0;
      obj.statisticsSpentToday = 0;
    }

    if ('mtProject' in obj) {
      //@ts-ignore
      obj.mtProject = (obj.mtProject as string)
        .split(',')
        .map(el => wsDictionary.projects[el] || { name: 'Не определен' });
    }

    if ('responsible' in obj) {
      obj.responsible = wsDictionary.members[obj.responsible as string] || 'Не указано';
    }

    data.push(obj);
  });

  if (data.length > 0 && 'status' in data[0]) {
    data.sort((a, b) => {
      const aStatus = getSortByStatus(a.status as string);
      const bStatus = getSortByStatus(b.status as string);

      if (aStatus === bStatus) {
        return (a[manageVkTableMainNames[tabName]] as string).toLowerCase() >
          (b[manageVkTableMainNames[tabName]] as string).toLowerCase()
          ? 1
          : -1;
      }
      return aStatus - bStatus;
    });
  }

  if (data.length > 0 && 'status' in data[0]) {
    const statusFilter = (statusIncludes: string) =>
      data.filter(obj => typeof obj.status === 'string' && obj.status.includes(statusIncludes));

    const active = statusFilter('Активн');
    const disabled = statusFilter('Остановл');
    const archived = statusFilter('Архивн');

    const activeActive = active.filter(el => el.statusColor !== 'banned');
    const activeBanned = active.filter(el => el.statusColor === 'banned');
    const disabledActive = disabled.filter(el => el.statusColor !== 'banned');
    const disabledBanned = disabled.filter(el => el.statusColor === 'banned');

    return data[0].adModerationStatus
      ? [...activeActive, ...activeBanned, ...disabledActive, ...disabledBanned, ...archived]
      : [...active, ...disabled, ...archived];
  }

  return data;
};

// export const parseToData = (
//   columns: string[],
//   values: string[][],
//   dict: Dictionary,
//   tabName: ManageVkTabNameEnum,
//   wsDictionary: TWsDictionary,
// ) => {
//   const data: MetricRecord[] = [];
//   function getStatusColor(status: string, deliveryStatus: string, moderationStatus: string): string {
//     if (status === '2') {
//       return 'archived';
//     }

//     if (status === '0') {
//       return !!moderationStatus && moderationStatus === 'banned' ? 'banned' : 'stopped';
//     }

//     if (status === '1') {
//       if (deliveryStatus === 'delivering') return 'active';
//       if (!!moderationStatus && moderationStatus === 'banned') return 'banned';
//       return 'stopped';
//     }
//     return '';
//   }

//   const translateStatus = (status: string, tabName: ManageVkTabNameEnum): string => {
//     switch (status) {
//       case '0':
//         return tabName === 'ad_ids' ? 'Остановлено' : 'Остановлена';
//       case '1':
//         return tabName === 'ad_ids' ? 'Активное' : 'Активная';
//       case '2':
//         return tabName === 'ad_ids' ? 'Архивное' : 'Архивная';
//       default:
//         return '';
//     }
//   };

//   const getSortByStatus = (status: any) => {
//     if (status.includes('Активн')) {
//       return 0;
//     }
//     if (status.includes('Остан')) {
//       return 1;
//     }
//     if (status.includes('Архив')) {
//       return 2;
//     } else {
//       return 3;
//     }
//   };

//   const returnId = (tabName: ManageVkTabNameEnum) => {
//     let id = 'accountId';
//     switch (tabName) {
//       case 'client_ids':
//         return 'accountId';
//       case 'ad_plan_ids':
//         return 'adPlanId';
//       case 'campaign_ids':
//         return 'campaignId';
//       case 'ad_ids':
//         return 'adId';
//     }
//     return id;
//   };

//   values.forEach(stat => {
//     const obj: MetricRecord = {};

//     columns.forEach((col, index) => {
//       const type = dict[col];
//       const value = stat[index];

//       if (type === 'int') {
//         obj[col] = +value;
//       } else if (type === 'currency' && value) {
//         obj[col] = parseFloat(value);
//       } else if (type === 'json' && value) {
//         obj[col] = JSON.parse(value.replace(/('|`|^\w)/g, ''));
//       } else {
//         obj[col] = value;
//       }
//     });

//     obj.key = Number(obj[returnId(tabName)]);
//     obj.cellLoadingName = '';
//     obj.previewSmall = 'none';

//     if (obj.status) {
//       obj.pastStatus = '';
//       obj.nextStatus = '';
//       obj.statusColor = getStatusColor(
//         obj.status as string,
//         obj.deliveryStatus as string,
//         (obj.adModerationStatus as string) || '',
//       );
//       obj.status = translateStatus(`${obj.status}`, tabName);
//     }

//     obj.budgetOptimizationLevel =
//       obj.network === 'mt'
//         ? 'none'
//         : obj.adPlanDaily || obj.adPlanLifetime
//         ? MtOptimisationLevel.adPlan
//         : MtOptimisationLevel.adGroup;

//     if (obj.budgetLifetime) {
//       obj.percentBudgetLifetime = `${100 - (+obj.statisticsSpentAll / +obj.budgetLifetime) * 100 || 100}`;
//     }

//     if (typeof obj.status !== 'number' && !obj.status?.includes('Архив')) {
//       obj.fastStat = 0;
//       obj.fastStat72 = 0;
//       obj.statisticsSpentToday = 0;
//     }

//     if ('mtProject' in obj) {
//       // @ts-ignore
//       obj.mtProject = (obj.mtProject as string)
//         .split(',')
//         .map(el => wsDictionary.projects[el] || { name: 'Не определен' });
//     }

//     if ('responsible' in obj) {
//       obj.responsible = wsDictionary.members[obj.responsible as string] || 'Не указано';
//     }

//     data.push(obj);
//   });

//   if (data.length > 0 && data[0].status) {
//     data.sort((a, b) => {
//       const aStatus = getSortByStatus(a.status as string);
//       const bStatus = getSortByStatus(b.status as string);

//       if (aStatus === bStatus) {
//         return (a[manageVkTableMainNames[tabName]] as string).toLowerCase() >
//           (b[manageVkTableMainNames[tabName]] as string).toLowerCase()
//           ? 1
//           : -1;
//       }
//       return aStatus - bStatus;
//     });
//   }

//   if (data.length > 0 && data[0].status) {
//     const statusFilter = (statusIncludes: string) =>
//       data.filter(obj => typeof obj.status === 'string' && obj.status.includes(statusIncludes));

//     const active = statusFilter('Активн');
//     const disabled = statusFilter('Остановл');
//     const archived = statusFilter('Архивн');

//     const activeActive = active.filter(el => el.statusColor !== 'banned');
//     const activeBanned = active.filter(el => el.statusColor === 'banned');
//     const disabledActive = disabled.filter(el => el.statusColor !== 'banned');
//     const disabledBanned = disabled.filter(el => el.statusColor === 'banned');

//     return data[0].adModerationStatus
//       ? [...activeActive, ...activeBanned, ...disabledActive, ...disabledBanned, ...archived]
//       : [...active, ...disabled, ...archived];
//   }

//   return data;
// };

// export const parseToData = (
//   columns: string[],
//   values: string[][],
//   dict: Dictionary,
//   tabName: ManageVkTabNameEnum,
//   wsDictionary: TWsDictionary,
// ) => {
//   const data: MetricRecord[] = [];

//   values.forEach(stat => {
//     let obj = {} as MetricRecord;

//     stat.forEach((el, index) => {
//       if (dict[columns[index]] === 'int') {
//         obj[columns[index]] = +el;
//       } else if (el && dict[columns[index]] === 'currency') {
//         obj[columns[index]] = parseFloat(el);
//       } else if (el && dict[columns[index]] === 'json') {
//         obj[columns[index]] = JSON.parse(el.replace(/('|`|^\w)/g, ''));
//       } else {
//         obj[columns[index]] = el;
//       }
//     });

//     obj.key = Number(obj[returnId(tabName)]);
//     obj.cellLoadingName = '';
//     obj.previewSmall = 'none';

//     if (obj.status) {
//       obj.pastStatus = '';
//       obj.nextStatus = '';

//       obj.statusColor = getStatusColor(
//         obj.status as string,
//         obj.deliveryStatus as string,
//         (obj.adModerationStatus as string) || '',
//       );

//       obj.status = translateStatus(`${obj.status}`, tabName);
//       // obj.activeStatus = parseStatus(translateStatus(`${obj.status}`, tabName));
//     }

//     obj.budgetOptimizationLevel =
//       obj.network === 'mt'
//         ? 'none'
//         : obj.adPlanDaily || obj.adPlanLifetime
//         ? MtOptimisationLevel.adPlan
//         : MtOptimisationLevel.adGroup;

//     // Временно для проверки и тестирования, потом убрать
//     const getRandomArbitrary = (min: number, max: number) => {
//       return Math.random() * (max - min) + min;
//     };
//     if (!isUrlProd && !isUrlPreProd && obj.adPlanId) {
//       const items = getItemsType(window.location.pathname);

//       // фейковые лимиты и траты для таба "Кампании"
//       if (items === 'ad_plan') {
//         if (obj.network === 'va') {
//           obj.statisticsSpentAll = getRandomArbitrary(0, +obj.adPlanLifetime || 99000000);
//         } else {
//           if (obj.network === 'mt' && !obj.statisticsSpentAll && !obj.adPlanLifetime) {
//             obj.statisticsSpentAll = getRandomArbitrary(0, 99000000);
//             obj.adPlanLifetime = getRandomArbitrary(0, 99000000);
//           }
//           if (obj.network === 'mt' && !obj.statisticsSpentToday && !obj.adPlanDaily) {
//             obj.adPlanDaily = getRandomArbitrary(0, 99000000);
//           }
//         }
//       }

//       // фейковые лимиты и траты для табов "Группы" и "Объявления"
//       if (items === 'campaign' || items === 'banner') {
//         if (obj.network === 'va') {
//           obj.statisticsSpentAll = getRandomArbitrary(0, +obj.campaignLifetime || 99000000);
//         } else {
//           if (obj.network === 'mt' && !obj.statisticsSpentAll && !obj.campaignLifetime) {
//             obj.campaignLifetime = getRandomArbitrary(0, 99000000);
//             obj.statisticsSpentAll = getRandomArbitrary(0, 99000000);
//           }
//           if (obj.network === 'mt' && !obj.statisticsSpentToday && !obj.campaignDaily) {
//             obj.campaignDaily = getRandomArbitrary(0, 99000);
//           }
//         }
//       }
//     }

//     obj.budgetLifetime &&
//       (obj.percentBudgetLifetime = `${
//         obj.budgetLifetime ? 100 - (+obj.statisticsSpentAll / +obj.budgetLifetime) * 100 || 100 : ''
//       }`);

//     if (typeof obj.status !== 'number' && !obj.status?.includes('Архив')) {
//       obj.fastStat = 0;
//       obj.fastStat72 = 0;

//       obj.statisticsSpentToday = 0;
//     }

//     if (obj.hasOwnProperty('mtProject')) {
//       // @ts-ignore
//       obj.mtProject = (obj.mtProject as string)
//         .split(',')
//         .map(el => (wsDictionary.projects.hasOwnProperty(el) ? wsDictionary.projects[el] : { name: 'Не определен' }));
//     }

//     // для получение имени отвественного ( есть методы преобразования по которому получаем id по
//     // responsible и в wsDictionary.members получаем имя ответсвенного по id - для того что бы определялось имя ответсвенного
//     // по id и если его нет то будет выводится "не указано", а в интерфейсе будет пусто вместо ответственного)

//     if (obj.hasOwnProperty('responsible')) {
//       obj.responsible = wsDictionary.members[obj.responsible as string] || 'Не указано';
//     }

//     data.push(obj);
//   });

//   if (data[0].status) {
//     data[0].status.toString().length &&
//       data.sort((a, b) => {
//         let aStatus = getSortByStatus(a.status);
//         let bStatus = getSortByStatus(b.status);

//         return aStatus - bStatus === 0
//           ? a[manageVkTableMainNames[tabName]].toString().toLowerCase() >
//             b[manageVkTableMainNames[tabName]].toString().toLowerCase()
//             ? 1
//             : -1
//           : aStatus - bStatus;
//       });
//   }

//   if (data[0].status) {
//     const active = data.filter(obj => typeof obj.status === 'string' && obj.status.includes('Активн'));
//     const disabled = data.filter(obj => typeof obj.status === 'string' && obj.status.includes('Остановл'));
//     const archived = data.filter(obj => typeof obj.status === 'string' && obj.status.includes('Архивн'));

//     const activeActive = active.filter(el => el.statusColor !== 'banned');
//     const activeBanned = active.filter(el => el.statusColor === 'banned');

//     const disabledActive = disabled.filter(el => el.statusColor !== 'banned');
//     const disabledBanned = disabled.filter(el => el.statusColor === 'banned');

//     return data[0].adModerationStatus
//       ? [...activeActive, ...activeBanned, ...disabledActive, ...disabledBanned, ...archived]
//       : [...active, ...disabled, ...archived];
//   }
//   return data;
// };

export const getColumnsForTable = (
  columns: string[],
  dictionary: Dictionary,
  metrics: TMetricGroup[],
  fast72Checked?: boolean,
) => {
  const result: TMetric[] = [];

  // нужно для добавления колонки по умолчанию
  columns.push('satisticsFast');

  Object.keys(dictionary).forEach(el => {
    columns.forEach(columnName => {
      if (
        columnName === el &&
        columnName !== parseStringToCamelCase(ADS.ACTIVE) &&
        columnName !== parseStringToCamelCase(ADS.PAUSED) &&
        columnName !== parseStringToCamelCase(ADS.ARCHIVED)
      ) {
        metrics.forEach(group => {
          group.fields.forEach(metric => {
            if (columnName === parseStringToCamelCase(metric.path)) {
              result.push(metric);
            }
            if (
              fast72Checked &&
              columnName === parseStringToCamelCase(FAST_STAT.SatisticsFast1) &&
              metric.path === FAST_STAT.SatisticsFast1
            ) {
              metrics.forEach(
                groups =>
                  groups.path === METRICS_GROUP_PATHS.STATISTIC &&
                  groups.fields.forEach(metric => metric.path === FAST_STAT.SatisticsFast72 && result.push(metric)),
              );
            }
          });
        });
      }

      if (columnName === parseStringToCamelCase(ADS.ACTIVE) && el === parseStringToCamelCase(ADS.ACTIVE)) {
        result.push({
          hidden: true,
          math_allowed: true,
          math_default: 'sum',
          name: { ru: 'Кол-во объявлений', en: 'Ads count' },
          obj_types: ['account', 'banner', 'ad_plan', 'campaign'],
          path: ADS.ALL,
          position: 'right',
          round_digits: 2,
          type: 'percent',
        });
      }
    });
  });
  return result;
};

export const translateStatus = (status: string, tabName: ManageVkTabNameEnum): string => {
  switch (status) {
    case '0':
      return tabName === 'ad_ids' ? 'Остановлено' : 'Остановлена';
    case '1':
      return tabName === 'ad_ids' ? 'Активное' : 'Активная';
    case '2':
      return tabName === 'ad_ids' ? 'Архивное' : 'Архивная';
    default:
      return '';
  }
};

export const getSortBudget = (tabName?: keyof typeof ManageVkNameCellsEnum, isDaily?: boolean) => {
  if (tabName === 'adPlanName') {
    return isDaily ? 'adPlanDaily' : 'adPlanLifetime';
  }
  if (tabName === 'campaignName' || tabName === 'adName') {
    return isDaily ? 'campaignDaily' : 'campaignLifetime';
  }
  if (tabName === 'accountName') {
    return isDaily ? 'accountDaily' : 'accountLifetime';
  } else {
    return '';
  }
};

export const getSortByStatus = (status: any) => {
  if (status.includes('Активн')) {
    return 0;
  }
  if (status.includes('Остан')) {
    return 1;
  }
  if (status.includes('Архив')) {
    return 2;
  } else {
    return 3;
  }
};

export const comporatorStatus =
  (tabName: keyof typeof ManageVkNameCellsEnum) =>
  (valueA: string, valueB: string, nodeA: IRowNode, nodeB: IRowNode, isDescending: boolean) => {
    let aStatus = getSortByStatus(valueA || '');
    let bStatus = getSortByStatus(valueB || '');

    return aStatus - bStatus === 0
      ? nodeA.data[tabName].toLowerCase() > nodeB.data[tabName].toLowerCase()
        ? 1
        : -1
      : aStatus - bStatus;
  };

// for cells
export const returnCellRules = (status: string): boolean => {
  let value = false;
  if (status === 'Архивная' || status === 'Архивное') {
    value = true;
  }
  return value;
};

// приводит дату к виду "2023-04-27T00:00:00+03:00" - такого вида строку стоку мы получаем от мт-апишки для дасписания у рекламных объектов из vk ads
export const formatDateToReqString = (argDate: string | Date | undefined) =>
  argDate ? formatDateToReq(argDate) + 'T00:00:00+03:00' : null;

// данные непосредственно изменённых рекламных объектов из vk-ads, которые нужно перезаписать в сторе после ответа от апи о том, что всё успешно отредактировалось
export const getEditedStrategyItems = (
  requestData: StrategyBidSaveActionProps,
  isOptimized: boolean, // бюджет оптимизирован на уровне адпдана (кампании), либо не оптимизирован и определён отдельно для каждого campaign (групп)
): Record<
  string,
  {
    maxPrice: number | '';
    adPlanDaily: number | '';
    adPlanLifetime: number | '';
    campaignDaily: number | '';
    campaignLifetime: number | '';
    campaignStart: string | null;
    campaignStop: string | null;
  }
> => {
  const editedItems: Record<
    string,
    {
      maxPrice: number | '';
      adPlanDaily: number | '';
      adPlanLifetime: number | '';
      campaignDaily: number | '';
      campaignLifetime: number | '';
      campaignStart: string | null;
      campaignStop: string | null;
    }
  > = {};

  requestData.forEach(
    item =>
      (editedItems[item.obj_id.toString()] = {
        maxPrice: item.max_price || '',
        adPlanDaily: isOptimized ? item.budget_limit_day || '' : '',
        adPlanLifetime: isOptimized ? item.budget_limit || '' : '',
        campaignDaily: item.budget_limit_day || '',
        campaignLifetime: item.budget_limit || '',
        campaignStart: formatDateToReqString(item.date_start),
        campaignStop: formatDateToReqString(item.date_end),
      }),
  );

  return editedItems;
};

// данные нередактированных сиблингов (находятся в тех же адпланах или группах), которые нужно обновить
export const getStrategySiblingsNewData = (
  newBidsData: TNewStrategyBids,
  isOptimized: boolean, // бюджет оптимизирован на уровне адпдана (кампании), либо не оптимизирован и определён отдельно для каждого campaign (группы)
  parentIdName: 'adPlanId' | 'campaignId',
  parentIdsSet: Set<string>,
) => {
  const siblingsNewData: Record<
    string,
    {
      maxPrice: number | '';
      adPlanDaily: number | '';
      adPlanLifetime: number | '';
      campaignDaily: number | '';
      campaignLifetime: number | '';
    }
  > = {};

  Array.from(parentIdsSet).forEach(parentId => {
    const data = newBidsData.find(data => data[parentIdName] === parentId);

    if (data) {
      siblingsNewData[parentId] = {
        maxPrice: data.maxPrice || '',
        adPlanDaily: isOptimized ? data.limitDaily || '' : '', // если бюджеты не оптимизированы, то в adPlanDaily и adPlanLifetime всегда лежит пустая строка
        adPlanLifetime: isOptimized ? data.limitLifetime || '' : '',
        campaignDaily: data.limitDaily || '',
        campaignLifetime: data.limitLifetime || '',
      };
    }
  });

  return siblingsNewData;
};

// просто возвращает даты работы кампании, если мы имеем массив кампаний
const getUnoptimizedCampaignsSchedule = (
  siblingsSchedule: Record<
    string,
    {
      campaignStart: string | null;
      campaignStop: string | null;
    }
  >,
  newBidsData: TNewStrategyBids,
  campaignIdsSet: Set<string>,
) => {
  Array.from(campaignIdsSet).forEach(campaignId => {
    const data = newBidsData.find(data => data.campaignId === campaignId);

    if (data) {
      siblingsSchedule[campaignId] = {
        campaignStart: formatDateToReqString(data.campaignStart),
        campaignStop: formatDateToReqString(data.campaignStop),
      };
    }
  });
};

const getOptimizedCampaignsSchedule = (
  siblingsSchedule: Record<
    string,
    {
      campaignStart: string | null;
      campaignStop: string | null;
    }
  >,
  newBidsData: TNewStrategyBids,
  storeData: Record<string, any>[] | undefined,
) => {
  const campaignsSchedule: Record<
    string,
    {
      campaignStart: string | null;
      campaignStop: string | null;
    }
  > = {};

  // собираем данные: у какой группы какое актуальное расписание
  newBidsData.forEach(ad => {
    if (ad.campaignId && !campaignsSchedule[ad.campaignId]) {
      campaignsSchedule[ad.campaignId] = {
        campaignStart: formatDateToReqString(ad.campaignStart),
        campaignStop: formatDateToReqString(ad.campaignStop),
      };
    }
  });

  // заполняем объект для перезаписывания дат работы кампаний для сиблингов отредактированных объявлений
  storeData?.forEach(ad => {
    if (campaignsSchedule[ad.campaignId]) {
      siblingsSchedule[ad.adId] = {
        campaignStart: campaignsSchedule[ad.campaignId].campaignStart,
        campaignStop: campaignsSchedule[ad.campaignId].campaignStop,
      };
    }
  });
};

type TAdsScheduleProps = {
  commonBudgetOptimizationLevel: TCommonBudgetOptimizationLevel;
  itemsType: 'campaign' | 'banner';
  newBidsData: TNewStrategyBids;
  storeData: Record<string, any>[] | undefined;
  parentIdsSet: Set<string>;
};

// собирает объект для обновления дат работы кампаний у сиблингов отредактированных объявлений
export const getAdsCampaignSchedule = ({
  commonBudgetOptimizationLevel,
  itemsType,
  newBidsData,
  storeData,
  parentIdsSet,
}: TAdsScheduleProps) => {
  const siblingsSchedule: Record<
    string,
    {
      campaignStart: string | null;
      campaignStop: string | null;
    }
  > = {};

  if (commonBudgetOptimizationLevel === 'campaign' && itemsType === 'banner') {
    // для кампэйнов нормально редактируется
    getUnoptimizedCampaignsSchedule(siblingsSchedule, newBidsData, parentIdsSet);
  }

  if (commonBudgetOptimizationLevel === 'adPlan' && itemsType === 'banner') {
    getOptimizedCampaignsSchedule(siblingsSchedule, newBidsData, storeData);
  }

  return siblingsSchedule;
};

export const getStrategySiblingsData = (
  parentIdName: 'adPlanId' | 'campaignId',
  requestData: StrategyBidSaveActionProps,
  gridApi: GridApi,
) => {
  // массив с id объектов, которые непосредственно редактируются. Это будут либо adPlanId, либо campaignId, либо adId (в зависимости от itemsType)
  const requestIds = requestData.map(item => item.obj_id.toString());

  // массив rowNodes, найденных по id. Будут соответстовать одному из трёх типов рекламных сущностей: adPlan, campaign, banner
  const rowNodes = requestIds.map(id => gridApi.getRowNode(id));

  // если мы редактируем группы или объявления, то в этой переменной соберутся все родительские adPlanId. Если редактируем адплан, то тут будут только редактируемые адпланы
  const parentIdsOfEditedItems: Array<string> = rowNodes
    .map(node => node?.data[parentIdName]) // id могут дублироваться при редактированнии групп или объявлений
    .filter((value, index, self) => value !== undefined && self.indexOf(value) === index); // убрали дублирующиеся id  и undefined (которых быть не должно, но теоретически могут быть)

  // В эту переменную мы записываем данные всех рекламных объектов, имеющих в качестве родителя один из id , указанныв в parentIdsOfEditedItems
  // Если мы редактируем ad-groups или banners, то сюда могут попасть другие строки из таблицы, имеющие тот же родительский id, что и редактируемые строки.
  // Если редактируем адпланы, то сюда просто попадут данные этих адпланов
  const siblingsDataByParentId: any[] = [];

  // создаём массив со всеми объектами, в которых есть один из parentId
  rowNodes.length &&
    parentIdsOfEditedItems.forEach(parentId => {
      gridApi.forEachNodeAfterFilter(
        node => node.data[parentIdName] === parentId && siblingsDataByParentId.push(node.data),
      );
    });

  return {
    keysOfSiblingsToEdit: siblingsDataByParentId.map(item => item.key),
    parentsIds: new Set(parentIdsOfEditedItems),
  };
};
