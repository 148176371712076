import { ArrowUpIcon, Typography } from '@plarin/design';
import { Button } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TWsProject } from '../../../../../types/teams/teams';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { WorkspaceDrawerItemList } from '../../../../components/workspace-drawer-component/workspace-drawer-item-list';
import { WorkspaceDrawerSearchPopup } from '../../../../components/workspace-drawer-component/workspace-drawer-search-popup/workspace-drawer-search-popup';
import { menuSectionIndexes } from '../../../../utils/constants';
import { PROJECTS } from '../../../../routing/router-path';
import classes from './teams-drawer.module.scss';

export const TeamsDrawerProject = observer(() => {
  const {
    teams: { dataDrawer, updateDataDrawer, onCloseDrawer },
    menu: { selectMenuItem },
    projects: { projectsData },
    profileStore: { data: profileData, WSRole },
  } = useAppDataStore();
  const history = useHistory();

  const [allProjectItems, setAllProjectItems] = useState<TWsProject[]>([]);
  const [memberItems, setMemberItems] = useState<TWsProject[]>([]);

  const onAddProject = useCallback((item: TWsProject) => {
    dataDrawer?.project_ids && updateDataDrawer('project_ids', [...dataDrawer.project_ids, item._id]);
  }, []);

  const deleteItem = useCallback((id: string) => {
    dataDrawer?.project_ids &&
      updateDataDrawer(
        'project_ids',
        dataDrawer?.project_ids.filter(e => e !== id),
      );
  }, []);

  useEffect(() => {
    const hash: { [key in string]: boolean } = {};
    const all: TWsProject[] = [];
    const members: TWsProject[] = [];

    dataDrawer?.project_ids.forEach(e => (hash[e] = true));

    projectsData.forEach(e => {
      if (hash[e._id]) {
        members.push(e);
      } else {
        all.push(e);
      }
    });

    setMemberItems(members);
    setAllProjectItems(all);
  }, [dataDrawer?.project_ids]);

  const redirectToProjects = () => {
    onCloseDrawer();
    history.push(PROJECTS);
    selectMenuItem(PROJECTS, false, menuSectionIndexes.ws);
  };

  return (
    <div className={clsx(classes.member, !projectsData.length && classes.isGap)}>
      <div className={classes.memberTitle}>
        <Typography size="Main" weight={600}>
          Проекты
        </Typography>

        {!dataDrawer?.canOnlyView && !!projectsData.length && (
          <WorkspaceDrawerSearchPopup
            avatarType="square"
            searchPlaceholder="Поиск проекта"
            buttonText="Добавить проект"
            listItems={allProjectItems}
            setItem={onAddProject}
            allAddedText="Все проекты добавлены в команду."
            searchErrorText="По вашему запросу не найден ни один проект."
          />
        )}
      </div>

      {!dataDrawer?.project_ids.length ? (
        <>
          <Typography size="AuthContent" color="TextSecondary">
            {!projectsData.length
              ? 'У вас еще не созданы проекты в пространстве'
              : 'В команду еще не добавлен ни один проект'}
          </Typography>
          {!projectsData.length && (
            <Button className={classes.buttonAddForm} onClick={redirectToProjects}>
              <div className={classes.addItem}>
                <Typography size="AuthContent" weight={600} color="TextButton">
                  Перейти к созданию проектов
                </Typography>
                <ArrowUpIcon className={classes.arrowIcon} />
              </div>
            </Button>
          )}
        </>
      ) : null}

      <div>
        {memberItems.map(e => {
          return (
            <WorkspaceDrawerItemList
              key={e._id}
              status={e.status}
              name={e.name}
              avatarColor={e.avatar_color}
              id={e._id}
              deleteAction={dataDrawer?.canOnlyView ? () => {} : deleteItem}
              avatarType="square"
              hasAdminRights={
                dataDrawer?.leader_id === profileData.user_id || WSRole === 'wsrole:admin' || WSRole === 'wsrole:owner'
              }
            />
          );
        })}
      </div>
    </div>
  );
});
