import React, { useState } from 'react';
import { MainTooltip } from '../tooltip';
import { SocialButtonProps } from './social-button-props';

const getTooltipMessage = (socialName?: string): string => {
  if (window.location.href.endsWith('/registration')) {
    return `Регистрация через ${socialName} временно недоступна`;
  }
  return `Вход через ${socialName} временно недоступен`;
};

export const SocialButton: React.FC<SocialButtonProps> = function SocialButton({
  icon,
  children,
  onClick,
  isDeactivated = false,
  socialName,
}) {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <MainTooltip
      tooltipMessage={getTooltipMessage(socialName)}
      isVisible={isDeactivated && isVisible}
      placement="bottom"
      component="div"
    >
      <button
        type="button"
        className={isDeactivated ? 'social-button-deactivated' : 'social-button'}
        onClick={() => void onClick?.()}
        onMouseOver={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {!!icon && <i className="social-button__icon">{icon}</i>}
        <span className="social-button__label">{children}</span>
      </button>
    </MainTooltip>
  );
};
