import { IconStatus, Typography } from '@plarin/design';
import { getNumeral } from '@plarin/utils';
import { ICellRendererParams } from 'ag-grid-community';
import React, { useCallback, useRef, useState } from 'react';
import { HightLightText } from '../../../hightlight-text';
import { Popover } from '../../../popover';
import { SkeletonTableBlock } from '../../../preloader';
import classes from './style.module.scss';
import { TranslationItem } from './translationItem';
import { parseDeliveryStatus, TRANSLATION } from './utils';

export const TranslationRenderer = (props: ICellRendererParams) => {
  const { data } = props;
  const { status, deliveryStatus, issues, cellLoadingName } = data;
  const moderationReasons = data.adModerationReasons || ([] as typeof issues);

  const [isOpen, setOpen] = useState(false);
  const [isPositionLeft, setPositionLeft] = useState(false);

  const issuesRef = useRef<HTMLDivElement>(null);

  const onOpen = useCallback(e => {
    e.stopPropagation();

    e.view.innerWidth - e.clientX < 450 ? setPositionLeft(true) : setPositionLeft(false);
    setOpen(true);
  }, []);

  const onClose = useCallback((e: React.MouseEvent | Event) => {
    e.stopPropagation();
    setOpen(false);
  }, []);

  // @ts-ignore
  const filterValue = props.api.filterManager.quickFilter;

  const text = status?.includes('Актив') ? parseDeliveryStatus(deliveryStatus) : status;

  if (cellLoadingName === 'status') return <SkeletonTableBlock withCheckbox isReversLong height={17} />;

  return (
    <>
      <div className={classes.translationContainer}>
        {status && (
          <IconStatus status={status?.includes('Актив') && deliveryStatus === 'not_delivering' ? 'Останов' : status} />
        )}

        <span className={classes.statusText}>
          <HightLightText text={text} filterValue={filterValue} />
        </span>
      </div>
      {issues?.length && status?.includes('Актив') ? (
        <div
          ref={ref => {
            if (!ref) return;
            ref.onclick = event => onOpen(event);
          }}
          className={classes.statusIssues}
        >
          <Typography size="SmallText" color="LinkPrimary" componentProps={{ ref: issuesRef }}>{`${
            issues?.length + moderationReasons?.length
          } ${getNumeral(issues?.length + moderationReasons?.length, ['причина', 'причины', 'причин'])}`}</Typography>
        </div>
      ) : null}

      {Array.isArray(issues) && issues?.length && (
        <Popover isOpen={isOpen} setIsOpen={onClose} anchorEl={issuesRef.current}>
          <>
            {[...issues, ...moderationReasons?.map((el: any) => ({ ...el, code: 'Остановлена' }))]?.map(
              (elem: { arguments: { [index: string]: string }; code: keyof typeof TRANSLATION; message: string }) => {
                return (
                  <TranslationItem
                    key={elem.code}
                    itemName={elem.code}
                    itemDescription={elem.message}
                    args={elem.arguments}
                    onClose={onClose}
                    isPositionLeft={isPositionLeft}
                  />
                );
              },
            )}
          </>
        </Popover>
      )}
    </>
  );
};
