import { Avatar, EyeOffIcon, LockIcon, SettingsIcon } from '@plarin/design';
import { typografNames } from '@plarin/utils';
import { ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { HightLightText } from '../../../hightlight-text';
import { AvatarSkeleton } from '../../../preloader/skeleton/avatar-sceleton';
import { EllipsisTooltip } from '../../../tooltip';
import classes from './style.module.scss';

// export interface IEditProjectData {
//   avatar_color: string;
//   date_end: string;
//   date_start: string;
//   guest_ids: string[];
//   is_archived: null | boolean;
//   key: string;
//   manager_id: string;
//   name: string;
//   status: string;
//   tm_client_ids: number[];
//   _id: string;
// }

interface Props extends ICellRendererParams {
  data: any; // TGetTeamByIdResponse
  /** проверить
     *  data: any; // TGetTeamByIdResponse
  settingMenuOptions: {
    value: string;
    label: string;
    labelArchived?: string;
    action?: (e: IEditProjectData | null) => void;
  }[];
};* */
  wsRole: 'wsrole:owner' | 'wsrole:admin' | 'wsrole:employee' | 'wsrole:guest' | undefined;
  userId?: boolean;
  ProjectMenu: React.FC<{ data: any }>;
  SettingMenu: React.FC<{
    data: any;
    onClose: (e: React.MouseEvent) => void;
    anchorEl: HTMLDivElement | null;
    isAdmin: boolean;
    isOpen: boolean;
  }>;
}

export const SquareAvatarNameRenderer = ({ node, data, wsRole, SettingMenu, ProjectMenu, userId, api }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  // @ts-ignore
  const filterValue = api.filterManager.quickFilter || '';

  const isActionMenuOpen = Boolean(anchorEl);

  const openMenu = (e: MouseEvent, ref: HTMLDivElement) => {
    e.stopPropagation();
    setAnchorEl(ref);
  };

  const closeMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const isAdmin = useMemo(() => wsRole === 'wsrole:admin' || wsRole === 'wsrole:owner', [wsRole]);

  if (node.data.cellLoadingName && node.data?.cellLoadingName?.includes('name'))
    return <AvatarSkeleton size="long" height={17} />;

  return (
    <div className={classes.wrap}>
      <div className={classes.nameWrap}>
        {data.name && (
          <Avatar
            name={data.name}
            type="square"
            iconType={((node.data.status === 'inactive' || node.data.is_archived) && 'archived') || undefined}
            colorAvatar={
              node.data.status === 'inactive' || node.data.status === 'completed' || node.data.is_archived
                ? 'D3D8DE'
                : node.data.avatar_color
            }
          />
        )}

        <EllipsisTooltip tooltipMessage={node.data.name} maxWidth={480}>
          <HightLightText
            size="Caption"
            weight={600}
            textEllips={true}
            text={typografNames(data.name)}
            filterValue={filterValue}
          />
        </EllipsisTooltip>
      </div>
      <div className={classes.menu}>
        {data.hidden && (
          <EllipsisTooltip tooltipMessage="Невидимая команда">
            <EyeOffIcon color="disabled" size={16} />
          </EllipsisTooltip>
        )}
        {data.closed && (
          <EllipsisTooltip tooltipMessage="Закрытая команда">
            <LockIcon color="disabled" size={16} />
          </EllipsisTooltip>
        )}
      </div>

      {ProjectMenu ? (
        <ProjectMenu data={node.data} />
      ) : (
        (!data.closed || isAdmin || data.leader_id === userId) && (
          <>
            <div
              className={clsx(classes.settingsIcon, isActionMenuOpen && classes.forceVisibility)}
              ref={ref => {
                if (!ref) return;
                ref.onclick = e => openMenu(e, ref);
              }}
            >
              <SettingsIcon color="secondary" size={16} />
            </div>
            <SettingMenu
              data={node.data}
              onClose={closeMenu}
              anchorEl={anchorEl}
              isAdmin={isAdmin}
              isOpen={!!anchorEl}
            />
          </>
        )
      )}
      {/* проверить  <WsTableMainActionPanel
            isOpen={isActionMenuOpen}
            closeMenu={closeMenu}
            actionRef={actionRef}
            anchorEl={anchorEl}
            data={data}
            menuOptions={settingMenuOptions}
            isArchivedItem={data.status === 'inactive'}
        />*/}
    </div>
  );
};
