import { getNumeral } from './numeralsUtils';

export const newDateUtc = () => {
  let newDate = new Date();

  let newDateUtc = Date.UTC(
    newDate.getUTCFullYear(),
    newDate.getUTCMonth(),
    newDate.getUTCDate(),
    newDate.getUTCHours(),
    newDate.getUTCMinutes(),
    newDate.getUTCSeconds(),
    newDate.getUTCMilliseconds(),
  );

  return newDateUtc;
};

export const timeToSendReq = (date: Date) => {
  const nextReqAlw = new Date(new Date(date).toString().split('GMT')[0] + ' UTC').getTime();

  return nextReqAlw - newDateUtc();
};

export const minutesToSendReq = (err: any) => {
  const minToSendReq = Math.round(timeToSendReq(err.response?.data.error.details?.next_request_allowed) / 60000);

  return minToSendReq < 1
    ? 'меньше 1 минуты'
    : minToSendReq + ' ' + getNumeral(minToSendReq, ['минуту', 'минуты', 'минут']);
};
