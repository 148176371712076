import { Typography } from '@plarin/design';
import React, { ReactNode } from 'react';
import { AlertIntegrations } from './alert-integrations';

import './alert-integrations.scss';

interface IAlertIntegrationProps {
  closeAlert(): void;
  errorText?: string;
  titleText: string;
  subtitleText?: string | ReactNode;
}

export const AlertIntegrationError = ({ closeAlert, errorText, subtitleText, titleText }: IAlertIntegrationProps) => {
  return (
    <AlertIntegrations severity="error" closeAlert={closeAlert}>
      <Typography weight={700} color="TextPrimary">
        {titleText}
      </Typography>
      <br />
      <div className="alert-subtitle">
        <Typography color="TextPrimary">{subtitleText}</Typography>
        <div>
          <Typography color="TextPrimary">{errorText}</Typography>
        </div>
      </div>
    </AlertIntegrations>
  );
};
