import { Avatar, Typography } from '@plarin/design';
import { EllipsisTooltip } from '@plarin/inputs';
import { getMemberName } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import { TMembersTeams } from '../../../../../../../types/projects/projects';
import classes from '../project-info.module.scss';

interface IProjectMembersList {
  team: TMembersTeams;
}

export const ProjectMembersList = ({ team }: IProjectMembersList) => {
  const name = getMemberName({ fname: team?.fname, lname: team?.lname, email: team?.email });
  return (
    <div className={clsx(classes.listItemAdded, classes.listMemberAdded)}>
      <Avatar
        colorAvatar={team.avatarColor}
        fname={team.fname}
        lname={team.lname}
        email={team.email}
        classProps={classes.avatar}
      />
      <div className={classes.itemMember}>
        <EllipsisTooltip tooltipMessage={name}>
          <Typography color="TextPrimary" size="AuthContent" weight={400} ellips={true}>
            {name}
          </Typography>
        </EllipsisTooltip>
        <EllipsisTooltip tooltipMessage={`${team.teamName}`}>
          <Typography size="Caption" color="TextSecondary" weight={400} ellips={true}>
            {`${team.teamName.length > 1 ? 'Команды' : 'Команда'}: ${team.teamName.join(', ')} `}
          </Typography>
        </EllipsisTooltip>
      </div>
    </div>
  );
};
