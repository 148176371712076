import { Typography } from '@plarin/design';
import { TypeInput, useInput } from '@plarin/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Input } from '../input';
import classes from './style.module.scss';
import { transliterateWsLink } from './utils';

interface ITwoInputBlocks {
  initNameWorkspace?: { company: string; shortname: string };
  onChangeData?: (e: { company: string; shortname: string }) => void;
  onError?: (e: boolean) => void;
  disabledInput?: boolean;
  direction: 'row' | 'column';
  workspaceName?: string;
  workspaceShortname?: string;
  needAutofill?: boolean;
}

export const TwoInputBlocks = ({
  initNameWorkspace,
  workspaceName,
  workspaceShortname,
  onChangeData,
  onError,
  disabledInput,
  direction,
  needAutofill,
}: ITwoInputBlocks) => {
  const [wsName, setWsName] = useState(workspaceName || '');
  const [wsLink, setWsLink] = useState(workspaceShortname || '');
  const [hasAutoFill, setHasAutoFill] = useState(true);
  const [isRequiredWsName, setIsRequiredWsName] = useState(false);
  const [isRequiredWsLink, setIsRequiredWsLink] = useState(false);

  const { error: errorName, hint: hintName } = useInput({
    initialValue: wsName || '',
    type: TypeInput.workspace,
    isRequired: isRequiredWsName,
  });
  const { error: errorLink, hint: hintLink } = useInput({
    initialValue: wsLink || '',
    type: TypeInput.workspace,
    isRequired: isRequiredWsLink,
  });

  useEffect(() => {
    needAutofill && !workspaceName && !workspaceShortname && setHasAutoFill(true); // если два инпута пустых будет автозаполнение второго
    setWsName(workspaceName || '');
    setWsLink(workspaceShortname || '');
  }, [workspaceName, workspaceShortname]);

  useEffect(() => {
    // отвечает за изначально выключенное состояние автозаполнения
    needAutofill && setHasAutoFill(false);
  }, [needAutofill]);

  useEffect(() => {
    // при возврате к изначальному состоянию отменяет автозаполнение
    initNameWorkspace && setHasAutoFill(false);
  }, [initNameWorkspace]);

  useEffect(() => {
    onChangeData && onChangeData({ company: wsName, shortname: wsLink });
    onError && onError(errorName || errorLink);
  }, [wsName, wsLink, errorName, errorLink]);

  return (
    <>
      <div className={clsx(direction === 'column' ? classes.columnWrapper : classes.rowWrapper)}>
        <div>
          <Input
            disabledInput
            disabled={disabledInput}
            type="text"
            maxLength={42}
            maxCounterValue={42}
            inputRootWrapperDefault
            error={errorName}
            hint={hintName}
            label="Название пространства"
            size={48}
            value={wsName}
            onChange={e => {
              const linkValue = transliterateWsLink(e);
              !isRequiredWsName && setIsRequiredWsName(true);
              hasAutoFill && setWsLink(linkValue.length <= 42 ? linkValue : linkValue.slice(0, 42));
              setWsName(e);
              !isRequiredWsLink && setIsRequiredWsLink(true);
            }}
            onBlur={() => {
              setWsName(wsName.trim());
            }}
          />
        </div>
        <Input
          disabledInput
          error={errorLink}
          disabled={disabledInput}
          inputRootWrapperDefault
          type="text"
          maxLength={42}
          label="Название ссылки для пространства"
          size={48}
          value={wsLink}
          onChange={e => {
            needAutofill && setHasAutoFill(false);
            !needAutofill && setHasAutoFill(!e.length);
            !isRequiredWsLink && setIsRequiredWsLink(true);
            setWsLink(transliterateWsLink(e));
          }}
        />
      </div>
    </>
  );
};
