import { Typography } from '@plarin/design';
import { typografText } from '@plarin/utils';
import React from 'react';
import classes from './drawer-content-connect-start.module.scss';

export const MyTargetText = () => {
  return (
    <div>
      <Typography size="AuthContent" componentProps={{ className: classes.blockText }}>
        {typografText(
          'Для подключения аккаута myTarget вам необходимо предварительно войти в него в этом же браузере. После нажать кнопку подключить аккаунт.',
        )}
        <p>{typografText('Вы можете подключить любое количество аккаунтов myTarget любого типа.')}</p>
      </Typography>
    </div>
  );
};
