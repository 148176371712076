import React, { useState } from 'react';
import { SkeletonTableRow } from './skeleton-table-row';
import classes from './skeleton-table-row.module.scss';

type TitleProps = { title?: string };

export const SkeletonTable = ({ title }: TitleProps) => {
  const heightHeader = 257;
  const [rows] = useState<number[]>([
    ...Array(Math.floor((document.documentElement.clientHeight - heightHeader + 100) / 40)).keys(),
  ]);
  return (
    <div className={classes.tableWrapper}>
      <div className={classes.skeletonTable}>
        {rows.map(item => {
          if (item === 0) return <SkeletonTableRow isHeader={true} key={item} isColor={true} />;
          if (!(item % 2)) return <SkeletonTableRow key={item} isColor={true} />;
          return <SkeletonTableRow key={item} />;
        })}
      </div>
    </div>
  );
};
