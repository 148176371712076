import { IconButton } from '@mui/material';
import React from 'react';
import { WhiteCircle } from './white-circle';
import './spread-workflow-button.scss';
import './workflow-button.scss';

interface ISpreadIcon {
  count?: number;
}

export const SpreadWorkflowButton: React.FC<ISpreadIcon> = ({ count = 2 }) => {
  return (
    <IconButton
      classes={{
        root: 'workFlowIconButton',
      }}
    >
      <div className="spread-workflow-icon">
        <span>{`+${count}`}</span>
      </div>
      <WhiteCircle />
    </IconButton>
  );
};
