import { useState } from 'react';

export const useDebounce = () => {
  // @ts-ignore
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>('');
  const debounce = (func: any, time: number) => {
    clearTimeout(typingTimeout);
    const timeout = setTimeout(() => func(), time);
    setTypingTimeout(timeout);
  };
  return debounce;
};
