import React, { useState, useEffect, useRef } from 'react';
import classes from '../style.module.scss';

export const CustomLabelDot: React.FunctionComponent<any> = (props: any) => {
  let { x, y } = props.viewBox;
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    ref.current ? setWidth(ref.current.offsetWidth) : setWidth(0);
  }, [x]);

  return (
    <g>
      <foreignObject x={x - (width - 21) / 2} y={y - 30} width={150} height={40}>
        <div className={classes.labelDot} ref={ref}>
          {props.value}
        </div>
      </foreignObject>
    </g>
  );
};
