import { ToggleButton as ToggleButtonMui } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import classes from './toggle-button.module.scss';

type ToggleButtonProps = {
  children: React.ReactElement;
  value: string;
  color?: string;
};

export const ToggleButton = ({ children, value, color, ...props }: ToggleButtonProps) => {
  return (
    <ToggleButtonMui
      classes={{
        root: classes.toggleButton,
        selected: clsx(
          color === 'success' && classes.sucSelected,
          color === 'warning' && classes.warSelected,
          color === 'error' && classes.errSelected,
        ),
      }}
      value={value}
      aria-label={value}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      {...props}
    >
      {children}
    </ToggleButtonMui>
  );
};
