import React from 'react';

export type TabsProps = {
  tabs: TabItem[];
  value: string;
  onChange: (newValue: string) => void;
  link?: string;
  component?: any;
  className?: string;
};

export type TabItem = {
  value: string;
  label: string;
  link?: string;
  disabled?: boolean;
  onClick?: () => void;
  tooltipMessage?: string;
};

// mui-5 tabs
export interface TabPanelProps {
  children?: React.ReactNode;
  hidden: boolean;
}
