import { Typography } from '@plarin/design';
import React from 'react';
import classes from './schedule-layoyt.module.scss';

export const ScheduleLayout: React.FC<{ title: string }> = ({ title, children }) => {
  return (
    <div className={classes.scheduleLayout}>
      <Typography
        componentProps={{ className: classes.scheduleTitle }}
        size="BlockTitle"
        weight={600}
        color="TextPrimary"
      >
        {title}
      </Typography>
      {children}
    </div>
  );
};
