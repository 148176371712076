export enum EHttpsStatus {
  'OK' = 200,
  'BAD_REQUEST' = 400,
  'UNAUTHORIZED' = 401,
  'FORBIDDEN' = 403,
  'NOT_FOUND' = 404,
  'SERVER_ERROR' = 500,
}

export enum EErrorCode {
  UNPROCESSABLE_ENTITY = 422,
}

export type TValidationErrorPayload = {
  [fieldName: string]: string[];
};

export enum EApiCodes {
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  INCORRECT_EMAIL = 'INCORRECT_EMAIL',
  INVALID_JSON = 'INVALID_JSON',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  WRONG_RESPONSE_FORMAT = 'WRONG_RESPONSE_FORMAT',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  NO_AUTHORIZATION_HEADER = 'NO_AUTHORIZATION_HEADER',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  EMAIL_ALREADY_CONFIRMED = 'EMAIL_ALREADY_CONFIRMED',
  PHONE_ALREADY_CONFIRMED = 'PHONE_ALREADY_CONFIRMED',
  USER_DOES_NOT_EXISTS = 'USER_DOES_NOT_EXISTS',
  USER_IS_NOT_ACTIVE = 'USER_IS_NOT_ACTIVE',
  EMAIL_NOT_CONFIRMED = 'EMAIL_NOT_CONFIRMED',
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
  INVALID_TOKEN = 'INVALID_TOKEN',
  UNAUTHORIZED = 'UNAUTHORIZED',
  CAN_NOT_CHANGE_VALUE = 'CAN_NOT_CHANGE_VALUE',
  ALREADY_USED = 'ALREADY_USED',
  NOTHING_TO_CHANGE = 'NOTHING_TO_CHANGE',
  NO_NEW_MAIL = 'NO_NEW_MAIL',
  CURRENT_PASSWORD_INVALID = 'CURRENT_PASSWORD_INVALID',
  NO_VKAD_CABINET = 'NO_VKAD_CABINET',
  SOCIAL_NETWORK_ACCOUNT = 'SOCIAL_NETWORK_ACCOUNT',
  NEED_CAPTCHA = 'NEED_CAPTCHA',
  WRONG_CAPTCHA = 'WRONG_CAPTCHA',
  THROTTLING_LIMIT_5_MIN = 'THROTTLING_LIMIT_5_MIN',
  THROTTLING_LIMIT_2_MIN = 'THROTTLING_LIMIT_2_MIN',
  THROTTLING_LIMIT_1_MIN = 'THROTTLING_LIMIT_1_MIN',
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
  PHONE_NOT_CONFIRMED = 'PHONE_NOT_CONFIRMED',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  PHONE_NOT_FOUND = 'PHONE_NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
  SOCIAL_NETWORK_NOT_CONNECTED = 'SOCIAL_NETWORK_NOT_CONNECTED',
  TWILIO_ERROR_CODE = 'TWILIO_ERROR_CODE',
  TWILIO_INVALID_TO_PHONE_NUMBER = 'TWILIO_INVALID_TO_PHONE_NUMBER',
  DISCONNECTING_LAST_POSSIBLE_LOGIN_METHOD = 'DISCONNECTING_LAST_POSSIBLE_LOGIN_METHOD',
  WRONG_SMS_CODE = 'WRONG_SMS_CODE',
  EXPIRED_CODE = 'EXPIRED_CODE',
  SMS_NOT_SENT = 'SMS_NOT_SENT',
  PHONE_ALREADY_USED = 'PHONE_ALREADY_USED',
  EMAIL_REQUIRED = 'EMAIL_REQUIRED',
}
