import * as Sentry from '@sentry/react';
import { Breadcrumb, Extras, Primitive, Scope as ScopeInterface, SeverityLevel } from '@sentry/types';

type SentryCapture = {
  captureMessage?: { message: string; level?: SeverityLevel };
  tags?: { [key: string]: Primitive };
  breadcrumb?: Breadcrumb;
  extras?: Extras;
  error?: Error;
};

export const captureError = ({ tags, extras, error, breadcrumb, captureMessage }: SentryCapture) => {
  Sentry.withScope(scope => {
    tags && scope.setTags(tags);
    breadcrumb && scope.addBreadcrumb(breadcrumb);
    extras && scope.setExtras(extras);
    error && Sentry.captureException(error);
    captureMessage?.message && Sentry.captureMessage(captureMessage.message, captureMessage.level);
  });
};
