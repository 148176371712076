import React from 'react';
import { useAppDataStore } from '../../components/app-data-provider';

export const WorkspaceRoot = () => {
  const {
    profileStore: { workspaceName },
  } = useAppDataStore();

  return <div>Вы перешли на главную страницу пространства - {workspaceName}</div>;
};
