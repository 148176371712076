import { Typography } from '@plarin/design';
import { getYmCounter, isUrlDev, isUrlProd } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { CreateNewWs } from '../../../../components/create-new-ws';
import { WorkspaceItem } from '../workspace-card/ws-item';
import classes from './styles.module.scss';

type Props = {
  setIsDrawerOpen: (e: boolean) => unknown;
  margins?: string; // передаём сюда стили, задающие верхний и нижний отступы для обёртки
};

export const AllWsList = observer(({ setIsDrawerOpen, margins }: Props) => {
  const {
    profileStore: { workspaceName },
    ws: { wsList },
  } = useAppDataStore();

  const location = useLocation();
  // Отправляем хит в Яндекс Метрику только с рутовой страницы
  if (isUrlProd || isUrlDev) {
    useEffect(() => {
      if (location.pathname === '/') {
        // @ts-ignore
        window.ym(getYmCounter(), 'hit', location.pathname);
      }
    }, [location.pathname])
  }

  return (
    <div className={clsx(classes.blockWrap, !workspaceName && classes.blockWrapWs, margins)}>
      <div className={classes.createBlock}>
        <Typography
          size={workspaceName ? 'AuthSubheader' : 'WorkspaceTitle'}
          color={workspaceName ? 'textSecondary' : 'textPrimary'}
          weight={workspaceName ? 400 : 600}
        >
          {workspaceName ? 'Выберите пространство для перехода' : 'Ваши пространства'}
        </Typography>
        {workspaceName && <CreateNewWs setIsDrawerOpen={setIsDrawerOpen} />}
      </div>

      <div className={clsx(classes.workspacesList, !workspaceName && classes.listOne)}>
        {wsList.map(el => (
          <WorkspaceItem item={el} key={el._id} />
        ))}
      </div>
    </div>
  );
});
