import { LineChart } from '@plarin/inputs';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { ProjectsOverviewChartsResponse } from '../../../../../types/manage-vk/types';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { ScheduleLayout } from '../schedule-layout';

export const SpmSpsStatTable = ({ project_id }: { project_id: string }) => {
  const {
    projectOverview: { getChartsData },
  } = useAppDataStore();
  // TODO подумать над стэйтом для графифков
  const [data, setData] = useState<ProjectsOverviewChartsResponse>();
  useEffect(() => {
    project_id &&
      getChartsData({
        project_id: project_id,
        network: 'mt',
        name: 'cpc/cpm',
        relative_period: 'all_time',
        group_by: 'day',
      }).then(res => setData(res));
  }, [project_id]);

  return (
    <ScheduleLayout title="Динамика CPM и CPC">
      <LineChart data={data?.grouped} />
    </ScheduleLayout>
  );
};
