import { DescriptionIcon, Typography } from '@plarin/design';
import { Drawer, TitleOptions, EllipsisTooltip } from '@plarin/inputs';
import { typografNames } from '@plarin/utils';
import { observer } from 'mobx-react';
import React from 'react';
import { TShortClientsData } from '../../../../types/reports/reports';
import { useAppDataStore } from '../../app-data-provider';
import { ListReport } from '../../list';
import { CheckesBlock, FilterBlock, PeriodBlock, ReportClients, ReportName, ReportsFooter } from './components';
import classes from './reports-drawer-content.module.scss';

const ReportDrawerComponent = () => {
  const {
    reports: { initialStateDrawer, updateInitialStateDrawer, isShowDrawer, closeDrawer },
  } = useAppDataStore();

  const setName = (name: string) => {
    updateInitialStateDrawer({ name: name });
  };

  const setClientsFilterValue = (filterValue: string) => {
    updateInitialStateDrawer({ clientsFilterValue: filterValue });
  };

  const deleteClient = (id: number) => {
    updateInitialStateDrawer({
      reportClients: initialStateDrawer.reportClients.filter(client => client.account_id !== id),
    });
  };

  const addClient = (client: TShortClientsData[0]) => {
    updateInitialStateDrawer({ reportClients: [...initialStateDrawer.reportClients, client] });
  };

  const setReportsMetrics = (metrics: string[]) => {
    updateInitialStateDrawer({ fields: metrics });
  };

  return (
    <Drawer
      widthDrawer="large"
      isOpen={isShowDrawer}
      closeDrawer={closeDrawer}
      title={
        <TitleOptions
          withTitleBox={classes.withTitleBox}
          iconBlock={<DescriptionIcon />}
          titleName={
            <EllipsisTooltip tooltipMessage={initialStateDrawer?.name}>
              <Typography ellips={true} weight={600} size="DrawerProject">
                {typografNames(initialStateDrawer.name) ||
                  (initialStateDrawer.isEdit ? 'Редактирование отчета' : 'Создание отчета')}
              </Typography>
            </EllipsisTooltip>
          }
        />
      }
      actionBlock={<ReportsFooter />}
    >
      <div className={classes.contentWrapper}>
        <ReportName name={initialStateDrawer.name} setName={setName} defaultName={initialStateDrawer.defaultName} />
        <ReportClients
          filterValue={initialStateDrawer.clientsFilterValue}
          setFilterValue={setClientsFilterValue}
          reportClients={initialStateDrawer.reportClients}
          deleteAction={deleteClient}
          addClient={addClient}
          deleteClient={deleteClient}
        />
        <PeriodBlock
          updateData={updateInitialStateDrawer}
          initialDate={{
            storeStartDate: initialStateDrawer.date_from,
            storeEndDate: initialStateDrawer.date_to,
            storeLabel: initialStateDrawer.relative_period,
          }}
          initialGroup={initialStateDrawer.periodGroups}
          initialExcludeStatus={initialStateDrawer.exclude_no_stat}
        />
        <CheckesBlock
          updateData={updateInitialStateDrawer}
          initialEntities={initialStateDrawer.entities}
          // @ts-ignore
          initialStatuses={initialStateDrawer.statuses}
        />
        <FilterBlock updateData={updateInitialStateDrawer} initialFilters={initialStateDrawer.filters} />
        <ListReport setReportsMetrics={setReportsMetrics} reportMetrics={initialStateDrawer.fields} />
      </div>
    </Drawer>
  );
};

export const ReportDrawer = observer(ReportDrawerComponent);
