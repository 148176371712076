import { VkIcon } from '@plarin/design';
import { typografText } from '@plarin/utils';
import { observer } from 'mobx-react';
import React from 'react';
import { Social } from 'src/enums';
import { useAppDataStore } from '../../../components/app-data-provider';
import { ProfileBlockWrapper } from '../../../components/profile-block-wrapper';
import { ProfileItem } from './profile-item';

const profilePasswordTitle = 'Вход через социальные сети';
const profilePasswordSubTitle =
  'Используйте свои учетные записи в социальных сетях для быстрого, легкого и безопасного доступа к Plarin.';

const SocialBlock = observer(() => {
  const {
    profileStore: { data, addSocial, removeSocial },
  } = useAppDataStore();

  return (
    <>
      <ProfileItem
        icon={<VkIcon />}
        data={data.vkontakte}
        title={Social.vk}
        actionAdd={addSocial}
        actionRemove={removeSocial}
        userName={`${data.fname} ${data.lname}`}
      />
    </>
  );
});

export const ProfileSocial = () => {
  return (
    <ProfileBlockWrapper title={profilePasswordTitle} subTitle={typografText(profilePasswordSubTitle)}>
      <SocialBlock />
    </ProfileBlockWrapper>
  );
};
