import { Typography, InviteCardIcon } from '@plarin/design';
import { Button } from '@plarin/inputs';
import clsx from 'clsx';
import React, { useState } from 'react';
import { InviteData } from '../../../../../types/profile/types';
import classes from './styles.module.scss';

type TInvitesProps = {
  isStartPage: boolean;
  invites: InviteData[];
  userFname: string | null | undefined;
  margins?: string; // передаём сюда стили, задающие верхний и нижний отступы для блока .invites
  declineInvite: (hash: string, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
  acceptInvite: (hash: string, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
  hideInvite: (hash: string, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
};

type TCardProps = {
  invite: InviteData;
  isStartPage: boolean;
  userFname: string | null | undefined;
  declineInvite: (hash: string, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
  acceptInvite: (hash: string, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
  hideInvite: (hash: string, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
};

const InviteCard = ({ invite, isStartPage, userFname, declineInvite, hideInvite, acceptInvite }: TCardProps) => {
  const [awaitAccept, setAwaitAccept] = useState(false);
  const [awaitDecline, setAwaitDecline] = useState(false);

  const Hello = () => (
    <Typography component="p" size="IntegrationChannelMessage">
      Здравствуйте{userFname ? `, ${userFname}` : ''}!
    </Typography>
  );

  const InviteInfo = () => (
    <Typography component="p" size="IntegrationChannelMessage">
      <Typography component="span" size="IntegrationChannelMessage" weight={700}>
        {invite.invited_by || 'Неизвестный пользователь'}
      </Typography>{' '}
      {invite.recall ? 'отозвал приглашение' : 'пригласил вас'} в пространство{' '}
      <Typography component="span" size="IntegrationChannelMessage" weight={700}>
        {invite.company}
      </Typography>
    </Typography>
  );

  const InviteActions = () => (
    <>
      <Button
        size="medium"
        variant="filled"
        color="primary"
        loading={awaitAccept}
        onClick={() => acceptInvite(invite.hash, setAwaitAccept)}
        disabled={awaitDecline}
      >
        Принять
      </Button>
      <Button
        size="medium"
        variant="outlined"
        color="primary"
        loading={awaitDecline}
        onClick={() => declineInvite(invite.hash, setAwaitDecline)}
        disabled={awaitAccept}
      >
        Отклонить
      </Button>
    </>
  );

  const HideInvite = () => (
    <Button
      size="medium"
      variant="outlined"
      color="primary"
      loading={awaitAccept}
      onClick={() => hideInvite(invite.hash, setAwaitAccept)}
    >
      Закрыть
    </Button>
  );

  return (
    <div className={clsx(classes.card, isStartPage && classes.widthStartPage)}>
      <div className={classes.infoBlock}>
        <InviteCardIcon />
        <div className={classes.text}>
          <Hello />
          <InviteInfo />
        </div>
      </div>

      <div className={classes.acionBlock}>{invite.recall ? <HideInvite /> : <InviteActions />}</div>
    </div>
  );
};

export const Invites = ({
  isStartPage,
  invites,
  userFname,
  declineInvite,
  hideInvite,
  acceptInvite,
  margins,
}: TInvitesProps) => {
  return (
    <div className={clsx(classes.invitesWrap, margins)}>
      {invites.map(data => (
        <InviteCard
          userFname={userFname}
          invite={data}
          isStartPage={isStartPage}
          key={Date.now() + Math.random()}
          declineInvite={declineInvite}
          acceptInvite={acceptInvite}
          hideInvite={hideInvite}
        />
      ))}
    </div>
  );
};
