import { Avatar } from '@plarin/design';
import React from 'react';
import { LinesMember } from './components';
import classes from './style.module.scss';
import { MemberRenderProps } from './type';

export const MemberRender = ({ data, MemberCellMenu }: MemberRenderProps) => {
  const { fname, lname, status, email, created } = data;

  return (
    <div className={classes.container}>
      <div>
        <Avatar
          fname={fname}
          lname={lname}
          email={status ? email : undefined}
          colorAvatar={data.avatar_color}
          iconType={status === 'blocked' ? 'lock' : undefined}
        />
      </div>

      <LinesMember fname={fname} lname={lname} email={email} created={created} />

      <MemberCellMenu data={data} />
    </div>
  );
};
