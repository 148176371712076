export enum ManageVkTabNameEnum {
  'ACCOUNTS' = 'client_ids',
  'ADPLANS' = 'ad_plan_ids',
  'ADGROUPS' = 'campaign_ids',
  'ADS' = 'ad_ids',
}

export enum ManageYDTabNameEnum {
  'ACCOUNTS' = 'accounts_ids',
  'CAMPAIGNS' = 'campaigns_ids',
  'GROUPS' = 'groups_ids',
  'ADS' = 'ads_ids',
}

export enum IntegrationsTabNameEnum {
  'ACCOUNTS' = 'accounts',
  'CABINETS' = 'cabinets',
}

export const objTypes = {
  client_ids: 'account',
  ad_plan_ids: 'ad_plan',
  campaign_ids: 'campaign',
  ad_ids: 'banner',
} as const;

export const manageVkTableMainNames = {
  client_ids: 'clientAccount',
  ad_plan_ids: 'adPlanName',
  campaign_ids: 'campaignName',
  ad_ids: 'adPlanName',
};

export const TabNameToReq = {
  account: 'account_ids',
  ad_plan: 'ad_plan_ids',
  campaign: 'ad_group_ids',
  banner: 'ad_ids',
};

export const TabNameToIds = {
  account: 'accountId',
  ad_plan: 'adPlanId',
  campaign: 'campaignId',
  banner: 'adId',
};

export const RespToTabId = {
  account: 'account_id',
  ad_plan: 'ad_plan_id',
  campaign: 'campaign_id',
  banner: 'ad_id',
};
