import { Typography } from '@plarin/design';
import { getNumeral, int, getSelectedWord } from '@plarin/utils';
import { ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import React from 'react';
import classes from './style.module.scss';

type TTextVariants = [string, string, string, 'f' | 'm' | 'n'];

type TText = [string, string, string, 'f' | 'm' | 'n'];

interface NameCountRendererProps extends ICellRendererParams {
  text?: TText;
  hasGroups?: boolean;
  showSelectedAmount?: boolean;
  countUniqueCampaignId?: boolean;
  multipleConnectionsToCabinet?: boolean;
}

const getUniqueItems = (allKeys: String[]): String[] => {
  let totalUniqueKeys: String[] = [];
  allKeys.forEach(key => {
    if (!totalUniqueKeys.includes(key)) {
      totalUniqueKeys.push(key);
    }
  });
  return totalUniqueKeys;
};

const showSelected = (selectedRows: any[], text: TTextVariants) => (
  <Typography color="TextTertiary" weight={400}>
    {selectedRows.length ? ` (${getSelectedWord(selectedRows.length, text[3])} ${int(selectedRows.length)})` : null}
  </Typography>
);

// Сколько уникальных по ID строк в таблице: ('10 000 рекламных кампаний', '5 рекламных кабинетов')
const showTotal = (totalUniqueCount: number, text: TText) =>
  int(totalUniqueCount) + ' ' + getNumeral(totalUniqueCount, text);

export const NameCountRenderer = ({
  text,
  hasGroups,
  api,
  showSelectedAmount,
  countUniqueCampaignId,
  multipleConnectionsToCabinet,
}: NameCountRendererProps) => {
  let totalUniqueCount = 0;
  const totalItems: String[] = [];

  // ********** Подсчёт общего количества элементов таблицы
  api.forEachNodeAfterFilter(node => {
    // если находимся на manage-vk/ads то хотим, подсчитать не количество строк в табилце, а количество уникальных кампаний в таблице
    if (countUniqueCampaignId && !multipleConnectionsToCabinet) {
      return node.data.campaignId && totalItems.push(node.data.campaignId);
    }

    if (multipleConnectionsToCabinet) {
      return node.key && totalItems.push(node.key);
    }

    return (totalUniqueCount += 1);
  });

  if (countUniqueCampaignId && totalItems.length && !multipleConnectionsToCabinet) {
    // 1 сценарий: по завершении .forEachNodeAfterFilter подсчитает сколько же в итоге выбрано строк с уникальными id
    totalUniqueCount = getUniqueItems(totalItems).length;
  } else if (multipleConnectionsToCabinet) {
    // 2 сценарий: по завершении .forEachNodeAfterFilter подсчитает, сколько же в итоге выбрано строк с уникальным key
    totalUniqueCount = getUniqueItems(totalItems).length;
  }

  // ********** Подсчёт количества выбранных элементов таблицы

  let selectedRows = api.getSelectedRows();

  if (multipleConnectionsToCabinet) {
    let allSelectedKeys: String[] = selectedRows.map(data => data.key);
    selectedRows = getUniqueItems(allSelectedKeys);
  }

  return (
    <div className={clsx(classes.text, hasGroups && classes.padding)}>
      {/* Заголовок ячейки футера: сколько суммарно уникальных элементов в столбце: ('10 000 рекламных кампаний', '5 рекламных кабинетов') */}
      {text ? showTotal(totalUniqueCount, text) : ''}

      {/* Дополнительная информация: сколько строк выбрал пользователь ('выбрана 1')*/}
      {showSelectedAmount && text && showSelected(selectedRows, text)}
    </div>
  );
};
