import { TypeInput, useInput } from '@plarin/utils';
import React, { useEffect } from 'react';
import { Input } from '../inputs';
import { TSetModalAction, ModalActionNamesEnum } from '../modal';
import { TypeChangeEmail } from './types';

type TSetEmail = {
  email: string | null | undefined;
  public_id: string;
  setModalAction: TSetModalAction;
  changeEmail: (data: TypeChangeEmail, id: string) => Promise<void>;
  isLoading: boolean | undefined;
  closeModal: () => boolean;
};

export const SetEmailContent = ({
  email,
  public_id,
  setModalAction,
  changeEmail,
  isLoading,
  closeModal,
}: TSetEmail) => {
  const input = useInput({
    initialValue: email || '',
    type: TypeInput.email,
    isRequired: true,
  });
  // eslint-disable-next-line
  const { setError: setEmailError, setTextError: setTextEmailError, setHint, setWasChanged, checkValue, ...emailInput } = input;

  const setAction = () => {
    setModalAction({
      action: () => {
        if (!input.value || input.error) {
          !input.value && setEmailError(true);
        } else if (email && input.value === email) {
          closeModal();
        } else {
          email && changeEmail({ email: input.value }, public_id);
        }
      },
      actionTitle: isLoading ? ModalActionNamesEnum.Change : ModalActionNamesEnum.Save,
    });
  };

  useEffect(() => {
    checkValue();
    setAction();
  });

  return (
    <>
      <Input
        {...emailInput}
        name="email"
        type="email"
        label="Электронная почта"
        fullWidth
        autoFocus
        autoComplete="off"
      />
    </>
  );
};
