import { Skeleton as MUISkeleton } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import classes from './skeleton-table-row.module.scss';

const rowItems = [1, 2, 3, 4, 5];

export interface ISkeleton {
  size: 'maxLong' | 'long' | 'middle' | 'short';
  height?: number;
}

export const Skeleton = ({ size, height = 12 }: ISkeleton) => {
  return (
    <MUISkeleton
      variant="text"
      classes={{ text: classes.skeletonText, root: classes.skeletonColor }}
      height={height}
      width={(size === 'maxLong' && '100%') || (size === 'long' && '91.75%') || (size === 'middle' && '75%') || '46.4%'}
    />
  );
};

export const SkeletonList = ({ sumList = 1, size, height = 12, ...res }: ISkeleton & { sumList?: number }) => {
  const arrayItems = Array(sumList).fill('1');
  return (
    <>
      {arrayItems.map((el, index) => (
        <div key={index} className={classes.skeletonBlockItem}>
          <Skeleton size={size} height={height} {...res} />
        </div>
      ))}
    </>
  );
};

export const SkeletonTableBlock = ({
  isReversLong,
  isRightContent,
  withCheckbox,
  isColor,
  height,
  width,
}: {
  isRightContent?: boolean;
  isReversLong?: boolean;
  withCheckbox?: boolean;
  isColor?: boolean;
  height?: number;
  width?: number;
}) => {
  return (
    <div
      style={{ width: `${width}%` }}
      className={clsx(classes.skeletonBlock, isColor && classes.skeletonBlockPrimary)}
    >
      {withCheckbox ? (
        <div className={classes.rightBlock}>
          <div className={clsx(classes.wrapper, isRightContent && classes.rightContent)}>
            <Skeleton size={isReversLong ? 'long' : 'short'} height={height} />
            <Skeleton size={!isReversLong ? 'long' : 'short'} height={height} />
          </div>
        </div>
      ) : (
        <div className={classes.aloneRow}>
          <Skeleton height={height} size="long" />
        </div>
      )}
    </div>
  );
};

export const SkeletonTableRow = ({ isHeader, isColor }: { isHeader?: boolean; isColor?: boolean }) => {
  return (
    <div className={clsx(classes.skeletonRow, isHeader && classes.skeletonRowHeader)}>
      {rowItems.map(item => {
        if (item === 1) return <SkeletonTableBlock withCheckbox={true} key={item} isColor={isColor} />;
        return <SkeletonTableBlock key={item} isColor={isColor} />;
      })}
    </div>
  );
};
