import { Typography } from '@plarin/design';
import React from 'react';
import { AlertIntegrations } from './alert-integrations';

type TAlertIntegrationSuccess = {
  closeAlert(): void;
};

export const AlertIntegrationSuccess: React.FC<TAlertIntegrationSuccess> = ({ closeAlert, ...props }) => {
  return (
    <AlertIntegrations severity="success" {...props} closeAlert={closeAlert}>
      <Typography>Аккаунт успешно подключен</Typography>
    </AlertIntegrations>
  );
};
