// для обработки данных имени и фамилии, чтобы не выводилась пустота или null
export const getMemberName = ({
  fname,
  lname,
  email,
}: {
  fname?: string | null;
  lname?: string | null;
  email?: string | null;
}) => {
  if (!fname && !lname) return email || 'Без Имени';
  if (!lname && fname) return fname;
  if (!fname && lname) return lname;
  return `${fname} ${lname}`;
};
