import clsx from 'clsx';
import React from 'react';
import { Input } from '../inputs';
import { InputPasswordProps } from './input-password-props';
import { mapPasswordComplexity } from './map-password-complexity';
import './input-password.scss';

export const InputPassword: React.VFC<InputPasswordProps> = function InputPassword({
  value,
  onChange,
  label,
  errorHint,
  onFocus,
  onBlur,
  passwordComplexity,
  fullWidth,
  size,
  ...props
}) {
  const passwordHint = passwordComplexity && mapPasswordComplexity[passwordComplexity];

  return (
    <div
      className={clsx(
        'inputPassword',
        {
          inputPassword_fullWidth: fullWidth,
        },
        size === 48 && 'size48',
      )}
    >
      <Input
        {...props}
        type="password"
        value={value}
        onChange={onChange}
        label={label}
        hint={errorHint ? errorHint : passwordHint}
        error={!!errorHint}
        onFocus={onFocus}
        onBlur={onBlur}
        fullWidth={fullWidth}
        size={size}
      />
      {passwordComplexity && !errorHint && <div className={clsx('inputPasswordLine', passwordComplexity)} />}
    </div>
  );
};
