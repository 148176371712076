import { typografText } from '@plarin/utils';
import { TWsRole } from '../../../../types/profile/types';

export const canChange = (role?: TWsRole) => role === 'wsrole:admin' || role === 'wsrole:owner';

export const tooltipTexts = {
  tag_account: typografText(
    'Все рекламные кампании в рекламном кабинете, содержащим указанные метки, будут автоматически ассоциированы с этим пользователем.',
  ),
  tag_ad_plan: typografText(
    'Кампании, содержащие в названии указанные метки, будут автоматически ассоциированы с этим пользователем.',
  ),
};
