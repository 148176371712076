import { Tooltip } from '@mui/material';
import React from 'react';
import { ITooltipProps } from './tooltip-props';
import './arrow-tooltip.scss';

export const ArrowTooltip = function ArrowTooltip<TComponent extends React.ElementType>({
  children,
  content,
  component: Component,
  componentProps,
  placement = 'bottom',
}: ITooltipProps<TComponent>) {
  if (!content) return children;

  return (
    <Tooltip title={content} arrow placement={placement} classes={{ tooltip: 'tooltip', arrow: 'tooltipArrow' }}>
      <Component {...(componentProps as any)}>{children}</Component>
    </Tooltip>
  );
};
