import React from 'react';
import {
  BarChart as BarChartFromRecharts,
  XAxis,
  YAxis,
  Bar,
  LineChart as LineChartFromRecharts,
  Line,
  ResponsiveContainer,
} from 'recharts';

// const data = [...Array(31)].map((_, i) => ({
//   data: `${i}.01`,
//   spent: Math.floor(Math.random() * 4000),
//   doubleSpent: Math.floor(Math.random() * 4000),
// }));

// TODO придумать как типизировать данные плюс что за dataKey показываем
export const BarChart = ({ data }: any) => {
  return (
    <ResponsiveContainer height={152}>
      <BarChartFromRecharts data={data}>
        <XAxis dataKey="date" tickLine={false} style={{ fontSize: '10px', color: '#626F84' }} />
        <YAxis axisLine={false} tickLine={false} tickCount={4} style={{ fontSize: '10px', color: '#626F84' }} />
        {/* <Tooltip /> */}
        <Bar dataKey={data && data[0]?.statistics_spent ? 'statistics_spent' : 'statistics_installs'} fill="#46A1FF" />
      </BarChartFromRecharts>
    </ResponsiveContainer>
  );
};

export const LineChart = ({ data }: any) => {
  return (
    <ResponsiveContainer height={152}>
      <LineChartFromRecharts data={data}>
        <XAxis dataKey="date" tickLine={false} style={{ fontSize: '10px', color: '#626F84' }} />
        <YAxis axisLine={false} tickLine={false} style={{ fontSize: '10px', color: '#626F84' }} />
        <Line type="monotone" dataKey="statistics_cpc" stroke="#46A1FF" dot={false} />
        <Line type="monotone" dataKey="statistics_cpm" stroke="#A1DFA8" dot={false} />
      </LineChartFromRecharts>
    </ResponsiveContainer>
  );
};
