import { Divider } from '@mui/material';
import { Typography } from '@plarin/design';
import React from 'react';
import { IIntegrationsInfoProps } from './types';

import './account-info-block.scss';

export const IntegrationsInfoBlock: React.FC<IIntegrationsInfoProps> = ({ account }) => {
  return (
    <div className="account-info">
      <div className="account-info__header">
        <div className="header-label">
          <Typography>Аккаунт</Typography>
        </div>
        <div className="header-account-name">
          <Typography weight={700} size="IntegrationChannelMessage" color="TextPrimary">
            {account.name}
          </Typography>
        </div>
        {account.network === 'mt' && (
          <div className="header-account-description">
            <Typography>{account.description}</Typography>
          </div>
        )}
      </div>
      <div className="account-info__footer">
        <div className="footer-id">
          ID: &nbsp; <span className="footer-account-type__value"> {account.clientId}</span>
          <Divider orientation="vertical" classes={{ vertical: 'AccountVerticalDivider' }} />
        </div>
        <div className="footer-account-type">
          Тип аккаунта:&nbsp;
          <span className="footer-account-type__value"> {account.type}</span>
          <Divider orientation="vertical" classes={{ vertical: 'AccountVerticalDivider' }} />
        </div>
        <div className="footer-access">
          Права доступа: &nbsp; <span className="footer-account-type__value"> Полный доступ</span>
        </div>
      </div>
    </div>
  );
};
