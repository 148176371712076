import { TDateOption, ManageYDTabs, ListVariants } from '@plarin/inputs';
// import { ManageVkTabNameEnum } from '@plarin/utils';
import { ManageYDTabNameEnum } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { TTabsData } from '../../../types/common-types';
import { FilterState } from '../../../types/manage-vk/types';
import { TStatusYD } from '../../../types/manage-yd/types';
import { useAppDataStore } from '../../components/app-data-provider';
// import { ReportDrawer } from '../../components/drawer/report-drawer';
import { LayoutTable } from '../../components/layout-table';
// import { ListVk } from '../../components/list';
import { ListYD } from '../../components/list/list-yd';
import { routerPaths } from '../../routing/router-path';
// import { dateAdapter } from '../../utils/reports';
// import { MetricsDrawer } from './manage-vk-drawer/metrics-drawer';
import { MetricsDrawer } from '../manage-vk/manage-vk-drawer/metrics-drawer';
import { YDAdsTab, YDCampaignsTab, YDAccountsTab, YDGroupsTab } from './tabs-content';

type TStatusList = {
  value: TStatusYD;
  label: string;
  iconColor: string;
}[];

const singleStatusesVariantsYD: TStatusList = [
  {
    value: 'not_archived',
    label: 'Все, кроме архивных',
    iconColor: 'active',
  },
  {
    value: 'on',
    label: 'Запущенные',
    iconColor: 'active',
  },
  {
    value: 'suspended',
    label: 'Остановленные',
    iconColor: 'completed',
  },
  {
    value: 'draft',
    label: 'Черновик',
    iconColor: 'completed',
  },
  {
    value: 'moderation_and_rejected',
    label: 'На модерации и отклоненные',
    iconColor: 'completed',
  },
  {
    value: 'archived',
    label: 'В архиве',
    iconColor: 'blocked',
  },
  {
    value: 'all',
    label: 'Все',
    iconColor: 'completed',
  },
];

const ManageYD = observer(() => {
  const {
    manageYD: {
      drawerVisible,
      drawerAction,
      closeDrawer,
      setMetricFilterValue,
      metricsFilterValue,
      checkedTabs,
      filterValue,
      setFilterValue,
      setDateOption,
      setFilterStatuses,
      filterStatuses,
      dateOptions,
      date,
      tableLoading,
      setDeleteAction,
      deleteAction,
      // isAccountColumnActive,
      setCurrentTab,
      currentTab,
      selectedAccountsIds,
      selectedCampaignsIds,
      selectedAdGroupsIds,
      selectedAdIds,
      setSelectedIds,
      getSelectedIds,
      isGroupDestroyed,
      setIsGroupDestroyed,
    },
    // reports: { updateInitialStateDrawer },
    profileStore: { workspaceShortname },
  } = useAppDataStore();

  const [singleFilterValue, setSingleFilterValue] = useState<TStatusYD>(singleStatusesVariantsYD[0].value); // для таблицы управления рекламой Яндекс Директ мы можем выбрать только 1 статус, в отличие от таблицы с ВК рекламой.

  const tabsData = useMemo(
    (): TTabsData => ({
      tabs: [
        {
          label: 'Выберите вкладки',
          isGroup: true,
          key: 'group',
          path: '',
          content: <></>,
        },
        {
          label: 'Клиенты',
          key: ManageYDTabs.accounts,
          path: `/${workspaceShortname}${routerPaths.manageYD.ACCOUNTS}`,
          checkbox: true,
          checked: true,
          disableSelected: true,
          action: () => checkedTabs(1),
          content: <YDAccountsTab />,
          rowsCount: selectedAccountsIds.length,
          deleteAction: () => {
            setSelectedIds([], ManageYDTabNameEnum.ACCOUNTS);
            currentTab === ManageYDTabNameEnum.ACCOUNTS && deleteAction && deleteAction();
          },
        },
        {
          label: 'Кампании',
          key: ManageYDTabs.campaigns,
          path: `/${workspaceShortname}${routerPaths.manageYD.CAMPAIGNS}`,
          checkbox: true,
          disableSelected: true,
          checked: true,
          action: () => checkedTabs(2),
          content: <YDCampaignsTab />,
          rowsCount: selectedCampaignsIds.length,
          deleteAction: () => {
            setSelectedIds([], ManageYDTabNameEnum.CAMPAIGNS);
            currentTab === ManageYDTabNameEnum.CAMPAIGNS && deleteAction && deleteAction();
          },
        },
        {
          label: 'Группы объявлений',
          key: ManageYDTabs.groups,
          path: `/${workspaceShortname}${routerPaths.manageYD.GROUPS}`,
          checkbox: true,
          checked: true,
          disableSelected: true,
          content: <YDGroupsTab />,
          rowsCount: selectedAdGroupsIds.length,
          deleteAction: () => {
            setSelectedIds([], ManageYDTabNameEnum.GROUPS);
            currentTab === ManageYDTabNameEnum.GROUPS && deleteAction && deleteAction();
          },
        },
        {
          label: 'Объявления',
          key: ManageYDTabs.ads,
          path: `/${workspaceShortname}${routerPaths.manageYD.ADS}`,
          checkbox: true,
          checked: true,
          disableSelected: true,
          content: <YDAdsTab />,
          rowsCount: selectedAdIds.length,
          deleteAction: () => {
            setSelectedIds([], ManageYDTabNameEnum.ADS);
            currentTab === ManageYDTabNameEnum.ADS && deleteAction && deleteAction();
          },
        },
      ],
      settings: true,
    }),
    [
      selectedAccountsIds.length,
      selectedCampaignsIds.length,
      selectedAdGroupsIds.length,
      selectedAdIds.length,
      checkedTabs,
      setSelectedIds,
      deleteAction,
    ],
  );

  const setDate = useCallback(
    (date: TDateOption) => {
      setDateOption(date);
      /* updateInitialStateDrawer(dateAdapter(date));*/
    },
    [/* updateInitialStateDrawer,*/ setDateOption],
  );

  const setStatuses = useCallback(
    (newStatus: TStatusYD) => {
      setFilterStatuses(newStatus);
      // updateInitialStateDrawer({
      //   statuses: newStatus.length
      //     ? newStatus.map(status => {
      //         if (status === StatusEnum.blocked) return 0;
      //         if (status === StatusEnum.active) return 1;
      //         return 2;
      //       })
      //     : [],
      // });
    },
    [/* updateInitialStateDrawer,*/ setFilterStatuses],
  );

  useEffect(() => {
    setStatuses(singleFilterValue);
  }, [singleFilterValue]);

  return (
    <>
      <LayoutTable<FilterState>
        tabsData={tabsData}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        setDateFilterOptions={setDate}
        dateOption={dateOptions}
        date={date}
        tableLoading={tableLoading}
        singleStatusValue={singleFilterValue}
        setSingleStatusValue={setSingleFilterValue}
        // @ts-ignore
        singleStatusListVariants={singleStatusesVariantsYD}
        disableStatusSelection={window.location.pathname.includes(tabsData.tabs[1].path)} // на первой вкладке селект статуса будет задизеблен, т.к. статусы неприменимы для клиентов
      />
      <MetricsDrawer
        drawerVisible={drawerVisible}
        drawerAction={drawerAction}
        closeDrawer={closeDrawer}
        setMetricFilterValue={setMetricFilterValue}
        metricsFilterValue={metricsFilterValue}
      >
        <ListYD />
      </MetricsDrawer>
      {/* <ReportDrawer /> */}
    </>
  );
});

export default ManageYD;
