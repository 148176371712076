import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography } from '@plarin/design';
import React, { useState } from 'react';
import { FixedBidModes } from '../../edit-bid/fixed-edit-bid';
import { TAccountCurrency } from './utils';
import { TNewFixBids } from './utils';
import { BidLimitInput, BidTypeInput, BidValueInput, BidValueRelativeInput, BudgetLimit, LimitTypes } from './';

export const DemonstrationBetSlider = () => {
  const [currency] = useState<TAccountCurrency>('RUB');
  const [budgetDaily, setBudgetDaily] = useState<number>(3);
  const [value, setValue] = useState<string>('Test');
  const [mode, setMode] = useState(FixedBidModes.current); // 'Текущую'
  const [budgetType, setBudgetType] = useState<LimitTypes>(LimitTypes.daily);
  const [bid1, setBid1] = useState<TNewFixBids>([
    {
      item_id: '0',
      network: 'mt',
      bid: 32,
      maxBid: 1000,
      minBid: 0.01,
    },
  ]);
  const [bid2, setBid2] = useState<TNewFixBids>([
    {
      item_id: '0',
      network: 'mt',
      bid: 32,
      maxBid: 1000,
      minBid: 0.01,
    },
  ]);
  const [bid3, setBid3] = useState<TNewFixBids>([
    {
      item_id: '0',
      network: 'mt',
      bid: 32,
      maxBid: 1000,
      minBid: 0.01,
    },
  ]);
  const [bid4, setBid4] = useState<TNewFixBids>([
    {
      item_id: '0',
      network: 'mt',
      bid: 32,
      maxBid: 1000,
      minBid: 0.01,
    },
  ]);
  const [bid7, setBid7] = useState<TNewFixBids>([
    {
      item_id: '0',
      network: 'mt',
      bid: 32,
      maxBid: 1000,
      minBid: 0.01,
    },
  ]);
  const [bid10, setBid10] = useState<TNewFixBids>([
    {
      item_id: '0',
      network: 'mt',
      bid: 32,
      maxBid: 1000,
      minBid: 0.01,
    },
  ]);

  return (
    <Box>
      {/* <Paper
        sx={{
          width: 408,
          padding: '16px',
          mt: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
        elevation={0}
      >
        <Typography size="header" color="TextIntegrationsLink">
          Разный максимум значения ставки:
        </Typography>
        <br />

        <Typography size="main">Min: 0.01 -- Max: 1000</Typography>
        <BidValueInput
          minBid={0.01}
          currency={currency}
          selectedItems={bid1}
          setNewBids={setBid1}
          maxBid={1000}
          header="Новая ставка"
        />

        <Typography size="main">Min: 0.01 -- Max: 500</Typography>
        <BidValueInput minBid={0.01} currency={currency} selectedItems={bid2} setNewBids={setBid2} maxBid={500} />

        <br />
        <Typography size="header" color="TextIntegrationsLink">
          Размер точки на слайдере:
        </Typography>
        <br />

        <Typography size="main">Здесь пин на слайдере меньше, чтобы отодвинуть его от цифры в инпуте: 14px</Typography>
        <BidValueInput
          minBid={0.01}
          currency="EUR"
          selectedItems={bid3}
          setNewBids={setBid3}
          maxBid={34}
          pinSize="14"
        />

        <Typography size="main">Пин: 12px</Typography>
        <BidValueInput
          minBid={0.01}
          currency="USD"
          selectedItems={bid4}
          setNewBids={setBid4}
          maxBid={37}
          pinSize="12"
        />

        <Typography size="main">Пин: 10px</Typography>
        <BidValueInput
          minBid={0.01}
          currency="CNY"
          selectedItems={bid7}
          setNewBids={setBid7}
          maxBid={200}
          pinSize="10"
        />
        <br />
        <Typography size="header" color="TextIntegrationsLink">
          Сейчас используем слайдер с прином 10px, а не как в макете:
        </Typography>
        <br />

        <BidValueInput
          minBid={0.01}
          currency={currency}
          selectedItems={bid10}
          setNewBids={setBid10}
          maxBid={500}
          pinSize="10"
        />
      </Paper> */}
      <Paper
        sx={{
          width: 408,
          padding: '16px',
          mt: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
        elevation={0}
      >
        <Typography size="header" color="TextIntegrationsLink">
          Виды импутов
        </Typography>
        <br />

        <Typography size="main">BidValueInput</Typography>
        {/* <BidValueInput
          minBid={0.01}
          currency={currency}
          selectedItems={bid1}
          setNewBids={setBid1}
          maxBid={300}
          header="Новая ставка"
        /> */}

        <BidLimitInput
          currentValue={value}
          setCurrentValue={setValue}
          hasError={false}
          header="BidLimitInput"
          alertMessage="error"
        />
        <BidTypeInput
          mode={mode}
          setMode={setMode}
          inputsModeVariants={testArr}
          header="BidTypeInput"
          tooltipMessage="test"
        />

        <BidValueRelativeInput
          previousBids={TNewBid}
          setNewBids={setBid1}
          currencySymbol=""
          maxBid={300}
          minBid={1}
          openMinMax={() => console.log('')}
        />
        {/* <BudgetLimit
          commonDailyBudget={7}
          setCommonDailyBudget={setBudgetDaily}
          commonLifetimeBudget={budgetDaily}
          setCommonLifetimeBudget={setBudgetDaily}
          budgetType={budgetType}
          setBudgetType={setBudgetType}
          adsAmount={23}
          minBudgetLimit={150}
          currencySymbol="test"
        /> */}
      </Paper>
    </Box>
  );
};

const TNewBid = [
  {
    item_id: 'sw',
    network: 'mt',
    bid: 123,
    maxBid: 300,
    minBid: 1,
  },
  {
    item_id: 'w',
    network: 'mt',
    bid: 23,
    maxBid: 400,
    minBid: 1,
  },
];

const testArr = [
  {
    label: 'value1',
    value: '1',
  },
  {
    label: 'value2',
    value: '2',
  },
];
