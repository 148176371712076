import React from 'react';
import classes from './style.module.scss';

export const ActionBar: React.FC = ({ children, ...props }) => {
  return (
    <div {...props} className={classes.actionBar}>
      {children}
    </div>
  );
};
