import { Typography } from '@plarin/design';
import { CustomSelect, Input, ListVariants } from '@plarin/inputs';
import { getMemberName, InputNameErrors } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { WorkspaceDrawerItemList } from '../../../../components/workspace-drawer-component/workspace-drawer-item-list';
import classes from './teams-drawer.module.scss';

export const TeamsDrawerName = observer(() => {
  const {
    teams: { dataDrawer, updateDataDrawer, isErrorName },
    profileStore: { currentWorkspace },
  } = useAppDataStore();

  const memberList = useMemo(() => {
    return currentWorkspace.members.reduce((acc: ListVariants[], e, i) => {
      if (i === 0) {
        acc.push({ value: 'not', label: 'Без лидера', iconType: 'none' });
      }
      if (e.role !== 'wsrole:guest') {
        acc.push({
          value: e._id,
          label: getMemberName({ fname: e?.fname, lname: e?.lname, email: e?.email }),
          iconColor: e.avatar_color,
        });
      }
      return acc;
    }, []);
  }, [currentWorkspace]);

  if (dataDrawer && dataDrawer.canOnlyView) {
    return (
      <div className={classes.isViewTitle}>
        <Typography weight={600} size="Main">
          Лидер команды
        </Typography>
        {currentWorkspace.members.map(e => {
          if (e._id === dataDrawer.leader_id) {
            return (
              <WorkspaceDrawerItemList
                name={`${e.fname} ${e.lname}`}
                avatarColor={e.avatar_color}
                hasAdminRights={false}
              />
            );
          }
        })}
      </div>
    );
  }

  return (
    dataDrawer && (
      <div className={classes.nameContent}>
        <Typography weight={600} size="Main">
          О команде
        </Typography>
        <div className={classes.name}>
          <Input
            type="text"
            hint={isErrorName ? InputNameErrors.bigName255 : ''}
            error={isErrorName}
            inputRootWrapperDefault
            deactivatedMessage={dataDrawer.name}
            value={dataDrawer.name}
            size={48}
            maxLength={42}
            maxCounterValue={42}
            onChange={newValue => {
              updateDataDrawer('name', newValue);
            }}
            onBlur={() => {
              updateDataDrawer('name', dataDrawer.name.trim());
            }}
            label="Название команды"
          />
        </div>
        <CustomSelect
          value={dataDrawer.leader_id ? dataDrawer.leader_id : 'not'}
          listVariants={memberList}
          label="Лидер команды"
          menuHeight={248}
          avatarView
          iconType="circle"
          onChange={value => {
            updateDataDrawer('leader_id', value);
          }}
        />
      </div>
    )
  );
});
