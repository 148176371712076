import { Drawer } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React from 'react';
import { NetworkEnum } from '../../dictionary/integrations';
import { DrawerStep } from '../../enums';
import { useAppDataStore } from '../app-data-provider';
import { DrawerContentConnectResult, DrawerContentConnectStart, DrawerSelectConnect } from './components';
import { DrawerTitleForConnect, DrawerTitleWithNetwork } from './components/drawer-header';
import { DrawerHelperLinks } from './components/drawer-helper-links';

type DrawerContentPops = {
  step?: DrawerStep;
  network?: NetworkEnum;
  action: (network: NetworkEnum) => void;
};

type DrawerTitleProps = Omit<DrawerContentPops, 'action'>;

const drawerTitleRender = ({ step, network }: DrawerTitleProps) => {
  if (step) {
    return <DrawerTitleWithNetwork network={network} />;
  }
  return <DrawerTitleForConnect />;
};

const drawerContentRender = ({ step, action }: DrawerContentPops) => {
  switch (step) {
    case DrawerStep.ConnectStart:
      return <DrawerContentConnectStart />;
    case DrawerStep.ConnectResult:
      return <DrawerContentConnectResult />;
    case DrawerStep.SelectNetwork:
    default:
      return <DrawerSelectConnect action={action} />;
  }
};

const DrawerContent = () => {
  const {
    integrationsVK: {
      network,
      setStepInDrawer,
      setNetwork,
      stepInDrawer,
      isDrawerVisible,
      closeDrawer,
      isDrawerClosable,
      someAction,
    },
  } = useAppDataStore();

  const action = (newNetwork: DrawerContentPops['network']) => {
    setStepInDrawer(DrawerStep.ConnectStart);
    setNetwork(newNetwork);
  };

  return (
    <Drawer
      widthDrawer="large"
      isOpen={isDrawerVisible}
      closeDrawer={closeDrawer}
      title={drawerTitleRender({ step: stepInDrawer, network })}
      action={someAction}
      actionDisabled={!isDrawerClosable}
    >
      {/* <div style={{ width: '744px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}> */}
      {drawerContentRender({ step: stepInDrawer, action })}
      {/* </div> */}
    </Drawer>
  );
};

export default observer(DrawerContent);
