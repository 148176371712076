import { ArrowUpIcon, Typography } from '@plarin/design';
import { Button, Popover } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { TGuests } from '../../../../../../../types/projects/projects';
import { MEMBERS } from '../../../../../../routing/router-path';
import { menuSectionIndexes } from '../../../../../../utils/constants';
import { filtersGuests, getSearchResults } from '../../../../../../utils/searchList';
import { useAppDataStore } from '../../../../../app-data-provider';
import classes from '../project-info.module.scss';
import { CustomButtonText } from '../uicomponents/custom-button-text';
import { ProjectPopupGuests } from './components/project-popup-guests';
import { ProjectGuestFormList } from './project-guest-form-list';

export const ProjectInfoGuests = observer(() => {
  const anchorRef = useRef(null);
  const history = useHistory();

  const {
    projects: {
      guestsState,
      setGuestsState,
      editProject,
      statusWSroles,
      setIsOpenDrawer,
      setInitialGuestsState,
      setIsDrawerDataChanged,
    },
    menu: { selectMenuItem },
  } = useAppDataStore();

  const redirectToMembers = () => {
    setIsOpenDrawer(false);
    history.push(MEMBERS);
    selectMenuItem(MEMBERS, false, menuSectionIndexes.ws);
  };

  const handleClosePopover = () => {
    setGuestsState('isOpenModalGuests', false);
    setGuestsState('searchValueGuests', '');
  };

  useEffect(() => {
    editProject.editProjectData &&
      setGuestsState(
        'selectedGuests',
        guestsState.guests.filter(el => {
          return editProject.editProjectData?.guest_ids?.includes(el.id);
        }),
      );
    setIsDrawerDataChanged(false);
    setInitialGuestsState();
  }, [editProject]);

  useLayoutEffect(() => {
    guestsState.guests &&
      getSearchResults(
        guestsState.searchValueGuests,
        filtersGuests,
        (el: TGuests[]) => {
          setGuestsState('filteredGuests', el);
        },
        guestsState.guests,
      );
  }, [guestsState.guests, guestsState.searchValueGuests]);

  useEffect(() => {
    setGuestsState('filteredGuests', guestsState.guests);
  }, []);

  const { isAdmin, isManager, isLeader } = statusWSroles;
  const isHidden = isLeader || isManager || isAdmin;

  if (guestsState.guests?.filter(el => el.status !== 'blocked').length === 0 && !guestsState.selectedGuests.length) {
    return (
      <div className={clsx(classes.infoBlock)}>
        <span className={classes.infoTitleBox}>
          <Typography color="TextPrimary" weight={600} size="Main" align="left">
            Гости
          </Typography>
        </span>
        <span className={classes.infoTextBox}>
          <Typography color="TextTertiary" weight={400} size="AuthContent" align="left">
            У вас еще не приглашен ни один гость в пространстве
          </Typography>
        </span>
        {isAdmin && (
          <Button className={classes.buttonAddForm} onClick={redirectToMembers}>
            <div className={classes.addItem}>
              <Typography size="AuthContent" weight={600} color="Primary60">
                Перейти к настройкам пользователей
              </Typography>
              <ArrowUpIcon className={classes.arrowIcon} />
            </div>
          </Button>
        )}
      </div>
    );
  }

  return (
    <>
      <div className={clsx(classes.infoBlock, guestsState.selectedGuests.length && classes.infoBlockTextNone)}>
        <span className={clsx(classes.infoTitleBox, guestsState.selectedGuests.length && classes.buttonRight)}>
          <Typography color="TextPrimary" weight={600} size="Main" align="left">
            Гости
          </Typography>
          {isHidden && (
            <div ref={anchorRef}>
              <CustomButtonText
                onClickButton={() => setGuestsState('isOpenModalGuests', true)}
                isOpen={guestsState.isOpenModalGuests}
              >
                Добавить гостя
              </CustomButtonText>
            </div>
          )}
        </span>
        {!guestsState.selectedGuests.length && (
          <span className={classes.infoTextBox}>
            <Typography color="TextTertiary" weight={400} size="AuthContent" align="left">
              В проект не добавлен еще ни один гость
            </Typography>
          </span>
        )}
      </div>
      {!!guestsState.selectedGuests.length && (
        <div className={classes.boxScrollProject}>
          {guestsState.selectedGuests.map(el => {
            return (
              <ProjectGuestFormList
                isArchived={!editProject.editProjectData?.is_archived && isHidden}
                key={el.id}
                guest={el}
                setSelectedGuests={e => setGuestsState('selectedGuests', e)}
                selectedGuests={guestsState.selectedGuests}
              />
            );
          })}
        </div>
      )}

      <Popover
        isListLeft
        setIsOpen={handleClosePopover}
        onEscape={handleClosePopover}
        anchorEl={anchorRef.current}
        isOpen={guestsState.isOpenModalGuests}
      >
        <ProjectPopupGuests
          selectedGuests={guestsState.selectedGuests}
          setSelectedGuests={e => setGuestsState('selectedGuests', e)}
        />
      </Popover>
    </>
  );
});
