import { Avatar, Typography } from '@plarin/design';
import { switchFilter, getMemberName } from '@plarin/utils';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { HightLightText } from '../../../hightlight-text';
import { EllipsisTooltip } from '../../../tooltip';
import classes from './style.module.scss';

interface Props extends ICellRendererParams {
  wsMembers: any; // TWorkspaceMembers
  data: any; // TGetTeamByIdResponse
}

export const SimpleCircleAvatarNameRenderer = (props: ICellRendererParams) => {
  const name = getMemberName({ fname: props.value?.fname, lname: props.value?.lname, email: props.value?.email });
  return (
    <div className={classes.wrap}>
      <Avatar
        fname={props.value?.fname || ''}
        lname={props.value?.lname || ''}
        colorAvatar={props.value?.avatar_color || ''}
      />

      <EllipsisTooltip tooltipMessage={name}>
        <Typography size="Caption" ellips>
          {name}
        </Typography>
      </EllipsisTooltip>
    </div>
  );
};

export const CircleAvatarNameRenderer = ({ node, wsMembers, data, api }: Props) => {
  // @ts-ignore
  const filterValue = api.filterManager.quickFilter || '';
  // @ts-ignore
  const leader = wsMembers.find(member => member._id === data.leader_id || member._id === data.manager_id);
  const name = getMemberName({ fname: leader?.fname, lname: leader?.lname, email: leader?.email });
  return (
    <div className={classes.wrap}>
      {leader && (
        <Avatar
          name={name}
          colorAvatar={node.data.status === 'inactive' || node.data.is_archived ? 'C4CAD4' : leader.avatar_color}
        />
      )}

      <div className={classes.name}>
        {leader ? (
          <EllipsisTooltip tooltipMessage={name}>
            <HightLightText size="Caption" weight={400} textEllips={true} text={name} filterValue={filterValue} />
          </EllipsisTooltip>
        ) : (
          <Typography color="TextSecondary" weight={400} componentProps={{ className: classes.secondaryName }}>
            —
          </Typography>
        )}
      </div>
    </div>
  );
};
