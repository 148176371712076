import { IdeaIcon, Typography } from '@plarin/design';
import { Drawer, Lockscreen, MultipleFileUploader, TextArea, TextAreaErrors, TitleOptions } from '@plarin/inputs';
import {
  NotificationErrorTitle,
  NotificationMessage,
  NotificationTitle,
  useStateValidator,
  ValidatorLength255k,
} from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ProposeIdea } from '../../../types/propose-idea/types';
import { useAppDataStore } from '../../components/app-data-provider';
import { DrawerId } from '../../enums';
import { STATUS } from '../../utils/constants';
import { ContactAllowedBlock, FooterIdea, ImportanceBlock } from './components';
import classes from './components/style.module.scss';

export const SuggestIdeaDrawer = observer(() => {
  const {
    profileStore: { proposeIdea, isAuthorized },
    menu: { setCloseDrawer, menuBottomItems },
    addNotification,
  } = useAppDataStore();

  const [initStateIdea, setInitStateIdea] = useState<ProposeIdea>({
    text: '',
    contact_allowed: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [[isValidLength]] = useStateValidator(initStateIdea.text, ValidatorLength255k);
  const [errorTextArea, setErrorTextArea] = useState<string>('');

  useEffect(() => {
    isValidLength && initStateIdea.text && setErrorTextArea('');
  }, [initStateIdea.text]);

  const updateData = (value: any) => {
    setInitStateIdea((prev: any) => {
      return { ...prev, ...value };
    });
  };

  const sendProposeIdea = () => {
    if (!initStateIdea.text.trim()) {
      setErrorTextArea(TextAreaErrors.noText);
    } else {
      setIsLoading(true);
      setTimeout(() => {
        proposeIdea(initStateIdea)
          .then(() => {
            setCloseDrawer(DrawerId.proposeIdea);
            addNotification({
              type: STATUS.SUCCESS,
              title: NotificationTitle.PROPOSE_IDEA,
              message: NotificationMessage.PROPOSE_IDEA,
            });
            updateData({ text: '' });
          })
          .catch(e => {
            addNotification({
              type: STATUS.ERROR,
              title: NotificationErrorTitle.ERROR,
              message: NotificationMessage.ERROR_FORM,
            });
          })
          .finally(() => setIsLoading(false));
      }, 300);
    }
  };

  useEffect(() => {
    setIsDisabled(false);
    initStateIdea.files?.forEach(el => {
      el.errors?.length ? setIsDisabled(true) : setIsDisabled(false);
    });
  }, [initStateIdea]);

  useEffect(() => {
    !isAuthorized && setCloseDrawer(DrawerId.proposeIdea);
  }, [isAuthorized]);

  return (
    <Drawer
      isOpen={menuBottomItems.find(el => el.id === DrawerId.proposeIdea)?.isOpenDrawer}
      closeDrawer={() => setCloseDrawer(DrawerId.proposeIdea)}
      title={
        <TitleOptions
          titleName={
            <Typography weight={600} size="DrawerProject">
              Предложить идею
            </Typography>
          }
          circle
          iconBlock={<IdeaIcon />}
        />
      }
      actionBlock={
        <FooterIdea
          isDisabled={isDisabled}
          isLoading={isLoading}
          sendProposeIdea={sendProposeIdea}
          setCloseDrawer={setCloseDrawer}
        />
      }
    >
      <div className={classes.contentWrapper}>
        <Lockscreen visible={isLoading} />
        <div>
          <TextArea
            className={classes.textArea}
            value={initStateIdea.text}
            setValue={text => updateData({ text: text })}
            size={48}
            maxLengthValue={5120}
            maxCounterValue={5120}
            errorText={(!isValidLength && TextAreaErrors.maxLength) || errorTextArea}
            placeholder="Опишите, какого функционала не хватает и как вы бы его использовали"
            maxRows={16}
            disabled={isLoading}
            onBlurAction={() => updateData({ text: initStateIdea.text.trim() })}
          />
          <MultipleFileUploader updateData={updateData} maxFiles={5} className={classes.uploader} />
          <ImportanceBlock updateData={updateData} />
          <ContactAllowedBlock updateData={updateData} setIsError={setIsDisabled} />
        </div>
      </div>
    </Drawer>
  );
});
