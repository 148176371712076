import { CircularProgress } from '@mui/material';
import { EyeOpenedIcon, Typography } from '@plarin/design';
import { Button } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDataStore } from '../../../../app-data-provider';
import classes from './styles.module.scss';

const ReportsFooterComponent = () => {
  const {
    reports: {
      closeDrawer,
      createReport,
      createDownloadReport,
      downloadExcelReport,
      initialStateDrawer,
      updateReport,
      updateDownloadReport,
      updateInitialStateDrawer,
    },
  } = useAppDataStore();

  const [isLoadingEdit, setLoadingEdit] = useState(false);
  const [isLoadingEditDownload, setLoadingEditDownload] = useState(false);
  const [isLoadingPreview, setLoadingPreview] = useState(false);

  const disabledButton =
    !initialStateDrawer.reportClients.length ||
    // @ts-ignore
    initialStateDrawer.filters?.some(el => el.error);

  const checkEmptyReportName = () => {
    initialStateDrawer.name.trim() === '' && updateInitialStateDrawer({ name: initialStateDrawer.defaultName });
  };

  const onCreateReport = useCallback(() => {
    setLoadingEdit(true);
    createReport(initialStateDrawer);
  }, [initialStateDrawer, createReport]);

  const onCreateDownloadReport = useCallback(() => {
    setLoadingEditDownload(true);
    createDownloadReport(initialStateDrawer);
  }, [initialStateDrawer, createDownloadReport]);

  const onUpdateDownloadReport = useCallback(() => {
    setLoadingEditDownload(true);
    updateDownloadReport(initialStateDrawer);
  }, [initialStateDrawer, updateDownloadReport]);

  const onUpdateReport = useCallback(() => {
    setLoadingEdit(true);
    updateReport(initialStateDrawer);
  }, [initialStateDrawer, updateReport]);

  const onPreviewReport = useCallback(() => {
    setLoadingPreview(true);
    downloadExcelReport(initialStateDrawer, setLoadingPreview).then();
  }, [initialStateDrawer, downloadExcelReport]);

  useEffect(() => {
    if (isLoadingEdit || isLoadingEditDownload || isLoadingPreview) {
      checkEmptyReportName();
    }
  }, [isLoadingEdit, isLoadingEditDownload, isLoadingPreview, checkEmptyReportName]);

  return (
    <div className={classes.footer}>
      <div className={classes.footerButtonsCreate}>
        <Button
          disabled={disabledButton || isLoadingEdit || isLoadingPreview}
          className={classes.buttonCreateDownload}
          loading={isLoadingEditDownload}
          variant="filled"
          color="primary"
          size="small"
          onClick={
            !isLoadingEditDownload
              ? initialStateDrawer.isEdit
                ? onUpdateDownloadReport
                : onCreateDownloadReport
              : undefined
          }
        >
          {initialStateDrawer.isEdit ? 'Сохранить и скачать' : 'Создать и скачать'}
        </Button>
        <Button
          disabled={disabledButton || isLoadingEditDownload || isLoadingPreview}
          loading={isLoadingEdit}
          variant="outlined"
          color="primary"
          size="small"
          onClick={!isLoadingEdit ? (initialStateDrawer.isEdit ? onUpdateReport : onCreateReport) : undefined}
        >
          {initialStateDrawer.isEdit ? 'Сохранить' : 'Создать'}
        </Button>
        <Button
          disabled={isLoadingEdit || isLoadingEdit || isLoadingPreview || isLoadingEditDownload}
          variant="outlined"
          color="primary"
          size="small"
          onClick={closeDrawer}
        >
          Отменить
        </Button>
      </div>
      <Button
        disabled={disabledButton || isLoadingEdit || isLoadingEditDownload}
        variant="outlined"
        size="small"
        className={classes.buttonIcon}
        onClick={isLoadingPreview ? () => {} : onPreviewReport}
      >
        <div className={classes.buttonIconContent}>
          {isLoadingPreview ? (
            <CircularProgress size={12} />
          ) : (
            <EyeOpenedIcon color={disabledButton ? 'disabled' : 'primary60'} />
          )}

          <Typography color={disabledButton ? 'TextDisabled' : 'LinkPrimary'}>
            {isLoadingPreview ? 'Идет подготовка файла...' : 'Предпросмотр отчета'}
          </Typography>
        </div>
      </Button>
    </div>
  );
};

export const ReportsFooter = observer(ReportsFooterComponent);
