import { FacebookIcon, FacebookIconInactive } from '@plarin/design';
import React from 'react';
import { SocialButton } from './social-button';
import { SocialConfiguredButtonProps } from './social-configured-button-props';

const isDeactivated = true;

export const SocialFbButton: React.FC<SocialConfiguredButtonProps> = function SocialFbButton({ onClick }) {
  return (
    <SocialButton
      icon={isDeactivated ? <FacebookIconInactive /> : <FacebookIcon />}
      // onClick={onClick}
      isDeactivated={isDeactivated}
      socialName="Facebook"
    >
      Facebook
    </SocialButton>
  );
};
