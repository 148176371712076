import { isUrlProd, isUrlDev } from './constants';

export const getYmCounter = (): number | null => {
  if (isUrlProd) {
    return PROD_YM_COUNTER_ID;
  } else if (isUrlDev) {
    return DEV_YM_COUNTER_ID;
  } else {
    return null;
  }
};
