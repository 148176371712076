import { BasicStore } from '@plarin/core';
import { TabItem } from '@plarin/inputs';
import { action, makeObservable, observable } from 'mobx';
import { ForgotPasswordEmailFormStore } from './forgot-password-email-form.store';
import { ForgotPasswordPhoneFormStore } from './forgot-password-phone-form.store';

export class ForgotPasswordPageStore extends BasicStore {
  emailFormStore: ForgotPasswordEmailFormStore;
  phoneFormStore: ForgotPasswordPhoneFormStore;

  tabs: TabItem[] = [
    { value: 'email', label: 'Почта' },
    {
      value: 'phone',
      label: 'Телефон',
      disabled: true,
      tooltipMessage: 'Восстановление пароля по номеру телефона временно недоступно',
    },
  ];

  activeTab: string;

  constructor() {
    super();
    this.activeTab = this.tabs[0].value;
    this.emailFormStore = new ForgotPasswordEmailFormStore();
    this.phoneFormStore = new ForgotPasswordPhoneFormStore();
    makeObservable<this>(this, {
      emailFormStore: observable,
      activeTab: observable,
      selectTab: action.bound,
    });
  }

  selectTab(activeTab: string) {
    this.activeTab = activeTab;
  }
}
