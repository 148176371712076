import { Typography } from '@plarin/design';
import { Checkbox, Input } from '@plarin/inputs';
import { TypeInput, useInput } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDataStore } from '../../app-data-provider';
import classes from './style.module.scss';

const ContactAllowed = {
  title: 'Со мной можно связаться для уточнения деталей ',
  checked: false,
};

type ContactAllowedBlockProps = {
  updateData: ({}) => void;
  setIsError: Dispatch<SetStateAction<boolean>>;
};

export const ContactAllowedBlock = observer(({ updateData, setIsError }: ContactAllowedBlockProps) => {
  const {
    profileStore: { data },
  } = useAppDataStore();

  const [includeNoStat, setIncludeNoStat] = useState(ContactAllowed);

  const email = useInput({
    initialValue: data.email || '',
    type: TypeInput.email,
    isRequired: false,
  });
  // eslint-disable-next-line
  const { checkValue: emailCheckValue, ...emailInput } = email;

  const telegram = useInput({
    initialValue: '',
    type: TypeInput.telegram,
    isRequired: false,
  });
  // eslint-disable-next-line
  const { checkValue: telegramCheckValue, ...telegramInput } = telegram;

  useEffect(() => {
    emailCheckValue();
  }, [email.value]);

  useEffect(() => {
    telegramCheckValue();
  }, [telegram.value]);

  useEffect(() => {
    if (includeNoStat.checked) {
      (email.error || telegram.error) && setIsError(true);
      !email.error && !telegram.error && setIsError(false);
      updateData({
        contact_allowed: includeNoStat.checked,
        email: email.value,
        telegram: telegram.value,
      });
    } else {
      setIsError(false);
      updateData({ contact_allowed: includeNoStat.checked, email: undefined, telegram: undefined });
    }
  }, [telegram.value, email.value, telegram.error, email.error, includeNoStat]);

  const handleClick = () => {
    setIncludeNoStat(prev => {
      return { ...prev, checked: !prev.checked };
    });
  };

  return (
    <>
      <div onClick={handleClick} className={classes.box}>
        <Checkbox value={includeNoStat.checked} size="medium" />
        <Typography size="Main">{includeNoStat.title}</Typography>
      </div>
      {includeNoStat.checked && (
        <div>
          <Input {...emailInput} name="email" type="email" label="Электронная почта" fullWidth />
          <Input {...telegramInput} name="telegram" type="text" label="@Telegram" fullWidth autoComplete="off" />
        </div>
      )}
    </>
  );
});
