import {
  AccountMT,
  Cabinet,
  ConnectedAccount,
  MTCabinetExtended,
  TAccountsMTStoreData,
  TConnectedAccountMTStoreData,
} from '../../types/integration/types';
import { CabinetStatusEnum, MTCabinetsConnectionEnum, MTTypesEnum, NetworkEnum } from '../dictionary/integrations';
import {
  getAccountsIntegrationsStatus,
  getCabinetIntegrationStatus,
  getCabinetsSyncStatus,
} from './integration-converters';

export const mtToAccount = (item: TAccountsMTStoreData[0]): AccountMT => {
  return {
    key: item._id || '--',
    name: item.client_name || item.username || '--',
    clientId: item.username || '--',
    description: item.client_info || '--',
    type: MTTypesEnum[item.type] || '--',
    cabinetCount: 0,
    integrationStatus: getAccountsIntegrationsStatus(item.plarin_status, item.data_source_status) || '--',
    integrationStatusCode: item.data_source_status,
    integrationDate: item.last_meta_update || '--',
    client_id: item.client_id,
    syncStatusCode: item.plarin_status || '',
    network: item.is_vk_ads ? NetworkEnum.VkAds : NetworkEnum.myTarget,
  };
};

export const mtCabinetAssimilation = (mtAccounts: TAccountsMTStoreData) => (cab: MTCabinetExtended) => {
  const parentAcc = mtAccounts.find(acc => acc.client_id === cab.parent_id);
  cab.parent_type = (parentAcc || cab).type;
  cab.parentName = (parentAcc || cab).client_name || (parentAcc || cab).username || '--';
  cab.parentId = (parentAcc || cab).username || '--';
  return cab;
};

export const mtToCabinet = (item: MTCabinetExtended): Cabinet => {
  return {
    key: item._id || '--',
    network: item.is_vk_ads ? NetworkEnum.VkAds : NetworkEnum.myTarget,
    name: item.client_name || item.username || '--',
    clientId: item.username || '--',
    /** Права доступа */
    rights: '--',
    /** Тип подключения */
    connectionType: (item.parent_type && MTCabinetsConnectionEnum[item.parent_type]) || '--',
    syncStatus: getCabinetsSyncStatus(item.plarin_status, item.data_source_status) || '--',
    syncStatusCode: item.plarin_status || '',
    /** Последняя синхронизация */
    syncDate: item.last_stat_update || '--',
    integrationStatus: getCabinetIntegrationStatus(item.data_source_status) || '--',
    /** Integration. Последняя синхронизация */
    integrationDate: item.last_meta_update || '--',
    /** Integration. Статус синхронизации (code) */
    integrationStatusCode: item.data_source_status,
    /** Статус рекл. каб. */
    status: CabinetStatusEnum[item.data_source_status],
    statusCode: item.data_source_status,
    /** Баланс (валютное форматирование) */
    balance: item.balance || 0,
    /** currency */
    currency: item.currency,
    /** Общий лимит */
    // @ts-ignore // fixme
    limit: 0,
    /** Общий расход (валютное форматирование) */
    spent: item.stat?.spent || 0,
    /** Показы. (int форматирование) */
    shows: item.stat?.shows || 0,
    /** Клики (int форматирование) */
    clicks: item.stat?.clicks || 0,
    /** CTR (percent(3) форматирование)*/
    ctr: item.stat?.ctr || 0,
    parentName: item.parentName || item.parentId || '--',
    parentId: item.parentId || '--',
  };
};

export const mtToConnectedAccount = (resp: TConnectedAccountMTStoreData): ConnectedAccount => {
  return {
    ...mtToAccount(resp),
    update_after_creation: resp.update_after_creation || false,
    cabinets:
      resp.clients?.length === 0 && (resp.type === 'client' || resp.type === 'standalone')
        ? [mtToCabinet(resp)]
        : (resp.clients || []).map(mtToCabinet),
  };
};

// export const selectMtAccountsFromAll = (rows: AccountMT[]) =>
//   rows.filter(item => item.network === NetworkEnum.myTarget);

// export const selectMtCabinetsFromAll = (rows: Cabinet[]) => rows.filter(item => item.network === NetworkEnum.myTarget);
