import {
  AppError,
  BasicStore,
  CURRENT_WORKSPACE,
  EApiCodes,
  EAppErrorCodes,
  getApiUrl,
  httpClient2,
  httpClient3,
  httpClient4,
  WORKSPACE_ID,
} from '@plarin/core';
import { errorTitle, NotificationErrorTitle, NotificationTitle, getMemberName } from '@plarin/utils';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { action, autorun, makeObservable, observable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { paths } from '../../types/profile/apispec';
import type {
  ChangeEmailResp,
  ChangePasswordResp,
  InfoResp,
  ModalActionProps,
  ProfileData,
  SocialNetwork,
  TChangePassword,
  TClientInfoResp,
  TCustomerName,
  TOauthConnectionUrlReq,
  TOauthConnectionUrlResp,
  TWsDictionary,
  WorkspaceResp,
} from '../../types/profile/types';
import { TAccountWorkspaces, TWsRole, WorkspaceCreateReq, WorkspaceData } from '../../types/profile/types';
import type { ProposeIdea, ProposeIdeaReq } from '../../types/propose-idea/types';
import { TNameWorkspace } from '../../types/workspace/types';
import { appLocalStorage, appSessionStorage, history, saveAuthTokens } from '../components/app';
import { Social } from '../enums';
import { ModalContentType } from '../pages/profile/profile.types';
import { getWsRole } from '../pages/workspace-teams/components/utils';
import { routerPaths } from '../routing/router-path';
import { sortRow } from '../utils/common';
import { STATUS, STORAGE_ITEM } from '../utils/constants';
import { getAlertProps, getApiCodeAxiosError } from '../utils/get-alert-props';

export class ProfileStore extends BasicStore {
  data: ProfileData = {} as ProfileData;
  isAuthorized: boolean = false;
  modalVisible: boolean = false;
  modalAction?: ModalActionProps;
  modalTitle?: string;
  modalContentType?: ModalContentType;
  contentStep?: number;
  newEmail?: string;
  newPhone?: string;
  disabledActions?: boolean;
  disabledExit?: boolean;
  globalError?: string;
  globalStatus?: number | string;
  isLoading?: boolean = false;
  isRootRedirect?: boolean = false;
  workspaceCreate?: WorkspaceCreateReq;
  workspaces?: TAccountWorkspaces[] = [];
  workspaceShortname: string = ''; // это НЕ название воркспейса! используется как path для url и в запросах к api. Может задаваться пользователем. Не является уникальным/
  workspaceName: string = ''; // это название воркспейса, которое следует отображать в интерфейсе
  currentWorkspace: WorkspaceResp = {} as WorkspaceResp;
  WSRole?: TWsRole;
  wsDictionary: TWsDictionary = { members: {}, projects: {} };

  constructor() {
    super();
    makeObservable<this>(this, {
      isAuthorized: observable,
      data: observable,
      modalVisible: observable,
      logout: action.bound,
      loadProfile: action.bound,
      loadSuccess: action.bound,
      loadError: action.bound,
      modalContentType: observable,
      modalTitle: observable,
      modalAction: observable,
      contentStep: observable,
      newEmail: observable,
      newPhone: observable,
      globalError: observable,
      globalStatus: observable,
      isLoading: observable,
      isRootRedirect: observable,
      disabledExit: observable,
      disabledActions: observable,

      workspaceShortname: observable,
      workspaceName: observable,
      currentWorkspace: observable,
      workspaces: observable,
      WSRole: observable,

      workspaceCreate: observable,

      wsDictionary: observable,
    });
    this.initAlertUrlError();
    autorun(() => {
      if (!this.modalVisible) {
        this.globalError = '';
        this.globalStatus = '';
        this.newPasswordStyle = undefined;
      }
    });
  }

  setWorkspaceShortname = (data: string) => runInAction(() => (this.workspaceShortname = data));
  setWorkspaceName = (data: string) => runInAction(() => runInAction(() => (this.workspaceName = data)));

  resetCurrentWorkspace = () => {
    runInAction(() => (this.currentWorkspace = {} as WorkspaceResp));
    appLocalStorage.set(WORKSPACE_ID, '');
    appSessionStorage.set(CURRENT_WORKSPACE, '');
    history.push(routerPaths.auth.ROOT);
  };

  setWorkspaceCreate = (data: WorkspaceCreateReq) => runInAction(() => (this.workspaceCreate = data));
  setData = (data: ProfileData) => runInAction(() => (this.data = data));
  openModal = () => runInAction(() => (this.modalVisible = true));
  closeModal = () => (this.modalVisible = false);
  setDisabledExit = (value?: boolean) => runInAction(() => (this.disabledExit = value));
  setDisabledActions = (value?: boolean) => runInAction(() => (this.disabledActions = value));
  setModalAction = (modalAction?: ModalActionProps) => runInAction(() => (this.modalAction = modalAction));
  setModalTitle = (modalTitle?: string) => runInAction(() => (this.modalTitle = modalTitle));
  setModalContentType = (type: ModalContentType) => runInAction(() => (this.modalContentType = type));
  setContentStep = (step?: number) => runInAction(() => (this.contentStep = step));
  setNewEmail = (email?: string) => runInAction(() => (this.newEmail = email));
  setNewPhone = (phone?: string) => runInAction(() => (this.newPhone = phone));

  setGlobalError = (error?: string) => runInAction(() => (this.globalError = error));
  setGlobalStatus = (status?: number) => runInAction(() => (this.globalStatus = status));

  setIsLoading = (loading?: boolean) => runInAction(() => (this.isLoading = loading));
  setIsRootRedirect = (e?: boolean) => runInAction(() => (this.isRootRedirect = e));
  removeInviteFromData = (hash: string) =>
    runInAction(() => (this.data = { ...this.data, invites: this.data.invites?.filter(inv => inv.hash !== hash) }));

  // ========== ws ================

  setWorkspace = async (workspace: WorkspaceData) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { workspace_id, name, shortname } = workspace;

    await this.getWorkspace(workspace_id);
    runInAction(() => {
      this.workspaceName = name;
      this.workspaceShortname = shortname;
      this.WSRole = getWsRole(this.data.user_id, this.currentWorkspace);
    });
    appLocalStorage.set(WORKSPACE_ID, workspace.workspace_id);
    appSessionStorage.set(CURRENT_WORKSPACE, workspace.workspace_id);
  };

  setCurrentWS = async (wsId: string) => {
    const workspace = this.workspaces?.filter(el => el.workspace_id === wsId)[0];
    runInAction(
      () =>
        (this.workspaces = this.workspaces?.map(el =>
          el.workspace_id === workspace?.workspace_id ? { ...el, selected: true } : { ...el, selected: false },
        )),
    );
    await this.getWorkspace(workspace?.workspace_id || '');
    runInAction(() => (this.workspaceShortname = workspace?.shortname || ''));
    runInAction(() => (this.workspaceName = workspace?.name || ''));
    appLocalStorage.set(WORKSPACE_ID, workspace?.workspace_id || '');
    workspace?.workspace_id && appSessionStorage.set(CURRENT_WORKSPACE, workspace?.workspace_id);
    const wsRole: TWsRole | undefined = getWsRole(this.data.user_id, this.currentWorkspace);
    runInAction(() => (this.WSRole = wsRole));
    return workspace?.shortname || '';
  };

  editProjectName = (id: string, newName: string) => {
    this.currentWorkspace.projects.forEach(project => {
      if (id === project._id) {
        project.name = newName;
      }
    });
  };
  addProject = (data: { name: string; avatar_color: string; _id: string }) => {
    runInAction(
      () =>
        (this.wsDictionary = { ...this.wsDictionary, projects: { ...this.wsDictionary.projects, [data._id]: data } }),
    );
  };

  // ============ profile ================== //

  deleteProjectFromWsDictionary = (_id: string) => {
    const newData = new Map(Object.entries(this.wsDictionary.projects));
    newData.delete(_id);
    runInAction(() => (this.wsDictionary = { ...this.wsDictionary, projects: Object.fromEntries(newData) }));
  };

  changeProjectName = (_id: string, name: string) => {
    const newData = {
      ...this.wsDictionary,
      projects: { ...this.wsDictionary.projects, [_id]: { ...this.wsDictionary.projects[_id], name } },
    };
    runInAction(() => (this.wsDictionary = newData));
  };

  changeProfileName = async (data: TCustomerName) => {
    this.setIsLoading(true);
    setTimeout(async () => {
      await this.execRequest(httpClient4.put('/api/v1/user/profile', data))
        .then(() => {
          this.setIsLoading(false);
          this.setData({ ...this.data, lname: data.lname, fname: data.fname });
          this.addNotification({
            type: STATUS.SUCCESS,
            title: NotificationTitle.ACCOUNT_NAME_CHANGED,
          });
        })
        .catch(() => {
          this.setIsLoading(false);
          this.addNotification({
            type: STATUS.ERROR,
            title: NotificationErrorTitle.UNKNOW_ERROR,
          });
        })
        .finally(() => {
          this.setIsLoading(false);
          this.closeModal();
          this.getWorkspace(this.currentWorkspace._id);
        });
    }, 300);
  };

  logoutFromWorkspace = async () => {
    appLocalStorage.set(WORKSPACE_ID, null);
    runInAction(() => (this.workspaceShortname = ''));
    runInAction(() => (this.currentWorkspace = {} as WorkspaceResp));
    runInAction(() => (this.WSRole = undefined));
    runInAction(() => (this.workspaceName = ''));
    runInAction(() => (this.workspaces = []));
    history.push('/');
    await this.loadProfile();
  };

  logout = async () => {
    // Удаляем  email и имя пользователя из Sentry
    Sentry.configureScope(scope => scope.setUser(null));
    await this.execRequest([httpClient3.delete('/api/v1/user/auth/logout')]).finally(() => {
      runInAction(() => (this.isAuthorized = false));
      localStorage.removeItem('lastUpdate');
      appSessionStorage.set(CURRENT_WORKSPACE, null);
    });
    toast.dismiss();
  };

  getProfile = () => {
    return this.execRequest<[TClientInfoResp]>([httpClient3.get<ProfileData>('/api/v1/user/profile')]);
  };

  loadProfile = async () => {
    await this.getProfile().then(this.loadSuccess).then(this.loadWorkspaces).catch(this.loadError);
    return this.data;
  };

  loadProfileWithoutRedirect = async () => {
    await this.getProfile().then(this.loadSuccess).catch(this.loadError);
  };

  // делает то же что и loadProfile за исключением добавления в него проверки.
  // Проверяет наличие инвайтов и если они есть то удаляет из localStorage выбранный воркспейс
  loadProfileWithCheckInvites = async () => {
    try {
      const data = await this.getProfile();
      const haveInvites = !!data[0].data?.invites?.length;
      const workspaceId = appLocalStorage.get(WORKSPACE_ID);
      if (data[0].data?.workspaces?.length === 1 && !haveInvites) {
        appLocalStorage.set(WORKSPACE_ID, data[0].data?.workspaces[0]?.workspace_id);
        const workspace = data[0].data?.workspaces[0];
        this.workspaceShortname = workspace?.shortname;
        await this.getWorkspace(workspace?.workspace_id);
      }
      if (workspaceId) {
        const workspace = data[0].data?.workspaces?.filter(el => el.workspace_id === workspaceId)[0];
        this.workspaceShortname = workspace?.shortname || '';
        await this.getWorkspace(workspace?.workspace_id || '');
      }
      if (haveInvites) {
        appLocalStorage.set(WORKSPACE_ID, null);
      }
      await this.loadSuccess(data);
      haveInvites ? this.upWorkspaces() : await this.loadWorkspaces();
      return this.data;
    } catch (err) {
      this.loadError(err as AxiosError<unknown>);
    }
  };

  loadSuccess = async ([resp]: [TClientInfoResp]) => {
    this.setData(resp.data || ({} as ProfileData));

    // Добавляем email и имя пользователя в Sentry для индентификации

    this.data.fname && this.data.lname
      ? Sentry.setUser({ email: this.data.email || '', name: `${this.data.fname} ${this.data.lname}` })
      : Sentry.setUser({ email: this.data.email || '' });

    this.isAuthorized = true;
  };

  // вынесла логику для обновления воркспейсов (чтоб можно было обновить список ws без остальной логики с редиректами)
  upWorkspaces = () => {
    const pathnameParts = window.location.href.split('/');
    const wsUrlPart = decodeURIComponent(pathnameParts[3]);
    const saveLocalWs = appLocalStorage.get(WORKSPACE_ID);

    let selectedWS = '';
    if (!wsUrlPart || wsUrlPart === 'profile' || wsUrlPart === 'wslist') {
      selectedWS = saveLocalWs || '';
    } else if (saveLocalWs !== wsUrlPart && wsUrlPart !== 'profile' && wsUrlPart !== 'wslist') {
      const curentWorkspace = this.data.workspaces?.find(ws => ws.shortname === wsUrlPart);
      !curentWorkspace?.workspace_id && this.resetCurrentWorkspace();
      selectedWS = curentWorkspace?.workspace_id || '';
    }

    const workspaces = this.data.workspaces?.map(el => ({
      ...el,
      name: el.name || 'emptyName',
      shortname: el.shortname || 'emptyName',
      selected: el.workspace_id === selectedWS,
    }));
    runInAction(() => (this.workspaces = workspaces));
    return { workspaces, selectedWS };
  };

  loadWorkspaces = async () => {
    const { workspaces, selectedWS } = this.upWorkspaces();
    const pathnameParts = window.location.href.split('/');
    const wsUrlPart = decodeURIComponent(pathnameParts[3]);

    if (workspaces && !workspaces.length) {
      appLocalStorage.set(WORKSPACE_ID, null);
    }
    if (wsUrlPart === 'profile' && !selectedWS) {
      return null;
    }
    if (workspaces && workspaces.length === 1) {
      const [workspace] = workspaces;
      await this.setWorkspace(workspace).then();
    }
    if (workspaces && workspaces.length > 1) {
      const workspace = workspaces.find(el => el.workspace_id === selectedWS);
      workspace ? await this.setWorkspace(workspace).then() : appLocalStorage.set(WORKSPACE_ID, null);
    }
  };

  loadError = (err: AxiosError<any>) => {
    if (err.response?.status === 504) {
      runInAction(() => {
        this.error = err.response?.status;
      });
    }
  };

  //  ====== phone =====  //

  changeOrAddPhone = async (phone: string) => {
    await httpClient3
      .post('/api/v1/user/phone/edit', { phone: `+${phone}` })
      .then(() => this.setContentStep(2))
      .catch(err => {
        const apiCode = getApiCodeAxiosError(err);

        if (apiCode !== EApiCodes.PHONE_ALREADY_USED) {
          this.closeModal();
          this.addNotification({
            type: STATUS.ERROR,
            title: errorTitle(apiCode),
          });
        } else {
          this.setGlobalError(apiCode);
        }
      });
  };

  sendCode = async (phone: string) => {
    await this.execRequest(httpClient4.post('/api/v1/user/phone/edit', { phone: `+${phone}` })).catch(err => {
      err && this.setGlobalError(err);
    });
  };

  confirmCode = async (code: string) => {
    this.setIsLoading(true);

    setTimeout(async () => {
      await this.execRequest(httpClient4.post('/api/v1/user/phone/confirm', { code: code }))
        .then(() => {
          this.setData({ ...this.data, phone: this.newPhone });

          this.closeModal();
          this.addNotification({
            type: STATUS.SUCCESS,
            title: NotificationTitle.PHONE_CHANGED,
          });
        })
        .catch(err => {
          err && this.setGlobalError(err);
        })
        .finally(() => this.setIsLoading(false));
    }, 200);
  };

  // ============ email ================== //

  changeProfileEmail = async (data: { email: string; password: string }) => {
    this.setIsLoading(true);
    setTimeout(async () => {
      await httpClient4
        .put('/api/v1/user/email/edit', data)
        .then(res => {
          this.setIsLoading(false);
          this.setGlobalStatus(res.status);
        })
        .catch(e => {
          this.setIsLoading(false);
          e && this.setGlobalError(e);
        });
    }, 300);
  };

  cancelChangeEmail = async () => {
    await this.execRequest(httpClient4.delete('/api/v1/user/email/edit')).finally(() => this.closeModal());
  };

  // ============ password ================== //
  changeProfilePassword = async (data: TChangePassword) => {
    this.setIsLoading(true);
    setTimeout(async () => {
      await this.execRequest<ChangePasswordResp>(httpClient4.post('/api/v1/user/password/edit', data))
        .then(resp => {
          this.setIsLoading(false);
          resp && saveAuthTokens(resp.access_token, resp.refresh_token, resp.refresh_expires);
        })
        .then(() => {
          this.closeModal();
          this.addNotification({
            type: STATUS.SUCCESS,
            title: NotificationTitle.PASSWORD_CHANGED,
          });
        })
        .catch(e => {
          this.setIsLoading(false);
          e && this.setGlobalError(e);
        });
    }, 300);
  };

  addNewProfilePassword = async (password: string) => {
    this.setIsLoading(true);
    const data = { password: '', new_password: password };
    this.data.password_set = true;
    setTimeout(async () => {
      await this.execRequest<ChangePasswordResp>(httpClient4.post('/api/v1/user/password/edit', data))
        .then(resp => {
          this.setIsLoading(false);
          resp && saveAuthTokens(resp.access_token, resp.refresh_token, resp.refresh_expires);
        })
        .then(() => {
          this.closeModal();
          this.addNotification({
            type: STATUS.SUCCESS,
            title: NotificationTitle.PASSWORD_ADDED,
          });
        })
        .catch(e => {
          this.setIsLoading(false);
          e && this.setGlobalError(e);
        });
    }, 300);
  };

  // ============ social ================== //

  addSocial = async (socialInfo: SocialNetwork) => {
    const params: TOauthConnectionUrlReq = {
      url: `${window.location.origin}${routerPaths.auth.PROFILE}`,
      network: socialInfo,
    };
    await this.execRequest<[TOauthConnectionUrlResp]>([
      httpClient3.get(getApiUrl<paths>('/api/v1/user/oauth/connect_url'), {
        params,
      }),
    ])
      .then(this.getSocialUrlSuccess)
      .catch(this.getSocialUrlError);
  };

  getSocialUrlSuccess = ([resp]: [TOauthConnectionUrlResp]) => {
    const url = resp.data?.url;
    if (url) {
      window.location.assign(url);
    } else {
      console.log('error');
    }
  };

  removeSocial = async (socialInfo: SocialNetwork) => {
    await this.execRequest<[TClientInfoResp]>([
      httpClient3.get<ProfileData>(`/api/v1/user/social/${socialInfo}/disconnect`),
    ])
      .then(() => {
        this.loadProfile();
        this.addNotification({
          type: STATUS.SUCCESS,
          title: `${NotificationTitle.SOCIAL_ENTER} ${Social[socialInfo]} ${NotificationTitle.SOCIAL_OFF}`,
        });
      })
      .catch(this.getSocialUrlError);
  };

  getSocialUrlError = (err: AxiosError<any>) => {
    const apiCode = getApiCodeAxiosError(err);

    if (apiCode === EApiCodes.DISCONNECTING_LAST_POSSIBLE_LOGIN_METHOD) {
      this.addNotification({
        type: STATUS.ERROR,
        title: NotificationErrorTitle.NO_DISABLE_LAST_ENTER,
      });
    }
  };

  initAlertUrlError() {
    const error = new URLSearchParams(window.location.search).get(STATUS.ERROR);
    const status = new URLSearchParams(window.location.search).get(STATUS.STATUS);

    if (error === EAppErrorCodes.SOCIAL_PROFILE_ALREADY_CONNECTED) {
      history.push(routerPaths.auth.PROFILE);
      const alertProps = getAlertProps(
        new AppError(
          Object.keys(EAppErrorCodes).includes(error) ? (error as EAppErrorCodes) : EAppErrorCodes.SOCIAL_AUTH,
        ),
      );
      alertProps.title &&
        this.addNotification({
          type: STATUS.ERROR,
          title: alertProps.title?.toString(),
        });
    }

    if (status === STATUS.SUCCESS) {
      history.push(routerPaths.auth.PROFILE);
      this.addNotification({
        type: STATUS.SUCCESS,
        title: NotificationTitle.SOCIAL_ENTER_ON,
      });
    }
  }

  // ============ proposeIdea ================== //

  proposeIdea = async (data: ProposeIdea) => {
    const formData = new FormData();
    formData.append('text', data.text);
    data.importance && formData.append('importance', data.importance);
    formData.append('contact_allowed', data.contact_allowed.toString());
    data.email && formData.append('email', data.email);
    data.telegram && formData.append('telegram', `@${data.telegram}`);
    data.files && data.files.map(file => formData.append('files', file.file));

    await this.execRequest<ProposeIdeaReq>(httpClient4.post('/api/v1/core/propose_idea', formData));
  };

  // ============ WORKSPACE ================== //

  getWorkspace = async (workspace_id: string) => {
    await this.execRequest<WorkspaceResp>(httpClient4.get(`/api/v1/user/workspace/${workspace_id}`))
      .then(res => {
        res.members = res.members.sort((a, b) => sortRow(`${a.fname} ${a.lname}`, `${b.fname} ${b.lname}`));
        runInAction(() => (this.currentWorkspace = res));
        this.wsDictionary.members = res.members.reduce((acc, value) => {
          acc[value._id] = getMemberName({ fname: value?.fname, lname: value?.lname, email: value?.email });
          return acc;
        }, {} as TWsDictionary['members']);
        this.wsDictionary.projects = res.projects.reduce((acc, { name, _id, avatar_color }) => {
          acc[_id] = { name, avatar_color, _id };
          return acc;
        }, {} as TWsDictionary['projects']);
      })
      .catch(err => {
        console.log(err);
      });
  };

  postNewWorkspace = async () => {
    await this.execRequest<WorkspaceResp>(httpClient4.post('/api/v1/user/workspace/new', this.workspaceCreate))
      .then(() => {
        this.addNotification({
          type: STATUS.SUCCESS,
          title: `Пространство ${this.workspaceCreate?.company} успешно создано`,
        });
        this.execRequest<[TClientInfoResp]>([httpClient3.get<ProfileData>('/api/v1/user/profile')]).then(res => {
          this.setData(res[0]?.data || ({} as ProfileData));
          const selectedWS = appLocalStorage.get(WORKSPACE_ID) || '';
          const workspaces = res[0]?.data?.workspaces?.map(el => ({
            ...el,
            name: el.name || 'emptyName',
            shortname: el.shortname || 'emptyName',
            selected: el.workspace_id === selectedWS,
          }));
          runInAction(() => (this.workspaces = workspaces));
        });
      })
      .catch(err => {
        this.addNotification({
          type: STATUS.ERROR,
          title: err,
        });
        console.log(err);
      });
  };

  changeNameWorkspace = async (nameWorkspace: TNameWorkspace) => {
    await this.execRequest<WorkspaceResp>(httpClient2.put('/api/v1/user/workspace', nameWorkspace))
      .then(() => {
        this.addNotification({
          type: STATUS.SUCCESS,
          title: `Название пространства ${
            nameWorkspace.company ? nameWorkspace.company : this.workspaceName
          } успешно изменено`,
        });
        const modifiedUrl =
          nameWorkspace.shortname && window.location.pathname.replace(this.workspaceShortname, nameWorkspace.shortname);
        window.history.replaceState({ page: '' }, '', modifiedUrl);

        nameWorkspace.shortname && this.setWorkspaceShortname(nameWorkspace.shortname);
        nameWorkspace.company && this.setWorkspaceName(nameWorkspace.company);
      })
      .then(() => {
        this.getProfile().then(this.loadSuccess);
      })
      .catch(err => {
        this.addNotification({
          type: STATUS.ERROR,
          title: NotificationErrorTitle.UNKNOW_ERROR,
          message: err,
        });
      });
  };

  acceptWsInvite = async (hash: string, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    setIsLoading(true);

    await this.execRequest<WorkspaceResp>(httpClient4.post('api/v1/user/invite/email/accept', { hash: hash }))
      .then(() => {
        this.addNotification({
          type: STATUS.SUCCESS,
          title: 'Приглашение успешно принято',
        });

        this.removeInviteFromData(hash);
      })
      .then(() => {
        this.getProfile().then(this.loadSuccess).then(this.upWorkspaces);
      })
      .catch(err => {
        setIsLoading(false);
        this.addNotification({
          type: STATUS.ERROR,
          title: NotificationErrorTitle.UNKNOW_ERROR,
          message: err,
        });
      });
  };

  declineWsInvite = async (hash: string, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    setIsLoading(true);

    await this.execRequest<WorkspaceResp>(httpClient4.post('api/v1/user/invite/email/decline', { hash: hash }))
      .then(() => {
        this.addNotification({
          type: STATUS.SUCCESS,
          title: 'Приглашение успешно отклонено',
        });

        this.removeInviteFromData(hash);
      })
      .catch(err => {
        setIsLoading(false);

        this.addNotification({
          type: STATUS.ERROR,
          title: NotificationErrorTitle.UNKNOW_ERROR,
          message: err,
        });
      });
  };

  hideRecalledInvite = async (hash: string, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    await this.execRequest(httpClient4.post('api/v1/user/invite/email/hide', { hash: hash }))
      .then(() => {
        this.addNotification({
          type: STATUS.SUCCESS,
          title: 'Приглашение успешно скрыто',
        });

        this.removeInviteFromData(hash);
      })
      .catch(err => {
        setIsLoading(false);

        this.addNotification({
          type: STATUS.ERROR,
          title: NotificationErrorTitle.UNKNOW_ERROR,
          message: err,
        });
      });
  };

  // ============ loadUSSRVersion ================== //
  loadVersion = async () => {
    await this.execRequest<InfoResp>(httpClient4.get('/api/v1/core/app_info')).then(res => {
      console.group('USSR Version');
      console.log(`Branch name: ${res.branch_name}`);
      console.log(`Last commit: ${res.last_commit}`);
      console.groupEnd();
    });
  };
  resetStore = () =>
    runInAction(() => {
      this.data = {} as ProfileData;
      this.isAuthorized = false;
      this.modalVisible = false;
      this.modalAction = undefined;
      this.modalTitle = undefined;
      this.modalContentType = undefined;
      this.contentStep = undefined;
      this.newEmail = undefined;
      this.newPhone = undefined;
      this.disabledActions = undefined;
      this.disabledExit = undefined;
      this.globalError = undefined;
      this.globalStatus = undefined;
      this.isLoading = false;
      this.workspaces = [];
      this.workspaceShortname = '';
      this.workspaceName = '';
      this.currentWorkspace = {} as WorkspaceResp;
      this.WSRole = undefined;
      this.wsDictionary = { members: {}, projects: {} };
    });
}
