import Joi, { ValidationError } from '@hapi/joi';
import { TBasicFormStore, TEvents, TFormData, TFormInitParams, TValidators } from './types';

export function initBasicFormStoreValidators<F extends TFormData>(
  params: TFormInitParams<F>,
  formStore: TBasicFormStore<F>,
): TValidators<F> {
  return Object.keys(params).reduce((result, key: keyof F) => {
    const props = params[key];
    if (key && props) {
      result[key] = (value, event?: TEvents) => {
        function validateProp(event: TEvents) {
          const valid = props.validators && props.validators[event];
          if (typeof valid === 'function') {
            return valid(value, formStore);
          }
          if (Joi.isSchema(valid)) {
            return (valid as Joi.Schema).validate(value, { abortEarly: false }).error;
          }
          return undefined;
        }

        let error;
        if (event) {
          if (props.validators && props.validators[event]) {
            error = validateProp(event);
          }
        } else if (props.validators) {
          // если событие не передано, то происходит валидация по всем возможным
          const customErrorMsg = 'customErrorMsg';
          const joinedError = (Object.keys(props.validators) as TEvents[]).reduce((res, eventName) => {
            const validationError = validateProp(eventName);
            if (validationError) {
              if (validationError.details && validationError.details.length) {
                validationError.details.forEach(detail => {
                  // если еще нет ошибки с таким типом, то добавляем ее
                  if (!res.details.map(d => d.type).includes(detail.type)) {
                    res.details.push(detail);
                  }
                });
              } else {
                res = validationError;
              }
            }
            return res;
          }, new ValidationError(customErrorMsg, [], undefined));
          error = joinedError.details.length || joinedError.message !== customErrorMsg ? joinedError : undefined;
        }
        return error;
      };
    }
    return result;
  }, {} as TValidators<F>);
}

export function initBasicFormStoreData<F extends TFormData>(initParams: TFormInitParams<F>): F {
  return Object.keys(initParams).reduce((data: F, key: keyof F) => {
    const { value } = initParams[key];
    if (value !== undefined) {
      data[key] = value;
    }
    return data;
  }, {} as F);
}
