export enum Error {
  ALREADY_EXISTS = 'Already exists',
  PHONE_ALREADY_USED = 'Phone already used',
  DISCONNECTING_LAST_POSSIBLE_LOGIN_METHOD = 'DISCONNECTING_LAST_POSSIBLE_LOGIN_METHOD',
  SMS_NOT_SENT = 'SMS_NOT_SENT',
  THROTTLING_LIMIT_5 = 'THROTTLING_LIMIT_5_MIN',
}

export enum NotificationErrorTitle {
  UNKNOW_ERROR = 'Произошла ошибка',
  ERROR = 'Ошибка',
  LOAD_ERROR = 'Ошибка загрузки',
  ALREADY_EXISTS = 'Данный email уже используется',
  PHONE_ALREADY_USED = 'Данный номер телефона уже используется',
  NO_STAFFS_INFROMATION = 'Не удалось загрузить информацию о сотрудниках',
  NO_STAFF_INFROMATION = 'Не удалось загрузить информацию о сотруднике',
  NO_CLIENTS_LIST = 'Не удалось загрузить список пользователей',
  NO_CLIENT_INFROMATION = 'Не удалось загрузить информацию о пользователе',
  NO_DISABLE_LAST_ENTER = 'Невозможно отключить последний метод входа',
  NO_CONNECT_TO_VKAD = 'Не удалось подключиться к VKAD',
  NO_CONNECT_TO_PLARIN = 'Не удалось подключиться к Plarin',
  SMS_NOT_SENT = 'Не получается отправить смс. Произошла ошибка на сервере',
  THROTTLING_SMS_SEND = 'Вы исчерпали лимит попыток отправки СМС–кода.',
  TRY_AFTER_5_MIN = 'Повторите попытку через 5 минут',
}

export enum NotificationTitle {
  STAFF_NAME_CHANGED = 'Имя сотрудника успешно изменено',
  ACCOUNT_NAME_CHANGED = 'Имя учетной записи успешно изменено',
  EMAIL_CHANGED = 'Адрес электронной почты успешно изменен',
  PHONE_CHANGED = 'Номер телефона успешно изменен',
  PHONE_NOT_CHANGED = 'Номер телефона не был изменён',
  PASSWORD_CHANGED = 'Пароль успешно изменён',
  PASSWORD_ADDED = 'Пароль успешно добавлен',
  STAFF_CREATED = 'Сотрудник успешно создан',
  STAFF_DELETED = 'Сотрудник успешно удалён',
  ACCESS_BACKOFFICE_ON = 'Доступ к Backoffice включен',
  ACCESS_BACKOFFICE_OFF = 'Доступ к Backoffice выключен',
  ACCESS_PLARIN_ON = 'Доступ к Plarin включен',
  ACCESS_PLARIN_OFF = 'Доступ к Plarin выключен',
  SOCIAL_OFF = 'успешно отключен!',
  SOCIAL_ENTER = 'Вход через',
  SOCIAL_ENTER_ON = 'Вход через социальную сеть подключен',
  ACCOUNT_REMOVED = 'Аккаунт удален',
  PROPOSE_IDEA = 'Спасибо за идею!',
  DOWNLOAD_REPORT = 'Формирование отчета',
  MEMBER_STATUS = 'Статус пользователя успешно изменён',
  INVITE_DELETE = 'Приглашение успешно удалено',
  MEMBER_DELETE = 'Пользователь успешно удален',
  MEMBER_EDIT = 'Пользователь успешно отредактирован',
  PROJECT_DELETE = 'Проект успешно удален',
  PROJECT_COMPLETED = 'Проект завершён',
  PROJECT_ACTIVE = 'Проект восстановлен',
  PROJECT_ARCHIVED = 'Проект архивирован',
  PROJECT_UNARCHIVED = 'Проект разархивирован',
}

export enum NotificationMessage {
  ACCOUNTS_REMOVED = 'Аккаунты успешно удалены',
  PROPOSE_IDEA = 'Мы получили вашу идею и обязательно рассмотрим ее в ближайшее время.',
  ERROR_FORM = 'Произошла ошибка при отправке формы',
}

export const errorTitle = (e: string | undefined) => {
  if (e === Error.ALREADY_EXISTS) {
    return NotificationErrorTitle.ALREADY_EXISTS;
  }
  if (e === Error.PHONE_ALREADY_USED) {
    return NotificationErrorTitle.PHONE_ALREADY_USED;
  }
  if (e === Error.DISCONNECTING_LAST_POSSIBLE_LOGIN_METHOD) {
    return NotificationErrorTitle.NO_DISABLE_LAST_ENTER;
  }
  if (e === Error.SMS_NOT_SENT) {
    return NotificationErrorTitle.SMS_NOT_SENT;
  }
  if (e === Error.THROTTLING_LIMIT_5) {
    return NotificationErrorTitle.THROTTLING_SMS_SEND + ' ' + NotificationErrorTitle.TRY_AFTER_5_MIN;
  } else {
    return NotificationErrorTitle.UNKNOW_ERROR;
  }
};
