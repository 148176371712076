import { EmptyTableFindIcon, Typography, RefreshIcon } from '@plarin/design';
import React from 'react';
import classes from './style.module.scss';

type ErrorLoadingDataProps = {
  action?: () => void;
  loading?: boolean;
};

export const ErrorLoadingData = ({ action = () => {} }: ErrorLoadingDataProps) => {
  return (
    <div className={classes.error}>
      <EmptyTableFindIcon />
      <div className={classes.text}>
        <Typography size="AuthSubheader" color="TextSecondary" align="center">
          Ошибка загрузки данных,
          <br /> пожалуйста, попробуйте позднее.
        </Typography>
      </div>
      <div className={classes.refresh} onClick={action}>
        <RefreshIcon />
        <Typography size="AuthSubheader" color="Primary20" weight={700}>
          Обновить
        </Typography>
      </div>
    </div>
  );
};
