import { formatDate } from '@plarin/utils';
import { SatisticsFast, SatisticsFast72 } from '../../types/manage-vk/types';

// парсим время из статы
export const parseTime = (t: string) => {
  const d = new Date();
  const time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);

  time && d.setHours(parseInt(time[1]));
  time && d.setMinutes(parseInt(time[2]) || 0);
  d.setSeconds(0);

  return +d.getTime().toString().slice(0, -3);
};

// парсим даты и часы из статы
export const parseDate72 = (d: string) => {
  const date = d.slice(0, d.indexOf('T')).split('-');

  const newDate = `${date[0]}-${date[1]}-${date[2]}`;
  const time = (+d[d.indexOf('T') + 1] || '') + d[d.indexOf('T') + 2];
  const formattedTime = time.length === 1 ? `0${time}` : time;

  return new Date(`${newDate} ${formattedTime}:00`).getTime() / 1000;
};

// форматирование итоговой даты для фаст
const formatterStat = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const h = date.getHours();
  const min = date.getMinutes();
  return `${formatDate(h)}:${formatDate(min)}`;
};

// форматирование итоговой даты для фаст72
const formatterStat72 = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();
  const h = date.getHours();
  return `${formatDate(d)}.${formatDate(m)}.${y} ${formatDate(h)}:00`;
};

// парсинг фаст60
export const parseFastStat = (fast: SatisticsFast, startHour: number) => {
  const timesArray = [];

  for (let i = startHour; i < startHour + 3600; i = i + 60) {
    timesArray.push(formatterStat(i));
  }

  const fastObjectFormatted = fast.reduce((acc, value) => {
    acc[formatterStat(value.t)] = { ...value };
    return acc;
  }, {} as any);

  return timesArray.map(el => {
    return fastObjectFormatted.hasOwnProperty(el)
      ? { t: el, impressions: fastObjectFormatted[el].impressions || 0, clicks: fastObjectFormatted[el].clicks || 0 }
      : { t: el, impressions: 0, clicks: 0 };
  });
};

// парсинг фаст72
export const parseFastStat72 = (fast72: SatisticsFast72, startDate: number) => {
  const resultArray72: SatisticsFast72 = [];

  let prevD = startDate || 0;

  if (startDate && !fast72.some(el => el.d === startDate)) {
    fast72.unshift({ d: startDate, impressions: 0, clicks: 0 });
  }

  for (const point of fast72) {
    const currentD = point.d;
    const diffInHours = (currentD - prevD) / 3600;

    resultArray72.push(point);

    for (let index = 1; index < diffInHours; index++) {
      resultArray72.push({ d: prevD + index * 3600, impressions: 0, clicks: 0 });
    }

    prevD = currentD;
  }

  while (resultArray72.length < 72) {
    prevD += 3600;
    resultArray72.push({ d: prevD, impressions: 0, clicks: 0 });
  }

  return resultArray72.map(item => ({
    ...item,
    d: formatterStat72(item.d),
    impressions: item.impressions || 0,
    clicks: item.clicks || 0,
  }));
};
