import { WORKSPACE_ID } from '@plarin/core';
import { Avatar, ExitIcon, SettingsIcon, Typography } from '@plarin/design';
import { Popover, EllipsisTooltip, MainMenuItem } from '@plarin/inputs';
import { formatPhone } from '@plarin/utils';
import React, { useCallback, useMemo } from 'react';
import { routerPaths } from '../../../routing/router-path';
import { appLocalStorage, history, logout } from '../../app';
import { useAppDataStore } from '../../app-data-provider';
import classes from './style.module.scss';

export type MenuProfileProps = {
  isOpen: boolean;
  setOpen: (flag: boolean) => void;
  anchorEl: HTMLElement | null;
};

export const ProfileMenu = ({ isOpen, setOpen, anchorEl }: MenuProfileProps) => {
  const {
    menu: { removeHover, deselectMenuItem },
    profileStore: {
      data: { lname, fname, phone, workspaces: profileWorkspaces, email },
      workspaceShortname,
    },
  } = useAppDataStore();

  const colorAvatar = useMemo(() => {
    const selectedIdWs = appLocalStorage.get(WORKSPACE_ID);
    return profileWorkspaces?.find(el => el.workspace_id === selectedIdWs)?.avatar_color;
  }, [workspaceShortname]);

  const goToProfile = useCallback(() => {
    history.push(routerPaths.auth.PROFILE);
    deselectMenuItem();
    setOpen(false);
    removeHover();
  }, []);

  const logoutApp = useCallback(() => {
    logout();
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Popover
      marginTop={12}
      classNameWrapper={classes.menuProfile}
      setIsOpen={onClose}
      anchorEl={anchorEl}
      isOpen={isOpen}
    >
      <>
        <div className={classes.menuProfile__header}>
          <Avatar fname={fname || ''} lname={lname || ''} size={20} email={email} colorAvatar={colorAvatar} />
          <div className={classes.fioText}>
            {fname && (
              <EllipsisTooltip tooltipMessage={fname}>
                <Typography
                  ellips={true}
                  color="TextPrimary"
                  size="AuthContent"
                  weight={600}
                  componentProps={{ className: classes.menuProfile__header_text }}
                >
                  {fname}
                </Typography>
              </EllipsisTooltip>
            )}
            {lname && (
              <EllipsisTooltip tooltipMessage={lname}>
                <Typography
                  ellips={true}
                  color="TextPrimary"
                  size="AuthContent"
                  weight={600}
                  componentProps={{ className: classes.menuProfile__header_text }}
                >
                  {lname}
                </Typography>
              </EllipsisTooltip>
            )}
            {!lname && !fname && phone && (
              <EllipsisTooltip tooltipMessage={phone}>
                <Typography
                  ellips={true}
                  color="TextPrimary"
                  size="AuthContent"
                  weight={600}
                  componentProps={{ className: classes.menuProfile__header_text }}
                >
                  {formatPhone(phone)}
                </Typography>
              </EllipsisTooltip>
            )}
            {!lname && !fname && !phone && (
              <EllipsisTooltip tooltipMessage={email || 'Без имени'}>
                <Typography
                  ellips={true}
                  color="TextPrimary"
                  size="AuthContent"
                  weight={600}
                  componentProps={{ className: classes.menuProfile__header_text }}
                >
                  {email || 'Без имени'}
                </Typography>
              </EllipsisTooltip>
            )}
          </div>
        </div>
        <div className={classes.menuProfile__actions}>
          <MainMenuItem icon={<SettingsIcon />} title="Профиль" onClick={goToProfile} />
          <MainMenuItem icon={<ExitIcon size={20} />} title="Выйти" onClick={logoutApp} />
        </div>
      </>
    </Popover>
  );
};
