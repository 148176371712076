import { ICellRendererParams } from 'ag-grid-community';

type TMember = {
  _id: string;
  /** Email */
  email: string;
  /** Имя */
  fname: string;
  /** Фамилия */
  lname: string;
  /** Статус */
  status: 'active' | 'blocked';
  /** Роль пользователя в воркспейсе */
  role: 'wsrole:owner' | 'wsrole:admin' | 'wsrole:employee' | 'wsrole:guest';
  /** Время последней активности */
  last_activity?: string;
  /** Цвет пользователя в воркспейсе */
  avatar_color:
    | 'FEAFD5'
    | 'EE5D80'
    | 'B0445E'
    | 'ED6CCD'
    | 'EEAEE8'
    | 'CE5DE2'
    | '9F48AF'
    | 'BEB0FA'
    | '845CF7'
    | '6242BD'
    | 'ACB4F6'
    | '5671FF'
    | '3C50B9'
    | 'AACCFF'
    | '46A1FF'
    | '3478BF'
    | '99DDE4'
    | '17C5D1'
    | '0F828A'
    | '24A79F'
    | 'A1DFA8'
    | '3DC453'
    | '2A8739'
    | 'D6E994'
    | 'BEDA13'
    | '557C00'
    | 'FFDBA9'
    | 'FFC040'
    | 'FDC3AA'
    | 'FC8D46'
    | 'C95103'
    | 'F3ABAB'
    | 'EA4B4A'
    | 'C93A38';
};

type TInvite = {
  /** _id воркспейса */ workspace_id: string;
  /** Email */
  email: string;
  /** company */
  company?: string;
  /** Роль пользователя в воркспейсе */
  role: 'wsrole:owner' | 'wsrole:admin' | 'wsrole:employee' | 'wsrole:guest';
  /** Дата истечения срока действия */
  expired: string;
  /** Дата создания */
  created: string;
};

type TableMembersData = TMember & TInvite & { key: string; cellLoadingName: string };

export interface MemberRenderProps extends ICellRendererParams {
  MemberCellMenu: React.FC<TMemberCellMenu>;
  // TODO в data есть ещё key, cellLoaderName
  data: TableMembersData;
}

export type TMemberCellMenu = {
  data: TableMembersData;
};
