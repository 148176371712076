import { Avatar } from '@plarin/design';
import { CustomMenuItem, EllipsisTooltip, HightLightText } from '@plarin/inputs';
import { getMemberName } from '@plarin/utils';
import clsx from 'clsx';
import React, { useState } from 'react';
import { TGuests } from '../../../../../../../../types/projects/projects';
import classes from '../../styles.module.scss';

type TGuestsListItem = {
  guest: TGuests;
  changeGuestsSelection: (guest: TGuests) => void;
  isFoundItem?: boolean;
  searchValue?: string;
};

export const GuestsListItem = ({ guest, changeGuestsSelection, isFoundItem, searchValue = '' }: TGuestsListItem) => {
  const [isHovered, setIsHovered] = useState(false);
  const nameTooltipContent = getMemberName({ fname: guest?.fname, lname: guest?.lname, email: guest?.email });
  return (
    <CustomMenuItem
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={e => e.preventDefault()}
      onClick={() => {
        changeGuestsSelection(guest);
      }}
      className={clsx(classes.cabinetListItem, isFoundItem && classes.foundCabinet)}
    >
      <>
        <Avatar size={20} fname={guest.fname} lname={guest.lname} email={guest.email} colorAvatar={guest.avatarColor} />
        <div className={classes.regularWrap}>
          <EllipsisTooltip tooltipMessage={nameTooltipContent}>
            <HightLightText
              componentProps={{ className: classes.listItemText }}
              size="Main"
              textEllips={true}
              weight={400}
              color={isHovered ? 'TextWhite' : 'TextPrimary'}
              text={nameTooltipContent}
              filterValue={searchValue}
            />
          </EllipsisTooltip>
        </div>
      </>
    </CustomMenuItem>
  );
};
