import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

interface PercentsRendererType extends ICellRendererParams {
  fieldType?: string;
  valueFormatter?: (str: string) => string;
}

export const PercentsRenderer = ({ valueFormatter, value }: PercentsRendererType) => {
  return <div>{value && valueFormatter ? valueFormatter(`${value}`) : '--'}</div>;
};
