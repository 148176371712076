export enum DrawerStep {
  SelectNetwork = 1,
  ConnectStart = 3,
  ConnectResult = 4,
}

export enum Social {
  'g' = 'Google',
  'fb' = 'Facebook',
  'vk' = 'ВKонтакте',
}

export enum DrawerId {
  proposeIdea = 1,
  support = 2,
}

export enum LegalDocuments {
  LICENSE_AGREEMENT = 'https://plarin.ru/legal/license',
  PERSONAL_DATA_PROCESSING_CONSENT = 'https://plarin.ru/legal/privacy',
  INFORMATION_MESSAGES_CONSENT = 'https://plarin.ru/legal/advertising_agreement',
}
