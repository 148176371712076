import clsx from 'clsx';
import React from 'react';
import { useAppDataStore } from '../app-data-provider';
import classes from './style.module.scss';

type EditMenuLayoutProps = {
  visionBox: string;
  children: JSX.Element;
};

export const EditMenuLayout = ({ visionBox, children }: EditMenuLayoutProps) => {
  const {
    menu: { menuVisible },
  } = useAppDataStore();

  const stopPropagation = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  };

  return (
    <div
      className={clsx(
        classes.card,
        visionBox ? classes.visible : classes.noVisible,
        menuVisible ? classes.withMenu : classes.withoutMenu,
      )}
      onKeyDown={stopPropagation}
    >
      {children}
    </div>
  );
};
