import React from 'react';
import { CustomList } from '@plarin/inputs';
import { TShortCabinetsFilters } from '../../../../../../../../types/projects/projects';
import classes from '../../styles.module.scss';
import { ProjectListItem } from './project-list-item';

type TClientsListProps = {
  cabinetsListToRender: TShortCabinetsFilters[] | null;
  selectedCabinets?: TShortCabinetsFilters[];
  changeCabinetSelection: (cabinet: TShortCabinetsFilters) => void;
  searchValue?: string;
};

export const ProjectClientsList = ({
  cabinetsListToRender,
  selectedCabinets,
  changeCabinetSelection,
  searchValue,
}: TClientsListProps) => {
  return (
    <CustomList classProps={classes.listRoot}>
      <>
        {cabinetsListToRender &&
          cabinetsListToRender
            ?.filter(el => !selectedCabinets?.find(item => item.client_id === el.client_id))
            ?.map(cabinet => (
              <ProjectListItem
                cabinet={cabinet}
                changeCabinetSelection={changeCabinetSelection}
                key={cabinet.client_id}
                searchValue={searchValue}
              />
            ))}
      </>
    </CustomList>
  );
};
