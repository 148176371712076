import { Button, ButtonProps } from '@mui/material';
import { Typography } from '@plarin/design';
import React from 'react';

export const IntegrationConnectWithFacebookButton: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <Button
      {...props}
      classes={{
        root: 'FacebookButtonRoot',
      }}
    >
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: '10px' }}
      >
        <path
          d="M28 14.0852C28 6.30673 21.7314 0 14 0C6.2686 0 0 6.30673 0 14.0852C0 21.1149 5.11883 26.9423 11.8127 28V18.1576H8.25707V14.0852H11.8127V10.9813C11.8127 7.45161 13.9035 5.50057 17.1008 5.50057C18.6325 5.50057 20.2351 5.77589 20.2351 5.77589V9.24222H18.4691C16.7307 9.24222 16.1873 10.3277 16.1873 11.4428V14.0852H20.0698L19.4496 18.1576H16.1873V28C22.8812 26.9443 28 21.1169 28 14.0852Z"
          fill="white"
        />
      </svg>
      <Typography size="IntegrationSelectChannelMessage" weight={700}>
        Продолжить с Facebook
      </Typography>
    </Button>
  );
};
