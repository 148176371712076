import { Typography } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import { WorkspaceResp, TWsRole } from '../../../../types/profile/types';
// import { TCreateTeamReqBody } from '../../../../types/teams/teams';
import classes from './style.module.scss';

// возвращает request body для создания команды, в которую входят все участники и все проекты воркспейса
// export const getCreateTeamBody = (currentWorkspace: WorkspaceResp): TCreateTeamReqBody => ({
//   name: 'Тестовая команда',
//   leader_id: currentWorkspace.owner._id, // majid70901@bomoads.com
//   member_ids: currentWorkspace.members.map(member => member._id),
//   project_ids: currentWorkspace.projects.map(project => project._id),
//   hidden: false,
//   closed: false,
//   status: 'active',
// });

export const getWsRole = (
  userId: string | undefined,
  currentWorkspace: WorkspaceResp | undefined,
): TWsRole | undefined => {
  // при прямом переходе на данную страницу поначалу данные ещё не засетились в profileStore
  if (!userId || !currentWorkspace || !currentWorkspace.members || !currentWorkspace.members.length) return undefined;

  const myWsData = currentWorkspace.members.find(member => member._id === userId);
  return myWsData?.role;
};
