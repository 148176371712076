export type EditBidFormTypes =
  | 'FixedEditBid'
  | 'StrategyEditBid'
  | 'PlugAutoPrice'
  | 'PlugMaxShows'
  | 'PlugMarginalRate'
  | 'unknown';

// функция для определения того, что за тип ставки у объекта кампании/объявления, и какую форму для редактирования ставки в связи с этим нужно использовать
// в будущем в функции должно стать намного больше условий, а пока не до конца понятно, какие свойства объекта на что нам указывают
export const getFormType = (row: Record<string, any>): EditBidFormTypes => {
  if (row.network === 'va') return 'StrategyEditBid';

  if (row.network === 'mt') {
    if (
      row.bidType === 'CPM' &&
      row.bidAutobiddingMode === 'max_shows' &&
      (row.maxPrice === 0 || row.maxPrice === '') // в таске написано, что должно равняться нулю, но судя по тестовым кабинетам, тут приходит пустая строка
    )
      return 'PlugAutoPrice';

    // Это условие подходит и для всех нормальных фиксированных ставок. Видимо, условие для "максимума показов" задано неверно.
    // if (
    //   row.bidType === 'CPM' &&
    //   row.bidAutobiddingMode === 'second_price_mean' &&
    //   (row.maxPrice === 0 || row.maxPrice === '') // в таске написано, что должно равняться нулю, но судя по тестовым кабинетам, тут приходит пустая строка
    // )
    //   return 'PlugMaxShows';

    if (row.bidType === 'CPM' && row.bidAutobiddingMode === 'max_shows' && row.maxPrice > 0) return 'PlugMarginalRate';

    if (
      row.bidAutobiddingMode === 'fixed' ||
      row.bidAutobiddingMode === 'second_price' ||
      row.bidAutobiddingMode === 'second_price_mean'
    )
      return 'FixedEditBid';
  }

  return 'unknown';
};

export const getDate = (dateArg: string | Date): Date | null => {
  if (typeof dateArg === 'string') {
    return dateArg.length ? new Date(dateArg) : null;
  }

  return dateArg;
};
