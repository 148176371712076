// принимает 'ussr-api.plarin.ru' и 'ui-dev', возвращает 'ui-dev.plarin.ru'
export const transformDomain = (domain: string, subdomain: string): string => {
  return subdomain + '.' + domain.split('.').slice(1, 9).join('.');
};

export const getDomainFromLocation = (envUiSubdomain: string): string => {
  // window.location.href это, например: 'https://ui-backoffice.plarin.ru/clients/customers'
  // из него получим: 'ui-backoffice.plarin.ru'
  const currentDomain = window.location.href.split('/')[2];

  return transformDomain(currentDomain, envUiSubdomain);
};
