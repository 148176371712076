import { BasicStore, httpClient, httpClient2 } from '@plarin/core';
import { NotificationErrorTitle, NotificationTitle, shortDateTime } from '@plarin/utils';
import { makeObservable, observable, runInAction } from 'mobx';
import type { Dispatch } from 'react';
import React from 'react';
import { toast } from 'react-toastify';
import {
  TInitialStateDrawer,
  TReportData,
  TReportsData,
  TShortClientsResponse,
  TSortClients,
} from '../../types/reports/reports';
import { sortRow } from '../utils/common';
import { STATUS, DownloadExcelError } from '../utils/constants';
import { downloadFile } from '../utils/downloadFIle';
import { requestReportAdapter } from '../utils/reports';

const initialState: TInitialStateDrawer = {
  isEdit: false,
  fields: [],
  name: '',
  defaultName: `Новый отчет, ${shortDateTime(Date.now())}`,
  clientsFilterValue: '',
  reportClients: [],
  relative_period: 'all_time',
  date_to: null,
  date_from: null,
  periodGroups: 'noGroup',
  filters: [],
};

export class ReportsStore extends BasicStore {
  isShowDrawer: boolean = false;
  initialStateDrawer: TInitialStateDrawer = initialState;
  reportsList: TReportData[] = [];
  isLoading: boolean = false;
  searchValue: string = '';
  isLoadingExcelDownload: string[] = [];
  isError: boolean = false;
  shortClients: TSortClients = { error: false, loading: false, clients: [] };

  constructor() {
    super();
    makeObservable<this>(this, {
      isShowDrawer: observable,
      reportsList: observable,
      getClientsShort: observable,
      initialStateDrawer: observable,
      searchValue: observable,
      isLoading: observable,
      isLoadingExcelDownload: observable,
      isError: observable,
    });
  }

  openDrawer = (params?: 'initialState') =>
    runInAction(() => {
      if (params === 'initialState') {
        this.setDefaultInitialStateDrawerReport();
      }
      this.isShowDrawer = true;
    });
  closeDrawer = () =>
    runInAction(() => {
      this.isShowDrawer = false;
    });

  setDefaultInitialStateDrawerReport = () => runInAction(() => (this.initialStateDrawer = initialState));

  updateInitialStateDrawer = (value: Partial<TInitialStateDrawer>) => {
    runInAction(() => {
      this.initialStateDrawer = { ...this.initialStateDrawer, ...value };
    });
  };

  setSearchValue = (value: string) => runInAction(() => (this.searchValue = value));

  setIsLoading = (loading: boolean) => runInAction(() => (this.isLoading = loading));

  setIsLoadingExcelDownload = (id: string) => runInAction(() => this.isLoadingExcelDownload.push(id));
  deleteIsLoadingExcelDownload = (id: string | null) =>
    runInAction(() => (this.isLoadingExcelDownload = this.isLoadingExcelDownload.filter(e => e !== id)));

  getReports = async () => {
    this.setIsLoading(true);
    runInAction(() => (this.isError = false));

    setTimeout(async () => {
      await this.execRequest<TReportsData>(httpClient2.get('/api/v1/core/reports'))
        .then(response =>
          runInAction(
            () =>
              (this.reportsList = response.reports.sort(
                (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
              )),
          ),
        )
        .catch(() => runInAction(() => (this.isError = true)))
        .finally(() => this.setIsLoading(false));
    }, 350);
  };

  createReport = async (data: TInitialStateDrawer) => {
    try {
      const response = await this.execRequest<TReportData>(
        httpClient2.post('/api/v1/core/reports', requestReportAdapter(data)),
      );
      this.addNotification({ type: STATUS.SUCCESS, title: `Отчет ${response.name} успешно создан` });
      this.closeDrawer();
      await this.getReports();

      return response;
    } catch (error) {
      this.addNotification({
        type: STATUS.ERROR,
        title: NotificationErrorTitle.ERROR,
        message: 'Произошла ошибка при создании отчета. Попробуйте создать отчет еще раз',
      });
    }
  };

  createDownloadReport = async (data: TInitialStateDrawer) => {
    const resCreateReport = await this.createReport(data);
    if (resCreateReport) {
      this.setIsLoadingExcelDownload(resCreateReport._id);
      await this.downloadExcelReport({ id: resCreateReport._id, name: resCreateReport.name });
    }
  };

  downloadExcelReport = async (
    data: TInitialStateDrawer | { id: string; name: string },
    setLoading?: Dispatch<boolean>,
  ) => {
    const controller = new AbortController();
    const keyNotification = Date.now();

    data.id && this.setIsLoadingExcelDownload(data.id);
    // setTimeout для того чтобы не задваивался нотификатор
    setTimeout(() => {
      this.addNotification({
        title: NotificationTitle.DOWNLOAD_REPORT,
        type: STATUS.LOADING,
        message: `Идет подготовка отчета:\n${data.name}`,
        key: keyNotification,
        canceledDownloadExcel: e => {
          e.stopPropagation();
          data.id && this.deleteIsLoadingExcelDownload(data.id);
          controller.abort();
        },
      });
    });

    setTimeout(async () => {
      try {
        const body = 'isEdit' in data ? { report: requestReportAdapter(data) } : { report_id: data.id };
        let name = '';
        const blob = await this.execRequest(
          httpClient
            .post('/api/v1/core/reports/generate', body, { responseType: 'blob', signal: controller.signal })
            .then((res: any) => {
              // prettier-ignore
              name = decodeURIComponent(escape(atob(res.headers.get('content-disposition').split('"')[1])));
              return res;
            }),
        );
        await downloadFile(blob, name);

        data.id && this.deleteIsLoadingExcelDownload(data.id);
        setLoading && setLoading(false);

        // Удаление нотификатора
        toast.dismiss(keyNotification);
      } catch (error) {
        if (!controller.signal?.aborted) {
          this.addNotification({
            type: STATUS.ERROR,
            title: NotificationErrorTitle.LOAD_ERROR,
            message: <DownloadExcelError />,
          });

          toast.dismiss(keyNotification);
        }

        setLoading && setLoading(false);
      }
    }, 3500);
  };

  getClientsShort = async () => {
    runInAction(() => {
      this.shortClients.loading = true;
      this.shortClients.error = false;
    });

    await this.execRequest<TShortClientsResponse>(httpClient2.get('/api/v1/core/view/reports/clients'))
      .then(res => {
        runInAction(() => {
          this.shortClients.clients = res.clients.sort((a, b) =>
            sortRow(a.account_name || a.account_username, b.account_name || b.account_username),
          );
          this.shortClients.loading = false;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.shortClients.loading = false;
          this.shortClients.error = true;
        });
      });
  };

  deleteReport = async (id: string) => {
    try {
      await this.execRequest(httpClient2.delete(`/api/v1/core/reports/${id}`));
      this.addNotification({
        type: STATUS.SUCCESS,
        title: 'Отчет успешно удален',
      });
      await this.getReports();
    } catch (error) {
      this.addNotification({
        type: STATUS.ERROR,
        title: NotificationErrorTitle.ERROR,
        message: 'Произошла ошибка при удалении отчета',
      });
    }
  };

  updateReport = async (data: TInitialStateDrawer, onError?: () => any) => {
    try {
      const response = await this.execRequest<TReportData>(
        httpClient2.post(`/api/v1/core/reports/${data.id}`, requestReportAdapter(data)),
      );
      this.addNotification({ type: STATUS.SUCCESS, title: `Отчет ${response.name} успешно обновлен` });
      this.closeDrawer();
      await this.getReports();

      return response;
    } catch (error) {
      onError && onError();
      this.addNotification({
        type: STATUS.ERROR,
        title: NotificationErrorTitle.ERROR,
        message: 'Произошла ошибка при обновлении отчета. Попробуйте еще раз',
      });
    }
  };

  updateDownloadReport = async (data: TInitialStateDrawer) => {
    const resUpdateReport = await this.updateReport(data);
    if (resUpdateReport) {
      this.setIsLoadingExcelDownload(resUpdateReport._id);
      await this.downloadExcelReport({ id: resUpdateReport._id, name: data.name });
    }
  };

  resetStore = () =>
    runInAction(() => {
      this.isShowDrawer = false;
      this.initialStateDrawer = initialState;
      this.reportsList = [];
      this.isLoading = false;
      this.searchValue = '';
      this.isLoadingExcelDownload = [];
      this.isError = false;
      this.shortClients = { error: false, loading: false, clients: [] };
    });
}
