import { Tab, TabProps } from '@mui/material';
import { CloseIcon, Typography } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import { EllipsisTooltip } from '../tooltip';
import labelClasses from './label.module.scss';
import classes from './tabs.module.scss';

interface IntegrationsTabProps extends TabProps {
  label: string | JSX.Element;
  settings?: boolean;
  rowsCount?: number;
  deleteAction?: () => void;
}

export const TableTab = ({ label, settings, rowsCount, deleteAction, ...props }: IntegrationsTabProps) => {
  return (
    <Tab
      label={
        <>
          {settings ? (
            <div className={classes.labelText}>{label}</div>
          ) : (
            <EllipsisTooltip tooltipMessage={label}>
              <Typography size="Main" ellips={true}>
                {label}
              </Typography>
            </EllipsisTooltip>
          )}
          {!!rowsCount && (
            <div className={labelClasses.rowCounter}>
              <Typography color="TextWhite" size="Caption" weight={400}>
                {rowsCount}
              </Typography>
              <CloseIcon
                size={16}
                color="white"
                className={labelClasses.deleteAction}
                onClick={event => {
                  event.stopPropagation();
                  deleteAction && deleteAction();
                }}
              />
            </div>
          )}
        </>
      }
      {...props}
      classes={{
        root: clsx(settings && classes.settings, classes.tabRoot),
        selected: classes.tabSelected,
      }}
    />
  );
};
