import { BasicStore, httpClient2 } from '@plarin/core';
import { NotificationErrorTitle, NotificationTitle } from '@plarin/utils';
import isEqual from 'lodash/isEqual';
import { makeObservable, observable, runInAction } from 'mobx';
import { TClientInfoResp, TWorkspaceTagsData } from '../../types/profile/types';
import {
  AccessEditMemberReq,
  EditMemberModals,
  InviteCreateReq,
  InviteCreateResp,
  InviteEmailCreateReq,
  ResponseModel_Dict_,
  TWorkspaceAccessResp,
  TWorkspaceDrawerData,
  TwsDataDrawer,
  WorkspaceMemberResp,
  WSInvites,
  WSMembers,
} from '../../types/workspace/types';
import { sortRow } from '../utils/common';
import { STATUS } from '../utils/constants';

const defaultWsDataDrawer: TwsDataDrawer = {
  editMember: { isVisible: false, idMember: '', data: {} as TWorkspaceAccessResp },
};
const defaultEditMemberModals: EditMemberModals = {
  idMember: '',
  typeModal: '',
  action: () => {},
};
const defaultDrawerData: TWorkspaceDrawerData = { userData: {}, workspaceTags: [] as unknown } as TWorkspaceDrawerData;

export class MembersStore extends BasicStore {
  members: WSMembers[] = [];
  invites: WSInvites[] = [];
  isTableLoading: boolean = false;
  isLoading: boolean = false;
  hasLoadingError: boolean = false;
  wsDataDrawer = defaultWsDataDrawer;
  editMemberModals = defaultEditMemberModals;
  drawerData = defaultDrawerData;
  userId?: string;
  drawerLoading?: boolean = false;
  isDrawerDataChanged: boolean = false;
  initialDrawerData: TWorkspaceDrawerData = defaultDrawerData;

  constructor() {
    super();
    makeObservable<this>(this, {
      members: observable,
      invites: observable,
      isTableLoading: observable,
      isLoading: observable,
      wsDataDrawer: observable,
      editMemberModals: observable,
      drawerLoading: observable,
      drawerData: observable,
      userId: observable,
      hasLoadingError: observable,
      isDrawerDataChanged: observable,
      initialDrawerData: observable,
    });
  }

  setUserId = (userId: string) => runInAction(() => (this.userId = userId));

  setDrawerLoading = (loading: boolean) => runInAction(() => (this.drawerLoading = loading));

  setHasLoadingError = (hasError: boolean) => runInAction(() => (this.hasLoadingError = hasError));

  openModalMember = (nameModal: EditMemberModals['typeModal'], idMember?: string, action?: any) =>
    runInAction(() => (this.editMemberModals = { idMember: idMember || '', typeModal: nameModal, action: action }));

  closeModalMember = (nameModal: EditMemberModals['typeModal']) =>
    runInAction(() => (this.editMemberModals = { idMember: '', typeModal: '', action: () => {} }));

  private setTableLoading = (isLoading: boolean) => runInAction(() => (this.isTableLoading = isLoading));
  private setIsLoading = (loading: boolean) => runInAction(() => (this.isLoading = loading));

  setLoadingInviteCell = (email: string, cellLoadingName: string) =>
    runInAction(
      () =>
        (this.invites = this.invites.map((el: WSInvites) =>
          el.email === email ? { ...el, cellLoadingName: cellLoadingName } : el,
        )),
    );

  setWsDataDrawer = (
    nameDrawer: keyof TwsDataDrawer,
    isVisible: boolean,
    idMember: string,
    data: TWorkspaceAccessResp,
  ) => runInAction(() => (this.wsDataDrawer[nameDrawer] = { isVisible, idMember, data }));

  setMembers = (data: WSMembers[]) => runInAction(() => (this.members = data));
  setInvites = (data: WSInvites[]) => runInAction(() => (this.invites = data));

  getWSMembers = async () => {
    this.setTableLoading(true);
    this.setHasLoadingError(false);

    setTimeout(async () => {
      await this.execRequest<WorkspaceMemberResp>(httpClient2.get('/api/v1/user/workspace/members'))
        .then(response => {
          const members = response.members
            .map((el, i) => ({
              ...el,
              key: el._id,
              cellLoadingName: '',
            }))
            .sort((a, b) => sortRow(`${a.fname} ${a.lname}`, `${b.fname} ${b.lname}`));

          const invites = response.invites.map(el => ({
            ...el,
            key: (Date.now() + Math.random()).toString(),
            cellLoadingName: '',
          }));

          this.setMembers(members);
          this.setInvites(invites);
        })
        .catch(() => {
          this.setMembers([]);
          this.setInvites([]);
          this.setHasLoadingError(true);
        })
        .finally(() => this.setTableLoading(false));
    }, 350);
  };

  getInviteHash = async (data: InviteCreateReq) => {
    return this.execRequest<InviteCreateResp>(httpClient2.post('/api/v1/user/invite', data));
  };

  postInviteMember = async (
    data: InviteEmailCreateReq,
    setIsOpenInvite: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    this.setIsLoading(true);

    setTimeout(async () => {
      await this.execRequest<ResponseModel_Dict_>(httpClient2.post('/api/v1/user/invite/email', data))
        .then(resp => {
          this.addNotification({
            type: STATUS.SUCCESS,
            title: `Приглашение на почту ${data.emails} отправлено`,
          });
        })
        .catch(e => {
          console.log(e);
          this.addNotification({
            type: STATUS.ERROR,
            title: NotificationErrorTitle.UNKNOW_ERROR,
          });
        })
        .finally(() => {
          this.setIsLoading(false);
          setIsOpenInvite(false);
          this.getWSMembers();
        });
    }, 500);
  };

  editStatusMember = async (userId: string, data: AccessEditMemberReq) => {
    this.setIsLoading(true);
    setTimeout(async () => {
      await this.execRequest<TClientInfoResp>(httpClient2.post(`/api/v1/user/access/${userId}`, data))
        .then(_ => {
          this.members = this.members.map(el => {
            if (el._id === userId) {
              return { ...el, status: data.status! };
            } else {
              return el;
            }
          });

          this.drawerData = {
            ...this.drawerData,
            userData: { ...this.drawerData.userData, status: data.status! },
          };

          this.addNotification({
            type: STATUS.SUCCESS,
            title: NotificationTitle.MEMBER_STATUS,
          });
        })
        .catch(e => {
          console.log(e);
          this.addNotification({
            type: STATUS.ERROR,
            title: NotificationErrorTitle.UNKNOW_ERROR,
            message: e,
          });
        })
        .finally(() => {
          this.setIsLoading(false);
          this.closeModalMember('block');
          this.closeModalMember('unBlock');
          this.setUserId('');
        });
    }, 300);
  };

  deleteInviteMember = async (email: string) => {
    const params = { email: email };
    this.setLoadingInviteCell(email, 'status');
    setTimeout(async () => {
      await this.execRequest<ResponseModel_Dict_>(httpClient2.delete('/api/v1/user/invite/email', { params }))
        .then(_ => {
          const index = this.invites.findIndex((el: WSInvites) => el.email === email);
          runInAction(() => this.invites.splice(index, 1));
          this.addNotification({
            type: STATUS.SUCCESS,
            title: NotificationTitle.INVITE_DELETE,
          });
        })
        .catch(e => {
          console.log(e);
          this.addNotification({
            type: STATUS.ERROR,
            title: NotificationErrorTitle.UNKNOW_ERROR,
          });
        })
        .finally(() => {
          this.setLoadingInviteCell(email, '');
        });
    }, 300);
  };

  deleteMember = async (userId: string) => {
    this.setIsLoading(true);
    setTimeout(async () => {
      await this.execRequest<ResponseModel_Dict_>(httpClient2.delete(`/api/v1/user/access/${userId}`))
        .then(resp => {
          const index = this.members.findIndex((el: WSMembers) => el._id === userId);
          runInAction(() => this.members.splice(index, 1));

          this.addNotification({
            type: STATUS.SUCCESS,
            title: NotificationTitle.MEMBER_DELETE,
          });
        })
        .catch(e => {
          console.log(e);
          this.addNotification({
            type: STATUS.ERROR,
            title: NotificationErrorTitle.UNKNOW_ERROR,
          });
        })
        .finally(() => {
          this.setIsLoading(false);
          this.closeModalMember('remove');
          this.setUserId('');
        });
    }, 300);
  };

  getDrawerData = async (userId: string) =>
    this.execRequestAllSettled([
      httpClient2.get<TWorkspaceAccessResp>(`/api/v1/user/access/${userId}`),
      httpClient2.get<TWorkspaceTagsData>('/api/v1/user/workspace/tags'),
    ])
      // @ts-ignore
      .then(this.loadSuccess);

  private loadSuccess = ([accessResp, tagResp]: [
    accessResp: PromiseSettledResult<TWorkspaceAccessResp>,
    tagResp: PromiseSettledResult<TWorkspaceTagsData>,
  ]) => {
    const data = {} as TWorkspaceDrawerData;
    data.userData = accessResp.status === 'fulfilled' ? accessResp.value : ({} as TWorkspaceAccessResp);
    data.workspaceTags = tagResp.status === 'fulfilled' ? tagResp.value : ({} as TWorkspaceAccessResp);

    runInAction(() => (this.drawerData = data));
  };

  setInitialDrawerData = () =>
    runInAction(() => {
      this.initialDrawerData = this.drawerData;
    });

  setIsDrawerDataChanged = (isDrawerDataChanged: boolean) =>
    runInAction(() => (this.isDrawerDataChanged = isDrawerDataChanged));

  isChanged(initialData: any, drawerData: any): boolean {
    function compareObjects(obj1: any, obj2: any): number {
      const key1 = JSON.stringify(obj1);
      const key2 = JSON.stringify(obj2);
      if (key1 < key2) {
        return -1;
      } else if (key1 > key2) {
        return 1;
      } else {
        return 0;
      }
    }

    function isArrayDifferent(arr1: any[], arr2: any[]): boolean {
      if (arr1.length !== arr2.length) {
        return true;
      }
      const sortedArr1 = arr1.slice().sort(compareObjects);
      const sortedArr2 = arr2.slice().sort(compareObjects);

      return !sortedArr1.every((item, index) => {
        // Костыль: При добавлении команды в объекте передаются дополнительные поля, которых нет в объектах уже добавленных команд.
        // Чтобы это обойти, исключаем эти поля из проверки.
        const ignoredFields = [
          'role',
          'leader_id',
          'member_ids',
          'project_ids',
          'hidden',
          'closed',
          'key',
          'cellLoadingName',
        ];
        const item1 = { ...item };
        const item2 = { ...sortedArr2[index] };
        ignoredFields.forEach(field => {
          delete item1[field];
          delete item2[field];
        });
        return isEqual(item1, item2);
      });
    }

    for (const key in initialData) {
      const initialValue = initialData[key];
      const drawerValue = drawerData[key];
      const isArray = Array.isArray(initialValue);

      // 1 сравниваем свойства объектов, в которых лежат массивы
      if (isArray && isArrayDifferent(initialValue, drawerValue)) {
        return true;
      }

      // 2 сравниваем все оставшиеся значения: строки и булевы значения
      else if (!isArray && initialValue !== drawerValue) {
        return true;
      }
    }
    return false;
  }

  editDrawerData = (changedData: TWorkspaceDrawerData) =>
    runInAction(() => {
      this.drawerData = changedData;
      this.isDrawerDataChanged = this.isChanged(this.initialDrawerData.userData, this.drawerData.userData);
    });

  editDataMember = (userId: string, data: TWorkspaceAccessResp) => {
    this.setIsLoading(true);

    const editData: AccessEditMemberReq = {
      tag_account: data.tag_account,
      tag_ad_plan: data.tag_ad_plan,
      status: data.status,
      role: data.role,
      team_ids: data.teams.map(el => el._id),
    } as AccessEditMemberReq;

    data.role === 'wsrole:owner' && delete editData.role;

    if (data.role === 'wsrole:guest') {
      delete editData.tag_account;
      delete editData.tag_ad_plan;
      delete editData.team_ids;
    }

    this.execRequest<TClientInfoResp>(httpClient2.post(`/api/v1/user/access/${userId}`, editData))
      .then(_ => {
        this.addNotification({
          type: STATUS.SUCCESS,
          title: NotificationTitle.MEMBER_EDIT,
        });
      })
      .catch(e => {
        this.addNotification({
          type: STATUS.ERROR,
          title: NotificationErrorTitle.UNKNOW_ERROR,
          message: e,
        });
      })
      .finally(() => {
        this.userId = undefined;
        this.getWSMembers();
        this.setIsLoading(false);
      });
  };

  resetStore = () =>
    runInAction(() => {
      this.members = [];
      this.invites = [];
      this.isTableLoading = false;
      this.isLoading = false;
      this.hasLoadingError = false;
      this.wsDataDrawer = defaultWsDataDrawer;
      this.editMemberModals = defaultEditMemberModals;
      this.drawerData = defaultDrawerData;
      this.userId = undefined;
      this.drawerLoading = false;
    });
}
