import { ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import React from 'react';
import { HightLightText } from '../../../hightlight-text';
import { EllipsisTooltip } from '../../../tooltip';
import { Va, Mt } from './networks';
import classes from './style.module.scss';

export function networkRenderer(network: string, classname: string, disabled: boolean) {
  switch (network) {
    case 'mt':
      return <Mt className={classname} disabled={disabled} />;
    case 'va':
      return <Va className={classname} disabled={disabled} />;
  }
}

export const NameRenderer = (props: ICellRendererParams) => {
  const {
    network,
    name,
    clientId,
    orgHierarchy,
    parentName,
    parentId,
    connectionType,
    campaignName,
    campaignId,
    adsActive,
    adsArchived,
    adsPaused,
    syncStatusCode,
    integrationStatusCode,
  } = props.data || {};

  // @ts-ignore
  const filterValue = props.api.filterManager.quickFilter;

  if (orgHierarchy && orgHierarchy.length > 1) {
    return (
      <div className={classes.orgHierarchyContainer}>
        <div className={clsx(classes.name, classes.trough)}>
          <EllipsisTooltip
            tooltipMessage={clientId !== parentId ? `${connectionType} : ${parentName}` : connectionType}
          >
            <span className={classes.ellipsis}>
              {connectionType}
              {clientId !== parentId && (
                <>
                  : <HightLightText textEllips={true} text={parentName} filterValue={filterValue} />
                </>
              )}
            </span>
          </EllipsisTooltip>
        </div>

        <EllipsisTooltip tooltipMessage={parentId}>
          <div
            className={clsx(
              classes.clientId,
              (syncStatusCode === 'disabled' || integrationStatusCode === 'deleted') && classes.statusDisabled,
            )}
          >
            <HightLightText textEllips={true} text={parentId} filterValue={filterValue} />
          </div>
        </EllipsisTooltip>
      </div>
    );
  }

  return (
    <>
      <div className={classes.name}>
        {networkRenderer(
          network,
          classes.networkIcon,
          syncStatusCode === 'disabled' || integrationStatusCode === 'deleted' ? true : false,
        )}

        <EllipsisTooltip tooltipMessage={name ? name : campaignName}>
          <HightLightText textEllips={true} text={name ? name : campaignName} filterValue={filterValue} />
        </EllipsisTooltip>
      </div>
      <div
        className={clsx(
          classes.clientId,
          (syncStatusCode === 'disabled' || integrationStatusCode === 'deleted') && classes.statusDisabled,
        )}
      >
        {clientId && (
          <EllipsisTooltip tooltipMessage={clientId}>
            <HightLightText textEllips={true} text={clientId} filterValue={filterValue} />
          </EllipsisTooltip>
        )}
        {campaignId && (
          <HightLightText
            text={`MT ID: ${campaignId} | Объявл. ${adsActive || '-'}/${adsPaused || '-'}/${adsArchived || '-'}`}
            filterValue={filterValue}
          />
        )}
      </div>
    </>
  );
};
