import { int } from '@plarin/utils';
import { getCurrencySymbol, TUsedCurrencies } from '../../../inputs';

enum StrategyTextEnum {
  CONVERSION_OPTIMIZATION = 'Оптимизация конверсий',
  OPTIMIZING_NUMBER_CONVERSIONS = 'Оптимизация количества конверсий',
  CLICK_OPTIMIZATION = 'Оптимизация кликов',
  OPTIMIZING_NUMBER_CLICKS = 'Оптимизация количества кликов',
  OPTIMIZING_NUMBER_OF_APP_INSTALLATIONS = 'Оптимизация количества установок приложения',
  OPTIMIZATION_PROFITABILITY = 'Оптимизация рентабельности',
  OPTIMIZATION_THE_SHARE_OF_ADVERTISING_EXPENSES = 'Оптимизация доли рекламных расходов',
  IMPRESSIONS_ARE_DISABLED = 'Показы отключены',
  MANUAL_BID_MANAGEMENT = 'Ручное управление ставками с оптимизацией',
  AVERAGE_CPA_PER_CAMPAIGN = 'Оптимизация количества конверсий',
  AVERAGE_CPC_PER_CAMPAIGN = 'Оптимизация количества кликов',
}

export const strategyText = {
  AVERAGE_CPA: StrategyTextEnum.CONVERSION_OPTIMIZATION,
  AVERAGE_CPA_PER_CAMP: StrategyTextEnum.OPTIMIZING_NUMBER_CONVERSIONS,
  AVERAGE_CPA_PER_FILTER: StrategyTextEnum.OPTIMIZING_NUMBER_CONVERSIONS,
  AVERAGE_CPC: StrategyTextEnum.CLICK_OPTIMIZATION,
  AVERAGE_CPC_PER_CAMP: StrategyTextEnum.OPTIMIZING_NUMBER_CLICKS,
  AVERAGE_CPC_PER_FILTER: StrategyTextEnum.OPTIMIZING_NUMBER_CLICKS,
  AVERAGE_CPI: StrategyTextEnum.OPTIMIZING_NUMBER_OF_APP_INSTALLATIONS,
  AVERAGE_ROI: StrategyTextEnum.OPTIMIZATION_PROFITABILITY,
  AVERAGE_CRR: StrategyTextEnum.OPTIMIZATION_THE_SHARE_OF_ADVERTISING_EXPENSES,
  PAY_FOR_CONVERSION: StrategyTextEnum.CONVERSION_OPTIMIZATION,
  PAY_FOR_CONVERSION_CRR: StrategyTextEnum.OPTIMIZATION_THE_SHARE_OF_ADVERTISING_EXPENSES,
  PAY_FOR_CONVERSION_PER_CAMPAIGN: StrategyTextEnum.OPTIMIZING_NUMBER_CONVERSIONS,
  PAY_FOR_CONVERSION_PER_FILTER: StrategyTextEnum.OPTIMIZING_NUMBER_CONVERSIONS,
  PAY_FOR_INSTALL: StrategyTextEnum.CONVERSION_OPTIMIZATION,
  WB_MAXIMUM_APP_INSTALLS: StrategyTextEnum.OPTIMIZING_NUMBER_OF_APP_INSTALLATIONS,
  WB_MAXIMUM_CLICKS: StrategyTextEnum.CLICK_OPTIMIZATION,
  WB_MAXIMUM_CONVERSION_RATE: StrategyTextEnum.CONVERSION_OPTIMIZATION,
  WEEKLY_CLICK_PACKAGE: StrategyTextEnum.CLICK_OPTIMIZATION,
  SERVING_OFF: StrategyTextEnum.IMPRESSIONS_ARE_DISABLED,
  HIGHEST_POSITION: StrategyTextEnum.MANUAL_BID_MANAGEMENT,
  AVERAGE_CPA_PER_CAMPAIGN: StrategyTextEnum.AVERAGE_CPA_PER_CAMPAIGN,
  AVERAGE_CPC_PER_CAMPAIGN: StrategyTextEnum.AVERAGE_CPC_PER_CAMPAIGN,
};

export const period = {
  noBudget: 'Бюджет не задан',
  day: 'в день',
  weekly: 'в неделю',
};

export const valueBudget = (dailyBudgetAmount: string, weeklySpendLimit: string, accountCurrency: TUsedCurrencies) => {
  if (dailyBudgetAmount !== '' && +dailyBudgetAmount !== 0) {
    return ` ${int(dailyBudgetAmount)} ${getCurrencySymbol(accountCurrency)} ${period.day}`;
  }
  if (weeklySpendLimit !== '' && +weeklySpendLimit !== 0) {
    return `${int(weeklySpendLimit)} ${getCurrencySymbol(accountCurrency)} ${period.weekly}`;
  }
  return period.noBudget;
};
