import { StrategyEditBid, GridApi, WhatIsEditedInStrategy, TRows, getFormType } from '@plarin/inputs';
import { isUrlProd } from '@plarin/utils';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { useAppDataStore } from '../../app-data-provider';
import classes from './style.module.scss';

type EditBudgetProps = {
  close: () => void;
  selectedRows: TRows;
  gridApi: GridApi;
};

export const EditBudget = observer(({ close, selectedRows, gridApi }: EditBudgetProps) => {
  // Не удалять. Это постоянно нужно на деве!
  !isUrlProd && selectedRows.forEach(row => !isUrlProd && console.log(toJS(row)));

  const {
    manageVK: { editStrategyBid, getAvailableAds },
  } = useAppDataStore();

  if (selectedRows.length === 1 && getFormType(selectedRows[0]) === 'StrategyEditBid') {
    return (
      <StrategyEditBid
        close={close}
        selectedRows={selectedRows}
        onSaveAction={editStrategyBid}
        gridApi={gridApi}
        getAvailableAds={getAvailableAds}
        isOnlyBudgetEditor={true}
        whatIsEdited={WhatIsEditedInStrategy.limit}
      />
    );
  }

  // непредвиденные исключения, если в форму почему-то передают не те данные
  return <div className={classes.wrap}>Произошла ошибка.</div>;
});
