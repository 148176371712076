export const daysAbbreviation = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const stylesDatePicker = {
  marginRight: 0,
  display: 'none',

  '.MuiPickersDay-root:hover': {
    background: 'none',
    border: '1px solid #214ECB',
  },

  '.MuiPickersDay-root.Mui-selected:hover': {
    backgroundColor: '#24498F',
  },

  '.MuiTypography-caption': {
    color: '#232431',
  },

  '.css-169iwlq-MuiCalendarPicker-root': {
    padding: '0 16px',
    height: 'fit-content',
    maxHeight: '356px',
  },

  '.MuiPickerStaticWrapper-root div:first-of-type': {
    position: 'relative',
  },

  '.MuiPickerStaticWrapper-root , .MuiPickerStaticWrapper-root div': {
    maxWidth: '100%',
    minWidth: 0,
    boxSizing: 'border-box',
  },

  '.MuiMonthPicker-root, .MuiPickerStaticWrapper-root .css-2bahbb .css-1v994a0': {
    margin: 0,
  },

  '.MuiPickerStaticWrapper-root .MuiIconButton-edgeEnd': {
    left: 0,
    top: 0,
    position: 'absolute',
  },

  '.MuiPickerStaticWrapper-root .MuiIconButton-edgeStart': {
    right: 0,
    top: 0,
    position: 'absolute',
  },

  '.MuiPickerStaticWrapper-root .css-1dozdou': {
    paddingRight: 0,
  },

  '.MuiPickerStaticWrapper-root .css-2bahbb': {
    margin: '0 auto',
    display: 'flex',
    gap: '5px',
    width: 'fit-content',
    color: '#214ECB',
    fontWeight: 700,
    fontSize: '16px',
    paddingLeft: 0,
  },

  '.MuiPickerStaticWrapper-root .css-2bahbb .MuiButtonBase-root': {
    display: 'none',
  },

  '.PrivatePickersMonth-root': {
    display: 'block',
  },

  '.MuiPickerStaticWrapper-root .css-2bahbb  .css-1v994a0::first-letter, .PrivatePickersMonth-root::first-letter': {
    textTransform: 'uppercase',
  },

  '.PrivatePickersSlideTransition-root': {
    minHeight: 0,
  },

  'span.MuiTypography-root.MuiTypography-caption:nth-of-type(5n) ~ span': {
    color: '#D81823',
  },
};
