import { Button } from '@mui/material';
import {
  VkIntegrationsIconDisabled,
  MyTargetIntegrationsIcon,
  VkAdsIntegrationsIcon,
  Typography,
} from '@plarin/design';
import React, { useCallback } from 'react';
import { IButtonProps, NetworkEnum } from './types';
import './integration-buttons.scss';
import { MainTooltip } from '../../tooltip/main-tooltip';

const isDisabled = (socialVariant: string): boolean => socialVariant === NetworkEnum.VKontakte;

const getContent = (socialVariant: string) => {
  switch (socialVariant) {
    case NetworkEnum.VkAds:
      return { icon: <VkAdsIntegrationsIcon />, name: 'VK Реклама' };
    case NetworkEnum.VKontakte:
      return { icon: <VkIntegrationsIconDisabled />, name: 'ВКонтакте' };
    case NetworkEnum.myTarget:
      return { icon: <MyTargetIntegrationsIcon />, name: 'myTarget' };
  }
};

export const IntegrationButton: React.FC<IButtonProps> = ({ socialVariant, action }) => {
  const actionWrapper = useCallback(() => {
    // не даст появиться дроверу с интерфейсом для подключения Facebook
    if (isDisabled(socialVariant)) {
      return;
    }
    action && action(socialVariant);
  }, [action, socialVariant]);
  const content = getContent(socialVariant);
  return (
    <MainTooltip
      tooltipMessage="Подключение аккаунтов ВКонтакте временно недоступно"
      followCursor={true}
      component="div"
      isVisible={isDisabled(socialVariant)}
    >
      <Button
        disableFocusRipple
        classes={{ root: isDisabled(socialVariant) ? 'IntegrationButtonRootDisabled' : 'IntegrationButtonRoot' }}
        onClick={actionWrapper}
        // disabled-hint={isDisabled(socialVariant) && 'Подключение аккаунтов ВКонтакте временно недоступно'}
      >
        {content?.icon}
        <Typography size="AuthContent" weight={700} color={isDisabled(socialVariant) ? 'TextDisabled' : 'Black'}>
          {content?.name}
        </Typography>
      </Button>
    </MainTooltip>
  );
};
