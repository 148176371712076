import { Typography } from '@plarin/design';
import { ArrowTooltip } from '@plarin/inputs';
import React from 'react';
import './missing-sms-info.scss';

export const MissingSmsInfo: React.FC<{
  children: NonNullable<React.ReactNode>;
}> = ({ children }) => (
  <ArrowTooltip content={children} component="span" componentProps={{ className: 'missingSmsInfo__wrapper' }}>
    <Typography
      color="linkPrimary"
      size="main"
      align="center"
      component="span"
      componentProps={{ className: 'missingSmsInfo' }}
    >
      Почему не пришла СМС?
    </Typography>
  </ArrowTooltip>
);
