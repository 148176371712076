import clsx from 'clsx';
import React from 'react';

export interface SliderPaginationProps {
  count: number;
  index: number;
  onSelect: (index: number) => void;
  className?: string;
}

export const SliderPagination: React.VFC<SliderPaginationProps> = function SliderPagination({
  count,
  index,
  onSelect,
  className,
}) {
  return (
    <div className={clsx('sliderPagination', className)}>
      {[...Array.from({ length: count }).keys()].map(i => (
        <button
          type="button"
          aria-label={`slide ${i}`}
          className={clsx('sliderPagination__page', {
            selected: i === index,
          })}
          key={i}
          onClick={() => onSelect(i)}
          tabIndex={-1}
        >
          <span className="sliderPagination__page__point" />
        </button>
      ))}
    </div>
  );
};
