import { Box } from '@mui/material';
import React from 'react';
import { TabPanelProps } from './tabs-props';
import classes from './tabs.module.scss';

export const TableTabPanel = ({ children, hidden }: TabPanelProps) => (
  <div role="tabpanel" hidden={hidden} className={classes.tabPanel}>
    {!hidden && <Box className={classes.tabPanelContent}>{children}</Box>}
  </div>
);
