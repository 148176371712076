import { Modal as MUIModal } from '@mui/material';
import {
  ArchiveIcon,
  CloseIcon,
  CompletedIcon,
  DeleteIcon,
  EmailReadIcon,
  LockIcon,
  PersonIcon,
  Typography,
  UnLockIcon,
  AlertIcon,
  EditIcon,
  PlayIcon,
  PauseIcon,
  SaveIcon,
} from '@plarin/design';
import { typografElem } from '@plarin/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Button, IconButton } from '../buttons';
import { EllipsisTooltip } from '../tooltip/ellipsis-tooltip';
import { ModalProps } from './modal-props';
import classes from './style.module.scss';

export const Modal: React.FC<ModalProps> = ({
  children,
  open,
  closeModal,
  title,
  firstAction,
  secondAction,
  width,
  disabledActions,
  disabledExit,
  workspaceHeader,
  icon,
}) => {
  const isDangerClass =
    workspaceHeader === 'archive' ||
    workspaceHeader === 'block' ||
    workspaceHeader === 'remove' ||
    workspaceHeader === 'untie' ||
    workspaceHeader === 'edit' ||
    workspaceHeader === 'pause';

  const HeaderIcon = () => {
    switch (workspaceHeader) {
      case 'remove':
        return <DeleteIcon color="white" />;
      case 'block':
        return <LockIcon color="white" />;
      case 'unBlock':
        return <UnLockIcon color="white" />;
      case 'archive':
        return <ArchiveIcon color="white" />;
      case 'unArchive':
        return <CompletedIcon color="white" />;
      case 'person':
        return <PersonIcon color="white" />;
      case 'untie':
        return <AlertIcon color="white" />;
      case 'edit':
        return <EditIcon color="white" />;
      case 'pause':
        return <PauseIcon color="white" />;
      case 'saveExit':
        return <SaveIcon color="white" />;
    }
  };

  const Header = () => {
    if (workspaceHeader === 'invite') {
      return (
        <div className={classes.headerTitle}>
          <div className={classes.iconBlock}>
            <EmailReadIcon color="white" />
          </div>

          <span className={classes.linesHeader}>
            <Typography weight={600} size="ModalTitle" color="TextPrimary">
              Пригласить коллег
            </Typography>
            <EllipsisTooltip tooltipMessage={`${title}`}>
              <Typography color="TextSecondary" ellips={true}>
                {title}
              </Typography>
            </EllipsisTooltip>
          </span>
        </div>
      );
    } else {
      return (
        <div className={classes.headerTitle}>
          <div className={clsx(classes.iconBlock, isDangerClass && classes.danger)}>{icon ? icon : HeaderIcon()}</div>
          <Typography weight={600} size="ModalTitle" color="TextPrimary">
            {title}
          </Typography>
        </div>
      );
    }
  };

  const [textRef, setTextRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (textRef) {
      typografElem(textRef);
    }
  }, [textRef]);

  return (
    <MUIModal
      onClose={disabledExit ? () => {} : closeModal}
      open={open}
      BackdropProps={{ classes: { root: classes.modalBackdrop }, transitionDuration: 0 }}
      classes={{ root: classes.modal }}
      tabIndex={-1}
    >
      <div className={clsx(classes.content)}>
        <div className={clsx(classes.workspaceHeader)}>
          {Header()}

          {!disabledExit && (
            <IconButton onClick={closeModal} className={classes.closeButton}>
              <CloseIcon size={24} />
            </IconButton>
          )}
        </div>

        {workspaceHeader === 'invite' || workspaceHeader === 'profile' ? (
          <div className={classes.children}>{children}</div>
        ) : (
          <div className={classes.children} ref={ref => setTextRef(ref)}>
            {children}
          </div>
        )}

        {!disabledActions && (
          <div className={classes.actionBar}>
            {firstAction && (
              <Button
                size="small"
                variant="filled"
                color={isDangerClass ? 'danger' : 'primary'}
                onClick={firstAction.action}
                loading={firstAction.isLoading}
              >
                {firstAction.actionTitle}
              </Button>
            )}
            {secondAction && (
              <Button
                disabled={firstAction?.isLoading}
                size="small"
                variant={isDangerClass ? 'lightOutlined' : 'outlined'}
                color={isDangerClass ? 'light' : 'primary'}
                onClick={secondAction.action}
              >
                {secondAction.actionTitle}
              </Button>
            )}
          </div>
        )}
      </div>
    </MUIModal>
  );
};
