import { Typography } from '@plarin/design';
import { int } from '@plarin/utils';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import React, { useState } from 'react';
import { getFormType } from '../../../edit-bid';
import { getSingleBidMode, StrategyBidModes } from '../../../edit-bid/strategy-form-utils';
import { getItemsType } from '../../../inputs';
import { getCurrencySymbol } from '../../../inputs';
import { Popover } from '../../../popover';
import { SkeletonTableBlock } from '../../../preloader';
import classes from './style.module.scss';

interface TComponent extends React.FunctionComponent {
  gridApi: GridApi;
  close: () => void;
}

interface TBidCellRendererParams extends ICellRendererParams {
  Component: TComponent;
}

export const BidCellRenderer = (props: TBidCellRendererParams) => {
  const { valueFormatted, data, Component, api } = props;
  const { bidType, cellLoadingName, bidCurrent } = data;
  const [isFocused, setIsFocused] = useState(false);
  const archived: Boolean = data.status.includes('Архивн');

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: MouseEvent, ref: HTMLDivElement) => {
    event.stopPropagation();
    if (!archived) {
      setAnchorEl(ref);
      setIsFocused(true);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsFocused(false);
  };

  const open = Boolean(anchorEl);

  if (cellLoadingName === 'bid' || bidCurrent === 'loading')
    return <SkeletonTableBlock withCheckbox isReversLong height={17} isRightContent width={100} />;

  const fixedBidCurrent = (
    <>
      <div
        className={clsx(classes.dashed, archived && classes.dashedArchived)}
        ref={ref => {
          if (!ref) return;
          ref.onclick = event => handleClick(event, ref);
        }}
      >
        {archived ? (
          <Typography color="textDisable">{valueFormatted}</Typography>
        ) : (
          <Typography color={!isFocused ? 'LinkPrimary' : ''}>{valueFormatted}</Typography>
        )}
      </div>
      <Typography color={archived ? 'textDisable' : 'TextSecondary'} size="TableRowSubHeader">
        {bidType}
      </Typography>
    </>
  );

  const strategyBidCurrent = () => {
    const currencySymbol = data.accountCurrency ? getCurrencySymbol(data.accountCurrency) : '';

    const header =
      getSingleBidMode(data) === StrategyBidModes.maxPrice
        ? `Пред. цена${data.maxPrice && ` (${int(data.maxPrice)}\u00a0${currencySymbol})`}`
        : 'Мин. цена';

    const bidType = data.bidType ? `${data.bidType}` : '';

    const formatObjective = () => {
      if (data.adPlanObjective) {
        if (data.adPlanObjective === 'site_conversions') return 'Реклама сайта';
        if (data.adPlanObjective === 'leadads') return 'Лид-форма';
        if (data.adPlanObjective === 'appinstalls') return 'Приложение (установка)';
        if (data.adPlanObjective === 'reengagement') return 'Приложение (вовлечение)';
        if (data.adPlanObjective === 'storeproductssales') return 'Каталог товаров';
        if (data.adPlanObjective === 'socialengagement') return 'Сообщество в ВК';
      }

      return '';
    };

    const subheader =
      `${formatObjective()}` + (data.adPlanObjective === 'site_conversions' ? `${bidType && ' — '}${bidType}` : '');

    return (
      <>
        {data.budgetOptimizationLevel === 'campaign' && getItemsType(window.location.pathname) === 'ad_plan' ? (
          /* Если это ячейка для вкладки "Кампании" для кампании (адплана) с оптимизацией бюджетов на уровне группы, то в ячейке не пишем название стратегии (мы ее даже не знаем, статегия определена для групп)*/
          <div className={clsx(archived && classes.dashedArchived)}>
            <Typography color={archived ? 'textDisable' : 'TextPrimary'}>Стратегия группы</Typography>
          </div>
        ) : (
          <div
            className={clsx(classes.dashed, archived && classes.dashedArchived)}
            ref={ref => {
              if (!ref) return;
              ref.onclick = event => handleClick(event, ref);
            }}
          >
            <Typography color={archived ? 'textDisable' : !isFocused ? 'LinkPrimary' : ''}>{header}</Typography>
          </div>
        )}
        <Typography color={archived ? 'textDisable' : 'TextSecondary'} size="TableRowSubHeader">
          {subheader}
        </Typography>
      </>
    );
  };

  const PlugAutoPrice = (
    <div className={clsx(classes.rightAlign, archived && classes.dashedArchived)}>
      <Typography color={archived ? 'textDisable' : 'TextPrimary'}>
        Автоуправление
        <br /> ценой
      </Typography>
    </div>
  );

  // const PlugMaxShows = (
  //   <div className={clsx(archived && classes.dashedArchived)}>
  //     <Typography color={archived ? 'textDisable' : 'TextPrimary'}>Максимум показов</Typography>
  //   </div>
  // );

  const PlugMarginalRate = () => {
    const currencySymbol = data.accountCurrency ? getCurrencySymbol(data.accountCurrency) : '';

    return (
      <>
        <div className={clsx(archived && classes.dashedArchived)}>
          <Typography color={archived ? 'textDisable' : 'TextPrimary'}>
            Пред. цена{data.maxPrice && ` (${int(data.maxPrice)}\u00a0${currencySymbol})`}
          </Typography>
        </div>
      </>
    );
  };

  return (
    <div className={clsx(classes.cellWrapper, isFocused && classes.cellFocused)}>
      {getFormType(data) === 'FixedEditBid' && fixedBidCurrent}
      {getFormType(data) === 'StrategyEditBid' && strategyBidCurrent()}

      {/* Неклибальные ячейки для рекламных объектов, редактирование которых мы не поддерживаем */}
      {getFormType(data) === 'PlugAutoPrice' && PlugAutoPrice}
      {/* {getFormType(data) === 'PlugMaxShows' && PlugMaxShows} */}
      {getFormType(data) === 'PlugMarginalRate' && PlugMarginalRate()}

      <Popover
        classNameWrapper={classes.popoverWrapper}
        isListLeft
        isOpen={open}
        anchorEl={anchorEl?.parentElement || null}
        setIsOpen={handleClose}
        marginTop={3}
        zIndex={1200}
      >
        <Component
          // @ts-ignore
          data={props.data}
          close={handleClose}
          gridApi={api}
        />
      </Popover>
    </div>
  );
};
