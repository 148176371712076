import { Preloader } from '@plarin/design';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { MainTooltip } from '../tooltip';
import { IconButton } from './icon-button';
import classes from './icon-button.module.scss';

type ReloadButtonProps = {
  action: () => void;
  loading?: boolean;
  className?: string;
  icon: JSX.Element;
  textTooltip: JSX.Element | string;
  placement?: 'top' | 'bottom';
  active?: boolean;
  isReloadButton?: boolean;
};

export const ActionTableButton = ({
  action,
  loading,
  className,
  textTooltip,
  icon,
  placement = 'top',
  active,
  isReloadButton,
}: ReloadButtonProps) => {
  const handleKeyBindings = (event: KeyboardEvent) => {
    const key = event.key.toLowerCase();
    const ctrlOrMeta = event.ctrlKey || event.metaKey;

    if (isReloadButton && ctrlOrMeta && (key === 'r' || key === 'к')) {
      event.preventDefault();
      action();
    }
  };

  const isMac = () => {
    return navigator.userAgent.indexOf('Mac OS X') != -1;
  };

  const reloadTooltipText = (
    <div>
      Обновить
      <br />({isMac() ? 'CMD' : 'CTRL'} + R)
    </div>
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyBindings);
    return () => {
      document.removeEventListener('keydown', handleKeyBindings);
    };
  }, []);

  return (
    <MainTooltip
      isTypograf={false}
      tooltipMessage={isReloadButton ? reloadTooltipText : textTooltip}
      isVisible={!loading}
      component="span"
      placement={placement}
    >
      <IconButton
        onClick={action}
        className={clsx(className, loading && classes.buttonWidth)}
        disabled={loading}
        active={active}
      >
        {loading ? <Preloader size={16} thickness={3} /> : icon}
      </IconButton>
    </MainTooltip>
  );
};
