import { AvatarIcon, Typography } from '@plarin/design';
import { ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import React from 'react';
import classes from './style.module.scss';

export const NameEmailUser = (props: ICellRendererParams) => {
  const { fname, lname, email } = props.data || {};

  return (
    <div className={classes.container}>
      <AvatarIcon />
      <div className={classes.content}>
        <div className={classes.name}>
          {fname ? fname : '-'} {lname ? lname : '-'}
        </div>
        <div className={classes.email}>{email ? email : '-'}</div>
      </div>
    </div>
  );
};
