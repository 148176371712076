import { ErrorIcon, Typography } from '@plarin/design';
import { ManageVkTabNameEnum, isUrlProd } from '@plarin/utils';
import type { GridApi, ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import { toJS } from 'mobx';
import React, { useState } from 'react';
import { HightLightText } from '../../../hightlight-text';
import { HintPopup } from '../../../hint-popup';
import { CellLoader } from '../../../preloader';
import { EllipsisTooltip, MainTooltip } from '../../../tooltip';
import { Column } from '../../table';
import { copy } from '../../utils';
import { StatusColorEnum } from '../status-renderer-yd';
import { RenderIcon } from './IconsToASecondLine';
import { AdsPreview } from './components/ads-preview';
import classes from './style.module.scss';

interface TwoLinesRendererProps extends ICellRendererParams {
  dateFormatter?: (str: string | null) => string;
  currencyFormatter?: (str: string, type: string) => string;
  bullet?: string;
  reason?: string;
  first: string;
  firstBold?: boolean;
  second: string;
  nextSecond?: string;
  secondCopy?: boolean;
  type?: string;
  alternativeBullet?: string;
  canHasError?: boolean;
  errorTitle?: string;
  textHint?: string | JSX.Element;
  canHasBullet?: boolean;
  tabName?: string;
  createSwitchButton?: (tabName: ManageVkTabNameEnum, data: any, gridApi: GridApi) => React.ReactNode;
  isAdsPreview?: boolean;
  disableGroupCell?: true;
  icon?: JSX.Element;
  textTooltip?: string;
  textIcon?: string;
  visibleIcon?: string;
  isQuickFilter?: boolean;
  ActionsName?: React.FC<{
    name: string;
    tabName?: string;
    data: any;
    gridApi: GridApi;
    column: Column;
    setIsHoverCell: (hover: boolean) => void;
    disableEditName?: boolean;
  }>;
  disableEditName?: boolean;
}

const tooltipText = (isCopied: boolean) => (isCopied ? 'Скопировано!' : 'Нажмите, чтобы скопировать');

export const TwoLinesRenderer = ({
  dateFormatter,
  currencyFormatter,
  bullet,
  first,
  firstBold,
  second,
  secondCopy,
  type,
  alternativeBullet,
  canHasError,
  createSwitchButton,
  isAdsPreview,
  disableGroupCell,
  icon,
  textTooltip,
  textIcon,
  visibleIcon,
  nextSecond,
  errorTitle,
  textHint,
  isQuickFilter = true,
  ActionsName,
  disableEditName,
  ...props
}: TwoLinesRendererProps) => {
  const { data } = props;
  const isDisabled = data.syncStatusCode === 'disabled';

  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const [isHoverCell, setIsHoverCell] = useState(false);

  const updateData = (value: boolean) => {
    setIsShowTooltip(value);
  };
  const resetIsCopied = () => setIsCopied(false);

  // @ts-ignore
  const filterValue = isQuickFilter && props.api.filterManager.quickFilter;

  if (new Set(Object.keys(data)).has('orgHierarchy') && !data.orgHierarchy && !disableGroupCell)
    return <Typography weight={600}>{data[first]}</Typography>;

  if (alternativeBullet && data.syncStatusCode === 'loading') return <CellLoader />;

  const editNameIsDisabled = disableEditName || (alternativeBullet && data[alternativeBullet]?.includes('Архивн'));

  return (
    <div
      className={clsx(classes.container, canHasError && classes.containerHasHint)}
      onMouseEnter={() => setIsHoverCell(true)}
      onMouseLeave={() => setIsHoverCell(false)}
      onClick={() => !isUrlProd && console.log(toJS(data))}
    >
      <div
        className={clsx(
          classes.leftBlock,
          createSwitchButton && classes.leftBlockSpaceBetween,
          visibleIcon && classes.withIcon,
        )}
      >
        {bullet && alternativeBullet && (
          <span
            className={clsx(
              data[alternativeBullet] === 'disabled' ? classes[data[alternativeBullet]] : classes[data[bullet]],
              classes.bullet,
              classes.mba,
            )}
          />
        )}

        {isAdsPreview && <AdsPreview data={data} />}

        <div className={clsx(classes.first)}>
          {!currencyFormatter ? (
            <div className={classes.name}>
              <EllipsisTooltip
                classNameContainer={clsx(classes.firstValue, firstBold && classes.firstBold)}
                tooltipMessage={alternativeBullet && data[alternativeBullet] === 'disabled' ? 'Выключена' : data[first]}
              >
                <HightLightText
                  text={alternativeBullet && data[alternativeBullet] === 'disabled' ? 'Выключена' : data[first]}
                  filterValue={filterValue}
                  textEllips={true}
                />
              </EllipsisTooltip>
              {ActionsName && isHoverCell && data[first] && (
                <ActionsName
                  name={data[first]}
                  tabName={props.tabName}
                  data={data}
                  gridApi={props.api}
                  column={props.column as any as Column}
                  setIsHoverCell={setIsHoverCell}
                  disableEditName={editNameIsDisabled}
                />
              )}
            </div>
          ) : (
            <span>{currencyFormatter && currencyFormatter(data[first], 'RUB')}</span>
          )}
          {(data[second] || data[second] === null) && (
            <div
              className={clsx(
                classes.second,
                alternativeBullet &&
                  data[alternativeBullet] &&
                  (data[alternativeBullet] === 'disabled' ||
                    data[alternativeBullet].includes('Архивн') ||
                    data[alternativeBullet] === StatusColorEnum.ARCHIVED ||
                    data.syncStatusCode === 'disabled') &&
                  classes.errorGray,
              )}
            >
              {type && RenderIcon(data[type])}

              {secondCopy ? (
                <EllipsisTooltip
                  classNameContainer={classes.containerTooltip}
                  tooltipMessage={
                    isShowTooltip ? (dateFormatter ? dateFormatter(data[second]) : data[second]) : tooltipText(isCopied)
                  }
                  isShowTooltip={updateData}
                >
                  <span
                    className={classes.secondCopy}
                    ref={ref => {
                      if (!ref) return;
                      ref.onclick = event => {
                        copy(event, data[second]).then(() => setIsCopied(true));
                      };
                    }}
                    onMouseLeave={resetIsCopied}
                  >
                    <HightLightText
                      text={dateFormatter ? dateFormatter(data[second]) : data[second]}
                      filterValue={filterValue}
                      textEllips={true}
                    />
                  </span>
                </EllipsisTooltip>
              ) : (
                <>
                  <EllipsisTooltip
                    tooltipMessage={
                      dateFormatter
                        ? dateFormatter(data[second])
                        : nextSecond
                        ? `${data[second]} (${data[nextSecond]})`
                        : data[second]
                    }
                  >
                    <HightLightText
                      text={
                        dateFormatter
                          ? dateFormatter(data[second])
                          : nextSecond
                          ? `${data[second]} (${data[nextSecond]})`
                          : data[second]
                      }
                      filterValue={filterValue}
                      textEllips={true}
                    />
                  </EllipsisTooltip>
                </>
              )}
            </div>
          )}
        </div>
        {Object.keys(data).length
          ? createSwitchButton && (
              <div className={classes.switch}>
                {createSwitchButton(props.tabName as ManageVkTabNameEnum, { ...data }, props.api)}
              </div>
            )
          : null}
      </div>

      {visibleIcon && data[visibleIcon] && (
        <MainTooltip tooltipMessage={textTooltip!} isVisible={true} component="span" followCursor={true}>
          <span className={classes.cellIcon}>
            {icon}
            <Typography color="Tertiary" size="TableRowSubHeader" ellips>
              {textIcon}
            </Typography>
          </span>
        </MainTooltip>
      )}
      {canHasError && (
        <HintPopup
          isAutoHorizon
          classNameTrigger={classes.attentionAnchor}
          propsNameError={errorTitle}
          isHeaderBox
          textBody={textHint}
          triggerIcon={<ErrorIcon color={isDisabled ? 'disabled' : 'status_error'} />}
        />
      )}
    </div>
  );
};
