import { Typography } from '@plarin/design';
import { Checkbox, CustomList, EllipsisTooltip, HightLightText, SkeletonList } from '@plarin/inputs';
import { switchFilter } from '@plarin/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { TShortClientsData } from '../../../../../../types/reports/reports';
import classes from './custom-menu.module.scss';

type TCustomMenu = {
  open: boolean;
  loading: boolean;
  error: boolean;
  clients: TShortClientsData;
  addClient: (client: TShortClientsData[0]) => void;
  reportClients: TShortClientsData;
  deleteClient: (id: number) => void;
  filterValue: string;
};

export const CustomMenu = ({
  open,
  loading,
  error,
  clients,
  addClient,
  reportClients,
  deleteClient,
  filterValue,
}: TCustomMenu) => {
  const [rendererReportsClients, setRendererReportsClients] = useState(clients);
  const filterClients = (filterValue: any) => {
    return clients.filter(
      client =>
        (client.account_name && client.account_name.toLowerCase().includes(filterValue)) ||
        client.account_id?.toString().includes(filterValue) ||
        (client.account_username && client.account_username.toLowerCase().includes(filterValue)),
    );
  };

  useEffect(() => {
    setTimeout(() => {
      const wordsSearchMetrics = filterValue && filterValue.split(' ');
      if (filterClients(filterValue).length === 1) {
        setRendererReportsClients(filterClients(filterValue));
      } else if (wordsSearchMetrics && wordsSearchMetrics.length >= 2) {
        for (let index = 0; index < wordsSearchMetrics!.length; index++) {
          filterClients(wordsSearchMetrics![index] || '').length
            ? setRendererReportsClients(filterClients(wordsSearchMetrics![index] || ''))
            : setRendererReportsClients(filterClients(switchFilter(wordsSearchMetrics![index])));
        }
      } else {
        filterValue && filterClients(filterValue).length
          ? setRendererReportsClients(filterClients(filterValue))
          : setRendererReportsClients(filterClients(switchFilter(filterValue)));
      }
    }, 200);
  }, [filterValue, clients]);

  return (
    <div className={classes.anchor}>
      {open && (
        <div onMouseDown={e => e.preventDefault()} className={classes.backdrop}>
          <CustomList classProps={clsx(classes.menuWrapper, open && classes.menuWrapperShow)}>
            {loading ? (
              <div className={classes.preloaderWrapper}>
                <SkeletonList size="maxLong" height={17} sumList={7} />
              </div>
            ) : (
              <>
                {error ? (
                  <div className={classes.itemWrapper} onMouseDown={e => e.preventDefault()}>
                    <Typography size="Main" color="TextSecondary">
                      По вашему запросу не найден ни один рекламный кабинет.
                    </Typography>
                  </div>
                ) : (
                  <>
                    {rendererReportsClients.length ? (
                      <>
                        {rendererReportsClients.map(client => (
                          <CustomMenuItem
                            client={client}
                            key={client.account_id}
                            reportClients={reportClients}
                            addClient={addClient}
                            deleteClient={deleteClient}
                            filterValue={filterValue}
                          />
                        ))}
                      </>
                    ) : (
                      <div className={classes.blankMenuOverlay}>
                        <Typography size="Main" color="TextSecondary">
                          По вашему запросу не найден ни один рекламный кабинет.
                        </Typography>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </CustomList>
        </div>
      )}
    </div>
  );
};

export const CustomMenuItem = ({
  client,
  reportClients,
  addClient,
  deleteClient,
  filterValue,
}: {
  client: TShortClientsData[0];
  reportClients: TShortClientsData;
  addClient: (client: TShortClientsData[0]) => void;
  deleteClient: (id: number) => void;
  filterValue: string;
}) => {
  const checked = reportClients.some(e => e.account_id === client.account_id);
  const [isHover, setIsHover] = useState(false);
  const handleClick = () => {
    checked ? deleteClient(+client.account_id) : addClient(client);
  };

  return (
    <div
      className={clsx(classes.itemWrapper, isHover && classes.wrapperBackground)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={handleClick}
    >
      <div className={classes.checkboxBlock}>
        <Checkbox
          value={checked}
          size="small"
          className={clsx(classes.checkboxMargins, isHover && classes.hoverCheckbox)}
        />
        <EllipsisTooltip tooltipMessage={client.account_name || client.account_username}>
          <HightLightText
            color={isHover ? 'TextWhite' : ''}
            textEllips={true}
            text={client.account_name || client.account_username}
            filterValue={filterValue}
            size="Main"
          />
        </EllipsisTooltip>
      </div>
      <div className={classes.textBlock}>
        <Typography color={isHover ? 'TextWhite' : 'TextSecondary'} component="div" size="Main">
          (
          <HightLightText text={client.account_username} filterValue={filterValue} />
          {', '}
          <HightLightText text={client.account_id.toString()} filterValue={filterValue} />)
        </Typography>
      </div>
    </div>
  );
};
