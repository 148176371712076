import { Typography } from '@plarin/design';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import classes from './style.module.scss';

interface DateRangeRenderer extends ICellRendererParams {
  dateFormatter?: (str: string) => string;
  first?: string;
  second?: string;
  usedHours?: string;
  allHours?: string;
}

export const RangeDateRenderer = ({ data = {}, dateFormatter, node }: DateRangeRenderer) => {
  const { dateStart, dateStop } = data;
  const parseDateStart = dateFormatter && dateStart ? dateFormatter(dateStart) : 'Не определено';
  const parseDateStop = dateFormatter && dateStop ? dateFormatter(dateStop) : 'Никогда';

  if (node.rowPinned) {
    return null;
  }

  return (
    <div>
      <div className={classes.dates}>
        <Typography size="Caption">
          {parseDateStart} – {parseDateStop}
        </Typography>
      </div>
      <div className={classes.hoursCount}>
        <Typography size="TableRowSubHeader" color="TextTertiary">
          01.06.2021 – 01.06.2021
        </Typography>
      </div>
    </div>
  );
};
