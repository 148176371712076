import { Button, ScheduleButtonsProps } from '@plarin/inputs';
import React from 'react';
import { useAppDataStore } from '../app-data-provider';

export const ScheduleButtons = ({ disabledSave, requestData, onClose, gridApi }: ScheduleButtonsProps) => {
  const {
    manageVK: { changeSchedule },
  } = useAppDataStore();

  const onSave = () => {
    changeSchedule(requestData, gridApi).finally(() => onClose());
  };

  return (
    <>
      <Button disabled={disabledSave} variant="filled" size="small" color="primary" onClick={onSave}>
        Сохранить
      </Button>

      <Button variant="outlined" size="small" color="primary" onClick={onClose}>
        Отменить
      </Button>
    </>
  );
};
