import { Typography, VipIcon } from '@plarin/design';
import { Drawer, TitleOptions, TwoInputBlocks } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useAppDataStore } from '../../app-data-provider';
import { FooterWs } from './footer';
import classes from './style.module.scss';

interface IWorkspaceDrawer {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (e: boolean) => void;
}

export const WorkspaceDrawer = observer(({ isDrawerOpen, setIsDrawerOpen }: IWorkspaceDrawer) => {
  const {
    profileStore: { setWorkspaceCreate, workspaceCreate },
  } = useAppDataStore();
  const [isError, setIsError] = useState(false);
  const [isDisabledInput, setIsDisabledInput] = useState(false);
  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
    setIsDisabledInput(false);
  };

  return (
    <Drawer
      widthDrawer="large"
      isOpen={isDrawerOpen}
      title={<TitleOptions circle iconBlock={<VipIcon />} defaultTitleName="Создание пространства" />}
      closeDrawer={onCloseDrawer}
      actionBlock={
        <FooterWs
          onCreate={() => setIsDisabledInput(true)}
          setIsDrawerOpen={onCloseDrawer}
          isDisabled={workspaceCreate?.company.trim() === '' ? true : isError}
        />
      }
    >
      <div className={classes.nameContent}>
        <Typography size="Main" weight={600}>
          Общие сведения
        </Typography>
        <TwoInputBlocks
          disabledInput={isDisabledInput}
          onChangeData={data => setWorkspaceCreate(data)}
          onError={error => setIsError(error)}
          direction="column"
        />
      </div>
    </Drawer>
  );
});
