import { Page } from '@plarin/core';
import { Alert } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { AuthConfirmPhoneForm } from '../../components/auth/auth-confirm-phone-form';
import { AuthFormContainerResetTemplate, AuthFormContainerTemplate } from '../../components/auth/auth-form-template';
import { AuthResetPageTemplate } from '../../components/auth/auth-page-template';
import { AuthTabsTemplate } from '../../components/auth/auth-tabs-template';
import { TABS } from '../../utils/constants';
import { ForgotPasswordPageStore } from './forgot-password-page.store';
import { ResetByEmailForm } from './reset-by-email-form';
import { ResetByPhoneForm } from './reset-by-phone-form';

const ForgotPasswordPage: React.FC = observer(function ForgotPasswordPage() {
  const [pageStore] = useState(() => new ForgotPasswordPageStore());
  const { emailFormStore, phoneFormStore, tabs, activeTab, selectTab } = pageStore;
  const alert = emailFormStore.alertProps || phoneFormStore.alertProps;
  return (
    <Page title="Забыли пароль?">
      <AuthResetPageTemplate>
        {phoneFormStore.needConfirm ? (
          <AuthFormContainerTemplate title="Подтвердите смену пароля">
            <AuthConfirmPhoneForm store={phoneFormStore} />
          </AuthFormContainerTemplate>
        ) : (
          <AuthFormContainerResetTemplate title="Забыли пароль?" error={alert && <Alert {...alert} />}>
            <AuthTabsTemplate tabs={tabs} tab={activeTab} onTabChange={selectTab}>
              {tab =>
                tab === TABS.EMAIL ? (
                  <ResetByEmailForm store={emailFormStore} />
                ) : (
                  <ResetByPhoneForm store={phoneFormStore} />
                )
              }
            </AuthTabsTemplate>
          </AuthFormContainerResetTemplate>
        )}
      </AuthResetPageTemplate>
    </Page>
  );
});

export default ForgotPasswordPage;
