import { components } from '../../../types/profile/apispec';

export type TRegistrationEmailReq = components['schemas']['RegistrationReq'];

export type TRegistrationEmailResp = components['schemas']['ResponseModel_RegistrationResp_'];

export type TConfirmEmailReq = components['schemas']['ConfirmEmailReq'];

export type TRegistrationPhoneReq = components['schemas']['PhoneRegistrationReq'];

export type TRegistrationPhoneResp = components['schemas']['ResponseModel_RegistrationResp_'];

export type TPhoneValidateReq = components['schemas']['PhoneValidateReq'];

export type TPhoneValidateResp = components['schemas']['ResponseModel_LoginResp_'];
