import SwitchUnstyled, { switchUnstyledClasses, SwitchUnstyledProps } from '@mui/core/SwitchUnstyled';
import { styled } from '@mui/system';
import React from 'react';

const Root = styled('span')(`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;

  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }
  
  &.${switchUnstyledClasses.disabled}:hover .${switchUnstyledClasses.thumb} {
    box-shadow: none;
  }

  & .${switchUnstyledClasses.track} {
    background: #A7B0BE;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 12px;
    height: 12px;
    top: 2px;
    left: 2px;
    border-radius: 16px;
    background-color: #FFF;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: rgba(255,255,255,1);
    box-shadow: 0 0 1px 8px rgba(0,0,0,0.25);
  }
  

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 14px;
      top: 2px;
      background-color: #FFF;
    }

    .${switchUnstyledClasses.track} {
      background: #214ECB;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }`);

export const Switch: React.FC<SwitchUnstyledProps> = ({ ...props }) => {
  const label = { componentsProps: { input: { 'aria-label': 'switch' } } };
  return <SwitchUnstyled {...props} component={Root} {...label} />;
};
