import { getMemberName } from '@plarin/utils';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { Skeleton } from '../../../preloader';
import { ProjectListRenderer } from '../project-list-renderer';

interface Props extends ICellRendererParams {
  data: any; // TGetTeamByIdResponse
  wsMembers: any[]; // TWorkspaceMembers
}

export const TeamMembersRenderer = (props: Props) => {
  const { node, wsMembers } = props;

  const membersInTeam: { avatar_color: string; id: string; name: string }[] = wsMembers
    .filter(member => node.data.member_ids.includes(member._id))
    .map(item => {
      return {
        avatar_color: item.avatar_color,
        id: item._id,
        name: getMemberName({ fname: item.fname, lname: item.lname, email: item.email }),
      };
    });

  if (node.data.cellLoadingName && node.data.cellLoadingName.includes('members' || 'name'))
    return <Skeleton height={17} size="maxLong" />;

  return <ProjectListRenderer header="Участники команды" projectsData={membersInTeam} type="circle" node={node} />;
};
