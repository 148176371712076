import React from 'react';
import { ISkeleton, Skeleton } from './skeleton-table-row';
import classes from './skeleton-table-row.module.scss';

export const AvatarSkeleton = ({ sumList = 1, size, height = 12, ...res }: ISkeleton & { sumList?: number }) => {
  const arrayItems = Array(sumList).fill('1');
  return (
    <>
      {arrayItems.map((el, index) => (
        <div className={classes.wrapperBlock} key={index}>
          <div className={classes.avatar}></div>
          <Skeleton size={size} height={height} {...res} />
        </div>
      ))}
    </>
  );
};
