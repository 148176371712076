import { isUrlProd, isUrlDev } from './constants';
import { getYmCounter } from './getYmCounter';

export const sendMetricGoal = (goalId: string, pathSubstring: string | string[]) => {
  const isPathValid = Array.isArray(pathSubstring)
    ? pathSubstring.some(sub => window.location.pathname.includes(sub))
    : window.location.pathname.includes(pathSubstring);

  if (isPathValid && (isUrlProd || isUrlDev)) {
    // @ts-ignore
    ym(getYmCounter(), 'reachGoal', goalId);
  }
};
