import { CustomList } from '@plarin/inputs';
import React from 'react';
import { TTeamsFilters } from '../../../../../../../../types/projects/projects';
import classes from '../../styles.module.scss';
import { TeamsListItem } from './teams-list-item';

type TTeamsListProps = {
  teamsListToRender: TTeamsFilters[] | null;
  changeTeamsSelection: (team: TTeamsFilters) => void;
  isFoundItem: boolean;
  selectedTeams: TTeamsFilters[];
  searchValue?: string;
};

export const ProjectTeamsList = ({
  teamsListToRender,
  selectedTeams,
  changeTeamsSelection,
  isFoundItem,
  searchValue,
}: TTeamsListProps) => {
  return (
    <CustomList classProps={classes.listRoot}>
      <>
        {teamsListToRender
          ?.filter(el => {
            return !selectedTeams?.find(e => {
              return e.id === el.id;
            });
          })
          ?.map(team => (
            <TeamsListItem
              team={team}
              changeTeamsSelection={changeTeamsSelection}
              isFoundItem={isFoundItem}
              key={team.id}
              searchValue={searchValue}
            />
          ))}
      </>
    </CustomList>
  );
};
