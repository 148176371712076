import React from 'react';
import classes from './style.module.scss';

export const MembersPreloader = () => {
  return (
    <div className={classes.skeletonWrapper}>
      <div className={classes.content}>
        <svg width="432" height="256" viewBox="0 0 432 256" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="432" height="48" rx="4" fill="#E2E5E9" />
          <rect y="88" width="236" height="16" rx="4" fill="#E2E5E9" />
          <rect x="284" y="88" width="148" height="16" rx="4" fill="#E2E5E9" />
          <rect y="112" width="432" height="48" rx="4" fill="#E2E5E9" />
          <rect y="188" width="432" height="16" rx="4" fill="#E2E5E9" />
          <line y1="215.5" x2="432" y2="215.5" stroke="#E2E5E9" />
          <rect y="228" width="432" height="16" rx="4" fill="#E2E5E9" />
          <line y1="255.5" x2="432" y2="255.5" stroke="#E2E5E9" />
        </svg>
      </div>
      <div className={classes.footer}>
        <svg width="262" height="32" viewBox="0 0 262 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="101" height="32" rx="4" fill="#E2E5E9" />
          <rect x="116" width="100" height="32" rx="4" fill="#E2E5E9" />
          <rect x="228" width="34" height="32" rx="4" fill="#E2E5E9" />
        </svg>
      </div>
    </div>
  );
};

export const WsDrawerPreloader = () => {
  return (
    <div className={classes.skeletonWrapper}>
      <div className={classes.content}>
        <div className={classes.blocks}>
          <svg width="432" height="161" viewBox="0 0 432 161" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.927979" width="236" height="16" rx="4" fill="#E2E5E9" />
            <rect y="24.928" width="432" height="48" rx="4" fill="#E2E5E9" />
            <rect y="88.928" width="432" height="24" rx="4" fill="#E2E5E9" />
            <rect y="128.928" width="432" height="24" rx="4" fill="#E2E5E9" />
          </svg>
        </div>

        <div className={classes.blocks}>
          <svg width="432" height="161" viewBox="0 0 432 161" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.927979" width="236" height="16" rx="4" fill="#E2E5E9" />
            <rect y="24.928" width="432" height="48" rx="4" fill="#E2E5E9" />
            <rect y="88.928" width="432" height="24" rx="4" fill="#E2E5E9" />
            <rect y="128.928" width="432" height="24" rx="4" fill="#E2E5E9" />
          </svg>
        </div>

        <div className={classes.blocks}>
          <svg width="432" height="161" viewBox="0 0 432 161" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.927979" width="236" height="16" rx="4" fill="#E2E5E9" />
            <rect y="24.928" width="432" height="48" rx="4" fill="#E2E5E9" />
            <rect y="88.928" width="432" height="24" rx="4" fill="#E2E5E9" />
            <rect y="128.928" width="432" height="24" rx="4" fill="#E2E5E9" />
          </svg>
        </div>
      </div>
      <div className={classes.footer}>
        <svg width="262" height="32" viewBox="0 0 262 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="101" height="32" rx="4" fill="#E2E5E9" />
          <rect x="116" width="100" height="32" rx="4" fill="#E2E5E9" />
          <rect x="228" width="34" height="32" rx="4" fill="#E2E5E9" />
        </svg>
      </div>
    </div>
  );
};
