import { ValueFormatterFunc } from 'ag-grid-community';
import React from 'react';
import classes from './style.module.scss';

interface DefaultRendererProps {
  value: number | string;
  formatter?: string | ValueFormatterFunc;
  data?: unknown;
}

const renderer = (sum: number, valueFormatter: unknown, data: unknown) =>
  // todo: Нужно что-то придумать с каренси для суммы в мультивалютах. сейчас все складываем и лепим рубль
  typeof valueFormatter === 'function' ? valueFormatter({ value: sum, data }) : sum;

export const DefaultRenderer = ({ value, formatter, data = {} }: DefaultRendererProps) => {
  return (
    <div>
      <span className={classes.text}>{typeof value === 'string' ? value : renderer(+value, formatter, data)}</span>
    </div>
  );
};
