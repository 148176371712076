import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import './integration-buttons.scss';

export const IntegrationsConnectButton: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <Button
      {...props}
      classes={{
        root: 'ConnectButton',
      }}
    >
      Подключить
    </Button>
  );
};
