import React from 'react';
import { ErrorLoadingData } from './variants';

interface ErrorTableProps {
  action?: () => Promise<void>;
  error?: boolean;
}

export const ErrorTable = ({ action, error }: ErrorTableProps) => {
  return <>{error && <ErrorLoadingData action={action} />}</>;
};
