import { Typography } from '@plarin/design';
import { getNumeral } from '@plarin/utils';
import { ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import React, { useCallback, useRef, useState } from 'react';
import { EllipsisTooltip } from '../../../tooltip';
import { ItemsPopover } from '../../../wsTableActionMenu';
import classes from './style.module.scss';

interface ProjectsRendererProps extends ICellRendererParams {
  wsDictionary: { members: Record<string, string>; projects: Record<string, { name: string; avatar_color: string }> };
}

export const ProjectsRenderer = (props: ProjectsRendererProps) => {
  const { data } = props;
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const isArchived = data.statusColor === 'archived';

  const onOpen = useCallback(e => {
    e.stopPropagation();
    setOpen(true);
    setAnchorEl(e.currentTarget);
  }, []);
  //
  const closeMenu = useCallback((e: React.MouseEvent | Event) => {
    e.stopPropagation();
    setOpen(false);
    setAnchorEl(null);
  }, []);

  return (
    <div className={classes.projectsWrapper}>
      {data.mtProject.length > 1 ? (
        <div
          ref={ref => {
            if (!ref) return;
            ref.onclick = onOpen;
          }}
          className={classes.projectsTextWrapper}
        >
          <Typography
            size="Caption"
            color={isArchived ? 'TextSecondary' : 'LinkPrimary'}
            componentProps={{ className: clsx(classes.ref, isArchived && classes.archivedHover), ref: anchorRef }}
          >{`${data.mtProject.length} ${getNumeral(data.mtProject.length, [
            'проект',
            'проекта',
            'проектов',
          ])}`}</Typography>
          <ItemsPopover
            header="Проекты"
            items={data.mtProject}
            isOpen={open}
            closeMenu={closeMenu}
            anchorEl={anchorEl}
          />
        </div>
      ) : (
        <EllipsisTooltip tooltipMessage={data.mtProject[0].name}>
          <Typography
            size="Caption"
            ellips
            color={data.mtProject[0].name === 'Не определен' ? 'TextSecondary' : 'TextPrimary'}
          >
            {data.mtProject[0].name}
          </Typography>
        </EllipsisTooltip>
      )}
      <EllipsisTooltip
        tooltipMessage={data.hasOwnProperty('responsible') && data.responsible !== 'Не указано' ? data.responsible : ''}
      >
        <Typography
          size="TableRowSubHeader"
          color={isArchived ? ' Tertiary' : 'TextSecondary'}
          componentProps={{ className: classes.projectsSecondText }}
          ellips
        >
          {data.hasOwnProperty('responsible') && data.responsible !== 'Не указано' && data.responsible}
        </Typography>
      </EllipsisTooltip>
    </div>
  );
};
